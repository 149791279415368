define('components/widget/description-eligibility-guidelines/app',['require','components/widget/description-eligibility-guidelines/directive/descriptionEligibilityGuidelines','components/widget/description-eligibility-guidelines/controller/descriptionEligibilityGuidelinesController','common/service/specialtyApplication/EligibilityGuidelineService'],function(require) {
    "use strict";

    var descriptionEligibilityGuidelines 		= require("components/widget/description-eligibility-guidelines/directive/descriptionEligibilityGuidelines"),
        descriptionEligibilityGuidelinesController = require("components/widget/description-eligibility-guidelines/controller/descriptionEligibilityGuidelinesController"),
        eligibilityGuidelineService = require("common/service/specialtyApplication/EligibilityGuidelineService");

    var component 				                = angular.module("mPortal.components.widget.description-eligibility-guidelines", []);
    component.directive( "descriptionEligibilityGuidelines", descriptionEligibilityGuidelines );
    component.controller( "DescriptionEligibilityGuidelinesController", descriptionEligibilityGuidelinesController );
    component.factory("EligibilityGuidelineService", eligibilityGuidelineService);

    return component;

});
