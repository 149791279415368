define( 'common/service/user/EnforceSession',['require'],function( require ) {
    "use strict";

    EnforceSession.$inject = ["$q", "UserService"];

    function EnforceSession( $q, UserService ) {

        return UserService.getUserProfile().then( function( profile ) {
            return profile.isLoggedIn ? $q.when() : $q.reject({ reason: "notLoggedIn" });
        });

    }

    return EnforceSession;

});
