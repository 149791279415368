define( 'components/policyDetail/policyDetail/controller/PolicyDetailController',['require'],function ( require ) {
	"use strict";

	PolicyDetailController.$inject = ["$scope", "LossRunsService", "moment"];

	function PolicyDetailController( $scope, LossRunsService, moment ) {

		$scope.isBillingInfoVisible = function() {
			return $scope.policy.isBillingDataAvailable && $scope.isViewBillingAvailable();
		}
		$scope.canDisplayDocuments = function() {
			return $scope.policy.canDisplayDocuments ;
        }
		$scope.isViewBillingAvailable = function() {
			if ( $scope.policy.billType && $scope.user.type !== 'Insured' ) {
				if ( $scope.user.type === 'Internal' && $scope.policy.billType === 'Agency bill' && $scope.user.insuredEmulation ) {
					return false
				}
				return true
			}
			else if ( $scope.user.type === 'Insured' && $scope.policy.billType === 'Direct bill' ) {
				return true
			}
		}

		$scope.showLossRuns = function(){
			let viewLossRuns = false;
			if(localStorage.getItem('mportal-show-loss-runs'))
			{
				viewLossRuns = localStorage.getItem('mportal-show-loss-runs') === 'true';
			}	
			return viewLossRuns;
		}
	
		$scope.isLossRunLinkVisible = function() {
			let show_loss_run_pdf =  false;
			if(localStorage.getItem('mportal-show-loss-run-pdf'))
			{
				show_loss_run_pdf = localStorage.getItem('mportal-show-loss-run-pdf') === 'true';
			}

			if(show_loss_run_pdf){
				return $scope.user.type.toLowerCase() === 'insured' && $scope.policy.insuredType === 'Direct' && LossRunsService.showInsuredLossRuns( $scope.policy.agencyId );
			}
			return false;
		}

		$scope.isIWantToListVisible = function() {
			return $scope.account.piNumber && ($scope.policy.paymentEnabled || $scope.isLossRunLinkVisible());
		}

		$scope.isSendMessageVisible = function () {
			return $scope.user.type === 'Agent';
		}

		$scope.isPolicyChangeRequestVisible = function() {
			var isExpired = moment( $scope.policy.expirationDate, "MM/DD/YYYY" ).isBefore( Date.now() );
			return ($scope.user.type === 'Agent') && !isExpired && $scope.policy.status !== 'Cancelled';
		}

        $scope.isPolicyChangeRequestVisibleForInsured = function() {
            var isExpired = moment( $scope.policy.expirationDate, "MM/DD/YYYY" ).isBefore( Date.now() );
            return ($scope.user.type === "Insured" && $scope.policy.insuredType === 'Direct') && !isExpired && $scope.policy.status !== 'Cancelled';
        }

		$scope.isMergedInsuredEnabled = function () {
			if ( $scope.account.accountViewVersion === 2 ) {
				if ( $scope.account.primaryAccountId !== null && $scope.account.accountId === $scope.account.primaryAccountId ) {
					return true
				} else return !($scope.account.primaryAccountId !== null && $scope.account.accountId !== $scope.account.primaryAccountId);
			}
		}

	}

	return PolicyDetailController;

} );

