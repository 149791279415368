define('components/accountSummary/accountList/app',['require','components/accountSummary/accountList/directive/accountList','components/accountSummary/accountList/controller/AccountListController','moment'],function( require ) {
    "use strict";

    var accountList = require( "components/accountSummary/accountList/directive/accountList" ),
        AccountListController = require( "components/accountSummary/accountList/controller/AccountListController" ),
        moment = require( "moment" );

    var component = angular.module( "mPortal.accountSummary.accountList", [] );

    component.directive( "accountList", accountList );
    component.controller( "AccountListController", AccountListController );
    component.value( "moment", moment );

    return component;

});
