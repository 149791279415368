define('components/editSubmission/app',['require','components/editSubmission/directive/editSubmission','components/editSubmission/controller/EditSubmissionController','components/editSubmission/controller/EditWarningModalController','common/controller/GenericModalController','common/service/specialtyApplication/AccountService','common/service/user/UserProfile','moment'],function(require)  {
    "use strict";

    var editSubmission = require("components/editSubmission/directive/editSubmission"),
        EditSubmissionController = require("components/editSubmission/controller/EditSubmissionController"),
        EditWarningModalController = require("components/editSubmission/controller/EditWarningModalController"),
        GenericModalController = require("common/controller/GenericModalController"),

        AccountService = require("common/service/specialtyApplication/AccountService"),
        UserProfile = require( "common/service/user/UserProfile" ),
        moment = require("moment");

    var component = angular.module("mPortal.editSubmission", [ "ui.bootstrap", "mPortal.config" ]);

    component.directive("editSubmission", editSubmission);
    component.controller("EditSubmissionController", EditSubmissionController);
    component.controller("EditWarningModalController", EditWarningModalController);
    component.controller("GenericModalController", GenericModalController);

    component.factory( "AccountService", AccountService );
    component.factory( "UserProfile", UserProfile );

	// moment is needed to be added to the component/module so it is available to be injected into the AccountService.
	component.value("moment", moment);

    return component;
});
