define('components/specialtyApplication/payment/app',['require','components/specialtyApplication/payment/directive/payment','components/specialtyApplication/payment/controller/PaymentController'],function(require) {
	"use strict";

	var payment = require("components/specialtyApplication/payment/directive/payment"),
			PaymentController = require("components/specialtyApplication/payment/controller/PaymentController" );

	var component = angular.module("mPortal.components.specialtyApplication.payment", []);

	component.directive("specialtyApplicationPayment", payment);
	component.controller("SpecialtyApplicationPaymentController", PaymentController );

	return component;

});
