define('components/specialtyApplication/questions/config/widgets/FineArtsWidget',['require'],function(require) {
    "use strict";

    var widget = {};

    widget.getStructure = function() {

        return {

            "Location FAC" :{ wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 1 },
            "fineInfo": {
                wrapper: [ "fine-item-wrapper" ], orderNumber: 2,
                fieldGroup: {
                    "Loc FAC Num Items" : {  wrapper: [ "hideGroup" ], orderNumber: 1, data: { saveBroadcast: true } },
                    "Loc FAC Item Number" : {  wrapper: [ "facLimit", "facLimitError" ], orderNumber: 2,  data: { savePointOnly: true } },
                    "Loc FAC Limit" : {  wrapper: [ "facLimit", "facLimitError" ], orderNumber: 3,  data: { savePointOnly: true }},
                    "Loc FAC Desc": { wrapper: [ "facDescription", "facDescriptionError" ], orderNumber: 4,  data: { savePointOnly: true } },
                }
            },
            "Loc FAC Agg Limit" :{ wrapper: [ "verticalWideFullRowPointIM" ], wrapperOverride: true, orderNumber: 5 },
            "FAC Agg Location Warning": { wrapper: [ ], orderNumber: 6 }

        };
    };

    widget.configureFormly = function (formlyConfig, formlyApiCheck) {

        formlyConfig.setWrapper({
            name: "fine-item-wrapper",
            template: '<div style="padding-left:30px" fine-item-limit model="model" options="options" quantity-control-id="\'itemNumberQuantity\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "verticalWideFullRowPointIM",
            template:
                '<label style="display:inline !important; width:55%;float:left;margin-bottom:1%;margin-left: 35px;font-weight: bold;" for="{{ id }}" class="control-label">{{ to.label }}' + '</label>' +
                '<formly-transclude></formly-transclude>'
        });

        formlyConfig.setWrapper({
            name: "facLimit",
            template: '<div class="facLimit" style="width: 112px;">' +
                '<formly-transclude></formly-transclude>' +
                '</div>'
        });

        formlyConfig.setWrapper({
            name: "facLimitError",
            overwriteOk: true,
            template:
                '<div class="span1 form-group facLimitError" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
                '<formly-transclude></formly-transclude>' +
                '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
                '<div>' +
                '<p class="facLimitErr">{{ options.data.validationMessage }}</p>' +
                '</div>' +
                '</div>' +
                '</div>'
        });

        formlyConfig.setWrapper({
            name: "facDescription",
            template: '<div class="facDescription">' +
                '<formly-transclude></formly-transclude>' +
                '</div>'
        });

        formlyConfig.setWrapper({
            name: "facDescriptionError",
            overwriteOk: true,
            template:
                '<div class="form-group facDescriptionError" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
                '<formly-transclude></formly-transclude>' +
                '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
                '<div>' +
                '<p class="facDescError">{{ options.data.validationMessage }}</p>' +
                '</div>' +
                '</div>' +
                '</div>'
        });

    };

    return widget;


});
