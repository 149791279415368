define( 'components/specialtyApplication/pricingDetails/animalMortality/directive/animalMortality',['require'],function( require ) {
    "use strict";

    AnimalMortality.$inject = [];

    function AnimalMortality() {
        return {
            restrict: "AE",
            scope: {
                pricingData: "="
            },
            templateUrl: "app/components/specialtyApplication/pricingDetails/animalMortality/template/template.html"
        };
    }

    return AnimalMortality;

});
