define('common/service/specialtyApplication/SpecialtyEligibilityService',['require'],function(require) {
	"use strict";

	SpecialtyEligibilityService.$inject = ["$http", "archApiUrl"];

	function SpecialtyEligibilityService( $http, archApiUrl ) {

		var service = {};

		service.getMarketedPrograms = function( cobrandedId ) {
			return $http.get( archApiUrl + "application/marketed-program/" + cobrandedId ).then(function( result ) {
				return result.data;
			}).catch(function( err ) {
				// this endpoint returns a 404 if no marketed programs are configured. this is unlikely to occur. other
				// errors will be picked up by the global interceptor.
				return [];
			});
		};

		service.checkAvailability = function( zip, effectiveDate, marketedProgram, cobrandedId ) {
			return $http.post( archApiUrl + "application/availability", {
				zip: zip,
				effectiveDate: effectiveDate,
				marketedProgramId: marketedProgram,
				cobrandedId: cobrandedId
			});
		};

		service.checkZipCode = function( zip ) {
			return $http.post( archApiUrl + "application/zipCode", {
				zip: zip
			});
		};

		service.reopenSubmission = function( applicationId ) {
			return $http.get( archApiUrl + "application/submission/" + applicationId + "/edit" ).then( function ( result ) {
				return {
					error: false,
					response: {
						effectiveDate: result.data.masterquote.effdate,
						marketedProgram: result.data.marketedProgram,
						programName: result.data.masterquote.programName,
						masterQuoteID: result.data.masterquote.masterquoteid,
						servicingAgentId: result.data.masterquote.servicingAgentId,
						licensedAgentId: result.data.masterquote.licensedAgentId,
						instance: result.data.masterquoteinstance.instance,
						insured: {
							maid: result.data.insured.maid,
							name: result.data.insured.name,
							dba: result.data.insured.dba,
							fein: result.data.insured.fein,
							address1: result.data.insured.address1,
							city: result.data.insured.city,
							state: result.data.insured.state,
							zip: result.data.insured.zip,
							mailAddress1: result.data.insured.physicaladdress1,
							mailCity: result.data.insured.physicalcity,
							mailState: result.data.insured.physicalstate,
							mailZip: result.data.insured.physicalzip,
							phone: result.data.insured.phone,
							email: result.data.insured.email,
							deliveryPreference: result.data.masterquote.preferreddeliverytype
						},
						canEditInsured: result.data.canEditInsured,
						resumeLink: result.data.resumeLink
					}
				}
			} ).catch( function ( err ) {
				return {error: true}
			} );
		};

		service.getAgentsAndLicenses = function ( agencyId, programs, state ){
			return $http.post( archApiUrl + "application/agent-information" , {
				agencyId  :  agencyId,
				programs  :  programs,
				state     :  state
			});
		};

		service.checkQuoteAvailability = function ( zipCode, emailId ) {
			return $http.post( archApiUrl + "account/search-account", {
				zipCode : zipCode,
				emailId : emailId
			} );
		};
		return service;

	}

	return SpecialtyEligibilityService;

});
