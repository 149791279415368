define('components/specialtyApplication/questionShellSections/agentDateSelections/app',['require','components/specialtyApplication/questionShellSections/agentDateSelections/directive/agentDateSelections','components/specialtyApplication/questionShellSections/agentDateSelections/directive/licenseInfo','components/specialtyApplication/questionShellSections/agentDateSelections/controller/AgentDateSelectionsController','components/specialtyApplication/questionShellSections/agentDateSelections/controller/LicenseInfoModalController','common/filter/deDuplicate'],function(require) {
    "use strict";

    var agentDateSelections         = require("components/specialtyApplication/questionShellSections/agentDateSelections/directive/agentDateSelections"),
		licenseInfo                = require("components/specialtyApplication/questionShellSections/agentDateSelections/directive/licenseInfo"),
        AgentDateSelectionsController = require("components/specialtyApplication/questionShellSections/agentDateSelections/controller/AgentDateSelectionsController"),
		LicenseInfoModalController = require("components/specialtyApplication/questionShellSections/agentDateSelections/controller/LicenseInfoModalController"),
		deDuplicate               = require( "common/filter/deDuplicate" );
    var component = angular.module( "mPortal.components.specialtyApplication.questionShellSections.agentDateSelections", []);

    component.directive("agentDateSelections", agentDateSelections);
	component.directive("licenseInfo", licenseInfo);
    component.controller("AgentDateSelectionsController", AgentDateSelectionsController);
	component.controller("LicenseInfoModalController", LicenseInfoModalController);
	component.filter( "deDuplicate", deDuplicate );

    return component;

});
