define('components/submissionsList/directive/submissionListItem',['require'],function(require) {
	"use strict";

	function SubmissionListItem() {
		return {
			restrict: "AE",
			scope: {
				submission: "="
			},
			templateUrl: "app/components/submissionsList/template/submissionListItem.html"
		};
	}

	return SubmissionListItem;

});
