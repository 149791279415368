define('components/renewalsList/app',['require','common/directive/app','components/renewalsList/directive/renewalsList','components/renewalsList/controller/RenewalsListController','common/service/RenewalService'],function(require) {
	"use strict";

	require("common/directive/app");

	var renewalsList = require("components/renewalsList/directive/renewalsList"),
		RenewalsListController = require("components/renewalsList/controller/RenewalsListController"),
		RenewalService = require("common/service/RenewalService");

	var component = angular.module("mPortal.components.renewalsList", [
		"mPortal.directive"
	]);

	component.directive("renewalsList", renewalsList);
	component.controller("RenewalsListController", RenewalsListController);
	component.factory("RenewalService", RenewalService);

	return component;

});
