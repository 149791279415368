define( 'components/policyDetail/policyBilling/controller/PolicyBillingController',['require'],function ( require ) {
	"use strict";

	PolicyBillingController.$inject = ["$scope", "AccountService", "$window", "archApiUrl", "$routeParams", "moment", "$modal"];

	function PolicyBillingController( $scope, AccountService, $window, archApiUrl, $routeParams, moment, $modal ) {

		/*
		Need to account for the scenario where a policy is billed via ICON but a piNumber has not been tied to the policy
		yet (there can be delay between the time a policy is issued in mPolicy and the time the policy data is sent to
		ICON to generate billing data).
		*/
		$scope.uiState = {
			loading: $scope.accountId ? true : false,
			error: false,
			visible: true,
			hasBillingAccountId: $scope.accountId ? true : false,
			showWarningOnPaymentSchedule: $scope.policyBillType === "A",
			isAgent : $scope.user.type.toLowerCase() === "agent"
		};

		$scope.pageSize = 5;

		$scope.insuredId = $scope.account.accountId;

		$scope.showUnPaidDue = true;
		var defaultSelectValue = "All Policies"
		$scope.filterValues = {
			policy: defaultSelectValue
		};
		$scope.filterOptions = {
			policy: []
		};

		if ( $scope.uiState.hasBillingAccountId ) {
			var accountId = $scope.accountId;
			var effectiveDate = moment( $scope.policyEffectiveDate,"MM/DD/YYYY" ).format("YYYY-MM-DD" );
			if ( $scope.policy.billingSystem == 'BC9' ) {
				accountId = $scope.account.accountId
			}
			AccountService.getPolicyBillingHistory( accountId, $scope.policy.policyNumber, effectiveDate, $scope.insuredId, $scope.policy.billingSystem ).then( function ( result ) {
				if ( $scope.policy.billingSystem == 'BC9' ) {
					$scope.setBillingData( result );
				} else {
					$scope.billingDetail = result;
				}

			} ).catch( function ( err ) {
				$scope.uiState.error = true;
			} ).finally( function () {
				$scope.uiState.loading = false;
			} );
		}

		$scope.setBillingData = function ( result ) {
			$scope.filterOptions.policy = [];
			if ( result.accountSummary !== null ) {
				angular.forEach( result.accountSummary.policies, function ( policy ) {
					if ( $scope.filterOptions.policy.indexOf( policy.policyNumber ) === -1 ) {
						$scope.filterOptions.policy.push( policy.policyNumber );
					}
				} );
			}
			$scope.filterOptions.policy = $scope.filterOptions.policy.sort();
			$scope.filterOptions.policy.unshift( defaultSelectValue );
			$scope.billingDetail = result;
			if ( result.billingDocuments !== null ) {
				var billingDocuments = $scope.billingDetail.billingDocuments;
				if ( billingDocuments ) {
					$scope.filterDocuments( billingDocuments );
				}
				$scope.accountSummaryInvoice = $scope.billingDetail.accountSummary.invoices;
				$scope.invoices = $scope.accountSummaryInvoice;
			}
		};

		$scope.filterDocuments = function ( billingDocuments ) {
			var filteredDocuments = [];
			angular.forEach( billingDocuments, function ( document ) {
				if ( document.extension !== 'MHT' ) {
					filteredDocuments.push( document );
				}
			} );
			$scope.filteredBillingDocuments = filteredDocuments;
		};

		$scope.toggleVisibility = function () {
			$scope.uiState.visible = !$scope.uiState.visible;
		};

		$scope.getBillingDocument = function ( documentId ) {
			$window.open( archApiUrl + "application/dms/document/" + documentId, "_blank" );
		}

		$scope.filterByPolicyValue = function () {
			if ( $scope.filterValues.policy !== "All Policies" ) {
				var filteredItems = [];
				if ( $scope.billingDetail.policySummary.length > 0 ) {
					angular.forEach( $scope.billingDetail.policySummary, function ( policySummary ) {
						if ( policySummary.invoicesHistory.policyNumber === $scope.filterValues.policy ) {
							filteredItems = policySummary.invoicesHistory.invoices;
						}
					} );
					$scope.accountSummaryInvoice = filteredItems;
				}
			} else {
				$scope.accountSummaryInvoice = $scope.invoices;
			}
		}

		$scope.showBillingErrorMessage = function () {
			if ( $scope.account.accountViewVersion === 2 ) {
				if ( $scope.account.primaryAccountId !== null && $scope.account.accountId === $scope.account.primaryAccountId ) {
					return true
				} else return !($scope.account.primaryAccountId !== null && $scope.account.accountId !== $scope.account.primaryAccountId);
			}
		}

		$scope.isSameAgencyPrimaryAccount = function () {
			return $scope.account.primaryAgencyId === $scope.policy.agencyId;
		}



		$scope.openAddAccountModal = function() {
			var modalInstance = $modal.open({
				templateUrl: "app/components/addAccount/template/modalTemplate.html",
				controller: "AddAccountModalController",
				resolve: {
					insuredAccountNumber: function(){ return $scope.account.primaryAccountId; }
				}
			});

			modalInstance.result.then(function() {
				$scope.$emit('load-insured-accounts');
			}).catch(function( err ) {
				// just let it close, this indicates the user closed manually and we
				// did not successfully add an account to their profile.
			});
		};

	}

	return PolicyBillingController;

} );
