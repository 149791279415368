define('components/productSearch/filter/app',['require','components/productSearch/filter/directive/productSearchFilter','components/productSearch/filter/controller/productSearchFilterController','common/service/productSearch/FilterService'],function(require) {
	"use strict";

	var productSearchFilter 			= require("components/productSearch/filter/directive/productSearchFilter"),
		productSearchFilterController 	= require("components/productSearch/filter/controller/productSearchFilterController"),
		filterService 					= require("common/service/productSearch/FilterService");

	var component 						= angular.module("mPortal.components.productSearch.filter", []);

	component.directive("productSearchFilter", productSearchFilter);
	component.factory("FilterService", filterService);
	component.controller("productSearchFilterController", productSearchFilterController);

	return component;

});
