define( 'common/service/specialtyApplication/CoBrandingService',['require'],function (require) {
	"use strict";

	CoBrandingService.$inject = ["$http", "archApiUrl"];

	function CoBrandingService( $http, archApiUrl ) {
		var service = {};

		service.getCoBranding = function(coBrandingId) {
			return $http.get( archApiUrl + "cobranding/" + coBrandingId ).then( function ( result ) {
				return {
					error: false,
					response: result.data
				}
			} ).catch( function ( err ) {
				return {error: true}
			} );
		};

		return service;
	}

	return CoBrandingService;
});
