define('common/config/globalRequires',['require','angular-bootstrap','angular-animate','jquery-ui-autocomplete-html'],function(require) {
	"use strict";

	require("angular-bootstrap");
	require("angular-animate");
	require("jquery-ui-autocomplete-html");

	var app = angular.module("mPortal.config.globalRequires", [
		"ui.bootstrap"
		// ,"ngAnimate"  Commented out as inclusion of the library is having side-effects in the marketed program submission form
	]);

	return app;

});
