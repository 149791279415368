define('common/service/TabManagerService',['require'],function(require) {
	"use strict";

	TabManagerService.$inject = [];

	function TabManagerService() {

		var service = {},
			tabs = {};

		function getTab( category, id ) {
			return tabs[ category ] && tabs[ category ].find(function( tab ) {
				return tab.__id === id;
			});
		}

		service.getTabs = function(){
			return tabs;
		}

		service.createTab = function(category, id, model){
			var tabCategory = tabs[ category ];
			tabCategory.push({ __id: id, active: false, model: model });
		}

		service.initializeTabs = function( categories ) {
			categories.forEach(function( category ) {
				tabs[ category ] = [];
			});

			return tabs;
		};

		service.openTab = function( category, id, model ) {
			var tabCategory = tabs[ category ],
				existingTab = getTab( category, id );

			if( existingTab ) {
				existingTab.active = true;
			} else {
				tabCategory.push({ __id: id, active: true, model: model });
			}
		};

		service.closeTab = function( category, id ) {
			var existingTab = getTab( category, id ),
				index = tabs[ category ].indexOf( existingTab );

			if( index === -1 ) {
				return;
			}

			tabs[ category ].splice( index, 1 );
		};

		return service;

	}

	return TabManagerService;

});
