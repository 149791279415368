define('components/message/list/app',['require','common/directive/app','components/message/list/directive/messageList','components/message/list/controller/messageListController','common/service/MessageService','common/controller/GenericModalController','components/message/list/controller/forwardModalController','common/service/util/DateService'],function(require) {
	"use strict";

	require("common/directive/app");

	var messageList 				= require("components/message/list/directive/messageList"),
		messageListController 		= require("components/message/list/controller/messageListController"),
		MessageService 				= require('common/service/MessageService'),
		GenericModalController 		= require("common/controller/GenericModalController" ),
		forwardModalController		= require("components/message/list/controller/forwardModalController" ),
		DateService 				= require("common/service/util/DateService");

	var component = angular.module("mPortal.components.message.list", ["mPortal.directive"]);

	component.directive("messageList", messageList);
	component.controller("messageListController", messageListController);
	component.controller('forwardModalController',forwardModalController);
	component.factory("DateService", DateService);
	component.factory('MessageService',MessageService);


	return component;

});
