define( 'components/applicationData/applicationInfo/app',['require','components/applicationData/applicationInfo/directive/applicationInfo','components/applicationData/applicationInfo/controller/ApplicationInfoController'],function( require ) {
    "use strict";

    var applicationInfo = require( "components/applicationData/applicationInfo/directive/applicationInfo"),
        ApplicationInfoController = require( "components/applicationData/applicationInfo/controller/ApplicationInfoController");

    var component = angular.module( "mPortal.applicationData.applicationInfo", [] );

    component.directive( "applicationInfo", applicationInfo );
    component.controller( "ApplicationInfoController", ApplicationInfoController );

    return component;

});
