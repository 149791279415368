define('components/lossRunReport/controller/LossRunReportController',['require'],function(require) {
	"use strict";

	LossRunReportController.$inject = ["$scope", "$window", "ReportService", "UserProfile"];

	function LossRunReportController( $scope, $window, ReportService, UserProfile ) {

		$scope.error = "";
		$scope.months = ReportService.months;
		$scope.years = ReportService.years;
		$scope.reportParams = {
			month: ReportService.firstAvailableRun.getMonth(),
			year: ReportService.firstAvailableRun.getFullYear(),
			policyNumber: "",
			detail: "",
			reportId: 83,
			performerID: UserProfile.performerID
		};

		$scope.getReport = function() {

			var reportUrl;

			$scope.error = "";

			if( ReportService.validateDateRange( $scope.reportParams ) ) {

				reportUrl = ReportService.getLossRunReportUrl( $scope.reportParams );
				$window.open( reportUrl, "_blank", "toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=1,resizable=1,width=950,height=650" );

				// this directive might be called in a modal.. if we have a close method, call it after opening the new window.
				if( $scope.close ) {
					$scope.close();
				}

			} else {

				$scope.error = "Data is not available for this period";

			}

		};

	}

	return LossRunReportController;

});
