define('reports/app',['require','common/config/config','components/agencyExperienceReport/app','components/lossRunReport/app','shim/overmind'],function(require) {
	"use strict";

	require("common/config/config");
	require("components/agencyExperienceReport/app");
	require("components/lossRunReport/app");

	var overmind = require("shim/overmind");

	var app = angular.module("mPortal.reports", [
		"mPortal.config",
		"mPortal.components.agencyExperienceReport",
		"mPortal.components.lossRunReport"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider.when("/report/", {
			templateUrl: "app/reports/template/template.html"
		});
	}]);

	return app;

});
