define('common/directive/app',['require','angular','common/directive/autocomplete','common/directive/datePicker','common/directive/errorMessage','common/directive/maphilight','common/directive/selectAllChecks','common/directive/scrollTo','common/directive/productLabel','common/directive/distractionFreeToggle','common/directive/loadingButton','common/directive/iframeResizer','common/directive/inputPopover','common/directive/loadingBar','common/directive/fileUpload','common/directive/sectionToggle','common/directive/progressDial'],function(require) {
	"use strict";

	require("angular");

	var autocomplete = require("common/directive/autocomplete"),
		datePicker = require("common/directive/datePicker"),
		errorMessage = require("common/directive/errorMessage"),
		maphilight = require("common/directive/maphilight"),
		selectAllChecks = require("common/directive/selectAllChecks"),
		scrollTo = require("common/directive/scrollTo"),
		productLabel = require("common/directive/productLabel"),
		distractionFreeToggle = require("common/directive/distractionFreeToggle"),
		loadingButton = require("common/directive/loadingButton" ),
		iframeResizer = require("common/directive/iframeResizer"),
		inputPopover = require("common/directive/inputPopover"),
		loadingBar = require("common/directive/loadingBar"),
		fileUpload = require("common/directive/fileUpload"),
		sectionToggle = require("common/directive/sectionToggle"),
		progressDial = require("common/directive/progressDial");

	var app = angular.module( "mPortal.directive", [] );

	app.directive("autocomplete", autocomplete);
	app.directive("datePicker", datePicker);
	app.directive("errorMessage", errorMessage);
	app.directive("mapHilight", maphilight);
	app.directive("selectAllChecks", selectAllChecks);
	app.directive("scrollTo", scrollTo);
	app.directive("productLabel", productLabel);
	app.directive("distractionFreeToggle", distractionFreeToggle);
	app.directive("loadingButton", loadingButton);
	app.directive("iframeResizer", iframeResizer);
	app.directive("inputPopover", inputPopover);
	app.directive("loadingBar", loadingBar);
	app.directive("fileUpload", fileUpload);
	app.directive("sectionToggle", sectionToggle);
	app.directive("progressDial", progressDial);
	return app;

});
