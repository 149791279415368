define('components/widget/host-shows-events/app',['require','components/widget/host-shows-events/directive/hostShowsEvents','components/widget/host-shows-events/controller/HostShowsEventsController'],function(require) {

    "use strict";

    var hostShowsEvents           = require("components/widget/host-shows-events/directive/hostShowsEvents"),
        HostShowsEventsController = require("components/widget/host-shows-events/controller/HostShowsEventsController");

    var component      = angular.module("mPortal.components.widget.host-shows-events", []);
    component.directive( "hostShowsEvents", hostShowsEvents );
    component.controller( "HostShowsEventsController", HostShowsEventsController );

    return component;

});
