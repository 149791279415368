define('common/service/search/SearchService',['require'],function (require) {
	"use strict";

	/**
	* The backend APIs for search have oddly named params. In an effort to make our controllers more legible, we're using param
	* names that more closely resemble what we're actually searching for (e.g. "productLine" instead of "lob"). Throughout the
	* methods in this service, you'll see calls to RenameKeys.rename. This method translates our legible param names into param
	* names that the backend requires.
	*/

	SearchService.$inject = ["$http", "maverickApiUrl", "archApiUrl", "RenameKeys", "moment", "$q"];

	function SearchService($http, maverickApiUrl, archApiUrl, RenameKeys, moment, $q) {

		var service = {};

		function getSearchResults(method, params, resultContainer) {
			let params_obj = Object.assign({}, params);

			params = $.param(params || {});

			if (resultContainer) {
				resultContainer.status.working = true;
				resultContainer.status.ready = false;
				resultContainer.clearResults();
				resultContainer.resetErrors();
			}
			var searchResult = [];
			if (method === 'getQuickSearchResults') {
				var solrData = [];
				var luxon_data = [];
				var promiseArray = [
					service.getSolrSearchData(method, params)
				];

				if (1 == 2) {
					promiseArray.push(service.getLuxonSearchData(params_obj));
				}

				searchResult = $q.all(promiseArray).then(function (results) {
					var result = [];
					solrData = results[0];
					luxon_data = results[1];

					if (solrData[0].category !== "No results found.") {
						result = solrData;
					}
					else {
						result = service.filterClaimResults(solrData);
					}


					if (luxon_data && luxon_data.count > 0) {
						if (solrData[0].category === "No results found.") {
							result = [];
						}

						for (let i = 0; i < luxon_data.rows.length; i++) {
							let row = luxon_data.rows[i].document;

							row.category = "SUBMISSION";
							row.value = `luxon_${row.id}`;
							row.is_luxon = true;

							result.push(row);
						}
					}


					//Sorting the data to eliminate the possibility of displaying duplicate categories on the result list
					var sortOrder = { "ACCOUNT": 1, "SUBMISSION": 2, "POLICY": 3, "CLAIM": 4 }
					result.sort(function (a, b) {
						return sortOrder[a.category] - sortOrder[b.category];
					});
					return result;
				})
			} else {
				searchResult = $http.post(maverickApiUrl + "search/" + method + "/", params, {
					headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" }
				}).then(function (result) {
					return result.data;
				});
			}

			if (resultContainer) {
				return searchResult.then(function (result) {
					return resultContainer.populate(result);
				});
			} else {
				return searchResult;
			}

		}

		service.filterClaimResults = function (solrData) {
			var filteredList = [];
			if (solrData.length > 0) {
				solrData.each(function (item) {
					if (item.category.toLowerCase() !== 'claim') {
						filteredList.push(item);
					}
				});
				if (filteredList.length === 0) {
					filteredList.push({
						category: "No results found.",
						label: "No relevant results determined, please alter your search criteria.",
						value: ""
					})
				}
				return filteredList;
			}
		}

		service.getQuickSearchResults = function (params) {

			params = RenameKeys.rename(params, [
				{ from: "query", to: "q" },
				{ from: "entities", to: "solrEntities" }
			]);

			/*
			Restrict the records returned to the last 2 years as follows:
			--Policies and submission: based on effectiveDate
			--Claims: based on accidentDate
			*/
			params.minimumRecordDate = moment().startOf("day").subtract(2, "year").format("YYYY-MM-DD");
			// Exclude newly added mpolicy records
			params.isAvailableInPortal = 0;

			return getSearchResults("getQuickSearchResults", params);

		};

		service.getAccountsSearchResults = function (params, resultContainer) {

			params = RenameKeys.rename(params, [
				{ from: "effectiveDateFrom", to: "effDateFrom" },
				{ from: "effectiveDateTo", to: "effDateTo" }
			]);

			return getSearchResults("getAccountsSearchResults", params, resultContainer);

		};

		service.getSubmissionsSearchResults = function (params, resultContainer) {

			params = RenameKeys.rename(params, [
				{ from: "effectiveDateFrom", to: "effDateFrom" },
				{ from: "effectiveDateTo", to: "effDateTo" },
				{ from: "submissionDateFrom", to: "subDateFrom" },
				{ from: "submissionDateTo", to: "subDateTo" },
				{ from: "productLine", to: "lob" },
				{ from: "insuredName", to: "search" }
			]);

			return getSearchResults("getSubmissionsSearchResults", params, resultContainer);

		};

		service.getPoliciesSearchResults = function (params, resultContainer) {

			params = RenameKeys.rename(params, [
				{ from: "effectiveDateFrom", to: "effDateFrom" },
				{ from: "effectiveDateTo", to: "effDateTo" },
				{ from: "productLine", to: "lob" }
			]);

			return getSearchResults("getPoliciesSearchResults", params, resultContainer);

		};

		service.getClaimsSearchResults = function (params, resultContainer) {

			params = RenameKeys.rename(params, [
				{ from: "accidentDateFrom", to: "accDateFrom" },
				{ from: "accidentDateTo", to: "accDateTo" },
				{ from: "program", to: "productLine" }
			]);

			return getSearchResults("getClaimsSearchResults", params, resultContainer);
		};

		service.getOccurrenceSearchResults = function (params, resultContainer) {

			params = RenameKeys.rename(params, [
				{ from: "occurrenceDateFrom", to: "occDateFrom" },
				{ from: "occurrenceDateTo", to: "occDateTo" },
				{ from: "program", to: "productLine" }
			]);

			return getSearchResults("getOccurrenceSearchResults", params, resultContainer);
		};

		service.getClassCodesSearchResults = function (params, resultContainer) {

			params = RenameKeys.rename(params, [
				{ from: "productLine", to: "lob" }
			]);

			return getSearchResults("getClassCodesSearchResults", params, resultContainer);

		};

		service.getSolrSearchData = function (method, params) {
			return $http.post(maverickApiUrl + "search/" + method + "/", params, {
				headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" }
			}).then(function (result) {
				return result.data;
			})
		};

		service.getLuxonSearchData = function (params) {

			return $http.post(window.location.origin + "/lx/search", { "search": "", "insured_name": params.q }).then(function (result) {
				return result.data;
			});
		};

		// returns data used to populate the search forms such as states, licensed agents, etc.
		service.getSearchData = function () {
			return $http.get(maverickApiUrl + "search/getSearch/").then(function (result) {
				return result.data;
			});
		};

		service.getTwoYearView = function (params) {
			params = RenameKeys.rename(params, [
				{ from: "effectiveDateFrom", to: "minEffectiveDate" },
				{ from: "effectiveDateTo", to: "maxEffectiveDate" }
			]);

			return $http.get(archApiUrl + "account/two-year-view", { params: params }).then(function (result) {
				return result.data;
			});
		};

		/*
		 Currently a claims search triggers one of three possible service methods, with each
		 method calling a different API endpoint:
			  --This one for claim occurrences associated with programs supported by mPolicy/ICON
			  --getClaimsSearchResults for WC claims
			  --getOccurrenceSearchResults for BO claim occurrences

		 The hope is that at some point in the near future, a single method/Arch API endpoint
		 will perform the claims search regardless of the program/LOB involved.
		*/
		service.getICONClaimsSearchResults = function (params, resultContainer) {
			params = RenameKeys.rename(params, [
				{ from: "occurrenceDateFrom", to: "dateOfLossStart" },
				{ from: "occurrenceDateTo", to: "dateOfLossEnd" },
				{ from: "insuredAccount", to: "accountId" }
			]);

			resultContainer.status.working = true;
			resultContainer.status.ready = false;
			resultContainer.clearResults();
			resultContainer.resetErrors();

			var deferred = $q.defer();
			getGraphQueryLossRuns(params).then(function (result) {

				if (result && result.length > 0) {
					resultContainer.filteredResults = result;
					resultContainer.results = result;
					resultContainer.status.working = false;
					resultContainer.status.ready = true;
					resultContainer.visible.claims = false;
					resultContainer.visible.maverickOccurrence = false;					
					deferred.resolve(resultContainer);
				}
				else {

					var searchResult = $http.post(archApiUrl + "claim/search", params).then(function (result) {
						return { searchResults: result.data };
					});

					// If a problem with the search occurred, the data returned will be a "messages" array of strings.
					return searchResult.then(function (result) {
						if (result.searchResults.messages) {
							resultContainer.error.message = result.searchResults.messages.join('. ');
							resultContainer.status.working = false;
							deferred.resolve(resultContainer);
						} else {
							resultContainer.populate(result);
							return deferred.resolve(resultContainer);
						}
					});
				}
			});

			return deferred.promise;
		};

		function getGraphQueryLossRuns(params) {
			var searchResult = $http.post(window.location.origin + "/loss-run-api/loss-runs/mportal/", params).then(function (result) {
				return result.data;
			});
			return searchResult;

		}




		/*
		 The following method is a short-term solution given the time and risk constraints
		 involved in adding a status search field for the claims search that provides status
		 options based on claim system and program.

		 In the future, this should be replaced by a method that calls an API endpoint in
		 Arch that provides the status options for the different systems, where the "value"
		 represents the primary key value of the status option, and any "Void" option returned
		 from the full set of status options is omitted (searches for voided claims/occurrences
		 are not permitted per business rules)
		*/
		service.getClaimStatusOptions = function () {
			return {
				maverick: {
					wc: [
						{ "name": "All", "value": "" },
						{ "name": "Notice", "value": "Notice" },
						{ "name": "Deny", "value": "Deny" },
						{ "name": "Open", "value": "Open" },
						{ "name": "Resolved", "value": "Resolved" },
						{ "name": "Closed", "value": "Closed" },
						{ "name": "Re-Opened", "value": "Re-Opened" },
						{ "name": "Re-Closed", "value": "Re-Closed" },
						{ "name": "Incomplete", "value": "Incomplete" },
						{ "name": "Submitted", "value": "Submitted" },
						{ "name": "Pending Closure", "value": "Pending Closure" }
					],
					bo: [
						{ "name": "All", "value": "" },
						{ "name": "Open", "value": "Open" },
						{ "name": "Closed", "value": "Closed" },
						{ "name": "Re-Opened", "value": "Re-Opened" },
						{ "name": "Re-Closed", "value": "Re-Closed" },
						{ "name": "Incomplete", "value": "Incomplete" },
						{ "name": "Submitted", "value": "Submitted" },
						{ "name": "Notice Only", "value": "Notice Only" }
					]
				},
				icon: [
					{ name: "All", value: "" },
					{ name: "Open", value: "Open" },
					{ name: "New", value: "New" },
					{ name: "Closed", value: "Closed" },
					{ name: "Open Rec", value: "Open Rec" }
				]
			}
		};

		return service;

	}

	return SearchService;

});
