define('components/productSearch/list/directive/productSearchList',['require'],function(require) {
	"use strict";

	productSearchList.$inject = [];

	function productSearchList() {
		return {
			restrict: "AE",
            scope: {
                products : "=",
                messages : "="
            },
			templateUrl: "app/components/productSearch/list/template/template.html",
			controller: "productSearchListController"
		};
	}

	return productSearchList;

});
