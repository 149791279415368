define('components/userAccountSettings/userCredentials/app',['require','common/directive/app','components/userAccountSettings/userCredentials/directive/userCredentials','components/userAccountSettings/userCredentials/controller/UserCredentialsController','common/service/MessageContainer','common/service/user/UserService'],function(require) {
	"use strict";

	require("common/directive/app");

	var userCredentials = require("components/userAccountSettings/userCredentials/directive/userCredentials"),
		UserCredentialsController = require("components/userAccountSettings/userCredentials/controller/UserCredentialsController"),
		MessageContainer = require("common/service/MessageContainer"),
		UserService = require("common/service/user/UserService");

	var component = angular.module("mPortal.userAccountSettings.userCredentials", [
		"mPortal.directive"
	]);

	component.directive("userCredentials", userCredentials);
	component.controller("UserCredentialsController", UserCredentialsController);
	component.factory("MessageContainer", MessageContainer);
	component.factory("UserService", UserService );

	return component;

});
