define('components/widget/optional-endorsements/owner-officer-information/directive/ownerOfficerInformation',['require'],function(require) {
    "use strict";

    ownerOfficerInformation.$inject = [];

    function ownerOfficerInformation() {
        return {
            restrict: "AE",
            scope:{
                model	            : "=",
                options             : "=",
                quantityControlId   : "="
            },
            templateUrl: "app/components/widget/optional-endorsements/owner-officer-information/template/template.html",
            controller: "OwnerOfficerInformationController"
        };
    }

    return ownerOfficerInformation;

});
