define('faq/app',['require','shim/overmind'],function(require) {
	"use strict";

	var overmind = require("shim/overmind");

	var app = angular.module("mPortal.faq", []);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {

		$routeProvider.when("/faq", {
			templateUrl: "app/faq/template/template.html"
		});
		
	}]);

	return app;

});
