define('common/service/SurveyService',['require'],function(require) {
	"use strict";

	SurveyService.$inject = ["$http", "maverickApiUrl"];

	function SurveyService( $http, maverickApiUrl ) {

		var service = {};

		service.getSurveys = function() {
			return $http.get( maverickApiUrl + "survey/getSurvey" ).then(function( result ) {
				return result.data;
			});
		};

		return service;

	}

	return SurveyService;

});
