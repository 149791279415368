define('components/activityFeed/directive/activityFeedItem',['require'],function(require) {
	"use strict";

	ActivityFeedItem.$inject = [];

	function ActivityFeedItem() {
		return {
			restrict: "AE",
			scope: {
				item: "=activityFeedItem",
				openMessageModal : "="
			},
			templateUrl: "app/components/activityFeed/template/activityFeedItem.html"
		};
	}

	return ActivityFeedItem;

});
