define('components/policyDetail/policyInfo/directive/policyInfo',['require'],function(require) {
	"use strict";

	function PolicyInfo() {
		return {
			restrict: "AE",
			scope: {
				policy: "=",
				user: "="
			},
			templateUrl: "app/components/policyDetail/policyInfo/template/template.html",
			controller: "PolicyInfoController"
		};
	}

	return PolicyInfo;

});
