define('components/specialtyApplication/questionShellSections/agentDateSelections/directive/agentDateSelections',['require'],function(require) {
    "use strict";

    function AgentDateSelections() {
        return {
            restrict: "AE",
            scope: {
                section: "=",
                store: "=",
                user: "="
            },
            templateUrl: "app/components/specialtyApplication/questionShellSections/agentDateSelections/template/template.html",
            controller: "AgentDateSelectionsController"
        };
    }

    return AgentDateSelections;

});
