define('components/widget/building-optional-endorsements/loss-payable-details/directive/lossPayableDetails',['require'],function(require) {
    "use strict";

    lossPayableDetails.$inject = [];

    function lossPayableDetails() {
        return {
            restrict: "AE",
            scope:{
                model	            : "=",
                options             : "="
            },
            templateUrl: "app/components/widget/building-optional-endorsements/loss-payable-details/template/template.html",
            controller: "LossPayableDetailsController"
        };
    }

    return lossPayableDetails;

});
