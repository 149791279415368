define('components/addAccount/controller/AddAccountController',['require'],function(require) {
	"use strict";

	AddAccountController.$inject = ["$scope", "$modal"];

	function AddAccountController( $scope, $modal ) {

		$scope.open = function() {
			var modalInstance = $modal.open({
				templateUrl: "app/components/addAccount/template/modalTemplate.html",
				controller: "AddAccountModalController",
				resolve: {
					insuredAccountNumber: function(){ return null }
				}
			});

			modalInstance.result.then(function() {
				$scope.onSuccess();
			}).catch(function( err ) {
				// just let it close, this indicates the user closed manually and we
				// did not successfully add an account to their profile.
			});
		};

	}

	return AddAccountController;

});
