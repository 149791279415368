define('components/specialtyApplication/result/directive/result',['require'],function(require) {
	"use strict";

	Result.$inject = [];

	function Result() {
		return {
			restrict: "AE",
			scope: {
				applicationId: "=?"
			},
			templateUrl: "app/components/specialtyApplication/result/template/template.html",
			controller: "SpecialtyApplicationResultController"
		};
	}

	return Result;

});
