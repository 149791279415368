define('components/quickLinks/controller/MakePaymentController',['require'],function(require) {
    "use strict";
 
    MakePaymentController.$inject = ["$scope", "$modalInstance", "SearchService"];
    function MakePaymentController($scope, $modalInstance, SearchService) {  
        $scope.policyQuery = "";
        $scope.selectedPolicy = null;
        $scope.loading = false;
        $scope.noResults = false;
 
        /**
         * Fetches matching policies based on the user's query.
         * @param {string} query - The search term entered by the user.
         * @returns {Promise} - A promise that resolves with an array of matching policies.
         */
        $scope.searchPolicies = function(query) {
            if (!query || query.trim() === "") {
                return [];
            }

            $scope.loading = true;
            $scope.noResults = false;
 
            var params = {
                query: query,
                entities: "policy"
            };

 
            return SearchService.getQuickSearchResults(params)
            .then(function(data) {
                var queryLower = query.toLowerCase();
                var filteredResults = data.filter(function(item) {
                    var policyNumber = (item.IPI_PolicyNumber || "").toLowerCase();
                    return policyNumber.indexOf(queryLower) === 0;
                });
                if (filteredResults.length === 0) {
                    $scope.noResults = true;
                    return [];
                }
                $scope.noResults = false;
                return filteredResults;
                });

        };
 
        $scope.cleanLabel = function(label) {
            if (label && typeof label === 'string') {
                return label.replace(/<\/?[^>]+(>|$)/g, "");
            }
            return '';  
        };

        /**
         * Handles the selection of a policy from the Typeahead dropdown.
         * Redirects the user to the payment page for the selected policy.
         * @param {Object} policy - The selected policy object.
         */
        $scope.onPolicySelect = function(policy, returnTo) {
            $modalInstance.dismiss('close');
            var policyID = policy.value.split("_")[1];
            var returnTo = (typeof returnTo === "undefined") ? window.location.href : returnTo;
            var url = window.maverickRoot + '/webTopTransfer.cfm?applicationID=9&forward=/policymgmt/Policies/Payments/dsp_onlinePayment.cfm?quoteID=' + policyID + '%26returnTo='+encodeURIComponent(returnTo);
            var iFrameHeight = new Number( Math.max($(window).height() - 320, 420) );
            $.skynet.modal.set({
                id:'online-payment-win',
                title:'Make Online Payment',
                content:'<div style="width:1100px;"><iframe id="onlinePayments" height="' + iFrameHeight + '" width="1060" style="margin:0" class="onlinePayments" src="' + url + '"></iframe></div>'
            });
            //This modal works differently than others as it has an iframe. Override settings here to make work nicely in all browsers.
            $('#alert_modal .modal-scroll').css('overflow','hidden');
            $('#alert_modal').css('width', '1100px');
            $.skynet.modal.show('online-payment-win');
            $('#alert_modal .modal-body').css('overflow','hidden');
            if ($(window).height() <= 640) {
                $('#alert_modal').css('height', '557px');
                $('#alert_modal .modal-body').css('height','424px');
            }
            return false;
        };
 
        $scope.close = function() {
            $modalInstance.dismiss('close');
        };
    }
   
    return MakePaymentController;
});
