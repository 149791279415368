define('components/accountSummary/quoteTable/controller/QuoteTableController',['require'],function(require) {
	"use strict";

	QuoteTableController.$inject = ["$scope", "LossRunsService", "moment", "UserProfile", "ResultService"];

	function QuoteTableController( $scope, LossRunsService, moment, UserProfile, ResultService ) {

		$scope.userProfile = UserProfile;
		$scope.outcomeType = ResultService.getResultType( $scope.quote.status );

		$scope.openLossRunsWindow = function() {
			LossRunsService.openLossRunsWindow( $scope.account.piNumber );
		};
		
        $scope.isQuoteEffectiveDateValid = function() {
            if ( moment( $scope.quote.marketedProgramEffectiveDate, "YYYY-MM-DD" ).isSameOrBefore( $scope.quote.effectiveDate, "YYYY-MM-DD" ) )
                return true;
            else
                return false;
        };

        $scope.showViewApplicationLink = function() {
            return $scope.quote.viewApplicationAllowed == 1 && $scope.quote.applicationId && ($scope.user.type == 'Agent' || $scope.user.agencyEmulation) && !($scope.quote.isUploadBasedSubmission) &&
                $scope.isQuoteEffectiveDateValid();
        };

		$scope.isLossRunVisible = function() {
			return $scope.quote.lossRunEnabled && ( $scope.user.type.toLowerCase() == 'agent' || ( $scope.quote.insuredType === 'Direct' && LossRunsService.showInsuredLossRuns( $scope.quote.agencyId ) ) );
		};

		$scope.doesUserTrackBilling = function() {
			return $scope.quote.billType && $scope.quote.billType === ( $scope.user.type === 'Agent' ? $scope.quote.billType : 'Direct bill');
		};

		$scope.isPaymentLinkVisible = function() {
			return $scope.isBillingInfoVisible() && $scope.quote.paymentEnabled && $scope.quote.billingAccountId && $scope.isMergedInsuredEnabled() ;
		};

		$scope.isMergedInsuredEnabled = function (){
		if($scope.account.accountViewVersion === 2) {
			if ($scope.account.primaryAccountId !== null && $scope.account.accountId === $scope.account.primaryAccountId) {
				return true
			} else if ($scope.account.primaryAccountId !== null && $scope.account.accountId !== $scope.account.primaryAccountId) {
				return false
			} else {
				return true
			}
		}
		}

		$scope.isBillingInfoVisible = function() {
			return $scope.quote.isPolicy && $scope.quote.isBillingDataAvailable && $scope.isViewBillingAvailable();
		};

		$scope.isViewBillingAvailable = function() {
			if ( $scope.quote.billType && $scope.user.type !== 'Insured' ) {
				if ( $scope.user.type === 'Internal' && $scope.quote.billType === 'Agency bill' && $scope.user.insuredEmulation ) {
					return false
				}
				return true
			}
			else if ( $scope.user.type === 'Insured' && $scope.quote.billType === 'Direct bill' ) {
				return true
			}
		};

		$scope.isPayPlanVisible = function() {
			return $scope.quote.payPlan && $scope.doesUserTrackBilling();
		};

		$scope.isBillTypeVisible = function() {
			return $scope.quote.billType;
		};
		$scope.isBalanceVisible = function() {
			if( $scope.quote.balance === null || $scope.quote.balance > 0) {
				return $scope.isViewBillingAvailable() && ($scope.quote.balance || $scope.quote.balance === 0);
			}else{
				$scope.quote.balance = 0
				return $scope.isViewBillingAvailable() && ($scope.quote.balance || $scope.quote.balance === 0);

			}
		};

		$scope.isCurrentDueVisible = function() {
			if($scope.quote.balance === null || $scope.quote.currentDue > 0 ) {
				return $scope.isViewBillingAvailable() && ($scope.quote.currentDue || $scope.quote.currentDue === 0);
			}else{
				$scope.quote.currentDue = 0
				return $scope.isViewBillingAvailable() && ($scope.quote.currentDue || $scope.quote.currentDue === 0);
			}
		};


		$scope.isRenewalDepositDueVisible = function() {
			return $scope.isViewBillingAvailable() && ($scope.quote.renewalDepositDue || $scope.quote.renewalDepositDue === 0);
		};

		$scope.getLossRunLinkText = function() {
			return $scope.user.type.toLowerCase() === "insured"?"Pull Loss Report":"Pull Loss Runs";
		};

		$scope.canViewPolicyDocuments = function() {
			return $scope.quote.isPolicy && $scope.quote.canDisplayDocuments;
		};

		$scope.isPolicyChangeRequestVisible = function() {
            var isExpired = moment( $scope.quote.expirationDate, "MM/DD/YYYY" ).isBefore( Date.now() );
            return $scope.quote.isPolicy && $scope.user.type === 'Agent' && !isExpired && ($scope.quote.status !== 'Cancelled');
        };

        $scope.isPolicyChangeRequestVisibleForInsured = function() {
            var isExpired = moment( $scope.quote.expirationDate, "MM/DD/YYYY" ).isBefore( Date.now() );
            return $scope.quote.isPolicy && ($scope.user.type === 'Insured' && $scope.quote.insuredType === 'Direct') && !isExpired && ($scope.quote.status !== 'Cancelled');
        };

		$scope.isSubmissionChangeRequestVisible = function() {
			return !$scope.quote.isPolicy && !$scope.quote.canEdit;
		};

		$scope.canViewSubmissionDocuments = function() {
			return !$scope.quote.isPolicy && $scope.quote.submissionId && $scope.quote.accountId && [ "Bound", "Closed", "Declined", "Quoted", "Submitted", "Lost" ].indexOf( $scope.quote.status ) > -1
		};

		$scope.canViewResult = function() {
			return $scope.quote.isQuoteAvailable || ( $scope.quote.applicationId && ( $scope.quote.status === "Declined" || $scope.quote.status === "Submitted" )  )
		};

		$scope.canAlterDocumentDeliveryPreferences = function() {
			return $scope.quote.canEditDeliveryPreferences && ($scope.userProfile.type === 'Agent' || $scope.userProfile.type === 'Insured') ;
		}
	}

	return QuoteTableController;

});
