define( 'components/claimDetail/claimDetail/app',['require','components/claimDetail/claimDetail/controller/ClaimDetailController','common/service/specialtyApplication/AccountService','common/directive/app','components/claimDetail/claimInfo/app','components/claimDetail/claimDetail/directive/claimDetail'],function( require ) {
    "use strict";

    var ClaimDetailController = require( "components/claimDetail/claimDetail/controller/ClaimDetailController"),
        AccountService = require( "common/service/specialtyApplication/AccountService" );

    require( "common/directive/app" );
    require( "components/claimDetail/claimInfo/app" );

    var claimDetail = require( "components/claimDetail/claimDetail/directive/claimDetail" );

    var component = angular.module( "mPortal.claimDetail.claimDetail", [
        "mPortal.directive",
        "mPortal.claimDetail.claimInfo"
    ]);

    component.directive( "claimDetail", claimDetail );
    component.controller( "ClaimDetailController", ClaimDetailController);
    component.factory( "AccountService", AccountService);

    return component;

});
