define( 'components/policyDetail/policyInfo/controller/PolicyInfoController',['require'],function ( require ) {
	"use strict";

	PolicyInfoController.$inject = [ "$scope", "AgencyService" ];

	function PolicyInfoController( $scope, AgencyService ) {

		$scope.uiState = {
			additionalData: false,
			loadingData: false
		};

		if( $scope.user.type == 'Insured' && $scope.policy.agencyId && AgencyService.showAgencyInfo( $scope.policy.agencyId ) ) {
			$scope.uiState.loadingData = true;
			$scope.uiState.additionalData = true;
			AgencyService.getAgency( "ICON", $scope.policy.agencyId ).then( function( agency ) {
				$scope.agency = agency;
				$scope.uiState.loadingData = false;
			}).catch(function( err ) {
				$scope.uiState.loadingData = false;
			});
		}

		$scope.isBillTypeVisible = function () {
			return true;
		}

		$scope.isBalanceVisible = function () {
			return $scope.policy.billType === ( $scope.user.type === 'Agent' ? $scope.policy.billType : 'Direct bill');
		}

	}

	return PolicyInfoController;

} );
;
