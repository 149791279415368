define('common/service/RewardsService',['require'],function(require) {
	"use strict";

	RewardsService.$inject = ["$http", "maverickApiUrl"];

	function RewardsService( $http, maverickApiUrl ) {

		var service = {};

		service.getRewardsSummary = function() {
			return $http.get( maverickApiUrl + "rewards/getSummary" ).then(function( result ) {
				return result.data;
			});
		};

		return service;

	}

	return RewardsService;

});
