define('components/twoYearView/accountTable/controller/AccountTableController',['require'],function(require) {
	"use strict";

	AccountTableController.$inject = ["$scope"];

	function AccountTableController( $scope ) {

		$scope.uiState = {
			detailsHidden: true
		};

		$scope.toggleDetails = function() {
			$scope.uiState.detailsHidden = !$scope.uiState.detailsHidden;
		};

	}

	return AccountTableController;

});
