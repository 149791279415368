define('components/widget/optional-endorsements/additional-information-location/app',['require','components/widget/optional-endorsements/additional-information-location/directive/additionalInformationLocation','components/widget/optional-endorsements/additional-information-location/controller/AdditionalInformationLocationController'],function(require) {
	"use strict";

	var additionalInformationLocation 		= require("components/widget/optional-endorsements/additional-information-location/directive/additionalInformationLocation"),
			AdditionalInformationLocationController = require("components/widget/optional-endorsements/additional-information-location/controller/AdditionalInformationLocationController");

	var component 				                = angular.module("mPortal.components.widget.optional-endorsements.additional-information-location", []);
	component.directive( "additionalInformationLocation", additionalInformationLocation );
	component.controller( "AdditionalInformationLocationController", AdditionalInformationLocationController );

	return component;

});
