define('footer/app',['require','common/config/config','shim/overmind','footer/controller/FooterController','common/service/NavService','common/service/user/UserProfile'],function(require) {
	"use strict";

	require("common/config/config");

	var overmind = require("shim/overmind"),
		FooterController = require("footer/controller/FooterController"),
		NavService = require("common/service/NavService"),
		UserProfile = require("common/service/user/UserProfile");

	var app = angular.module("mPortal.footer", [
		"mPortal.config"
	]);

	app.config( overmind.control() );
	app.controller("FooterController", FooterController);
	app.factory("NavService", NavService);
	app.factory("UserProfile", UserProfile);
	
	return app;

});
