define('shortLink/controller/ShortLinkController',['require'],function(require) {
	"use strict";

	ShortLinkController.$inject = ["$scope", "$routeParams", "$location", "$q", "routes", "ShortLinkService", "UserProfile", "UserService"];

	function ShortLinkController( $scope, $routeParams, $location, $q, routes, ShortLinkService, UserProfile, UserService ) {
		ShortLinkService.verify( $routeParams.id, $routeParams.token ).then(function( result ) {
			var route = routes[ result.route ];

			if( !route ) {
				return $q.reject();
			}

			if( result.cobranding ) {
				UserProfile.set("cobranding", result.cobranding);
			}

			UserProfile.set("shortLinkParams", result.parameters);

			$location.path( route );

		}).catch(function( err ) {
			UserService.getUserProfile().then( function( profile ) {
				if( profile.isLoggedIn ) {
					$location.path( "/error/agent/404" );
				} else {
					$location.path( "/error/404" );
				}
			});
		});
	}

	return ShortLinkController;

});
