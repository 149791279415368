define('common/service/productSearch/FilterService',['require'],function(require) {
	"use strict";

	FilterService.$inject = ["$http","$sce","archApiUrl"];

	function FilterService( $http, $sce, archApiUrl) {

		var service = { filters : { source: [] } };
		

        service.validate = function(filter){

            filter.invalid = false;

            if(!filter.hidden)
            {
                if(filter.type.display == 'autocomplete')
                {
                    if(filter.display_value != null && filter.display_value.length == 0)
                    {
                        filter.value = '';
                    }
                }

                if(filter.required)
                {
                    if(filter.value == null)
                    {
                        filter.invalid = true;
                    }

                    if( !filter.invalid && filter.value.length == 0  )
                    {
                        filter.invalid = true;
                    }
                }
            }

            return filter;
        };

        service.calendar_click = function(e)
        {
            var btn = angular.element(e.currentTarget);
            var input = btn.closest('.filter').find('input');

                input.focus();

            return false;
        }


        service.open_dropdown = function(e,filter){
            var btn = angular.element(e.currentTarget);
            var input = btn.closest('.filter').find('input');

            if(!input.data('autocomplete') != undefined)
            {
                if(!input.data('autocomplete').widget().is(':visible'))
                {
                    if(e.type == 'click')input.autocomplete('search','');
                    if(e.type == 'focusin')
                    {
                        var input_val = (input.val().length == 0)?' ':input.val();

                        input.autocomplete('search',input_val);
                    }
                }
                else
                {
                    if(e.type != 'focusout' && e.type !='focusin')input.autocomplete('close');
                }
            }
            
            return false;
        };

        service.set_value = function(e,selected)
        {
            var input = $(e.target);
            var filterID = input.attr('filter-id');
                input.val(selected.item.label);
                
            //ok lame, but I can't make the autocomplete play nice
            for(var i = 0; i < service.filters.source.length ; i++)
            {
                var filter = service.filters.source[i];

                if(filter.id == filterID)
                {
                   filter.value = selected.item.value;
                   filter.display_value = selected.item.label;

                    for(var j = 0; j < filter.options.length; j++)
                    {
                        var option = filter.options[j];

                        if(option.id == selected.item.id)
                        {
                            option.selected = true;
                        }
                        else
                        {
                            option.selected = false;
                        }
                    }
                   break;
                }
            }

            input.change();

            if(filter)service.validate(filter);
            return false;
        };
	
        service.option_click = function(e,filter,option){
            var target  = e.currentTarget;

            switch(filter.type.display)
            {
                case 'single-select':
                    var option_id = option.id;

                    if(filter.options.length <= 2)
                    {
                        option.selected = !option.selected;

                        //toggle select and select other option
                        for(var i=0; i < filter.options.length; i++)
                        {
                            var sub_option = filter.options[i];

                            if(sub_option.id != option_id)
                            {
                                sub_option.selected = !option.selected;
                            }
                        } 
                    }
                    else
                    {
                        if(!option.selected)
                        {
                            option.selected = true;
                        
                            //toggle select and select other option
                            for(var i=0; i < filter.options.length; i++)
                            {
                                var sub_option = filter.options[i];

                                if(sub_option.id != option_id)
                                {
                                    sub_option.selected = !option.selected;
                                }
                            } 
                        }
                    }
                    
                    
                break;

                case 'multi-select':
                    option.selected = !option.selected;
                break;
            }
            
            //set filter value based on selected options
            var value_arr = [];

            for(var i=0; i < filter.options.length; i++)
            {
                var option = filter.options[i];

                    if(option.selected)
                    {
                        value_arr.push(option.value);
                    }
            }

            filter.value = value_arr.join();

            service.validate(filter);

            return false;
        };

        
        service.slider_update = function(e,filter){
            var min    = filter.min;
            var max    = filter.max;
            var value  = filter.value;
            var step   = filter.step;
            var percent = (value / max) * 100;

            filter.percent = percent; 
            //console.log(percent, max , min , value,step);
        }



        service.get_filter_data = function()
        {
            var filter_data         = {};

            //put our filter data into an easy to use structure;
            for(var i = 0; i < service.filters.source.length; i++)
            {
                var filter = service.filters.source[i];

                if(filter.codekey)filter_data[filter.codekey.toLowerCase()] = filter.value;

                filter_data[filter.id] = filter.value;
            }

            return filter_data;
        };

        service.filters_visible = function(type){
            var visible = false;

            for(var x = 0; x < service.filters[type].length ; x++)
            {
                var filter = service.filters[type][x];

                if(filter.visible)
                {
                    visible = true;
                    break;
                }
            }

            return visible;
        };

        service.filters_required_completed = function(type){
            var completed = true;

            for(var x = 0; x < service.filters[type].length ; x++)
            {
                var filter = service.filters[type][x];

                if ( filter.required == 1 && ( filter.value == null || filter.value == '' || ( filter.type.display == 'slider' && filter.value == 0 ) ) && filter.visible ) {
                    completed = false;
                    break;
                }
            }

            return completed;
        };

        service.validate_filters = function(){
            var filters_valid = true;

            //call validate on each required filter
             for(var i=0; i < service.filters.required.length;i++)
            {
                var filter = service.filters.required[i];

                service.validate(filter);
            }

            //check if filters valid
            for(var i=0; i < service.filters.required.length;i++)
            {
                var filter = service.filters.required[i];

                if(filter.invalid && filter.visible)
                {
                    filters_valid = false;
                    break;
                }
            }

            return filters_valid;
        }


		return service;

	}

	return FilterService;

});
