define( 'components/claimDetail/claimInfo/app',['require','components/claimDetail/claimInfo/directive/claimInfo','common/filter/formatMoney'],function( require ) {
    "use strict";

    var claimInfo = require( "components/claimDetail/claimInfo/directive/claimInfo" ),
        formatMoney = require( "common/filter/formatMoney" );

    var component = angular.module( "mPortal.claimDetail.claimInfo", [] );

    component.directive( "claimInfo", claimInfo );
    component.filter( "formatMoney", formatMoney );
    return component;

});

