define( 'components/specialtyApplication/pricingDetails/directive/pricingDetails',['require'],function( require ) {
    "use strict";

    PricingDetails.$inject = [];

    function PricingDetails() {
        return {
            restrict: "AE",
            scope: {
                program: "=",
                pricingData: "="
            },
            templateUrl: "app/components/specialtyApplication/pricingDetails/template/template.html"
        };
    }

    return PricingDetails;

});
