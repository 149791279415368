define('components/widget/field-group-table/app',['require','components/widget/field-group-table/directive/fieldGroupTable'],function(require) {
    "use strict";

    var fieldGroupTable 		= require("components/widget/field-group-table/directive/fieldGroupTable");
    var component 				= angular.module("mPortal.components.widget.field-group-table", []);
    component.directive( "fieldGroupTable", fieldGroupTable );

    return component;

});
