define('components/surveyList/app',['require','common/service/SurveyService','components/surveyList/directive/surveyList','components/surveyList/controller/SurveyListController'],function(require) {
	"use strict";

	var SurveyService = require("common/service/SurveyService"),
		surveyList = require("components/surveyList/directive/surveyList"),
		SurveyListController = require("components/surveyList/controller/SurveyListController");

	var component = angular.module("mPortal.components.surveyList", []);

	component.factory("SurveyService", SurveyService);
	component.directive("surveyList", surveyList);
	component.controller("SurveyListController", SurveyListController);

	return component;

});
