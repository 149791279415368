define('components/activityFeed/directive/activityFeed',['require'],function(require) {
	"use strict";

	ActivityFeed.$inject = [];

	function ActivityFeed() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/activityFeed/template/template.html",
			controller: "ActivityFeedController"
		};
	}

	return ActivityFeed;

});
