define('support/app',['require','angular-sanitize','common/config/config','shim/overmind','support/controller/SupportController'],function(require) {
	"use strict";

	require("angular-sanitize");
	require("common/config/config");	

	var overmind = require("shim/overmind"),
		SupportController = require("support/controller/SupportController");

	var app = angular.module("mPortal.support", [
		"ngSanitize",
		"mPortal.config"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider.when("/support/", {
			templateUrl: "app/support/template/template.html",
			controller: "SupportController"
		});
	}]);

	app.controller("SupportController", SupportController);

	return app;

});
