define('components/specialtyApplication/retrieveQuote/directive/retrieveQuote',['require'],function(require) {
	"use strict";

	RetrieveQuote.$inject = [];

	function RetrieveQuote() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/specialtyApplication/retrieveQuote/template/template.html",
			controller: "SpecialtyApplicationRetrieveQuoteController"
		};
	}

	return RetrieveQuote;

});
