define('components/specialtyApplication/questions/config/widgets/HorseSummaryWidget',['require'],function(require){

    "use strict";

    var widget={};

    widget.getStructure = function(){
        return {

           "AnimalInfo"   : { wrapper: ["Animal-info-wrapper"], fieldGroup: {
                "Horse Num PHO"         :{ wrapper: ["hideGroup"],orderNumber: 1, data: { saveBroadcast: true }},
                "Horse Name"            :{ wrapper: ["AnimalInfoDataPointName", "AnimalInfoRowErrorName"],orderNumber: 2, data: { savePointOnly: true }, wrapperOverride: true},
                "Horse Age"             :{ wrapper: ["AnimalInfoDataPointBirthYear", "AnimalInfoRowErrorBirthYear"],orderNumber: 3, data: { savePointOnly: true }, wrapperOverride: true},
                "Horse Gender"          :{ wrapper: ["AnimalInfoDataPointGender", "AnimalInfoRowErrorGender"],orderNumber: 4, data: { savePointOnly: true }, wrapperOverride: true},
                "Horse Breed"           :{ wrapper: ["AnimalInfoDataPointBreed", "AnimalInfoRowErrorBreed"],orderNumber: 5, data: { savePointOnly: false }, wrapperOverride: true},
                "Horse Use"             :{ wrapper: ["AnimalInfoDataPointUse", "AnimalInfoRowErrorUse"],orderNumber: 6, data: { savePointOnly: false }, wrapperOverride: true},
                "Horse Ownership"       :{ wrapper: ["AnimalInfoDataPointOwnership", "AnimalInfoRowErrorOwnership"],orderNumber: 7, data: { savePointOnly: true }, wrapperOverride: true},
                "Ownership Percentage"  :{ wrapper: ["AnimalInfoDataPointPercentage", "AnimalInfoRowErrorPercentage"],orderNumber: 8, data: { savePointOnly: true }, wrapperOverride: true}
                }
            }

        }
    };
    widget.configureFormly = function(formlyConfig, formlyApiCheck){

        formlyConfig.setWrapper({
           name: "Animal-info-wrapper",
           template: '<div  summary-of-equines  model="model" options="options" quantity-control-id="\'horseNumPho\'"><formly-transclude></formly-transclude></div>'
       });

       formlyConfig.setWrapper({
            name: "AnimalInfoDataPointName",
            template: '<div class="AnimalInfoDataPointName">' +
           '<formly-transclude></formly-transclude>' + '</div>'
       });

        formlyConfig.setWrapper({
            name: "AnimalInfoDataPointBirthYear",
            template: '<div class="AnimalInfoDataPointBirthYear">' +
            '<formly-transclude></formly-transclude>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "AnimalInfoDataPointGender",
            template: '<div class="AnimalInfoDataPointGender">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "AnimalInfoDataPointBreed",
            template: '<div class="AnimalInfoDataPointBreed">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "AnimalInfoDataPointUse",
            template: '<div class="AnimalInfoDataPointUse">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "AnimalInfoDataPointOwnership",
            template: '<div class="AnimalInfoDataPointOwnership">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

       formlyConfig.setWrapper({
            name: "AnimalInfoDataPointPercentage",
            template: '<div class="AnimalInfoDataPointPercentage">' +
            '<formly-transclude></formly-transclude>' + '</div>'
       });


        formlyConfig.setWrapper({
            name: "AnimalInfoRowErrorName",
            template:
            '<div class="span1 form-group AnimalInfoRowErrorName" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="AIErrorName">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "AnimalInfoRowErrorBirthYear",
            template:
            '<div class="span1 form-group AnimalInfoRowErrorBirthYear" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="AIErrorBirthYear">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "AnimalInfoRowErrorGender",
            template:
            '<div class="span1 form-group AnimalInfoRowErrorGender" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="AIErrorGender">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "AnimalInfoRowErrorBreed",
            template:
            '<div class="span1 form-group AnimalInfoRowErrorBreed" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="AIErrorBreed">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "AnimalInfoRowErrorUse",
            template:
            '<div class="span1 form-group AnimalInfoRowErrorUse" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="AIErrorUse">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "AnimalInfoRowErrorOwnership",
            template:
            '<div class="span1 form-group AnimalInfoRowErrorOwnership" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="AIErrorOwnership">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "AnimalInfoRowErrorPercentage",
            template:
            '<div class="span1 form-group AnimalInfoRowErrorPercentage" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="AIErrorPercentage">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });

    };
    return widget;
});
