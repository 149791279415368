define('components/applicationData/applicationData/config/genericFormlyConfiguration',['require'],function(require) {
    "use strict";

    return function( formlyConfig, formlyApiCheck ) {

        // Regular labels will only be displayed if they have a widgetReference value
        formlyConfig.setType({
            name: "viewLabel",
            template: '<div ng-if="options.data.widgetReference" class="viewItem labelDefault" ng-class="options.templateOptions.style.className" style={{options.templateOptions.style.style}} ng-bind-html="options.templateOptions.label | trustHtml"></div>',
            defaultOptions: {
                noFormControl: true
            }
        });

        formlyConfig.setType({
            name: "viewLabelWithBullet",
            template: '<div class="viewItem labelDefault" ng-if="to.label">' +
            '<ul><li ng-class="options.templateOptions.style.className" style={{options.templateOptions.style.style}}>{{::to.label}}</li></ul>' +
            '</div>',
            defaultOptions: {
                noFormControl: true
            }
        });

        formlyConfig.setType({
            name: "viewData",
            template:
                '<div class="viewItem" ng-if="!options.data.hide">' +
                    '<div class="row-fluid">' +
                        '<div class="span6">' +
                            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
                            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
                        '</div>' +
                        '<div class="span6">' +
                            '{{::options.defaultValue}}' +
                        '</div>' +
                    '</div>' +
                '</div>',

            defaultOptions: {
                noFormControl: true
            }
        });

        // Checkbox datapoints are usually used to include/opt-into policy features, so excluded(false) checkboxes are not
        // shown, and only the label (not the truthy/falsy value) is displayed.  If you have a checkbox datapoint that needs
        // to behave differently, override the type on the datapoint with widget configuration
        formlyConfig.setType({
            name: "viewCheckbox",
            template:
                '<div class="viewItem" ng-if="!options.data.hide && options.defaultValue">' +
                    '<div class="row-fluid">' +
                        '<div class="span6">' +
                            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
                         '</div>' +
                    '</div>' +
                '</div>',
            defaultOptions: {
                noFormControl: true
            }
        });
    };

});
