define('components/userAccountSettings/ciamAccountSettings/directive/ciamAccountSettings',['require'],function(require) {
	"use strict";

	function CiamAccountSettings() {
		return {
			restrict: "AE",
			scope: {
				user: '=',
				showKey: '='
			},
			templateUrl: "app/components/userAccountSettings/ciamAccountSettings/template/template.html",
			controller: "CiamAccountSettingsController"
		};
	}

	return CiamAccountSettings;

});
