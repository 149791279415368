define('components/widget/host-shows-events/directive/hostShowsEvents',['require'],function(require){

   "use strict";

    hostShowsEvents.$inject = [];
     function hostShowsEvents(){

         return{

             restrict : "AE",
             scope: {
                 model                : "=",
                 options              : "=",
                 quantityControlId    : "="
             },
             templateUrl: "app/components/widget/host-shows-events/template/template.html",
             controller: "HostShowsEventsController"

         };
     }
     return hostShowsEvents
});


