define('components/specialtyApplication/questions/config/widgets/verisk/veriskController',['require'],function(require) {
	"use strict";

	VeriskController.$inject = ["$scope", "$modal", "VeriskService", "SubmissionStore"];

	function VeriskController( $scope, $modal, VeriskService, SubmissionStore ) {

        $scope.store = SubmissionStore;

        $scope.overlayShow = true;

        const fields = $scope.fields;

        let has_property = false;
        
        var applicationdata = fields.find(x=>x.type == "verisk_widget").data;
        var submissionId = "";

        if(applicationdata)
        {
            submissionId = applicationdata.applications[0].submissionId;
        }

        const risk_data = {   
            "submission":
                {
                    "street":"",
                    "city":"",
                    "state":"",
                    "zip":""                            
                },
            "calling_system":"mportal",
            "UUID": submissionId,
            "risksectionID":""
        };

        if (fields.length > 0) {
            for (let x = 0; x < fields.length; x++) {
                
                const field_item = fields[x];

                if (field_item.templateOptions) {
                    if (field_item.templateOptions.label === "Is property coverage needed for this location?") {
                        if (field_item.value() === "Yes") {
                            has_property = true;
                        } else if (field_item.value() === "No") {
                            has_property = false;
                        }
                    }
                }                

                if (field_item.data.name === "LocationDataPoint") {
                    
                    for (let i = 0; i < field_item.fieldGroup.length; i++) {
    
                        switch (field_item.fieldGroup[i].templateOptions.label) {
                            case "Address":
                                risk_data.submission.street = field_item.fieldGroup[i].defaultValue;
                                break;
                            case "City":
                                risk_data.submission.city = field_item.fieldGroup[i].defaultValue;
                                break;
                            case "State":
                                risk_data.submission.state = field_item.fieldGroup[i].defaultValue;
                                break;
                            case "Zip code":
                                risk_data.submission.zip = field_item.fieldGroup[i].defaultValue;
                                break;
                            default:
                                break;
                        }
    
                    }
                    
                }           
    
            }
        }

        var has_requirements = true;

        for (var item in risk_data.submission) {
            if (risk_data.submission[item].length === 0) {
                has_requirements = false;
                break;
            }
        }

        if (has_requirements && has_property) {

            if ($scope.store.programCode === "GenPackage") {

                VeriskService.getRisks(risk_data).then(function( result ) {                    

                    $scope.riskres = result;

                    if (!result.Risks || result.Risks.length === 0) {
                        $scope.overlayShow = false;
                    } else if (result.Risks && result.Risks.length > 0) {
                        $modal.open({
                            templateUrl: "app/components/specialtyApplication/questions/config/widgets/verisk/modal-template.html",
                            controller: "VeriskModalController",
                            scope: $scope,
                            windowClass: "verisk-risk-modal",
                            resolve: {
                                resBody: function(){ return risk_data },
                                riskData: function(){ return result }
                            }
                        });

                        $scope.overlayShow = false;
                    }
                });

            } else {
                $scope.overlayShow = false;
            } 
            
        } else {
            $scope.overlayShow = false;
        }
        
	}

	return VeriskController;
});
