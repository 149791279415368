define('common/service/wizard/Wizard',['require'],function(require) {
	"use strict";

	WizardFactory.$inject = ["$rootScope", "$window"];

	function WizardFactory( $rootScope, $window ) {
		var wizard = {};

		$window.rootScopes = $window.rootScopes || [];
		$window.rootScopes.push( $rootScope );

		if( $window.quote_wizard ) {
			return $window.quote_wizard;
		}

		wizard.create = function()
		{
			//create the initial wizard object
			wizard.data = { arr: [] , entries: {} };
			
			//store our new wizard object
			localStorage.setItem('mportal-wizard',JSON.stringify(wizard.data));
			
			return wizard;
		}

		wizard.get = function()
		{
			if(localStorage.getItem('mportal-wizard'))
			{
				wizard.data = JSON.parse(localStorage.getItem('mportal-wizard'));
			}
			else
			{
				wizard.create();
				wizard.set({insured : null , data : {}, maverick : { arr:[] }, luxon : { arr:[] }, mpolicy: { arr:[]}, mol:{ arr:[]} , transaction : null , step:0 , products:{ arr: [] }, mPolicySkip: false });				
			}
		}

		wizard.set = function(obj)
		{
			if(!obj.id)obj.id = 'id-' + wizard.data.arr.length;
			
			//set the wizard object
			if(!wizard.data.entries[obj.id])wizard.data.arr.push(obj.id);
			wizard.data.entries[obj.id] = obj;

			//store our new wizard object
			localStorage.setItem('mportal-wizard',JSON.stringify(wizard.data));
		}

		wizard.reset = function()
		{
			localStorage.removeItem("mportal-wizard");
			wizard.get();
		}

		wizard.systems = function()
		{
			return { 
						magency : wizard.data.entries[wizard.data.arr[0]].maverick.arr.length, 
					 	mol :     wizard.data.entries[wizard.data.arr[0]].mol.arr.length , 
					 	mpolicy : wizard.data.entries[wizard.data.arr[0]].mpolicy.arr.length,
					 	luxon : wizard.data.entries[wizard.data.arr[0]].luxon.arr.length 
					};
		}

		wizard.visible = function()
		{
			var magency = wizard.data.entries[wizard.data.arr[0]].maverick.arr.length;
			var mpolicy = wizard.data.entries[wizard.data.arr[0]].mpolicy.arr.length;
			var mol     = wizard.data.entries[wizard.data.arr[0]].mol.arr.length;

			return (magency && mpolicy || magency && mol || mpolicy && mol);
		}

		wizard.get();
		$window.quote_wizard =  wizard;

		return $window.quote_wizard;

	}

	return WizardFactory;

});
