define( 'components/policyDetail/policyDocuments/controller/PolicyDocumentsController',['require'],function ( require ) {
	"use strict";

	PolicyDocumentsController.$inject = ["$scope", "moment", "SpecialtyDocumentService"];

	function PolicyDocumentsController( $scope, moment, SpecialtyDocumentService ) {

		$scope.uiState = {
			loading: true,
			error: false,
			visible: true
		};

		// the date filter is a single option that encompasses both the start and end date for filtering. i didn't want to go through
		// the effort of calculating each option every time we load the controller because it's unlikely the user will be hitting every
		// single one of these options every time they view their documents. instead, i decided to encapsulate the start and end date
		// logic into method attached to each option. these are used in the filterDocuments method to calculate the start/end date for
		// the selected option on the fly. the one exception is the "All" option, which just means that we have no date filter to apply.
		$scope.filterDateOptions = [
		{ label: "All" },
			{
				label: "Today",
				start: function() { return moment().startOf("day"); },
				end: function() { return moment().endOf("day"); }
			},
			{
				label: "Yesterday",
				start: function() { return moment().startOf("day").subtract( 1, "day" ); },
				end: function() { return moment().endOf("day").subtract( 1, "day" ); },
			},
			{
				label: "Last 6 Hours",
				start: function() { return moment().startOf("hour").subtract( 6, "hour" ); },
				end: function() { return moment().endOf("hour"); }
			},
			{
				label: "Last Week",
				start: function() { return moment().startOf("week").subtract( 1, "week" ); },
				end: function() { return moment().endOf("week").subtract( 1, "week" ); }
			},
			{
				label: "This Week",
				start: function() { return moment().startOf("week"); },
				end: function() { return moment().endOf("week"); }
			},
			{
				label: "Last Month",
				start: function() { return moment().subtract( 1, "month" ).startOf("month"); },
				end: function() { return moment().subtract( 1, "month" ).endOf("month"); }
			},
			{
				label: "This Month",
				start: function() { return moment().startOf("month"); },
				end: function() { return moment().endOf("month"); }
			},
			{
				label: "Last Year",
				start: function() { return moment().subtract( 1, "year" ).startOf("year"); },
				end: function() { return moment().subtract( 1, "year" ).endOf("year"); },
			},
			{
				label: "This Year",
				start: function() { return moment().startOf("year"); },
				end: function() { return moment().endOf("year"); }
			}
		];

		$scope.filter = {
			date: $scope.filterDateOptions[ 0 ],
			description: ""
		};

		$scope.toggleVisibility = function() {
			$scope.uiState.visible = !$scope.uiState.visible;
		};

		$scope.filterDocuments = function() {
			var date = $scope.filter.date,
					description = $scope.filter.description,
					docs = $scope.allDocuments;

			if( date.label !== "All" ) {
				docs = docs.filter(function( doc ) {
					var documentDate = moment( doc.date, "YYYY-MM-DDTHH:mm:ssZ" );
					return documentDate.isAfter( date.start() ) && documentDate.isBefore( date.end() );
				});
			}

			if( description.length ) {
				docs = docs.filter(function( doc ) {
					return doc.description.toLowerCase().indexOf( description.toLowerCase() ) !== -1;
				});
			}

			$scope.visibleDocuments = docs;
		};

		$scope.getDocument = function( document ) {
			if ( $scope.policyId ) {
				SpecialtyDocumentService.getPolicyDocument( document );
			}

			if ( $scope.submissionId ) {
				SpecialtyDocumentService.getSubmissionDocument( document );
			}
		}

		if( $scope.submissionId ) {
			SpecialtyDocumentService.getSubmissionDocuments( $scope.accountId, $scope.submissionId ).then( function ( outcome ) {
				$scope.retrievalError = outcome.error;
				$scope.allDocuments = outcome.docs;
				$scope.visibleDocuments = $scope.allDocuments;
				$scope.uiState.loading = false;
			} );
		}

		if ( $scope.policyId ) {
			SpecialtyDocumentService.getPolicyDocuments( $scope.accountId, $scope.policyId ).then( function ( outcome ) {
				$scope.retrievalError = outcome.error;
				$scope.allDocuments = outcome.docs;
				$scope.visibleDocuments = $scope.allDocuments;
				$scope.uiState.loading = false;
			});
		}

	}

	return PolicyDocumentsController;

});
