define('components/paymentLink/directive/paymentLink',['require'],function(require) {
	"use strict";

	function PaymentLink() {
		return {
			restrict: "AE",
			scope: {
				accountId: "=",
				billingSystem: "="
			},
			templateUrl: "app/components/paymentLink/template/template.html",
			controller: "PaymentLinkController"
		};
	}

	return PaymentLink;

});
