define('components/lossRunReport/app',['require','components/lossRunReport/directive/lossRunReport','components/lossRunReport/controller/LossRunReportController','common/service/ReportService','common/service/util/DateService','moment','common/service/user/UserProfile'],function(require) {
	"use strict";

	var lossRunReport = require("components/lossRunReport/directive/lossRunReport"),
		LossRunReportController = require("components/lossRunReport/controller/LossRunReportController"),
		ReportService = require("common/service/ReportService"),
		DateService = require("common/service/util/DateService"),
		moment = require("moment"),
		UserProfile = require("common/service/user/UserProfile");

	var component = angular.module("mPortal.components.lossRunReport", []);

	component.directive("lossRunReport", lossRunReport);
	component.controller("LossRunReportController", LossRunReportController);
	component.factory("ReportService", ReportService);
	component.factory("UserProfile", UserProfile);
	component.value("moment", moment);

	return component;

});
