define('common/service/NewsService',['require'],function(require) {
	"use strict";

	NewsService.$inject = ["$http", "maverickApiUrl"];

	function NewsService( $http, maverickApiUrl ) {

		var service = {};

		service.getNews = function() {
			return $http.get( maverickApiUrl + "office/getNews" ).then(function( result ) {
				return result.data;
			});
		};

		service.getNewsArchives = function() {
			return $http.get( maverickApiUrl + "office/getNewsArchives" ).then(function( result ) {
				return result.data;
			});
		};

		return service;

	}

	return NewsService;

});
