define('components/startQuoteDropdown/directive/startQuoteDropdown',['require'],function(require) {
	"use strict";

	StartQuoteDropdown.$inject = [];

	function StartQuoteDropdown() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/startQuoteDropdown/template/template.html",
			controller: "StartQuoteDropdownController"
		};
	}

	return StartQuoteDropdown;

});
