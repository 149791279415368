define('common/service/NavService',['require'],function(require) {
	"use strict";

	NavService.$inject = ["$location"];

	function NavService( $location ) {

		var service = {};

		service.isActive = function( path ) {

			var currentBasePath = $location.path().split("/")[ 1 ],
				targetBasePath = path.split("/")[ 1 ];

			if( currentBasePath ) {
				currentBasePath = currentBasePath.replace(/\?.*$/, "");
			}

			return targetBasePath == currentBasePath;

		};

		service.getLayout = function() {
			return $location.search().layout || "";
		};

		return service;

	}

	return NavService;

});
