define('components/claimDetail/insuredClaimInfo/directive/insuredClaimInfo',['require'],function(require) {
    "use strict";

    function InsuredClaimInfo() {
        return {
            restrict: "AE",
            scope: {
                claim: "=",
                openClaimDetail: "="
            },
            controller: "InsuredClaimInfoController",
            templateUrl: "app/components/claimDetail/insuredClaimInfo/template/template.html"
        };
    }

    return InsuredClaimInfo;

});
