define('components/policyDetail/policyDocuments/app',['require','components/policyDetail/policyDocuments/directive/policyDocuments','components/policyDetail/policyDocuments/controller/PolicyDocumentsController','common/service/specialtyApplication/SpecialtyDocumentService'],function(require) {
	"use strict";

	var policyDocuments = require("components/policyDetail/policyDocuments/directive/policyDocuments"),
		PolicyDocumentsController = require("components/policyDetail/policyDocuments/controller/PolicyDocumentsController"),
		SpecialtyDocumentService = require("common/service/specialtyApplication/SpecialtyDocumentService");

	var component = angular.module("mPortal.policyDetail.policyDocuments", []);

	component.directive("policyDocuments", policyDocuments);
	component.controller("PolicyDocumentsController", PolicyDocumentsController);
	component.factory("SpecialtyDocumentService", SpecialtyDocumentService);

	return component;

});
