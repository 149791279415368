define('components/bigNews/app',['require','common/filter/stripHtml','components/bigNews/directive/bigNews','components/bigNews/controller/BigNewsController','components/newsHeadlines/controller/NewsItemController','common/service/NewsService'],function(require) {
	"use strict";

	var stripHtml = require("common/filter/stripHtml"),
		bigNews = require("components/bigNews/directive/bigNews"),
		BigNewsController = require("components/bigNews/controller/BigNewsController"),
		NewsItemController = require("components/newsHeadlines/controller/NewsItemController"),
		NewsService = require("common/service/NewsService");

	var component = angular.module("mPortal.components.bigNews", []);

	component.filter("stripHtml", stripHtml);
	component.directive("bigNews", bigNews);
	component.controller("BigNewsController", BigNewsController);
	component.controller("NewsItemController", NewsItemController);
	component.factory("NewsService", NewsService);

	return component;

});
