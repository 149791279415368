define('components/userAccountSettings/ciamAccountSettings/app',['require','common/directive/app','components/userAccountSettings/ciamAccountSettings/directive/ciamAccountSettings','components/userAccountSettings/ciamAccountSettings/controller/CiamAccountSettingsController','common/service/MessageContainer','common/service/user/UserService'],function(require) {
	"use strict";

	require("common/directive/app");

	var ciamAccountSettings = require("components/userAccountSettings/ciamAccountSettings/directive/ciamAccountSettings"),
		CiamAccountSettingsController = require("components/userAccountSettings/ciamAccountSettings/controller/CiamAccountSettingsController"),
		MessageContainer = require("common/service/MessageContainer"),
		UserService = require("common/service/user/UserService");

	var component = angular.module("mPortal.userAccountSettings.ciamAccountSettings", [
		"mPortal.directive"
	]);

	component.directive("ciamAccountSettings", ciamAccountSettings);
	component.controller("CiamAccountSettingsController", CiamAccountSettingsController);
	component.factory("MessageContainer", MessageContainer);
	component.factory("UserService", UserService );

	return component;

});
