define('components/submissionsList/directive/submissionsList',['require'],function(require) {
	"use strict";

	function SubmissionsList() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/submissionsList/template/template.html",
			controller: "SubmissionsListController"
		};
	}

	return SubmissionsList;

});
