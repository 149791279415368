define('components/quoteWizard/directive/quoteWizard',['require'],function(require) {
	"use strict";

	quoteWizard.$inject = [];

	function quoteWizard() {
		return {
			restrict: "AE",
			templateUrl: "app/components/quoteWizard/template/template.html",
			controller: "quoteWizardController"
		};
	}

	return quoteWizard;

});
