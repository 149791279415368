define('components/applicationData/applicationData/config/widgets/ViewInsuredInfoWidget',['require'],function(require) {
	"use strict";

	var widget = {};

	widget.getStructure = function () {

		return {
			"viewInsuredInfo": {wrapper: null, fieldGroup:
					{
						"viewPrimaryContactName": {wrapper: ["leftAddInsuredInformation"], type: "setAddInsuredInfo", wrapperOverride: true,orderNumber:1},
						"viewLicenseNumber" : {wrapper: ["rightAddInsuredInformation"], type: "setAddInsuredInfo", wrapperOverride: true,orderNumber:2},
						"viewEntityType": {wrapper: ["fullAddInsuredInformation"], type: "setAddInsuredInfo", wrapperOverride: true,orderNumber:3},
						"viewAddInsured": {wrapper: ["leftOrFullAddInsuredInformation"], type: "setAddInsuredInfo", wrapperOverride: true,orderNumber:4},
						"viewAddInsuredNumber": {wrapper: ["right2AddInsuredInformation"], type: "setAddInsuredInfo", wrapperOverride: true,orderNumber:5},
						"viewAddInsuredName": {wrapper: ["leftAddInsuredInformation"], type: "setAddInsuredInfo", wrapperOverride: true,orderNumber:6},
						"viewCommonOwnership": {wrapper: ["rightAddInsuredInformation"], type: "setAddInsuredInfo", wrapperOverride: true,orderNumber:6},
						"viewExplainRelation": {wrapper: ["fullAddInsuredInformation"], type: "setAddInsuredInfo", wrapperOverride: true,orderNumber:6}
					}
			}
		};

	};

	widget.configureFormly = function (formlyConfig, formlyApiCheck) {

		formlyConfig.setType( {
			name: "setAddInsuredInfo",
			template: '<formly-transclude></formly-transclude>'

		} );

		formlyConfig.setWrapper( {
			name: "leftAddInsuredInformation",
			template:
			'<div class="viewItem" ng-if="!options.data.hide">' +
			'<formly-transclude></formly-transclude>' +
			'<div class= "span6" style=" width:50%; margin-left:0" >' +
			'<span style = "line-height: 27px" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
			'</div>' +
			'</div>'
		} );

		formlyConfig.setWrapper( {
			name: "leftOrFullAddInsuredInformation",
			template:
			'<style>.fullRow  { width: 100%; margin-bottom:15px;margin-left:0;}</style><div class="viewItem" ng-if="!options.data.hide">' +
			'<formly-transclude></formly-transclude>' +
			'<div class= {{options.defaultValue===\'No\'?\'span12\':\'span6\'}} style="margin-left:0px">' +
			'<span style = "line-height: 27px" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
			'</div>' +
			'</div>'
		} );

		formlyConfig.setWrapper( {
			name: "rightAddInsuredInformation",
			template:
			'<div class="viewItem" ng-if="!options.data.hide">' +
			'<formly-transclude></formly-transclude>' +
			'<div class= "span6" style=" width:49%; margin-left:0" >' +
			'<span style = "line-height: 27px;margin-left: 2.5%;" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
			'</div>' +
			'</div>'
		} );

		formlyConfig.setWrapper( {
			name: "right2AddInsuredInformation",
			template:
			'<div class="viewItem" ng-if="!options.data.hide">' +
			'<formly-transclude></formly-transclude>' +
			'<div class= "span6" style=" width:50%; margin-left:0px" >' +
			'<span style = "line-height: 27px;margin-left: 4.5%;" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
			'</div>' +
			'</div>'
		} );

		formlyConfig.setWrapper( {
			name: "fullAddInsuredInformation",
			template:
			'<div class="viewItem" ng-if="!options.data.hide">' +
			'<formly-transclude></formly-transclude>' +
			'<div class= "span12" style="width:100%; margin-left:0" >' +
			'<span style = "line-height: 27px" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
			'</div>' +
			'</div>'
		} );


	};

	return widget;
});


