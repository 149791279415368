define('common/service/ClassCodeService',['require'],function(require) {
	"use strict";

	function ClassCodeService() {

		var service = {},
			validators = { wc: {}, bo: {} };

		validators.wc.classCode = function( classCode ) {
			return /^[0-9]{4}$/.test( classCode );
		};

		validators.bo.classCode = function( classCode ) {
			return /^[0-9]{5}$/.test( classCode );
		};

		service.validateClassCode = function( classCode, productLine ) {
			return validators[ productLine ].classCode( classCode );
		};

		return service;

	}

	return ClassCodeService;

});
