define( 'components/specialtyApplication/submit/programDetails/wc/app',['require','components/specialtyApplication/submit/programDetails/wc/directive/wc','components/specialtyApplication/submit/programDetails/wc/controller/WcController','moment'],function( require ) {
	"use strict";

	var wc           = require( "components/specialtyApplication/submit/programDetails/wc/directive/wc" ),
		WcController = require( "components/specialtyApplication/submit/programDetails/wc/controller/WcController"),
		moment       = require( "moment" );

	var component = angular.module( "mPortal.components.specialtyApplication.submit.programDetails.wc", [] );

	component.directive( "specialtyApplicationReviewWc", wc );
	component.controller( "WcController", WcController );
	component.value( "moment", moment );

	return component;

});
