define('components/promotionsSummary/directive/promotionsSummary',['require'],function(require) {
	"use strict";

	PromotionsSummary.$inject = [];

	function PromotionsSummary() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/promotionsSummary/template/template.html",
			controller: "PromotionsSummaryController"
		};
	}

	return PromotionsSummary;

});
