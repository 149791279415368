define('components/quickSearch/app',['require','common/directive/app','components/quickSearch/directive/quickSearch','components/quickSearch/controller/QuickSearchController','components/quickSearch/directive/quickSearchLabel','components/quickSearch/controller/QuickSearchLabelController','common/service/util/RenameKeys','common/service/search/SearchService'],function(require) {
	"use strict";
	
	require("common/directive/app");

	var quickSearch = require("components/quickSearch/directive/quickSearch"),
		QuickSearchController = require("components/quickSearch/controller/QuickSearchController"),
		quickSearchLabel = require("components/quickSearch/directive/quickSearchLabel"),
		QuickSearchLabelController = require("components/quickSearch/controller/QuickSearchLabelController"),
		RenameKeys = require("common/service/util/RenameKeys"),
		SearchService = require("common/service/search/SearchService");

	var component = angular.module("mPortal.components.quickSearch", [
		"mPortal.directive"
	]);

	component.directive("quickSearch", quickSearch);
	component.directive("quickSearchLabel", quickSearchLabel);
	component.controller("QuickSearchController", QuickSearchController);
	component.controller("QuickSearchLabelController", QuickSearchLabelController);
	component.factory("RenameKeys", RenameKeys);
	component.factory("SearchService", SearchService);

	return component;

});
