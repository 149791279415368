define('components/submissionDetail/submissionDetail/app',['require','common/directive/app','components/policyDetail/policyDocuments/app','components/submissionDetail/submissionInfo/app','components/paymentLink/app','components/submissionDetail/submissionDetail/directive/submissionDetail'],function(require) {
	"use strict";

	require("common/directive/app");
	require("components/policyDetail/policyDocuments/app");
	require("components/submissionDetail/submissionInfo/app");
	require("components/paymentLink/app");

	var submissionDetail = require("components/submissionDetail/submissionDetail/directive/submissionDetail");

	var component = angular.module("mPortal.submissionDetail.submissionDetail", [
		"mPortal.directive",
		"mPortal.policyDetail.policyDocuments",
		"mPortal.submissionDetail.submissionInfo",
		"mPortal.paymentLink"
	]);

	component.directive("submissionDetail", submissionDetail);

	return component;

});
