define('components/message/form/directive/uploadAttachment',['require'],function(require) {
	"use strict";

	/**
	* File uploads are a mess in older IE. Basically, we have to post our upload form to an iframe and then get the result back
	* via the browser's postMessage API. Whatever you do, do not add the iframe into the template - it has to be created dynamically
	* by the calling code or else the browser will open a separate tab when you post. I have no idea *why* it does this, but it does.
	*/

	UploadAttachmentModal.$inject = ["$sce", "$timeout", "UserProfile"];

	function UploadAttachmentModal( $sce, $timeout, UserProfile ) {
		return {
			restrict: "AE",
			scope: {
				uploadAction: "="
			},
			templateUrl: "app/components/message/form/template/uploadAttachmentModal.html",
			link: function( scope, element, attrs ) {
				scope.formConfig = {
					name: "uploadFrame_" + Date.now(),
					action: $sce.trustAsResourceUrl( scope.uploadAction ),
					profileToken: UserProfile ? ( UserProfile.profileToken ? UserProfile.profileToken : UserProfile.portalToken ) : ""
				};

				scope.status = {
					requiredError: false,
					uploadError: false
				};

				scope.browser = {
					msie: $.browser.msie
				};

				scope.form = {
					fileName: ""
				};

				// don't move this to the template or you will regret it for the rest of your life (or at least a few hours while you troubleshoot)
				var iframe = $("<iframe name='" + scope.formConfig.name + "' src='about:blank' class='hidden' />");
				$("body:first").append( iframe );

				scope.browse = function() {
					// we have to wrap this click call in a timeout because it triggers a digest cycle, which makes angular unhappy because we triggered
					// a digest cycle already when we called scope.browse().
					$timeout(function() {
						$("input#file-upload").click();
					});
				};

				scope.submit = function() {
					// in chrome and firefox, our fileName will be set via the setFile function (which is called by an onchange on the input), but IE
					// doesn't respect onchange for input[type=file], so we have to fall back to jQuery to validate the field.
					if( !scope.form.fileName && !$("input#file-upload").val() ) {
						scope.status.requiredError = true;
						return;
					}

					scope.status.requiredError = false;
					scope.status.uploadError = false;

					$(window).on("message", scope.fileUploaded);
					$("#form_" + scope.formConfig.name).submit();
				};

				scope.setFile = function() {
					scope.$apply(function() {
						scope.form.file = $("input#file-upload").get(0).files[ 0 ];
						scope.form.fileName = $("input#file-upload").get(0).files[ 0 ].name;
					});
				};

                scope.isRemoveFileEnabled = function() {
                    return !scope.form.fileName && !$("input#file-upload").val() ;
                };

                scope.removeFile = function() {
                    if( scope.form.fileName && $("input#file-upload").val() ) {
                        scope.form.fileName = "";
                        $("input#file-upload").val('');
                    }
                };

				scope.fileUploaded = function( event ) {
					var data = $.parseJSON( event.originalEvent.data );
					if( data.result && data.result === "failure" ) {
						scope.$apply(function() {
							scope.status.uploadError = data.message.join("\n");
						});

						return;
					}

					scope.$parent.close( { data : data, form : scope.form} );
				}
			}
		};
	}

	return UploadAttachmentModal;

});
