define('components/specialtyApplication/questions/config/widgets/ProgramEligibilityWidget',['require'],function(require) {

    "use strict";

    var widget = {};

    widget.getStructure = function() {

        return {
            "MarkelWelcome" : { wrapper:  [ "eligibilityLabel" ] , wrapperOverride: true, orderNumber: 1},
            "EligibilityCELLabel" : { wrapper:  [ "eligibilityLabel" ] , wrapperOverride: true, orderNumber: 2},
            "EligibilityFarriersLabel" : { wrapper:  [ "eligibilityLabel" ] , wrapperOverride: true, orderNumber: 3},
            "EligibilityFarriersIronWork" : { wrapper:  [ "eligibilityLabel" ] , wrapperOverride: true, orderNumber: 4},
            "EligibilityCELNotCovered" : { wrapper:  [ "eligibilityLabel" ] , wrapperOverride: true, orderNumber: 5},
            "EligibilityCELBodilyInjury" : { wrapper:  [ "eligibilityLabel" ] , wrapperOverride: true, orderNumber: 6},
            "EligibilityCELBodilyInjury2" : { wrapper:  [ "eligibilityLabel" ] , wrapperOverride: true, orderNumber: 7},
            "LiveryStableLink" : { wrapper:  [ "liveryLabel" ] , wrapperOverride: true, orderNumber: 8, type: "liveryPDFLabel"}
         };
    };

    widget.configureFormly = function ( formlyConfig, formlyApiCheck ) {
        formlyConfig.setWrapper({
            name: "liveryLabel",
            template: '<div class="liveryLabel" >' +
                '<formly-transclude></formly-transclude>' +
                '</div>'
        });

        formlyConfig.setType({
            name: "liveryPDFLabel",
            template:
            '<div ng-class="options.templateOptions.label.length ? options.templateOptions.style.className ? options.templateOptions.style.className : \'alert alert-info\': \'\' " style="" class="ng-scope no-style">'+
            '<ul><li>' +
            '<label class="control-label  " for="{{ id }}" class="">{{ to.label }}' +
            '<span class="span_link" style="float:none; padding-left: 2%;" onClick="window.open( \'/assets/documents/Sample MGL109 EXCLUSION OF LIVERY STABLE OPERATIONS.pdf\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >view exclusion (pdf)</span>'+
            '</label>' +
            '</li></ul>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "eligibilityLabel",
            template: '<div class="eligibilityLabel" >' +
                '<formly-transclude></formly-transclude>' +
                '</div>'
        });

    };

    return widget;

    });
