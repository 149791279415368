define('components/startQuoteCommercialPackage/directive/startQuoteCommercialPackage',['require'],function(require) {
	"use strict";

	StartQuoteCommercialPackage.$inject = [];

	function StartQuoteCommercialPackage() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/startQuoteCommercialPackage/template/template.html",
			controller: "StartQuoteCommercialPackageController"
		};
	}

	return StartQuoteCommercialPackage;

});
