define('components/specialtyApplication/questions/controller/QuestionsController',[],function() {

	QuestionsController.$inject = [
		"$scope",
		"$location",
		"$http",
		"$timeout",
		"$anchorScroll",
		"$routeParams",
		"$q",
		"SubmissionStore",
		"QuestionService",
		"ApplicationSectionService",
		"UserProfile",
		"Wizard",
		"ResultService",
		"ApplicationStateStore",
		"$filter",
		"moment",
		"$window",
		"$modal",
        "VeriskService",
		"DataPointLookUpService"
	];

	function QuestionsController(
		$scope,
		$location,
		$http,
		$timeout,
		$anchorScroll,
		$routeParams,
		$q,
		SubmissionStore,
		QuestionService,
		ApplicationSectionService,
		UserProfile,
		Wizard,
		ResultService,
		ApplicationStateStore,
    	$filter,
    	moment,
    	$window,
		$modal,
        VeriskService,
		DataPointLookUpService
	) {

		$scope.store = SubmissionStore;
		$scope.user = UserProfile;
		$scope.saveDataNotAllowed = function() {
			if( $scope.isAgent ) {
				$modal.open({
					templateUrl: "app/components/editSubmission/template/saveDataWarning.html",
					controller: "GenericModalController"
				});
			}
			else {
				$modal.open({
					templateUrl: "app/components/editSubmission/template/insuredSaveDataWarning.html",
					controller: "GenericModalController"
				});
			}
		};
		
		$scope.status = {
			loadingSections: true,
			loading: false,
			menuVisible: true,
			onEnumerationLandingPage: false,
			hasVisibleSections: false,
			percentComplete: 0
		};

		//add access to wizard of the quotes object
        $scope.quotes =  Wizard.data.entries['id-0'];

		ApplicationStateStore.populatePrograms();
		
        $scope.blockingDataPointsAreChecked = function() {
            return ApplicationStateStore.evaluateBlockingDataPoints();	
        };
		
		/*
		 The SubmissionStore.preBindEdit property indicates whether this controller is being invoked to answer/edit
		 submission questions or to answer questions on a quote in order to bind.
		*/
		$scope.preBindEdit = SubmissionStore.preBindEdit[ SubmissionStore.applicationId ];
		var editing = false;
		// legacy mode indicates running from quoteDirective with a bundled BOP and/or WC submission
		var legacyMode = $scope.applicationId || false;

		$scope.legacyMode = legacyMode;

		$scope.disableSection = false;

		if( UserProfile && UserProfile.isLoggedIn ) {
			var applicationPath = "/application/agent";
			$scope.isAgent = true;
		} else {
			var applicationPath = "/application";
			$scope.isAgent = false;
		}

		if( $routeParams.applicationId || $scope.applicationId) {
			SubmissionStore.applicationId = $routeParams.applicationId ? $routeParams.applicationId : $scope.applicationId;
			if( !$scope.phase ) {
				SubmissionStore.visitedSections.push("/application/questions");
			}
			editing = true;
		// Added to disable breadcrumb navigation back to the insured information step, as resubmitting
		// that step after visiting this one will throw errors and block the user from proceeding
		} else if ( !$scope.preBindEdit  ) {
			SubmissionStore.visitedSections = [ "/application/questions" ];
		}

		$scope.editingApplication = SubmissionStore.applicationId == UserProfile.editedApplication;

		if( !$scope.preBindEdit ) {
			// if the user refreshes the browser on this step we will only have 1 visited section. In this case we
			// are just going to forward them back to the eligibility step. however, if they are coming in to edit
			// an application (determined via $routeParams.applicationId existing), we don't want to send them away.
			if (!editing && !SubmissionStore.applicationId && SubmissionStore.visitedSections.length === 1) {
				SubmissionStore.visitedSections = [];
				SubmissionStore.visitedSections = [];
				$location.path(applicationPath);
				return;
			}

			// if the user already submitted their app, it's finalized and we aren't going to allow them to do anything with it. in the view,
			// we display a nice message directing them back to the outcome page, but here we just exit early because there's no reason to run
			// any other logic from this controller.
			if (SubmissionStore.finalizedApplication && !$scope.editingApplication) {
				$scope.status = {finalizedApplication: true};
				return;
			}
		}

		$scope.tree = {
			sections: [],
			expandedNodes: [],
			selectedNode: null,
			currentRootNode: null,
			options: {
				nodeChildren: "sections",
				dirSelectable: true,
				allowDeselect: false,
				equality: ApplicationSectionService.sectionsAreEqual,
				injectClasses: {
					 li: "depth-{{ node.depth }}"
				},
				isSelectable: function() {
					return !$scope.areRequestsPending();
				},
				isLeaf: function( node ) {
					// using this to get around some weird tree-control assumptions. it disregards our isSelectable function
					// above for "branch" nodes, so we're just going to tell it that everything is a leaf if we're currently
					// loading or saving.
					if( $scope.areRequestsPending() ) {
						return true;
					}

					// if we're not loading, use the default behavior. we have a leaf if the node does not have children
					return !node.sections || node.sections.length === 0;
				}
			}
		};

		$scope.form = {
			model: {},
			fields: []
		};

		$scope.sectionGraph = {};
		$scope.firstVisibleSectionId = null;
		$scope.allSections = [];
		$scope.processedSections = [];
		$scope.sectionsAreCompleted = true;

		// we're going to be optimistic here and assume the user is not trying to edit an account that
		// has already been submitted. we could run these requests synchronously and only load sections
		// once we've verified that the application has not yet been submitted, but that will slow down
		// initial load for the majority of our users. we may end up not needing the data from the
		// listSections call, but 99% of the time running these requests in parallel will be a slight
		// speed improvement for the user.
		$q.all([
			QuestionService.getSubmission( SubmissionStore.applicationId ),
			QuestionService.listSections( SubmissionStore.applicationId )
		]).then(function( results ) {
			var submission = results[ 0 ],
				sections = results[ 1 ];

			// we probably already have this data on SubmissionStore, but if the user is editing an
			// application we need to re-populate the object. no need to check if the data already
			// exists on SubmissionStore because any existing data is superseded by server data anyway.
			angular.extend( SubmissionStore, {
				submissionId: submission.submissionId,
				applicant: submission.applicant,
				programCode: submission.programCode,
				programName: submission.programName,
				state: submission.state,
				effectiveDate: submission.effectiveDate,
				resumeLink: submission.resumeLink
			});

			if(SubmissionStore.programs.length == 0 )
			{
				submission.marketedProgram.effectiveDate =  moment( submission.effectiveDate, "YYYY-MM-DD" ).format("MM/DD/YYYY");
				submission.marketedProgram.marketedProgramId = submission.marketedProgram.id;
				submission.marketedProgram.marketedProgramName = submission.marketedProgram.name;
				SubmissionStore.instance = submission.instance;
				SubmissionStore.programs.push(submission.marketedProgram);
			}

            var programCode = $filter('filter')(SubmissionStore.programs, {'referenceString': 'bop'}, true);
            if (programCode.length > 0 || ( SubmissionStore.programCode && SubmissionStore.programCode.toLowerCase() == "bop" ) ) {
                QuestionService.getAppEligibilityGuidelines( SubmissionStore.applicationId, SubmissionStore.effectiveDate, 'Class Code Description ID').then( function ( result ) {
					ApplicationStateStore.populateEligibilityGuidelines( result );
                });
            }
			// Do not override the existing payment plan on the submission store
			if( submission.paymentPlan && !SubmissionStore.paymentPlan ) {
				SubmissionStore.paymentPlan = submission.paymentPlan
			}

			if( submission.marketedProgram && !SubmissionStore.marketedProgramId ) {
				SubmissionStore.marketedProgramId = submission.marketedProgram.value;
			}

			if( !$scope.preBindEdit) {
				// Submitted applications can only be edited after user acknowledgement (editingApplication flag)
				if ( submission.editRestrictedByStatus && !$scope.editingApplication ) {
					$scope.status.loadingSections = false;
					$scope.status.finalizedApplication = true;
					SubmissionStore.finalizedApplication = true;
					return;
				}

			}

			sections = ApplicationSectionService.setUpEnumerationLandingPages( sections );
			$scope.tree.sections = ApplicationSectionService.addSections( $scope.tree.sections, sections );

			var firstSection;
			angular.forEach( $scope.tree.sections, function( section ) {
				if( firstSection == undefined && section.visible ) {
					firstSection = section;
					$scope.firstVisibleSectionId = section.sectionId;
					$scope.status.hasVisibleSections = true;
					$scope.tree.currentRootNode = section;
				}
			});

			/*
			 If the user is an agent, create a shell section that will add the effective date and agent selection
			 custom component as if it were an appmaster-driven section
			*/
			if( ($scope.user.type == "Agent" || $scope.user.agencyEmulation) && 1 == 1 ) { //TODO Remove impossible condition to apply this code change.
				var title = $scope.store.programs.length > 1 ? "Effective Dates and Agents" : "Effective Date and Agents";
				$scope.tree.sections.push( ApplicationSectionService.createShellSection( "agentDateSelections", title ) );
			}

			if( $scope.status.hasVisibleSections ) {
				$scope.loadSection( firstSection );
				$scope.sectionGraph = ApplicationSectionService.graphSections( $scope.tree.sections );
			}
			if($scope.editingApplication && !Wizard.visible()) {
				var sections = [];
				angular.forEach($scope.tree.sections, function (section) {
					if (section.visible) {
						$scope.status.hasVisibleSections = true;
						if (!section.shellSection) {
							if(section != firstSection){
								$scope.buildAllSections(section, sections);
							}
						}else{
							section.visited = true;
							$scope.status.loading = false;
						}
					}
				});
				sections.push(firstSection);
				$scope.allSections = sections;
				$scope.triggerLoadAllSections();
			}

			if(!$scope.editingApplication || Wizard.visible()){
				$scope.status.loadingSections = false;
			}
		});

		$scope.buildAllSections = function(section, sections) {
			sections.push(section);
			if(section.sections){
				angular.forEach(section.sections, function (childSection) {
					if(childSection.sections){
						$scope.buildAllSections(childSection, sections);
					}
				});
			}
		};

		$scope.triggerLoadAllSections = function(count) {
			if(count == undefined){
				count = 0;
				$scope.loadAllSections($scope.allSections[count], count);
			}
			if(count != undefined && count){
				if($scope.allSections[count]){
					$scope.loadAllSections($scope.allSections[count], count);
				}else{
					angular.forEach($scope.processedSections, function (processedSection) {
						if(!processedSection.enumeration && processedSection.type != 'inline') {
							if (!processedSection.isComplete()) {
								$scope.sectionsAreCompleted = false;
							}
						}
					});
					if($scope.sectionsAreCompleted){
						$scope.status.percentComplete = 100;
					}
					$scope.status.loadingSections = false;
				}
			}
		};

		$scope.updateDialPercentage = function() {
			$scope.status.percentComplete = QuestionService.getCompletionPercentage( $scope.tree.sections, $scope.status.percentComplete, 1 );
		};

		$scope.isCurrentRootNode = function( node ) {
			return node.sectionId == $scope.tree.currentRootNode.sectionId;
		};

		$scope.isClosedRootNode = function( node ) {
			return ( node.depth == 1 && !$scope.isCurrentRootNode( node ) );
		};

		$scope.showNode = function( node ) {
			return node.depth == 1 | ( node.sectionId.split(":")[0] == $scope.tree.currentRootNode.sectionId );
		};

		$scope.showInvalidMarker = function( node ) {
			return node.type != "enumerationLandingSection";
		};


		$scope.loadSection = function( node, isPrevious, reload = false ) {
			$scope.updateDialPercentage();

            if (!reload && !$scope.isOnLastSection() && !$scope.sectionsAreCompleted) {

                if( isPrevious !== "deleteEnumeratedSection"){
                    if( ApplicationSectionService.sectionsAreEqual( node, $scope.tree.selectedNode ) ) {
                        return;
                    }
                } else {
                    isPrevious = false;
                }
    
                if( node.depth == 1 && node.sectionId != $scope.tree.currentRootNode.sectionId ) {
                    $scope.tree.currentRootNode = node;
                } else if( isPrevious && $scope.tree.selectedNode.depth == 1) {
                    var selectedNodeIndex = 0;
                    for( var rootIndex = 0; rootIndex < $scope.tree.sections.length; rootIndex++ ) {
                        if( ApplicationSectionService.sectionsAreEqual( $scope.tree.sections[ rootIndex ], $scope.tree.selectedNode ) ) {
                            selectedNodeIndex = rootIndex;
                        }
                    }
                    // Find the first previous and visible top-level section and make it the current root node
                    for( var previousIndex = ( selectedNodeIndex - 1 ); previousIndex >= 0; previousIndex-- ) {
                        if( $scope.tree.sections[ previousIndex ].visible || previousIndex == 0 ){
                            $scope.tree.currentRootNode = $scope.tree.sections[ previousIndex ];
                            break;
                        }
                    }
                }

            }

			var prevSelectedNode = $scope.tree.selectedNode;

			$scope.status.onEnumerationLandingPage = node.type === "enumerationLandingSection";
			$scope.form.model = {};
			$scope.form.fields = [];
			$scope.tree.selectedNode = node;
			$anchorScroll( 'specialtyApplicationQuestions' );

			if( node.sections && node.sections.length && $scope.tree.expandedNodes.indexOf( node ) === -1 ) {
				$scope.tree.expandedNodes.push( node );
			}

			if( node.type === "enumerationLandingSection" ) {
				$scope.status.loading = false;
				node.visited = true;
				return;
			}

			$scope.status.loading = true;

			ResultService.getAutoOutcomeResult( SubmissionStore.applicationId, !!UserProfile.editedApplication, Wizard.visible() ).then( function( result ) {
				if( result.outcome === "Decline" ) {
					SubmissionStore.visitedSections = [];
					if(Wizard.visible())
					{
						//update our wizard step
						$scope.quotes.step = 5;
						Wizard.set($scope.quotes);
						//redirect to our combined result in old code
						$location.path( $scope.quotes.result_url );
					} else {
						$location.path( "/application/result" );
					}

				} else {
					if( !node.shellSection ) {
						QuestionService.getSection( SubmissionStore.applicationId, node.sectionId )
							.then( function ( section ) {
								var sections = ApplicationSectionService.setUpEnumerationLandingPages( section.sections );

								// sometimes we get back sections that will replace existing sections in the tree.. when this happens we need
								// to re-flag the sections as visited so that the UI will render the checkmarks/error flags correctly.
								ApplicationSectionService.revisitSections( section.sections, $scope.tree.sections );

								node.datapoints = section.datapoints;
								ApplicationSectionService.addSections( $scope.tree.sections, sections );
								$scope.sectionGraph = ApplicationSectionService.graphSections( $scope.tree.sections );

								$scope.setSelected( node );
								$scope.status.loading = false;
								
								$timeout(() => 	$('select[qa-reference="abuse limit"]').prop('disabled', true), 0);
								
								if ($scope.isOnLastSection()){
									$scope.sectionsAreCompleted = $scope.processSections($scope.tree.sections);
								}
								else{
									$scope.sectionsAreCompleted =false;
								}
							} )
							.catch( function ( err ) {
								if ( err.status === 404 ) {
									// No datapoints available for section; remove section.
									ApplicationSectionService.deleteSection( $scope.tree.sections, node );
									prevSelectedNode.datapoints = [];
									$scope.loadSection( prevSelectedNode );
									$scope.sectionGraph = ApplicationSectionService.graphSections( $scope.tree.sections );
								}
							} );
					} else {
						$scope.processedSections = $scope.tree.sections;
						$scope.sectionsAreCompleted = $scope.processedSections.length > 0;
						angular.forEach( $scope.processedSections, function ( processedSection ) {
							if ( !processedSection.shellSection && !processedSection.isComplete() ) {
								$scope.sectionsAreCompleted = false;
							}
						} );
						node.visited = true;
						$scope.status.loading = false;
					}
					if ($scope.isOnLastSection() && !$scope.editingApplication ) {

						if( $scope.notVisitedSections && $scope.notVisitedSections.completeLevel !=100 ){
							$scope.loadSection($scope.notVisitedSections, false);
							$scope.setSelected($scope.notVisitedSections)
							$scope.notVisitedSections ='';
						}
					}



				}

			});


		};

		$scope.processSections = function ( processedSections ) {
			var disableLastSection = false;
			$scope.sectionsAreCompleted = false;
			$scope.processedSections = processedSections;
			angular.forEach( $scope.processedSections, function ( processedSection ) {
				if ( !processedSection.isComplete() ) {
					disableLastSection = true;
				}
			} );
			return !disableLastSection;
		}

		$scope.setSelected = function( node ) {

			$scope.form.fields = ApplicationSectionService.getAllDatapoints( node );

            if(node.enumeration == "Structure") {
                $('#mportal-loader').show();
            }

            if(node.name == "Location")
            {
				//check if versik is called before if its called before than don't call it again for same location.
				var verisk_sessionstorage_arr = JSON.parse(sessionStorage.getItem('Verisk')) || [];
				var verisk_data =  verisk_sessionstorage_arr.find(x => x.location_section_Id == node.sectionId && x.applicationId == SubmissionStore.applicationId);				
				if(!verisk_data)
				{
					$scope.form.fields.push({"type": "verisk_widget", "data" :{
						"datapointId": null,
						"isEarlyDisplay": null,
						"sectionId": node.sectionId,
						"node":node,
						"reference": "verisk",
						"orderNumber": 0,
						"widgetReference": "verisk",
						"applications": [
							{
								"applicationId": SubmissionStore.applicationId,
								"submissionId": SubmissionStore.submissionId,
								"referenceString": "FinanceInstitutions",
								"sectionId": "2019128.1:2019129.1",
								"datapointId": null
							}
						]
					} ,"templateOptions": {"label": "Verisk thing"}});
				}
            }
			if(node.name == "Building")
				{	
					var location_state = node.parentNode.parentNode.datapoints.find(x=> x.key.includes("__rf_Location_State_")).defaultValue;
					//check if loss cost is called before if not then update existing structure.
					var verisk_sessionstorage_arr = JSON.parse(sessionStorage.getItem('Verisk')) || [];				
					var verisk_data =  verisk_sessionstorage_arr.find(x => x.sectionId == node.sectionId && x.applicationId == SubmissionStore.applicationId);
					
					if(verisk_data !== "undefined" && verisk_data )
					{								
						if( !verisk_data.loss_cost_data )
						{
							$scope.call_loss_cost(SubmissionStore.applicationId, node.sectionId,location_state,node.datapoints,"","").then( function ( result ) {				
							if(result)
							{			
								$scope.form.fields.push({"type": "verisk_loss_cost_widget", "data" :{						
									"sectionId": node.sectionId,
									"node":node,
									"reference": "verisk",
									"orderNumber": 0,
									"widgetReference": "verisk",
									"applications": [
										{
											"applicationId": SubmissionStore.applicationId,
											"submissionId": SubmissionStore.submissionId,
											
										}
									]
								} ,"templateOptions": {"label": "Verisk Loss Cost"}});
								
							}
							});
						}
						else 
						{
							
							$scope.add_occupant_name(verisk_data.occupant_name,verisk_data.sectionId, verisk_data.state);
							$('#mportal-loader').hide();
						}
					} 
					else
					{
                        $('#mportal-loader').hide();
                    }
				}
			ApplicationSectionService.recurseInjectEventHandlers( $scope.tree.sections, $scope.form.fields, $scope.submitFieldHandler, $scope );

			// if we've visited this node before, go ahead and flag all the invalid fields so the user knows why
			// the section was marked as invalid.
			if( node.visited ) {
				$scope.flagInvalidFields( $scope.form.fields );
			}

			ApplicationSectionService.expandSectionsUpTree( node, $scope.tree.expandedNodes );
			node.visited = true;
		};

		$scope.flagInvalidFields = function( fields ) {
			// we have to wrap this update in a $timeout in order to push these changes further down in the digest
			// cycle. field.formControl does not exist until formly has processed the fields and associated each one
			// with an actual DOM element, so we need to wait for that to happen before we can call $setValidity.
			// if the field is invalid, the error message itself already exists on field.data.validationMessage, so
			// all we need to do is make sure angular and formly are aware that the field is invalid and that we
			// want to display the validation message.
			return $timeout(function() {
				fields.forEach( function( field ) {
					$scope.processInvalidField( field );
				})
			});
		};

		$scope.processInvalidField = function( field ) {
			if( field.fieldGroup ) {
				field.fieldGroup.forEach( function( field) {
					$scope.processInvalidField( field );
				});
			} else {
				if( !field.data.isValid && field.formControl ) {
					field.formControl.$setValidity("error", false);
					field.validation.show = true;
				}
			}
		};

		$scope.addEnumeration = function( section ) {
			var existingEnumeration = section.sections[ section.sections.length - 1 ],
				enumerationId = existingEnumeration ? existingEnumeration.sectionId : section.sectionId.replace(/HEAD$/, 1);

			QuestionService.createEnumeration( SubmissionStore.applicationId, enumerationId ).then(function( sections ) {
				ApplicationSectionService.updateSections( sections, $scope.tree.sections, $scope.tree.expandedNodes, $scope.tree.selectedNode , 'questions' );
				$scope.sectionGraph = ApplicationSectionService.graphSections( $scope.tree.sections );
			});
		};

		$scope.deleteEnumeration = function( section ) {
			$scope.status.loading = true;

			QuestionService.deleteEnumeration( SubmissionStore.applicationId, section.sectionId ).then(function( response ) {

				// when an enumeration is deleted, rateforest resequences the remaining enumerations so that there are no gaps
				// in the section ids. however, when rateforest sends the changes back, there's no way for us to tell what an
				// enumeration's *old* section id was, so we receive both a "delete" change and a "changed" change with the
				// same section id.  for example, if we have enumerations 1.1, 1.2, and 1.3, and we delete 1.1, the following
				// changes take place: 1.1 is deleted, 1.2 is changed to 1.1, and 1.3 is changed to 1.2. because RF doesn't
				// give us the old IDs, we dont know that the last section is ultimately removed due to the shifting of the
				// sections. this results in the UI being out of sync because 1.3 still exists as far as the UI is concerned.
				// in order to combat this, we just splice off the last section from the parent of the deleted enumeration. the
				// updateSections call that immediately follows gets all of our other sections back in place.
				ApplicationSectionService.removeLastEnumeration( section.parentNode );

				ApplicationSectionService.updateSections( response.sections, $scope.tree.sections, $scope.tree.expandedNodes, $scope.tree.selectedNode , 'questions');
				$scope.sectionGraph = ApplicationSectionService.graphSections( $scope.tree.sections );
				$scope.status.loading = false;
			});
		};

		$scope.receiveSectionData = function ( sectionData, id ) {

			var currentSection = $filter( "filter" )( $scope.tree.sections, function ( section ) {
				return section.name === id.name
			} );

			var enumeratedSection = $filter( "filter" )( currentSection[0].sections, function ( section ) {
				return section.sectionId === id.sectionId
			} );

			$scope.deleteSpecificEnumeratedSection( enumeratedSection[0], currentSection[0] );
		};

		$scope.receiveIMSectionData = function ( sectionData, id ) {

			var mainSection = $filter( "filter" )( $scope.tree.sections, function ( section ) {
				return section.name === id.mainSectionName
			} );

			$scope.getChildSection( mainSection[0].sections, id);

		};

		$scope.deleteSpecificEnumeratedSection = function ( section, currentSection ) {
			$scope.status.loading = true;

			QuestionService.deleteEnumeration( SubmissionStore.applicationId, section.sectionId ).then( function ( response ) {

				// when an inline-enumeration is deleted, rateforest resequences the remaining enumerations so that there are no gaps
				// in the section ids. however, when rateforest sends the changes back, there's no way for us to tell what an
				// enumeration's *old* section id was, so we receive both a "delete" change and a "changed" change with the
				// same section id.  for example, if we have enumerations 1.1, 1.2, and 1.3, and we delete 1.1, the following
				// changes take place: 1.1 is deleted, 1.2 is changed to 1.1, and 1.3 is changed to 1.2. because RF doesn't
				// give us the old IDs, we dont know that the last section is ultimately removed due to the shifting of the
				// sections. this results in the UI being out of sync because 1.3 still exists as far as the UI is concerned.
				// in order to combat this, we just reload the parent node of the particular section.

				ApplicationSectionService.removeLastEnumeration( section.parentNode );
				section.parentNode.sections = [];
				$scope.status.loading = false;
				$scope.loadSection( currentSection, "deleteEnumeratedSection" )
				
			} );

		};

		$scope.isOnLastSection = function() {
			return $scope.tree.selectedNode && !$scope.sectionGraph[ $scope.tree.selectedNode.sectionId ].next;
		};

		$scope.showPreviousButton = function() {
			/*
			 In a post-quote scenario when only a single top-level section is visible, the section tree may designate
			 the nearest enumeration landing section in the tree as the previous section, which is undesireable.
			*/
			if( $scope.sectionGraph[ $scope.tree.selectedNode.sectionId ].previous ) {
				if( $scope.tree.selectedNode.sectionId == $scope.firstVisibleSectionId ) {
					return $scope.sectionGraph[ $scope.tree.selectedNode.sectionId ].previous.type != "enumerationLandingSection";
				} else {
					return true;
				}
			} else {
				return false;
			}
		};

		$scope.toggleMenu = function() {
			$scope.status.menuVisible = !$scope.status.menuVisible;
		};

        $scope.closeVeriskWaitModal = function() {
			$('#overlay-verisk-risks').remove();
		};

        $scope.verisk_interval = null;

        $scope.runVerisk = function (field, actualValue, scope, sectionID) {  

                let is_prop_cov = field.key.includes("__rf_Property_Coverage");

            let scope_fields = [];

            if (scope.datapoints && scope.datapoints.length > 0) {
                scope_fields = scope.datapoints;
            } else  if (scope.fields && scope.fields.length > 0) {
                scope_fields = scope.fields;
            }

            if (scope_fields[0].key.includes("__rf_Location_Address1")) {
                if (field.key.includes("__rf_Location_Address1")
                    || field.key.includes("__rf_Location_Zip")
                    || field.key.includes("__rf_Location_City")
                    || field.key.includes("__rf_Location_State")
                    || field.key.includes("__rf_Property_Coverage")
                ) {
    
                    var location_fields   = [];
                    var location_sections = $scope.tree.sections;
                    let has_property      = false;
                    let has_values        = true;
    
                    // Get Location enum items
                    for (var i = 0; i < location_sections.length; i++) {
                        if (location_sections[i].name === "Location Information") {
    
                            var inner_location_sections = location_sections[i].sections;
    
                            for (var x = 0; x < inner_location_sections.length; x++) {
                                if (inner_location_sections[x].title === "Location") {
    
                                    // Find correct enum item
                                    var inner_location_section_2 = inner_location_sections[x].sections;
    
                                    for (var t = 0; t < inner_location_section_2.length; t++) {
                                        if (inner_location_section_2[t].sectionId === sectionID) {
                                            location_fields = inner_location_section_2[t].datapoints;
                                        }
                                    }                                        
    
                                }
                            }
    
                        }
                    }
    
                    let risk_data = {   
                        "submission":
                        {
                        "street":"",
                        "city":"",
                        "state":"",
                        "zip":""
                        },
                        "calling_system":"mportal",
                        "UUID":SubmissionStore.submissionId,
                        "risksectionID":""
                    };                    
                    risk_data.risksectionID = sectionID;
                    if (location_fields.length > 0) {

                        for (let k = 0; k < location_fields.length; k++) {
                            const location_item = location_fields[k];
    
                            if (location_item.key.includes("__rf_Property_Coverage")) {
                                let prop_cov_val = "";

                                if (is_prop_cov) {
                                    prop_cov_val = actualValue;
                                } else {
                                    prop_cov_val = location_item.value();                                    
                                }

                                if (prop_cov_val === "Yes") {
                                    has_property = true;
                                } else if (prop_cov_val === "No") {
                                    has_property = false;
                                }
                                
                            }
                        }

                    }
    
                    if (scope_fields.length > 0) {

                        for (let r = 0; r < scope_fields.length; r++) {
                            const field_item = scope_fields[r];

                                if (field_item.data.change !== "added") {
                                    const field_value = field_item.value();
    
                                    if (field_item.key.includes("__rf_Property_Coverage")) {
    
                                        if (field_item.value() === "Yes") {
                                            has_property = true;
                                        } else if (field_item.value() === "No") {
                                            has_property = false;
                                        }                              
        
                                    } else {
        
                                        if (field_item.key.includes("__rf_Location_Address1")) {
        
                                            if (field_value === undefined || field_value.length === 0) {
                                                has_values = false;        
                                            } else {
        
                                                if (field.key.includes("__rf_Location_Address1")) {
                                                    risk_data.submission.street = actualValue;
                                                } else {
                                                    risk_data.submission.street = field_value;
                                                }
        
                                            }                              
                                            
                                        } else if (field_item.key.includes("__rf_Location_Zip")) {
        
                                            if (field_value === undefined || field_value.length === 0) {
                                                has_values = false;        
                                            } else {
        
                                                if (field.key.includes("__rf_Location_Zip")) {
                                                    risk_data.submission.zip = actualValue;
                                                } else {
                                                    risk_data.submission.zip = field_value;
                                                } 
        
                                            }                                              
                
                                        } else if (field_item.key.includes("__rf_Location_City")) {
                                            
                                            if (field_value === undefined || field_value.length === 0) {
                                                has_values = false;        
                                            } else {
        
                                                if (field.key.includes("__rf_Location_City")) {
                                                    risk_data.submission.city = actualValue;
                                                } else {
                                                    risk_data.submission.city = field_value;
                                                }
        
                                            }                     
                                            
                                        } else if (field_item.key.includes("__rf_Location_State")) {
                                            
                                            if (field_value === undefined || field_value.length === 0) {
                                                has_values = false;        
                                            } else {
        
                                                if (field.key.includes("__rf_Location_State")) {
                                                    risk_data.submission.state = actualValue;
                                                } else {
                                                    risk_data.submission.state = field_value;
                                                } 
        
                                            }                         
                
                                        }
                                    }
                                } else {
                                    has_values = false;
                                }

                            
        
                        }
                    }
    
                    if (has_property && has_values) {
                        
                        VeriskService.getRisks(risk_data).then(function( result ) {
                            $scope.riskres = result;

                            if (result.Risks && result.Risks.length > 0) {
                                $modal.open({
                                    templateUrl: "app/components/specialtyApplication/questions/config/widgets/verisk/modal-template.html",
                                    controller: "VeriskModalController",
                                    windowClass: "verisk-risk-modal",
                                    resolve: {
                                        resBody: function(){ return risk_data },
                                        riskData: function(){ return result }
                                    }
                                });

                            }        
                            
                        });
                    } 
                }
            }
            
        };

		$scope.submitFieldHandler = function( sections ) {
			return function submitField( value, field, scope ) {

                if ('key' in field) {	
                        
                    if (field.key.includes("__rf_Location_")) {
                       
                        var html_modal = '<div ng-show="overlayShow" class="overlay-verisk-modal" id="overlay-verisk-risks" style="position: absolute;top: 0;right: 0;bottom: 0;left: 0;background-color: rgba(0,0,0,.4);display: flex;justify-content: center;align-items: center;" tabindex="-1"><div class="overlay-verisk-modal-message" style="background-color: #fff;width: auto;height: auto;padding: 2rem;">Please wait while we look up location details</div></div>';

                        if (!($('#overlay-verisk-risks').length)) {
                            $(html_modal).appendTo(document.body); 
                            $('#overlay-verisk-risks').focus();
                        }                                           

                    }
                }

				if(SubmissionStore.programCode && (SubmissionStore.programCode === 'AM' || SubmissionStore.programCode === 'Farm')) {
					$scope.disableSection = true;
				}
			

				// don't bother saving it if the value hasn't changed, but record that it has been touched
				if( value === field.defaultValue && field.type !== 'Button') {
					ApplicationStateStore.processDatapoint( field, "touch" );
					return;
				}

				$scope.status.saving = true;

				var actualValue = value;

				// rateforest checkboxes do not abide by the rules of normal checkboxes, where there is only a value when checked and null when not. so, depending
				// on whether the checkbox is checked, we either send back the checked or unchecked value.
				if( field.type === "CheckBox" || field.type === "easyCheckBox" || field.type === "CheckBoxWithMargin" ) {
					actualValue = value === true ? field.data.checkedValue : field.data.uncheckedValue;
				}

				if( field.type === "Button" ) {
					actualValue = field.data.checkedValue;
				}

				QuestionService.saveDatapoint( SubmissionStore.applicationId, field.data.sectionId, field.data.datapointId, field.key, actualValue ).then(function( response ) {
                    
                    if( response.uwLocked )   {
						$scope.saveDataNotAllowed();
					}
					/*
					  The savePointOnly flag can be added to a datapoint's data collection using widget configuration. It should only be
					  used on those datapoints where:
					  1) Changing the value of a datapoint does not add or remove datapoints or sections from the UI, so the normal section
					  and section graph  updates are unnecessary.
					  2) Updating the section and section graph causes a redraw of widget DOM elements (which can happen when the widget
					  configuration implements custom components.
					*/
					else 
					{
						$scope.handle_datapoint_save(field,response,scope);
					}

                    const section_id_full  = field.data.sectionId; //"2044887.1:2044888.1"
                    const section_id_split = section_id_full.split(":");
                    const section_id_parent = section_id_split[0];              
                    var verisk_sessionstorage_arr = JSON.parse(sessionStorage.getItem('Verisk')) || [];	
					var verisk_data =  verisk_sessionstorage_arr.find(x => x.sectionId == field.data.sectionId && x.applicationId == SubmissionStore.applicationId);
                    if ('key' in field) {	
                        
                        if ((field.key.includes("__rf_Construction_Type_") 
                            || field.key.includes("__rf_Building_Square_Footage_") 
                            || field.key.includes("__rf_Sprinklered_")
							|| field.key.includes("__rf_Building_Name"))
                            && (verisk_data ) ) 
							if( !verisk_data.loss_cost_data)
							{
								{			
									var mainSection = sections.find(x => x.sectionId == section_id_parent );
									var childSections = mainSection.sections[0].sections;				
									var location = childSections.find(x=> x.sectionId == section_id_split[0] +":" +section_id_split[1]);	
									var location_state = location.datapoints.find(x=> x.key.includes("__rf_Location_State_")).defaultValue;		
									$scope.call_loss_cost( SubmissionStore.applicationId,field.data.sectionId,location_state,"",field.key,value ).then( function ( result ) {
										if(result)
										{
											$scope.call_loss_cost_modal_pop_up(location_state,SubmissionStore.effectiveDate,field.data.sectionId);	
										}
									} );
									
								}	
							}
							else 
							{							
								$scope.add_occupant_name(verisk_data.occupant_name,verisk_data.sectionId, verisk_data.state);
								$('#mportal-loader').hide();
							}				
                    }                    

                    if (scope.fields) {                     

                        if (scope.to.label === "Purpose of this structure") {

                            if (actualValue === "Awning" || actualValue === "Fence" || actualValue === "Sign") {
                                var enum_index = scope.options.data.enumeration;
                                var replacement_id = `#replacementCost${enum_index}`;
                                var replacement_field = $(replacement_id);

                                if (replacement_field) {
                                    $(replacement_field).hide();
                                }
                            }
                                
                        }

                        const scope_fields = scope.fields;
    
                        if ('key' in scope_fields[0]) {
                            if (scope_fields[0].key.includes("__rf_Location_Address1")) {

                                $scope.runVerisk(field, value, scope, section_id_full);
                                $scope.closeVeriskWaitModal();
                            }
                        } else if ('data' in scope_fields[0]) {
                            if (scope_fields[0].data.name === "LocationDataPoint") {                          
    
                                for (let c = 0; c < sections.length; c++) {
    
                                    const section1 =  sections[c];
                                    
                                    if (section1.sectionId === section_id_parent) {
                                        const inner_sections = section1.sections;
    
                                        for (let v = 0; v < inner_sections.length; v++) {
    
                                            const inner_section1 = inner_sections[v];
    
                                            if (inner_section1.sections) {
                                                for (let g = 0; g < inner_section1.sections.length; g++) {
    
                                                    const inner_section2 = inner_section1.sections[g];
    
                                                    if (inner_section2.sectionId === section_id_full) {
                                                        $scope.runVerisk(field, value, inner_section2, section_id_full);
                                                        $scope.closeVeriskWaitModal();
                                                    }
                                                }
                                            }
    
                                        }
                                    }
                                }
                            }
    
                        }
                        
                    } else {
                        $scope.closeVeriskWaitModal();
                    }
				});

                clearInterval($scope.verisk_modal_interval);   
                $scope.verisk_modal_interval = setInterval(() =>
                {

                    if (($('#overlay-verisk-risks').length)) {
                        $('#overlay-verisk-risks').remove();
                    }  

                    clearInterval($scope.verisk_interval);

                }, 5000);

                }
            

		};

        $scope.handle_datapoint_save = async function( field,response, scope = null ) 
		{
			if (field.data.savePointOnly) 
			{
				ApplicationSectionService.updateDatapoints(response.datapoints, $scope.tree.sections, scope, field.data.sectionId);
				ApplicationStateStore.processDatapoint(field, "save");
			} 
			else 
			{
				ApplicationSectionService.updateSections(response.sections, $scope.tree.sections, $scope.tree.expandedNodes, $scope.tree.selectedNode, 'questions');
				ApplicationSectionService.updateDatapoints(response.datapoints, $scope.tree.sections, scope, field.data.sectionId);
				ApplicationStateStore.processDatapoint(field, "save");
				$scope.form.fields = ApplicationSectionService.getAllDatapoints($scope.tree.selectedNode);
				ApplicationSectionService.recurseInjectEventHandlers( $scope.tree.sections, $scope.form.fields, $scope.submitFieldHandler, $scope );
				$scope.sectionGraph = ApplicationSectionService.graphSections($scope.tree.sections);
				$scope.status.saving = false;
			}
			/*
			  The saveBroadcast flag can be added to a datapoint's data collection using widget configuration when you have a custom
			  widget component that needs to take action once the save process has completed.
			*/
			if (field.data.saveBroadcast) 
			{
				$scope.$broadcast("broadcastedDatapointSave", {field: field});
			}					

			if ( $scope.isOnLastSection() && !($scope.user.type == "Agent" || $scope.user.agencyEmulation) ) {
				$scope.sectionsAreCompleted = $scope.processSections($scope.tree.sections);
			}
			
			angular.forEach(response.sections, function (section) {
				if(section.enumeration && section.change==='added' && !section.datapoints.length > 0){
					$scope.notVisitedSections = $scope.tree.selectedNode;
				}
			})
		};

		$scope.allSectionsAreVisitedAndComplete = function() {
			return ApplicationSectionService.sectionsAreVisited( $scope.tree.sections )
					&& ApplicationSectionService.sectionsAreComplete( $scope.tree.sections );
		};

		$scope.allSectionsAreVisited = function() {
			return ApplicationSectionService.sectionsAreVisited( $scope.tree.sections )
		};

		$scope.unVisitedSection = function (){
			return ApplicationSectionService.unVisitedSection( $scope.tree.sections )
		}

		$scope.finalizeApplication = function() {
			// if in legacyMode (i.e., WC +- BOP + EO) we call the click method different submit button with Continue rather than Submit Application
			if ( legacyMode ) {
				$( "#miscEOPricingFormSubmit" ).click();
				return false;
			}
			$scope.updateDialPercentage();

			if(Wizard.visible())
			{
				//update our wizard step
				$scope.quotes.step = 4;
				Wizard.set($scope.quotes);
			}
			

			$location.path( "/application/review" );
		};

		$scope.finalizeAdditionalQuestions = function() {
			SubmissionStore.preBindEdit[ SubmissionStore.applicationId ] = false;
			SubmissionStore.preBindQuestionsAnswered[ SubmissionStore.applicationId ] = true;
			if( SubmissionStore.preBindPayment[ SubmissionStore.applicationId ] ) {
				$location.path( "/application/payment" );
			} else {
				ResultService.bindSubmission( SubmissionStore.applicationId, SubmissionStore.paymentPlan ).then( function ( result ) {
					$location.path( "/application/result" );
				} );
			}
		};

		$scope.areRequestsPending = function() {
			return $http.pendingRequests.length > 0;
		};

		$scope.showSectionInTree = function( section ) {
			return section.visible && section.type !== "inline";
		};

		$scope.startNewApplication = function() {
			SubmissionStore.reset();
			$location.path( applicationPath );
		};

		$(document).on('change', 'input[qa-reference="gl optional abuse"]', function() {
			$timeout(() => 	$('select[qa-reference="abuse limit"]').prop('disabled', true), 1500);
		});

		$scope.loadAllSections = function( section, count ) {
            $('#mportal-loader').hide();
			var prevSelectedNode;
			var promise;
			$scope.status.hasVisibleSections = true;
			if( section.type === "enumerationLandingSection" ) {
				$scope.status.loading = false;
				section.visited = true;
				count++;
				$scope.triggerLoadAllSections(count);
			}else{
				promise = QuestionService.getSection(SubmissionStore.applicationId, section.sectionId);
				promise.then(function (data) {
					var sections = ApplicationSectionService.setUpEnumerationLandingPages(data.sections);
					section.datapoints = data.datapoints;
					ApplicationSectionService.addSections($scope.tree.sections, sections);
					$scope.setSelected(section);
					if ($scope.status.hasVisibleSections) {
						$scope.updateDialPercentage();
						prevSelectedNode = section;
						$scope.sectionsLoaded = true;
						$scope.sectionGraph = ApplicationSectionService.graphSections($scope.tree.sections);
						$scope.status.loading = false;
					}
					$scope.processedSections.push(section);
					count++;
					$scope.triggerLoadAllSections(count);
				}).catch(function (err) {
					if (err.status === 404) {
						// No datapoints available for section; remove section.
						ApplicationSectionService.deleteSection($scope.tree.sections, section);
						prevSelectedNode.datapoints = [];
						$scope.loadSection(prevSelectedNode);
						$scope.sectionGraph = ApplicationSectionService.graphSections($scope.tree.sections);
					}
				});
			}
		};


		$scope.resize = function()
        {
            //console.log($scope.account.isMobile)
            if(arguments.length)$scope.$apply();
        }

        $scope.focus = function(e)
        {
        	var input = $(e.target).closest('.field-wrapper').find('input.hasDatepicker');
			input.focus();
        	return false;
        }

		$scope.getChildSection = function( sections, id ) {
			var childSection;

			for(var i = 0; i< sections.length; i++){
				if( id.mainSectionName === "Policy Info" ) {
					if (sections[i].name === id.name) {
						childSection = sections[i];
						break;
					}
				}
				else if( id.mainSectionName === "Location Information" ) {
					if (sections[i].sectionId === id.sectionId) {
						childSection = sections[i].parentNode;
						break;
					}
				}

				if(sections[i].sections && !childSection){
					$scope.getChildSection( sections[i].sections, id );
				}
			}
			if(childSection) {
				var childEnumeratedSection = $filter( "filter" )( childSection.sections, function ( section ) {
					return section.sectionId === id.sectionId
				} );

				if(childEnumeratedSection) {
					var currentSection = id.mainSectionName === "Policy Info" ? childSection.parentNode.parentNode : childSection.parentNode;
					$scope.deleteSpecificEnumeratedSection(childEnumeratedSection[0], currentSection);
				}
			}
		};

		$scope.add_multiple_risks = async function ( data, calldata ) {	

            $.skynet.modal.set({
                content: "Please wait, we are currently setting the structure information based on selections.",
                show_header	: false,
                show_footer	: false
            });
            $.skynet.modal.show();
						
			var mainSection = $filter( "filter" )( $scope.tree.sections, function ( section ) {
				return section.name === calldata.mainSectionName
			});	
			
			var childSections = mainSection[0].sections[0].sections;				
			var location = childSections.find(x=> x.sectionId == calldata.sectionId);
			var section = location.sections;			
			
			var datapoint_Fire = location.datapoints.find(x=> x.key.includes("Fire_Station_Distance"));
			var datapoint_Hydrant = location.datapoints.find(x=> x.key.includes("Hydrant_Distance"));
			var location_state = location.datapoints.find(x=> x.key.includes("__rf_Location_State_")).defaultValue;
			
			if(datapoint_Fire &&  datapoint_Hydrant)
			{
				var val_datapoint_Fire =  "";
				var val_datapoint_Hydrant = "" ;

				if(calldata.veriskdata[0].Risks.Ppc !== null && calldata.veriskdata[0].Risks.Ppc !== undefined)					
				{
					var ppc = calldata.veriskdata[0].Risks.Ppc;
					if(ppc.PredominantPpc >=1 && ppc.PredominantPpc <= 9)
					{
						val_datapoint_Fire =  "Under 5";
						val_datapoint_Hydrant = "Under 1000"
					}
					else 
					{
						val_datapoint_Fire =  "Over 5";
						val_datapoint_Hydrant = "Over 1000"
					}
				}
				
				var savedatapoint_result_Fire = await QuestionService.saveDatapoint_async( SubmissionStore.applicationId,datapoint_Fire.data.sectionId,  datapoint_Fire.data.datapointId, datapoint_Fire.key, val_datapoint_Fire );					
				await $scope.handle_datapoint_save(datapoint_Fire,  savedatapoint_result_Fire);	
                var savedatapoint_result_hydrant = await QuestionService.saveDatapoint_async( SubmissionStore.applicationId,datapoint_Hydrant.data.sectionId,  datapoint_Hydrant.data.datapointId, datapoint_Hydrant.key, val_datapoint_Hydrant ); 	
                await $scope.handle_datapoint_save(datapoint_Hydrant, savedatapoint_result_hydrant);		
			}
			var verisk_sessionstorage_arr = [];
			verisk_sessionstorage_arr = JSON.parse(sessionStorage.getItem('Verisk')) || [];
			
			// if multiple risk selected add structure for each risk under location.			
			for(var i = 0 ; i < calldata.veriskdata.length ; i++)
			{						
				var risk_details = calldata.veriskdata[i].Risks;						
				var existingEnumeration = section[0].sections[section[0].sections.length - 1 ];
				enumerationId = existingEnumeration ? existingEnumeration.sectionId : section.sectionId.replace(/HEAD$/, 1);
				//check if loss cost is called before if not then update existing structure.
				var verisk_sessionstorage_arr = JSON.parse(sessionStorage.getItem('Verisk')) || [];				
				var verisk_data =  verisk_sessionstorage_arr.find(x => x.sectionId == enumerationId && x.applicationId == SubmissionStore.applicationId);
			
				var createEnumeration_sections;
				var added_section ;
				var added_section_sectionId;
				if(verisk_data !== "undefined" && verisk_data )
				{
					createEnumeration_sections = await QuestionService.createEnumeration_async( SubmissionStore.applicationId, enumerationId);
					ApplicationSectionService.updateSections( createEnumeration_sections, $scope.tree.sections, $scope.tree.expandedNodes, $scope.tree.selectedNode , 'questions' );
					$scope.sectionGraph = ApplicationSectionService.graphSections( $scope.tree.sections );					
					added_section = createEnumeration_sections.find(x=> x.name == "Building");
					added_section_sectionId = added_section.sectionId;
				}
				else
				{ 
					createEnumeration_sections = existingEnumeration;
					added_section_sectionId = enumerationId;
				}
				if(createEnumeration_sections && risk_details) 
				{						
					var getSection_section = await QuestionService.getSection_async( SubmissionStore.applicationId, added_section_sectionId);				
					if(getSection_section)
					{						
						var MPC = risk_details.ModeledPropertyCharacteristics;	
						var structure_desc = "";
						if(risk_details.BuildingRatingDetails !== null )
						{
							if(risk_details.BuildingRatingDetails.WindRatingConstructionBuildingEffectiveness !== null)
							{
								if(risk_details.BuildingRatingDetails.WindRatingConstructionBuildingEffectiveness.Details !== null)
								{		
									if(risk_details.BuildingRatingDetails.WindRatingConstructionBuildingEffectiveness.Details.BuildingWindconstruction !== null)								
										{
											structure_desc = risk_details.BuildingRatingDetails.WindRatingConstructionBuildingEffectiveness.Details.BuildingWindconstruction.Description;
										}
								}
							}								
						}
						var typeOfMasonry = "";
						var typeOfSteel = "";
						if(structure_desc)
						{
							var is_Reinforced_Masonry 		= structure_desc.includes("Reinforced Masonry") ? true : false  ;
							var is_Un_Reinforced_Masonry 	= structure_desc.includes("Un-Reinforced Masonry") ? true : false  ; 
							if(is_Reinforced_Masonry && !is_Un_Reinforced_Masonry)
							{
								typeOfMasonry = "Reinforced" ;
							}
							else 
							{
								typeOfMasonry = "Other" ;
							}
							
							var is_with_steel = structure_desc.includes("With Light Steel") ? true : false ;	
							if(is_with_steel)
							{
								typeOfSteel = "Light Steel" ;
							}
							else 
							{
								typeOfSteel = "Other" ;
							}			
						}
						var field = getSection_section.datapoints;										
						var map_datapoint_result = await $scope.map_verisk_data_point(field,risk_details,MPC,typeOfMasonry,typeOfSteel);
						var verisk = {
							"sectionId": added_section_sectionId,
							"riskId":risk_details.RiskId,
							"state":location_state,
							"effectiveDate":SubmissionStore.effectiveDate,
							"applicationId":SubmissionStore.applicationId,
							"location_section_Id" : location.sectionId
						}
						verisk_sessionstorage_arr.push(verisk);
						sessionStorage.setItem('Verisk', JSON.stringify(verisk_sessionstorage_arr));
					}
				}			
			}

            $.skynet.modal.hide();
		};
        
		$scope.add_occupant_name = async function ( name,sectionId,location_state) 
		{
			
            var occupant_val = "Not available";	
			occupant_val = name;				
			var field_sectionid          = sectionId;
			var field_section_arr        = field_sectionid.split(":");
			var field_section_arr_length = field_section_arr.length;

			var field_section_last       = field_section_arr[field_section_arr_length -1]

			var field_section_arr        = field_section_last.split(".");
			var field_section_arr_length = field_section_arr.length;
			var field_section            = field_section_arr[field_section_arr_length - 1];     
			
			
			setTimeout(() => 
			{  
				var parent = $('[qa-reference="sprinklered"]').closest(".form-group");

				if (($('#occupant-button').length)) {
					$('#occupant-button').remove();
				}  
				if (($('#formly_1_TextBox___rf_Occupant_Name_12').length)) {
					$('#formly_1_TextBox___rf_Occupant_Name_12').remove();
				}  
				if (($('#formly_1_Label___rf_Occupant_Name_12').length)) {
					$('#formly_1_Label___rf_Occupant_Name_12').remove();
				} 
				var field_html = `
				<div class="form-group" id="occupantname${field_section}">
					<div class="control-group row-fluid">
						<div class="span6">
							<label style=":after: " id= "formly_1_Label___rf_Occupant_Name_12" for="formly_1_TextBox___rf_Occupant_Name_12" class="ng-binding"> Selected Occupant
						</div>
						<div class="span6 field-wrapper ">
						
							
							<span   id="formly_1_TextBox___rf_Occupant_Name_12" name="formly_1_TextBox___rf_Occupant_Name_12" class="ng-binding">${occupant_val}</span>
															
							<button class="btn btn-primary" id="occupant-button" data-location-state="${location_state}" data-section-id = "${sectionId}" >Change Occupant</button>
						</div>											  
					</div>
					</div>
					`;		
				
				parent.append(field_html);
			}, "2500");
                       
        };
		
		$('body').on('click','#occupant-button',function(){   
			
			let location_state = $("#occupant-button").attr("data-location-state");
			let sectionId = $("#occupant-button").attr("data-section-id");                                       
			$scope.call_loss_cost_modal_pop_up(location_state,SubmissionStore.effectiveDate,sectionId);
		});  
	

		$scope.map_verisk_data_point = async function(field,risk_details,MPC,typeOfMasonry,typeOfSteel) {
				
			for(var j= 0 ; j < field.length ; j++ )
				{
					var field_item = field[j];
					var data = field_item.data;								
					var actualValue = '';	
								
					if(data.reference == "Stories")
					{			
						if(MPC.NumberOfStories !== null)									
							actualValue = MPC.NumberOfStories.NumberOfStories; 
					}
					else if(data.reference ==	"Construction Type")
					{
						
						if(MPC.IsoConstructionClass !== null)
							actualValue = MPC.IsoConstructionClass.ConstructionClassDescription; 
					}
					else if(data.reference ==	"Year Built")
					{
						
						actualValue = risk_details.YearBuilt ;
					}	
					else if(data.reference ==	"Building Square Footage")
					{
						if(MPC.BuildingSquareFootage !== null)
							actualValue = MPC.BuildingSquareFootage.BuildingSquareFootage; 
					}	
					else if(data.reference ==	"Type of Steel")
					{
						actualValue = typeOfSteel; 
					}	
					else if(data.reference ==	"Type of Masonry")
					{
						actualValue = typeOfMasonry; 
						
					}	
					
					if(actualValue !== '')
					{	
						var savedatapoint_result = await QuestionService.saveDatapoint_async( SubmissionStore.applicationId,data.sectionId,  data.datapointId,field[j].key, actualValue );						
						await $scope.handle_datapoint_save(field_item, savedatapoint_result);
                        if(savedatapoint_result.datapoints.length > 0)
							{															
								var map_datapoint_result = await $scope.map_verisk_data_point(savedatapoint_result.datapoints,risk_details,MPC,typeOfMasonry,typeOfSteel);
							}	
					}
				}
		};

		$scope.call_loss_cost = async function (applicationId,sectionId,state,datapoints,field,value) {			
			console.log("Call loss cost condition check");
			var verisk_data = await $scope.get_verisk_session_data(sectionId);
			if(verisk_data)
			{
				//var RiskId = verisk_data.riskId ;	
					
				let is_valid_loss_cost_call = false;
				var constructionType = "";
				var buildingSquareFootage = "";
				var buildingSprinklered = "";
				if(datapoints == "")
				{
					var section = await QuestionService.getSection_async( applicationId, sectionId);
					datapoints = section.datapoints;
				}
				
				if(field !== "")
				{
					if(field.includes("__rf_Construction_Type_"))
					{
						constructionType = value;				
					}
					if(field.includes("__rf_Building_Square_Footage_"))
					{
						buildingSquareFootage = value;				
					}
					if(field.includes("__rf_Sprinklered_"))
					{
						buildingSprinklered = value;				
					}
				}						
				if(constructionType == "")
				{
					var constructionType_dp = datapoints.find(x=>x.key.includes('__rf_Construction_Type_'))
					if(constructionType_dp)	
					{				
						constructionType = constructionType_dp.defaultValue;
					}
					
				}
				if(buildingSquareFootage == "")
				{
					var buildingSquareFootage_dp = datapoints.find(x=>x.key.includes('__rf_Building_Square_Footage_'))
					if(buildingSquareFootage_dp)
					{
						buildingSquareFootage = buildingSquareFootage_dp.defaultValue;			
					}
				}
				if(buildingSprinklered == "")
				{
					var buildingSprinklered_dp  = datapoints.find(x=>x.key.includes('__rf_Sprinklered_'))
					if(buildingSprinklered_dp)
					{
						buildingSprinklered = buildingSprinklered_dp.defaultValue;
					}
				}
			
				if (
					state !== "MS" &&
					(
						((constructionType == "Frame" || constructionType == "Joisted Masonry" || constructionType == "Non-Combustible")
							&& buildingSquareFootage > 25000)
						|| ((constructionType == "Masonry Non-Combustible" || constructionType == "Modified Fire Resistive" || constructionType == "Fire Resistive")
							&& buildingSquareFootage > 5000)
						|| buildingSprinklered == "Yes"
					)
				) {
					if (state == "LA" || state == "WA" || state == "ID") {
						specific_rating_without_api_call = true;
					}
					else {
						is_valid_loss_cost_call = true;
					}
			
				}
				else if (
					state === "MS" && (
						(constructionType == "Masonry Non-Combustible" || constructionType == "Modified Fire Resistive" || constructionType == "Fire Resistive")
						|| buildingSquareFootage > 5000
						|| buildingSprinklered == "Yes"
					)
				) {
					specific_rating_without_api_call = true;
				}	
				
				//call lostcost api here and add values for result to mpolicy
				if(is_valid_loss_cost_call)	
				{ 
					return is_valid_loss_cost_call;
				}
				// if no call to loss cost api then set values to default.
				else 
				{
                    $('#mportal-loader').hide();
					await $scope.add_remove_specific_rating_info(null,sectionId,false);
					return false ;
				}
			}
			else 
			{
                $('#mportal-loader').hide();
				console.log ("No Session Data Found For SectionId" , sectionId);
				return false ;
			}
			
		};
		
		$scope.call_loss_cost_api = async function(RiskId,state,effectiveDate) {
			var api_call_data = {
						"calling_system":"mportal",
						"UUID":SubmissionStore.submissionId,
						"submission":{"riskId": RiskId,  state:state,"effectiveDate": effectiveDate }
					}
			var result = await VeriskService.getLossCostReport_async(api_call_data);	
			console.log("call_loss_cost_api result" , result);		
			return  result;
		}

		$scope.get_verisk_session_data = async function(sectionId)
		{
			var verisk_sessionstorage_arr = JSON.parse(sessionStorage.getItem('Verisk')) || [];				
			var verisk_data =  verisk_sessionstorage_arr.find(x => x.sectionId == sectionId && x.applicationId == SubmissionStore.applicationId);
			return verisk_data;
		}

		$scope.call_loss_cost_modal_pop_up  =  async function(state,effectiveDate,sectionId) 
		{	
			var verisk_sessionstorage_arr = JSON.parse(sessionStorage.getItem('Verisk')) || [];	
			var verisk_data =  verisk_sessionstorage_arr.find(x => x.sectionId == sectionId && x.applicationId == SubmissionStore.applicationId);
			var result  = await $scope.call_loss_cost_api(verisk_data.riskId,state,effectiveDate);
			if(result)	
			{						
				result.LossCosts.push({"Name":"NONE OF THE ABOVE"});
				result.sectionId = sectionId;
				result.is_emit = false;
			
				$modal.open({
					keyboard: false,
					templateUrl: "app/components/specialtyApplication/questions/config/widgets/verisk/modal-loss-cost-template.html",
					controller: "VeriskLossCostModalController",
					windowClass: "verisk-risk-modal",
					resolve: {							
						lossCostData: function(){ return result }
					}
				});
			}
			
		 }		

		$scope.add_loss_cost_data = async function (data, calldata)
		{		
			var sectionId = calldata.sectionId
			//store selected data in sesssion
			var verisk_sessionstorage_arr = JSON.parse(sessionStorage.getItem('Verisk')) || [];	
			var verisk_data =  verisk_sessionstorage_arr.find(x => x.sectionId == sectionId && x.applicationId == SubmissionStore.applicationId);
			verisk_data.loss_cost_data = calldata;	
			
			verisk_data.occupant_name = calldata.losscostdata.Name;	
			verisk_sessionstorage_arr.remove(verisk_data);					
			verisk_sessionstorage_arr.push(verisk_data);
			sessionStorage.setItem('Verisk', JSON.stringify(verisk_sessionstorage_arr));
			//map verisk fields
			var result = await $scope.add_remove_specific_rating_info(calldata.losscostdata,sectionId,calldata.add,verisk_data.state);	
		}

		$scope.add_remove_specific_rating_info = async function(result,sectionId,add,state)
		{ 
			var ref_key = "__rf_Specific_Rating_" + sectionId.replaceAll(".","_");					
			ref_key =  ref_key.replaceAll(":","_"); 
			const section_id_split = sectionId.split(":");			
			const section_id_parent = section_id_split[section_id_split.length-1];			
			var parent_split = section_id_parent.split(".");		
			var datasectionId = parent_split[0];
			var datapoint_info;
			//console.log("datasectionId",datasectionId);
			datapoint_data = 
			{
				"datapoint_reference":["RCP Construction Type","RCP Code","Loss Cost Date","Bldg Loss Cost","Cnts Loss Cost","Tentative Rates Apply","Specific Rating","Specific Rtg Survey Date"],
				"datasection_Id":datasectionId
			}			

			datapoint_info = await DataPointLookUpService.getdatapoint(datapoint_data);
			
			 //add datapoint if we find datapointids
			 console.log("datapoint_info" , datapoint_info );
			 if(datapoint_info && datapoint_info.Message != "No Matching Data Found" && datapoint_info.Message != "You do not have access"  )
			 {
				var losscost_data_info 
				if(add)
				{
					losscost_data_info = await $scope.assign_fields(result);					
					
				}
				else 
				{
					losscost_data_info = await $scope.remove_fields();
				}
				var add_cooupant = await  $scope.add_occupant_name(result.Name,sectionId,state);
				console.log("losscost_data_info",losscost_data_info);
				//add specific rating first 
				var add_Specific_Rating = datapoint_info.data.find(e=> e.reference == "Specific Rating" );
				var actualValue_specificRating= "";
				if(add_Specific_Rating)
				{
					actualValue_specificRating = losscost_data_info["specificRating"]
					var savedatapoint_result = await QuestionService.saveDatapoint_async(SubmissionStore.applicationId,sectionId, add_Specific_Rating.datapointid,ref_key, actualValue_specificRating );	
					if(savedatapoint_result)
					{
						console.log("savedatapoint", add_Specific_Rating)
					}
				}

				//add TentativeRating first 
				var add_tentative_Rating = datapoint_info.data.find(e=> e.reference == "Tentative Rates Apply" );
				var actualValue_TentativeRating= "";
				if(add_tentative_Rating)
				{
					actualValue_TentativeRating = losscost_data_info["TentativeRating"]
					var savedatapoint_result = await QuestionService.saveDatapoint_async(SubmissionStore.applicationId,sectionId, add_tentative_Rating.datapointid,ref_key, actualValue_TentativeRating );	
					if(savedatapoint_result)
					{
						console.log("savedatapoint", add_tentative_Rating)
					}
				}
				
				if(actualValue_specificRating == "Yes")
				{
					for(var i= 0 ; i < datapoint_info.data.length ; i++ )
					{
						var actualValue = "";
						if(datapoint_info.data[i].reference == "Bldg Loss Cost")
						{
							actualValue = losscost_data_info["bldgLossCost"]
						}
						else if(datapoint_info.data[i].reference == "Cnts Loss Cost")
						{
							actualValue = losscost_data_info["ppLossCost"]
						}
						else if(datapoint_info.data[i].reference == "RCP Code")
						{
							actualValue = losscost_data_info["rcpCode"]
						}	
						else if (datapoint_info.data[i].reference == "Loss Cost Date")
						{
							actualValue =losscost_data_info["lossCostEffectiveDate"]
						}	
						else if (datapoint_info.data[i].reference == "Specific Rtg Survey Date")
						{							
							actualValue =losscost_data_info["specificRtgSurveyDate"]
						}	
						if(actualValue && actualValue !== "")
						{
							var savedatapoint_result = await QuestionService.saveDatapoint_async(SubmissionStore.applicationId,sectionId, datapoint_info.data[i].datapointid,ref_key, actualValue );	
							if(savedatapoint_result)
							{
								console.log("savedatapoint ", datapoint_info.data[i] )
							}
						}
					}
				}
			}
			else 
			{
				console.log("No datapoint datapoint_info = ." , datapoint_info);
			}			
		}

		$scope.assign_fields =async function (api_result) {
			
			var structure = {};
			try {
				structure.specificRating = "Yes";
				structure.TentativeRating = "Yes";
				structure.rcpCode = "";
				structure.lossCostEffectiveDate = "";
				structure.bldgLossCost = "";
				structure.ppLossCost = "";
				structure.specificRtgSurveyDate = "";
				//rcp code 
				if(api_result.BuildingRatingDetails)
				{
					if(api_result.BuildingRatingDetails.FireRatingConstructionProtection)
					{
						if( api_result.BuildingRatingDetails.FireRatingConstructionProtection.FireRatingConstructionProtectionCode)
						{
							structure.rcpCode = api_result.BuildingRatingDetails.FireRatingConstructionProtection.FireRatingConstructionProtectionCode ;
						}
					}	
				}
				//lost cost effective date
				if(api_result.ExperienceLevelAdjustment)
				{
					if(api_result.ExperienceLevelAdjustment.EffectiveDate)
					{
						structure.lossCostEffectiveDate = api_result.ExperienceLevelAdjustment.EffectiveDate;
					}
				}
				//building loss cost 
				if(api_result.bldgLossCost)
				{
					structure.bldgLossCost  = api_result.bldgLossCost;								
				}
				//personal property loss cost 
				if(api_result.SpecificLossCost)
				{
					if(api_result.SpecificLossCost.BGILossCostSpecific)
					{
						structure.ppLossCost  = api_result.SpecificLossCost.BGILossCostSpecific.toString() ;
					}
				}
				if(api_result.RiskSummary)	
				{					
					if(api_result.RiskSummary.OnsiteSurveyDate)
					{
						structure.specificRtgSurveyDate  = api_result.RiskSummary.OnsiteSurveyDate.toString() ;
					}
				}		
			}
			catch (error) {
				console.log(error)
			}
			return structure;
		}

		$scope.remove_fields = async function () {
			console.log("remove_fields loss cost");
			var structure = {};
			structure.specificRating = "No";
			structure.TentativeRating = "Yes";
			return structure;
		}
        $('.specialty-application-questions').on('click','.cal-btn',$scope.focus);

        angular.element($window).bind('resize',$scope.resize);

		$scope.$on( 'delete-enumerated-section', $scope.receiveSectionData );
		$scope.$on( 'delete-im-enumerated-section', $scope.receiveIMSectionData );		
		$scope.$on( 'add_multiple_risks',  $scope.add_multiple_risks);
		$scope.$on( 'add_loss_cost_data',  $scope.add_loss_cost_data);
	}

	return QuestionsController;

});
