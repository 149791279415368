define('components/productSearch/list/app',['require','components/productSearch/list/directive/productSearchList','components/productSearch/list/controller/productSearchListController','common/service/specialtyApplication/SubmissionStore'],function(require) {
	"use strict";

	var productSearchList 				= require("components/productSearch/list/directive/productSearchList"),
		productSearchListController 	= require("components/productSearch/list/controller/productSearchListController"),
		SubmissionStore 				= require("common/service/specialtyApplication/SubmissionStore");

	var component 						= angular.module("mPortal.components.productSearch.list", []);

	component.directive("productSearchList", productSearchList);
	component.controller("productSearchListController", productSearchListController);
	component.factory("SubmissionStore", SubmissionStore);
	return component;

});
