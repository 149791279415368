define('components/applicationData/applicationData/config/widgets/ViewExclusionsWidget',['require'],function(require) {
    "use strict";

    var widget = {};

    widget.getStructure = function () {

        return {
            "ViewEligibilityExclusion": {wrapper: null, fieldGroup:
                {
                    "viewExclusion1": {wrapper: ["viewExclusionDatapoint"],type: "setExclusions", wrapperOverride: true},
                    "viewExclusion2": {wrapper: ["viewExclusionDatapoint"], type: "setExclusions",wrapperOverride: true},
                    "viewExclusion3": {wrapper: ["viewExclusionDatapoint"],type: "setExclusions", wrapperOverride: true},
                    "viewEligibilityCommon": {wrapper: ["viewExclusionDatapoint"], type: "setExclusions",wrapperOverride: true}
                }
            }
        };
    };

    widget.configureFormly = function (formlyConfig, formlyApiCheck) {

        formlyConfig.setType({
            name: "setExclusions",
            template:'<formly-transclude></formly-transclude>'

        });

        formlyConfig.setWrapper({
            name: "viewExclusionDatapoint",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="row-fluid">' +
            '<div class="span12">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '</div>' +
            '<div class="span12">' +
            '{{::options.defaultValue}}' +
            '</div>' +
            '</div>'
        });

    };

    return widget;
});
