define('common/filter/formatMoney',['require'],function(require) {
	"use strict";

	// from rexUtil.js - as far as i can tell, it's a modified version of this SO answer: http://stackoverflow.com/a/149099/603502
	function FormatMoney() {
		return function( number, places, decimal, thousand, useSymbol ) {
			var amount = number;
			var decimalPlaces = isNaN(places = Math.abs(places)) ? 2 : places;
			var decimalSeparator = decimal === undefined ? "." : decimal;
			var thousandSeparator = thousand === undefined ? "," : thousand;
			var sign = amount < 0 ? "-" : "";
			var symbol = useSymbol === undefined ? "$" : useSymbol;
			var absoluteAmount = parseInt(amount = Math.abs(+amount || 0).toFixed(decimalPlaces), 10) + "";
			var thousandSections;
			thousandSections = (thousandSections = absoluteAmount.length) > 3 ? thousandSections % 3 : 0;
			return sign + symbol + (thousandSections ? absoluteAmount.substr(0, thousandSections) + thousandSeparator : "") + absoluteAmount.substr(thousandSections).replace(/(\d{3})(?=\d)/g, "$1" + thousandSeparator) + decimalSeparator + Math.abs(amount - absoluteAmount).toFixed(decimalPlaces).slice(2);
		};
	}

	return FormatMoney;

});
