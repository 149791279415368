define('components/policyDetail/accountHistory/controller/AccountHistoryController',['require'],function(require) {
	"use strict";

	AccountHistoryController.$inject = ["$scope", "moment"];

	function AccountHistoryController( $scope, moment ) {

		$scope.policiesByYear = {};
		$scope.policyYears = [];

		if( $scope.user && $scope.user.type == "Insured" ) {
			$scope.policies = $scope.account.policies;
		}

		if( $scope.policies ) {
			$scope.policiesByYear = $scope.policies.reduce(function( grouped, policy ) {
				var effectiveYear = moment( policy.effectiveDate, "MM/DD/YYYY" ).year();

				if( !grouped[ effectiveYear ] ) {
					grouped[ effectiveYear ] = [];
					$scope.policyYears.push( effectiveYear );
				}

				grouped[ effectiveYear ].push( policy );

				return grouped;
			}, {});
			$scope.policyYears.sort().reverse()
		}

	}

	return AccountHistoryController;

});
