define( 'common/service/LossRunsService',['require'],function ( require ) {
	"use strict";

	LossRunsService.$inject = ["$http", "$window", "archApiUrl", "SubmissionStore"];

	function LossRunsService( $http, $window, archApiUrl, SubmissionStore ) {

		var service = {};

		service.loadInitialContent = function ( windowObj, accountId ) {
			var loadingHTML = '<h3>Please wait.. Thank you for your patience as we retrieve your document. This may take up to 60 seconds.</h3>';
			$( windowObj.document.body ).html( loadingHTML );
		};

		service.openLossRunsWindow = function ( accountId ) {
			var storageId = accountId + "LossRuns";
			var retrievalUrl = window.location.origin + "/loss-run-api/accounts/" + accountId + "/loss-report";
			if ( SubmissionStore.documentWindows[storageId] != undefined && SubmissionStore.documentWindows[storageId].closed ) {
				delete SubmissionStore.documentWindows[storageId];
			}

			if ( SubmissionStore.documentWindows[storageId] == undefined ) {
				// to avoid pop-up blockers, we need to open the window before kicking off an async call, so we'll load it with no URL for now.
				SubmissionStore.documentWindows[storageId] = $window.open( '', storageId, "location=0,width=990,height=550,resizable=1,scrollbars=1" );
				this.loadInitialContent( SubmissionStore.documentWindows[storageId], accountId );
				SubmissionStore.documentWindows[storageId] = $window.open( retrievalUrl, storageId, 'location=0,width=990,height=500,resizable=1' );
			}

		};

		service.showInsuredLossRuns = function( agencyId ) {
			var hiddenDirectAgencies = [ "61341", "94519", "93970", "83820", "83822" ];
			return (agencyId == null || agencyId == undefined) ? true : hiddenDirectAgencies.indexOf( agencyId ) === -1;
		};

		return service;
	}

	return LossRunsService;

} );
