define('components/promotionsSummary/app',['require','angular-sanitize','common/filter/formatMoney','components/promotionsSummary/controller/PromotionsSummaryController','components/promotionsSummary/controller/PromotionModalController','components/promotionsSummary/directive/promotionsSummary','common/service/PromotionsService'],function(require) {
	"use strict";

	require("angular-sanitize");

	var formatMoney = require("common/filter/formatMoney"),
		PromotionsSummaryController = require("components/promotionsSummary/controller/PromotionsSummaryController"),
		PromotionModalController = require("components/promotionsSummary/controller/PromotionModalController"),
		promotionsSummary = require("components/promotionsSummary/directive/promotionsSummary"),
		PromotionsService = require("common/service/PromotionsService");

	var component = angular.module("mPortal.components.promotionsSummary", [
		"ngSanitize"
	]);

	component.directive("promotionsSummary", promotionsSummary);
	component.controller("PromotionsSummaryController", PromotionsSummaryController);
	component.controller("PromotionModalController", PromotionModalController);
	component.filter("formatMoney", formatMoney);
	component.factory("PromotionsService", PromotionsService);

	return component;

});
