define('components/specialtyApplication/questions/config/widgets/PropertyWidget',['require'],function(require) {
    "use strict";
    var widget = {};
    widget.getStructure = function() {

        return {
            "_datapointPlacement": "strictOrder",
            "Jan_propEnhancement" : { wrapper:  [ "janitorialPropOe" ] , wrapperOverride: true},
            "PC_propEnhancement" : { wrapper:  [ "pestControlPropOe" ] , wrapperOverride: true},
            "CC_propEnhancement" : { wrapper:  [ "childCarePropOe" ] , wrapperOverride: true},
            "SC_propEnhancement" : { wrapper:  [ "schoolsPropOe" ] , wrapperOverride: true},
            "HC_propEnhancement" : { wrapper:  [ "healthClubPropOe" ] , wrapperOverride: true},
            "GP_PropEnhancement" : { wrapper:  [ "genPkgOe" ] , wrapperOverride: true}
        };
    };
    widget.configureFormly = function (formlyConfig, formlyApiCheck) {
        formlyConfig.setWrapper({
            name: "janitorialPropOe",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Markel Janitorial Services Commercial Property Enhancement 8.22.18.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name:"pestControlPropOe",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Pest Control Property Enhancement v2.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name:"childCarePropOe",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Child Care Property Enhancement v2.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name:"schoolsPropOe",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Ed. Services Property Enhancement v1.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name:"healthClubPropOe",
            template:
                '<div class="control-group row-fluid">' +
                '<label style="display:inline !important; width:49%;margin-left:10px;font-weight: bold;" for="{{ id }}" class="control-label">{{ to.label }}' + '</label>' +
                '<formly-transclude></formly-transclude>' +
                '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Markel health club program enhancements.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >package options (pdf)</span>' +
                '</div>'
        });

        formlyConfig.setWrapper({
            name:"genPkgOe",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Property Enhancement 5.9.22.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        })
    };
    return widget;

    });
