define('common/service/interceptor/ProfileTokenInterceptor',[],function() {
    "use strict";

    ProfileTokenInterceptor.$inject = [ "$injector" ];

    /*
     This interceptor grabs an Arch session token (if it exists) from the UserProfile and passes it to Arch
     as a request header.  The profileToken should be present if the mPortal user is an authenticated user;
     unauthenticated users / insured registering for a user account should have a portalToken value.

     The token will be evaluated by a filter in the SingleSingOnFilter in Arch.
    */
    function ProfileTokenInterceptor( $injector ) {
        return {
            request: function( config ) {
                var userProfile = $injector.get( "UserProfile" );
                var requestToken = userProfile ? ( userProfile.profileToken ? userProfile.profileToken : userProfile.portalToken ) : "";
                config.headers[ "X-Profile-Token" ] =  requestToken;

                /*
                 The ajaxSetup invocation will ensure that any subsequent jQuery-based AJAX call, like those used in the legacy
                 "_directive" files used with Maverick submission screens, will also include the header. Invoking it every time
                 should also ensure the token value is not stale.
                */
                $.ajaxSetup(
                    {
                        cache: false,
                        headers: { "X-Profile-Token": requestToken }
                    }
                );

                return config;
            }
        };
    }

    return ProfileTokenInterceptor;

});
