define('common/directive/distractionFreeToggle',[],function() {
	"use strict";

	function DistractionFreeToggle() {
		return {
			restrict: "AE",
			scope: {},
			template: 
				'<i id="setDistractionFree"' +
					'ng-class="{ \'icon-chevron-up\': !isDistractionFree(), \'icon-chevron-down\': isDistractionFree() }"' +
					'ng-click="toggleDistractionFree()"' +
					'popover="Click to toggle distraction free mode"' +
					'popover-placement="left"' +
					'popover-append-to-body="true"' +
					'popover-trigger="mouseenter">' +
				'</i>',
			link: function( scope, element, attrs ) {
				var body = $("body");

				scope.isDistractionFree = function() {
					return body.hasClass("distraction-free");
				};

				scope.toggleDistractionFree = function() {
					body.toggleClass("distraction-free");
				};
			}
		};
	}

	return DistractionFreeToggle;

});
