define('components/quickLinks/app',['require','angular-sanitize','common/directive/app','components/lossRunReport/app','angular-utils-pagination','common/service/CommissionsService','common/service/search/SearchService','common/service/util/RenameKeys','components/quickLinks/directive/quickLinks','components/quickLinks/controller/QuickLinksController','components/quickLinks/controller/QuickEndorsementController','components/quickLinks/controller/CommissionsController','components/quickLinks/controller/BillingDocumentsController','components/quickLinks/controller/MassSubmissionController','components/quickLinks/controller/MakePaymentController','moment','common/controller/GenericModalController'],function(require) {
	"use strict";

	require("angular-sanitize");
	require("common/directive/app");
	require("components/lossRunReport/app");
	require("angular-utils-pagination");

	var CommissionsService = require("common/service/CommissionsService"),
		SearchService = require("common/service/search/SearchService"),
		RenameKeys = require("common/service/util/RenameKeys"),
		quickLinks = require("components/quickLinks/directive/quickLinks"),
		QuickLinksController = require("components/quickLinks/controller/QuickLinksController"),
		QuickEndorsementController = require("components/quickLinks/controller/QuickEndorsementController"),
		CommissionsController = require("components/quickLinks/controller/CommissionsController"),
		BillingDocumentsController = require("components/quickLinks/controller/BillingDocumentsController"),
		MassSubmissionController = require("components/quickLinks/controller/MassSubmissionController"),
	    MakePaymentController = require("components/quickLinks/controller/MakePaymentController"),
		moment = require("moment"),
		GenericModalController = require("common/controller/GenericModalController");

	var component = angular.module("mPortal.components.quickLinks", [
		"ngSanitize",
		"mPortal.directive", 
		"mPortal.components.lossRunReport","angularUtils.directives.dirPagination"
	]);

	component.factory("CommissionsService", CommissionsService);
	component.factory("SearchService", SearchService);
	component.factory("RenameKeys", RenameKeys);
	component.directive("quickLinks", quickLinks);
	component.controller("QuickLinksController", QuickLinksController);
	component.controller("QuickEndorsementController", QuickEndorsementController);
	component.value("moment", moment);
	component.controller("CommissionsController", CommissionsController);
	component.controller("BillingDocumentsController", BillingDocumentsController);
	component.controller("MassSubmissionController", MassSubmissionController);
	component.controller("MakePaymentController", MakePaymentController);
	component.controller("GenericModalController", GenericModalController);

	return component;

});
