define('components/accountSummary/quoteSummarySection/controller/QuoteSummarySectionController',['require'],function(require) {
    "use strict";

    QuoteSummarySectionController.$inject = ["$scope", "moment"];

    function QuoteSummarySectionController( $scope, moment ) {

        // Feature flag toggle for demonstrating future features
        $scope.showFutureControls = false;
        $scope.toggleFutureControls = function() {
            $scope.showFutureControls = !$scope.showFutureControls;
        };

        $scope.uiState = {
            showingExpired: false,
            selectedQuotes: [],
            hasCurrentQuotes: false,
            hasExpiredQuotes: false
        };

        $scope.markQuotesExpirationStatus = function() {
            for( var q = 0; q < $scope.quotes.length; q++ ) {
                if( moment( $scope.quotes[ q ].expirationDate, "MM/DD/YYYY" ).isAfter( moment().subtract(31, 'd'), 'days' ) ) {
                    $scope.quotes[ q ].expired = false;
                    $scope.uiState.hasCurrentQuotes = true;
                } else {
                    $scope.quotes[ q ].expired = true;
                    $scope.uiState.hasExpiredQuotes = true;
                }
            }
        };

        $scope.toggleExpiredVisibility = function() {
            $scope.uiState.showingExpired = !$scope.uiState.showingExpired;
        };

        $scope.showQuoteTable = function() {
            return $scope.uiState.showingExpired ? ( $scope.uiState.hasExpiredQuotes || $scope.uiState.hasCurrentQuotes ) : $scope.uiState.hasCurrentQuotes;
        };

        $scope.isVisible = function( quote ) {
            if( $scope.uiState.showingExpired ) {
                return true;
            }
            return !quote.expired;
        };

        $scope.isMultiButtonDisplayed = function() {
            for( var q = 0; q < $scope.quotes.length; q++ ) {
                if( $scope.quotes[ q ].status === "Quoted") {
                    return true;
                }
            }
            return false;
        };

        $scope.updateQuoteSelection = function() {
            var selectedQuotes = [];
            for( var q = 0; q < $scope.quotes.length; q++ ) {
                if( $scope.quotes[ q ].selected ) {
                    selectedQuotes.push( $scope.quotes[ q ].submissionId );
                }
            }
            $scope.uiState.selectedQuotes = selectedQuotes;
        };

        $scope.submitSelected = function() {
            // To be implemented with the multi-quote bind story
        };

        $scope.markQuotesExpirationStatus();

    }

    return QuoteSummarySectionController;

});
