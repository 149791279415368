define( 'components/specialtyApplication/quoteResult/controller/QuoteResultController',[],function () {

    QuoteResultController.$inject = [
        "$scope",
	    "$modal",
        "$location",
        "SubmissionStore",
        "SpecialtyDocumentService",
        "ResultService",
        "TeaLeafService",
        "QuoteResultService",
        "UserProfile",
        "$route",
        "moment",
		"$timeout"
    ];

    function QuoteResultController( $scope, $modal,
       $location,
       SubmissionStore,
       SpecialtyDocumentService,
       ResultService,
       TeaLeafService,
       QuoteResultService,
       UserProfile,
       $route,
       moment,
       $timeout
    ) {

        $scope.referForAgent = $scope.result.marketedProgram ? $scope.result.marketedProgram.referForAgents : false;
        $scope.referForInsured = $scope.result.marketedProgram ? $scope.result.marketedProgram.referForInsured : false;
        $scope.isReferredReason = $scope.result.outcomeReasons.length &&(((UserProfile.type == 'Agent' || UserProfile.agencyEmulation) && $scope.referForAgent ) || (UserProfile.type === 'Insured' && $scope.referForInsured))? true : false;
	    $scope.luxonData = {
		    luxon_id : null,
		    luxon_partition_key : null,
	    };
	    $scope.counter  = 1;
	    $scope.maxCount = 60;
	    $scope.processError = false;
        // The following scope variables and functions support the new recipient and document type delivery preferences
        $scope.docDeliveryDataAvailable = $scope.result.documentDeliveryConfiguration.success;
        $scope.docDeliveryConfig = $scope.result.documentDeliveryConfiguration.configuration;
        $scope.deliveryErrors = [];
        $scope.openElectronicDeliveryTermsModal = function() {
            $modal.open({
                templateUrl: "app/components/specialtyApplication/quoteResult/template/ElectronicDeliveryTermsModal.html",
                controller: "GenericModalController"
            });
        };
        if ($scope.result.marketedProgram.programCode === 'AM' || $scope.result.marketedProgram.programCode === 'Farm') {
            $scope.docDeliveryConfig["insured"]["policy"].value = "EM";
        }
        /*
         This document retrieval function should be called as soon as possible the during initial load and also upon the successful
         execution of any controller function that could result in a change to the quote status and returns a response to
         mPortal (like a "Request To Issue" action)
         */
        $scope.documentList = { loading: false };

        $scope.cataboom_url = null;
        $scope.cataboom_bind_data = null;

        $scope.check_cataboom = function() {  
            let applicationId = $scope.applicationId;
            let agent_id = window.userProfile.userId;
            let calling_system = "mpolicy";
            let submissionId = $scope.submissionId;

            QuoteResultService.check_bind_api(applicationId, agent_id, calling_system).then( function( check_bind_result ) {

                let records = check_bind_result;

                if( records && records.data && records.data.length > 0 )
                    {
        
                        $scope.show_cataboom = false;
                        let result = false;
        
                        QuoteResultService.check_cataboom_url(submissionId).then( function( res ) {
                            result = res;

                            if (res && !res.Error) { 
            
                                // Exclude AG goes here
                                if ($scope.result.marketedProgram.referenceString !== "horseMortality" &&
                                    $scope.result.marketedProgram.referenceString !== "Farriers" &&
                                    $scope.result.marketedProgram.referenceString !== "CommercialEquine" && 
                                    $scope.result.marketedProgram.referenceString !== "PrivateHorse" &&
                                    $scope.result.marketedProgram.referenceString !== "farm") {
                                        if($scope.result.masterquote.portalStatus === "Issued")
                                        {
                                            $scope.cataboom_url = result.response;                                                
                                            $scope.set_cataboom(true);
                                        }
                                }
                                
                            }
                        }).catch(function( err ) {
                            console.log('No URL data');
                        });
                    }

			}).catch(function( err ) {
				console.log('No data');
			});
  
        }

        $scope.set_cataboom = function(set_flag) {
            $scope.show_cataboom = set_flag;
        }

        $scope.getDocuments = function () {
            $scope.documentList.loading = true;
            /*
             This page should never display any loss run document (regardless of workflow rules), and it should not
             display the standard appmaster-generated Application form for upload-based submissions, so an exclusion list
             containing the names used such excluded documents in the different marketed programs is passed to the service
             */
            var exclusions = ( $scope.result.marketedProgram && $scope.result.marketedProgram.isUploadBasedSubmission ) ? [ 'Application', 'Loss Run', 'Loss Runs' ] : [ 'Loss Run', 'Loss Runs' ];
            SpecialtyDocumentService.getDocuments( $scope.applicationId, exclusions ).then(
                function ( result ) {
                    $scope.documentList.loading = false;
                    angular.extend( $scope.documentList, result );
                }
            );
        };
        $scope.getDocuments();

        // Set view model default values
        $scope.button_text = 'Buy Now';
        $scope.buyNowProcessing = false;
        $scope.isBuyNowButtonEnabled = true;
        $scope.showPaymentPlans = false;
        $scope.showAgreeToTerms = false;
        $scope.hasMultiplePaymentOptions = false;
        $scope.showBuyButton = false;
        $scope.hasLicenseError = false;
        $scope.non_bindable_quote = false;
        $scope.has_multi_policy_discount = $scope.result.isEligibleForMPD;
        $scope.multi_policy_discount = 0;
        $scope.accept_quote = false;
	    $scope.availableAction = false;
        $scope.effectiveDateValidation = false;

        $scope.show_cataboom = false;

	    angular.forEach( $scope.result.availableActions, function ( item ) {

		    if ( item.action === "Bind Request" ) {
			    $scope.availableAction = true;
		    }
	    } );

	    $scope.luxonData.luxon_id = $scope.result.masterquote.luxonId;
	    $scope.luxonData.luxon_partition_key = $scope.result.masterquote.luxonPartitionKey;
        $scope.effectiveDate = $scope.result.masterquote.effdate;
	    $scope.showBillingType = !($scope.result.paymentOptions.allowOnlinePayments);
	    $scope.abbreviation = $scope.result.marketedProgram ? $scope.result.marketedProgram.abbreviation : '';
	    if($scope.abbreviation ==='DP' || $scope.abbreviation ==='BD' || $scope.abbreviation === 'MEDTRAN' || $scope.abbreviation === 'EO'  || $scope.abbreviation === 'MISCEO' ){
	        $scope.showBillingType = false;
        } else if($scope.abbreviation === 'INVEST'){
            var releaseDate = new Date("2022-12-01");
            var effdate = new Date($scope.effectiveDate);
            if(effdate >= releaseDate){
                $scope.showBillingType = true;
            }
            else{
                $scope.showBillingType = false;
            }
        }
	    $scope.disableBillingType = $scope.result.paymentOptions.allowOnlinePayments;
	    $scope.billingType = $scope.result.paymentOptions.allowOnlinePayments ? "D" : "A";
	    $scope.billingTypes = [{label: 'Agency', billingType: 'A'}, {label: 'Direct', billingType: 'D'}];

	    $scope.changeBillType = function ( submissionId, billingplan, billingType ) {

		    ResultService.changeBillType( submissionId, billingplan, billingType ).then( function ( result ) {

			    if ( result === 200 ) {
				    $scope.result.paymentOptions.allowOnlinePayments = billingType === "D";
				    $scope.showPaymentPlans = $scope.result.paymentOptions.allowOnlinePayments;
				    $scope.paymentData.paymentOptions.allowOnlinePayments = $scope.result.paymentOptions.allowOnlinePayments;
				    $scope.hasMultiplePaymentOptions = $scope.paymentData.paymentOptions.allowOnlinePayments && $scope.paymentData.paymentOptions.allowBillMeLater;
				    $scope.disableBillingType = $scope.result.paymentOptions.allowOnlinePayments;
				    if ( $scope.result.paymentOptions.allowOnlinePayments === false &&
						    $scope.result.isAppCompleteForBind === false &&
						    $scope.availableAction ) {
					    $scope.result.allowAcceptQuote = true;
				    }
				    else $scope.result.allowAcceptQuote = false;
				    $scope.changeButtonText();
			    } else {
				    $scope.billingType = "A";
			    }

                if( billingType === "A" ) {
                    $scope.docDeliveryConfig[ "agency" ][ "billing" ].hidden = false;
                    $scope.docDeliveryConfig[ "insured" ][ "billing" ].value = "NO";
                    $scope.docDeliveryConfig[ "insured" ][ "billing" ].changed = true;
                    $scope.docDeliveryConfig[ "insured" ][ "billing" ].hidden = true;
                } else {
                    $scope.docDeliveryConfig[ "agency" ][ "billing" ].hidden = true;
                    $scope.docDeliveryConfig[ "insured" ][ "billing" ].value = $scope.docDeliveryConfig[ "insured" ][ "billing" ].agencyDefault;
                    $scope.docDeliveryConfig[ "insured" ][ "billing" ].hidden = false;
                }

		    } );
	    };

        if( $scope.result.pricingDetails ) {
            $scope.pricingProgram = $scope.result.pricingDetails.programCode;
            $scope.pricingData = $scope.result.pricingDetails.data;
        }

        $scope.paymentData = {
            paymentPlan: "F",
            paymentPlanDesc: "Full Payment",
            paymentMethod: "Pay Now",
            paymentOptions: {}

        };

        // Assign view model values based on result object added to the scope via the directive
        $scope.quotePhase = $scope.result.masterquote.phase;
        $scope.phaseName = $scope.result.masterquote.phaseName;
        /*
         If hasPhaseQuestions = true (there are questions only presented to the  user at this phase of the quote lifecycle),
         the SubmissionStore "phase" and "preBindQuestionsAnswered" will impact whether the user is presented with
         additional questions to answer before payment can be completed. The phase value will also affect the display of UI
         elements on the view where the additional questions are displayed
         */
        $scope.hasPhaseQuestions = $scope.result.hasPhaseQuestions;
        $scope.applicant = $scope.result.insured.name;
        $scope.paymentProcessed = $scope.result.masterquoteinstance.paymentProcessed;
        $scope.lockedByUnderwriter = $scope.result.isLocked;
	    $scope.isAvailableForBind = false;
	    $scope.isGenPackageQuote = $scope.result.marketedProgram.programCode === 'GenPackage';
        $scope.agentCanEdit = $scope.result.agentCanEdit;
        $scope.submissionId = $scope.result.masterquote.masterquoteid;

        $scope.quoteInfo = {
            quotes: $scope.result.masterquote.quoteset,
            isRenewal: $scope.result.masterquote.renewal,
            tax: $scope.result.masterquoteinstance.writtentaxchange,
            totalPremium: $scope.result.masterquoteinstance.writtentotal,
            status: $scope.result.masterquote.portalStatus
        };
        if ( moment( $scope.effectiveDate, "YYYY-MM-DD" ).isBefore( moment(Date.now()).add($scope.result.programMinQuoteEffDate+1,'days').format( "YYYY-MM-DD" ) ) ) {
            $scope.effectiveDateValidation = true;
        }

	    $scope.showAdditionalQuestionsButton = $scope.hasPhaseQuestions && !(($scope.quoteInfo.isRenewal === true) && ($scope.result.marketedProgram.programCode === 'ChildCare' || $scope.result.marketedProgram.abbreviation === 'INHOME'));
        $scope.masterquoteStatus = $scope.result.masterquote.status;
        $scope.accountId = $scope.submissionId.split( "-" )[0];
        $scope.isQuoteAvailableForBind = $scope.result.isQuoteAvailableforBind;

        $scope.isSwitchingBillTypeAllowed = function(){
            return  ( $scope.isQuoteAvailableForBind && ( UserProfile.type === 'Agent' || ( UserProfile.type === 'Internal' && UserProfile.agencyEmulation )));
        };

        $scope.messageUW = function( accountId, submissionId , isApplicationUpload ) {
            var url = "/account-summary/" + accountId + "/new-message/" + submissionId;

            /*For Upload Application the value will be true and for the rest of scenarios value it as will be as false.*/
            if(isApplicationUpload)
            {
                url += "/application"
            }

            $location.path( url );
        };

        $scope.setMessageView = function(accountId, submissionId)
        {
            var url = "/account-summary/" + accountId + "/new-message/" + submissionId + "/reason/requestChange";
            $location.path( url );
        };

        $scope.pageState = {
            quoteRequestError: $scope.result.status !== 200,
            showDecline : false,
            showReferral : false,
            showQuote : false,
            showSuccess : false,
            showBindPromo: false,
            showLocked : false,
            showPurchased: false,
            isTerrorismVerbiageRequired: $scope.result.marketedProgram ? $scope.result.marketedProgram.isTerrorismVerbiageRequired : UserProfile.isTerrorismVerbiageRequired,
            showEditSubmission: false,
            paymentSuccessBindFailed: false
        };
        $scope.contactPhone = $scope.result.marketedProgram ? $scope.result.marketedProgram.contactPhone : UserProfile.contactPhone;
        $scope.contactEmail = $scope.result.marketedProgram ? $scope.result.marketedProgram.contactEmail : UserProfile.contactEmail;

        if( UserProfile.cobranding.id ) {
            $scope.contactPhone = UserProfile.contactPhone
        }

        if( $scope.contactPhone == "" ) {
            $scope.contactPhone = '888-500-3344'
        }

        // Set payment data
        angular.extend( $scope.paymentData.paymentOptions, $scope.result.paymentOptions );
        if ( !$scope.paymentData.paymentOptions.allowOnlinePayments ) {
            $scope.paymentData.paymentMethod = "Bill Me Later";

        }
        // Set the payment plan automatically if there's only one
        if ( $scope.paymentData.paymentOptions.billingPlans.length === 1 ) {
            $scope.paymentData.paymentPlan = $scope.paymentData.paymentOptions.billingPlans[0].billingplan;
            $scope.paymentData.paymentPlanDesc = $scope.paymentData.paymentOptions.billingPlans[0].description;
        }
	        $scope.isRenewalBindAvailable = $scope.quoteInfo.isRenewal ? $scope.result.marketedProgram.isRenewalBindAvailable : true;
            $scope.showPaymentPlans = $scope.paymentData.paymentOptions.allowOnlinePayments;
            $scope.showAgreeToTerms = $scope.paymentData.paymentOptions.allowOnlinePayments || $scope.paymentData.paymentOptions.allowBillMeLater;
            $scope.hasMultiplePaymentOptions = $scope.paymentData.paymentOptions.allowOnlinePayments && $scope.paymentData.paymentOptions.allowBillMeLater;
            $scope.showBuyButton = $scope.paymentData.paymentOptions.allowOnlinePayments || $scope.paymentData.paymentOptions.allowBillMeLater;
            //Disabling the Buy Now button if bind action is not allowed for the current user
            var availableActions = $scope.result.availableActions;
            $scope.isBindAllowed = false;
            for( var i=0;i < availableActions.length; i++ ){
                var action = availableActions[i].action.toLowerCase();

                switch(action) {
                    case 'bind':
                        $scope.isBindAllowed = true;
                    break;
                }
            }
            $scope.isBuyNowButtonEnabled = ($scope.paymentData.paymentOptions.isLicenseValid && $scope.isBindAllowed) || ($scope.paymentData.paymentOptions.isLicenseValid && $scope.accept_quote)

        $scope.hasLicenseError = !$scope.paymentData.paymentOptions.isLicenseValid;

        /**
         * The logic in this method determines if the user will see a Denial, Referral or Quote
         */
        $scope.changeButtonText= function(){
            $scope.accept_quote = false;
            $scope.button_text = 'Buy Now';
            if($scope.result.isAppCompleteForBind == false)
            {
                if($scope.result.paymentOptions.isDelayedPaymentAvailable)
                {
                    //show text
                    $scope.non_bindable_quote = true;

                    //allow accept quote?
                    if($scope.result.allowAcceptQuote)
                    {
                        $scope.accept_quote = true;
                        $scope.button_text = 'Accept Quote';
                    }

                }
            }
        };

        $scope.setPageState = function () {
                $scope.pageState.showLocked = true;
	            SubmissionStore.submissionId = $scope.submissionId;
                /*
                Note: the SubmissionStore will not necessarily have the correct applicationId, since we don't want to
                set it to match the applicationId passed into this directive until we want to perform an action on the
                submission.  But we can update the submissionId / master quote ID since that is not used to drive any
                actions.
                */
                SubmissionStore.submissionId = $scope.submissionId;
                TeaLeafService.sendCustomEvent( 'PortalResult.SubmissionLocked', SubmissionStore );
            if ( $scope.paymentProcessed && $scope.quotePhase < 25 ) {
                $scope.pageState.paymentSuccessBindFailed = true;
            }
             else if ( $scope.paymentProcessed || $scope.quotePhase > 25 ) {
                $scope.pageState.showSuccess = true;
                $scope.check_cataboom();

	             // Very quick and dirty logic to display a promotional message in the page.
                if( ( UserProfile.type === 'Agent' || UserProfile.agencyEmulation ) && $scope.result.masterquoteinstance.bounddate ){
	                if( [
			                "studentAccident",
			                "cheer",
			                "childCareCommercial",
			                "afds",
			                "dance",
			                "schoolsPrivate",
			                "gymnastics",
			                "healthClubs",
			                "humanServices",
			                "museums",
			                "janitorial",
			                "lawnCare",
			                "martialArts",
			                "pestControl"
			           ].indexOf( $scope.result.marketedProgram ? $scope.result.marketedProgram.referenceString : 'none' ) >= 0 ) {
			                var bindDate = moment( $scope.result.masterquoteinstance.bounddate.substring( 0, 10 ), "YYYY-MM-DD" );
			                if ( bindDate.isBefore( moment( "2024-12-31" ) ) && bindDate.isAfter( "2024-12-03" ) ) {
				                $scope.pageState.showBindPromo = true;
			                }
			        }
                }
            }
            else if ( $scope.quotePhase === 20 ) {
                $scope.pageState.showQuote = true;
                $scope.pageState.showEditSubmission = $scope.agentCanEdit && UserProfile.type == "Agent";
            }
            else if ( $scope.quotePhase === 25 ) {
                // The presence of a payment profile determines what page state to display
                if( $scope.result.paymentOptions.hasPaymentProfileId ) {
                    $scope.pageState.showPurchased = true;
                } else {
                    $scope.pageState.showQuote = true;
                    $scope.showBuyButton = false;
                    $scope.showAgreeToTerms = false;
                    $scope.pageState.showAccepted = true;
                }
            }
            else if ( $scope.quotePhase <= 0 ) {
                $scope.pageState.showDecline = true;
                $scope.pageState.showEditSubmission = $scope.agentCanEdit && UserProfile.type == "Agent";
            } else {
                $scope.pageState.showReferral = true;
                $scope.pageState.showEditSubmission = $scope.agentCanEdit && UserProfile.type == "Agent";
            }

            //oooook few things to check here!
            $scope.changeButtonText();

            //MMMMMPPPPPPPPDDDDDDDDD
            if($scope.has_multi_policy_discount)
            {
                $scope.multi_policy_discount = $scope.result.quoteinstances[0].multiPolicyDiscount;
            }

        };

        $scope.setPageState();

        $scope.getDocument = function ( name, retrievalURL ) {
            SpecialtyDocumentService.getDocument( name, retrievalURL );
        };

	    $scope.showQuoteStatusErrorModal = function () {
		    $scope.processError = true;
		    $scope.buyNowProcessing = false;
		    var data = {
			    contact: $scope.contactPhone,
			    processError: $scope.processError
		    };
		    $modal.open( {
			    templateUrl: "app/components/specialtyApplication/quoteResult/template/IsLocked.html",
			    controller: "ConfirmController",
			    resolve: {
				    submissionInfo: function () {
					    return data;
				    }
			    }
		    } );
	    }

	    $scope.getQuoteStatus = function () {
		    if($scope.isGenPackageQuote){
			    ResultService.getStatusSummary( $scope.applicationId ).then( function ( response ) {
				    if ( response.error ) {
					    $scope.showQuoteStatusErrorModal();
				    } else {
					    $scope.isAvailableForBind = response.data[0].isAvailableForBind;
					    if ( $scope.isAvailableForBind ) {
						    $scope.processPurchase();
					    } else {
						    if ( $scope.counter <= $scope.maxCount ) {
							    $timeout( function () {
								    $scope.counter++;
								    $scope.getQuoteStatus()
							    }, 10000 );
						    } else {
							    $scope.showQuoteStatusErrorModal();
						    }
					    }
				    }
			    } );
		    } else{
			    $scope.isAvailableForBind = true;
			    $scope.processPurchase();
		    }

	    }

	    $scope.confirmBuyNow = function () {
		    var isInsured = (UserProfile.type === 'Agent' || UserProfile.agencyEmulation) ? false : true;
	    	ResultService.getStatus( $scope.applicationId ).then( function ( response ) {
			    if ( response.error ) {
				    $scope.uiState.errorType = "resultError";
				    // A 500 error will display the error modal window, so no point in waiting to show the error
				    if ( response.status == 500 || $scope.uiState.stage == "retryingSubmit" || ($scope.uiState.stage == "continue" && $scope.uiState.messageIndex) ) {
					    $scope.uiState.stage = "showError";
				    }
			    } else {
				   $scope.isLocked =  response.data[0].isLocked
			    }

		    if($scope.isLocked){
                   var data = {
                   	contact : $scope.contactPhone,
	                processError   :  $scope.processError
                   };
			    if( $scope.docDeliveryDataAvailable ) {
				    $scope.deliveryErrors = [];
				    ResultService.saveSubmissionDeliveryPreferences( $scope.result.masterquote.masterquoteid, $scope.docDeliveryConfig ).then( function ( result ) {
					    if ( result.error ) {
						    $scope.deliveryErrors = result.errorMessages;
					    }
				    } );
			    }

			    $modal.open( {
				    templateUrl: "app/components/specialtyApplication/quoteResult/template/IsLocked.html",
				    controller: "ConfirmController",
				    resolve: {
					    submissionInfo: function () {
						    return data;
					    }
				    }
			    });
            }else if ( isInsured ) {
			    $scope.buyNow();
		    }
		    else {
			    var data = {
				    effectiveDate: moment( $scope.effectiveDate ).format( "MM-DD-YYYY" ),
				    accountId: $scope.submissionId.split( "-" )[0],
				    submissionId: $scope.submissionId,
				    email: $scope.contactEmail
			    };
				if(!$scope.quoteInfo.isRenewal) {
					$modal.open( {
						templateUrl: "app/components/specialtyApplication/quoteResult/template/ConfirmTemplate.html",
						controller: "ConfirmController",
						resolve: {
							submissionInfo: function () {
								return data;
							}
						}
					} );
				}else{
					$scope.buyNow();
				}
		    }
		    });
	    };

	    $scope.buyNow = function () {
            $scope.buyNowProcessing = true;
            $scope.isBuyNowButtonEnabled = false;
            if( $scope.docDeliveryDataAvailable ) {
                $scope.deliveryErrors = [];
                ResultService.saveSubmissionDeliveryPreferences( $scope.result.masterquote.masterquoteid, $scope.docDeliveryConfig ).then( function ( result ) {
                    if( result.error ) {
                        $scope.deliveryErrors = result.errorMessages;
                        //renable the buttons baby
                        $scope.buyNowProcessing = false;
                        $scope.isBuyNowButtonEnabled = true;
                    } else {
                        $scope.processPurchase();
                    }
                });
            } else {
                $scope.processPurchase();
            }
	    };

        $scope.processPurchase = function() {
	        /*
			 Update the SubmissionStore with values tied to this particular application, so that if the user navigates
			 to the question route or the payment route those controllers will have the correct information to work
			 with in the SubmissionStore.
			 */
	        if ( $scope.isAvailableForBind ) {
		        $scope.modifyStoreForThisApplication();
		        $scope.showAdditionalQuestions = !(($scope.quoteInfo.isRenewal === true) && ($scope.result.marketedProgram.programCode === 'ChildCare' || $scope.result.marketedProgram.abbreviation === 'INHOME')); //used abbreviation for INHOME because the program code AFDS is common for few other marketed programs
		        if ( $scope.paymentData.paymentMethod == "Pay Now" ) {
			        SubmissionStore.preBindPayment[$scope.applicationId] = true;
			        if ( $scope.hasPhaseQuestions && !SubmissionStore.preBindQuestionsAnswered[$scope.applicationId] && $scope.showAdditionalQuestions ) {
				        SubmissionStore.preBindEdit[$scope.applicationId] = true;
				        return $location.path( "/application/additional-questions" );
			        } else {
				        SubmissionStore.preBindEdit[$scope.applicationId] = false;
				        return $location.path( "/application/payment" );
			        }
		        } else {
			        SubmissionStore.preBindPayment[$scope.applicationId] = false;
			        if ( $scope.hasPhaseQuestions && !SubmissionStore.preBindQuestionsAnswered[$scope.applicationId] && $scope.showAdditionalQuestions ) {
				        SubmissionStore.preBindEdit[$scope.applicationId] = true;
				        return $location.path( "/application/additional-questions" );
			        } else {
				        if ( $scope.accept_quote || ($scope.paymentData.paymentMethod == "Bill Me Later" && $scope.non_bindable_quote) ) {
					        ResultService.acceptSubmission( SubmissionStore.applicationId ).then( function ( result ) {
						        $route.reload();
					        } );
				        } else {
					        SubmissionStore.preBindEdit[$scope.applicationId] = false;
					        ResultService.bindSubmission( SubmissionStore.applicationId, SubmissionStore.paymentPlan ).then( function ( result ) {
						        $route.reload();
					        } );
				        }
			        }
		        }
	        } else {
		        $scope.getQuoteStatus();
	        }
        };

	    $scope.$on( 'buyNow', $scope.buyNow );
        $scope.modifyStoreForThisApplication = function() {
            SubmissionStore.applicationId = $scope.applicationId;
            SubmissionStore.activeApplication = $scope.applicationId;
            SubmissionStore.marketedProgramId = $scope.marketedProgramId;
            SubmissionStore.paymentPlan = $scope.paymentData.paymentPlan;
            SubmissionStore.phase = $scope.quotePhase;
            SubmissionStore.delayedPaymentFlag = $scope.result.paymentOptions.isDelayedPaymentAvailable && $scope.non_bindable_quote;


            if ( !SubmissionStore.preBindPayment[ $scope.applicationId ] ) {
                SubmissionStore.preBindPayment[ $scope.applicationId ] = false;
            }
            if ( !SubmissionStore.preBindQuestionsAnswered[ $scope.applicationId ] ) {
                SubmissionStore.preBindQuestionsAnswered[ $scope.applicationId ] = false;
            }
            if ( !SubmissionStore.preBindEdit[ $scope.applicationId ] ) {
                SubmissionStore.preBindEdit[ $scope.applicationId ] = false;
            }
        };

	    $scope.isAnAuthenticatedUser = function() {
		    return $scope.agentCanEdit && UserProfile.type === "Agent"
	    };

        $scope.isDirect = function() {
            return  ((UserProfile.type !== "Agent" && !$scope.agentCanEdit)||(UserProfile.type !== "Internal" && !$scope.agentCanEdit))
        };

        $scope.reloadResults = function() {
            $route.reload();
        };

	    $scope.openAdditionalQuestionsPage = function() {
		    return $location.path( "/application/additional-questions" ).search({ save : true })
	    };

        $scope.open_cataboom = function() {
            var result_url = $scope.cataboom_url;
		    window.open(result_url, "cataboomWindow", "width=800, height=1060");
            $('#cataboom-btn').prop("disabled",true);
	    };

    }

    return QuoteResultController;

} );
