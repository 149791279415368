define('components/applicationData/applicationData/config/widgets/ViewAdditionalInsuredWidget',['require'],function(require) {
	"use strict";

	var widget = {};

	widget.getStructure = function () {

		return {
			"viewAdditionalInsuredInfo": {wrapper: null, fieldGroup:
						{
							"viewAddLocationAddress1": {wrapper: ["AdditionalInsured"],type: "setAdditionalInsuredInfo", wrapperOverride: true},
							"viewAddLocationAddress2": {wrapper: ["AdditionalInsured"], type: "setAdditionalInsuredInfo", wrapperOverride: true},
							"viewAddLocationZip": {wrapper: ["AdditionalInsured"], type: "setAdditionalInsuredInfo",  wrapperOverride: true},
							"viewAddLocationCity": {wrapper: ["AdditionalInsured"],type: "setAdditionalInsuredInfo", wrapperOverride: true},
							"viewAddLocationState": {wrapper: ["AdditionalInsured"], type: "setAdditionalInsuredInfo", wrapperOverride: true},
							"viewAddLocationCounty": {wrapper: ["AdditionalInsured"], type: "setAdditionalInsuredInfo",  wrapperOverride: true},
							"viewAddInsuredName": {wrapper: ["AdditionalInsured"], type: "setAdditional_InsuredInfo",  wrapperOverride: true},
							"viewAddInsuredType": {wrapper: ["AdditionalInsured"], type: "setAdditional_InsuredInfo",  wrapperOverride: true},
							"viewNoOfAddInsuredName": {wrapper: ["AdditionalInsured"], type: "setAdditional_InsuredInfo",  wrapperOverride: true},
							"viewNoOfAddInsuredType": {wrapper: ["AdditionalInsured"], type: "setAdditional_InsuredInfo",  wrapperOverride: true},
							"viewAddInsuredTypeDesc": {wrapper: ["AdditionalInsuredInfoDesc"], type: "setAdditional_InsuredInfo",  wrapperOverride: true},
							"viewAddInsuredAccount": {wrapper: ["AdditionalInsuredInfoDesc"], type: "setAdditional_InsuredInfo",  wrapperOverride: true}
						}
			}
		};
	};

	widget.configureFormly = function (formlyConfig, formlyApiCheck) {

		formlyConfig.setType({
			name: "setAdditionalInsuredInfo",
			template:'<formly-transclude></formly-transclude>'

		});

		formlyConfig.setWrapper({
			name: "AdditionalInsured",
			template: '<formly-transclude></formly-transclude>'
		});

		formlyConfig.setType({
			name: "setAdditional_InsuredInfo",
			template:'<formly-transclude></formly-transclude>'
	});

		formlyConfig.setWrapper({
			name: "AdditionalInsuredInfoDesc",
			template: '<div class="viewItem" ng-if="!options.data.hide">' +
			'<div class="row-fluid">'+
			'<div class="span3" style="width:12%; margin-left: 5px">' +
			'<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
			'<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
			'</div>' +
			'<div class="span9" style="width:10%; margin-left: 8px">' +
			'{{::options.defaultValue}}' +
			'</div>' +
			'</div>' +
			'</div>'+  '<formly-transclude></formly-transclude>'
		});

	};

	return widget;
});
