define('common/controller/GenericModalController',['require'],function(require) {
	"use strict";

	GenericModalController.$inject = ["$scope", "$modalInstance"];

	function GenericModalController( $scope, $modalInstance ) {
		$scope.close = $modalInstance.close;
	}

	return GenericModalController;

});
