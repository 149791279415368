define('components/accountSummary/quoteSummarySection/directive/quoteSummarySection',['require'],function(require) {
    "use strict";

    function QuoteSummarySection() {
        return {
            restrict: "AE",
            scope: {
                quotes: "=",
                account: "=",
                quoteType: "@",
                openTab: "=",
                setMessageView: "=",
                user: '=',
                openApplicationTab: '='
            },
            templateUrl: "app/components/accountSummary/quoteSummarySection/template/template.html",
            controller: "QuoteSummarySectionController"
        };
    }

    return QuoteSummarySection;

});
