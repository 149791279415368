define('components/specialtyApplication/questions/config/widgets/RiskManagementWidget',['require'],function(require) {
	"use strict";

	var widget = {};

	widget.getStructure = function() {

		return {
			"_datapointPlacement": "strictOrder",
			"BB Checklist" : {  type: "yesNoToggle", wrapper: [ "verticalFullRowRadioBrochure", "defaultBootstrapHasError", "brochureUpload" ], wrapperOverride: true,
				data: {
					fileUploadSettings: {
						documentType: "Brochure MSC",
						documentTypeDescriptor: "file(s)",
						buttonLabel: "Upload",
						modalTitle: "Upload File"
					} }
			}

		};
	};

	widget.configureFormly = function (formlyConfig, formlyApiCheck) {

		formlyConfig.setWrapper({
			name: "brochureUpload",
			overwriteOk: true,
			template:
			'<div>' +
			'<formly-transclude></formly-transclude>' +
			'<div style="margin-left: 10px" class="row-fluid document-upload" ng-if="options.defaultValue === \'Yes\'">' +
			'<document-upload settings="options.data.fileUploadSettings" datapoint-applications="options.data.applications"></document-upload>' +
			'</div>' +
			'</div>'
		});

		formlyConfig.setWrapper({
			name: "verticalFullRowRadioBrochure",
			overwriteOk: true,
			template:
			'<div class="control-group row-fluid">' +
				'<div class="span6">' +
					'<label for="{{ id }}" style="font-weight: bold;" class="control-label">{{ to.label }}</label>' +
				'</div>' +
				'<div class="span6 field-wrapper">' +
					'<div class="help-container help-{{ options.type | lowercase }}"><formly-transclude></formly-transclude>' +
						'<i ng-if="to.help" class="glyphicon glyphicon-question-sign field-help" ' +
						'popover="{{ to.help }}" ' +
						'popover-trigger="mouseenter" ' +
						'popover-placement="bottom" ' +
						'tooltip-class="custom-popover"' +
						'></i>' + '</div>' +
				'</div>' +
			'</div>'
		});

	};

	return widget;


});
