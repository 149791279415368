define('accountSummary/controller/InsuredAccountSummaryController',['require'],function(require) {
	"use strict";

	InsuredAccountSummaryController.$inject = ["$scope", "AccountService", "TabManagerService", "UserProfile", "UserService", "$filter", "$q", "$sce"];

	function InsuredAccountSummaryController( $scope, AccountService, TabManagerService, UserProfile, UserService, $filter, $q, $sce ) {

		$scope.user = UserProfile;

		$scope.status = {
			notFound: false,
			noAccessiblePolicies: false,
			serverDown : false
		};

		$scope.messageVisible 					= false;
		$scope.isFilteredClaimMessageVisible 	= false;
		$scope.bundle_payment_visible 			= false;
		$scope.tabs 							= TabManagerService.initializeTabs([ "summary","message","submissions","policies","occurrence" ]);
		
		$scope.openPolicyTab 					= TabManagerService.openTab.bind( null, "policies" );
		$scope.openSubmissionTab 				= TabManagerService.openTab.bind( null, "submissions" );

		$scope.closePolicyTab 					= TabManagerService.closeTab.bind( null, "policies" );
		$scope.closeSubmissionTab 				= TabManagerService.closeTab.bind( null, "submissions" );

		//create summary tab
		TabManagerService.createTab('summary',1);

		$scope.showLossRuns = function(){
			let viewLossRuns = false;
			if(localStorage.getItem('mportal-show-loss-runs'))
			{
				viewLossRuns = localStorage.getItem('mportal-show-loss-runs') === 'true';
			}	
			return viewLossRuns;
		}

		$scope.loadAccounts = function() {

			var promiseArray = [
				AccountService.getInsuredAccountSummary( )
			];

			if($scope.showLossRuns())
			{
				promiseArray.push( AccountService.searchGraphQLClaims ( {} ));
			}

			$q.all( promiseArray ).then( function( results ) {
				/*
				The accounts array can return empty if:
				1) The insured has no Maverick polices, recent or otherwise
				2) The insured has no mPolicy policies with an effectiveDate within the past 2 years
				*/
				$scope.insuredAccounts = results[0].summaries;
				$scope.claims   	   = results[0].claims;
				
				if($scope.showLossRuns())
				{
					$scope.claims   	   = results[0].claims;
					for(var i = 0; i < results[1].length; i++)
					{
						$scope.claims.push(results[1][i]);
					}
				}
				
				if( $scope.insuredAccounts.length == 0 ) {
					$scope.status.noAccessiblePolicies = true;
				} else {
					// if we have a maverick account, use that for the insured info
					var account = $scope.insuredAccounts.find(function( account ) {
						return account.accountViewVersion === 1;
					});

					// otherwise, just grab the first account in the list for insured info
					if( !account ) {
						account = $scope.insuredAccounts[ 0 ];
					}

					$scope.account = account;

					var filtered_claim_states = ['AK','MT','OR','ID'];
					var agentInsuredPolicyCount = 0;
					var policyCount = 0;
					$scope.insuredAccounts.forEach(function (account) {
						if (filtered_claim_states.indexOf(account.state) != -1&& !$scope.isFilteredClaimMessageVisible) {
							$scope.isFilteredClaimMessageVisible = true;
						}
						account.policies = $filter("orderBy")(account.policies, ["-sortableEffectiveDate", "+policyNumber"]);
						policyCount += account.policies.length;
						agentInsuredPolicyCount += $filter( "filter" )( account.policies, { insuredType: "Agent" }).length;
					});

					$scope.$on( 'bundle_payment_visible', function ( event, args ) {
						$scope.bundlePaymentVisible = args;
						if ( $scope.bundlePaymentVisible === true ) {
							$scope.account.showCurrentDue = false;
							$scope.account.showPastDue = false;
						}
						else {
							$scope.account.showCurrentDue = true;
							$scope.account.showPastDue = true;

							if ( $scope.account.policies.length === 0 ) {
								$scope.account.showCurrentDue = false;
								$scope.account.showPastDue = false
							}
							angular.forEach( $scope.accounts, function ( account) {
								angular.forEach( account.policies, function ( policy ) {
									if ( policy.billingSystem === 'ICON' || policy.billingSystem === 'MAVERICK' || policy.billType === 'Agency bill') {
										$scope.account.showCurrentDue = false;
										$scope.account.showPastDue = false;
									}
								} );
							});

						}
					} );

					// Use the policy counter values to determine the proper insuredType value for the insured user
					UserService.setUserInsuredTypeByPolicyCounts( policyCount, agentInsuredPolicyCount, UserProfile );

					/*
					Accounts should be ordered based on policy system, with Maverick accounts listed first, mPolicy accounts
					(with or without ICON account data) listed next, and ICON accounts with no corresponding mPolicy account
					data listed last.  The following sort will achieve that.
					*/
					$scope.accounts = $filter( "orderBy" )( $scope.insuredAccounts, [ "+accountViewVersion", "(accountId === piNumber)", "+accountId" ] );

					angular.forEach( $scope.accounts, function ( account) {
						angular.forEach( account.policies, function ( policy ) {
							policy.account = account;
						});
					});

					$scope.accountIds = $scope.accounts.map( function( account ) {
						return account.accountId;
					});

					//create message tab
					TabManagerService.createTab('message',$scope.account.accountId);
				}

			}).catch(function( err ) {
				if( err.status === 404 ) {
					$scope.status.notFound = true;
				}
				if( err.status >= 500 ) {
					$scope.status.serverDown = true;
				}
			});
		};

		$scope.openSummaryTab = function(e){
			TabManagerService.openTab('summary',1);
		}

		$scope.openMessageTab = function(e){
			TabManagerService.openTab('message',$scope.account.accountId);
		}

		$scope.closeMessageTab = function ( event ) {
			//close message tab
			TabManagerService.closeTab('message',$scope.account.accountId);
			//create message tab because reasons
			TabManagerService.createTab('message',$scope.account.accountId);
			$scope.messageVisible = false;
			$scope.openSummaryTab();
		}

		$scope.openClaimDetail = function(claimId, program, agencyId) {
			if(program == "WC") {
				AccountService.getClaim(claimId).then(function (result) {
					$scope.claims = result;
					if ($scope.claims) {
						if ($scope.claims.tab_title) {
							$scope.claims.tab_title = $sce.trustAsHtml($scope.claims.tab_title);
						}
						if($scope.claims.insured.address){
							$scope.claims.insured.address = $sce.trustAsHtml($scope.claims.insured.address);
						}
						if($scope.claims.insured.contact){
							$scope.claims.insured.contact = $sce.trustAsHtml($scope.claims.insured.contact);
						}
						if($scope.claims.claim.closeDate == 'N/A'){
							$scope.claims.claim.closeDate = $scope.claims.claim.claimOpenDays + ' Days Open';
						}
						if($scope.claims.payment.medical != undefined){
							if (isNaN($scope.claims.payment.medical)) {
								$scope.claims.payment.medical = '$0.00';
							} else{
								$scope.claims.payment.medical = '$' + parseFloat($scope.claims.payment.medical).toFixed(2);
							}
						}
						if($scope.claims.payment.expense != undefined){
							if (isNaN($scope.claims.payment.expense)) {
								$scope.claims.payment.expense = '$0.00';
							} else{
								$scope.claims.payment.expense = '$' + parseFloat($scope.claims.payment.expense).toFixed(2);
							}
						}
						if($scope.claims.payment.indemnity != undefined){
							if (isNaN($scope.claims.payment.indemnity)) {
								$scope.claims.payment.indemnity = '$0.00';
							} else{
								$scope.claims.payment.indemnity = '$' + parseFloat($scope.claims.payment.indemnity).toFixed(2);
							}
						}
						if ($scope.claims.claim.policyNumber) {
							TabManagerService.initializeTabs(["claims"]);
							TabManagerService.openTab("claims", $scope.claims.claim.policyNumber, $scope.claims);
						}
					}
				});
			} else if(program == "BOP"){
				AccountService.getOccurrenceDetails(claimId).then(function (result){
					$scope.occurrence = result;
					if ($scope.occurrence) {
						if ($scope.occurrence.occurrenceNumber) {
							TabManagerService.initializeTabs(["occurrence"]);
							TabManagerService.openTab("occurrence", $scope.occurrence.occurrenceNumber, $scope.occurrence);
						}
					}
				});
			}else if(program == "ICON"){
				AccountService.getClaimOccurrence(claimId).then(function (result){
					$scope.occurrence = Array.isArray(result) ? result[0] : result;					
					if ($scope.occurrence) {
						if ($scope.occurrence.occurrenceNumber) {
							$scope.occurrence.insuredType = UserProfile.type;
							TabManagerService.initializeTabs(["occurrence"]);
							TabManagerService.openTab("occurrence", $scope.occurrence.occurrenceNumber, $scope.occurrence);
						}
					}
				});

			}
		};

		$scope.setMessageView = function ( messageView, obj ) {
			//open message tab and open message
			TabManagerService.openTab( 'message', $scope.account.accountId );
			$scope.$broadcast( 'set-message-view-insured', {view: messageView, obj: obj} );
			$scope.messageVisible = true;
		}

		$scope.onAddAccountSuccess = function() {
			$scope.account = null;
			$scope.accountIds = [];
			$scope.loadAccounts();
		};

		$scope.closeClaim = function ($event , category, policyNumber){
			$event.preventDefault();
			$event.stopPropagation();
			TabManagerService.closeTab(category, policyNumber );
			$scope.openSummaryTab();
		}

		$scope.close = function( $event, policyNumber ) {
			$event.preventDefault();
			$event.stopPropagation();
			$scope.closePolicyTab( policyNumber );
			$scope.openSummaryTab();
		};
		$scope.reloadOnAddAccountSuccess = function(){
			$scope.onAddAccountSuccess();
			$scope.openSummaryTab();
		}
		$scope.loadAccounts();

		$scope.$on( 'open-message-tab', $scope.openMessageTab );
		$scope.$on( 'close-message-tab', $scope.closeMessageTab );
		$scope.$on( 'load-insured-accounts', $scope.reloadOnAddAccountSuccess);
	}

	return InsuredAccountSummaryController;

});
