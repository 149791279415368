define('components/addAccount/controller/AddAccountModalController',['require'],function(require) {
	"use strict";

	AddAccountModalController.$inject = ["$scope", "$modalInstance", "InsuredRegistrationService", "insuredAccountNumber"];

	function AddAccountModalController( $scope, $modalInstance, InsuredRegistrationService, insuredAccountNumber ) {

		$scope.uiState = {
			adding: false,
			error: false
		};
		$scope.insuredAccountNumber = insuredAccountNumber ? insuredAccountNumber : null;
		// close and dismiss have different meanings in this context. when a modal is "closed", its promise
		// resolves as a successful result. when a modal is "dismissed", it rejects the promise as an unsuccessful
		// result. for this specific component, a successful result means that the parent component should reload
		// whatever account-specific information it needs.
		$scope.close = $modalInstance.close;
		$scope.dismiss = $modalInstance.dismiss;

		$scope.addAccount = function( accountNumber, zipCode ) {
			$scope.uiState.adding = true;
			$scope.insuredAccountNumber = insuredAccountNumber ? insuredAccountNumber : accountNumber;

			InsuredRegistrationService.addAccount( $scope.insuredAccountNumber, zipCode ).then(function( result ) {
				$scope.close();
			}).catch(function( err ) {
				$scope.uiState.error = true;
				$scope.uiState.adding = false;
			});
		};

	}

	return AddAccountModalController;

});
