define('insuredPayment/app',['require','common/config/config','common/directive/app','components/insuredRegistration/accountLookup/app','shim/overmind','insuredPayment/controller/InsuredPaymentController','common/service/insuredRegistration/InsuredRegistrationService','common/service/specialtyApplication/SubmissionStore','common/service/BundlePaymentService','common/service/PaymentService'],function(require) {
	"use strict";

	require("common/config/config");
	require("common/directive/app");
	require("components/insuredRegistration/accountLookup/app");

	var overmind = require("shim/overmind");

	var InsuredPaymentController = require("insuredPayment/controller/InsuredPaymentController"),
			InsuredRegistrationService = require("common/service/insuredRegistration/InsuredRegistrationService" ),
			SubmissionStore = require("common/service/specialtyApplication/SubmissionStore"),
			BundlePaymentService = require("common/service/BundlePaymentService" ),
			PaymentService = require("common/service/PaymentService");

	var app = angular.module("mPortal.insuredPayment", [
		"mPortal.config",
		"mPortal.directive",
		"mPortal.insuredRegistration.accountLookup"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {

		$routeProvider.when("/pay-now", {
			templateUrl: "app/insuredPayment/template/template.html",
			controller: "InsuredPaymentController",
			view:'accountLookup',
			source:'internal',
			guard: function( userProfile ) {
				return !userProfile.isLoggedIn;
			}
		});

		$routeProvider.when("/payment/:accountId/:zip", {
			templateUrl: "app/insuredPayment/template/template.html",
			controller: "InsuredPaymentController",
			view:'payment',
			source:'external',
			guard: function( userProfile ) {
				return !userProfile.isLoggedIn;
			}
		});

	}]);

	app.controller("InsuredPaymentController", InsuredPaymentController);
	app.factory("InsuredRegistrationService", InsuredRegistrationService);
	app.factory("SubmissionStore", SubmissionStore);
	app.factory("BundlePaymentService", BundlePaymentService);
	app.factory("PaymentService", PaymentService);

	return app;

});
