define('components/specialtyApplication/questions/config/widgets/verisk/veriskModalController',['require'],function(require) {
	"use strict";

	VeriskModalController.$inject = ["$scope", "$modalInstance", "riskData", "resBody", "VeriskService" ];

	function VeriskModalController( $scope, $modalInstance, riskData, resBody, VeriskService) {
        $scope.uiState = {
			visible: false,
			submitting: false
		};

        $scope.multiCheckbox = {
            checked: [],
            change: setRisks
        };

        $scope.riskList    = [];
        $scope.riskData    = riskData;
        $scope.riskResBody = resBody;

         var is_emit = false ;
        if($scope.options !== null && $scope.options !== undefined && $scope.options !== "" &&   $scope.options.length !== 0)
        {           
            if($scope.options.data !== null && $scope.options.data !== "undefined")
                {
                    $scope.risksectionID = $scope.options.data.sectionId;
                }
                is_emit = true;
        }
        else if(resBody.risksectionID !== 'undefined')
        {
            $scope.risksectionID = resBody.risksectionID;
        }

        $scope.close       = $modalInstance.close;

        function setRisks() {

            var riskListCopy = $scope.riskList;

            angular.forEach($scope.multiCheckbox.checked, (checkbox, index) => {

                if (checkbox) {
                    var riskIsFound = riskListCopy.find((element) => element === riskData.Risks[index].RiskId);

                    if (!riskIsFound) {
                        riskListCopy.push(riskData.Risks[index].RiskId);
                    }
                    
                } else {
                    const item_index = riskListCopy.indexOf(riskData.Risks[index].RiskId);
                    const x = riskListCopy.splice(item_index, 1);
                }

            });

            $scope.riskList = riskListCopy;

        }

        $scope.getData = async function () { 
            var bodyForStructures = $scope.riskResBody;            
            var verisk_api_result_arr =[] ;
            if ($scope.riskList.length > 0) {                

                for (var i = 0; i < $scope.riskList.length; i++) {
                    var bodyForStructures = $scope.riskResBody;

                    bodyForStructures.submission.riskId = $scope.riskList[i];

                    var result = await VeriskService.getStructures(bodyForStructures);                                    
                    
                    //for multiple risk add risk only if actul riskdata is available 
                    if(result.Risks)
                    {
                        verisk_api_result_arr.push(result);
                    }
                }

            }          
            map_multiple_risk_data(verisk_api_result_arr,is_emit);      
            $modalInstance.close();
        };      

        function map_multiple_risk_data(verisk_data,is_emit)
        {    
            if (verisk_data.length > 0) {
                if(is_emit && verisk_data)
                {
                    $scope.$emit( 'add_multiple_risks', {
                        veriskdata: verisk_data    ,
                        sectionId :  $scope.risksectionID, 
                        mainSectionName: "Location Information"  
                    } );
                }
                else 
                {
                    $scope.$root.$broadcast( 'add_multiple_risks', {
                        veriskdata: verisk_data    ,
                        sectionId :  $scope.risksectionID, 
                        mainSectionName: "Location Information"  
                    } );
                }
            }
            
        }
	}
    
	return VeriskModalController;

});
