define('components/widget/inland-marine/blanket-item-limit/directive/blanketItemLimit',['require'],function(require) {
    "use strict";

    blanketItemLimit.$inject = [];

    function blanketItemLimit() {
        console.log("Inside Inland Directive");
        return {
            restrict: "AE",
            scope:{
                model	            : "=",
                options             : "=",
                quantityControlId   : "="
            },
            templateUrl: "app/components/widget/inland-marine/blanket-item-limit/template/template.html",
            controller: "BlanketItemLimitController"
        };
    }

    return blanketItemLimit;

});
