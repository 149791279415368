define('forms/controller/FormsController',['require'],function(require) {
	"use strict";

	FormsController.$inject = ["$scope", "$routeParams", "FormService"];

	function FormsController( $scope, $routeParams, FormService ) {

		$scope.config = { placeholder: "Form Search" };
		$scope.documents = [];
		$scope.initialView = true;

		$scope.loadForms = function( state ) {
			$scope.initialView = false;
			$scope.documents = [];

			FormService.getStateForms( state ).then(function( result ) {
				$scope.documents = result.documents;
				$scope.config.placeholder = result.state;
			});

		};

		if( $routeParams.state ) {
			$scope.loadForms( $routeParams.state );
		}

	}

	return FormsController;

});
