define('common/service/user/VerifySession',['require'],function(require) {
	"use strict";

	VerifySession.$inject = ["$q", "UserService", "UserProfile"];

	function VerifySession( $q, UserService, UserProfile ) {

		// if we think the user is logged in (based on the last profile we got from Arch), let's reach out just to double check
		if( UserProfile.isLoggedIn ) {
			return UserService.getUserProfile().then(function( profile ) {
				return profile.isLoggedIn ? $q.when() : $q.reject({ reason: "notLoggedIn" });
			});
		}

		// if not (i.e. an unauthenticated user), just let them continue along
		return $q.when();

	}

	return VerifySession;

});
