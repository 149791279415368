define('components/newsHeadlines/app',['require','angular-sanitize','components/newsHeadlines/directive/newsHeadlines','components/newsHeadlines/controller/NewsHeadlinesController','components/newsHeadlines/controller/NewsItemController','common/service/NewsService'],function(require) {
	"use strict";

	require("angular-sanitize");

	var newsHeadlines = require("components/newsHeadlines/directive/newsHeadlines"),
		NewsHeadlinesController = require("components/newsHeadlines/controller/NewsHeadlinesController"),
		NewsItemController = require("components/newsHeadlines/controller/NewsItemController"),
		NewsService = require("common/service/NewsService");

	var component = angular.module("mPortal.components.newsHeadlines", [
		"ngSanitize"
	]);

	component.directive("newsHeadlines", newsHeadlines);
	component.controller("NewsHeadlinesController", NewsHeadlinesController);
	component.controller("NewsItemController", NewsItemController);
	component.factory("NewsService", NewsService);

	return component;

});
