define('newsArchives/controller/NewsArchivesController',['require'],function(require) {
	"use strict";

	NewsArchivesController.$inject = ["$scope", "$sce", "NewsService"];

	function NewsArchivesController( $scope, $sce, NewsService ) {

		$scope.news = [];

		NewsService.getNewsArchives().then(function( result ) {

			$scope.news = result.archives;

			angular.forEach( $scope.news, function( news ) {
				news.message = $sce.trustAsHtml( news.message );
			});

		});

	}

	return NewsArchivesController;

});
