define('components/widget/summary-of-equines/app',['require','components/widget/summary-of-equines/directive/summaryOfEquines','components/widget/summary-of-equines/controller/SummaryOfEquinesController'],function(require) {

    "use strict";

    var summaryOfEquines           = require("components/widget/summary-of-equines/directive/summaryOfEquines"),
        SummaryOfEquinesController = require("components/widget/summary-of-equines/controller/SummaryOfEquinesController");

    var component      = angular.module("mPortal.components.widget.summary-of-equines", []);
    component.directive( "summaryOfEquines", summaryOfEquines );
    component.controller( "SummaryOfEquinesController", SummaryOfEquinesController );

    return component;

});
