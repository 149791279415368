define( 'components/specialtyApplication/submit/review/app',['require','components/specialtyApplication/submit/review/directive/review','components/specialtyApplication/submit/review/controller/ReviewController','components/specialtyApplication/submit/programDetails/bop/app','components/specialtyApplication/submit/programDetails/wc/app','components/specialtyApplication/submit/programDetails/miscErrorsOmissions/app'],function( require ) {
	"use strict";

	var review = require( "components/specialtyApplication/submit/review/directive/review" ),
		ReviewController = require( "components/specialtyApplication/submit/review/controller/ReviewController" ),
		bopDetails = require( "components/specialtyApplication/submit/programDetails/bop/app"),
		wcDetails = require( "components/specialtyApplication/submit/programDetails/wc/app"),
		miscEODetails = require( "components/specialtyApplication/submit/programDetails/miscErrorsOmissions/app" );

	var component = angular.module( "mPortal.components.specialtyApplication.submit.review", [
		"mPortal.components.specialtyApplication.submit.programDetails.bop",
		"mPortal.components.specialtyApplication.submit.programDetails.wc",
		"mPortal.components.specialtyApplication.submit.programDetails.miscErrorsOmissions"
	]);

	component.directive( "specialtyApplicationReview", review );
	component.controller( "ReviewController", ReviewController );

	return component;

});
