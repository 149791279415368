define('components/widget/key-characteristics/app',['require','components/widget/key-characteristics/directive/keyCharacteristics','components/widget/key-characteristics/controller/KeyCharacteristicsController'],function(require) {

    "use strict";

    var keyCharacteristics           = require("components/widget/key-characteristics/directive/keyCharacteristics"),
        KeyCharacteristicsController = require("components/widget/key-characteristics/controller/KeyCharacteristicsController");

    var component      = angular.module("mPortal.components.widget.key-characteristics", []);
    component.directive( "keyCharacteristics", keyCharacteristics );
    component.controller( "KeyCharacteristicsController", KeyCharacteristicsController );

    return component;

});
