define('components/twoYearView/twoYearView/filter/TwoYearViewFilter',['require'],function(require) {
	"use strict";

	TwoYearViewFilter.$inject = [ "$filter", "moment"];

	function TwoYearViewFilter( $filter, moment ) {
		return function( items, filterValues ) {

			var fuzzySearch = function( item, search ) {
				var searchParts = search.toLowerCase().split(" "),
					normalizedName = item.accountName.toLowerCase(),
					normalizedDba = item.dba ? item.dba.toLowerCase() : "";

				var nameMatch = searchParts.every( function( part ) {
					return normalizedName.indexOf( part ) !== -1 || normalizedDba.indexOf( part ) !== -1;
				});

				// Skip the policy number search if possible
				if( searchParts.length > 1 || nameMatch ) {
					return nameMatch;
				} else {
					var matchingPolicyNumber = false;
					for( var q = 0; q < item.quotes.length; q++ ) {
						var policyNumber = item.quotes[ q ].policyNumber;
						if( policyNumber && policyNumber.toLowerCase().indexOf( searchParts[ 0 ] ) !== -1 ) {
							matchingPolicyNumber = true;
							break; // No need to loop through the rest of the item quotes
						}
					}
					return matchingPolicyNumber;
				}
			};

			/*
			The moment documentation says the "YYYY" pattern should accept 2 or 4-digit years, but in practice
			it does not seem to be the case.  Setting the pattern based on the number of year digits allows us
			to support users who just want to type in the date as short as possible.
			*/
			var effDateFromPattern = /\/\d{2}$/.test( filterValues.effectiveDateFrom ) ? "M/D/YY" : "M/D/YYYY";
			var effDateToPattern = /\/\d{2}$/.test( filterValues.effectiveDateTo ) ? "M/D/YY" : "M/D/YYYY";

			var effDateFrom = moment( filterValues.effectiveDateFrom, effDateFromPattern, true ),
				effDateTo = moment( filterValues.effectiveDateTo, effDateToPattern, true ),
				defaultSelectValue = "All",
				defaultBalanceFilterValue = "All balances",
				filteredItems = [];

			angular.forEach( items, function( account, index ) {

				if( fuzzySearch( account, filterValues.name ) ) {

					angular.forEach( account.quotes, function( quote ) {
						if( effDateFrom.isValid() && moment( quote.effectiveDate ).isBefore( effDateFrom ) ) {
							quote.hidden = true;
							return;
						}

						if( effDateTo.isValid() && moment( quote.effectiveDate ).isAfter( effDateTo ) ) {
							quote.hidden = true;
							return;
						}

						if( filterValues.status && filterValues.status !== defaultSelectValue && filterValues.status !== quote.status ) {
							quote.hidden = true;
							return;
						}

						if( filterValues.program && filterValues.program !== defaultSelectValue && filterValues.program !== quote.program ) {
							quote.hidden = true;
							return;
						}

						if( filterValues.balance && filterValues.balance !== defaultBalanceFilterValue && ( quote.balance === null || quote.balance == 0 ) ) {
							quote.hidden = true;
							return;
						}

						if( filterValues.billType && filterValues.billType !== defaultSelectValue && filterValues.billType !== quote.billType ) {
							quote.hidden = true;
							return;
						}

						/* when we filter by billType, we hide all submissions. */
						if( filterValues.billType && filterValues.billType !== defaultSelectValue && ( quote.policyNumber === null || quote.policyNumber === "" ) ) {
							quote.hidden = true;
							return;
						}

						quote.hidden = false;
					});

					var visibleQuotes = $filter("filter")( account.quotes, function( quote ) {
						return !quote.hidden;
					});

					if( visibleQuotes.length ) {
						filteredItems.push( account );
					}

				}

			});

			return filteredItems;

		};
	}

	return TwoYearViewFilter;

});
