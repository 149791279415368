define('home/controller/HomeController',['require'],function(require) {
	"use strict";

	HomeController.$inject = ["$scope", "UserProfile", "maverickApiUrl", "NewsService", "$sce"];

	function HomeController( $scope, UserProfile, maverickApiUrl, NewsService, $sce ) {
		$scope.user = UserProfile;
		$scope.maverickApiUrl = maverickApiUrl;
		$scope.newsItems = NewsService.getNews();
        $scope.showOld = false;
        $scope.product_search_location = "home";

        $scope.toggleWidget = function () {
        	$scope.showOld = !$scope.showOld;
            // clear businessType so we don't leave results hanging
             $scope.businessTypeSelected = "";

        };

	}

	return HomeController;

});
