define('support/controller/SupportController',['require'],function(require) {
	"use strict";

	SupportController.$inject = ["$scope", "$sce", "maverickApiUrl"];

	function SupportController( $scope, $sce, maverickApiUrl ) {
		$scope.iframeSrc = $sce.trustAsResourceUrl( maverickApiUrl + "?action=support.form" );
	}

	return SupportController;

});
