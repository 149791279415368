define('components/accountsSearch/directive/accountsSearch',['require'],function(require) {
	"use strict";

	function AccountsSearch() {
		return {
			restrict: "AE",
			scope: {
				searchDataResults: "="
			},
			templateUrl: "app/components/accountsSearch/template/template.html",
			controller: "AccountsSearchController"
		};
	}

	return AccountsSearch;

});
