define( 'components/viewApplication/controller/ViewApplicationController',['require'],function ( require ) {
	"use strict";

	ViewApplicationController.$inject = ["$scope", "SpecialtyDocumentService", "SubmissionStore"];

	function ViewApplicationController( $scope, SpecialtyDocumentService, SubmissionStore ) {

		SubmissionStore.applicationId = $scope.applicationId;
		SubmissionStore.programName = $scope.program;

		$scope.viewApplication = function () {
			SpecialtyDocumentService.getApplicationDocument();
		};
	}

	return ViewApplicationController;
} );
