define( 'components/documentDeliveryPreferencesLink/controller/DocumentDeliveryPreferencesLinkController',['require'],function( require ) {
    "use strict";

    DocumentDeliveryPreferencesLinkController.$inject = [ "$scope", "$modal", "ResultService", "UserProfile" ];

    function DocumentDeliveryPreferencesLinkController( $scope, $modal, ResultService, UserProfile ) {
        $scope.userProfile = UserProfile;

        $scope.isInsured = function () {
            return $scope.userProfile.type === 'Insured';
        };

        $scope.isAgent = function () {
            return $scope.userProfile.type === 'Agent';
        };

        $scope.retrievingData = false;
        $scope.openErrorModal = function() {
            $modal.open({
                templateUrl: "app/components/documentDeliveryPreferencesLink/template/modalPreferencesUnavailable.html",
                controller: "GenericModalController"
            });
        };

        $scope.getPreferences = function( applicationId, submissionId, insuredType ) {
            $scope.retrievingData = true;
            ResultService.getSubmissionDeliveryPreferences( applicationId ).then( function( result ) {
                if( !result.data.success ) {
                    $scope.retrievingData = false;
                    $scope.openErrorModal();
                } else {
                    $modal.open({
                        templateUrl: "app/components/documentDeliveryPreferencesLink/template/modalUpdatePreferences.html",
                        controller: "DocumentDeliveryPreferencesModalController",
                        windowClass: "doc-delivery-modal",
                        resolve: {
                            submissionId: function(){ return submissionId },
                            docDeliveryDataAvailable: function(){ return true; },
                            docDeliveryConfig: function(){ return result.data.configuration },
                            insuredType: function(){ return insuredType }
                        }
                    });
                    $scope.retrievingData = false;
                }
            });
        };

    }

    return DocumentDeliveryPreferencesLinkController;
});
