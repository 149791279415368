define('components/addAccount/directive/addAccount',['require'],function(require) {
	"use strict";

	function AddAccount() {
		return {
			restrict: "AE",
			scope: {
				onSuccess: "="
			},
			templateUrl: "app/components/addAccount/template/template.html",
			controller: "AddAccountController"
		};
	}

	return AddAccount;

});
