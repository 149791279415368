define('components/rewardsSummary/controller/RewardsSummaryController',['require'],function(require) {
	"use strict";

	RewardsSummaryController.$inject = ["$scope", "RewardsService", "maverickApiUrl"];

	function RewardsSummaryController( $scope, RewardsService, maverickApiUrl ) {

		$scope.rewardsSummary = {};
		$scope.maverickApiUrl = maverickApiUrl;

		RewardsService.getRewardsSummary().then(function(result) {
			$scope.rewardsSummary = result;
		});
	}

	return RewardsSummaryController;

});
