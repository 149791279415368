define('components/specialtyApplication/questions/config/widgets/AdditionalInformationWidget',['require'],function (require) {
    "use strict";

    var widget = {};

    widget.getStructure = function () {

        return {
            "Insured Info Label" : { wrapper: null, wrapperOverride: true, orderNumber: 1 },
            "InsuredInformationDataPoint": {
                wrapper: ["twoPointRow"], orderNumber: 2, fieldGroup:
                    {
                        "Entity Type": { wrapper: ["horizontalBox", "aiSpan3HalfRowError"] },
                        "Nature of Business": { wrapper: ["horizontalBox", "aiSpan3HalfRowError"] },
                        "Assigned Risk Pool Prior Period": { wrapper: ["aiHorizontalWideFullRowPoint", "span4HalfRowError"] }
                    }
            },

            "UnemployementNoDataPoint": {
                wrapper: ["twoPointRow"],orderNumber: 3, fieldGroup:
                    {
                        "HI Unemployment No": { wrapper: ["horizontalBox", "span4HalfRowError"] },
                        "ME Unemployment No": { wrapper: ["horizontalBox", "span4HalfRowError"] },
                        "MN Unemployment No": { wrapper: ["horizontalBox", "span4HalfRowError"] },
                        "MN Association": { wrapper: ["horizontalBox", "span4HalfRowError"] },
                        "NJ Tax Payer No": { wrapper: ["horizontalBox", "span4HalfRowError"] },
                        "RI Unemployment No": { wrapper: ["horizontalBox", "span4HalfRowError"] },
                        "UT Unemployment No": { wrapper: ["horizontalBox", "span4HalfRowError"] }
                    }
            },

            "Contact Information Label": { wrapper: null, wrapperOverride: true, orderNumber: 4 },
            "ContactInfoTable": {
                wrapper: [ "fieldGroupTable" ], orderNumber: 5,
                data: {
                    tableDiv: { class: "oeFieldGroupTableContainer" },
                    table: {
                        class: "table table-bordered",
                        headerRows: [
                            {
                                headers: [ "First Name*", "Last Name*","Phone Number*","Email*" ]
                            }
                        ]
                    }
                },
                fieldGroup: {
                    "row1": {
                        fieldGroup: {
                            "Primary Contact Name": { wrapper: [ "labelDatapoint","contactLocationRowError" ], wrapperOverride: true },
                            "Primary Contact Last Name": { wrapper: [ "labelDatapoint","contactLocationRowError" ], wrapperOverride: true },
                            "Phone Number": { wrapper: [ "labelDatapoint","contactLocationRowError" ], wrapperOverride: true },
                            "Email": { wrapper: [ "labelDatapoint","contactLocationRowError" ], wrapperOverride: true }
                        }
                    }
                }
            },

            "Owner Officer Label" : { wrapper: null, wrapperOverride: true, orderNumber: 6 },

            "OwnersOfficerDetails": { wrapper: [ "owners-officer-wrapper" ], orderNumber: 7, fieldGroup:
                {
                    "Owner Officer Number": { wrapper: ["hideGroup"], orderNumber: 1 },
                    "Owner First Name": { wrapper: ["ownerOfficerDataPoint", "ownerOfficerRowError"], orderNumber: 2 },
                    "Owner Last Name": { wrapper: ["ownerOfficerDataPoint", "ownerOfficerRowError"], orderNumber: 3 },
                    "Owner Title": { wrapper: ["selectHelpError"], orderNumber: 4 },
                    "Owner Ownership": { wrapper: ["ownerOfficerDataPoint", "ownerOfficerRowError"], orderNumber: 5 },
                    "Owner Class": { wrapper: ["ownerOfficerDataPoint", "ownerOfficerRowError"], orderNumber: 6 },
                    "Owner Payroll": { wrapper: ["ownerOfficerDataPoint", "ownerOfficerRowError"], orderNumber: 7 },
                    "Owner Include": { wrapper: [ "ownerOfficerRowError"], type: "ownerIncludeSelectType", orderNumber: 8 }
                }
            },

            "termsOfAgreement" : { wrapper: ["terms-of-agreement-wrapper"], orderNumber: 8, fieldGroup:
                    {
                        "AI Agreement": { wrapper: null, data: {
                            actions: [
                               {
                                   methodName: "injectNameIntoAgreement",
                                   contexts: [ "update" ],
                                   programs: []
                               }
                            ]
                        }},
                        "AI Electronic Signature": { wrapper: [ "defaultBootstrapLabel", "defaultBootstrapHasError" ], wrapperOverride: true  },
                        "AI Signature Timestamp": { wrapper: [ "defaultBootstrapLabel" ], wrapperOverride: true }
                    }
            }
        }

    };

    widget.configureFormly = function (formlyConfig, formlyApiCheck) {

        formlyConfig.setWrapper({
            name: "contactLocationRowError",
            overwriteOk: true,
            template:
            '<div class="span2_5 form-group" style="width: 74%;height: 52px"  ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "terms-of-agreement-wrapper",
            template: '<div class="alert alert-info">Terms of Agreement</div>' +
                      '<div style="padding-left:26px" ><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "owners-officer-wrapper",
            template: '<div style="padding-left:26px" owner-officer-information  model="model" options="options" quantity-control-id="\'OwnerOfficerNumber\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ownerOfficerDataPoint",
            template: '<style>.ownerOfficerDataPoint input { width: 110px !important; } </style><div class="ownerOfficerDataPoint">' +
            '<formly-transclude></formly-transclude>' + '</div>'

        });

        formlyConfig.setType({
            extends: "select",
            name: "ownerIncludeSelectType",
            template: '<style>.ownerSelect select { min-width: 100px; width: 120px !important; } </style><div class="ownerSelect">'+
                      '<select class="form-control" ng-model="model[options.key]"></select>'+
                      '</div>'
        });

        formlyConfig.setWrapper({
            name: "ownerOfficerRowError",
            overwriteOk: true,
            template:
            '<div class="span1 form-group" style="width: 95% !important; padding-top: 5px;padding-bottom: 5px; padding-left: 0px; padding-right: 0px"  ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "aiHorizontalWideFullRowPoint",
            template:
            '<label style="display:inline !important; width:140%;float:left;font-weight: bold;" for="{{ id }}" class="control-label">{{ to.label }}' + '</label>' +
            '<formly-transclude></formly-transclude>'
        });

        formlyConfig.setWrapper({
            name: "aiSpan3HalfRowError",
            overwriteOk: true,
            template:
            '<div class="span3 form-group" style="padding-left:30px;" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "selectHelpError",
            overwriteOk: true,
            template:
            '<div class="form-group" style="width: 97% !important; padding-left: 0px; padding-right: 0px; margin-top: -4px;"  ng-class="{ \'has-error\': !options.data.isValid && ( options.validation.errorExistsAndShouldBeVisible || options.data.wasTouched ), required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && ( options.validation.errorExistsAndShouldBeVisible || options.data.wasTouched )">' +
            '</div>' +
            '</div>'
        });

    };
    return widget;
});



