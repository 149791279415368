define('common/service/LogDataService',['require'],function (require) {
    "use strict";

    LogDataService.$inject = [ "$http", "archApiUrl","UserProfile"];

    function LogDataService( $http, archApiUrl, UserProfile ) {

        var service = {};
        var previousLogData = '';
        var previousLogType = 0;
        var previousTransactionUUID = '';

        service.productSearchLog = function(logData, transactionUUID, logType) {
            var logDataString = JSON.stringify(logData);
            if( previousLogData == logDataString && previousLogType == logType && previousTransactionUUID == transactionUUID) {
                return
            }
             else {
                previousLogData = logDataString;
                previousLogType = logType;
                previousTransactionUUID = transactionUUID;
                return $http({
                    method: "POST",
                    url: archApiUrl + "product-search/log-details",
                    data: {
                        logData: logDataString,
                        logType: logType,
                        transactionUUID: transactionUUID
                    }
                })
            }
        };

        service.productSearchLogOnLoad = function( businessId, businessType, filters, products, transactionUUID, logType ) {
	        var logData = [];
	        var filterDetails = this.packageFilters( filters );
	        var productDetails = this.packageProducts( products );
            if(UserProfile.isLoggedIn){
            logData.push({
		        business_Id: businessId,
		        business_Type: businessType,
		        Filters: filterDetails,
		        Products: productDetails,
                AgencyID: UserProfile.agencyId,
                AgencyName: UserProfile.agencies[0].name,
                PilotAgent: UserProfile.hasPermissions('PilotAgent')
	        });
                service.productSearchLog( logData, transactionUUID, logType );
            }
            else{
                logData.push({
                    business_Id: businessId,
                    business_Type: businessType,
                    Filters: filterDetails,
                    Products: productDetails
                });
            }

        };
        service.getUUID = function(){
            return $http.get( archApiUrl + "product-search/uuid").then(function( result ) {
                return result.data;
            });

        };
        service.productSearchLogOnContinue = function( businessId, businessType, filters, products, rules , programs, transactionUUID, logType ){
            var logData = [];
            var filterDetails = this.packageFilters( filters );
            var productDetails = this.packageProducts( products );
            var ruleDetails = this.packageRules( rules );
            if(UserProfile.isLoggedIn) {
                logData.push({
                    business_Id: businessId,
                    business_Type: businessType,
                    Filters: filterDetails,
                    Products: productDetails,
                    Rules: ruleDetails,
                    AgencyID: UserProfile.agencyId,
                    AgencyName: UserProfile.agencies ? UserProfile.agencies[0].name : null,
                    PilotAgent: UserProfile.hasPermissions('PilotAgent')
                });
                service.productSearchLog( logData, transactionUUID, logType );
            }else{
                logData.push({
                business_Id: businessId,
                    business_Type: businessType,
                    Filters: filterDetails,
                    Products: productDetails,
                    Rules: ruleDetails
                });
            }

        };

        service.productSearchLogOnFilterChange = function( businessId, businessType, filters, products, rules, transactionUUID, logType )  {
        	if( !filters.length ) return;

        	var logData = [];
        	var filterData = this.packageFilters( filters );
        	var productDetails = this.packageProducts( products );
            var ruleDetails = this.packageRules( rules );
            if(UserProfile.isLoggedIn) {
                logData.push({
                    business_Id: businessId,
                    business_Type: businessType,
                    Filters: filterData,
                    Products: productDetails,
                    Rules: ruleDetails,
                    AgencyID: UserProfile.agencyId,
                    AgencyName: UserProfile.agencies ? UserProfile.agencies[0].name : null,
                    PilotAgent: UserProfile.hasPermissions('PilotAgent')
                });
                service.productSearchLog( logData, transactionUUID, logType );
            }
            else{
                logData.push({
                    business_Id: businessId,
                    business_Type: businessType,
                    Filters: filterData,
                    Products: productDetails,
                    Rules: ruleDetails

                });
            }

        };

        service.packageProducts = function( products ) {
            var productDetails = [];
            var rule = null;
            for ( var i = 0; i < products.length; i++) {
                var product  = products[i];
                if( products[i].rule ) {
                        rule = {
                            id: products[i].rule.id,
                            display: products[i].rule.display
                        }
                } else {
                    rule = null;
                }

                productDetails.push({
                    id: products[i].id,
                    title: products[i].title,
                    allowed: products[i].allowed,
                    selected: products[i].selected,
                    ineligible: products[i].ineligible,
                    disabled: products[i].disabled,
                    unavailable: products[i].unavailable,
                    classCode: products[i].classCode ? products[i].classCode.classCode : null
                });

            }
            return productDetails;
        };

        service.packageFilters = function( filters ){
        	var filterDetails = [];

	        for (var i = 0; i < filters.length; i++) {
                if(filters[i].visible  && !filters[i].hidden)
                {
                    filterDetails.push({
                        id: filters[i].id,
                        display: filters[i].display,
                        value: filters[i].value
                    });
                }
		        
	        }
	        return filterDetails;
        };

        service.packageRules = function( rules ){
            var ruleDetails  = [];

             for (var i = 0; i < rules.length; i++) {
                ruleDetails.push({
                    id: rules[i].id,
                    display: rules[i].display,
                    type: rules[i].type
                });
            }

            return ruleDetails;
        }

        return service;
    }

    return LogDataService;
});
