define( 'common/service/specialtyApplication/DatapointModificationService',[],function(  ) {
    "use strict";

    DatapointModificationService.$inject = [ "$filter", "UserProfile", "SubmissionStore" ];

    function DatapointModificationService( $filter, UserProfile, SubmissionStore ) {
        var service = {};

        service.blockingDatapoints = {};
        service.blockingDatapointNoneAboveCheckedKey = null;

        service.injectNameIntoAgreement = function( datapoint, storeDatapoint ) {
            if( UserProfile.name ) {
                datapoint.templateOptions.label = datapoint.templateOptions.label.replace(/I\sattest/,"I, " + UserProfile.name + ", attest");
            }
        };

        service.registerBlockingDataPoint = function( datapoint, storeDatapoint ) {
            var refString = datapoint.data.blockingGroup;
            
            if (datapoint.data.NoneElement == "Yes") {
	            service.blockingDatapointNoneAboveCheckedKey = datapoint.key;
            }

            if( service.blockingDatapoints[refString]==undefined ) {
                var initialDatapoint = {};
                initialDatapoint[datapoint.key] = datapoint.defaultValue;
                service.blockingDatapoints[refString] = initialDatapoint;
            }   
            else {
                var datapointValues = service.blockingDatapoints[refString];
                datapointValues[datapoint.key] = datapoint.defaultValue;
                service.blockingDatapoints[refString] = datapointValues;
            }
        };

        service.updateBlockingDataPoint = function( datapoint, storeDatapoint ) {
            var refString = datapoint.data.blockingGroup;
            
            if (datapoint.data.NoneElement == "Yes") {
	            service.blockingDatapointNoneAboveCheckedKey = datapoint.key;
            }

            var datapointValues = service.blockingDatapoints[refString];
            datapointValues[datapoint.key] = datapoint.defaultValue;

            service.blockingDatapoints[refString] = datapointValues;
        };
        
        service.evaluateBlockingDataPoints = function() {
            var result = null;
            var noneAboveChecked = false;

            for(var dataPointKey in service.blockingDatapoints) {
                var datapointValues = service.blockingDatapoints[dataPointKey];
                var datapointCheckedCount = 0;

                for (var prop in datapointValues) {
	                var propVal = datapointValues[prop];
	                if (prop == service.blockingDatapointNoneAboveCheckedKey) {
		                noneAboveChecked = propVal;
	                }

                    if (propVal == true) {
	                    datapointCheckedCount++; 
                    }
                }

	            if (datapointCheckedCount > 1 && noneAboveChecked == true) {
		            return false;
	            }
                else {
                    var resultValue = datapointCheckedCount == 0 ? false : true;

                    if (result == null) {
                        result = resultValue;
                    }
                    else {
                        result = result && resultValue;
                    }
                 }
            }

            return result == null ? true : result;
	
        };

        /*
         This method decorates the datapoint data with the offlineApplicationUrl of the matching MarketedProgram, which
         can be found in the programs array on the SubmissionStore.  It is assumed that the datapoint in only tied to
         a single application/master quote, or at least to MarketedPrograms that share the same URL.
        */
        service.setMarketedProgramOfflineApplicationUrl = function( datapoint, storeDatapoint ) {
            var applicationUrl = "";
            var matchingProgram;
            if( datapoint.data.applications && datapoint.data.applications.length > 0 ) {
                matchingProgram = $filter("filter")( SubmissionStore.programs, function( program ) {
                    return program.referenceString == datapoint.data.applications[ 0 ].referenceString
                }, true )[ 0 ];
                applicationUrl = matchingProgram ? matchingProgram.offlineApplicationUrl : "";
            }
            datapoint.data.offlineApplicationUrl = applicationUrl;
        };


	    service.setMarketedProgramFraudLinkUrl = function ( datapoint, storeDatapoint ) {
		    var fraudLinkUrl = "";
		    var matchingProgram;
		    if ( datapoint.data.applications && datapoint.data.applications.length > 0 ) {
			    matchingProgram = $filter( "filter" )( SubmissionStore.programs, function ( program ) {
				    return program.referenceString == datapoint.data.applications[0].referenceString
			    }, true )[0];
			    fraudLinkUrl = matchingProgram ? matchingProgram.fraudLink : "";
		    }
		    datapoint.data.fraudLinkUrl = fraudLinkUrl;
	    };

        service.setHighlightsDocPath = function( datapoint, storeDatapoint ) {
            if( SubmissionStore.effectiveDate > '2019-08-31' ) {
               if( datapoint.data.widgetReference == 'CC_GLEnhancement' )
                    datapoint.data.glDoc = "/assets/documents/Child Care GL Standard  Plus Enhancements v2 3.25.19.pdf";
               if( datapoint.data.widgetReference == 'SC_GLEnhancement' )
                    datapoint.data.glDoc = "/assets/documents/Educational Services GL Standard  Plus Enhancements v2 4.29.19.pdf";
            } else {
                if( datapoint.data.widgetReference == 'CC_GLEnhancement' )
                    datapoint.data.glDoc = "/assets/documents/Child Care GL Enhancement v1.pdf";
                if( datapoint.data.widgetReference == 'SC_GLEnhancement' )
                    datapoint.data.glDoc = "/assets/documents/Ed. Services GL Enhancement v1.pdf";
            }
        };

        return service;
    }

    return DatapointModificationService;
});
