define('components/specialtyApplication/questions/config/widgets/LocationWidget',['require'],function(require) {
    "use strict";

    var widget = {};

    widget.getStructure = function() {

        return {

            "LocationDataPoint" : { wrapper: ["locationRow"], fieldGroup:
                {
                    "Verisk": { wrapper: [ "topLabelLocationDatapoint", "locationRowError1"], data: { savePointOnly: true } },
                    "Location Address1": { wrapper: [ "topLabelLocationDatapoint", "locationRowError1"], data: { savePointOnly: true } },
                    "Location Zip": { wrapper: [ "zipLabelLocationDatapoint", "locationRowError" ],},
                    "Location City": { wrapper: [ "topLabelLocationDatapoint", "locationRowError" ]},
                    "Location State": { wrapper: ["topLabelLocationDatapoint", "locationRowError" ]},
                    "Location County": { wrapper: ["topLabelLocationDatapoint", "locationRowError" ]}
                }
            },
            "LocationAdditinalDataPoint" : { wrapper: ["twoPointRow"], fieldGroup:
                    {
                        "Location Address2": { wrapper: ["horizontalBox", "verticalHalfRowError"],  wrapperOverride: true },
                        "No of Buildings": { wrapper: ["horizontalBox", "verticalHalfRowError"], wrapperOverride: true }
                    }
            }
        };
    };

    widget.configureFormly = function (formlyConfig, formlyApiCheck) {

        formlyConfig.setWrapper({
            name: "locationRow",
            template: '<style>.locationRow input, .locationRow select { width: 160px !important; } </style><div class="row-fluid locationRow"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "topLabelLocationDatapoint",
            template:'<style>.selectStyle select { min-width: 100px; margin-top: 2px; } input { margin-top: 2px; }</style><div class="selectStyle">' +
            '<label for="{{ id }}" class="control-label">{{ to.label }}</label>' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });
        formlyConfig.setWrapper({
            name: "zipLabelLocationDatapoint",
            template: '<style>.zipRow input { width: 120px !important; } .field-help {' +
            'margin-top: 4px !important;' +
            '    } </style><div class="zipRow">' +
            '<label for="{{ id }}" class="control-label">{{ to.label }}</label>' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "locationRowError",
            overwriteOk: true,
            template:
            '<div class="span2_5 form-group" style="padding-left:8px;padding-top:0px;padding-right:0px" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "locationRowError1",
            overwriteOk: true,
            template:
            '<div class="span2_5 form-group" style="padding-left:8px;padding-top:2px;padding-right:0px" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

    };

    return widget;


});
