define('components/widget/policy-information/building-table/directive/buildingTable',['require'],function(require) {
	"use strict";

	buildingTable.$inject = [];

	function buildingTable() {
		return {
			restrict: "AE",
			scope:{
				model	 : "=",
				options  : "=",
				fields	 : "=",
				form	 : "=",
				section  : "="
			},
			templateUrl: "app/components/widget/policy-information/building-table/template/template.html",
			controller: "buildingTableController"
		};
	}

	return buildingTable;

});
