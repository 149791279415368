define('components/accountSummary/accountList/controller/AccountListController',['require'],function(require) {
    "use strict";

    AccountListController.$inject = ["$scope", "moment"];

    function AccountListController( $scope, moment ) {

        $scope.toggleExpiredVisibility = function( account ) {
            account.showingExpired = !account.showingExpired;
        };

        $scope.isVisible = function( account, quote ) {
            if( account.showingExpired ) {
                return true;
            }

            // Any quote (expired or not) with a balance or deposit due should always be visible
            return !!quote.balance || !!quote.renewalDepositDue || moment( quote.expirationDate, "MM/DD/YYYY" ).isAfter( Date.now() );
        };

        $scope.acctNameMaxLength = 75;

        // Display a maximum of 75 characters in Account Name. If it exceeds 75 characters, truncate and replace them with ...
        $scope.truncName = function (account) {
            if (!account.accountName) {
                return "";
            }
            return account.accountName.length > $scope.acctNameMaxLength ?
                "/ ".concat(account.accountName.substring(0, $scope.acctNameMaxLength).concat("...")) : "/ ".concat(account.accountName);
        }
    }

    return AccountListController;

});
