define('common/service/activityFeed/ActivityFeedItem',['require'],function(require) {
	"use strict";

	ActivityFeedItemFactory.$inject = ["DateService"];

	function ActivityFeedItemFactory( DateService ) {

		function ActivityFeedItem( params ) {

			angular.extend( this, params );

			this.timestamp = Date.parse( this.timestamp );
			this.normalizedNotificationUrl = this.notificationUrl ? this.notificationUrl.toLowerCase() : "";
			this.normalizedProductType = this.productShortName ? this.productShortName.toLowerCase() : "";

			return this;

		}

		ActivityFeedItem.prototype.isOlderThanTenDays = function() {
			return DateService.dateDiff( "days", this.timestamp, new Date() ) > 10;
		};

		ActivityFeedItem.prototype.hasPolicy = function() {
			return this.policyNumber !== "" && this.policyId !== "";
		};

		ActivityFeedItem.prototype.getActivityHref = function() {

			var base = "#/",
				activityHref = "";

			if( this.accountViewVersion != 1 ) {
				if ( this.policyNumber ) {
					if ( this.luxon_id && this.luxon_partition_key ) {
						return base + "lx/submission/" + this.luxon_partition_key + "/" + this.luxon_id;
					} else if ( this.entityId ) {
						switch ( this.entityType.toLowerCase() ) {
							case 'claim' :
								//passing dummy effective date to match 2 Year page claims url and route in accountSummary/app.js
								return base + "account-summary/" + this.accountId + "/policy/" + this.policyNumber + "/effective-date/" + "1900-01-01" + "/claim/" + this.entityId;
								break;

							case 'message' :
								return base + "account-summary/" + this.accountId + "/message/" + this.entityId + "/reply";
								break;

							default :
								return base + "account-summary/" + this.accountId + "/policy/" + this.policyNumber;
								break;
						}
					}
				} else {
					if( this.luxon_id && this.luxon_partition_key ){
						return base + "lx/submission/" + this.luxon_partition_key + "/" + this.luxon_id;
					}else{
						return base + "account-summary/" + this.accountId;
					}

				}
			}

			switch ( this.type ) {
				case "Document Release":
				case "Account Status Changed":
				case "Policy Status Changed":
				case "New Endorsement":
				case "Endorsement Status Change":
				case "Payment Received":
					if( this.entityType == "policy" && this.luxon_id && this.luxon_partition_key ){
						activityHref = base + "lx/submission/" + this.luxon_partition_key + "/" + this.luxon_id;
					}
					else if ( this.entityType == "policy" ) {
						activityHref = base + "account/policy/" + this.entityId;
					} else {
						activityHref = base + "account/application/" + this.entityId;
					}
				break;

				case "Quote Status Changed":
					if( this.entityType == "policy" && this.luxon_id && this.luxon_partition_key ){
						activityHref = base + "lx/submission/" + this.luxon_partition_key + "/" + this.luxon_id;
					}
					else if ( this.entityType == "policy" ) {
						activityHref = base + "account/policy/" + this.entityId;
					} else {
						if ( this.entityType === "quote" && this.title.indexOf('Renewal') > -1 ) {
							activityHref = base + "renewal/" + this.entityId;
						} else {
							if( this.entityType == "quote" && this.luxon_id && this.luxon_partition_key ){
								activityHref = base + "lx/submission/" + this.luxon_partition_key + "/" + this.luxon_id;
							}else{
								activityHref = base + "account/issuance/" + this.accountId + "/na/" + this.entityId;
							}
						}
					}
				break;

				case "New Message":
					activityHref = base + "account/message/detail/" + this.accountId + "/" + this.entityId;
				break;

				case "Claim Status Change":
				case "Claim Opened":
					activityHref = base + "account/claim/" + this.entityId;
				break;

				case "Notifications":
					// notification URLs are expected to be fully qualified with the exception of the
					// keywords, "bop" and "wc." these will result in a link to a new quote.
					if( this.normalizedNotificationUrl == "wc" || this.normalizedNotificationUrl == "bop" ) {
						activityHref = base + "quote/startClean";
					} else {
						activityHref = this.notificationUrl;
					}
				break;
			}

			return activityHref;

		};

		ActivityFeedItem.prototype.getActivityTarget = function() {

			if( this.normalizedNotificationUrl.length && this.normalizedNotificationUrl !== "wc" && this.normalizedNotificationUrl !== "bop" ) {
				return "_blank";
			}

		};

		ActivityFeedItem.prototype.getActivityId = function() {

			if( this.normalizedNotificationUrl.length ) {

				switch( this.normalizedNotificationUrl ) {
					case "wc":
						return "alternateProductLineLinkWC";

					case "bop":
						return "alternateProductLineLinkBOP";

					default:
						return "alternateProductLineLinkExternal";
				}

			}

		};

		ActivityFeedItem.prototype.isMessageProductLineVisible = function() {
			return this.type === "New Message" && !this.hasPolicy();
		};

		ActivityFeedItem.prototype.getMessageReplyId = function() {

			if( this.type === "New Message" ) {
				return this.entityId + "-" + this.accountId;
			}

			return "";

		};

		ActivityFeedItem.prototype.isBindable = function() {


			if( !this.canBind && (this.title.indexOf("Quoted") == -1)) {
				return false;
			}

			if( this.title.indexOf("Quoted") > -1 && this.title.indexOf("Renewal") > -1 && !this.isRenewalBindAvailable) {
				return false;
			}

			return true;

		};

		ActivityFeedItem.prototype.getBindNowHref = function() {

			if( !this.canBind && (this.title.indexOf("Quoted") == -1)) {
				return;
			}

            if (this.accountViewVersion == 1) {
                return "#/account/additional-information/" + this.entityId;
            } else {
                return "#/application/result/" + this.applicationId + "/account/" + this.entityId;
            }

		};

		return ActivityFeedItem;

	}

	return ActivityFeedItemFactory;

});
