define('components/widget/summary-of-equines/controller/SummaryOfEquinesController',['require'],function(require) {
    "use strict";

    SummaryOfEquinesController.$inject = [ "$scope", "$timeout" ];

    function SummaryOfEquinesController( $scope, $timeout ) {

        $scope.changingQuantity = false;

        $scope.widgetFields = $scope.options.fieldGroup;

        $scope.quantityControl = $scope.widgetFields.shift();

        $scope.quantityKey = $scope.quantityControl.key;

        $scope.widgetFields.sort(function( a, b ) {
            return a.data.orderNumber - b.data.orderNumber;
        });

        $scope.enumerations = [];
        for( var d = 0; d < $scope.widgetFields.length; d++ ) {
            var currentPoint = $scope.widgetFields[ d ];

            if(  currentPoint.validation && currentPoint.validation.errorExistsAndShouldBeVisible  ) {
                currentPoint.data.wasTouched = true;
            }

            var enumIndex = currentPoint.data.enumeration - 1;
            if( !$scope.enumerations[ enumIndex ] ) {
                $scope.enumerations[ enumIndex ] = [];
            }
            $scope.enumerations[ enumIndex ].push( currentPoint );
        }

        $scope.newLength = $scope.enumerations.length;

        $scope.changeQuantity = function( movement ) {
            $scope.changingQuantity = true;
            // We want to disable the inputs while a row quantity change is taking place so that a redraw
            // of the UI doesn't interrupt the saving process.  jQuery is the easiest way to achieve this.
            $( "#summaryOfEquinesTable input" ).prop( "disabled", true );
            $scope.newLength = parseInt($scope.model[ $scope.quantityKey ]) + movement;
            $scope.model[ $scope.quantityKey ] = "" + $scope.newLength + "";
            $("#" + $scope.quantityControlId + " input").blur();
        };

	    $scope.changeQuantityOfSections = function ( section, movement ) {

		    $scope.newLength = parseInt( $scope.model[$scope.quantityKey] ) + movement;
		    $scope.model[$scope.quantityKey] = "" + $scope.newLength + "";
		    $scope.$emit( 'delete-enumerated-section', {
			    section: section,
			    sectionId: section[0].data.sectionId,
			    name: "Horse Summary Main"
		    } );

	    };

        $scope.buttonDisabled = function( row ) {
            if( $scope.changingQuantity ) {
                return true;
            }
            var datapointsInvalid = false;
            // Buttons should be disabled if any datapoint in the row is visibly invalid (touched and invalid)
            angular.forEach( $scope.enumerations[ row ], function( dPoint ) {
               if( dPoint.data.wasTouched && !dPoint.data.isValid || dPoint.data.isNull) {
                   datapointsInvalid = true;
               }
            });
            return datapointsInvalid;
        };

        $scope.buttonHidden = function( context, isLast ) {
            if( context == 'subtract' ) {
                return !isLast || $scope.enumerations.length == 1 || $scope.enumerations.length != $scope.newLength;
            } else {
                return !isLast || $scope.enumerations.length != $scope.newLength;
            }
        };

        $scope.saveReceived = function( event, data ) {
            $( "#summaryOfEquinesTable input" ).prop( "disabled", false );
            $scope.changingQuantity = false;
        };

        // The quantity datapoint is configured to send out a broadcast once the save data is returned
        // from mPolicy, so we can listen for that to know when that action is completed and we can
        // re-enable UI elements.
        $scope.$on( 'broadcastedDatapointSave', $scope.saveReceived )

    }

    return SummaryOfEquinesController;

});
