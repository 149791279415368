define('components/surveyList/directive/surveyList',['require'],function(require) {
	"use strict";

	SurveyList.$inject = [];

	function SurveyList() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/surveyList/template/template.html",
			controller: "SurveyListController"
		};
	}

	return SurveyList;

});
