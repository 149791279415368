define( 'components/specialtyApplication/resultSummary/app',['require','common/directive/app','components/editSubmission/app','components/viewApplication/app','components/specialtyApplication/quoteResult/app','components/specialtyApplication/resultSummary/directive/resultSummary','common/service/specialtyApplication/accountService','components/specialtyApplication/resultSummary/controller/ResultSummaryController','common/service/user/UserProfile','common/filter/formatMoney','common/filter/trustHtml'],function ( require ) {
	"use strict";

	require("common/directive/app");
	require("components/editSubmission/app");
	require("components/viewApplication/app");
	require("components/specialtyApplication/quoteResult/app");

	var result_summary_directive = require( "components/specialtyApplication/resultSummary/directive/resultSummary" ),
		AccountService = require("common/service/specialtyApplication/accountService" ),
		result_summary_controller = require( "components/specialtyApplication/resultSummary/controller/ResultSummaryController" ),
		UserProfile = require( "common/service/user/UserProfile" ),
		formatMoney = require( "common/filter/formatMoney" ),
		trustHtml = require( "common/filter/trustHtml" );

	var component = angular.module( "mPortal.components.specialtyApplication.resultSummary", [
		"mPortal.directive",
		"mPortal.editSubmission",
		"mPortal.viewApplication",
		"mPortal.components.specialtyApplication.quoteResult"
	] );

	component.filter( "formatMoney", formatMoney );
	component.filter( "trustHtml", trustHtml );
	component.directive( "specialtyApplicationResultSummary", result_summary_directive );
	component.controller( "SpecialtyApplicationResultSummaryController", result_summary_controller );
	component.factory( "UserProfile", UserProfile );
	component.factory("AccountService", AccountService);

	return component;

} );
