define('common/service/MessageContainer',['require'],function(require) {
	"use strict";

	function MessageContainerFactory() {

		function MessageContainer( defaults ) {

			defaults = defaults || {};
			angular.extend( this.defaults, defaults );
			this.reset();

			return this;

		}

		MessageContainer.prototype.reset = function() {
			// defaults have to be copied on because pass by reference breaks resetting for non-simple values like arrays or objects.
			angular.extend( this, angular.copy( this.defaults ) );
		};

		MessageContainer.prototype.defaults = {};

		return MessageContainer;

	}

	return MessageContainerFactory;

});
