define('components/applicationData/applicationData/config/widgets/ViewPolicyInformationWidget',['require'],function(require) {
    "use strict";

    var widget = {};

    widget.getStructure = function () {

        return {
            "viewPolicyInfo": {wrapper: null, fieldGroup:
                {
                    "viewMedicalPayment": {wrapper: ["PolicyInformation"],type: "setPolicyInfo", wrapperOverride: true},
                    "viewGLSplitLimit": {wrapper: ["PolicyInformation"], type: "setPolicyInfo", wrapperOverride: true},
                    "viewSexualAbuseLimit": {wrapper: ["PolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true},
                    "viewStudentAccidentLimit": {wrapper: ["PolicyInfoAccLimit"], type: "setPolicyInfo",  wrapperOverride: true},
                    "viewNoOfAddInsureds": {wrapper: ["PolicyInfoAddInsureds"], type: "setPolicyInfo",  wrapperOverride: true},

                    "viewAggregateLimit": {wrapper: ["BoPolicyAggregate"], type: "setPolicyInfo",  wrapperOverride: true},
                    "viewMedicalExpenses": {wrapper: ["BoPolicyMedical"], type: "setPolicyInfo",  wrapperOverride: true},
                    "viewPropertyDeductible": {wrapper: ["BoPolicyDeductible"], type: "setPolicyInfo",  wrapperOverride: true},
                    "viewPDLiabilityDeductible": {wrapper: ["BoPolicyLiability"], type: "setPolicyInfo",  wrapperOverride: true},

                    "viewCoverageSelection": {wrapper: ["PolicySelection"], type: "setPolicyInfo",  wrapperOverride: true},
                    "Years Insured":{wrapper: ["PolicySelection"], type: "setPolicyInfo",  wrapperOverride: true},
                    "Terrorism Coverage":{wrapper: ["PolicyCoverage"], type: "setPolicyInfo",  wrapperOverride: true},
                    "Previous Prem":{wrapper: ["PolicyCoverage"], type: "setPolicyInfo",  wrapperOverride: true},
                    "viewLosses":{wrapper: ["PolicyLosses"], type: "setPolicyInfo",  wrapperOverride: true},

                    "viewLimitsLabel": {wrapper: null, type: "omittedHeader",  wrapperOverride: true},
                    "viewDeductiblesLabel": {wrapper: null, type: "omittedHeader",  wrapperOverride: true},
                    "viewEssentialLabel": {wrapper: null, type: "omittedHeader",  wrapperOverride: true},
                    "viewTerrorismLabel": {wrapper: null, type: "omittedHeader",  wrapperOverride: true},
                    "Terrorism Label": {wrapper: null, type: "omittedHeader",  wrapperOverride: true},
                    "Loss History Label": {wrapper: null, type: "omittedHeader",  wrapperOverride: true},
                    "viewIncludeBOP": {wrapper: null, type: "omittedHeader",  wrapperOverride: true},

	                "viewYearsInsured": {wrapper: ["PolicyInformationForPestControl"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 1},
	                "viewNoOfTechEmployees": {wrapper: ["PolicyInformationForPestControl"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 2},
	                "viewNoOfNonTechEmployees": {wrapper: ["PolicyInformationForPestControl"], type: "setPolicyInfo",  wrapperOverride: true,orderNumber : 3},
	                "viewNon-PCOperations": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 4},
	                "viewNon-PCOperationsDesc": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 5},
	                "viewClaimsIn5Years": {wrapper: ["HalfRowPolicyInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 6},
	                "viewLossesAvailable": {wrapper: ["HalfRowPolicyInformation2"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 7},
	                "viewKnowledgeOfPriorClaims": {wrapper: ["HalfRowPolicyInformation3"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 8},
	                "viewKnowledgeOfPriorClaimsExplain": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 9},
	                "viewIncludeGLLabel": {wrapper: null, type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 10},
	                "viewIncludePR": {wrapper: null, type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 11},
	                "viewIncludeIM": {wrapper: null, type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 12},
	                "viewIncludeAuto": {wrapper: null, type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 13},
	                "viewIncludeUmbrella": {wrapper: null, type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 14},
	                "viewTypesofLicenses": {wrapper: ["PolicyFullRowAlert"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 15},
	                "viewLicense-GHP" :  {wrapper: ["FullRowPolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 16},
	                "viewLicense-BedBugs" :  {wrapper: ["FullRowPolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 17},
	                "viewLicense-Fumigation" :  {wrapper: ["FullRowPolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 18},
	                "viewLicense-WildlifeControl": {wrapper: ["FullRowPolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 20},
	                "viewLicense-TermiteTreatment": {wrapper: ["FullRowPolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 21},
	                "viewLicense-WDI" :  {wrapper: ["FullRowPolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 22},
	                "viewLicense-Lawn" :  {wrapper: ["FullRowPolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 23},
	                "viewLicense-Tree" :  {wrapper: ["FullRowPolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 24},

	                "viewUmbLimit": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 1},
	                "viewUmbWithAuto": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation2"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 2},
	                "viewUmbWithWC": {wrapper: ["FullRowUmbrellaExcessLiabilityForEmploy"], type: "setPolicyInfo",  wrapperOverride: true,orderNumber : 3},
	                "viewWCCarrierForUmb": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation3"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 4},
	                "viewWCBestRating": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 5},
	                "viewWCPolNumberForUmb": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation2"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 6},
	                "viewWCEffDate": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 7},
	                "viewWCXDate": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation2"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 8},
	                "viewEmployersLiabLimits": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 9},

	                "viewCglLimit": {wrapper: ["PolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 10},
	                "viewCglDeductible": {wrapper: ["PolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 11},
	                "viewPersonalInjuryLimit": {wrapper: ["PolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 12},
	                "viewProductsAggLimit": {wrapper: ["PolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 13},
	                "viewFireDamageLimit": {wrapper: ["PolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 14},
	                "viewMedicalPaymentsLimit": {wrapper: ["PolicyInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 15},

	                "viewOptionalCoverageLabel": {wrapper: ["PolicyFullRowAlert"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 16},

	                "viewGlEnhancement": {wrapper: ["FullRowInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 17},
	                "viewEbl": {wrapper: ["FullRowInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 18},

	                "viewEblLimit": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber :19},
	                "viewEblNoOfEmployees": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 20},
	                "viewEblRetroDate": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 21},

	                "viewEpli": {wrapper: ["FullRowInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 22},
	                "viewEpliLimit": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 23},
	                "viewEpliDeductible": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 24},
	                "viewEpliNoOfFullTimeEmployees": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 25},
	                "viewEpliNoOfPartTimeEmployees": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 26},
	                "viewEpliRetroDate": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 27},

	                "viewPestPropertyDeductible": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 28},
	                "viewPropertyCoinsurance": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 29},
	                "viewInclEquipBreakdown": {wrapper: ["FullRowInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 30},
	                "viewEquipBreakdownDeductible": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 31},
	                "viewPropEnhancement": {wrapper: ["FullRowInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 32},
	                "viewPeeForm": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 33},

	               "viewLimitedFungiliability" : {wrapper:null ,wrapperOverride: true,orderNumber : 34},
	                "viewLimitedMoldLimit": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 35},

	                "Monument Form" : {wrapper: null , wrapperOverride: true,orderNumber : 36},

	                "viewStopLable": {wrapper: null , wrapperOverride: true,orderNumber : 37},
	                "viewStopGapLimit": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 38},
	                "viewStopGapNoofStates" : {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation2"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 39},

	                "viewStopGapState": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation1"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 40},
	                "viewStopGapPayroll": {wrapper: ["HalfRowUmbrellaExcessLiabilityInformation2"], type: "setPolicyInfo",  wrapperOverride: true, orderNumber : 40}

                }
            }
        };

    };

    widget.configureFormly = function (formlyConfig, formlyApiCheck) {

        formlyConfig.setType({
            name: "setPolicyInfo",
            template:'<formly-transclude></formly-transclude>'

        });

	    formlyConfig.setWrapper({
		    name: "HalfRowUmbrellaExcessLiabilityInformation1",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide" >' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span6" style=" width:50%; margin-left:0; line-height: 14.5px;" >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper({
		    name: "HalfRowUmbrellaExcessLiabilityInformation2",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide" >' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span6" style=" width:48.5%; margin-left:0; line-height: 14.5px; padding-left: 10px" >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper({
		    name: "HalfRowUmbrellaExcessLiabilityInformation3",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide" >' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span6" style=" width:48.5%; margin-left:20px; line-height: 14.5px;" >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper({
		    name: "FullRowUmbrellaExcessLiabilityInformation",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide">' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span12" style="margin-left:0; line-height: 14.5px " >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper( {
		    name: "FullRowInformation",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide">' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span12" style="margin-left:0; line-height: 14.5px " >' +
		    '<span ng-if="options.templateOptions.label && options.defaultValue == true">{{::options.templateOptions.label}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    } );

	    formlyConfig.setWrapper({
		    name: "FullRowUmbrellaExcessLiabilityForEmploy",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide">' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "{{options.defaultValue===\'No\'?\'span12\':\'span6\'}}" style="margin-left:0; line-height: 14.5px " >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

        formlyConfig.setWrapper({
            name: "PolicyInformation",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div class= "span4" style=" width:33%; margin-left:0" >' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span><br>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "PolicyInfoAccLimit",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  style=" margin-left:0; margin-bottom: 40px" >' +
            '<span class= "span5" style=" width:31%; margin-left:0" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span  ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span class= "span7" style = "margin-left: 22px">{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "PolicyInfoAddInsureds",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  style=" margin-left:0; margin-bottom: 15px; padding-top: 8px" >' +
            '<span class= "span5" style=" width:27%; margin-left:0" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span  ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span class= "span4" style = "margin-left: 72px">{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "BoPolicyAggregate",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="span4" style=" width:31%; margin-left: 0%" >' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label).replace(":", "")}}</span><br>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "BoPolicyMedical",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="span4" style=" width:19.8%; margin-left: 0%" >' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label).replace(":", "")}}</span><br>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "BoPolicyDeductible",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="span4" style=" width:18%; margin-left: 0%" >' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label).replace(":", "")}}</span><br>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "BoPolicyLiability",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="span4" style=" width:31.2%; margin-left: 0%" >' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label).replace(":", "")}}</span><br>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "PolicyCoverage",
            template:
            '<div class="viewItem" style="position: relative" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span2" style="width:49.2%;max-height:30px; margin-left: 0px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span style="margin-left: 6px">{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "PolicySelection",
            template:
            '<div class="viewItem" style="position: relative" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span5" style="width:50.8%;max-height:30px; margin-left: 0px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span style="margin-left: 6px">{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "PolicyLosses",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:100%;max-height:30px; margin-left: 0%">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

	    formlyConfig.setWrapper({
		    name: "HalfRowPolicyInformation1",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide" >' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "{{(options.defaultValue===\'No\' || !options.defaultValue )?\'span12\':\'span8\'}}" style="margin-left:0; line-height: 14.5px;" >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper({
		    name: "HalfRowPolicyInformation2",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide" >' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span4" style="margin-left:0; line-height: 14.5px;" >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper({
		    name: "HalfRowPolicyInformation3",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide" >' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span8" style=" width:65%; margin-left:0; line-height: 14.5px; margin-right: 35%" >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper({
		    name: "FullRowPolicyInformation",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide" >' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span12" style="margin-left:0; line-height: 14.5px " >' +
		    '<span ng-if="options.templateOptions.label && options.defaultValue">{{::options.templateOptions.label}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper({
		    name: "PolicyInformationForPestControl",
		    template:
		    '<div class="viewItem" ng-if="!options.data.hide">' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span4" style=" width:33%; margin-left:0" >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>&nbsp;' +
		    '<span>{{::options.defaultValue}}</span><br><br>'+
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper( {
		    name: "PolicyFullRowAlert",
		    template:
		    '<div class= "viewItem">' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span12 alert alert-text" style="width: 95.3%; height:15px; margin-left:0;"  >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp<br><br></span>' +
		    '</div>' +
		    '</div>'
	    } );

    };

    return widget;
});


