define('components/widget/optional-endorsements/item-description-limit/app',['require','components/widget/optional-endorsements/item-description-limit/directive/itemDescriptionLimit','components/widget/optional-endorsements/item-description-limit/controller/ItemDescriptionLimitController'],function(require) {
    "use strict";

    var itemDescriptionLimit 		    = require("components/widget/optional-endorsements/item-description-limit/directive/itemDescriptionLimit"),
        ItemDescriptionLimitController 	= require("components/widget/optional-endorsements/item-description-limit/controller/ItemDescriptionLimitController");

    var component 					= angular.module("mPortal.components.widget.optional-endorsements.item-description-limit", []);

    component.directive("itemDescriptionLimit", itemDescriptionLimit);
    component.controller("ItemDescriptionLimitController", ItemDescriptionLimitController);

    return component;

});
