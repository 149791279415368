define('components/specialtyApplication/questions/config/widgets/BuildingOEsandAIsWidget',['require'],function(require) {
    "use strict";

    var widget = {};

    widget.getStructure = function() {

        return {
            "Location OE Label" : { wrapper: null, wrapperOverride: true },

            "Condo Coverage Optional": { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true },
            "CondoTable": {
                wrapper: [ "oeFieldGroupTable" ],
                data: {
                    tableDiv: { class: "oeFieldGroupTableContainer" },
                    table: {
                        class: "table table-bordered",
                        headerRows: [
                            {
                                headers: [ "Loss Assessment LOI", "Misc. Real Property LOI","Loss Assessment Deductible" ]
                            }
                        ]
                    }
                },
                fieldGroup: {
                    "row1": {
                        fieldGroup: {
                            "Condo Loss Assessment": {wrapper: [ "labelDatapoint","bOeSpan8HalfRowError" ], wrapperOverride: true},
                            "Condo Real Property": { wrapper: ["labelDatapoint","bOeSpan8HalfRowError"], wrapperOverride: true },
                            "Condo Deductible": { wrapper: ["labelDatapoint"], type: 'oeReadonly', wrapperOverride: true }
                        }
                    }
                }
            },

            "Food Contamination": { wrapper:[ "bootstrapTooltipIcon" ], wrapperOverride: true },
            "FoodContaminationTable": {
                wrapper: [ "oeFieldGroupTable" ],
                data: {
                    tableDiv: { class: "oeFieldGroupTableContainer" },
                    table: {
                        class: "table table-bordered",
                        headerRows: [
                            {
                                headers: [ "Food Contamination Limit", "Advertising Expense Limit" ]
                            }
                        ]
                    }
                },
                fieldGroup: {
                    "row1": {
                        fieldGroup: {
                            "Food Contamination Limit": {wrapper: [ "verticalHalfRowTable1" ], type: 'oeReadonly', wrapperOverride: true},
                            "Advertising Expense Limit": { wrapper: ["verticalHalfRowTable1"], type: 'oeReadonly', wrapperOverride: true }
                        }
                    }
                }
            },

            "Loss Payable" : { wrapper: "bootstrapTooltipIcon", wrapperOverride: true },
            "Loss Payable Number" : { wrapper: ["defaultBootstrapLabel"], wrapperOverride: true },
            "LossPayableDetails": { wrapper: ["loss-payable-details"], wrapperOverride: true, fieldGroup:
                {
                    "Loss Payee Name": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true, orderNumber: 1},
                    "Loss Payable Description": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true, orderNumber: 2},
                    "Loss Payable Clause": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true, orderNumber: 3},
                    "Loss Payee Address": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true, orderNumber: 4},
                    "Loss Payee Zip": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true, orderNumber: 5},
                    "Loss Payee City": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true, orderNumber: 6},
                    "Loss Payee State": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true, orderNumber: 7}
                }
            },

            "Mortgage Holder" : { wrapper: "bootstrapTooltipIcon", wrapperOverride: true },
            "Mortgage Number" : { wrapper: ["defaultBootstrapLabel"], wrapperOverride: true },
            "MortgageHolderDetails" : { wrapper: ["mortgage-holder-details"], wrapperOverride: true, fieldGroup:
                {
                    "Mortgage Holder Name": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "Mortgage Holder Account": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "Mortgage Holder Zip": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "Mortgage Holder Address 1": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "Mortgage Holder Address 2": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "Mortgage Holder City": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "Mortgage Holder State": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true}
                }
            },

            "Ordinance and Law": { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true},
            "OrdinanceAndLawTable": {
                wrapper: [ "oeFieldGroupTable" ],
                data: {
                    tableDiv: { class: "oeFieldGroupTableContainer" },
                    table: {
                        class: "table table-bordered",
                        headerRows: [
                            {
                                headers: [ "Coverage", "LOI" ]
                            }
                        ]
                    }
                },
                fieldGroup: {
                    "row1": {
                        fieldGroup: {
                            "Ordinance Coverage": {wrapper: [ "labelDatapoint", "bOeSpan8HalfRowError" ]},
                            "cell2": {
                                fieldGroup: {
                                    "Ordinance Coverage 1 Limit": {
                                        wrapper: [ "verticalHalfRowTable" ],
                                        type: 'oeReadonly',
                                        wrapperOverride: true
                                    },
                                    "Ordinance Combined Limit": {wrapper: [ "verticalHalfRowPoint", "bOeSpan8HalfRowError" ]}
                                }
                            }
                        }
                    }
                }
            },

            "Restaurants": { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true },
            "Food Coverage Error Warning": {wrapper: null, wrapperOverride: true },
            "RestaurantsTable": {
                wrapper: [ "oeFieldGroupTable" ],
                data: {
                    tableDiv: { class: "oeFieldGroupTableContainer" },
                    table: {
                        class: "table table-bordered",
                        headerRows: [
                            {
                                headers: [ "Food Contamination Limit", "Advertising Expense Limit","Spoilage Limit" ]
                            }
                        ]
                    }
                },
                fieldGroup: {
                    "row1": {
                        fieldGroup: {
                            "Contamination Limit": {wrapper: [ "verticalHalfRowTable1" ], type: 'oeReadonly', wrapperOverride: true},
                            "Advertising Limit": {wrapper: [ "verticalHalfRowTable1" ], type: 'oeReadonly', wrapperOverride: true},
                            "Restaurant Occ Limit": { wrapper: ["verticalHalfRowTable1","bOeSpan10HalfRowError"], wrapperOverride: true },
                            "Perishable Stock": { wrapper: ["verticalHalfRowPoint", "oeVerticalHalfRowError"], wrapperOverride: true}
                        }
                    }
                }
            },

            "Spoilage": { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true },
            "Spoilage Coverage Error Warning": { wrapper: null, wrapperOverride: true },
            "SpoilageTable": {
                wrapper: [ "oeFieldGroupTable" ],
                data: {
                    tableDiv: { class: "oeFieldGroupTableContainer" },
                    table: {
                        class: "table table-bordered",
                        headerRows: [
                            {
                                headers: [ "Limit", "Cause of Loss" ]
                            }
                        ]
                    }
                },
                fieldGroup: {
                    "row1": {
                        fieldGroup: {
                            "Spoilage Limit": {wrapper: [ "verticalHalfRowTable1", "bOeSpan8HalfRowError" ]},
                            "cell2": {
                                fieldGroup: {
                                    "Cause of Loss": {wrapper: [ "labelDatapoint", "bOeSpan8HalfRowError" ]},
                                    "Spoilage Refrigeration" : { type: "yesNoToggle", wrapper: [ "horizontalBox", "bOeSpan10HalfRowError" ], wrapperOverride: true },
                                    "Spoilage Refrigeration Explain": {wrapper: [ "textAreaTable", "bOeSpan8HalfRowError" ], type:"TextArea"},
                                    "Spoilage Perishable Stock": {wrapper: [ "horizontalBox", "bOeSpan8HalfRowError" ]},
                                    "Spoilage Perishable Other": {wrapper: [ "horizontalBox", "bOeSpan8HalfRowError" ]}
                                }
                            }
                        }
                    }
                }
            },

            "AIBuildingOwnerDetails": { wrapper: ["ai-buildingOwner-details"], fieldGroup:
                    {
                        "AI Building Owner Name": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                        "AI Building Owner Address 1": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                        "AI Building Owner Address 2": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                        "AI Building Owner Zip": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                        "AI Building Owner City": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                        "AI Building Owner State": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true}
                    }
            },

            "AICoOwnerDetails": { wrapper: ["ai-co-owner-details"], fieldGroup:
                    {
                        "AI Co Owner Name": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                        "AI Co Owner Address 1": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                        "AI Co Owner Address 2": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                        "AI Co Owner Zip": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                        "AI Co Owner City": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                        "AI Co Owner State": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true}
                    }
            },

            "Condo Unit Number": { wrapper: [ "defaultBootstrapLabel", "condoUnitNumberWrapper" ] },

            "AILeasedEquipmentDetails": { wrapper: ["ai-LeasedEquipment-details"], fieldGroup:
                {
                    "AI Leased Equipment Name": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Leased Equipment Address 1": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Leased Equipment Address 2": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Leased Equipment Zip": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Leased Equipment City": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Leased Equipment State": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true}
                }
            },

            "AIManagersOrLessorsDetails": { wrapper: ["ai-ManagersOrLessors-details"], fieldGroup:
                {
                    "AI Managers or Lessors Name": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Managers or Lessors Address 1": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Managers or Lessors Address 2": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Managers or Lessors Zip": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Managers or Lessors City": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Managers or Lessors State": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true}
                }
            },

            "AIMortgageeDetails": { wrapper: ["ai-Mortgagee-details"], fieldGroup:
                {
                    "AI Mortgagee Name": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Mortgagee Address 1": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Mortgagee Address 2": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Mortgagee Zip": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Mortgagee City": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Mortgagee State": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true}
                }
            },

            "AIOwnerOfLeasedLandDetails": { wrapper: ["ai-OwnerOfLeasedLand-details"], fieldGroup:
                {
                    "AI Owner of Leased Land Name": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Owner of Leased Land Address 1": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Owner of Leased Land Address 2": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Owner of Leased Land Zip": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Owner of Leased Land City": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Owner of Leased Land State": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true}
                }
            },

            "AIVendorsDetails": { wrapper: ["ai-Vendors-details"], fieldGroup:
                {
                    "AI Vendors Name": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Vendors Address 1": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Vendors Address 2": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Vendors Zip": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Vendors City": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true},
                    "AI Vendors State": {wrapper: ["horizontalBox", "bOeSpan8HalfRowError"], wrapperOverride: true}
                }
            }

        };
    };

    widget.configureFormly = function (formlyConfig, formlyApiCheck) {

        formlyConfig.setType({
            name: "oeReadonly",
            template: '<span ng-bind-html="options.defaultValue | trustHtml"></span>',
            defaultOptions: {
                noFormControl: true
            }
        });

        formlyConfig.setWrapper({
            name: "verticalHalfRowTable",
            template:
            '<div>' +
            'Coverage 1 LOI = Building Replacement Cost $' +
            '<formly-transclude></formly-transclude>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "verticalHalfRowTable1",
            template:
            '<div >' +
            '$ <formly-transclude></formly-transclude>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "bOeSpan3HalfRowError",
            overwriteOk: true,
            template:
            '<div class="span3 form-group" style="padding-left:30px;" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "bOeSpan8HalfRowError",
            overwriteOk: true,
            template:
            '<div class="span8 form-group"  ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "bOeSpan10HalfRowError",
            overwriteOk: true,
            template:
            '<div class="span10 form-group"  ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "textAreaTable",
            template:
            '<style>.textAreaBox textarea { height: 50px;width: 280px !important;  } </style><div class="textAreaBox">' + '<label for="{{ id }}" class="control-label">{{ to.label }}</label>' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "textAreaWoLabel",
            template:
            '<style>.textAreaBox textarea { height: 50px;width: 280px !important;  } </style><div class="textAreaBox">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

	    formlyConfig.setWrapper({
		    name: "mortgage-holder-details",
		    template: '<div style="padding-left:30px" mortgage-holder-details model="model" options="options"><formly-transclude></formly-transclude></div>'
	    });

        formlyConfig.setWrapper({
            name: "loss-payable-details",
            template: '<div style="padding-left:30px" loss-payable-details model="model" options="options"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-buildingOwner-details",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Building Owner\'" location-control-id="\'aiBuildingOwnerDetails\'"><formly-transclude></formly-transclude></div>'
        });


        formlyConfig.setWrapper({
            name: "ai-co-owner-details",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options"  header="\'Co Owner of Insured Premises\'" location-control-id="\'aiCoOwnerDetails\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-LeasedEquipment-details",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Lessor of Leased Equipment\'" location-control-id="\'AILeasedEquipmentDetails\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-ManagersOrLessors-details",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Managers or Lessors of Premises\'" location-control-id="\'AIManagersOrLessorsDetails\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-Mortgagee-details",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Mortgagee, Assignee, or Receiver\'" location-control-id="\'AIMortgageeDetails\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-OwnerOfLeasedLand-details",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Owner or Other Interests from whom Land has been Leased\'" location-control-id="\'AIOwnerOfLeasedLandDetails\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-Vendors-details",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Vendors\'" location-control-id="\'VendorsDetails\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "condoUnitNumberWrapper",
            template:
            '<div>' + '<b>' +'Condominium Commercial Unit Owners' + '</b>' + '</div>' + '</br>' +
            '<formly-transclude></formly-transclude>'
        });
    };


    return widget;
});
