define('components/marketedPrograms/detail/controller/FileUploadModalController',['require'],function(require) {
	"use strict";

	FileUploadModalController.$inject = ["$scope", "$modalInstance", "archApiUrl", "program", "UserProfile"];

	function FileUploadModalController( $scope, $modalInstance, archApiUrl, program, UserProfile ) {

		$scope.program = program;
		$scope.close = $modalInstance.close;
		$scope.formAction = archApiUrl + "application/upload";
		$scope.profileToken = UserProfile ? ( UserProfile.profileToken ? UserProfile.profileToken : UserProfile.portalToken ) : "";
		$scope.uiState = {
			submitting: false,
			completed: false,
			errors: []
		};

		$scope.applications = [{id: 'application1'}];

		$scope.onSubmit = function() {
			$scope.uiState.submitting = true;
			$scope.uiState.completed = false;
			$scope.uiState.errors = [];
		};

		$scope.onUploadSuccess = function( result ) {
			$scope.uiState.submitting = false;
			$scope.uiState.completed = true;
		};

		$scope.onUploadFailure = function( result ) {
			$scope.uiState.submitting = false;
			$scope.uiState.completed = false;
			$scope.uiState.errors = result.message || [ "An unexpected error has occurred. Please try again." ];
		};

		$scope.addNewApplication = function () {
			$scope.applications.push( {'id': 'application' + ($scope.applications.length + 1)} );
		};

		$scope.removeApplication = function ( index ) {
			$scope.applications.splice( index, 1 );
		};

	}

	return FileUploadModalController;

});
