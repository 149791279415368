define('components/policyDetail/policyDetail/directive/policyDetail',['require'],function(require) {
	"use strict";

	function PolicyDetail() {
		return {
			restrict: "AE",
			scope: {
				policy: "=",
				account: "=",
				allPolicies: "=",
				openPolicyTab: "=",
				openClaimTab: "=",
				submission: "=",
				allSubmissions: "=",
				openSubmissionTab: "=",
				setMessageView: "=",
				user: "="
			},
			templateUrl: "app/components/policyDetail/policyDetail/template/template.html",
			controller: "PolicyDetailController"
		};
	}

	return PolicyDetail;

});
