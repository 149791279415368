define( 'components/specialtyApplication/quoteResult/directive/quoteResult',['require'],function( require ) {
    "use strict";

    QuoteResult.$inject = [];

    function QuoteResult() {
        return {
            restrict: "AE",
            scope: {
                applicationId: "=",
                marketedProgramId: "=",
                result: "="
            },
            templateUrl: "app/components/specialtyApplication/quoteResult/template/template.html",
            controller: "SpecialtyApplicationQuoteResultController"
        };
    }

    return QuoteResult;

});
