define('components/insuredRegistration/accountLookup/app',['require','components/insuredRegistration/accountLookup/directive/accountLookup','components/insuredRegistration/accountLookup/controller/AccountLookupController'],function(require) {
	"use strict";

	var accountLookup = require("components/insuredRegistration/accountLookup/directive/accountLookup"),
		AccountLookupController = require("components/insuredRegistration/accountLookup/controller/AccountLookupController");

	var component = angular.module("mPortal.insuredRegistration.accountLookup", []);

	component.directive("accountLookup", accountLookup);
	component.controller("AccountLookupController", AccountLookupController);

	return component;

});
