define('forms/app',['require','common/config/config','components/stateImageMap/app','components/filterableDocumentList/app','shim/overmind','forms/controller/FormsController','common/service/FormService'],function(require) {
	"use strict";

	require("common/config/config");	
	require("components/stateImageMap/app");
	require("components/filterableDocumentList/app");

	var overmind = require("shim/overmind"),
		FormsController = require("forms/controller/FormsController"),
		FormService = require("common/service/FormService");

	var app = angular.module("mPortal.forms", [
		"mPortal.config",
		"mPortal.components.stateImageMap",
		"mPortal.components.filterableDocumentList"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider.when("/form/:slug?/:state?", {
			templateUrl: "app/forms/template/template.html",
			controller: "FormsController",
			reloadOnSearch: false
		});
	}]);

	app.factory("FormService", FormService);
	app.controller("FormsController", FormsController);

	return app;

});
