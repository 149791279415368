define( 'common/service/PaymentService',['require'],function ( require ) {
	"use strict";

	PaymentService.$inject = ["$http", "$window", "archApiUrl", "SubmissionStore"];

	function PaymentService( $http, $window, archApiUrl, SubmissionStore ) {

		var service = {};

		service.verifyAccount = function( accountId, zipCode, honeyPot, email ) {
			return $http.post( archApiUrl + "account/verify", {
				accountId: accountId,
				zipCode: zipCode,
				honeyPot: honeyPot,
				email: email
			});
		};

		service.getPaymentURL = function ( applicationId, billingPlan, marketedProgramId , delayedPaymentFlag, redirectURL, supportPhoneNumber ) {
			var data = {
				applicationId: applicationId,
				billingPlan: billingPlan,
				marketedProgramId: marketedProgramId,
				delayedPaymentFlag:delayedPaymentFlag,
				redirectURL: redirectURL,
				supportPhoneNumber : supportPhoneNumber
			};
			return $http.post( archApiUrl + "application/" + applicationId + "/payment-request", data ).then( function ( result ) {
				return {error: false, paymentURL: result.data.response.paymentURL}
			} ).catch( function ( err ) {
				return {error: true, paymentURL: null}
			} );
		};

		service.getAccountPaymentUrl = function( accountId, billingSystem ) {
			var requestUrl = archApiUrl + "account/" + ( accountId ? accountId + "/" : "" ) + "payment-request" + ( billingSystem ? "?billingSystem=" + billingSystem : "")
			return $http.get( requestUrl ).then(function( result ) {
				return result.data;
			});
		};

		service.openPaymentWindow = function( accountId, billingSystem ) {
			var storageId = accountId + "Payment";

			if( SubmissionStore.documentWindows[ storageId ] != undefined && SubmissionStore.documentWindows[ storageId ].closed ) {
				delete SubmissionStore.documentWindows[ storageId ];
			}

			if( SubmissionStore.documentWindows[ storageId ] == undefined ) {
				// to avoid pop-up blockers, we need to open the window before kicking off an async call, so we'll load it with no URL for now.
				SubmissionStore.documentWindows[ storageId ] = $window.open( '', storageId, "location=0,width=990,height=550,resizable=1,scrollbars=1" );
				SubmissionStore.documentWindows[ storageId ].document.write( "Loading.." );
				this.getAccountPaymentUrl( accountId, billingSystem ).then( function( result ) {
					SubmissionStore.documentWindows[ storageId ].location.href = result.url;
				}).catch(function ( err ) {
					SubmissionStore.documentWindows[ storageId ].document.body.innerHTML = "We were unable to load your payment information. Please try again.";
				});
			}

		};

		return service;
	}

	return PaymentService;

} );
