define( 'components/specialtyApplication/submit/programDetails/miscErrorsOmissions/directive/miscErrorsOmissions',['require'],function( require ) {
	"use strict";

	function miscErrorsOmissions() {
		return {
			restrict: "AE",
			scope: {},
			templateUrl: "app/components/specialtyApplication/submit/programDetails/miscErrorsOmissions/template/template.html",
			controller: "MiscErrorsOmissionsController"
		};
	}

	return miscErrorsOmissions;

});
