define('components/promotionsSummary/controller/PromotionsSummaryController',['require'],function(require) {
	"use strict";

	PromotionsSummaryController.$inject = ["$scope", "$sce", "$modal", "PromotionsService"];

	function PromotionsSummaryController( $scope, $sce, $modal, PromotionsService ) {

		$scope.promotionsSummary = {};

		PromotionsService.getPromotionsSummary().then(function( result ) {
			$scope.promotionsSummary = result;
		});

		$scope.openDetail = function( type ) {
			PromotionsService.getPromotionHistory( type ).then(function(result) {

				$modal.open({
					templateUrl: "app/components/promotionsSummary/template/modal.html",
					controller: "PromotionModalController",
					resolve: {
						body: function() {
							return $sce.trustAsHtml( result.body );
						}
					}
				});

			});
		};

	}

	return PromotionsSummaryController;

});
