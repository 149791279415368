define( 'components/message/form/filter/PolicyVisibleFilter',['require'],function ( require ) {
	"use strict";

	PolicyVisibleFilter.$inject = ["moment"];

	function PolicyVisibleFilter( moment ) {
		return function ( input, userType ) {
			var output = [];
			var expiredPolices = [];
			var FlatRatePolicy = [];
			angular.forEach( input, function ( policy ) {
				if ( policy.isPolicy && policy.accountViewVersion === 2 && (userType === 'Agent' || policy.insuredType === 'Direct') ) {

					var isPastTwoYearPolicy = moment( policy.expirationDate, "MM/DD/YYYY" ).isBetween( moment().subtract( 2, 'years' ), moment() );
					var isNotExpired = moment( policy.expirationDate, "MM/DD/YYYY" ).isAfter( moment() );
					var isFlatRatePolicy = moment( policy.instanceTranseffDate, "MM/DD/YYYY" ).isSame( moment( policy.effectiveDate, "MM/DD/YYYY" ) );

					if ( !isFlatRatePolicy && policy.status === 'Cancelled' ) {
						output.push( policy );
					}
					if ( (isPastTwoYearPolicy || isNotExpired ) && ( policy.status !== 'Cancelled') ) {
						output.push( policy );
					}
					if ( output.length === 0 && (policy.status !== 'Cancelled') ) {
						expiredPolices.push( policy );
					}
					if ( isFlatRatePolicy && output.length === 0 && expiredPolices.length === 0 ) {
						FlatRatePolicy.push( policy );
					}
				}
			} );
			if ( output.length ===0 && expiredPolices.length > 0 ) {
				var visibleExpiredPolicy = expiredPolices[0];
				angular.forEach( expiredPolices, function ( policy ) {
					if ( visibleExpiredPolicy.expirationDate < policy.expirationDate ) {
						visibleExpiredPolicy = policy;
					}
				} );
				output.push( visibleExpiredPolicy );
			}
			if ( output.length ===0 && FlatRatePolicy.length > 0 ) {
				var visibleCancelledPolicy = FlatRatePolicy[0];
				angular.forEach( FlatRatePolicy, function ( policy ) {
					if ( visibleCancelledPolicy.expirationDate < policy.expirationDate ) {
						visibleCancelledPolicy = policy;
					}
				} );
				output.push( visibleCancelledPolicy );
			}
			return output;
		};
	}

	return PolicyVisibleFilter;
} );
