define('common/service/interceptor/CacheBusterInterceptor',[],function() {
	"use strict";

	// append a random character to the end of the cachebuster so that maverick knows not to
	// encode requests from mPortal...............
	var letters = "abcdefghijklmnopqrstuvwxyz";

	function CacheBusterInterceptor() {
		return {
			request: function( config ) {
				if( config.method === "GET" && !config.url.match(/\.html/) ) {
					var separator = config.url.indexOf("?") === -1 ? "?" : "&";
					config.url += separator + "_=" + new Date().getTime();
					config.url += letters.charAt( Math.floor( Math.random() * letters.length ) );
				}

				return config;
			}
		};
	}

	return CacheBusterInterceptor;

});
