define('common/service/interceptor/ErrorInterceptor',['require'],function(require) {
	"use strict";

	ErrorInterceptor.$inject = ["$injector"];

	function ErrorInterceptor( $injector ) {

		var modalIsOpen = false;
		var apiRegEx = new RegExp( "markelinsurance.com\/api\/" );
		var timeoutMessageRegEx = new RegExp( "timed out" );
		var applicationSubmissionRegEx = new RegExp( "application\/submission\/\\d+" );

		return {
			responseError: function( responseError ) {
				//Standardize error data from different systems
				if( responseError.data ) {
					try {
						responseError.data.message = responseError.data.message ? responseError.data.message : responseError.data.error;
					} catch( e ) {
						// in case responseError.data doesn't support adding props, like if it's a string. no need to handle this error,
						// the value gets defaulted if necessary.
					}
				}

				let loss_run_regex = new RegExp("/loss-run-api/");
				var modal = $injector.get("$modal"),
					$location = $injector.get("$location"),
					$q = $injector.get("$q"),
					UserService = $injector.get("UserService"),
					maverickApiUrl = $injector.get("maverickApiUrl"),
					modalParams = {
						title: "",
						message: "",
						exception: "",
						status :responseError.status
					};

				switch( responseError.status ) {

					case 401:
						//suppress 401 errors from the loss run API
						if( loss_run_regex.test( responseError.config.url ) ) {
							return $q.resolve({data:[]});
						}

						modalParams.title = "Session Timed Out";
						modalParams.message = responseError.data.message;
					break;

					case 403:
						/*
						403 errors returned from "api" calls to Arch could indicate a session timeout that would prevent
						insured users from being able to proceed. This code will detect that scenario and provide a more
						specific error response that will include the application ID.
						*/

						// Create a JavaScript regex for /loss-run-api
						if( loss_run_regex.test( responseError.config.url ) ) {
							return $q.resolve({data:[]});
						}

						if( apiRegEx.test( responseError.config.url ) && timeoutMessageRegEx.test( responseError.data.message ) ) {
							modalParams.title = "Session Timed Out";
							var requestUrl = responseError.config.url;
							var applicationUrlFragment = requestUrl.match( applicationSubmissionRegEx );
							if( applicationUrlFragment ) {
								var applicationId = applicationUrlFragment[0].split( '/' )[ 2 ];
								modalParams.message = responseError.data.message
									+ " Please contact us and provide the following submission ID number: "
									+ applicationId + "." ;
							} else {
								modalParams.message = responseError.data.message;
							}
						} else {
							modalParams.title = "Access Error";
							modalParams.message = responseError.data.message;
						}

					break;

					case 408:
						modalParams.title = "Request Timeout";
						modalParams.message = "Your request to " + responseError.config.url + " has timed out.";
					break;

					case 500:
						modalParams.title = "Error";
						modalParams.message = responseError.data.message ? responseError.data.message : "Internal Server Error";
						modalParams.exception = responseError.data.exception;
					break;

					default:
						// only display a modal for the statuses checked above, otherwise just pass the error on.
						return $q.reject( responseError );

				}

				// if we hit multiple exceptions, angular will try to open multiple modals that overlap. if we already have one open,
				// let's not flood the user with more.
				if( !modalIsOpen ) {

					var modalInstance = modal.open({
						templateUrl: "app/components/errorModal/template/template.html",
						resolve: {
							title: function() { return modalParams.title; },
							message: function() { return modalParams.message; },
							exception: function() { return modalParams.exception; },
							status : function() { return modalParams.status;}
						},
						controller: "ErrorModalController"
					});

					modalIsOpen = true;
				}

				return $q.reject( responseError );

			}
		};
	}

	return ErrorInterceptor;

});
