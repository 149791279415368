define('common/service/QuoteResultService',['require'],function(require) {
	"use strict";

	QuoteResultService.$inject = ["$http"];

	function QuoteResultService( $http ) {

		var service = {};

		service.check_bind_api = function( applicationId, agent_id, calling_system ) {
            return $http.post( "/check-bind-it-api/intake", {
                quote_id: applicationId,
                agent_id: agent_id,
                calling_system: calling_system
            }).then( function ( result ) {
				return result.data;
			}).catch( function ( err ) {
				return null;
			});
        };

        service.check_cataboom_url = function(submissionId) {
            return $http.post( "/cataboom-api/intake", {
                account_id: submissionId
            }).then( function ( result ) {
				return result.data;
			}).catch( function ( err ) {
				return null;
			});
		};

		return service;

	}

	return QuoteResultService;

});
