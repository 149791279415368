define('common/service/user/UserService',['require'],function(require) {
	"use strict";

	UserService.$inject = [ "$http", "maverickApiUrl", "archApiUrl", "ssoApiUrl","ciamApiUrl", "UserProfile" ];

	function UserService( $http, maverickApiUrl, archApiUrl,ssoApiUrl,ciamApiUrl, UserProfile ) {

		var service = {},
			cachedProfilePromise,
			// Several methods POST data using form field data instead of JSON data
			formPostSettings = { headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" } };

		service.getUserData = function() {
			return $http.get( maverickApiUrl + "user/getUserData/" ).then(function( result ) {
				return result.data;
			});
		};

		service.check_user = function(username)
		{
			return $http.post( maverickApiUrl + "sso:authentication/check_ciam_status", $.param({ username : username }) , formPostSettings ).then( function( result ) {
				return result.data;
			});
		};

		service.check_ciam_user = function(username,password)
		{
			return $http.post( maverickApiUrl + "sso:authentication/check_ciam_user", $.param({ username : username , password : password }) , formPostSettings ).then( function( result ) {
				return result.data;
			});
		}


		service.update_ciam_attempts = function(username) {
			return $http.post( maverickApiUrl + "sso:authentication/update_ciam_attempts", $.param({ username : username  }) , formPostSettings ).then( function( result ) {
				return result.data;
			});
		}

		service.send_verification_code = function(email,guid) {
			return $http.post( maverickApiUrl + "sso:authentication/send_verification_code", $.param({ email : email, guid: guid }) , formPostSettings ).then( function( result ) {
				return result.data;
			});
		}

		service.okta_login = function(id,password){
			return $http.post( ciamApiUrl + "auth/login-request/mportal", $.param({ id : id, password : password }) , formPostSettings ).then( function( result ) {
				return result.data;
			});
		};

		service.verify_ciam_code = function(guid , email, code) {
			return $http.post( maverickApiUrl + "sso:authentication/verify_ciam_code", $.param({ guid : guid, email : email, code : code }) , formPostSettings ).then( function( result ) {
				return result.data;
			});
		}

		service.migrate_ciam_user = function(guid, email , new_user) {
			return $http.post( maverickApiUrl + "sso:authentication/migrate_ciam_user", $.param({ guid : guid, email : email , new_user : new_user }) , formPostSettings ).then( function( result ) {
				return result.data;
			});
		}

		service.ciam_unique_user = function(userName,guid){
			return $http.post( maverickApiUrl + "sso:authentication/ciam_unique_user", $.param({ userName : userName,guid : guid }) , formPostSettings ).then( function( result ) {
				return result.data;
			});
		};

		service.magency_check_user = function(guid,confirmationNumber)
		{
			return $http.post( archApiUrl + "magency-user/check", $.param({ guid : guid , confirmationNumber : confirmationNumber}) , formPostSettings ).then( function( result ) {
				return result.data;
			});
		};

		service.magency_update_user = function(user)
		{
			return $http.post( archApiUrl + "magency-user/update",user ).then( function( result ) {
				return result.data;
			});
		};

		service.getUserProfile = function() {

			// cache the promise so we can use it in each of our routes without making an http request for every route load.
			cachedProfilePromise = $http.get( archApiUrl + "authenticate/profile" ).then(function( result ) {
				return UserProfile.extend( result.data, { isLoaded: true });
			}).catch(function( err ) {
				return UserProfile.extend({ isLoaded: true });
			});

			return cachedProfilePromise;

		};

		service.getMVPAccounts = function() {
			return $http.get( archApiUrl + "authenticate/mvp").then( function( result ) {
				return result.data;
			});
		};

		service.switchMvpAccount = function(index) {
			return $http.post( archApiUrl + "authenticate/mvp",$.param({ index:index }),formPostSettings).then( function( result ) {
				return result.data;
			});
		};

		service.clear = function() {
			return $http.get( maverickApiUrl + "sso:authentication/clear").then( function( result ) {
				return result.data;
			});
		}

		service.createInsuredRegistrationUserProfile = function() {
			return $http.get( archApiUrl + "authenticate/insured-registration" ).then(function( result ) {
				return UserProfile.extend( result.data, { isLoaded: true });
			}).catch(function( err ) {
				return UserProfile.extend({ isLoaded: true });
			});
		};

		service.getCachedUserProfile = function() {
			return cachedProfilePromise || service.getUserProfile();
		};

		service.savePersonalDetails = function( user ) {
			var postProperties = [ "firstName", "lastName", "phone", "email", "birthdate", "personalDetailPassword", "address", "address2", "city", "stateOrProvince", "postalCode" ];
			var postObject = $.param( this.copySelectedProperties( user, postProperties ) || {} );

			return $http.post( maverickApiUrl + "user/savePersonalDetails", postObject, formPostSettings ).then( function( result ) {
				return result.data;
			});
		};

		service.saveCredentials = function( user ) {
			var postProperties = [ "userName", "newUserName", "priorPassword", "password", "passwordConfirm" ];
			var postObject = $.param( this.copySelectedProperties( user, postProperties ) || {} );

			return $http.post( maverickApiUrl + "user/saveCredentials", postObject, formPostSettings ).then( function( result ) {
				return result.data;
			});
		};

		service.saveSecurityResponse = function( user ) {
			var postProperties = [ "question", "answer", "securityPassword" ];
			var postObject = $.param( this.copySelectedProperties( user, postProperties ) || {} );

			return $http.post( maverickApiUrl + "user/saveSecurityResponse", postObject, formPostSettings ).then( function( result ) {
				return result.data;
			});
		};

		service.saveStateSubscriptions = function( subscriptions ) {
			var postObject = $.param( subscriptions || {} );
			return $http.post( maverickApiUrl + "user/saveStateSubscriptions", postObject, formPostSettings ).then( function( result ) {
				return result.data;
			});
		};

		/*
		 An insured with multiple accounts could have agent-insured policies, direct insured policies, or
		 a mix of both.  Comparing the count of agent-insured policies to the overall count, and taking the
		 existing insuredType value on the UserProfile (based solely on the types of accounts the insured has),
		 we can determine the correct insuredType to set.
		 */
		service.setUserInsuredTypeByPolicyCounts = function( policyCount, agentInsuredPolicyCount, userProfile ) {
			if( agentInsuredPolicyCount ) {
				userProfile.set( "insuredType", ( agentInsuredPolicyCount == policyCount ? "Agent" : "Mix" ) );
			} else if( !agentInsuredPolicyCount && userProfile.insuredType !== "Direct" ) {
				userProfile.set( "insuredType", "Direct" );
			}
		};

		service.copySelectedProperties = function( obj, selectedProperties ) {
			var objCopy = angular.extend( {}, obj );

			angular.forEach( objCopy, function( value, key ) {
				if( selectedProperties.indexOf( key ) == -1 ) {
					delete objCopy[ key ];
				}
			});

			return objCopy;
		};

		service.logout = function() {
			UserProfile.reset();
			var url = maverickApiUrl + "sso:authentication/logout?silent=true" ;

			return $http.get( url );
		};

		return service;

	}

	return UserService;

});
