define('components/insuredRegistration/accountLookup/controller/AccountLookupController',['require'],function(require) {
	"use strict";

	AccountLookupController.$inject = ["$scope", "$location", "InsuredRegistrationService", "UserService"];

	function AccountLookupController( $scope, $location, InsuredRegistrationService, UserService ) {

		$scope.form = {
			accountNumber: $scope.insuredAccountNumber ? $scope.insuredAccountNumber : "",
			zipCode: "",
			email: "",
			test: ""
		};

		if( angular.isUndefined( $scope.submitButtonText ) ){
			$scope.submitButtonText = "Submit";
		}

		$scope.uiState = {
			errors: {},
			cantFindAccount: false,
			submitting: false
		};

		$scope.submit = function() {
			$scope.uiState.submitting = true;
			$scope.uiState.errors = {};
			$scope.uiState.cantFindAccount = false;

			/*
			 The UserProfile object will be refreshed to ensure that:
			 --A currently unauthenticated user (including users that have recently logged out) obtains a CSRF token value.
			 --Logged-in users (who shouldn't be attempting to use this feature) will be redirected to the portal home page.
			 */
			UserService.getUserProfile().then( function( profile ) {
				if( profile.profileToken && $scope.refresh ) {
					$location.path( "/" );
				}

				InsuredRegistrationService.verifyAccount( $scope.form.accountNumber, $scope.form.zipCode, $scope.form.test, $scope.form.email ).then(function(result) {
					$scope.onNextStep( $scope.form.accountNumber, $scope.form.zipCode, $scope.form.email );
				}).catch(function( err ) {
					if( err.status === 404 ) {
						$scope.uiState.cantFindAccount = true;
					}

					if( err.status === 422 ) {
						$scope.uiState.errors = err.data;
					}
				}).finally(function() {
					$scope.uiState.submitting = false;
				});

			});

		};

		$scope.formIsValid = function() {
			return $scope.form.accountNumber && $scope.form.zipCode && ( $scope.form.email || !$scope.includeEmail );
		};

	}

	return AccountLookupController;

});
