define('components/startQuote/directive/productLineClassCodeRow',['require'],function(require) {
	"use strict";

	ProductLineClassCodeRow.$inject = [];

	function ProductLineClassCodeRow() {
		return {
			restrict: "AE",
			scope: {
				rowTitle: "@",
				line: "=",
				classCodes: "=",
				groups: "=",
				programs: "=",
				effectiveDate: "=",
				state: "=",
				productLines: "=",
                classifications: "="
			},
			templateUrl: "app/components/startQuote/template/productLineClassCodeRow.html",
			controller: "ProductLineClassCodeRowController"
		};
	}

	return ProductLineClassCodeRow;

});
