define( 'components/specialtyApplication/section/detail/controller/SectionDetailController',[],function () {

	SectionDetailController.$inject = ["$scope", "UserProfile"];

	function SectionDetailController( $scope, UserProfile ) {
		$scope.fields 			 	= [];
		$scope.title 			 	= '';
		$scope.enumeration_title 	= '';

		$scope.add_enumeration 	 	= false;
		$scope.section.open		 	= false;
		$scope.section.loaded    	= false;
		$scope.section.loading		= false;
		$scope.section.valid     	= true;
		$scope.show_all				= ($scope.layout == 'vertical')?$scope.layout:false;

		if($scope.section.depth == 1)
		{
			$scope.open = true;
		}

		//console.log($scope.section.title,$scope.section.type,$scope.section.datapoints);

		//on load check if we have sub sections, if we do lets see if the are enumerations
		//if enumeration show add button
		if($scope.section.sections.length)
		{
			$scope.add_enumeration = $scope.section.sections[0].enumeration != null;
			$scope.enumeration_title = $scope.section.sections[0].enumeration;
		}
		
		$scope.activate_section = function(e,section){
			$scope.section.active = true;

			e.stopPropagation();

			return false;
		}

		$scope.section_open = function(e,section){

			if($scope.section.loaded && $scope.section.depth > 1)
			{
				section.open = !section.open;
			}

			e.stopPropagation();

			return false;
		}

		$scope.load_section = function(){
			//load our sections if we aren't loaded but active 
			if($scope.section.active)
			{
				if(!$scope.section.loaded && $scope.section.type != 'inline')$scope.$emit('load-section',{ section:$scope.section });

				if($scope.section.sections.length)
				{
					for(var i=0; i < $scope.section.sections.length; i++)
					{
						var sub_section = $scope.section.sections[i];
							
							sub_section.active = true;
							sub_section.open   = true;
					}
				}
			}

			//$scope.section.valid = $scope.section.isComplete();
		
			if(!$scope.section.active && $scope.section.visited)
			{ 
				$scope.section.open = !$scope.section.valid && $scope.section.loaded;
			}


			if($scope.section.active && $scope.section.visited)
			{
				$scope.section.open = true;
			}

			return false;
		}

		$scope.add_section = function(e,section){
			$scope.$emit('add-section',{ section:section });

			e.stopPropagation();
			return false;
		}

		$scope.delete_section = function(e,section){
			$scope.$emit('delete-section',{ section:section });

			e.stopPropagation();
			return false;
		}

		$scope.$watch('section.active',$scope.load_section);
	}
	return SectionDetailController;

} );
