define('common/service/StartQuoteService',['require'],function(require) {
	"use strict";

	StartQuoteService.$inject = ["$q", "EligibilityService", "ClassCodeService", "DateService"];

	function StartQuoteService( $q, EligibilityService, ClassCodeService, DateService ) {

		var service = {};

		service.allowEligibilityCheck = function( lines ) {

			var atLeastOneLineSelected = false;

			angular.forEach( lines, function( line ) {
				if( line.enabled ) {
					atLeastOneLineSelected = true;
				}
			});

			return atLeastOneLineSelected;

		};

		service.validate = function( line, state, effectiveDate, ClassificationId ) {

            if( !state || !effectiveDate ) {
                return "All fields required";
            }

            if( !DateService.isValidDate( effectiveDate, "MM/DD/YYYY" ) ) {
                return "Effective date must be a valid date (mm/dd/yyyy)";
            }

            if (line.productLine == "bo" || line.productLine == "wc") {
                var classCode = angular.isObject(line.classCode) ? line.classCode.value : line.classCode,
                    classCodeDesc = angular.isObject(line.classCode) ? line.classCode.descriptionId : null,
                    programsAvailable = line.programsAvailable,
                    program = line.program,
                    isValidClassCode = ClassCodeService.validateClassCode(classCode, line.productLine);


                if (!programsAvailable && !classCode.length) {
                    return "Class code is required";
                }

                if (programsAvailable && program === 0 && !classCode.length) {
                    return "Class code or program is required";
                }

                if (line.productLine == "bo" && !classCodeDesc) {
                    return "Class code description is required"
                }

                if (program === 0 && !isValidClassCode) {
                    return "Invalid class code";
                }

            }

            if (line.productLine === 'eo' && !line.classCode) {
                return "Please select classification for bundle or unselect Miscellaneous E&O to continue";
            }

			return "";

		};

		service.checkEligibility = function( lines, enterpriseID, state, effectiveDate ) {

			var params = {
				eligibilityState: state,
				effectiveDate: effectiveDate,
				stateClassCodes: {},
				program: {},
				agencyID: enterpriseID
			};

			angular.forEach( lines, function( line ) {
				line.messages.checking = true;
				params.stateClassCodes[ line.productLine ] = [ service.parameterizeLine( line, state ) ];

				if( line.programsAvailable && line.program !== 0 ) {
					params.program[ line.productLine ] = [{ program: line.program }];
				}
			});

			// if no lines were passed in, there's no point in making an http request. just resolve an empty object
			// back to the calling process.
			if( !Object.keys( params.stateClassCodes ).length ) {
				return $q.when({});
			}

			return EligibilityService.checkEligibility( params ).then(function( eligibility ) {
				return eligibility.quoteProgressStatus;
			});

		};

		service.parameterizeLine = function( line, state ) {

			var param = { state: state },
				classCodeDescriptionId,
				classificationId,
				classCodeId;

			if( angular.isObject( line.classCode ) ) {
				param.classCode = line.classCode.value;
				classCodeDescriptionId = line.classCode.descriptionId;
				classCodeId = line.classCode.id;
				if( line.productLine === 'eo'){
					classificationId = line.classCode.id;
				}
			} else {
				param.classCode = line.classCode;
			}

			if( typeof classCodeDescriptionId !== "undefined" ) {
				param.descriptionId = classCodeDescriptionId;
			}

			if( typeof classCodeId !== "undefined" ) {
				param.classCodeId = classCodeId;
			}

			if( typeof classificationId !== "undefined" ) {
				param.classificationId = classificationId;
			}

			return param;

		};

		return service;

	}

	return StartQuoteService;

});
