define('components/widget/building-optional-endorsements/loss-payable-details/app',['require','components/widget/building-optional-endorsements/loss-payable-details/directive/lossPayableDetails','components/widget/building-optional-endorsements/loss-payable-details/controller/LossPayableDetailsController'],function(require) {
    "use strict";

    var lossPayableDetails 		= require("components/widget/building-optional-endorsements/loss-payable-details/directive/lossPayableDetails"),
        LossPayableDetailsController = require("components/widget/building-optional-endorsements/loss-payable-details/controller/LossPayableDetailsController");

    var component 				                = angular.module("mPortal.components.widget.building-optional-endorsements.loss-payable-details", []);
    component.directive( "lossPayableDetails", lossPayableDetails );
    component.controller( "LossPayableDetailsController", LossPayableDetailsController );

    return component;

});
