define( 'components/specialtyApplication/submit/review/directive/review',['require'],function( require ) {
	"use strict";

	function Review() {
		return {
			restrict: "AE",
			scope: {},
			templateUrl: "app/components/specialtyApplication/submit/review/template/template.html",
			controller: "ReviewController"
		};
	}

	return Review;

});
