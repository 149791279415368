define('components/filterableDocumentList/app',['require','components/filterableDocumentList/directive/filterableDocumentList','components/filterableDocumentList/controller/FilterableDocumentListController'],function(require) {
	"use strict";

	var filterableDocumentList = require("components/filterableDocumentList/directive/filterableDocumentList"),
		FilterableDocumentListController = require("components/filterableDocumentList/controller/FilterableDocumentListController");

	var component = angular.module("mPortal.components.filterableDocumentList", []);

	component.directive("filterableDocumentList", filterableDocumentList);
	component.controller("FilterableDocumentListController", FilterableDocumentListController);

	return component;

});
