define( 'components/applicationData/applicationSection/directive/applicationSection',['require'],function( require ) {
    "use strict";

    function ApplicationSection() {
        return {
            restrict: "AE",
            scope: {
                section: "=",
                depth: "=",
                previousEnumeration: "=",
                program: "="
            },
            templateUrl: "app/components/applicationData/applicationSection/template/template.html",
            controller: "ApplicationSectionController"
        };
    }

    return ApplicationSection;

});
