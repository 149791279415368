define('components/specialtyApplication/account/controller/ClearanceController',[],function() {

	ClearanceController.$inject = [ "$scope", "$location", "SubmissionStore", "AccountService", "UserProfile" ];

	function ClearanceController( $scope, $location, SubmissionStore, AccountService, UserProfile  ) {

		// if the user refreshes the browser on this step we will only have 1 visited section. In this case we
		// are just going to forward them back to the eligibility step. 
		if( SubmissionStore.visitedSections.length == 1 ) {
			SubmissionStore.visitedSections = [];
			if( UserProfile && UserProfile.isLoggedIn ) {
				$location.path( "/application/agent" );
			} else {
				$location.path( "/application" );
			}
			return;
		}
		$scope.backBtnDisabled = false;
		$scope.continueBtnDisabled = true;
		$scope.status = { loading: false };
		$scope.clearanceSearchResults = SubmissionStore.clearanceSearchResults;
		$scope.searchName = SubmissionStore.insured.name;
		$scope.form = { selectedInsuredIndex: -1 };

		/**
		 * When a user selects a search result we need to enable the continue button
		 */
		$scope.enableContinueBtn = function() {
			$scope.continueBtnDisabled = false;
		}

		/**
		 *  When a user is presented with multiple results they have the option to select an insured and continue.
		 *  If they do this the continue button submits the form and we get the maId of the selected search result
		 */
		$scope.submitForm = function() {
			// this was the delivery preference the user selected in the previous step
			var deliveryPreference = SubmissionStore.insured.deliveryPreference;
			$scope.backBtnDisabled=true;
			$scope.continueBtnDisabled = true;
			// the index of the selected insured is passed in the form
			var insured = $scope.clearanceSearchResults[ $scope.form.selectedInsuredIndex ];
				insured.deliveryPreference = deliveryPreference;
				angular.extend( SubmissionStore.insured, insured );

			// if the source system is ICON create a new insured record
			if( insured.system == "ICON" ) {
				saveInsured(insured);
			}
			else{
				createMasterQuote();
			}
			clearSearchResults();
		}

		/**
		 * A user was presented with search results and decided that none of them were a match. At this point we are going
		 * to create a new insured. 
		 */
		$scope.createNew = function() {
			$scope.status.loading = true;
			$scope.continueBtnDisabled = true;
			$scope.backBtnDisabled=true;
			var insured = SubmissionStore.insured;
			insured.city = insured.city;
			insured.state=insured.state;
			saveInsured(insured);
		}

		$scope.goBack = function() {
			$scope.status.loading = true;
			$location.path("application/account");
		}

		function saveInsured( insured ) {
			var programCode = SubmissionStore.programCode,
				agencyId    = SubmissionStore.agencyId;

			AccountService.saveInsured(
				insured,
				programCode,
				agencyId,
				getMaxScore()
			)
			.then(function( result ) {
				var data = result.data;
				angular.extend( SubmissionStore.insured, data );
				createMasterQuote();
			});

		}

		function getMaxScore() { 
			var searchResults = SubmissionStore.clearanceSearchResults;
			return (searchResults != null && searchResults.length > 0) ? Math.max.apply(Math,searchResults.map(function(o){return o.score;})) : 0;
		}

		function clearSearchResults(){
			SubmissionStore.clearanceSearchResults = null;
		}

		function createMasterQuote() {
			clearSearchResults();
			$scope.status.loading = true;

			AccountService.createMasterQuote(
				SubmissionStore.insured.maid,
				SubmissionStore.agencyId,
				SubmissionStore.agentId,
				"",
				SubmissionStore.state.abbreviation,
				SubmissionStore.programCode,
				SubmissionStore.effectiveDate,
				SubmissionStore.marketedProgramId,
				SubmissionStore.insured.deliveryPreference,
				SubmissionStore.cobrandingId
			)
			.then(function( result ) {
				var data = result.data;

				angular.extend( SubmissionStore, {
					applicationId: data.applicationId,
				    masterQuoteID: data.masterQuoteID,
				    instance: data.instance,
					programName: data.programName
				});

				$location.path("/application/questions");	
			});
			
		}

	}

	return ClearanceController;

});
