define( 'common/service/specialtyApplication/SpecialtyDocumentService',['require'],function( require ) {
	"use strict";

	SpecialtyDocumentService.$inject = [ "$http", "$window", "$q", "SubmissionStore", "archApiUrl" ];

	function SpecialtyDocumentService( $http, $window, $q, SubmissionStore, archApiUrl ) {

		var service = {};

		service.getDocuments = function( applicationId, exclusions ) {
			return $http.get( archApiUrl + "application/" + applicationId + "/document" ).then( function( result ) {
				if( exclusions ) {
					var outcome = { error: false, documents: [] };
					angular.forEach( result.data, function( documentObject ) {
						if( exclusions.indexOf( documentObject.name ) === -1 ) {
							outcome.documents.push( documentObject )
						}
					});
					return outcome;
				} else {
					return { error: false, documents: result.data }
				}
			}).catch( function( err ) {
				return { error: true, documents: [] }
			});
		};

		service.getApplicationDocument = function() {
			var retrievalURL = 'application/' + SubmissionStore.applicationId + '/document/' + SubmissionStore.programName.replace(/\s/g,'') + 'Application';
			this.getDocument( 'Application', retrievalURL );
		};

		service.loadInitialContent = function( windowObj, name ) {
			var loadingHTML = '<h3>Thank you for your patience as we retrieve your document. This may take up to 60 seconds.</h3>';
			$( windowObj.document.body ).html( loadingHTML );
		};

		service.getDocument = function( name, retrievalURL ) {
			var docId = retrievalURL.split('/')[1] + retrievalURL.split('/')[3];

			/*
			 The documentWindows collection keeps track of which documents (uniquely identified by applicationId and
			 document name) have been opened in a window object. It prevents an attempt to open a new window for a
			 document already opened in a window via this function, which will throw a JavaScript error
			 */
			if( SubmissionStore.documentWindows[ docId ] != undefined && SubmissionStore.documentWindows[ docId ].closed ) {
				delete SubmissionStore.documentWindows[ docId ];
			}

			if( SubmissionStore.documentWindows[ docId ] == undefined ) {
				SubmissionStore.documentWindows[ docId ] = $window.open( '', docId, 'location=0,width=900,height=500,resizable=1' );
				this.loadInitialContent( SubmissionStore.documentWindows[ docId ], name );
				SubmissionStore.documentWindows[ docId ] = $window.open( archApiUrl + retrievalURL , docId, 'location=0,width=900,height=500,resizable=1' );
			}
		};

		service.getPolicyDocuments = function( accountId, policyId ) {
			return $http.get( archApiUrl + "account/" + accountId + "/policy/" + policyId + "/document" ).then( function( result ) {
				angular.forEach( result.data, function( doc ) {
					doc.accountId = accountId;
					doc.policyId = policyId;
				});
				return { error: false, docs: result.data };
			}).catch( function( err ) {
				return { error: true, docs: [] }
			});
		};

		service.getPolicyDocument = function( document ) {
			var storageId = document.policySystem + document.id;
			var name = document.type + " Document " + document.id;
			var retrievalUrl = archApiUrl + "account/" + document.accountId + "/policy/" + document.policyId + "/dms/" + document.dms + "/document/" + document.id;

			var queryString = jQuery.param(document.parameters);
			if( queryString ){
				retrievalUrl += "?" + queryString
			}


			if( SubmissionStore.documentWindows[ storageId ] != undefined && SubmissionStore.documentWindows[ storageId ].closed ) {
				delete SubmissionStore.documentWindows[ storageId ];
			}

			if( SubmissionStore.documentWindows[ storageId ] == undefined ) {
				SubmissionStore.documentWindows[ storageId ] = $window.open( '', storageId, 'location=0,width=900,height=500,resizable=1' );
				this.loadInitialContent( SubmissionStore.documentWindows[ storageId ], name );
				SubmissionStore.documentWindows[ storageId ] = $window.open( retrievalUrl , storageId, 'location=0,width=900,height=500,resizable=1' );
			}
		}

		service.getSubmissionDocuments = function( accountId, submissionId ) {
			return $http.get( archApiUrl + "account/" + accountId + "/submission/" + submissionId + "/document" ).then( function( result ) {
				angular.forEach( result.data, function( doc ) {
					doc.accountId = accountId;
					doc.submissionId = submissionId;
				});
				return { error: false, docs: result.data };
			}).catch( function( err ) {
				return { error: true, docs: [] }
			});
		};

		service.getSubmissionDocument = function( document ) {
			var storageId = document.policySystem + document.id;
			var name = document.type + " Document " + document.id;
			var retrievalUrl = archApiUrl + "account/" + document.accountId + "/submission/" + document.submissionId + "/dms/" + document.dms + "/document/" + document.id;

			var queryString = jQuery.param(document.parameters);
			if( queryString ){
				retrievalUrl += "?" + queryString
			}


			if( SubmissionStore.documentWindows[ storageId ] != undefined && SubmissionStore.documentWindows[ storageId ].closed ) {
				delete SubmissionStore.documentWindows[ storageId ];
			}

			if( SubmissionStore.documentWindows[ storageId ] == undefined ) {
				SubmissionStore.documentWindows[ storageId ] = $window.open( '', storageId, 'location=0,width=900,height=500,resizable=1' );
				this.loadInitialContent( SubmissionStore.documentWindows[ storageId ], name );
				SubmissionStore.documentWindows[ storageId ] = $window.open( retrievalUrl , storageId, 'location=0,width=900,height=500,resizable=1' );
			}
		}

		service.listDocuments = function ( submissionId, documentType ){
			return $http.get( archApiUrl + "application/submission/" + submissionId +"/list-documents/" + documentType ).then( function( result ) {
				angular.forEach( result.data, function( doc ) {
					doc.submissionId = submissionId;
				});
				return { error: false, docs: result.data };
			}).catch( function( err ) {
				return { error: true, docs: [] }
			});
		};

		service.viewDocument = function ( document ){
			var storageId = document.policySystem + document.id;
			var name = document.type + " Document " + document.id;
			var retrievalUrl = archApiUrl + "application/" + SubmissionStore.applicationId + "/submission/"+ document.submissionId + "/dms/" + document.dms + "/document/" + document.id;

			var queryString = jQuery.param(document.parameters);
			if( queryString ){
				retrievalUrl += "?" + queryString
			}


			if( SubmissionStore.documentWindows[ storageId ] != undefined && SubmissionStore.documentWindows[ storageId ].closed ) {
				delete SubmissionStore.documentWindows[ storageId ];
			}

			if( SubmissionStore.documentWindows[ storageId ] == undefined ) {
				SubmissionStore.documentWindows[ storageId ] = $window.open( '', storageId, 'location=0,width=900,height=500,resizable=1' );
				this.loadInitialContent( SubmissionStore.documentWindows[ storageId ], name );
				SubmissionStore.documentWindows[ storageId ] = $window.open( retrievalUrl , storageId, 'location=0,width=900,height=500,resizable=1' );
			}
		};

		service.uploadDocumentByDragAndDrop = function ( fileName,fileType, base64, submissionId, documentType, effectiveDate ){
			var form_data = new FormData();
			form_data.append('base64',base64);

			return $http({
				method: "POST",
				url: archApiUrl + "application/upload-document-base64",
				headers: {
					"Content-Type": undefined
				},
				data: form_data,
				params: {
					fileName : fileName,
					fileType : fileType,
					submissionId : submissionId,
					documentType : documentType,
					effectiveDate : effectiveDate
				},
				transformRequest : angular.identity
			});
		};

		return service;
	}

	return SpecialtyDocumentService;
});
