define('components/specialtyApplication/account/directive/clearance',['require'],function(require) {
	"use strict";

	Clearance.$inject = [];

	function Clearance() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/specialtyApplication/account/template/clearance.html",
			controller: "SpecialtyApplicationClearanceController"
		};
	}

	return Clearance;

});
