define( 'common/service/analytics/GoogleAdWordsService',['require'],function ( require ) {
	"use strict";

	GoogleAdWordsService.$inject = [];

	function GoogleAdWordsService() {
		var service = {};

		service.trackSubmittedApplicationConversion = function( applicationId ) {
			if( typeof window.google_trackConversion === 'function' ){
				try {
					window.google_trackConversion( {
						google_conversion_id: 875889025,
						google_conversion_label: "y8C6CKOp1WkQgYPUoQM",
						google_conversion_value: 0,
						google_remarketing_only: false,
						google_custom_params: {
							applicationId: applicationId
						}
					} );
				} catch ( e ) {
					// Wrapping this in a try / catch since it is third party code.
				}
			}
		};

		return service;
	}

	return GoogleAdWordsService;
} );
