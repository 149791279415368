define('components/message/container/directive/messageContainer',['require'],function(require) {
	"use strict";

	MessageContainer.$inject = [];

	function MessageContainer() {
		return {
			restrict: "AE",
			scope: {
				account			: "=",
				accounts		: "="
			},
			templateUrl: "app/components/message/container/template/template.html",
			controller: "messageContainerController"
		};
	}

	return MessageContainer;

});
