define('components/accountSummary/quoteSummary/app',['require','common/directive/app','components/paymentLink/app','components/lossRuns/app','components/editSubmission/app','components/accountSummary/quoteSummary/directive/quoteSummary','components/accountSummary/quoteSummary/controller/QuoteSummaryController','common/service/LossRunsService','moment','common/service/specialtyApplication/SubmissionStore','common/service/specialtyApplication/ResultService','common/filter/formatMoney'],function(require) {
    "use strict";

    //For product label pill
    require("common/directive/app");

    require("components/paymentLink/app");
    require("components/lossRuns/app");
    require("components/editSubmission/app");

    var quoteSummary = require("components/accountSummary/quoteSummary/directive/quoteSummary"),
        QuoteSummaryController = require("components/accountSummary/quoteSummary/controller/QuoteSummaryController"),
        LossRunsService = require("common/service/LossRunsService" ),
        moment = require("moment" ),
        SubmissionStore = require("common/service/specialtyApplication/SubmissionStore"),
        ResultService = require("common/service/specialtyApplication/ResultService" ),
        formatMoney = require("common/filter/formatMoney");

    var component = angular.module("mPortal.accountSummary.quoteSummary", [
        "mPortal.paymentLink",
        "mPortal.editSubmission"
    ]);

    component.directive("quoteSummary", quoteSummary);
    component.controller("QuoteSummaryController", QuoteSummaryController);
    component.factory("LossRunsService", LossRunsService);
    component.factory("SubmissionStore", SubmissionStore);
    component.factory("ResultService", ResultService);
    component.filter("formatMoney", formatMoney);
    component.value("moment", moment);

    return component;

});
