define('components/userAccountSettings/personalDetails/app',['require','common/directive/app','components/userAccountSettings/personalDetails/directive/personalDetails','components/userAccountSettings/personalDetails/controller/PersonalDetailsController','common/service/MessageContainer','common/service/user/UserService'],function(require) {
	"use strict";

	require("common/directive/app");

	var personalDetails = require("components/userAccountSettings/personalDetails/directive/personalDetails"),
		PersonalDetailsController = require("components/userAccountSettings/personalDetails/controller/PersonalDetailsController"),
		MessageContainer = require("common/service/MessageContainer"),
		UserService = require("common/service/user/UserService");

	var component = angular.module("mPortal.userAccountSettings.personalDetails", [
		"mPortal.directive"
	]);

	component.directive("personalDetails", personalDetails);
	component.controller("PersonalDetailsController", PersonalDetailsController);
	component.factory("MessageContainer", MessageContainer);
	component.factory("UserService", UserService);

	return component;

});
