define( 'components/documentDeliveryPreferences/controller/DocumentDeliveryPreferencesController',['require'],function( require ) {
   "use strict";

    DocumentDeliveryPreferencesController.$inject = [ "$scope",  "$modal" ];

    function DocumentDeliveryPreferencesController( $scope, $modal ) {

        $scope.isInsuredUser = $scope.docDeliveryConfig ? $scope.docDeliveryConfig[ 'agency' ].hidden : true;
        // Whether or not the user is direct insured only matters in this custom UI logic post-issuance;
        $scope.isDirectInsured = $scope.isIssued && $scope.insuredType == "Direct";
        $scope.introStatement = $scope.isIssued ? "View and/or update your delivery preferences." : "";
        $scope.emailColumnTitle = $scope.isInsuredUser ? "Email address" : "Insured email address";
        // Pre-issuance, an insured email address can be added if we don't already have one, so we present a text input with placeholder text.
        $scope.emailPlaceholderText = $scope.isInsuredUser ? "Enter your email address": "Enter insured email address";
        // Post-issuance, the insured email address cannot be changed here, and we provide instructions instead
        $scope.insuredEmailInstructions = !$scope.isInsuredUser
            ? "Please use message board to provide an email address."
            : ( $scope.isDirectInsured ? "Please use request policy change action to provide an email address." : "Please contact your agent to provide an email address.");

        $scope.billingEmailPlaceholderText = $scope.isInsuredUser ? "Enter your billing email address": "Enter insured billing email address";
        $scope.priorityDocumentTip = "At this time paper is the only option available for delivery.";
        $scope.billingDocumentTip = $scope.isInsuredUser
            ? "Electronic billing documents can be sent to a separate email address"
            : "Please contact agency services at agencyappointment@markel.com to request to update the agency bill delivery preference.";

        // Configure the agencyPolicyEmailRecipient data
        $scope.startingAgencyPolicyEmailRecipient =  $scope.docDeliveryConfig.agencyPolicyEmailRecipient;
        $scope.agencyPolicyEmailRecipientOptions = [
            { label: $scope.docDeliveryConfig.servicingAgent, value: "Servicing Agent" }
        ];

        if( $scope.docDeliveryConfig.preferredDeliveryEmail ) {
            $scope.agencyPolicyEmailRecipientOptions.push( { label: ( "Agency - " + $scope.docDeliveryConfig.preferredDeliveryEmail ), value: "Agency" } );
        } else {
            // The value can only be Servicing Agent
            $scope.docDeliveryConfig.agencyPolicyEmailRecipient = "Servicing Agent";
            $scope.startingAgencyPolicyEmailRecipient = "Servicing Agent"
        }

        $scope.showAgencyPolicyEmailRecipient = function() {
            var answer = !$scope.isInsuredUser
                    &&( $scope.docDeliveryConfig[ 'agency' ][ 'policy' ].value == 'BT' || $scope.docDeliveryConfig[ 'agency' ][ 'policy' ].value == 'EM');
            if( !answer ) {
                // Reset value to original value so it is essentially unchanged
                $scope.docDeliveryConfig.agencyPolicyEmailRecipient = $scope.startingAgencyPolicyEmailRecipient;
            }
            return answer;
        };

        $scope.showAgencyEmailTooltip = function() {
            return $scope.agencyPolicyEmailRecipientOptions.length > 1
        };

        $scope.showInsuredEmailColumn = function() {
            return $scope.docDeliveryConfig[ "insured" ][ "policy" ].value == "EM"
                || $scope.docDeliveryConfig[ "insured" ][ "priority" ].value == "EM"
                || ( $scope.docDeliveryConfig[ "insured" ][ "billing" ].value == "EM" && $scope.docDeliveryConfig[ "agency" ][ "billing" ].hidden );
        };

        $scope.showInsuredEmail = function() {
            return $scope.docDeliveryConfig[ "insured" ][ "policy" ].value == "EM";
        };

        $scope.showInsuredEmailInstructions = function() {
            return $scope.docDeliveryConfig[ "insured" ][ "policy" ].value == "EM"
                && $scope.docDeliveryConfig[ "insured" ][ "policy" ].oldEmail == ""
                && $scope.isIssued;
        };

        $scope.showBillingToolTip = function() {
            return !$scope.isInsuredUser && !$scope.docDeliveryConfig[ "agency" ][ "billing" ].hidden;
        };

        $scope.openElectronicDeliveryTermsModal = function() {
            $modal.open({
                templateUrl: "app/components/specialtyApplication/quoteResult/template/ElectronicDeliveryTermsModal.html",
                controller: "ElectronicDeliveryTermsModalController",
                resolve: {
                    buttonText: function(){ return $scope.buttonText; }
                }
            });
        };
    }

    return DocumentDeliveryPreferencesController;
});
