define( 'components/editSubmission/controller/EditSubmissionController',['require'],function( require ) {
   "use strict";

    EditSubmissionController.$inject = [ "$scope", "$location", "$modal", "AccountService", "UserProfile" ];

    function EditSubmissionController( $scope, $location, $modal, AccountService, UserProfile ) {

        $scope.checkingLock = false;
        $scope.openLockModal = function() {
            $modal.open({
                templateUrl: "app/components/editSubmission/template/modalBlocked.html",
                controller: "GenericModalController"
            });
        };

	    if( angular.isUndefined( $scope.linkText ) ){
		    $scope.linkText = "Edit Submission";
	    }

        $scope.editSubmission = function() 
        {
            if( $scope.luxonId && $scope.luxonPartitionKey )
            {
                $scope.luxon = {
                    luxon_id : $scope.luxonId.length ? ( $scope.luxonId !== "null" ? $scope.luxonId : null ) : null,
                    luxon_partition_key : $scope.luxonPartitionKey.length ? ( $scope.luxonPartitionKey !== "null" ? $scope.luxonPartitionKey : null ) : null
                };
            }
            else
            {
                //default to nulls 
                $scope.luxon = { luxon_id : null , luxon_partition_key : null };
            }

            if( $scope.status == "Closed" ) {
                $scope.openLockModal();
            } else if( $scope.luxon.luxon_id !== null && $scope.luxon.luxon_partition_key !== null ){
                $location.path( "/lx/submission/" + $scope.luxon.luxon_partition_key + "/" + $scope.luxon.luxon_id )
            } else {
                $scope.checkingLock = true;
                AccountService.submissionLockableByUser( $scope.applicationId ).then( function( result ) {
                    $scope.checkingLock = false;
                    if( !result.canUserLock ) {
                        $scope.openLockModal();
                    } else if( $scope.status.length >= 5 && $scope.status.slice(0,5) == "Quote" ) {
                        $modal.open({
                            templateUrl: "app/components/editSubmission/template/modalWarning.html",
                            controller: "EditWarningModalController",
                            resolve: {
                                applicationId: function(){ return $scope.applicationId; },
                                userProfile: function(){ return UserProfile },
                                status : function (){return $scope.status}
                            }
                        });

                    } else {
                        UserProfile.set( "editedApplication", $scope.applicationId );
                        $location.path( "/application/agent/edit/" + $scope.applicationId );

                    }
                });
            }

        }
    }

    return EditSubmissionController;
});
