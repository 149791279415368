define('components/message/modal/app',['require','angular','angular-bootstrap','components/message/modal/controller/MessageModalController','common/controller/GenericModalController','components/message/modal/directive/uploadAttachment'],function(require) {
	"use strict";

	require("angular");
	require("angular-bootstrap");

	var app = angular.module("mPortal.components.message.modal", [
		"ui.bootstrap"
	]);

	var MessageModalController = require("components/message/modal/controller/MessageModalController"),
		GenericModalController = require("common/controller/GenericModalController" ),
		uploadAttachment = require("components/message/modal/directive/uploadAttachment");

	app.controller("MessageModalController", MessageModalController);
	app.controller("GenericModalController", GenericModalController);
	app.directive("uploadAttachment", uploadAttachment);

	return app;

});


