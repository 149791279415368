define('components/userAccountSettings/ciamAccountSettings/controller/CiamAccountSettingsController',['require'],function(require) {
	"use strict";

	CiamAccountSettingsController.$inject = [ "$scope", "MessageContainer", "UserService", "ciamDomain" ];

	function CiamAccountSettingsController( $scope, MessageContainer, UserService, ciamDomain ) {

		$scope.messages = new MessageContainer({
			success: false,
			errors: [],
			errorHeader: ""
		});
		
		$scope.domain = ciamDomain;
	}

	return CiamAccountSettingsController;

});
