define('components/widget/policy-information/building-table/controller/buildingTableController',['require'],function(require) {
    "use strict";

    buildingTableController.$inject = ["$scope", "$filter", "$location","$q", "moment", "$sce", "UserProfile","SubmissionDataService"];

    function buildingTableController( $scope, $filter, $location , $q ,moment ,$sce , UserProfile, SubmissionDataService ) {

        $scope.init_data = function()
        {
            if($scope.fields)
            {
                $scope.field_group       = $scope.fields[0].fieldGroup;
                $scope.building_fields   = {};
                $scope.datapointStyles   = {};

                for(var i=0 ; i < $scope.field_group.length; i++)
                {
                    var fld = $scope.field_group[i];

                    $scope.datapointStyles[fld.data.reference] = fld.templateOptions.style.className;

                    $scope.building_fields[fld.data.reference] = fld;
                }
                if($scope.fields.length > 0){
                    for(var j=1 ; j < $scope.fields.length; j++){
                        if($scope.fields[j]){
                            var fid = $scope.fields[j];
                            $scope.building_fields[fid.data.reference] = $scope.fields[j];
                        }
                    }
                }
            }
        }
        
        $scope.set_option = function(e,pack){           
            pack.selected = true;

            $scope.model[$scope.key] = pack.value;
            

            for(var i=0 ; i < $scope.packages.length; i++)
            {
                var pack = $scope.packages[i];

                if($scope.model[$scope.key]  == pack.value )
                {
                    pack.selected = true;
                }
                else
                {
                    pack.selected = false;
                }
            }

            //fire off data change event so we can save the change
            $scope.change($scope.model[$scope.key],$scope.options,$scope,e);
            
            return false;
        }
       
        $scope.init_data();
        $scope.$watch('fields',$scope.init_data);
    }

    return buildingTableController;
});
