define('components/accountSummary/insuredAccountActivity/app',['require','components/accountSummary/insuredAccountActivity/directive/insuredAccountActivity'],function(require) {
	"use strict";

	var insuredAccountActivity = require("components/accountSummary/insuredAccountActivity/directive/insuredAccountActivity")

	var component = angular.module("mPortal.accountSummary.insuredAccountActivity", []);

	component.directive("insuredAccountActivity", insuredAccountActivity);

	return component;

});
