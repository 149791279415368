define( 'components/viewApplication/directive/viewApplication',['require'],function ( require ) {
	"use strict";

	function viewApplication() {
		return {
			restrict: "AE",
			scope: {
				applicationId: "@",
				linkText: "@",
				program: "@"
			},
			templateUrl: "app/components/viewApplication/template/template.html",
			controller: "ViewApplicationController"
		}
	}

	return viewApplication;
} );
