define('components/message/list/directive/messageList',['require'],function(require) {
	"use strict";

	MessageList.$inject = [];

	function MessageList() {
		return {
			restrict: "AE",
			scope: {
				threads:"=",
				account:"=",
				query:"="
			},
			templateUrl: "app/components/message/list/template/template.html",
			controller: "messageListController"
		};
	}

	return MessageList;

});
