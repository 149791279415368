define('components/widget/inland-marine/blanket-item-limit/app',['require','components/widget/inland-marine/blanket-item-limit/directive/blanketItemLimit','components/widget/inland-marine/blanket-item-limit/controller/BlanketItemLimitController'],function(require) {
    "use strict";

    var blanketItemLimit 		    = require("components/widget/inland-marine/blanket-item-limit/directive/blanketItemLimit"),
        BlanketItemLimitController 	= require("components/widget/inland-marine/blanket-item-limit/controller/BlanketItemLimitController");

    var component 					= angular.module("mPortal.components.widget.inland-marine.blanket-item-limit", []);

    component.directive("blanketItemLimit", blanketItemLimit);
    component.controller("BlanketItemLimitController", BlanketItemLimitController);

    return component;

});
