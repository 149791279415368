define('components/specialtyApplication/questionShellSections/agentDateSelections/directive/licenseInfo',['require'],function(require) {
	"use strict";

	licenseInfo.$inject = [];

	function licenseInfo() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/specialtyApplication/questionShellSections/agentDateSelections/template/licensedInfoModal.html",
			controller: "LicenseInfoModalController"
		};
	}

	return licenseInfo;

});
