define( 'components/policyDetail/policyBilling/filter/UnPaidDueFilter',['require'],function ( require ) {
	"use strict";

	UnPaidDueFilter.$inject = [];

	function UnPaidDueFilter( moment ) {
		return function ( input, showUnPaidDue ) {
			var output = [];
			if ( showUnPaidDue ) {
				angular.forEach( input, function ( invoice ) {
					if ( invoice.unpaid !== 0 ) {
						output.push( invoice );
					}
				} );
				return output;
			}
			return input;
		}
	}

	return UnPaidDueFilter;
} );
