define( 'common/service/analytics/TeaLeafService',['require'],function ( require ) {
	"use strict";

	TeaLeafService.$inject = [];

	function TeaLeafService() {
		var service = {};

		/**
		 * TeaLeaf can only handle a map/Object with string properties.  This function takes an object and filters out
		 * any property that is not a string.
		 * @param data
		 * @returns a copy of the data Object with all properties that are not strings, numbers or booleans removed.
		 */
		function sanitizeEventData( data ) {
			var result = {};
			for ( var propertyName in data ) {
				if ( data.hasOwnProperty( propertyName ) ) {
					switch ( typeof data[ propertyName ] ) {
						case 'boolean':
						case 'number':
							result[ propertyName] = data[ propertyName ].toString();
							break;
						case 'string':
							result[ propertyName ] = data[ propertyName ];
							break;
					}
				}
			}
			return result;
		}

		service.sendCustomEvent = function ( eventType, eventData ) {
			try {
				if( typeof( TLT ) !== "undefined" && TLT.isInitialized() ){
					TLT.logCustomEvent( eventType, sanitizeEventData( eventData ) );
				}
			} catch ( e ) {
				// TeaLeaf should just add this event to its queue and send it later, so we do not expect this to ever
				// throw an exception, but still safer to wrap this in a try / catch since it is third party code.
			}
		};

		return service;
	}

	return TeaLeafService;
} );
