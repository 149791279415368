define('components/productSearch/list/controller/productSearchListController',['require'],function(require) {
	"use strict";

	productSearchListController.$inject = ["$scope", "$filter", "$location", "UserProfile", "SubmissionStore"];

	function productSearchListController( $scope, $filter, $location, UserProfile, SubmissionStore ) {

		$scope.user = UserProfile;
		$scope.isUnAuthenticatedDirect = SubmissionStore.isUnAuthenticatedDirect;
		$scope.singleProducts  = [];
		$scope.groupedProducts = [];

		$scope.group_products = function() {
			$scope.iterator        = [];
			$scope.singleProducts  = [];
			$scope.groupedProducts = [];
			$scope.iterator = $scope.products;
			if(!$scope.user.isLoggedIn) {
				var lookUp = $scope.iterator.reduce( (a,e) => {
					a[e.program.programCode] = ++a[e.program.programCode] || 0;
					return a;
				}, {})
				$scope.groupedProducts = $scope.iterator.filter( e => lookUp[e.program.programCode] )
				angular.forEach( $scope.iterator, function ( product ) {
					if ( !$scope.groupedProducts.includes( product ) ) {
						$scope.singleProducts.push( product );
					}
				} )
			}
		}

		if(!$scope.user.isLoggedIn && !$scope.isUnAuthenticatedDirect) {
			$scope.group_products();
		}

    	$scope.select_product = function(e,product) {
            $scope.$emit('select-product',{product:product,e:e});
   
            e.stopPropagation();
            return false;
        }

		$scope.open_product_page = function(e,product) {
			window.open( product.program.msiApplicationUrl ? product.program.msiApplicationUrl : product.url , '_blank' );
			e.stopPropagation();
			return false;
		}


    	$scope.show_eligibility = function(e,product){
    	
    		product.show_eligibility = !product.show_eligibility;
            e.stopPropagation();
    		
    		return false;
    	};


        $scope.visible_messages = function(product){
            var has_visible_messages = false;

            angular.forEach(product.messages,function(message){
                if(!has_visible_messages && message.visible && !message.closed)
                {
                    has_visible_messages = true;
                }
            });
        
            return has_visible_messages;
        };


        $scope.hide_message = function(e,message,product){
            
            message.closed = true;

            e.stopPropagation();
        }

        
        $scope.open_link = function(e){
            e.stopPropagation();
        };

		$scope.$watch('products',$scope.group_products,true);
	}

	return productSearchListController;
});
