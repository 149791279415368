define('components/activityFeed/filter/ActivityFeedFilter',['require'],function(require) {
	"use strict";

	ActivityFeedFilter.$inject = [];

	function ActivityFeedFilter() {
		return function( items, activityConfig ) {

			var filteredItems = [];
			var keyword = activityConfig.filters.keyword ? activityConfig.filters.keyword.toLowerCase() : null;

			if( activityConfig.showAll() ) {

				filteredItems = items.slice( 0, activityConfig.maxRows );

			} else {
				angular.forEach( items, function( item, index ) {
					var itemContainsKeyword = keyword && (
						(item.title && item.title.toLowerCase().includes(keyword)) ||
						(item.accountName && item.accountName.toLowerCase().includes(keyword)) ||
						(item.policyNumber && item.policyNumber.toLowerCase().includes(keyword)) ||
						(item.type && item.type.toLowerCase().includes(keyword))
					);
				
					switch( item.type ) {

						case "Document Release":
							if( activityConfig.filters.documents && ( !keyword || itemContainsKeyword ) ) {
								filteredItems.push( item );
							}
						break;

						case "Account Status Changed":
						case "Quote Status Changed":
						case "Policy Status Changed":
						case "Claim Status Change":
						case "Endorsement Status Change":
						case "Status Change":
							if( activityConfig.filters.statusChange && ( !keyword || itemContainsKeyword )) {
								filteredItems.push( item );
							}
						break;

						case "New Endorsement": // TODO: we don't have a filter for this? do we want one?
							filteredItems.push( item );
						break;

						case "New Message":
							if( activityConfig.filters.messages && ( !keyword || itemContainsKeyword )) {
								filteredItems.push( item );
							}
						break;

						case "Claim Opened":
							if( activityConfig.filters.claims && ( !keyword || itemContainsKeyword )) {
								filteredItems.push( item );
							}
						break;

						case "Notifications":
							if( activityConfig.filters.notifications && ( !keyword || itemContainsKeyword ) ) {
								filteredItems.push( item );
							}
						break;

					}

				});

			}

			return filteredItems;

		};
	}

	return ActivityFeedFilter;

});
