define('components/specialtyApplication/questions/config/widgets/AutoWidget',['require'],function(require) {

    "use strict";

    var widget = {};

    widget.getStructure = function() {

        return {
            "_datapointPlacement": "strictOrder",
            "Auto Enh Form" : { wrapper:  [ "childCareAuto" ] , wrapperOverride: true},
            "SC Auto Enh Form" : { wrapper:  [ "schoolsAuto" ] , wrapperOverride: true},
            "GP_AutoEnhancement" : { wrapper:  [ "genPkgAuto" ] , wrapperOverride: true},
            "Farm_Auto Enh Form" : { wrapper:  [ "farmAuto" ] , wrapperOverride: true}

        };
    };

    widget.configureFormly = function ( formlyConfig, formlyApiCheck ) {

        formlyConfig.setWrapper({
            name: "childCareAuto",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Child Care Auto Endorsement.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "schoolsAuto",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Educational Services Auto Standard  Plus Enhancement v4 3.25.19.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "genPkgAuto",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/GP Auto Endorsement.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });


        formlyConfig.setWrapper({
            name: "farmAuto",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Farm Auto Endorsement.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });
    };

    return widget;

});
