define( 'components/quickLinks/controller/BillingDocumentsController',['require'],function ( require ) {
	"use strict";

	BillingDocumentsController.$inject = ["$scope", "$modalInstance", "UserProfile", "CommissionsService", "archApiUrl"];

	function BillingDocumentsController( $scope, $modalInstance, UserProfile, CommissionsService, archApiUrl ) {
		$scope.close = $modalInstance.close;
		$scope.agencies = UserProfile.agencies;
		$scope.agencyBillingDocuments = [];
		$scope.documentCount = -1;
		$scope.showDocuments=['Agency Bill Past Due Statement BC','Agency Bill Statement BC', 'Direct Bill Commission Statement BC'];
		$scope.pageSize = 10;
		if ( UserProfile.agencyBillingLocationId === 0 ) {
			$scope.billingAgencyId = UserProfile.agencyId;
			angular.forEach( $scope.agencies, function ( agency ) {
				if ( agency.id === UserProfile.agencyId ) {
					$scope.agency = agency.name;
				}
			} );
		}
		else {
			$scope.billingAgencyId = UserProfile.agencyBillingLocationId;
			$scope.agency = UserProfile.agencyBillingLocationName;
		}

		CommissionsService.listAgencyBillingDocuments( $scope.billingAgencyId ).then( function ( result ) {
			var billingDocuments = result;
			angular.forEach( billingDocuments, function ( billingDocument ) {
				if ( $scope.showDocuments.indexOf( billingDocument.documentType ) !== -1 )
				{
					var imageSource = "assets/images/Document_Icon.png";
					if ( billingDocument.extension !== null && billingDocument.extension.toUpperCase() === "PDF" ) {
						imageSource = "assets/images/pdf-small.gif";
					}
					var document = {
						'Agencyname': $scope.agency,
						'Statement_Date': new Date( billingDocument.date ),
						'imageSource': imageSource,
						'document_link': archApiUrl + "application/dms/document/" + billingDocument.id
					};
					$scope.agencyBillingDocuments.push( document );
				}
			} );
		$scope.documentCount = $scope.agencyBillingDocuments.length;
		} );
	}

	return BillingDocumentsController;

} );
