define('components/newsHeadlines/controller/NewsItemController',['require'],function(require) {
	"use strict";

	NewsItemController.$inject = ["$scope", "$modalInstance", "$sce", "newsItem"];

	function NewsItemController( $scope, $modalInstance, $sce, newsItem ) {
		
		$scope.newsItem = newsItem;
		$scope.newsItem.trustedMessage = $sce.trustAsHtml( $scope.newsItem.message );

		$scope.close = $modalInstance.close;

	}

	return NewsItemController;

});
