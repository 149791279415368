define('components/specialtyApplication/questionShellSections/agentDateSelections/controller/LicenseInfoModalController',['require'],function(require) {
	"use strict";

	LicenseInfoModalController.$inject = [ "$scope", "$modalInstance", "selectedLicense", "licenseAgents" ];

	function LicenseInfoModalController( $scope, $modalInstance, selectedLicense, licenseAgents ) {

		$scope.close = $modalInstance.close;
		$scope.close();

		$scope.result = [];

		angular.forEach( licenseAgents, function( agent ){
			if( agent.licenseId === selectedLicense.licenseId ){
				$scope.result.push( agent );
			}
		});

	}

	return LicenseInfoModalController;

});
