define('components/specialtyApplication/questions/config/widgets/KeyCharacteristicsWidget',['require'],function(require){

    "use strict";

    var widget={};

    widget.getStructure = function(){
        return {

            "KeyCharacteristics"   : { wrapper: ["key-characteristics-wrapper"], fieldGroup:{
                    "RAP No of Locations"            :{ wrapper: ["hideGroup"],orderNumber: 1, data: { saveBroadcast: true }},
                    "Eligibility Zip"                :{ wrapper: ["KeyCharacteristicsDataPoint", "keyCharacteristicsRowError"],orderNumber: 2, data: { savePointOnly: true } },
                    "RAP Eligibility Building Age"   :{ wrapper: ["KeyCharacteristicsDataPoint", "keyCharacteristicsRowError"],orderNumber: 3, data: { savePointOnly: true }},
                    "RAP Eligibility Years Owned"    :{ wrapper: ["KeyCharacteristicsDataPoint", "keyCharacteristicsRowError"],orderNumber: 4, data: { savePointOnly: true }},
                    "Licensed Capacity"              :{ wrapper: ["KeyCharacteristicsDataPoint", "keyCharacteristicsRowError"],orderNumber: 5, data: { savePointOnly: true }},
                    "Average daily attendance"       :{ wrapper: ["KeyCharacteristicsDataPoint", "keyCharacteristicsRowError"],orderNumber: 6, data: { savePointOnly: true }},
                    "No of Infants"                  :{ wrapper: ["KeyCharacteristicsDataPoint", "keyCharacteristicsRowError"],orderNumber: 7, data: { savePointOnly: true }},
                    "RAP Eligibility No of Vehicles" :{ wrapper: ["KeyCharacteristicsDataPoint", "keyCharacteristicsRowError"],orderNumber: 8, data: { savePointOnly: true }}
                 }

            }
        }
    };
    widget.configureFormly = function(formlyConfig, apiCheck){

        formlyConfig.setWrapper({
            name: "key-characteristics-wrapper",
            template: '<div  key-characteristics  model="model" options="options" quantity-control-id="\'RAPNoofLocations\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "KeyCharacteristicsDataPoint",
            template: '<div class="KeyCharacteristicsDataPoint">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "keyCharacteristicsRowError",
            overwriteOk: true,
            template:
            '<div class="span1 form-group keyCharacteristicsRowError" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="KCError">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });

    };
    return widget;
});
