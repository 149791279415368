define('components/classCodesSearch/app',['require','common/directive/app','components/classCodesSearch/directive/classCodesSearch','components/classCodesSearch/controller/ClassCodesSearchController','common/service/MessageContainer','common/service/util/PaginationService','common/service/util/RenameKeys','common/service/search/SearchService','common/service/search/SearchResultContainer','components/classCodesSearch/filter/formatClassCodeDescription'],function(require) {
	"use strict";
	
	require("common/directive/app");

	var classCodesSearch = require("components/classCodesSearch/directive/classCodesSearch"),
		ClassCodesSearchController = require("components/classCodesSearch/controller/ClassCodesSearchController"),
		MessageContainer = require("common/service/MessageContainer"),
		PaginationService = require("common/service/util/PaginationService"),
		RenameKeys = require("common/service/util/RenameKeys"),
		SearchService = require("common/service/search/SearchService"),
		SearchResultContainer = require("common/service/search/SearchResultContainer"),
		formatClassCodeDescription = require("components/classCodesSearch/filter/formatClassCodeDescription");

	var component = angular.module("mPortal.components.classCodesSearch", [
		"mPortal.directive"
	]);

	component.directive("classCodesSearch", classCodesSearch);
	component.controller("ClassCodesSearchController", ClassCodesSearchController);
	component.factory("MessageContainer", MessageContainer);
	component.factory("PaginationService", PaginationService);
	component.factory("RenameKeys", RenameKeys);
	component.factory("SearchService", SearchService);
	component.factory("SearchResultContainer", SearchResultContainer);
	component.filter("formatClassCodeDescription", formatClassCodeDescription);

	return component;

});
