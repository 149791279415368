define('components/userAccountSettings/personalDetails/controller/PersonalDetailsController',['require'],function(require) {
	"use strict";

	PersonalDetailsController.$inject = [ "$scope", "MessageContainer", "UserService", "UserProfile" ];

	function PersonalDetailsController( $scope, MessageContainer, UserService, UserProfile) {
		$scope.userProfile = UserProfile;
		$scope.uiState = {
			visible: false,
			submitting: false,
			sectionTitle: $scope.user.type === "Agent" ? "Personal and Address Information" : "Personal Information",
			showPersonalInfo: $scope.user.type !== "Agent"
		};

		$scope.messages = new MessageContainer({
			success: false,
			errors: [],
			errorHeader: ""
		});

		$scope.save = function() {
			$scope.messages.reset();
			$scope.uiState.submitting = true;
			UserService.savePersonalDetails( $scope.user ).then( function( result ) {
                $scope.user.personalDetailPassword = "";
				if( result.isValid && result.isSaved ) {
					$scope.messages.success = true;
				} else {
					$scope.messages.errorHeader = "Please correct the following problems:";
					angular.forEach( result.errors, function( error ) {
						$scope.messages.errors.push( error.message );
					});
				}
				$scope.uiState.submitting = false;
			});
		};


	}

	return PersonalDetailsController;

});
