define('components/startQuote/app',['require','common/directive/app','common/service/ClassCodeService','common/service/MessageContainer','common/service/EligibilityService','common/service/MarketedProgramService','common/service/StartQuoteService','common/service/AgencyService','common/service/util/DateService','components/startQuote/directive/startQuote','components/startQuote/directive/productLineClassCodeRow','components/startQuote/directive/productLineCheck','components/startQuote/controller/StartQuoteController','common/service/specialtyApplication/SubmissionStore','components/startQuote/controller/ProductLineClassCodeRowController'],function(require) {
	"use strict";

	require("common/directive/app");

	var ClassCodeService 				  = require("common/service/ClassCodeService"),
		MessageContainer 				  = require("common/service/MessageContainer"),
		EligibilityService 				  = require("common/service/EligibilityService"),
		MarketedProgramService			  = require("common/service/MarketedProgramService"),
		StartQuoteService 				  = require("common/service/StartQuoteService"),
		AgencyService 					  = require("common/service/AgencyService"),
		DateService 					  = require("common/service/util/DateService"),
		startQuote 						  = require("components/startQuote/directive/startQuote"),
		productLineClassCodeRow 		  = require("components/startQuote/directive/productLineClassCodeRow"),
		productLineCheck 				  = require("components/startQuote/directive/productLineCheck"),
		StartQuoteController 		      = require("components/startQuote/controller/StartQuoteController"),
		SubmissionStore 				  = require("common/service/specialtyApplication/SubmissionStore"),
		ProductLineClassCodeRowController = require("components/startQuote/controller/ProductLineClassCodeRowController");

	var component = angular.module("mPortal.components.startQuote", [
		"mPortal.directive"
	]);

	component.factory("ClassCodeService", ClassCodeService);
	component.factory("MessageContainer", MessageContainer);
	component.factory("EligibilityService", EligibilityService);
	component.factory("MarketedProgramService", MarketedProgramService);
	component.factory("StartQuoteService", StartQuoteService);
	component.factory("AgencyService", AgencyService);
	component.factory("DateService", DateService);
	component.factory("SubmissionStore", SubmissionStore);

	component.directive("startQuote", startQuote);
	component.directive("productLineClassCodeRow", productLineClassCodeRow);
	component.directive("productLineCheck", productLineCheck);
	component.controller("StartQuoteController", StartQuoteController);
	component.controller("ProductLineClassCodeRowController", ProductLineClassCodeRowController);

	return component;

});
