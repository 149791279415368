define('components/userAccountSettings/stateNewsSubscriptions/app',['require','common/directive/app','components/userAccountSettings/stateNewsSubscriptions/directive/stateNewsSubscriptions','components/userAccountSettings/stateNewsSubscriptions/controller/StateNewsSubscriptionsController','common/service/MessageContainer','common/service/user/UserService'],function(require) {
	"use strict";

	require("common/directive/app");

	var stateNewsSubscriptions = require("components/userAccountSettings/stateNewsSubscriptions/directive/stateNewsSubscriptions"),
		StateNewsSubscriptionsController = require("components/userAccountSettings/stateNewsSubscriptions/controller/StateNewsSubscriptionsController"),
		MessageContainer = require("common/service/MessageContainer"),
		UserService = require("common/service/user/UserService");

	var component = angular.module("mPortal.userAccountSettings.stateNewsSubscriptions", [
		"mPortal.directive"
	]);

	component.directive("stateNewsSubscriptions", stateNewsSubscriptions);
	component.controller("StateNewsSubscriptionsController", StateNewsSubscriptionsController);
	component.factory("MessageContainer", MessageContainer);
	component.factory("UserService", UserService );

	return component;

});
