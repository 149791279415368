define( 'components/applicationData/applicationInfo/controller/ApplicationInfoController',['require'],function( require ) {
    "use strict";

    ApplicationInfoController.$inject = [ "$scope", "$http", "UserProfile" ];

    function ApplicationInfoController( $scope, $http, UserProfile ) {
        $scope.loading = true;
        $scope.user = UserProfile;
        $scope.isInsured = false;
	    if ( $scope.user.type.toLowerCase() === "insured" )
	    	$scope.isInsured = true;
        $scope.areRequestsPending = function() {
            if( $scope.loading && $http.pendingRequests.length > 0 ) {
                return true;
            } else {
                $scope.loading = false;
                return false;
            }
        };

	    if ( $scope.application ) {

		    $scope.licensedAgentFullName = function () {
			    if ( $scope.application.agencyInfo.licensedAgentFirstName !== null || $scope.application.agencyInfo.licensedAgentLastName !== null ) {
				    return $scope.application.agencyInfo.licensedAgentFirstName + " " + $scope.application.agencyInfo.licensedAgentLastName;
			    }
			    else {
				    return "N/A"
			    }
		    }

		    $scope.servicingAgentFullName = function () {
			    if ( $scope.application.agencyInfo.servicingAgentFirstName !== null || $scope.application.agencyInfo.servicingAgentLastName !== null ) {
				    return $scope.application.agencyInfo.servicingAgentFirstName + " " + $scope.application.agencyInfo.servicingAgentLastName;
			    }
			    else {
				    return "N/A"
			    }
		    }

	    }
    }

    return ApplicationInfoController;
});
