define('components/applicationData/applicationData/config/widgets/ViewLocationInformationWidget',['require'],function(require) {
	"use strict";

	var widget = {};

    widget.getStructure = function () {
        return {

            "ViewLocationInformationDataPoint": {wrapper: null, fieldGroup:
                {
                    "viewVerisk": {wrapper: ["Location"], type: "setVerisk", wrapperOverride: true},
	                "viewLocationAddress1": {wrapper: ["Location"], type: "setVerisk", wrapperOverride: true},
	                "viewLocationAddress2": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "viewLocationCity": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "viewLocationState": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "viewLocationCounty": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "viewLocationZip": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

                    "No of Buildings": {wrapper: ["viewBoNoOfBuildings"], type: "setLocation", wrapperOverride: true},

                    "Applicant Type": {wrapper: null, type: "omittedHeader", wrapperOverride: true},
                    "Building Description": {wrapper: null,type: "omittedHeader", wrapperOverride: true},

                    "Construction Type": {wrapper: ["ConstructionTypeWrapper"],type: "setLocation", wrapperOverride: true,orderNumber:1},
                    "Year Built": {wrapper: ["viewYearBuilt"],type: "setLocation", wrapperOverride: true,orderNumber:2},
                    "Stories": {wrapper: ["viewStories"],type: "setLocation", wrapperOverride: true,orderNumber:3},
                    "Building Square Footage": {wrapper: ["viewSquareFootage"],type: "setLocation", wrapperOverride: true,orderNumber:4},

                    "Building Location": {wrapper: ["viewBuildingLocation"],type: "setLocation", wrapperOverride: true,orderNumber:5},
                    "Annual Gross Sales": {wrapper: ["viewAnnualGross"],type: "setLocation", wrapperOverride: true,orderNumber:6},
                    "Single Occupancy": {wrapper: ["viewSingleOccupancy"],type: "setLocation", wrapperOverride: true,orderNumber:7},
                    "Occupied Percent": {wrapper: ["viewOccupiedPercent"],type: "setLocation", wrapperOverride: true,orderNumber:8},

                    "Business Name": {wrapper: ["viewBuildingLocation"],type: "setLocation", wrapperOverride: true,orderNumber:9},
                    "Fire Alarm": {wrapper: ["viewAnnualGross"],type: "setLocation", wrapperOverride: true,orderNumber:10},
                    "Sprinklered": {wrapper: ["viewSingleOccupancy"],type: "setLocation", wrapperOverride: true,orderNumber:11},
                    "Condo Unit Owner": {wrapper: ["viewOccupiedPercent"],type: "setLocation", wrapperOverride: true,orderNumber:12},

                    "Class Code Description ID": {wrapper: null, type: "omittedHeader", wrapperOverride: true},

                    "Hydrant Distance": {wrapper: ["ConstructionTypeWrapper"],type: "setLocation", wrapperOverride: true,orderNumber:13},
                    "Fire Station Distance": {wrapper: ["viewDistanceToStation"],type: "setLocation", wrapperOverride: true,orderNumber:14},

                    "BPP Limit": {wrapper: ["ConstructionTypeWrapper"],type: "setLocation", wrapperOverride: true,orderNumber:15},
                    "Building Value": {wrapper: ["viewDistanceToStation"],type: "setLocation", wrapperOverride: true,orderNumber:16},

	                "viewLocationPestControlOperators": {wrapper: null,type: "omittedHeader", wrapperOverride: true},
	                "viewLocationPestControlGross ": {wrapper: ["fullLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:17},
	                "viewLocationBedBugs": {wrapper: null,type: "omittedHeader", wrapperOverride: true},
	                "viewLocationBedBugsGross": {wrapper: ["fullLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:18},
	                "viewLocationFumigating": {wrapper: null,type: "omittedHeader", wrapperOverride: true},
	                "viewLocationFumStructural": {wrapper: ["leftLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:22},
	                "viewLocationFumCommodity": {wrapper: ["rightLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:23},
	                "viewLocationWildlifeControl": {wrapper: null,type: "omittedHeader", wrapperOverride: true},
	                "viewLocationWildlifeControlGross ": {wrapper: ["fullLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:19},
	                "viewLocationTermiteTreatment": {wrapper: null,type: "omittedHeader", wrapperOverride: true},
	                "viewLocationTermiteTreatmentGross": {wrapper: ["fullLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:20},
	                "viewLocationWDI ": {wrapper: null,type: "omittedHeader", wrapperOverride: true},
	                "viewLocationWDIGross": {wrapper: ["fullLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:21},
	                "viewLocationLawnCare ": {wrapper: null,type: "omittedHeader", wrapperOverride: true},
	                "viewLocationLawnCarePayroll ": {wrapper: ["leftLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:24},
	                "viewLocationLawnCareReceipts ": {wrapper: ["rightLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:25},
	                "viewLocationTreeTrimming ": {wrapper: null,type: "omittedHeader", wrapperOverride: true},
	                "viewLocationTreeTrimmingPayroll ": {wrapper: ["leftLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:26},
	                "viewLocationTreeTrimmingReceipts ": {wrapper: ["rightLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:27},

                    "Renovations": {wrapper: ["leftLocationInformation"],type: "setLocation", wrapperOverride: true,orderNumber:28},
                    "Renovation Type Label": {wrapper: ["RightRenovationTypeLabel"],type: "setLocation", wrapperOverride: true,orderNumber:29},
                    "Roof Renovation": {wrapper: ["RoofRenovationLabels"],type: "setLocation", wrapperOverride: true,orderNumber:30},
                    "Roof Years": {wrapper: ["RoofRenovationQuestions"],type: "setLocation", wrapperOverride: true,orderNumber:31},
                    "Electrical Renovation": {wrapper: ["RightRenovationLabels"],type: "setLocation", wrapperOverride: true,orderNumber:32},
                    "Electrical Years": {wrapper: ["RightRenovationQuestions"],type: "setLocation", wrapperOverride: true,orderNumber:33},
                    "Heating Renovation": {wrapper: ["HeatingRenovationLabels"],type: "setLocation", wrapperOverride: true,orderNumber:34},
                    "Heating Years": {wrapper: ["HeatingRenovationQuestions"],type: "setLocation", wrapperOverride: true,orderNumber:35},
                    "Plumbing Renovation": {wrapper: ["RightRenovationLabels"],type: "setLocation", wrapperOverride: true,orderNumber:37},
                    "Plumbing Years": {wrapper: ["RightRenovationQuestions"],type: "setLocation", wrapperOverride: true,orderNumber:38},

	                "viewOwnBuilding": {wrapper: ["HalfRowBuildingInformation1"],type: "setLocation", wrapperOverride: true,orderNumber:1},
	                "viewStories": {wrapper: ["HalfRowBuildingInformation2"],type: "setLocation", wrapperOverride: true,orderNumber:2},
	                "viewBuildingSquareFootage": {wrapper: ["HalfRowBuildingInformation1"],type: "setLocation", wrapperOverride: true,orderNumber:3},
	                "viewSprinklered": {wrapper: ["HalfRowBuildingInformation2"],type: "setLocation", wrapperOverride: true,orderNumber:4},
	                "viewBuildingUpdated": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"],type: "setLocation", wrapperOverride: true,orderNumber:5},
	                "viewWiringUpdateYear": {wrapper: ["HalfRowBuildingInformation1"],type: "setLocation", wrapperOverride: true,orderNumber:6},
	                "viewRoofingUpdateYear": {wrapper: ["HalfRowBuildingInformation2"],type: "setLocation", wrapperOverride: true,orderNumber:7},
	                "viewPlumbingUpdateYear": {wrapper: ["HalfRowBuildingInformation1"],type: "setLocation", wrapperOverride: true,orderNumber:8},
	                "viewHeatingUpdateYear": {wrapper: ["HalfRowBuildingInformation2"],type: "setLocation", wrapperOverride: true,orderNumber:9},

	                "viewPropertyCoverageLabel ": {wrapper: ["FullRowAlert"],type: "setLocation", wrapperOverride: true,orderNumber:10},
	                "viewBuildingCoverage": {wrapper: ["FullRowNoDefaultValue"],type: "setLocation", wrapperOverride: true,orderNumber:11},
	                "viewBuildingValue": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"],type: "setLocation", wrapperOverride: true,orderNumber:12},
	                "viewDatapointContentsCoverage": {wrapper: ["FullRowNoDefaultValue"],type: "setLocation", wrapperOverride: true,orderNumber:13},
	                "viewContentsValue": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"],type: "setLocation", wrapperOverride: true,orderNumber:14},
	                "viewDatapointBICoverage": {wrapper: ["FullRowNoDefaultValue"],type: "setLocation", wrapperOverride: true,orderNumber:15},
	                "viewBILimit": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"],type: "setLocation", wrapperOverride: true,orderNumber:16},
                    "viewDatapointTIBCoverage": {wrapper: ["FullRowNoDefaultValue"],type: "setLocation", wrapperOverride: true,orderNumber:17},
	                "viewTIBValue": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"],type: "setLocation", wrapperOverride: true,orderNumber:18},
	                "viewNoOfAddInterests": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"],type: "setLocation", wrapperOverride: true,orderNumber:19},
	                "viewPropAddInterestInfo": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"],type: "setLocation", wrapperOverride: true,orderNumber:20},
	                "viewTypeAddInterest": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"],type: "setLocation", wrapperOverride: true,orderNumber:21},
	                "viewPropAddInterestName": {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"],type: "setLocation", wrapperOverride: true,orderNumber:22},
	                "viewPropAddInterestAddress1": {wrapper: ["HalfRowBuildingInformation3"],type: "setLocation", wrapperOverride: true,orderNumber:23},
	                "viewPropAddInterestAddress2": {wrapper: ["InRowInformation"],type: "setLocation", wrapperOverride: true,orderNumber:24},
	                "viewPropAddInterestCity": {wrapper: ["InRowInformation"],type: "setLocation", wrapperOverride: true,orderNumber:25},
	                "viewPropAddInterestCounty": {wrapper: ["InRowInformation"],type: "setLocation", wrapperOverride: true,orderNumber:26},
	                "viewPropAddInterestState": {wrapper: ["InRowInformation"],type: "setLocation", wrapperOverride: true,orderNumber:27},
	                "viewPropAddInterestZip": {wrapper: ["InRowInformation"],type: "setLocation", wrapperOverride: true,orderNumber:28},
	                "viewPropAddInterestAccountNumber" : {wrapper: ["FullRowUmbrellaExcessLiabilityInformation"],type: "setLocation", wrapperOverride: true,orderNumber:29},
	                "viewBuildingName": {wrapper: ["Location"],type: "setLocation", wrapperOverride: true,orderNumber:30},
	                "viewConstructionType": {wrapper: ["Location"],type: "setLocation", wrapperOverride: true,orderNumber:31},
	                "viewTypeOfMasonry": {wrapper: ["Location"],type: "setLocation", wrapperOverride: true,orderNumber:32},
	                "viewYearBuilt": {wrapper: ["Location"],type: "setLocation", wrapperOverride: true,orderNumber:33},
                    "viewTypeOfSteel": {wrapper: null,type: "omittedHeader", wrapperOverride: true}

                }
            }
        };
    };

    widget.configureFormly = function (formlyConfig, formlyApiCheck) {

        formlyConfig.setWrapper({
            name: "Location",
            template: '<formly-transclude></formly-transclude>'
        });

        formlyConfig.setType({
            name: "setLocation",
            template: '<formly-transclude></formly-transclude>'
        });
        formlyConfig.setType({
            name: "setVerisk",
            template: '<span>Verisk: <formly-transclude></formly-transclude></span>'
        });
        formlyConfig.setWrapper({
            name: "viewBoNoOfBuildings",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div style=" margin-left:0" >' +
            '<span style=" width:27%; margin-left: 0" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span style = "width:2%; margin-left: 1%">{{::options.defaultValue}}</span><br><br>'+
            '<span style="width:71%;"></span>' +
            '</div>'+
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "ConstructionTypeWrapper",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:31%; margin-left: 0%; max-height: 28px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "viewYearBuilt",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:19.8%; margin-left: 0%; max-height: 28px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "viewStories",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:18%; margin-left: 0%; max-height: 28px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "viewSquareFootage",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:31.2%; margin-left: 0%; max-height: 28px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "viewBuildingLocation",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:29%; margin-left: 0%; margin-right: 2%; margin-bottom: 10px; max-height: 35px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "viewAnnualGross",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:19.8%; margin-left: 0%; margin-bottom: 10px; max-height: 35px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "viewSingleOccupancy",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:18%; margin-left: 0%; margin-bottom: 10px; max-height: 35px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "viewOccupiedPercent",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:31.2%; margin-left: 0%; margin-bottom: 10px; max-height: 35px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "viewDistanceToStation",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:69%; margin-left: 0%; max-height: 28px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

	    formlyConfig.setWrapper( {
		    name: "fullLocationInformation",
		    template:
		    '<div class="viewItem" ng-if="!options.data.hide">' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span12" style="width:100%; margin-left:0" >' +
		    '<span style = "line-height: 27px" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
		    '</div>' +
		    '</div>'
	    } );

	    formlyConfig.setWrapper( {
		    name: "leftLocationInformation",
		    template:
		    '<div class="viewItem" ng-if="!options.data.hide">' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span6" style=" width:50%; margin-left:0" >' +
		    '<span style = "line-height: 27px" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
		    '</div>' +
		    '</div>'
	    } );

	    formlyConfig.setWrapper( {
		    name: "rightLocationInformation",
		    template:
		    '<div class="viewItem" ng-if="!options.data.hide">' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span6" style=" width:50%; margin-left:0" >' +
		    '<span style = "line-height: 27px;margin-left: 13px;" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
		    '</div>' +
		    '</div>'
	    } );

	    formlyConfig.setWrapper({
		    name: "HalfRowBuildingInformation1",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide" >' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span6" style=" width:65%; margin-left:0; line-height: 14.5px;" >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper({
		    name: "HalfRowBuildingInformation2",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide" >' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span4" style=" width:35%; margin-left:0; line-height: 14.5px;" >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper({
		    name: "HalfRowBuildingInformation3",
		    template:
		    '<div class= "viewItem" ng-if="!options.data.hide" >' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span4" style="width: auto; margin-left:0; line-height: 14.5px;" >' +
		    '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}},</span>' +
		    '</div>' +
		    '</div>'
	    });

	    formlyConfig.setWrapper({
		    name: "InRowInformation",
		    template:
		    '<div class= "viewItem span1" style="margin-left:0; line-height: 14.5px; width: auto;" >' +
		    '<span ng-if="options.defaultValue">{{::options.defaultValue}}, ' +
		    '<formly-transclude></formly-transclude>' +
		    '</div>'

	    });

	    formlyConfig.setWrapper( {
		    name: "FullRowNoDefaultValue",
		    template:
		    '<div class= "viewItem">' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= "span12" style="margin-left:0; line-height: 14.5px;"  >' +
		    '<span ng-if="options.templateOptions.label && options.defaultValue == true">{{::options.templateOptions.label}}&nbsp<br><br></span>' +
		    '</div>' +
		    '</div>'
	    } );

	    formlyConfig.setWrapper({
		    name: "FullRowAlert",
		    template:
		    '<div class= "viewItem">' +
		    '<formly-transclude></formly-transclude>' +
		    '<div class= " span12 alert alert-text" style="width: 95.3%; margin-left:0;"  >' +
		    '<span style="display: flex;" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp<br><br></span>' +
		    '</div>' +
		    '</div>'
	    });

        formlyConfig.setWrapper( {
            name: "RightRenovationTypeLabel",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div class= "span6" style=" width:50%; margin-left:0" >' +
            '<span style = "line-height: 27px;margin-left: 10px;" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
            '</div>' +
            '</div>'
        } );

        formlyConfig.setWrapper({
            name: "RoofRenovationLabels",
            template:
            '<div class= "viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="{{(options.defaultValue===\'false\' || !options.defaultValue )?\'renovationLeftFull\':\'renovationRoofLabel\'}}" style="margin-left: 0px; line-height: 27px;">' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label)}}:</span><br>' +
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "HeatingRenovationLabels",
            template:
            '<div class= "viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="{{(options.defaultValue===\'false\' || !options.defaultValue )?\'renovationLeftFull\':\'renovationHeatingLabel\'}}" style="margin-left: 0px; line-height: 27px;">' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label)}}:</span><br>' +
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "RightRenovationLabels",
            template:
            '<div class= "viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="{{(options.defaultValue===\'false\' || !options.defaultValue )?\'renovationRightLabel\':\'renovationRightFull\'}}" style="margin-left: 0px; line-height: 27px;">' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label)}}:</span><br>' +
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "RoofRenovationQuestions",
            template:
            '<div class= "viewItem" ng-if="!options.data.hide" >' +
            '<formly-transclude></formly-transclude>' +
            '<div class= "span4" style=" width:47%; margin-left:10px" >' +
            '<span  style = " position: static; line-height: 27px" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "HeatingRenovationQuestions",
            template:
            '<div class= "viewItem" ng-if="!options.data.hide" >' +
            '<formly-transclude></formly-transclude>' +
            '<div class= "span4" style=" width:45.2%; margin-left:8px" >' +
            '<span style = " position: static; line-height: 27px" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "RightRenovationQuestions",
            template:
            '<div class= "viewItem" ng-if="!options.data.hide" >' +
            '<formly-transclude></formly-transclude>' +
            '<div class= "span4" style=" width:42%; margin-left:10px" >' +
            '<span style = " position: static; line-height: 27px" ng-if="options.templateOptions.label">{{::options.templateOptions.label}}&nbsp;{{::options.defaultValue}}<br></span>' +
            '</div>' +
            '</div>'
        });
    };

    return widget;
});
