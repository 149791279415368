define('components/stateImageMap/app',['require','common/directive/app','components/stateImageMap/directive/stateImageMap','components/stateImageMap/controller/StateImageMapController','common/service/FormService'],function(require) {
	"use strict";

	require("common/directive/app");

	var stateImageMap = require("components/stateImageMap/directive/stateImageMap"),
		StateImageMapController = require("components/stateImageMap/controller/StateImageMapController"),
		FormService = require("common/service/FormService");

	var component = angular.module("mPortal.components.stateImageMap", [
		"mPortal.directive"
	]);

	component.directive("stateImageMap", stateImageMap);
	component.controller("StateImageMapController", StateImageMapController);
	component.factory("FormService", FormService);

	return component;

});
