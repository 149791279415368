define( 'common/service/specialtyApplication/QuestionService',['require'],function ( require ) {
			"use strict";

			QuestionService.$inject = ["$http", "archApiUrl"];

			function QuestionService( $http, archApiUrl ) {

				var service = {};

                service.getAppEligibilityGuidelines = function (applicationId, effectiveDate, reference) {
                    return $http.get(archApiUrl + "application/submission/" + applicationId + "/data/" + reference + "/effectiveDate/" + effectiveDate).then(function (result) {
                        return result.data;
                    });
                };

				service.viewApplication = function ( applicationId, program ) {
					return $http.post( archApiUrl + "application/submission/" + applicationId + "/view", {
						applicationId: applicationId,
						marketedProgram: program
					} ).then( function ( result ) {
						return result.data;
					} );
				};

				service.getInsuredInfo = function ( applicationId ) {
					return $http.get( archApiUrl + "application/submission/" + applicationId + "/insuredInfo").then( function ( result ) {
						return result.data;
					} );
				};

				service.listSections = function ( applicationId ) {
					return $http.get( archApiUrl + "application/submission/" + applicationId + "/section" ).then( function ( result ) {
						return result.data;
					} );
				};

				service.getSection = function ( applicationId, sectionId ) {
					return $http.get( archApiUrl + "application/submission/" + applicationId + "/section/" + sectionId ).then( function ( result ) {
						return result.data;
					} );
				};
				service.getSection_async = async function ( applicationId, sectionId ) {
					return $http.get( archApiUrl + "application/submission/" + applicationId + "/section/" + sectionId ).then( function ( result ) {
						return result.data;
					} );
				};

				service.createEnumeration = function ( applicationId, sectionId ) {
					return $http.post( archApiUrl + "application/submission/" + applicationId + "/section/" + sectionId ).then( function ( result ) {
						return result.data;
					} );
				};

				service.createEnumeration_async = async function ( applicationId, sectionId ) {
					return $http.post( archApiUrl + "application/submission/" + applicationId + "/section/" + sectionId ).then( function ( result ) {
						return result.data;
					} );
				};
				service.deleteEnumeration = function ( applicationId, sectionId ) {
					return $http( {
						method: "delete",
						url: archApiUrl + "application/submission/" + applicationId + "/section/" + sectionId
					} ).then( function ( result ) {
						return result.data;
					} );
				};

				service.saveDatapoint = function ( applicationId, sectionId, datapointId, key, value ) {
					return $http.post( archApiUrl + "application/submission/" + applicationId + "/section/" + sectionId + "/data/" + datapointId, {
						applicationId: applicationId,
						sectionId: sectionId,
						datapointId: datapointId,
						key: key,
						value: value
					} ).then( function ( result ) {
						return result.data;
					} )
						.catch( function ( err ) {
							if (err.status === 417) {
								return { uwLocked : true };
							}
						} );
				};

				service.saveDatapoint_async = async function ( applicationId, sectionId, datapointId, key, value ) {
					return $http.post( archApiUrl + "application/submission/" + applicationId + "/section/" + sectionId + "/data/" + datapointId, {
						applicationId: applicationId,
						sectionId: sectionId,
						datapointId: datapointId,
						key: key,
						value: value
					} ).then( function ( result ) {
						return result.data;
					} )
						.catch( function ( err ) {
							if (err.status === 417) {
								return { uwLocked : true };
							}
						} );
				};

				service.submitApplication = function ( applicationId, marketedProgramId, cobrandingId, insuredName ) {
					var postUrl = archApiUrl + "application/submission/" + applicationId + "/submit";
					return $http.post( postUrl, { applicationId: applicationId, marketedProgramId: marketedProgramId, cobrandingId: cobrandingId, insuredName: insuredName } )
							.then( function ( result ) {
								return { success: true };
							} )
							.catch( function ( err ) {
								if ( err.status === 409 ) {
									// Conflict error occurred; expect array of sectionIds in response.
									// A 409 is still successful since it is possible to have a data point
									// configured to be required to submit but not configured visible to
									// the unauth user. There's no point leaving the user stuck on the
									// application step with nothing to do.
									return { success: true, response: err.data };
								} else {
									// Other error occurred.
									return { success: false };
								}
							} );
				};

				service.getSubmission = function( applicationId ) {
					return $http.get( archApiUrl + "application/submission/" + applicationId ).then(function( result ) {
						return result.data;
					});
				};

				/*
				 Returns a sections completed percentage value for the progress indicator.  The currentPercentage
				 argument can be used to prevent the percentage from going backwards if the total number of sections
				 increases too much between calculations.  The currentCompletedCount argument can be used to add previous
				 submission steps to the completion calculation.
				*/
				service.getCompletionPercentage = function( topSections, currentPercentage, completedCount ) {
					var minimumPercentage = currentPercentage ? ( currentPercentage < 100 ? currentPercentage : 100 ) : 0;
					var totalSections = completedCount ? completedCount : 0;
					var completedSections = completedCount ? completedCount : 0;

					angular.forEach( topSections, function( section ) {
						if( section.visible ) {
							totalSections++;
							if( section.isVisited() && section.isComplete() ) {
								completedSections++;
							}
						}
					});

					var rawPercentage = completedSections ? Math.round( ( completedSections / totalSections ) * 100 ) : 0;
					return rawPercentage > minimumPercentage ? rawPercentage : minimumPercentage;
				};

				return service;
			}

			return QuestionService;
		}
)
;
