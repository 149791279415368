define('common/directive/inputPopover',['require'],function(require) {
	"use strict";

	/**
	* This directive assumes a "help" icon (or something similar) will be used to trigger a popover attached to a sibling input. If there is not
	* an input as a descendant of the target element's parent, no popover will be added.
	*/
	function InputPopover() {
		return {
			restrict: "A",
			scope: {
				content: "@inputPopover",
				placement: "@inputPopoverPlacement",
				trigger: "@inputPopoverTrigger",
                html: "@html",
				template:"@inputPopoverTemplate"
			},
			link: function( scope, element, attrs ) {
				var input = element.parent().find(":input");

				scope.template = (scope.template)? scope.template: '<div class="popover input-popover"><div class="arrow"></div><div class="popover-inner"><div class="popover-content"><p></p></div></div></div>';

				input.popover({
					placement: scope.placement,
					trigger: "manual",
                    html: scope.html,
					content: scope.content,
					template: scope.template
				});

				element.on( scope.trigger, function() {
					input.popover("toggle");
				});

			}
		};
	}

	return InputPopover;

});
