define('components/submissionsSearch/directive/submissionsSearch',['require'],function(require) {
	"use strict";

	function SubmissionsSearch() {
		return {
			restrict: "AE",
			scope: {
				searchDataResults: "="
			},
			templateUrl: "app/components/submissionsSearch/template/template.html",
			controller: "SubmissionsSearchController"
		};
	}

	return SubmissionsSearch;

});
