define('components/widget/building-optional-endorsements/mortgage-holder-details/app',['require','components/widget/building-optional-endorsements/mortgage-holder-details/directive/mortgageHolderDetails','components/widget/building-optional-endorsements/mortgage-holder-details/controller/MortgageHolderDetailsController'],function(require) {
	"use strict";

	var mortgageHolderDetails 		= require("components/widget/building-optional-endorsements/mortgage-holder-details/directive/mortgageHolderDetails"),
		MortgageHolderDetailsController = require("components/widget/building-optional-endorsements/mortgage-holder-details/controller/MortgageHolderDetailsController");

	var component 				                = angular.module("mPortal.components.widget.building-optional-endorsements.mortgage-holder-details", []);
	component.directive( "mortgageHolderDetails", mortgageHolderDetails );
	component.controller( "MortgageHolderDetailsController", MortgageHolderDetailsController );

	return component;

});
