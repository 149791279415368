define('common/directive/errorMessage',['require'],function(require) {
	"use strict";

	function ErrorMessage() {
		return {
			restrict: "AE",
			scope: {
				errorMessage: "=?",
				errorMessageHeader: "=?",
				errorMessageArray: "=?"
			},
			template: 
					'<div class="alert alert-error" ng-show="isVisible()">' +
						'<p ng-show="errorMessageHeader.length"><strong>{{ errorMessageHeader }}</strong></p>' +
						'<span ng-show="errorMessage.length">{{ errorMessage }}</span>' +
						'<span ng-show="errorMessageArray.length === 1">{{ errorMessageArray[0].message || errorMessageArray[0] }}</span>' +
						'<ul ng-show="errorMessageArray.length > 1">' +
							'<li ng-repeat="error in errorMessageArray track by $index">{{ error.message || error }}</li>' +
						'</ul>' +
					'</div>',
			link: function( scope, element, attrs ) {

				scope.errorMessage = scope.errorMessage || "";
				scope.errorMessageHeader = scope.errorMessageHeader || "";
				scope.errorMessageArray = scope.errorMessageArray || [];

				scope.isVisible = function() {
					return (
						( scope.errorMessageHeader && scope.errorMessageHeader.length ) ||
						( scope.errorMessage && scope.errorMessage.length ) ||
						( scope.errorMessageArray && scope.errorMessageArray.length )
					);
				};

			}
		};
	}

	return ErrorMessage;

});
