define('components/accountSummary/quoteSummarySection/app',['require','components/accountSummary/paymentSystem/app','components/accountSummary/quoteSummarySection/directive/quoteSummarySection','components/accountSummary/quoteSummarySection/controller/QuoteSummarySectionController','moment'],function(require) {
    "use strict";

    require("components/accountSummary/paymentSystem/app");

    var quoteSummarySection = require("components/accountSummary/quoteSummarySection/directive/quoteSummarySection"),
        QuoteSummarySectionController = require("components/accountSummary/quoteSummarySection/controller/QuoteSummarySectionController"),
        moment = require("moment");

    var component = angular.module("mPortal.accountSummary.quoteSummarySection", [ "mPortal.accountSummary.paymentSystem"]);

    component.directive("quoteSummarySection", quoteSummarySection);
    component.controller("QuoteSummarySectionController", QuoteSummarySectionController);
    component.value("moment", moment);

    return component;

});
