define('components/quickSearch/controller/QuickSearchController',['require'],function(require) {
	"use strict";

	QuickSearchController.$inject = ["$scope", "$location", "$window", "SearchService", "$compile", "$sce"];

	function QuickSearchController( $scope, $location,$window, SearchService, $compile, $sce ) {

		// this variable is only used to empty out the input after we've selected an item.
		$scope.search = $scope.searchText;
		$scope.searchItems  =[];
		var input = $window.document.getElementById('headerSearchInput');
		input.focus();



		$scope.clearSearch = function() {
			$scope.search = ""; 
			$scope.searchItems = []; 
		};

		$scope.quickSearch = function()
		{	
			
			if($scope.search.length <3)
			{
				$scope.searchItems =[];
				 return;
			}
						
			let interval  = null;

			//Implementing the setInterval method
			interval = setInterval(() =>
			{
				SearchService.getQuickSearchResults({
					query: $scope.search,
					entities: "insured,quote,policy,claim"
				}).then(function(data){
					$scope.searchItems = [];
					angular.forEach( data, function( item ) {
						item.label = $sce.trustAsHtml(item.label);
						$scope.searchItems.push(item);
					});
				});

				clearInterval(interval);
			}, 1000);
					
		}

		$scope.select = function(selected ) {
			
			var itemParts = selected.value.split("_"),
				type = itemParts[itemParts.length-2],
				id = itemParts[itemParts.length-1],
				url;

			switch( type ) {

				case "claim":
					if( selected.productLine === 2 ) {
						url = "/account/occurrence/" + selected.IC_ICO_ID;
					} else if( selected.productLine === 0 ){
						//passing dummy effective date to match 2 Year page claims url and route in accountSummary/app.js
						url = "/account-summary/" + selected.II_ID + "/policy/" + selected.IPI_PolicyNumber + "/effective-date/" + "1900-01-01" + "/claim/" + selected.item.IC_ClaimNumber;
					} else {
						url = "/account/claim/" + id;
					}

					break;

				case "policy":
					if( selected.productLine === 0 ) {
						url = "/account-summary/" + selected.II_ID + "/policy/" + selected.IPI_PolicyNumber;
					} else {
						url = "/account/policy/" + id;
					}
					break;

				case "insured":
					if( selected.productLine === 0 ) {
						url = "/account-summary/" + selected.accountNumber;
					} else{
						url = "/account/activity/" + id;
					}
					break;

				case "quote":
					if( selected.productLine === 0 ) {
						url = "/account-summary/" + selected.II_ID;
					} else {
						if ( selected.isRenewal ) {
							url = "/renewal/" + id;
						} else {
							url = "/account/issuance/" + selected.II_ID + "/na/" + id;
						}
					}
					break;

				case "luxon":
					url = "/lx/submission/" + selected.partition_key + "/" + selected.id;
				break;

				default:
					url = "";

			}

			if( url.length ) {
				// we have to empty the legacy app container prior to routing because some of the legacy apps (especially accountDirective) make
				// some assumptions about routing that don't hold up when navigating from quick search.
								
				jQuery("#tmplMainContent").empty();
				$location.path( url );
				$scope.search = "";
			}

			return false;

		};

		$scope.focus = function( event ) {
			event.preventDefault();
		};

		$scope.$watch('searchText', function(newVal) {
			if (newVal) {
				$scope.quickSearch();
			}
		});

	}

	return QuickSearchController;

});
