define('components/policyDetail/policyBilling/directive/policyBilling',['require'],function(require) {
	"use strict";

	function PolicyBilling() {
		return {
			restrict: "AE",
			scope: {
				accountId: "=",
				account: "=",
				policy: "=",
				policyEffectiveDate: "=",
				policyBillType: "=",
				user:"="
			},
			templateUrl: "app/components/policyDetail/policyBilling/template/template.html",
			controller: "PolicyBillingController"
		};
	}

	return PolicyBilling;

});
