define( 'components/editSubmission/controller/EditWarningModalController',['require'],function( require ) {
    "use strict";

    EditWarningModalController.$inject = [ "$scope", "$modalInstance", "$location", "applicationId", "UserProfile","status" ];

    function EditWarningModalController( $scope, $modalInstance, $location, applicationId, UserProfile,status ) {

        $scope.applicationId = applicationId;
        $scope.close = $modalInstance.close;
        $scope.status = status;

        $scope.proceedToEdit = function() {
            if($scope.status == "Quote"){
                UserProfile.set("editedApplication", $scope.applicationId);
                $location.path("/application/edit/" + $scope.applicationId);
                $scope.close();
            }
            else {
                UserProfile.set("editedApplication", $scope.applicationId);
                $location.path("/application/agent/edit/" + $scope.applicationId);
                $scope.close();
            }
        }
    }

    return EditWarningModalController;
});
