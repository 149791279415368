define('accountSummary/app',['require','common/config/config','common/directive/app','components/accountSummary/insuredInfo/app','components/accountSummary/accountList/app','components/accountSummary/quoteList/app','components/accountSummary/quoteTable/app','components/accountSummary/quoteSummarySection/app','components/accountSummary/quoteSummary/app','components/accountSummary/insuredAccountActivity/app','components/accountSummary/paymentSystem/app','components/claimDetail/insuredClaimInfo/app','components/addAccount/app','components/policyDetail/policyDetail/app','components/insuredClaims/app','components/claimDetail/claimDetail/app','components/submissionDetail/submissionDetail/app','components/lossRuns/app','components/message/container/app','components/applicationData/applicationData/app','angular-sanitize','shim/overmind','accountSummary/controller/AccountSummaryController','accountSummary/controller/InsuredAccountSummaryController','common/service/specialtyApplication/accountService','common/service/RenewalService','common/service/RecentlyViewedService','common/service/TabManagerService','common/service/user/UserService','moment','common/service/MessageService'],function(require) {
	"use strict";

	require("common/config/config");
	require("common/directive/app");
	require("components/accountSummary/insuredInfo/app");
	require("components/accountSummary/accountList/app");
	require("components/accountSummary/quoteList/app");
	require("components/accountSummary/quoteTable/app");
	require("components/accountSummary/quoteSummarySection/app");
	require("components/accountSummary/quoteSummary/app");
	require("components/accountSummary/insuredAccountActivity/app");
	require("components/accountSummary/paymentSystem/app");
	require("components/claimDetail/insuredClaimInfo/app");
	require("components/addAccount/app");
	require("components/policyDetail/policyDetail/app");
	require("components/insuredClaims/app");
	require("components/claimDetail/claimDetail/app");
	require("components/submissionDetail/submissionDetail/app");
	require("components/lossRuns/app");
	require("components/message/container/app");
	require("components/applicationData/applicationData/app");
	require("angular-sanitize");

	var overmind = require("shim/overmind");

	var AccountSummaryController = require("accountSummary/controller/AccountSummaryController"),
		InsuredAccountSummaryController = require("accountSummary/controller/InsuredAccountSummaryController"),
		AccountService = require("common/service/specialtyApplication/accountService" ),
		RenewalService = require("common/service/RenewalService"),
		RecentlyViewedService = require("common/service/RecentlyViewedService"),
		TabManagerService = require("common/service/TabManagerService"),
		UserService = require( "common/service/user/UserService" ),
		moment = require("moment" ),
		MessageService = require( "common/service/MessageService" );

	var app = angular.module("mPortal.accountSummary", [
		"mPortal.config",
		"mPortal.directive",
		"mPortal.accountSummary.insuredInfo",
		"mPortal.accountSummary.accountList",
		"mPortal.accountSummary.quoteList",
		"mPortal.accountSummary.quoteTable",
		"mPortal.accountSummary.quoteSummarySection",
		"mPortal.accountSummary.quoteSummary",
		"mPortal.accountSummary.insuredAccountActivity",
		"mPortal.accountSummary.paymentSystem",
		"mPortal.addAccount",
		"mPortal.policyDetail.policyDetail",
		"mPortal.insuredClaims",
		"mPortal.claimDetail.claimDetail",
		"mPortal.claimDetail.insuredClaimInfo",
		"mPortal.submissionDetail.submissionDetail",
		"mPortal.lossRuns",
		"mPortal.components.message.container",
		"mPortal.components.message.form",
		"mPortal.applicationData.applicationData",
		"ngSanitize"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider.when("/account-summary", {
			templateUrl: "app/accountSummary/template/insuredTemplate.html",
			controller: "InsuredAccountSummaryController"
		});

		$routeProvider.when("/account-summary/:accountId", {
			templateUrl: "app/accountSummary/template/template.html",
			controller: "AccountSummaryController"
		});

		$routeProvider.when("/account-summary/:accountId/policy/:policyNumber", {
			templateUrl: "app/accountSummary/template/template.html",
			controller: "AccountSummaryController"
		});

		$routeProvider.when("/account-summary/:accountId/policy/:policyNumber/effective-date/:effectiveDate/claim/:occurrenceNumber", {
			templateUrl: "app/accountSummary/template/template.html",
			controller: "AccountSummaryController"
		});

		$routeProvider.when("/account-summary/:accountId/submission/:submissionId/application/:applicationId", {
			templateUrl: "app/accountSummary/template/template.html",
			controller: "AccountSummaryController"
		});

		$routeProvider.when("/account-summary/:accountId/new-message/:messageReasonId/:isApplicationUpload?", {
			templateUrl: "app/accountSummary/template/template.html",
			controller: "AccountSummaryController"
		});

		$routeProvider.when("/account-summary/:accountId/new-message/:messageReasonId/reason/:reason", {
			templateUrl: "app/accountSummary/template/template.html",
			controller: "AccountSummaryController"
		});

		$routeProvider.when("/account-summary/:accountId/message/:threadId/:threadAction?", {
			templateUrl: "app/accountSummary/template/template.html",
			controller: "AccountSummaryController"
		});
	}]);

	app.controller("AccountSummaryController", AccountSummaryController);
	app.controller("InsuredAccountSummaryController", InsuredAccountSummaryController);
	app.factory("AccountService", AccountService);
	app.factory("RecentlyViewedService", RecentlyViewedService);
	app.factory("RenewalService", RenewalService);
	app.factory("TabManagerService", TabManagerService);
	app.factory("UserService", UserService);
	app.factory("MessageService", MessageService);
	app.value("moment", moment);

	return app;

});
