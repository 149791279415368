define('accountSummary/controller/AccountSummaryController',['require'],function(require) {
	"use strict";

	AccountSummaryController.$inject = ["$scope", "$routeParams", "$q", "AccountService", "RecentlyViewedService", "TabManagerService", "UserProfile", "moment"];

	function AccountSummaryController( $scope,  $routeParams, $q, AccountService, RecentlyViewedService, TabManagerService, UserProfile, moment ) {

		$scope.user = UserProfile;

		$scope.status = {
			notFound: false,
			claimNotFound: false,
			serverDown: false
		};


		$scope.tabs = TabManagerService.initializeTabs([ "summary","message","policies", "claims", "submissions", "applications" ]);
		//create account summary
		TabManagerService.createTab('summary',1);
		
		// Creating a curried call to openTab to be passed down to child directives/components
		$scope.openPolicyTab = TabManagerService.openTab.bind( null, "policies" );
		$scope.openSubmissionTab = TabManagerService.openTab.bind( null, "submissions" );
		$scope.openApplicationTab = TabManagerService.openTab.bind( null, "applications" );
		$scope.openPolicy;
		$scope.claim;
		$scope.submission;
		$scope.upcomingRenewals = [];

		$scope.showLossRuns = function(){
			let viewLossRuns = false;
			if(localStorage.getItem('mportal-show-loss-runs'))
			{
				viewLossRuns = localStorage.getItem('mportal-show-loss-runs') === 'true';
			}	
			return viewLossRuns;
		}

		/*
		 There are three different routes that can call this controller:

		 1) The generic route containing just the "accountId" route param.  This route simply
		 loads the account summary data and opens the view.

		 2) The policy-detail route which contains the additional "policyNumber" route param.  The
		 policyNumber param is used to invoke the creation/display of a policy detail tab on the
		 account summary view, using policy data retrieved along with the account summary data to
		 partially hydrate the view and then kick off REST calls for additional policy-related data.

		 3) The claim-detail route which contains "policyNumber" and "occurrenceNumber" route params.
		 The policyNumber param is used to identify the corresponding policy in the account summary data,
		 some of which is needed to provide values needed to create/display a claim detail tab on the
		 account summary view, while the occurrenceNumber is used to add an additional REST call to the
		 init action of this controller to grab the specified claim record, which is needed as part of
		 the process of displaying the tab.

		 Both the policy detail and claim detail tabs can also be created/displayed as actions of child
		 directives
		*/
		var promiseArray = [
			AccountService.getAccountSummary( $routeParams.accountId ),
			AccountService.getAccountActivity( $routeParams.accountId )
		];

		if( $routeParams.occurrenceNumber ) {
			promiseArray.push( AccountService.getClaimOccurrence( $routeParams.occurrenceNumber ) );
		}

		$q.all( promiseArray ).then( function( results ) {
			$scope.account 	= results[ 0 ];
			$scope.accounts = [ $scope.account ];

			$scope.$on( 'bundle_payment_visible', function ( event, args ) {
				$scope.bundlePaymentVisible = args;
				if ( $scope.bundlePaymentVisible === true ) {
					$scope.account.showCurrentDue = false;
					$scope.account.showPastDue = false;
				}
				else {
					$scope.account.showCurrentDue = true;
					$scope.account.showPastDue = true;
					if ( $scope.account.policies.length === 0 ) {
						$scope.account.showCurrentDue = false;
						$scope.account.showPastDue = false
					}
					angular.forEach( $scope.accounts, function ( account) {
						angular.forEach( account.policies, function ( policy ) {
							if ( policy.billingSystem === 'ICON' ) {
								$scope.account.showCurrentDue = false;
								$scope.account.showPastDue = false;
							}
						} );
					});
				}

			} );

			$scope.activity = results[ 1 ];

			//this is dumb but throw our account into the accounts array for the payamentsystem code
			$scope.accounts = [$scope.account];

			//create message tab after we have an account
			TabManagerService.createTab('message',$scope.account.accountId);

			/*
			 The drawback to including the claim request with the other promises is that a failure of the claim request
			 means the entire account summary page displays the "Account not found" message.  However, since the user
			 was specifically interested in the claim detail, the message is not far off the mark, and the account
			 summary is still accessible by using one of the other account summary routes
			*/
			if( promiseArray.length == 3 ) {
				$scope.claim = results[ 2 ];
			}

			angular.forEach( $scope.account.policies, function ( policy ) {
				policy.account = $scope.account;
				// AccountService.policyNumbersAndEffectiveDatesMatch handles the equivalency check between mPolicy and ICON policy numbers and effective dates
				// Passing dummy effectiveDate from ActivityFeedItem.js and QuickSearchController.js to match 2 year claims route. This call match else if condition.
				if ( $routeParams.policyNumber ) {
					if( $routeParams.effectiveDate && AccountService.policyNumbersAndEffectiveDatesMatch(
									$routeParams.policyNumber, policy.policyNumber,
									$routeParams.effectiveDate, policy.effectiveDate )
					){
						$scope.openPolicy = policy;
					} else if( AccountService.policyNumbersMatch( $routeParams.policyNumber, policy.policyNumber ) ){
						$scope.openPolicy = policy;
					}

				}
			} );
			$scope.policies = $scope.account.policies;

			angular.forEach( $scope.account.submissions, function( submission ) {
				submission.account = $scope.account;
			});
			$scope.submissions = $scope.account.submissions;

			$scope.newSubmission = [];
			for(var i = 0;i < $scope.account.submissions.length;i++){
				if($scope.account.submissions[i].isRenewal && $scope.account.submissions[i].status === "Quoted"){
					$scope.upcomingRenewals.push($scope.account.submissions[i]);
				}
				else{
					$scope.newSubmission.push($scope.account.submissions[i]);
				}
			}

			try {
				var marketedProgramAbbreviation = getAccountMarketedProgramAbbreviation( $scope.account.policies, $scope.account.submissions );
				RecentlyViewedService.addRecentlyViewed( $routeParams.accountId, $scope.account.accountName, marketedProgramAbbreviation, 2 );
			} catch( e ) {
				// ignore any exceptions, this isn't that important anyway
			}


			if( $scope.openPolicy ) {
				/*
				 The presence of both a policyNumber and occurrenceNumber in the route indicates the intention to open
				 a claim detail tab (the claim occurrence being associated with a particular policy).  If only a policy
				 number is in the route, open up the appropriate policy detail tab
				 */
				if( $routeParams.occurrenceNumber ) {
					$scope.openClaimTab( $scope.openPolicy, $routeParams.occurrenceNumber, $scope.claim, false );
				} else {
					$scope.openPolicyTab( $scope.openPolicy.policyNumber, $scope.openPolicy );
				}
			}

			if( $routeParams.messageReasonId ) {
				$scope.openMessageTab();
			}
			
		}, function( error ) {
			if( error.status === 404 ) {
				/*
				If the promise collection failed because the claim occurrence call failed, we want to display
				a different error message
				*/
				if( error.config.url.indexOf( '/claim/' ) > -1 ) {
					$scope.status.claimNotFound = true;
				}
				$scope.status.notFound = true;
			}
			if (error.status === 500) {
				$scope.status.serverDown = true;
			}
		});

		$scope.openMessageTab = function(e){
			TabManagerService.openTab('message',$scope.account.accountId);
		}

		$scope.setMessageView = function(messageView,obj)
		{
			//open message tab and open message
			$scope.openMessageTab();
            $scope.$broadcast('set-message-view',{ view : messageView , obj : obj});
		}

		$scope.close = function( $event, category, identifier ) {
			$event.preventDefault();
			$event.stopPropagation();
			TabManagerService.closeTab( category, identifier );
		};

		$scope.openClaimTab = function( policy, occurrenceNumber, claim, loadClaim ) {
			TabManagerService.openTab( "claims", occurrenceNumber, { policy: policy, occurrenceNumber: occurrenceNumber, claim: claim, loadClaim: loadClaim } );
		};

		$scope.isLossRunLinkVisible = function() {
			let show_loss_run_pdf =  false;
			if(localStorage.getItem('mportal-show-loss-run-pdf'))
			{
				show_loss_run_pdf = localStorage.getItem('mportal-show-loss-run-pdf') === 'true';
			}

			if(show_loss_run_pdf){
				for(var i = 0;i < $scope.account.policies.length; i++){
					if(moment( $scope.account.policies[i].effectiveDate, "MM/DD/YYYY" ).isBefore( Date.now() )) {
						return true;
					}
				}
			}
			return false;
		};

		$scope.isIWantToListVisible = function() {
			return $scope.account.piNumber && $scope.isLossRunLinkVisible();
		}

		$scope.$on('open-message-tab',$scope.openMessageTab);
		
	}

	// private function
	function getAccountMarketedProgramAbbreviation( policies, submissions ){
		var marketedProgramAbbreviation = '';

		// Set the marketedProgramAbbreviation only if its value on all policies and submissions on the account are the same
		if( policies.length > 0 ){
			marketedProgramAbbreviation = policies[0].programAbbreviation
		} else if( submissions.length > 0 ){
			marketedProgramAbbreviation = submissions[0].programAbbreviation
		}

		for( var i = 0; i < policies.length; i++ ){
			if( marketedProgramAbbreviation !== policies[i].programAbbreviation ){
				marketedProgramAbbreviation = '';
				break;
			}
		}
		if( marketedProgramAbbreviation ){
			for( var i = 0; i < submissions.length; i++ ){
				if( marketedProgramAbbreviation !== submissions[i].programAbbreviation ){
					marketedProgramAbbreviation = '';
					break;
				}
			}
		}
		return marketedProgramAbbreviation;
	}

	return AccountSummaryController;

});
