define('common/service/specialtyApplication/ApplicationSection',['require'],function(require) {
	"use strict";

	ApplicationSectionFactory.$inject = [ "ApplicationSectionService" ];

	function ApplicationSectionFactory( ApplicationSectionService ) {

		function ApplicationSection( section, depth, parentNode ) {
			this.depth = depth || 1;
			this.parentNode = parentNode;
			angular.extend( this, section );

			this.sections = [];

			if( section.sections && section.sections.length ) {
				this.addSections( section.sections );
			}
		}

		ApplicationSection.prototype.addSections = function( sections ) {
			for( var i = 0; i < sections.length; i++ ) {
				this.addSection( sections[ i ] );
			}
		};

		ApplicationSection.prototype.addSection = function( rawSection ) {
			var section = new ApplicationSection( rawSection, this.depth + 1, this );
			this.sections.push( section );
			ApplicationSectionService.sortSections( this.sections );

			return section;
		};

		ApplicationSection.prototype.replaceSection = function( rawSection ) {
			angular.merge( this, rawSection );
			this.title = rawSection.title;
		};

		ApplicationSection.prototype.deleteSection = function( section ) {
			var sections = this.sections,
				idx = sections.indexOf( section );

			if( this.type === "enumerationLandingSection"
				&& idx !== -1
				&& sections.length === 1 ){

				// Prep removal of entire enum section instead since section to delete is the remaining child section.
				sections = this.parentNode.sections;
				idx = sections.indexOf( this );
			}

			if( idx !== -1 ) {
				sections.splice( idx, 1 );
			}
		};

		ApplicationSection.prototype.hasChildren = function() {
			return this.sections && this.sections.length;
		};

		ApplicationSection.prototype.isComplete = function() {
			var childrenAreValid = ApplicationSectionService.sectionsAreComplete( this.sections );

			if( this.type === "enumerationLandingSection" ) {
				return childrenAreValid;
			}

			return ApplicationSectionService.datapointsAreValid( this ) && childrenAreValid;
		};

		/*
		 The purpose of this method is to denote whether a particular section is valid based on the validity
		 of its datapoints and the validity of any inline child sections.  Unlike isComplete(), the section
		 should not be marked invalid when a non-inline child section is invalid (the idea being the non-inline
		 child section will be marked as invalid so the user focuses on that section and not its otherwise valid
		 parent).
		*/
		ApplicationSection.prototype.isSectionValid = function() {
			if( this.type === "enumerationLandingSection" ) {
				return ApplicationSectionService.sectionsAreComplete( this.sections );
			} else {
				if( this.sections && this.sections.length ) {
					var inlineChildSections = [];
					angular.forEach( this.sections, function( section ) {
						if( !section.title ) {
							inlineChildSections.push( section );
						}
					});
					return ApplicationSectionService.datapointsAreValid( this ) && ApplicationSectionService.sectionsAreComplete( inlineChildSections );
				} else {
					return ApplicationSectionService.datapointsAreValid( this );
				}
			}
		};

		ApplicationSection.prototype.isVisited = function() {
			var childrenAreVisited = ApplicationSectionService.sectionsAreVisited( this.sections );

			if( this.type === "inline" || !this.visible || this.type === "enumerationLandingSection" ) {
				return childrenAreVisited;
			}

			return this.visited && childrenAreVisited;
		};

		ApplicationSection.prototype.isLastChildOfEnumeration = function() {
			var parent = this.parentNode,
				isChild = false;

			if( parent && parent.sections[ parent.sections.length - 1 ].sectionId !== this.sectionId ) {
				return false;
			}

			return !!this.getEnumeration();
		};

		ApplicationSection.prototype.getEnumeration = function() {
			if( !this.parentNode ) {
				return false;
			}

			var parent = this.parentNode,
				enumeration;

			while( parent ) {
				enumeration = angular.isDefined( parent.enumeration ) && parent;

				if( enumeration ) {
					break;
				}

				parent = parent.parentNode;
			}

			return enumeration;
		};

		ApplicationSection.prototype.findSection = function( sectionId ) {
			var foundSection;

			if( this.sectionId === sectionId ) {
				return this;
			}

			if( this.hasChildren() ) {

				for( var i = 0; i < this.sections.length; i++ ) {
					foundSection = this.sections[ i ].findSection( sectionId );

					if( foundSection ) {
						break;
					}
				}

			}

			return foundSection;
		};

		ApplicationSection.prototype.findDatapointIndex = function ( datapointId ) {
			for ( var i = 0; i < this.datapoints.length; i++ ) {
				if ( this.datapoints[i].data.datapointId === datapointId ) {
					return i;
				}
			}
		};

		ApplicationSection.prototype.addDatapoint = function ( newDatapoint ) {
			this.datapoints.push( newDatapoint );
			ApplicationSectionService.sortDatapoints( this.datapoints );
		};

		ApplicationSection.prototype.replaceDatapoint = function ( newDatapoint ) {
			var existingDatapointIndex = this.findDatapointIndex( newDatapoint.data.datapointId );

			if ( existingDatapointIndex === undefined ) {
				return;
			}

			// reset the options array to the empty array before calling merge so we get a clean set of options.
			this.datapoints[existingDatapointIndex].templateOptions.options = [];

			angular.merge( this.datapoints[existingDatapointIndex], newDatapoint );

			if(newDatapoint.type !== "Typeahead" ){
				this.datapoints[existingDatapointIndex].initialValue = newDatapoint.defaultValue;

                if (typeof this.datapoints[existingDatapointIndex].resetModel === 'function') {
                    this.datapoints[existingDatapointIndex].resetModel();
                }
				
			}
		};

		ApplicationSection.prototype.deleteDatapoint = function ( datapoint ) {
			var existingDatapointIndex = this.findDatapointIndex( datapoint.data.datapointId );

			if ( existingDatapointIndex === undefined ) {
				return;
			}

			this.datapoints.splice( existingDatapointIndex, 1 );
		};

		return ApplicationSection;
	}

	return ApplicationSectionFactory;

});

