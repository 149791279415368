define('components/mvpSwitch/directive/mvpSwitch',['require'],function(require) {
	"use strict";

	mvpSwitch.$inject = [];

	function mvpSwitch() {
		return {
			restrict: "AE",
			templateUrl: "app/components/mvpSwitch/template/template.html",
			controller: "mvpSwitchController"
		};
	}

	return mvpSwitch;

});
