define('common/service/specialtyApplication/SlugParserService',['require'],function(require) {
	"use strict";

	SlugParserService.$inject = ["$http", "archApiUrl"];

	function SlugParserService( $http, archApiUrl ) {

		var service = {};

		service.populateUserProfileFromUrlSlugs = function( userProfile, slugs ) {
			return $http.post( archApiUrl + "portal/program-cobrand", { urlTokens: slugs }).then(function( result ) {
				var data = result.data;

				if( data.marketedProgram ) {
					userProfile.set( "marketedProgram", data.marketedProgram );
				}

				if( data.cobranding ) {
					data.cobranding.states = data.cobranding.agencies.map(function( agency ) {
						return agency.state;
					});

					userProfile.set( "cobranding", data.cobranding );
				}
			});
		};

		return service;

	}

	return SlugParserService;

});
