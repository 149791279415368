define('components/accountSummary/quoteList/controller/QuoteListController',['require'],function(require) {
	"use strict";

	QuoteListController.$inject = ["$scope", "moment"];

	function QuoteListController( $scope, moment ) {

		$scope.uiState = {
			showingExpired: false
		};

		$scope.toggleExpiredVisibility = function() {
			$scope.uiState.showingExpired = !$scope.uiState.showingExpired;
		};

		$scope.isVisible = function( quote ) {
			if( $scope.uiState.showingExpired ) {
				return true;
			}

			// Any quote (expired or not) with a balance or deposit due should always be visible
			return !!quote.balance || !!quote.renewalDepositDue || moment( quote.expirationDate, "MM/DD/YYYY" ).isAfter( moment().subtract(31, 'd'), 'days' );
		};

	}

	return QuoteListController;

});
