define('bootstrap\app',['require','angular','angular-bootstrap','common/config/config','angular-animate','legacyRoutes/app','nav/app','footer/app','shim/overmind','common/service/user/UserService','common/service/user/UserProfile','common/service/util/OvermindHelperService','common/config/routes'],function(require) {
	"use strict";

	require("angular");
	require("angular-bootstrap");
	require("common/config/config");
    require("angular-animate");
    require("legacyRoutes/app");
	require("nav/app");
	require("footer/app");

	var overmind = require("shim/overmind");

	var UserService = require("common/service/user/UserService"),
		UserProfile = require("common/service/user/UserProfile"),
		OvermindHelperService = require("common/service/util/OvermindHelperService"),
		routes = require("common/config/routes");

	overmind.apps = routes;

	overmind["default"] = {
		ngModule: "mPortal.home",
		file: "home/app"
	};

	overmind.requires.push("mPortal.config");

	overmind.factory("UserService", UserService);
	overmind.factory("UserProfile", UserProfile);
	overmind.factory("OvermindHelperService", OvermindHelperService);

	overmind.config(["$routeProvider", function( $routeProvider ) {

		var originalWhen = $routeProvider.when;

		/*
		 Override $routeProvider.when so we can inject an authentication guard on all secure routes.
		 There is an issue with this approach:  in an app.js file called via an Overmind route defined
		 in common/config/routes.js that contains multiple $routeProvider.when statements, the actual
		 route/URL called ends up with the route object of the final "$routeProvider.when" statement,
		 rather than the route object intended to go with/define the desired URL.  At the moment, it is
		 not causing undesired behavior, but it needs to be addressed at some point.
		*/
		$routeProvider.when = function( path, route ) {

			route.resolve = route.resolve || {};

			// add __authGuard to resolve so that we can check a few things before processing the route. there are
			// several checks that take place and we return an early rejection if any of them fail.
			angular.extend( route.resolve, {
				__authGuard: ["$q", "$location", "UserService", function( $q, $location, UserService ) {
					return UserService.getCachedUserProfile().then(function( profile ) {
						//sercurity-check
						// let cookie_arr = document.cookie.split("; ");
						// cookie_arr = cookie_arr.sort();
	
						// let access_token_found = false;
						
						// for(let i = 0; i < cookie_arr.length; i++) 
						// {
						// 	let current_cookie = cookie_arr[i].split("=");
		
						// 	if(current_cookie[0] == "access_token" & !access_token_found)
						// 	{
						// 		access_token_found = true;
						// 		break;
						// 	}
						// }
		
						// if(!access_token_found && profile.type != 'Internal')
						// {
						// 	profile.isLoggedIn = false;
						// }
	

						// first, let's see if the user even has permissions to access the route. there's no point in
						// checking this if we don't have uiPermissions on the profile as it will return false for
						// every route and lock us out.
						if( profile.uiPermissions && !profile.hasRoutePermissions( $location.path() ) ) {
							return $q.reject({ reason: "unauthorized", redirectTo: profile.getDefaultRoute() });
						}

						// if we have a custom guard, execute that instead of the standard behavior
						if( route.guard && typeof route.guard === "function" ) {
							var result = route.guard( profile );

							// the guard can return either a promise or a boolean. if we get a promise, we can just return that..
							if( result.then ) {
								return result;
							}

							// ..otherwise, handle the resolve/reject here with some sane defaults
							return result ? $q.when() : $q.reject({ reason: "unauthorized", redirectTo: profile.getDefaultRoute() });
						}

						// "insecure" is not built in to $routeProvider, it's just a custom property added to the route definition.
						// if our route is insecure (aka anyone can access it) or the user is logged in, let them through. otherwise,
						// rejecting with a reason of notLoggedIn will result in the routeChangeError handler redirecting the user
						// to the login page.
						return ( route.insecure || profile.isLoggedIn ) ? $q.when() : $q.reject({ reason: "notLoggedIn" });

					});
				}]
			});

			// pass it through to the original $routeProvider.when with the injected resolve. originalWhen.call has to be
			// used here so that method chaining doesn't break.
			return originalWhen.call( $routeProvider, path, route );

		};

	}]);

	overmind.run(["$rootScope", "$location", "$window", "OvermindHelperService","ciamApiUrl", function( $rootScope, $location, $window, OvermindHelperService,ciamApiUrl ) {

		angular.bootstrap( document.getElementById("navBar"), ["mPortal.nav"] );
		angular.bootstrap( document.getElementById("footerBar"), ["mPortal.footer"] );

		// in order to handle location changes from legacy apps that are executing outside of an angular digest cycle, we need
		// to manually trigger a digest whenever the hash changes.
		jQuery($window).on("hashchange", function( event ) {
			$rootScope.$evalAsync();
		});

		$rootScope.$on("$routeChangeStart", function( event, next, current) {
			var app;
			var title 	= 'Markel Specialty Online Portal'; 
			
			$('title').text(title);

			if( !next.$$route ) {
				app = OvermindHelperService.getAppByRoute( $location.path(), overmind.apps );

				if( app ) {
					$rootScope.$emit( app.ngModule + ":routeNotFound", event, next, current );	
				}
			}
		});

		$rootScope.$on("$routeChangeError", function( event, next, current, rejection ) {

			// if our authentication guard rejects a route, it will pass a rejection reason of "notLoggedIn", indicating
			// that we should redirect the user to the login page. if we're already on the login page, we can disregard this.
			if( rejection && rejection.reason === "notLoggedIn" ) {

				$rootScope.$evalAsync(function() {
					var target = $location.path();
					var url = ciamApiUrl + 'auth/login-request/mportal';

					if( target !== "/" ) {
						url+="?target_url="+ target;
					}

					window.location = url;
				});

			}

			if( rejection && rejection.reason === "unauthorized" ) {

				$rootScope.$evalAsync(function() {
					$location.path( rejection.redirectTo );
				});

			}

		});

		$rootScope.$on("$routeChangeSuccess", function() {

			var path = $location.path(),
				app = OvermindHelperService.getAppByRoute( path, overmind.apps );

			// if we're moving in to an angular app from one of our legacy routes, we need
			// to clear out #tmplMainContent, which is where superman sticks the content from
			// the legacy applications. our angular apps reside in a different div. we also
			// need a special case for the home page route ("/") because that app doesn't exist
			// in overmind.apps.
			if( ( app && app.ngModule !== "mPortal.legacyRoutes" ) || path === "/" ) {
				jQuery("#tmplMainContent").empty();
			}

			// if we're moving away from quote and we have some appStorage stuff hanging around, go ahead
			// and trash it so it doesn't persist the next time the user hits the quote app.
			if( path !== "/quote" && ( $window.appStorage.curQuote || $window.appStorage.newQuote ) ) {
				delete $window.appStorage.curQuote;
				delete $window.appStorage.newQuote;
			}

		});

	}]);

	angular.element(document).ready(function() {
		angular.bootstrap( document.getElementById("overmind"), ["overmind"] );
	});

});
