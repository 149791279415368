define('components/specialtyApplication/questions/config/widgets/GeneralLiabilityWidget',['require'],function(require) {

    "use strict";

    var widget = {};

    widget.getStructure = function() {

        return {
            "_datapointPlacement": "strictOrder",
            "Jan_GLEnhancement" : { wrapper:  [ "janitorialGLOe" ] , wrapperOverride: true},
            "PC_GLEnhancement" : { wrapper:  [ "pestControlGLOe" ] , wrapperOverride: true},
            "GP_GLEnhancement" : { wrapper:  [ "genPkgGLOe" ] , wrapperOverride: true},
            "CC_GLEnhancement" : { wrapper:  [ "glEnhancement" ] , wrapperOverride: true, data: {
                actions: [
                    {
                        methodName: "setHighlightsDocPath",
                        contexts: [ "update" ],
                        programs: []
                    }
                ]
            }},
            "SC_GLEnhancement" : { wrapper:  [ "glEnhancement" ] , wrapperOverride: true, data: {
                actions: [
                    {
                        methodName: "setHighlightsDocPath",
                        contexts: [ "update" ],
                        programs: []
                    }
                ]
            }},
            "Monument Form" : { wrapper:  [ "MonumentOe" ] , wrapperOverride: true}
         };
    };

    widget.configureFormly = function ( formlyConfig, formlyApiCheck ) {
        formlyConfig.setWrapper({
            name: "MonumentOe",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Markel Commercial Monument Endorsement v3 (1).pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "janitorialGLOe",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Markel Janitorial Services Commercial Liability Enhancement 8.22.18.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "pestControlGLOe",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/Pest Control GL Enhancement v2.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "glEnhancement",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<a target="_blank" style="float:none;margin-left:1%" ng-href="{{options.data.glDoc}}">highlights (pdf)</a>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "genPkgGLOe",
            template:
            '<div class="inlineCheckbox">' +
            '<formly-transclude></formly-transclude>' +
            '<span class="span_link" style="float:none;margin-left:1%" onClick="window.open( \'/assets/documents/GL Standard  Plus Enhancements 5.9.22.pdf\', \'BOPCoverageHighlights\', \'menubar=no;height=500;width=400;scrollbars=yes\' );" >highlights (pdf)</span>' +
            '</div>'
        });

    };

    return widget;

    });
