define('components/policiesSearch/controller/PoliciesSearchController',['require'],function(require) {
	"use strict";

	PoliciesSearchController.$inject = ["$scope", "SearchService", "SearchResultContainer"];

	function PoliciesSearchController( $scope, SearchService, SearchResultContainer ) {

		$scope.searchData = {
			statuses: [],
			programs: []
		};

		$scope.searchParams = {
			insuredName: "",
			status: "All",
			fein: "",
			productLine: "",
			insuredAccount: "",
			policyNumber: "",
			effectiveDateFrom: "",
			effectiveDateTo: ""
		};

		$scope.searchResults = new SearchResultContainer({ currentPage: 1, itemsPerPage: 25 });

		$scope.searchDataResults.then(function( result ) {
			$scope.searchData.programs = [  { name: "--Please select a program--", value: ""},
											{ name: "Workers' Compensation", value: "wc" },
											{ name: "Business Owners", value: "bo" }
			                             ];
			$scope.selectedProgram = $scope.searchData.programs[ 0 ];
			$scope.searchData.statuses = result.policiesStatus;
		});

		$scope.getResults = function() {
			if( $scope.selectedProgram.value == "" ) {
				$scope.searchResults.error.header = "Please select a program.";
			} else {
				$scope.searchParams.productLine = $scope.selectedProgram.value;
				SearchService.getPoliciesSearchResults( $scope.searchParams, $scope.searchResults );
			}
		};

	}

	return PoliciesSearchController;

});
