define('components/mvpSwitch/app',['require','angular','components/mvpSwitch/directive/mvpSwitch','components/mvpSwitch/controller/mvpSwitchController','common/service/user/UserService'],function(require) {
	"use strict";

	// i'm not quite sure why i need to require angular here, but there is an intermittent issue where this file
	// seems to be executed prior to angular being loaded. there's no downside to loading it here as requirejs will
	// only load a given file once anyway.
	require("angular");

	var mvpSwitch 		    = require("components/mvpSwitch/directive/mvpSwitch"),
		mvpSwitchController = require("components/mvpSwitch/controller/mvpSwitchController"),
		UserService 		= require("common/service/user/UserService" );

	var component 			= angular.module("mPortal.components.mvpSwitch", []);

	component.directive("mvpSwitch", mvpSwitch);
	component.controller("mvpSwitchController", mvpSwitchController);
	component.factory('UserService', UserService);

	
	return component;

});

