define('common/service/MessageService',['require'],function(require) {
	"use strict";

	MessageService.$inject = ["$http","$sce", "maverickApiUrl","archApiUrl"];

	function MessageService( $http, $sce, maverickApiUrl , archApiUrl ) {

		var service = {};


		service.getMessage = function(args){
			switch(args.system)
			{
				case 'maverick' :
						return $http.get( maverickApiUrl + "messages/getMessage", {
							params: {
								messageId: args.messageId,
								insuredId: args.insuredId
							}
						}).then(function( result ) {
							return result.data.message;
						});
				break;


				case 'mpolicy' :
					return $http.get( archApiUrl + "messages/getMessage", {
						params: {
							messageId: args.messageId,
							insuredId: args.insuredId
						}
					}).then(function( result ) {
						return result.data.message;
					});
				break;
			}
		};

		service.sendMessage = function( args ){

			switch(args.system)
			{
				case 'maverick' :
					return $http({
									method: "POST",
									url: maverickApiUrl + "/messages/sendMessage", 
									headers: {
										"Content-Type": "application/x-www-form-urlencoded"
									},
									data: $.param({
										entityId		: args.entityId,
										claimId			: args.claimId,
										parentMessageId	: args.parentMessageId,
										uploadDocuments	: args.uploadDocuments,
										message			: args.message,
										subject			: args.subject
									})
								});
				break;


				case 'mpolicy':
				case 'icon' :
					var form_data = new FormData();

					form_data.append('accountId',args.accountId);
					if(args.threadId)form_data.append('threadId', args.threadId);
					form_data.append('submissionId',args.submissionId);
					form_data.append('subject',args.subject);
					form_data.append('message', args.message);
					form_data.append('fromAddress', args.fromAddress);
					if(args.policyNumber)form_data.append('policyNumber', args.policyNumber);
					form_data.append('system', args.system);
					form_data.append('typeId', args.typeId);
					if(args.accountInsuredId)form_data.append('accountInsuredId', args.accountInsuredId);

					if(args.system == 'icon') {
						form_data.append( 'insuredName', args.insuredName );
						form_data.append( 'agencyId', args.agencyId );
						form_data.append( 'programCode', args.programCode );
					}
					for(var i=0; i < args.uploadAttachments.length; i++)
					{
						form_data.append('files', args.uploadAttachments[i]);
					}

					return $http({
									method: "POST",
									url: archApiUrl + "account/" + args.accountId + "/message", 
									headers: {
										"Content-Type": undefined
									},
									data: form_data,
									transformRequest : angular.identity
								});


			}
		};


		service.getThread = function(args){
			return $http.get( archApiUrl + "account/" + args.accountId + "/thread/" + args.threadId + "/" + args.system).then(function( result ) {	

							angular.forEach(result.data.messages,function(message,k){
								message['datePosted'] = new Date(message['datePosted'].split('.')[0].replaceAll('-','/'));
								message['message'] 	  = message['message'].replace(new RegExp('\r?\n','g'), '<br />') ;

								result.data.messages[k] = message;
							});

						return result.data.messages;
					});
	
		};


		service.getThreads = function(args){
		
			return $http.get( archApiUrl + "account/" + args.accountId + "/message-thread/" + args.system ).then(function( result ) {
							angular.forEach(result.data.threads,function(thread,k){

								thread['datePosted'] 		= thread['datePosted'] ? new Date(thread['datePosted'].split('.')[0].replaceAll('-','/')) : '';
								thread['recentDatePosted']  = thread['recentDatePosted'] ? new Date(thread['recentDatePosted'].split('.')[0].replaceAll('-','/')) : '';
								thread['lineOfBusiness'] 	= (thread['lineOfBusinesses'].length > 1)?thread['lineOfBusinesses'].join(' ') : thread['lineOfBusinesses'] = thread['lineOfBusinesses'][0];
								thread['messages'] 			= [];
								thread['form']				= { message : '',upload:[],fileNames:[]};

								result.data.threads[k] 		= thread;
							});

						return result.data.threads;
					});
		};

		service.markRead = function(args){
			return $http({
							method: "POST",
 							url: archApiUrl + "account/" + args.accountId + "/thread/" + args.threadId + "/changeReadStatus", 
							headers: {
								"Content-Type": "application/x-www-form-urlencoded"
							},
							data: $.param({
								accountId		: args.accountId,
								threadId		: args.threadId,
								readStatus		: true,
								system			: args.system								
							})
						});
		};

		service.forwardMessage = function(args){
			return $http({
							method: "POST",
							url: archApiUrl + "account/" + args.accountId + "/thread/" + args.threadId + "/forwardMessage", 
							headers: {
								"Content-Type": "application/x-www-form-urlencoded"
							},
							data: $.param({
								accountId		: args.accountId,
								threadId		: args.threadId,
								fromEmail		: args.fromEmail,
								forwardToEmail	: args.forwardToEmail,
								system			: args.system								
							})
						});
		};

		service.getMessagePDF = function(args){
				return $http({
								method: "POST",
								url: archApiUrl + "account/" + args.accountId + "/thread/" + args.threadId + "/pdf/" + args.system,
								headers: {
									"Content-Type": "application/x-www-form-urlencoded"
								},
								data: $.param({
									accountId		: args.accountId,
									threadId		: args.threadId							
								})
							});
		};



		return service;

	}

	return MessageService;

});
