/**
* This is a polyfill for angular.merge, which was implemented in Angular 1.4. Source is basically a direct copy from the 1.4 source,
* but with the isArray, isDate, etc. function calls replaced with calls to built in Angular methods. The only one that does not exist
* in 1.2.x is isRegExp, which is also implemented here.
*
* https://github.com/angular/angular.js/blob/v1.4.9/src/Angular.js#L348
*/
define('angular-merge-polyfill',['require','angular'],function( require ) {
	var angular = require("angular");

	angular = angular || window.angular;
	angular.merge = angular.merge || merge;

	function merge( dst ) {
		return baseExtend( dst, [].slice.call( arguments, 1 ), true );
	}

	function baseExtend(dst, objs, deep) {
		var h = dst.$$hashKey;

		for (var i = 0, ii = objs.length; i < ii; ++i) {
			var obj = objs[i];
			if (!angular.isObject(obj) && !angular.isFunction(obj)) continue;
			var keys = Object.keys(obj);
			for (var j = 0, jj = keys.length; j < jj; j++) {
				var key = keys[j];
				var src = obj[key];

				if (deep && angular.isObject(src)) {
					if (angular.isDate(src)) {
						dst[key] = new Date(src.valueOf());
					} else if (isRegExp(src)) {
						dst[key] = new RegExp(src);
					} else if (src.nodeName) {
						dst[key] = src.cloneNode(true);
					} else if (angular.isElement(src)) {
						dst[key] = src.clone();
					} else {
					if (!angular.isObject(dst[key])) dst[key] = angular.isArray(src) ? [] : {};
						baseExtend(dst[key], [src], true);
					}
				} else {
					dst[key] = src;
				}
			}
		}

		setHashKey(dst, h);
		return dst;
	}

	function isRegExp(value) {
		return Object.prototype.toString.call(value) === '[object RegExp]';
	}

	function setHashKey(obj, h) {
		if (h) {
			obj.$$hashKey = h;
		} else {
			delete obj.$$hashKey;
		}
	}
});
