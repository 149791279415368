define( 'components/specialtyApplication/resultSummary/controller/ResultSummaryController',[],function () {

	ResultSummaryController.$inject = [
		"$scope",
		"$location",
		"SubmissionStore",
		"ResultService",
		"TeaLeafService",
		"AccountService",
		"UserProfile",
		"$routeParams",
		"$q"
	];

	function ResultSummaryController(
		$scope,
	    $location,
		SubmissionStore,
	    ResultService,
	    TeaLeafService,
	    AccountService,
	    UserProfile,
	    $routeParams,
	    $q
	) {

		$scope.uiState = {
			loading: true,
			requestError: false,
			visibleApplicationQuote: 0,
			showSummary: $routeParams.accountId || $scope.applicationId ? false : true,
			full_width : false,
			result_summary : false
		};
		$scope.accountId = $routeParams.accountId;
		$scope.userType = UserProfile.type;
		$scope.outcomes = [];

		$scope.getOutcomes = function() {

			if($routeParams.insuredId)
			{
				AccountService.getAccountSummary( $routeParams.insuredId ).then(function(result){
					let promise_arr = [];

					for(let i= 0 ; i < result.submissions.length; i++)
					{
						let submission = result.submissions[i];
						
						promise_arr.push(ResultService.getStatus( submission.applicationId ));
					}

					
					$q.all( promise_arr ).then( function( promise_results ) {

						for(let i = 0; i < promise_results.length; i++ )
						{
							let current_result = promise_results[ i ].data[0];
							$scope.outcomes.push( $scope.parseOutcome( current_result ) );
						}

						//show the first quote
						$scope.uiState.visibleApplicationQuote 	= $scope.outcomes[0].applicationId;
						$scope.uiState.loading 					= false;
						$scope.uiState.full_width 				= true;
						$scope.uiState.result_summary 			= true;
					});

				});

				return;
			}
		};

		$scope.parseOutcome = function( outcome ) {
			var quoteStatus;
			switch( outcome.masterquote.phase ) {
				case 25:
					quoteStatus = outcome.masterquoteinstance.paymentProcessed ? "Policy" : "Quote";
					break;
				default:
					quoteStatus = ResultService.getResultType( outcome.masterquote.portalStatus );
					break;
			}
			return {
				accountId: outcome.masterquote.masterquoteid.split("-")[0],
				accountName: outcome.insured.name,
				agentCanEdit: outcome.agentCanEdit,
				applicationId: outcome.masterquoteinstance.applicationid,
				effectiveDate: outcome.masterquote.effdate,
				luxon:{
					luxon_id : outcome.masterquote.luxonId,
					luxon_partition_key : outcome.masterquote.luxonPartitionKey
				},
				marketedProgram: {
					id: outcome.marketedProgram.value,
					name: outcome.marketedProgram.name,
					abbreviation: outcome.marketedProgram.abbreviation,
					isUploadBasedSubmission : outcome.marketedProgram.isUploadBasedSubmission
				},
				portalStatus: outcome.masterquote.portalStatus,
				premium: quoteStatus == "Quote" ? outcome.masterquoteinstance.writtentotal : null,
				quoteStatus: quoteStatus,
				result: outcome,
				responseStatus: outcome.status,
				submissionId: outcome.masterquote.masterquoteid
			}
		};

		$scope.showApplicationQuote = function( applicationId ) {
			$scope.uiState.visibleApplicationQuote = applicationId;
		};

		$scope.hideApplicationQuote = function( applicationId ) {
			$scope.uiState.visibleApplicationQuote = -1;
		};

		$scope.messageUW = function( accountId, submissionId , isApplicationUpload ) {
			var url = "/account-summary/" + accountId + "/new-message/" + submissionId;

			if(isApplicationUpload)
			{
				url += "/application"
			}

			$location.path( url );
		};

		$scope.getOutcomes();

	}

	return ResultSummaryController;

} );
