define('common/service/activityFeed/ActivityFeedConfig',['require'],function(require) {
	"use strict";

	function ActivityFeedConfig() {

		var service = {};

		service.maxRows = 30;

		service.filters = {
			keyword: null,
			documents: true,
			statusChange: true,
			messages: true,
			claims: true,
			notifications: true
		};

		service.showAll = function() {
			return service.filters.documents &&
					service.filters.statusChange &&
					service.filters.messages &&
					service.filters.claims &&
					service.filters.notifications &&
					(service.filters.keyword === null || service.filters.keyword === "");
		};

		return service;

	}

	return ActivityFeedConfig;

});
