define('components/quickLinks/controller/QuickLinksController',['require'],function(require) {
	"use strict";

	QuickLinksController.$inject = ["$scope", "$modal", "UserProfile", "maverickApiUrl","$window", "ciamDomain"];

	function QuickLinksController( $scope, $modal, UserProfile, maverickApiUrl, $window, ciamDomain ) {

		$scope.user = UserProfile;
		$scope.maverickApiUrl = maverickApiUrl;

		$scope.openMarkelContacts = function() {
			window.open('https://www.markel.com/us/contact-search?state=' + UserProfile.defaultState);
		};

		$scope.addNewUser = function() {
			var url = ciamDomain + "?prompt=login#register";
			window.open(url);
		};

		$scope.openQuickEndorsement = function() {
			$modal.open({
				templateUrl: "app/components/quickLinks/template/quickEndorsement.html",
				controller: "QuickEndorsementController",
				windowClass: "quickEndorsementModal"
			});
		};

		$scope.openMassSubmission = function() {
			$modal.open({
				templateUrl: "app/components/quickLinks/template/massSubmission.html",
				controller: "MassSubmissionController",
				windowClass: "massSubmissionModal"
			});
		};

		$scope.openLossRunsReport = function() {
			$modal.open({
				templateUrl: "app/components/quickLinks/template/lossRuns.html",
				controller: "GenericModalController",
				windowClass: "lossRunModal"
			});
		};

		$scope.openCommissions = function() {
			$modal.open({
				templateUrl: "app/components/quickLinks/template/commissions.html",
				controller: "CommissionsController",
				windowClass: "commissionsModal"
			});
		};

		$scope.openAgencyBillingDocuments = function() {
			$modal.open({
				templateUrl: "app/components/quickLinks/template/agencyBillingDocuments.html",
				controller: "BillingDocumentsController",
				windowClass: "billingDocumentModal"
			});
		};

		$scope.openMakePayment = function() {
            var modalInstance = $modal.open({
                templateUrl: "app/components/quickLinks/template/makePayment.html",
                controller: "MakePaymentController",
                windowClass: "makePaymentModal"
            });

        };
    }

    return QuickLinksController;
});
