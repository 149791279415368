define('common/service/specialtyApplication/ApplicationSectionConfiguration',['require','components/specialtyApplication/questions/config/widgets/PolicyInformationWidget','components/specialtyApplication/questions/config/widgets/OptionalEndorsementsWidget','components/specialtyApplication/questions/config/widgets/LocationInformationWidget','components/specialtyApplication/questions/config/widgets/LocationWidget','components/specialtyApplication/questions/config/widgets/BuildingOEsandAIsWidget','components/specialtyApplication/questions/config/widgets/BuildingWidget','components/specialtyApplication/questions/config/widgets/WindStorm','components/specialtyApplication/questions/config/widgets/AdditionalInformationWidget','components/specialtyApplication/questions/config/widgets/UnderwriterQuestionsWidget','components/specialtyApplication/questions/config/widgets/RiskManagementWidget','components/specialtyApplication/questions/config/widgets/SignatureWidget','components/specialtyApplication/questions/config/widgets/GeneralLiabilityWidget','components/specialtyApplication/questions/config/widgets/PropertyWidget','components/specialtyApplication/questions/config/widgets/UploadDocumentsWidget','components/specialtyApplication/questions/config/widgets/KeyCharacteristicsWidget','components/specialtyApplication/questions/config/widgets/AutoWidget','components/specialtyApplication/questions/config/widgets/EligibilityInformationWidget','components/specialtyApplication/questions/config/widgets/HorseSummaryWidget','components/specialtyApplication/questions/config/widgets/OperationsWidget','components/specialtyApplication/questions/config/widgets/InlandMarineWidget','components/specialtyApplication/questions/config/widgets/FineArtsWidget','components/specialtyApplication/questions/config/widgets/ProgramEligibilityWidget'],function (require) {
    'use strict';

    // Use require statements below this comment to pull in any widget configurations
    var PolicyInformationWidget     = require("components/specialtyApplication/questions/config/widgets/PolicyInformationWidget");
    var OptionalEndorsementsWidget  = require("components/specialtyApplication/questions/config/widgets/OptionalEndorsementsWidget");
    var LocationInformationWidget   = require("components/specialtyApplication/questions/config/widgets/LocationInformationWidget");
    var LocationWidget              = require("components/specialtyApplication/questions/config/widgets/LocationWidget");
    var BuildingOEsandAIsWidget     = require("components/specialtyApplication/questions/config/widgets/BuildingOEsandAIsWidget");
    var BuildingWidget              = require("components/specialtyApplication/questions/config/widgets/BuildingWidget");
    var WindStormWidget             = require("components/specialtyApplication/questions/config/widgets/WindStorm");
    var AdditionalInformationWidget = require("components/specialtyApplication/questions/config/widgets/AdditionalInformationWidget");
    var UnderwriterQuestionsWidget  = require("components/specialtyApplication/questions/config/widgets/UnderwriterQuestionsWidget");
    var RiskManagementWidget        = require("components/specialtyApplication/questions/config/widgets/RiskManagementWidget");
    var SignatureWidget             = require("components/specialtyApplication/questions/config/widgets/SignatureWidget");
    var GeneralLiabilityWidget      = require("components/specialtyApplication/questions/config/widgets/GeneralLiabilityWidget");
    var PropertyWidget              = require("components/specialtyApplication/questions/config/widgets/PropertyWidget");
    var UploadDocumentsWidget       = require("components/specialtyApplication/questions/config/widgets/UploadDocumentsWidget");
    var KeyCharacteristicsWidget    = require("components/specialtyApplication/questions/config/widgets/KeyCharacteristicsWidget");
    var AutoWidget                  = require("components/specialtyApplication/questions/config/widgets/AutoWidget");
	var EligibilityInformation      = require("components/specialtyApplication/questions/config/widgets/EligibilityInformationWidget");
    var HorseSummaryWidget          = require("components/specialtyApplication/questions/config/widgets/HorseSummaryWidget");
    var OperationsWidget            = require("components/specialtyApplication/questions/config/widgets/OperationsWidget");
    var InlandMarineWidget          = require("components/specialtyApplication/questions/config/widgets/InlandMarineWidget");
    var FineArtsWidget              = require("components/specialtyApplication/questions/config/widgets/FineArtsWidget");
    var ProgramEligibilityWidget    = require("components/specialtyApplication/questions/config/widgets/ProgramEligibilityWidget");

    function ApplicationSectionConfiguration() {

        // Populate the sectionConfigs object with key/values pairs where the key reflects the section widget reference (not the name)
        // and the value is the configuration object returned by the corresponding widget's getStructure() method
        var sectionConfigs = {
            "Policy Information"            : PolicyInformationWidget.getStructure(),
            "Location Information"          : LocationInformationWidget.getStructure(),
            "Location"                      : LocationWidget.getStructure(),
            "bop_PolicyOEsAndAIs"           : OptionalEndorsementsWidget.getStructure(),
            "bop_BuildingOEsAndAIs"         : BuildingOEsandAIsWidget.getStructure(),
            "Building"                      : BuildingWidget.getStructure(),
            "bop_WindstormCoverage"         : WindStormWidget.getStructure(),
            "Additional Information"        : AdditionalInformationWidget.getStructure(),
            "wcBop_UnderwriterQuestions"    : UnderwriterQuestionsWidget.getStructure(),
            "Risk Management"               : RiskManagementWidget.getStructure(),
            "Signature"                     : SignatureWidget.getStructure(),
            "GeneralLiability"              : GeneralLiabilityWidget.getStructure(),
            "Property"                      : PropertyWidget.getStructure(),
            "uploadDocuments"               : UploadDocumentsWidget.getStructure(),
            "Key characteristics"           : KeyCharacteristicsWidget.getStructure(),
            "Business auto"                 : AutoWidget.getStructure(),
            "Eligibility Information"       : EligibilityInformation.getStructure(),
            "Horse Summary"                 : HorseSummaryWidget.getStructure(),
            "Host Shows"                    : OperationsWidget.getStructure(),
            "Misc Articles"                 : InlandMarineWidget.getStructure(),
            "InlandMarine"                  : FineArtsWidget.getStructure(),
            "Program eligibility"           : ProgramEligibilityWidget.getStructure(),
            "Operations"                    : OperationsWidget.getStructure()
            };

        return sectionConfigs;
    }

    return ApplicationSectionConfiguration;
});

