define('components/widget/optional-endorsements/additional-information-location/directive/additionalInformationLocation',['require'],function(require) {
	"use strict";

	additionalInformationLocation.$inject = [];

	function additionalInformationLocation() {
		return {
			restrict: "AE",
			scope:{
				model	            : "=",
				options             : "=",
				header               : "=",
				locationControlId   : "="
			},
			templateUrl: "app/components/widget/optional-endorsements/additional-information-location/template/template.html",
			controller: "AdditionalInformationLocationController"
		};
	}

	return additionalInformationLocation;

});
