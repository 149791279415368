define('components/classCodesSearch/directive/classCodesSearch',['require'],function(require) {
	"use strict";

	function ClassCodesSearch() {
		return {
			restrict: "AE",
			scope: {
				searchDataResults: "="
			},
			templateUrl: "app/components/classCodesSearch/template/template.html",
			controller: "ClassCodesSearchController"
		};
	}

	return ClassCodesSearch;

});
