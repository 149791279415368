define( 'components/claimDetail/claimDetail/directive/claimDetail',['require'],function( require ) {
    "use strict";

    /*
     Even though the claim object provided to the directive has a policyNumber property, an ICON claim can have
     a slightly different policy number value than the corresponding policy object instance in the relevant
     account (because the term suffix in ICON policy numbers do not have leading zeros).  So we want inherit
     the number from the matching account policy instance

     And the loadClaim boolean value determines whether the controller will make a REST call to get a full/refreshed
     claim object.
    */
    function ClaimDetail() {
        return {
            restrict: "AE",
            scope: {
                policyNumber: "=",
                occurrenceNumber: "=",
                claim: "=",
                loadClaim: "=",
                openClaimDetail: "="
            },
            templateUrl: "app/components/claimDetail/claimDetail/template/template.html",
            controller: "ClaimDetailController"
        };
    }

    return ClaimDetail;

});
