define('components/documentDeliveryPreferencesLink/app',['require','components/documentDeliveryPreferences/app','components/documentDeliveryPreferencesLink/directive/documentDeliveryPreferencesLink','components/documentDeliveryPreferencesLink/controller/DocumentDeliveryPreferencesLinkController','components/documentDeliveryPreferencesLink/controller/DocumentDeliveryPreferencesModalController','common/controller/GenericModalController','common/service/specialtyApplication/ResultService','common/service/user/UserProfile'],function(require)  {
    "use strict";

    require("components/documentDeliveryPreferences/app");

    var documentDeliveryPreferencesLink = require("components/documentDeliveryPreferencesLink/directive/documentDeliveryPreferencesLink"),
        DocumentDeliveryPreferencesLinkController = require("components/documentDeliveryPreferencesLink/controller/DocumentDeliveryPreferencesLinkController"),
        DocumentDeliveryPreferencesModalController = require("components/documentDeliveryPreferencesLink/controller/DocumentDeliveryPreferencesModalController"),

        GenericModalController = require("common/controller/GenericModalController"),

        ResultService = require("common/service/specialtyApplication/ResultService"),
        UserProfile = require( "common/service/user/UserProfile" );

    var component = angular.module("mPortal.documentDeliveryPreferencesLink", [
        "ui.bootstrap",
        "mPortal.config",
        "mPortal.components.documentDeliveryPreferences"
    ]);

    component.directive("documentDeliveryPreferencesLink", documentDeliveryPreferencesLink);
    component.controller("DocumentDeliveryPreferencesLinkController", DocumentDeliveryPreferencesLinkController);
    component.controller("DocumentDeliveryPreferencesModalController", DocumentDeliveryPreferencesModalController);
    component.controller("GenericModalController", GenericModalController);

    component.factory( "ResultService", ResultService );
    component.factory( "UserProfile", UserProfile );

    return component;
});
