define('common/directive/autocomplete',['require'],function(require) {
	"use strict";

	// override renderMenu, resizeMenu and renderItem so that we have the option to use custom render methods from the directive. if a custom method is not passed,
	// the original will be called.
	var originalRenderMenu = jQuery.ui.autocomplete.prototype._renderMenu,
		originalResizeMenu = jQuery.ui.autocomplete.prototype._resizeMenu,
		originalRenderItem = jQuery.ui.autocomplete.prototype._renderItem;

	jQuery.ui.autocomplete.prototype._renderMenu = function( ul, item ) {
		return angular.isFunction( this.options.renderMenu ) ? this.options.renderMenu( this, ul, item ) : originalRenderMenu.apply( this, arguments );
	};

	jQuery.ui.autocomplete.prototype._resizeMenu = function( ) {
		return angular.isFunction( this.options.resizeMenu ) ? this.options.resizeMenu( this ) : originalResizeMenu.apply( this );
	};

	jQuery.ui.autocomplete.prototype._renderItem = function( ul, item ) {
		return angular.isFunction( this.options.renderItem ) ? this.options.renderItem( this, ul, item ) : originalRenderItem.apply( this, arguments );
	};

	function AutoComplete() {
		return {
			restrict: "A",
			scope: {
				appendTo: "=autocompleteAppendTo",
				autoFocus: "=autocompleteAutoFocus",
				delay: "=autocompleteDelay",
				disabled: "=autocompleteDisabled",
				focus: "=autocompleteFocus",
				minLength: "=autocompleteMinLength",
				position: "=autocompletePosition",
				source: "=autocompleteSource",
				html: "=autocompleteHtml",
				select: "=?autocompleteSelect",
				renderMenu: "=autocompleteRenderMenu",
				resizeMenu: "=autocompleteResizeMenu",
				renderItem: "=autocompleteRenderItem",
				ngModel: "=ngModel"
			},
			link: function( scope, element, attrs ) {

				var options = {},
					watcher;

				// if a custom select method is passed in, use that. otherwise, use a sane default.
				scope.select = scope.select || function( event, selected ) {
					scope.$apply(function() {
						scope.ngModel = selected.item;
					});
				};

				options.appendTo = scope.appendTo;
				options.autoFocus = scope.autoFocus;
				options.delay = scope.delay;
				options.disabled = scope.disabled;
				options.focus = scope.focus;
				options.minLength = scope.minLength;
				options.position = scope.position;
				options.source = scope.source;
				options.html = scope.html;
				options.select = scope.select;
				options.renderMenu = scope.renderMenu;
				options.resizeMenu = scope.resizeMenu;
				options.renderItem = scope.renderItem;

				if(attrs.autocompleteAppendTo)
				{
					options.appendTo = '.' + attrs.autocompleteAppendTo;
				}

				if( angular.isFunction( options.source ) ) {

					var el = $(element).autocomplete( options );

				} else {

					watcher = scope.$watch("source", function() {
						if( scope.source && scope.source.length ) {
							// deregister the watcher now that our source has been loaded
							watcher();
							options.source = scope.source;

							//this is a stupid hack to throw on our custom class for a menu
							//blah
							var el = $(element).autocomplete( options );

							if(attrs.autocompleteClasses)
							{
								var ac = $(el).data()['autocomplete'];

								var menu = $(ac.menu.element[0]);
								menu.addClass(attrs.autocompleteClasses);
								menu.width($(el).width());
							}
						}
					});

				}

			}
		};
	}

	return AutoComplete;

});
