define('components/claimsSearch/directive/claimsSearch',['require'],function(require) {
	"use strict";

	function ClaimsSearch() {
		return {
			restrict: "AE",
			scope: {
				searchDataResults: "=",
				user: "="
			},
			templateUrl: "app/components/claimsSearch/template/template.html",
			controller: "ClaimsSearchController"
		};
	}

	return ClaimsSearch;

});
