define('components/message/list/controller/forwardModalController',['require'],function(require) {
	"use strict";

	forwardModalController.$inject = ["$scope", "$modalInstance", "$sce", "$window", "$modal", "$location", "MessageService" , "thread" , "account" , "user"];

	function forwardModalController( $scope, $modalInstance, $sce, $window, $modal, $location, MessageService , thread , account , user ) {
		$scope.thread	= thread;
		$scope.account	= account;
		$scope.user		= user;
		$scope.btn		= null;

		$scope.errors = {
			visible : false,
			email	: false
		};


		$scope.form = {
			email: ""
		};

		$scope.status = {
			sending: false
		};

		$scope.close = $modalInstance.close;



		
		$scope.validate = function(){
			$scope.errors = {
				visible : false,
				email	: !$scope.form.email,
				invalid	: !$.rexUtil.validateEmail($scope.form.email)
			};

			angular.forEach($scope.errors,function(error,key){
				if(key != 'visible' &&  error && !$scope.errors.visible)$scope.errors.visible = true;
			});
		}

		$scope.send = function($event){

			$scope.btn = angular.element($event.currentTarget);

			$scope.validate();

			if($scope.errors.visible)return;

			if(!$scope.status.sending)
			{
				$scope.status.sending = true;	
				$scope.btn.attr('disabled',true);

				MessageService.forwardMessage({
					system 			: ($scope.account.accountId == $scope.account.piNumber)? 'icon' : 'mpolicy',
					threadId 		: $scope.thread.threadId,
					accountId		: $scope.account.accountId,
					fromEmail		: $scope.user.email,
					forwardToEmail	: $scope.form.email
				}).then(function( response ) {
					
					$modal.open({
						templateUrl: "app/components/message/modal/template/sent.html",
						controller: "GenericModalController"
					});

					$scope.close();

					$scope.status.sending = false;
					$scope.btn.attr('disabled',false);
				});
			}

		
		}
	}

	return forwardModalController;

});


