define( 'components/claimDetail/claimDetail/controller/ClaimDetailController',['require'],function( require ) {
    "use strict";

    ClaimDetailController.$inject = [ "$scope", "AccountService" ];

    function ClaimDetailController( $scope, AccountService ) {

        $scope.uiState = {
            loading: true,
            error: false
        };

        /*
         This controller can be invoked as the result of a route navigation via the AccountSummaryController,
         which itself has to retrieve the full claim occurrence as part of its routing logic, or as a result
         of a click event in the policyClaims component, which only has a partial claim record to work with.
         So in the latter case, the loadClaim variable passed in via the directive will be set to true,
         triggering the REST call to get the full claim occurrence record.
        */
        if( $scope.loadClaim ) {
            AccountService.getClaimOccurrence( $scope.occurrenceNumber ).then( function( result ) {
                $scope.claim = result;
            }).catch(function( err ) {
                $scope.uiState.error = true;
            }).finally(function() {
                $scope.uiState.loading = false;
            });
        } else {
            $scope.uiState.loading = false;
        }
    }

    return ClaimDetailController;

});
