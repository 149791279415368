define('common/directive/selectAllChecks',['require'],function(require) {
	"use strict";

	function SelectAllChecks() {
		return {
			restrict: "A",
			scope: {
				// the source array that needs its values toggled
				source: "=",
				// if the source is an array of objects, the key in the object that needs to be toggled
				key: "@"
			},
			link: function( scope, ele, attrs ) {
				$(ele).on("change", function( event ) {

					var checked = this.checked;

					scope.$apply(function() {
						angular.forEach( scope.source, function( item, index ) {

							if( scope.key ) {
								item[ scope.key ] = checked;
							} else {
								scope.source[ index ] = checked;
							}

						});
					});

				});
			}
		};
	}

	return SelectAllChecks;

});
