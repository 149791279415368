define('common/service/SubmissionDataService',['require'],function(require) {
	"use strict";

	SubmissionDataService.$inject = ["$http","$sce","archApiUrl"];

	function SubmissionDataService( $http, $sce, archApiUrl ) {

		var service = {};

		service.getCoverages = function(args)
		{
				return $http({
									method: "POST",
									url:  archApiUrl + "submission-data/coverages/", 
									headers: {
										"Content-Type": "application/x-www-form-urlencoded"
									},
									data: $.param({
										packageNames		: args.packageNames
									})
						}).then(function(result){
									return result.data;
								});
		};

		return service;

	}

	return SubmissionDataService;

});
