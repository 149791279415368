define('common/service/util/RenameKeys',['require'],function(require) {
	"use strict";

	RenameKeys.$inject = [];

	function RenameKeys() {

		var service = {};

		service.rename = function( obj, keyTransforms ) {

			var renamed = angular.extend( {}, obj );

			if( !angular.isArray( keyTransforms ) ) {
				return renamed;
			}

			angular.forEach( keyTransforms, function( key ) {

				if( renamed.hasOwnProperty( key.from ) ) {
					renamed[ key.to ] = renamed[ key.from ];
					delete renamed[ key.from ];
				}

			});

			return renamed;

		};

		service.renameAll = function( obj, transform ) {

			var renamed = angular.extend( {}, obj );

			if( !angular.isFunction( transform ) ) {
				return renamed;
			}

			angular.forEach( obj, function( value, key ) {
				renamed[ transform( key ) ] = renamed[ key ];
				delete renamed[ key ];
			});

			return renamed;

		};

		return service;

	}

	return RenameKeys;

});
