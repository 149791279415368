define('components/quoteWizard/app',['require','components/quoteWizard/directive/quoteWizard','components/quoteWizard/controller/quoteWizardController','common/service/MarketedProgramService','common/service/wizard/Wizard','moment'],function(require) {
	"use strict";


	var quoteWizard 				= require("components/quoteWizard/directive/quoteWizard"),
		quoteWizardController 		= require("components/quoteWizard/controller/quoteWizardController"),
		MarketedProgramService		= require("common/service/MarketedProgramService"),
		Wizard 						= require("common/service/wizard/Wizard" ),
		moment 						= require("moment");

	var component 					= angular.module("mPortal.components.quoteWizard", []);

	component.directive("quoteWizard", quoteWizard);
	component.controller("quoteWizardController", quoteWizardController);
	component.factory('MarketedProgramService', MarketedProgramService);
	component.factory('Wizard',Wizard);
	component.value("moment", moment);
	
	return component;

});
