define('components/filterableDocumentList/controller/FilterableDocumentListController',['require'],function(require) {
	"use strict";

	FilterableDocumentList.$inject = ["$scope"];

	function FilterableDocumentList( $scope ) {

		$scope.filter = {
			document: ""
		};

		$scope.filterDocuments = function( doc ) {
			
			if( !$scope.filter.document ) {
				return true;
			}

			var description = doc.description.toLowerCase(),
				filterValue = $scope.filter.document.toLowerCase();

			return description.indexOf( filterValue ) !== -1;

		};

	}

	return FilterableDocumentList;

});
