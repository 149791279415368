define('components/quickLinks/controller/QuickEndorsementController',['require'],function(require) {
	"use strict";

	QuickEndorsementController.$inject = ["$scope", "$modalInstance", "$location", "SearchService", "moment"];

	function QuickEndorsementController( $scope, $modalInstance, $location, SearchService, moment ) {

		$scope.close = $modalInstance.close;

		$scope.select = function( event, selected ) {
			/*
			 Parse the value of the selected item to verify the type of record and to extract the ID. The parsing
			 logic depends on whether the policy is a Maverick or mPolicy policy
			*/
			var valueTypeIndex = selected.item.productLine == 0 ? 1 : 0,
				itemParts = selected.item.value.split("_"),
				type = itemParts[ valueTypeIndex ],
				policyId = selected.item.IPI_PolicyNumber,
				insuredId = selected.item.II_ID,
				reason = "endorsement";


			$scope.close();

			if( type === "policy" || type === "quote" ) {
				/*
				 mPolicy online endorsement behavior is controlled by whether or not the associated marketed program
				 allows for online endorsements. The redirect that occurs when a Maverick policy is selected is based
				 strictly on the product line.
				 */
				if( selected.item.productLine == 0 ) {
					if( selected.item.marketedProgram && selected.item.marketedProgram.areOnlineEndorsementsAllowed ) {
						// mPolicy online endorsement mechanisms TBD
						$location.path("/account-summary/" + insuredId + "/new-message/" + policyId + "/reason/" + reason);
					} else {
						/*
						 In the test environments, the Solr data can be stale, leading to search results that do not
						 have a insured ID (II_ID property).  This insuredId check prevents us from trying to navigate
						 to an invalid route (causing an error modal) in that scenario.

						 You can examine the REST response data to see if you have encountered that scenario.
						*/
						if( insuredId ) {
							$location.path( "account-summary/" + insuredId + "/new-message/" + policyId );
						}
					}
				} else {
					if( selected.item.productLine == 1 ) {
						if(itemParts.length ==2){
							policyId = itemParts[1];
						}						
						$location.path( "account/policy/" + policyId + "/endorsement/" );
					} else {
						if( insuredId ) {

							$location.path( "account/message/new/" + insuredId + "/quote-" + policyId );
						}
					}
				}

			}

		};

		$scope.lookup = function( request, callback ) {
			
			SearchService.getQuickSearchResults({
				q: request.term,
				solrEntities: "policy",
				minimumEffectiveDate: moment( new Date() ).subtract( 1, "y" ).format( "YYYY-MM-DD" )
			}).then( function( data ) {
				/*
				 In the test environments, the Solr data can be stale, leading to search results where policies
				 are still marked as quotes (based on the value string). The following logic will remove results
				 still marked as quotes from the choices shown to the user, and will display a single "no results
				 found" message if we end up with no policy results.

				 So if you are expecting a particular search result to be displayed but it doesn't show up, look
				 at the response of the network request and see if it appears in the REST response as something other
				 than a policy.
				*/
				
				var filteredResults = [];
				angular.forEach( data, function( item ) {
				 	var valueTypeIndex = item.productLine == 0 ? 1 : 0;
				 	if( item.value == "" || item.value.split("_")[ valueTypeIndex ] == "policy" || item.value.split("_")[ valueTypeIndex ] == "quote" ) {
						filteredResults.push( item );
				 	}
				});
				if( filteredResults.length === 0 ) {
					filteredResults.push(
						{
							category: "No results found.",
							value: "",
							label: "No relevant results determined, please alter your search criteria."
						}
					)
				}
				callback( filteredResults );

			} );

		};

	}

	return QuickEndorsementController;

});
