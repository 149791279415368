define('components/newsHeadlines/directive/newsHeadlines',['require'],function(require) {
	"use strict";

	NewsHeadlines.$inject = [];

	function NewsHeadlines() {
		return {
			restrict: "AE",
			scope: {
				newsItems: "="
			},
			templateUrl: "app/components/newsHeadlines/template/template.html",
			controller: "NewsHeadlinesController"
		};
	}

	return NewsHeadlines;

});
