define('components/message/container/app',['require','common/config/config','common/directive/app','components/message/form/app','components/message/list/app','shim/overmind','components/message/container/directive/messageContainer','components/message/container/controller/MessageContainerController','common/service/MessageService'],function(require) {
	"use strict";
	
	require("common/config/config");
	require("common/directive/app");
	require("components/message/form/app");
	require("components/message/list/app");

	var overmind = require("shim/overmind");

	var messageContainer 				= require("components/message/container/directive/messageContainer"),
		messageContainerController 		= require("components/message/container/controller/MessageContainerController"),
		MessageService 					= require('common/service/MessageService');

	var component = angular.module("mPortal.components.message.container", [
																				"mPortal.directive",
																				"mPortal.config",
																				"ui.bootstrap",
																				"mPortal.components.message.form",
																				"mPortal.components.message.list"
																			]);

	component.config( overmind.control() );

	component.config(["$routeProvider", function( $routeProvider ) {

		$routeProvider.when("/account-summary/:accountId/message/:threadId/:threadAction?", {
			templateUrl: "app/accountSummary/template/template.html",
			controller: "messageContainerController"
		});
	}]);

	component.directive("messageContainer", messageContainer);
	component.controller("messageContainerController", messageContainerController);
	component.factory('MessageService',MessageService);
	
	return component;

});
