define('components/marketedPrograms/detail/app',['require','common/directive/app','components/marketedPrograms/detail/directive/marketedProgramDetail','components/marketedPrograms/detail/controller/MarketedProgramDetailController','components/marketedPrograms/detail/controller/FileUploadModalController'],function(require) {
	"use strict";

	require("common/directive/app");

	var marketedProgramDetail = require("components/marketedPrograms/detail/directive/marketedProgramDetail"),
		MarketedProgramDetailController = require("components/marketedPrograms/detail/controller/MarketedProgramDetailController"),
		FileUploadModalController = require("components/marketedPrograms/detail/controller/FileUploadModalController");

	var component = angular.module("mPortal.components.marketedPrograms.marketedProgramDetail", [
		"mPortal.directive"
	]);

	component.directive("marketedProgramDetail", marketedProgramDetail);
	component.controller("MarketedProgramDetailController", MarketedProgramDetailController);
	component.controller("FileUploadModalController", FileUploadModalController);

	return component;

});
