define( 'components/applicationData/applicationData/directive/applicationData',['require'],function( require ) {
   "use strict";

    ApplicationData.$inject = [];

    function ApplicationData() {
        return {
            restrict: "AE",
            scope: {
                application: "="
            },
            templateUrl: "app/components/applicationData/applicationData/template/template.html",
            controller: "ApplicationDataController"
        };
    }

    return ApplicationData;
});
