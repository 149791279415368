define('components/submissionsSearch/controller/SubmissionsSearchController',['require'],function(require) {
	"use strict";

	SubmissionsSearchController.$inject = ["$scope", "SearchService", "SearchResultContainer"];

	function SubmissionsSearchController( $scope, SearchService, SearchResultContainer ) {

		$scope.searchData = {
			licensedAgents: [],
			servicingAgents: [],
			statuses: [],
			sources: [],
			types: [],
			programs: []
		};

		$scope.searchParams = {
			licensedAgent: "All",
			status: "All",
			effectiveDateFrom: "",
			effectiveDateTo: "",
			submissionDateFrom: "",
			submissionDateTo: "",
			productLine: "",
			servicingAgent: "All",
			source: "",
			type: "All",
			fein: "",
			policyNumber: "",
			insuredName: "",
			insuredAccount: ""
		};

		$scope.selectedProgram = null;

		$scope.searchResults = new SearchResultContainer({ currentPage: 1, itemsPerPage: 25 });

		$scope.searchDataResults.then(function( result ) {
			$scope.searchData.programs = [  { name: "--Please select a program--", value: ""},
											{ name: "Workers' Compensation", value: "wc" },
											{ name: "Business Owners", value: "bo" }
			                             ];
			$scope.selectedProgram = $scope.searchData.programs[ 0 ];

			$scope.searchData.licensedAgents = result.submissionsAgentLicensed;
			$scope.searchData.servicingAgents = result.submissionsAgentServicing;
			$scope.searchData.statuses = result.submissionsStatus;
			$scope.searchData.sources = result.submissionsSource || [];
			$scope.searchData.types = result.submissionsType;
		});

		$scope.getResults = function() {
			if( $scope.selectedProgram.value == "" ) {
				$scope.searchResults.error.header = "Please select a program.";
			} else {
				$scope.searchParams.productLine = $scope.selectedProgram.value;
				SearchService.getSubmissionsSearchResults( $scope.searchParams, $scope.searchResults );
			}

		};

		$scope.isQuoted = function ( status ) {

			var quotableStatuses = [ "Quoted", "Bound", "Issued" ];

			return ( quotableStatuses.indexOf( status ) !== -1 )
		};

		$scope.getStatusHref = function( item ) {

			var base = "#/",
				renewalIssuedStatuses = ["Issued", "Bound", "Quoted", "Lost"];

			if( item.TYPE === "Renewal" && renewalIssuedStatuses.indexOf( item.STATUS ) !== -1 ) {
				return base + "renewal/" + item.SUBMISSIONID;
			} else {
				return base + "account/issuance/" + item.INSUREDID + "/na/" + item.SUBMISSIONID;
			}

		};

	}

	return SubmissionsSearchController;

});
