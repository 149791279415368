define('common/service/user/UserProfile',['require'],function(require) {
	"use strict";

	UserProfileFactory.$inject = ["$rootScope", "$window"];

	function UserProfileFactory( $rootScope, $window ) {

		$window.rootScopes = $window.rootScopes || [];
		$window.rootScopes.push( $rootScope );

		if( $window.userProfile ) {
			return $window.userProfile;
		}

		function UserProfile() {
			this.setDefaultProperties();
		}

		UserProfile.prototype.setDefaultCobranding = function() {
			this.cobranding = {
				id: "",
				states: [],
				contactEmail: "",
				contactPhone: "",
				hideMarkelBranding: false
			};
		};

		UserProfile.prototype.setDefaultProperties = function() {
			this.permissions = [];
			this.uiPermissions = { components: {}, navElements: {}, routes: {} };
			this.isLoggedIn = false;
			this.isLoaded = false;
			
			this.agency = {
				id: ""
			};

			// This represents the marketedProgram specified in the URL.  See SlugParserService.js
			this.marketedProgram = {
				value: null,
				label: "",
				contactEmail: "",
				contactPhone: "",
				isTerrorismVerbiageRequired: false,
				businessTypeTag: "",
                defaultBusinessTypeId: ""
			};

			this.contactEmail = "";
			this.contactPhone = "";
			this.isTerrorismVerbiageRequired = false;
			this.mbus = [];
			this.defaultState = "";
			this.disallowedPrograms = [];
			this.agencyEmulation = false;
			this.insuredEmulation = false;
			this.insuredName = "";
			this.isProlificMaverickAgent = false;
			this.insuredType = "";
			this.editedApplication = 0;
			this.isMobile  = false;
			this.profileToken = "";
			this.portalToken = "";
			this.isUnauthenticatedUser = true;
			this.setDefaultCobranding();

			this.notify();
		};


		UserProfile.prototype.hasPermissions = function( groups ) {

			var self = this;

			groups = groups || "";
			groups = Array.isArray( groups ) ? groups : groups.split(",");

			return groups.every(function( group ) {
				return self.permissions.indexOf( group ) > -1;
			});

		};

		UserProfile.prototype.hasComponentPermissions = function( component ) {
			return this.uiPermissions && ( this.uiPermissions.components[ component ] || typeof this.uiPermissions.components[ component ] === "undefined" );
		};

		UserProfile.prototype.hasNavPermissions = function( navElement ) {
			return this.uiPermissions && ( this.uiPermissions.navElements[ navElement ] || typeof this.uiPermissions.navElements[ navElement ] === "undefined" );
		};

		UserProfile.prototype.hasRoutePermissions = function( route ) {
			var routeToCheck = route;

			if( routeToCheck === "/" ) {
				routeToCheck = "home";
			} else {
				// grab the first part of the route, excluding the leading and trailing slashes. for example,
				// /account-summary/ID123 becomes "account-summary"
				routeToCheck = /^\/([^\/]*)/.exec( routeToCheck )[ 1 ];

				if( routeToCheck.indexOf("-") !== -1 ) {
					// convert to camelCase. "account-summary" becomes "accountSummary"
					routeToCheck = routeToCheck.replace(/\-\w/g, function( matches ) {
						return matches[ 1 ].toUpperCase();
					});
				}
			}

			return this.uiPermissions && ( this.uiPermissions.routes[ routeToCheck ] || typeof this.uiPermissions.routes[ routeToCheck ] === "undefined" );
		};

		UserProfile.prototype.getDefaultRoute = function() {
			if( this.type && ( this.type.toLowerCase() === "insured" || this.insuredEmulation ) ) {
				return "/account-summary";
			}

			return "/";
		};

		/**
		 * Updates the UserProfile object based on the given marketed program, setting the isTerrorismVerbiageRequired
		 * flag and the contactPhone and contactEmail fields as needed.
		 * @param marketedProgram
		 */
		UserProfile.prototype.updateBasedOnMarketedProgram = function ( marketedProgram ){
			if( !this.cobranding.id && !this.isLoggedIn ){
				this.contactEmail = marketedProgram.contactEmail;
				this.contactPhone = marketedProgram.contactPhone;
			}
			this.isTerrorismVerbiageRequired = marketedProgram.isTerrorismVerbiageRequired;
			this.notify();
		};

		UserProfile.prototype.set = function( key, value ) {
			this[ key ] = value;

			switch ( key ){
				// Updating the cobranding property should update the contactEmail and contactPhone, overwriting whatever was there.
				case "cobranding":
					this.contactEmail = value.contactEmail;
					this.contactPhone = value.contactPhone;
					this.hideMarkelBranding = value.hideMarkelBranding;
					break;

				case "marketedProgram":
					this.updateBasedOnMarketedProgram( value );
					break;

				// If the user is logged in, then the contact phone should be set to the empty string.  Otherwise, fall back to the cobranding, and finally the marketed program.
				case "isLoggedIn":
					this.contactPhone = value ? "" : ( this.cobranding.id ? this.cobranding.contactPhone : this.marketedProgram.contactPhone );
					break;

				case "insuredType":
					this.insuredType = value;
					break;
			}

			this.notify();
		};

		UserProfile.prototype.extend = function() {

			Array.prototype.unshift.call( arguments, this );
			angular.extend.apply( this, arguments );
			this.notify();

			return this;

		};

		UserProfile.prototype.notify = function() {
			angular.forEach( $window.rootScopes, function( scope ) {
				if( !scope.$$phase ) {
					scope.$apply();
				}
			});
		};

		UserProfile.prototype.reset = function() {
			
			var self = this;

			angular.forEach( self, function( property, key ) {
				if( typeof property !== "function" ) {
					delete self[ key ];
				}
			});

			self.setDefaultProperties();

		};

        UserProfile.prototype.getAgencyId = function ( systemCode ) {
            var agencies = this.agencies;
            for (var agencyIndex = 0; agencyIndex < agencies.length; agencyIndex++) {
                for (var mbuIndex = 0; mbuIndex < agencies[agencyIndex].mbus.length; mbuIndex++) {
                    if (agencies[agencyIndex].mbus[mbuIndex].systemCode === systemCode) {
                        return agencies[agencyIndex].mbus[mbuIndex].agencyId;
                    }
                }
            }
        }

        UserProfile.prototype.resize = function (){
        	var width = angular.element('body').width();

        	if(!$window.userProfile.configuredMinWidth)
        	{
        		$window.userProfile.configuredMinWidth = 1100;
        	}
     
        	$window.userProfile.isMobile = Number(width)  < $window.userProfile.configuredMinWidth;
        }

		$window.userProfile = new UserProfile();

		$window.userProfile.resize();

		angular.element($window).bind('resize',$window.userProfile.resize);

		return $window.userProfile;
	}

	return UserProfileFactory;

});
