define('components/classCodesSearch/controller/ClassCodesSearchController',['require'],function(require) {
	"use strict";

	ClassCodesSearchController.$inject = ["$scope", "SearchService", "SearchResultContainer"];

	function ClassCodesSearchController( $scope, SearchService, SearchResultContainer ) {

		$scope.searchData = {
			states: [],
			eligibilities: [],
			programs: []
		};

		$scope.searchParams = {
			classCodesForm: "",
			classCode: "",
			classCodeState: "",
			description: "",
			eligibility: "AutoQuote,Refer,Decline",
			productLine: "",
			classCodesSearchResults: ""
		};

		$scope.selectedProgram = null;
		$scope.selectedEligibilities = null;

		$scope.searchResults = new SearchResultContainer({ currentPage: 1, itemsPerPage: 25 });

		$scope.searchDataResults.then(function( result ) {
			$scope.searchData.programs = [  { name: "--Please select a program--", value: ""},
											{ name: "Workers' Compensation", value: "wc" },
											{ name: "Business Owners", value: "bo" }
										 ];
			$scope.selectedProgram = $scope.searchData.programs[ 0 ];
		
			// remove the "all" option from states
			$scope.searchData.states = result.state.slice( 1, result.state.length );
			$scope.searchData.eligibilities = result.classcodesEligibility;
			$scope.selectedEligibilities= $scope.searchData.eligibilities[ 0 ];
			if( result.defaultState.length ) {
				$scope.searchParams.classCodeState = result.defaultState[ 0 ].VALUE;
			} else {
				$scope.searchParams.classCodeState = "";
			}

		});

		$scope.getResults = function() {
			if( $scope.selectedProgram.value == "" ) {
				$scope.searchResults.error.header = "Please select a program.";
			} else {
				$scope.searchParams.productLine = $scope.selectedProgram.value;
				$scope.searchParams.eligibility = $scope.selectedEligibilities.VALUE;
				SearchService.getClassCodesSearchResults($scope.searchParams, $scope.searchResults);
			}
		};

	}

	return ClassCodesSearchController;

});
