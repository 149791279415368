define('components/agencyExperienceReport/app',['require','components/agencyExperienceReport/directive/agencyExperienceReport','components/agencyExperienceReport/controller/AgencyExperienceReportController','common/service/ReportService','common/service/util/DateService','moment','common/service/user/UserProfile'],function(require) {
	"use strict";

	var agencyExperienceReport = require("components/agencyExperienceReport/directive/agencyExperienceReport"),
		AgencyExperienceReportController = require("components/agencyExperienceReport/controller/AgencyExperienceReportController"),
		ReportService = require("common/service/ReportService"),
		DateService = require("common/service/util/DateService"),
		moment = require("moment"),
		UserProfile = require("common/service/user/UserProfile");

	var component = angular.module("mPortal.components.agencyExperienceReport", []);

	component.directive("agencyExperienceReport", agencyExperienceReport);
	component.controller("AgencyExperienceReportController", AgencyExperienceReportController);
	component.factory("ReportService", ReportService);
	component.factory("DateService", DateService);
	component.factory("UserProfile", UserProfile);
	component.value("moment", moment);

	return component;

});
