define( 'components/applicationData/applicationData/app',['require','common/directive/app','components/applicationData/applicationInfo/app','components/applicationData/applicationSection/app','angular-formly','angular-formly-templates-bootstrap','components/applicationData/applicationData/directive/applicationData','common/service/specialtyApplication/accountService','common/service/specialtyApplication/QuestionService','components/applicationData/applicationData/controller/ApplicationDataController','components/applicationData/applicationData/config/genericFormlyConfiguration','components/applicationData/applicationData/config/widgetFormlyConfiguration'],function( require ) {
   "use strict";

    require( "common/directive/app" );
    require( "components/applicationData/applicationInfo/app" );
    require( "components/applicationData/applicationSection/app" );

    require("angular-formly");
    require("angular-formly-templates-bootstrap");

    var applicationData = require( "components/applicationData/applicationData/directive/applicationData"),
		AccountService = require("common/service/specialtyApplication/accountService" ),
        QuestionService = require("common/service/specialtyApplication/QuestionService" ),
        ApplicationDataController = require( "components/applicationData/applicationData/controller/ApplicationDataController"),
        setGenericFormlyConfigurations = require("components/applicationData/applicationData/config/genericFormlyConfiguration"),
        setWidgetFormlyConfigurations = require("components/applicationData/applicationData/config/widgetFormlyConfiguration");

    var component = angular.module( "mPortal.applicationData.applicationData", [
        "formly",
        "formlyBootstrap",
        "mPortal.directive",
        "mPortal.applicationData.applicationInfo",
        "mPortal.applicationData.applicationSection"
    ] );

	component.config( overmind.control() );

	component.config(["$routeProvider", function( $routeProvider ) {

		$routeProvider.when("/account-summary/view-application/:applicationId", {
			templateUrl: "app/components/applicationData/applicationData/template/template.html",
			controller : "ApplicationDataController"
		});
	}]);
    component.directive( "applicationData", applicationData );
    component.factory( "AccountService", AccountService );
    component.factory( "QuestionService", QuestionService );
    component.controller( "ApplicationDataController", ApplicationDataController );

    component.run(["formlyConfig", "formlyApiCheck", function( formlyConfig, formlyApiCheck ) {
        // Sets generic formly configuration settings
        setGenericFormlyConfigurations( formlyConfig, formlyApiCheck );
        // Sets formly configuration settings specific to widgets
        setWidgetFormlyConfigurations( formlyConfig, formlyApiCheck );
    }]);

    return component;

});
