define('components/submissionDetail/submissionInfo/app',['require','components/submissionDetail/submissionInfo/directive/submissionInfo'],function(require) {
	"use strict";

	var submissionInfo = require("components/submissionDetail/submissionInfo/directive/submissionInfo");

	var component = angular.module("mPortal.submissionDetail.submissionInfo", []);

	component.directive("submissionInfo", submissionInfo);

	return component;

});
