define('components/userAccountSettings/securityResponse/app',['require','common/directive/app','components/userAccountSettings/securityResponse/directive/securityResponse','components/userAccountSettings/securityResponse/controller/SecurityResponseController','common/service/MessageContainer','common/service/user/UserService'],function(require) {
	"use strict";

	require("common/directive/app");

	var securityResponse = require("components/userAccountSettings/securityResponse/directive/securityResponse"),
		SecurityResponseController = require("components/userAccountSettings/securityResponse/controller/SecurityResponseController"),
		MessageContainer = require("common/service/MessageContainer"),
		UserService = require("common/service/user/UserService");

	var component = angular.module("mPortal.userAccountSettings.securityResponse", [
		"mPortal.directive"
	]);

	component.directive("securityResponse", securityResponse);
	component.controller("SecurityResponseController", SecurityResponseController);
	component.factory("MessageContainer", MessageContainer);
	component.factory("UserService", UserService );

	return component;

});
