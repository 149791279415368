define('bundlePayment/app',['require','common/config/config','shim/overmind','common/filter/formatMoney','bundlePayment/filter/MaskAccountFilter','bundlePayment/controller/BundlePaymentController','common/service/BundlePaymentService'],function(require) {
	"use strict";

	require("common/config/config");

	var overmind = require("shim/overmind" ),
		formatMoney = require("common/filter/formatMoney" ),
		MaskAccountFilter = require("bundlePayment/filter/MaskAccountFilter" ),
		BundlePaymentController = require( "bundlePayment/controller/BundlePaymentController" ),
		BundlePaymentService = require("common/service/BundlePaymentService" );

	var app = angular.module("mPortal.bundlePayment", [
		"mPortal.config"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {

		$routeProvider.when("/bundle-payment/:accountId", {
			templateUrl: "app/bundlePayment/template/template.html",
			controller: "BundlePaymentController"
		});

		$routeProvider.when("/bundle-payment", {
			templateUrl: "app/bundlePayment/template/template.html",
			controller: "BundlePaymentController",
			guard: function( userProfile ) {
				return !userProfile.isLoggedIn;
			}
		});

	}]);
	app.filter("formatMoney", formatMoney);
	app.filter("MaskAccountFilter", MaskAccountFilter);
	app.controller( "BundlePaymentController", BundlePaymentController );
	app.factory("BundlePaymentService", BundlePaymentService);
	app.directive("progressbar2", function () {
		return {
			restrict: "A",
			scope: {
				load: '='
			},
			link: function (scope, element) {
				//watch load attribute
				scope.$watch('load', function() {
					//should be i+1 or load+1 in this case
					element.css('width', (scope.load+1) + '%');
				});
			}
		};
	});
	app.directive('luhnValid', function() {
		return {
			require: 'ngModel',
			link: function(scope, elm, attrs, ctrl) {
				var validator = function(viewValue) {
					var digit, digits = viewValue.toString().split('').reverse(), sum = 0, i, odd = true;
					for (i = 0; i < digits.length; i++) {
						digit = digits[i];
						digit = parseInt(digit, 10);
						if ((odd = !odd)) { digit *= 2; }
						if (digit > 9) { digit -= 9; }
						sum += digit;
					}
					if (sum % 10 === 0) {
						// valid
						ctrl.$setValidity('luhnValid', true);
						return viewValue;
					} else {
						ctrl.$setValidity('luhnValid', false);
						return viewValue;
					}
				};
				ctrl.$parsers.unshift(validator);
				// ctrl.$formatters.unshift(validator);
			}
		};
	});
	app.factory('SharedData', function() {
		var properties = {};

		return {
			getProperty: function ( key ) {
				if ( key in properties ) {
					return properties[ key ];
				} else {
					return null;
				}
			},
			setProperty: function( key, value ) {
				properties[ key ] = value;
			}
		};
	} );

	return app;

});
