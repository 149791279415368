define('components/renewalsList/controller/RenewalsListController',['require'],function(require) {
	"use strict";

	RenewalsListController.$inject = ["$scope", "RenewalService"];

	function RenewalsListController( $scope, RenewalService ) {

		$scope.renewals = [];

		RenewalService.getUpcomingRenewals().then(function( results ) {
			$scope.renewals = results;
		});

	}

	return RenewalsListController;

});
