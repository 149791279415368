define('components/addAccount/app',['require','components/insuredRegistration/accountLookup/app','components/addAccount/directive/addAccount','components/addAccount/controller/AddAccountController','components/addAccount/controller/AddAccountModalController','common/service/insuredRegistration/InsuredRegistrationService'],function(require) {
	"use strict";

	require("components/insuredRegistration/accountLookup/app");

	var addAccount = require("components/addAccount/directive/addAccount"),
		AddAccountController = require("components/addAccount/controller/AddAccountController"),
		AddAccountModalController = require("components/addAccount/controller/AddAccountModalController"),
		InsuredRegistrationService = require("common/service/insuredRegistration/InsuredRegistrationService");

	var component = angular.module("mPortal.addAccount", [
		"mPortal.insuredRegistration.accountLookup"
	]);

	component.directive("addAccount", addAccount);
	component.controller("AddAccountController", AddAccountController);
	component.controller("AddAccountModalController", AddAccountModalController);
	component.factory("InsuredRegistrationService", InsuredRegistrationService);

	return component;

});
