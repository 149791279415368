define('components/specialtyApplication/resume/app',['require','components/specialtyApplication/resume/directive/resume','components/specialtyApplication/resume/controller/ResumeController','common/service/specialtyApplication/ResumeService','common/service/user/UserProfile'],function(require)  {
    "use strict";

    var resume = require("components/specialtyApplication/resume/directive/resume"),
        ResumeController = require("components/specialtyApplication/resume/controller/ResumeController"),
        ResumeService = require("common/service/specialtyApplication/ResumeService"),
        UserProfile = require( "common/service/user/UserProfile" );

    var component = angular.module("mPortal.components.specialtyApplication.resume", []);

    component.directive("specialtyApplicationResume", resume);
    component.controller("ResumeController", ResumeController);
    component.factory( "ResumeService", ResumeService );
    component.factory( "UserProfile", UserProfile );

    return component;
});
