define('components/specialtyApplication/questions/config/widgets/verisk/veriskLossCostController',['require'],function(require) {
	"use strict";

	VeriskLossCostController.$inject = ["$scope", "$modal", "VeriskService", "SubmissionStore"];

	function VeriskLossCostController( $scope, $modal, VeriskService, SubmissionStore ) {
        $scope.store = SubmissionStore;

        const fields = $scope.fields;       
        
        var applicationdata = fields.find(x=>x.type == "verisk_loss_cost_widget").data;
        var submissionId = "";
        var sectionId = "";
       
        if(applicationdata)
        {
            submissionId = applicationdata.applications[0].submissionId;
            sectionId= applicationdata.sectionId;
        }
        var verisk_sessionstorage_arr = JSON.parse(sessionStorage.getItem('Verisk')) || [];				
        var verisk_data =  verisk_sessionstorage_arr.find(x => x.sectionId == sectionId  && x.applicationId == SubmissionStore.applicationId);
        if(verisk_data)
        {
            const loss_cost_data = {   
            "submission":
                            {
                                "riskId": verisk_data.riskId,  
                                state:verisk_data.state,
                                "effectiveDate": verisk_data.effectiveDate 
                            },
                "calling_system":"mportal",
                "UUID": submissionId,
                "risksectionID":""
            };      

            VeriskService.getLossCostReport(loss_cost_data).then(function( result ) {
                $scope.lossCostData = result;                
                result.LossCosts.push({"Name":"NONE OF THE ABOVE"});
                result.sectionId = sectionId;
                result.is_emit = true;
                if (result.LossCosts && result.LossCosts.length > 0) {               
                    $modal.open({
                        keyboard: false,
                        templateUrl: "app/components/specialtyApplication/questions/config/widgets/verisk/modal-loss-cost-template.html",
                        controller: "VeriskLossCostModalController",
                        scope: $scope,
                        windowClass: "verisk-loss-cost-modal",
                        resolve: {                                
                            lossCostData: function(){ return result }
                        }
                    });
                }   
            });        
        }
    }
	return VeriskLossCostController;
});
