define( 'components/claimDetail/insuredClaimInfo/app',['require','common/directive/app','components/claimDetail/insuredClaimInfo/directive/insuredClaimInfo','components/claimDetail/insuredClaimInfo/controller/InsuredClaimInfoController','common/service/specialtyApplication/AccountService'],function( require ) {
    "use strict";
    require( "common/directive/app" );

    var insuredClaimInfo = require( "components/claimDetail/insuredClaimInfo/directive/insuredClaimInfo" ),
        InsuredClaimInfoController = require("components/claimDetail/insuredClaimInfo/controller/InsuredClaimInfoController"),
        AccountService = require( "common/service/specialtyApplication/AccountService" );

    var component = angular.module( "mPortal.claimDetail.insuredClaimInfo" ,
        ["mPortal.directive"] );

    component.directive( "insuredClaimInfo", insuredClaimInfo );
    component.controller("InsuredClaimInfoController", InsuredClaimInfoController);
    component.factory("AccountService", AccountService);
    return component;

});

