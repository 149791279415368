define('components/insuredClaims/controller/InsuredClaimsController',['require'],function(require) {
	"use strict";

	InsuredClaimsController.$inject = ["$scope", "moment"];

	function InsuredClaimsController( $scope, moment ) {

		$scope.claimsByYear = {};
		$scope.claimYears = [];
		if( $scope.user && $scope.user.type == "Insured" ) {
			$scope.claimData = $scope.claims;
		}

		$scope.showLossRuns = function(){
			let viewLossRuns = false;
			if(localStorage.getItem('mportal-show-loss-runs'))
			{
				viewLossRuns = localStorage.getItem('mportal-show-loss-runs') === 'true';
			}	
			return viewLossRuns;
		}

		if($scope.claims ) {
			$scope.claimsByYear = $scope.claims.reduce(function( grouped, claim ) {

				var effectiveYear = null; 				
				if (claim.claimDate || claim.claimDate != null) {
					effectiveYear = moment( claim.claimDate, "MM/DD/YYYY" ).year();
				}
				else{
					effectiveYear = moment( claim.occurrenceDate, "YYYY-MM-DD" ).year();
				}

				if( !grouped[ effectiveYear ] ) {
					grouped[ effectiveYear ] = [];
					$scope.claimYears.push( effectiveYear );
				}

				grouped[ effectiveYear ].push( claim );

				return grouped;
			}, {});
			$scope.claimYears.sort().reverse()
		}

	}

	return InsuredClaimsController;

});
