define( 'components/specialtyApplication/submit/programDetails/wc/directive/wc',['require'],function( require ) {
	"use strict";

	function Wc() {
		return {
			restrict: "AE",
			scope: {},
			templateUrl: "app/components/specialtyApplication/submit/programDetails/wc/template/template.html",
			controller: "WcController"
		};
	}

	return Wc;

});
