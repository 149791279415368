define('user/app',['require','common/config/config','common/directive/app','components/userAccountSettings/personalDetails/app','components/userAccountSettings/userCredentials/app','components/userAccountSettings/securityResponse/app','components/userAccountSettings/ciamAccountSettings/app','components/userAccountSettings/stateNewsSubscriptions/app','shim/overmind','user/controller/UserEditController','common/service/user/UserService','common/service/user/UserProfile'],function(require) {
	"use strict";

	require("common/config/config");
	require("common/directive/app");
	require("components/userAccountSettings/personalDetails/app");
	require("components/userAccountSettings/userCredentials/app");
	require("components/userAccountSettings/securityResponse/app");
	require("components/userAccountSettings/ciamAccountSettings/app");
	require("components/userAccountSettings/stateNewsSubscriptions/app");

	var overmind = require("shim/overmind");

	var UserEditController = require("user/controller/UserEditController"),
		UserService = require("common/service/user/UserService"),
		UserProfile = require("common/service/user/UserProfile");

	var app = angular.module("mPortal.user", [
		"mPortal.config",
		"mPortal.directive",
		"mPortal.userAccountSettings.personalDetails",
		"mPortal.userAccountSettings.userCredentials",
		"mPortal.userAccountSettings.securityResponse",
		"mPortal.userAccountSettings.ciamAccountSettings",
		"mPortal.userAccountSettings.stateNewsSubscriptions"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {

		$routeProvider.when("/user/edit/", {
			templateUrl: "app/user/template/template.html",
			controller: "UserEditController"
		});
		
	}]);

	app.controller("UserEditController", UserEditController);
	app.factory("UserService", UserService);
	app.factory("UserProfile", UserProfile);

	return app;

});
