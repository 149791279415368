define('components/policyDetail/accountHistory/app',['require','components/policyDetail/accountHistory/directive/accountHistory','components/policyDetail/accountHistory/controller/AccountHistoryController'],function(require) {
	"use strict";

	var accountHistory = require("components/policyDetail/accountHistory/directive/accountHistory"),
		AccountHistoryController = require("components/policyDetail/accountHistory/controller/AccountHistoryController");

	var component = angular.module("mPortal.policyDetail.accountHistory", []);

	component.directive("accountHistory", accountHistory);
	component.controller("AccountHistoryController", AccountHistoryController);

	return component;

});
