define('components/productSearch/container/directive/productSearchContainer',['require'],function(require) {
	"use strict";

	productSearchContainer.$inject = [];

	function productSearchContainer() {
		return {
			restrict: "AE",
			scope: {
                includeLocation: "="
            },
			templateUrl: "app/components/productSearch/container/template/template.html",
			controller: "productSearchContainerController"
		};
	}

	return productSearchContainer;

});
