define('components/specialtyApplication/retrieveQuote/app',['require','components/specialtyApplication/retrieveQuote/directive/retrieveQuote','components/specialtyApplication/retrieveQuote/controller/RetrieveQuoteController','common/service/specialtyApplication/SpecialtyEligibilityService','moment','common/service/MessageContainer'],function(require) {
	"use strict";

	var retrieveQuote= require("components/specialtyApplication/retrieveQuote/directive/retrieveQuote"),
		retrieveQuoteController = require("components/specialtyApplication/retrieveQuote/controller/RetrieveQuoteController"),
		SpecialtyEligibilityService = require("common/service/specialtyApplication/SpecialtyEligibilityService"),
		moment = require("moment"),
		MessageContainer = require("common/service/MessageContainer");

	var component = angular.module("mPortal.components.specialtyApplication.retrieveQuote", []);

	component.directive("specialtyApplicationRetrieveQuote", retrieveQuote);
	component.controller("SpecialtyApplicationRetrieveQuoteController", retrieveQuoteController);
	component.factory("SpecialtyEligibilityService", SpecialtyEligibilityService);
	component.factory("MessageContainer", MessageContainer);
	component.value("moment", moment);

	return component;

});
