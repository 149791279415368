define( 'components/specialtyApplication/submit/programDetails/bop/directive/bop',['require'],function( require ) {
	"use strict";

	function Bop() {
		return {
			restrict: "AE",
			scope: {},
			templateUrl: "app/components/specialtyApplication/submit/programDetails/bop/template/template.html",
			controller: "BopController"
		};
	}

	return Bop;

});
