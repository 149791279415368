define( 'common/service/specialtyApplication/AccountService',['require'],function( require ) {
	"use strict";

	AccountService.$inject = [ "$http", "$filter", "$q", "archApiUrl", "moment" ];

	function AccountService( $http, $filter, $q, archApiUrl, moment ) {
		var service = {};

		/*
		 The data provided to the insured information/account controller differs depending on the context: whether the
		 submission is new or being edited, and whether the data comes from the eligibility step or the product search.
		 This service method handles the different scenarios, and because this code is executed as the controller is
		 initialized, handling it in a service method makes it easier to test the controller.
		*/
		service.updateScopeFromStore = function( scope, submissionStore, settableFields ) {
			// When dealing with a submission being edited
			if( submissionStore.insured ) {
				// NOTE: currently website is not a stored or retrieved value
				angular.forEach( settableFields, function( field ) {
					scope.form[ field ] = submissionStore.insured[ field ];
					scope.previousInsuredValues[ field ] = submissionStore.insured[ field ];
				});

				// Capture the state and delivery preference
				scope.initialValues.state = $filter('filter')( scope.states, { id: submissionStore.insured.state }, true )[0];
				scope.masterQuoteData.deliveryPreference = submissionStore.insured.deliveryPreference;

				// Denotes if user is allowed to edit insured data
				scope.status.canEditInsured = submissionStore.previousValues.canEditInsured != undefined ? submissionStore.previousValues.canEditInsured : true;

				// If the user changed the zip code in the previous step and they can edit, update it
				if( scope.status.canEditInsured && submissionStore.form.zip && submissionStore.form.zip != scope.form.zip ) {
					scope.form.zip = submissionStore.form.zip;
				}

			} else {
				// The eligibility step will provide a zip code; the product search may or may not.  But both provide the state
				scope.form.zip       = ( submissionStore.form.zip )? submissionStore.form.zip : '' ;

				// The state abbreviation will either be a string or an object property.  We also make sure it exists
				// at all in case someone reloads the page
				scope.form.state = submissionStore.form.state ? ( submissionStore.form.state.abbreviation ? submissionStore.form.state.abbreviation : submissionStore.form.state ) : "AL";
				scope.initialValues.state = $filter('filter')( scope.states, { id: scope.form.state }, true )[0];

				// The eligibility step will pass an array of allowed document delivery options; product search does not
				// At some future date, document delivery will not be set until after submission is quoted
				if( submissionStore.form.documentDeliveryOptions && submissionStore.form.documentDeliveryOptions.length ) {
					scope.masterQuoteData.deliveryPreference = submissionStore.form.documentDeliveryOptions[ 0 ].value;
				}

			}

			// Certain programs affect certain conditions.
			if( submissionStore.programs.length ) {
				for( var i = 0; i < submissionStore.programs.length ; i++ )
				{
					if( submissionStore.programs[i].referenceString.toLowerCase() == "wcbop" 
						|| submissionStore.programs[i].referenceString.toLowerCase() == "bop"  || 
						submissionStore.programs[i].referenceString.toLowerCase() == "umbrella") {
						scope.form.isFeinRequired = true;
					}
				}
				if( submissionStore.programs.length == 1 && submissionStore.programs[0].referenceString.toLowerCase() == "miscerrorsomissions") {
					scope.status.isEmailRequired = false;
				}
			}
		};

		service.populateAddressVerificationErrors = function( errors, verificationData, status ) {
			if( verificationData.isValidZip != undefined ) {
				if( !verificationData.isValidZip ) {
					errors.push( "The entered zip code is invalid or does not exist." );
				} else {
					if( !verificationData.isValidZipState ) {
						errors.push( "The entered zip code belongs to the state of " + verificationData.zipCodeOptions.state.name + ".")
					}
					if( !verificationData.isValidZipCity ) {
						var validCities = verificationData.zipCodeOptions.cities;
						var lastCityIndex = validCities.length - 1;
						var joinString = validCities.length == 2 ? " and " : ", ";
						validCities[ lastCityIndex ] = lastCityIndex && validCities.length > 2 ? ( "and " + validCities[ lastCityIndex ] ) : validCities[ lastCityIndex ];
						errors.push( "The entered zip code is only valid for the following " + ( lastCityIndex ? "cities" : "city" ) + ": " + validCities.join( joinString ) + "." );
					}
				}
				if( errors.length && status.existingInsuredSelected ) {
					status.selectedInsuredAddressError = true;
					status.hideAddressForm = false;
				}
			}
		};

		service.search = function( insured ) {
			return $http.post( archApiUrl + "organization/insured/search", {
				name: insured.name,
				dba: insured.dba,
				address1: insured.address1,
				city: insured.city,
				state: insured.state,
				zip: insured.zip,
				phone: insured.phone,
				email: insured.email,
				programCode: insured.programCode
			});
		};

		service.packageInsuredData = function( insured, programCode, agencyId, maxScore , marketedProgramName ) {
			var insuredData = {
				name: insured.name,
				dba: insured.dba,
				address1: insured.address1,
				address2: insured.address2,
				city: insured.city,
				state: insured.state,
				zip: insured.zip,
				phone: insured.phone,
				email: insured.email,
				fein: insured.fein,
				programcode: programCode,
				producerid: agencyId,
				pinum: insured.pinum,
				orignum: insured.orignum,
				maxscore: maxScore,
				physicalAddress1: insured.address1,
				physicalAddress2: insured.address2,
				physicalState: insured.state,
				physicalCity: insured.city,
				physicalZip: insured.zip,
                isFeinRequired: insured.isFeinRequired,
                marketedProgramName:marketedProgramName
			};
			if( insured.maverickAccountId ) {
				insuredData.maverickAccountId = insured.maverickAccountId;
				insuredData.isAddressVerificationRequired = insured.isAddressVerificationRequired
			}
			return insuredData;
		};

		service.isExistingInsuredSearchAllowed = function( userProfile ) {
			if( ( userProfile.agencyEmulation && userProfile.type == "Internal" ) || ( userProfile.type == "Agent" ) ) {
				return true;
			} else {
				return false;
			}
		};

		service.saveInsured = function( insured, programCode, agencyId, maxScore ,marketedProgramName ) {
			var restData = this.packageInsuredData( insured, programCode, agencyId, maxScore , marketedProgramName );
			return $http.post( archApiUrl + "organization/insured", restData );
		};

		service.changeInsured = function( insured, applicationId, programCode, agencyId, maxScore ) {
			var restData = this.packageInsuredData( insured, programCode, agencyId, maxScore,'' );
			restData.applicationId = applicationId;
			restData.maid = insured.maid;
			return $http.put( archApiUrl + "organization/insured", restData );
		};

		service.createMasterQuote = function( maId, insuredId, insuredName, insuredDba, agencyId, agentId, licenseId, state, postalCode, products, effectiveDate, marketedProgramId, deliveryPreference, cobrandingId, btype_description ) {
			return $http.post( archApiUrl + "application/quote", {
				maId: maId,
				insuredId: insuredId,
				insuredName: insuredName,
				insuredDba: insuredDba,
				agencyId: agencyId,
				agentId: agentId,
				licenseId: licenseId,
				state: state,
				postalCode: postalCode,
                products: products,
				effectiveDate: effectiveDate,
				marketedProgramId: marketedProgramId,
				preferreddeliverytype: deliveryPreference,
				cobrandedPartner: cobrandingId,
				businessTypeDescription: btype_description
			});
		};

		service.changeMasterQuote = function( applicationId, previousEffectiveDate, effectiveDateString, deliveryPreference, instance ) {
			var restData = { applicationId: applicationId, preferreddeliverytype: deliveryPreference, instance: instance };
			var effectiveDate = moment( effectiveDateString, "MM/DD/YYYY" );
			if( previousEffectiveDate.valueOf() !== effectiveDate.valueOf() ) {
				restData.effectiveDate = effectiveDateString;
			};
			return $http.put( archApiUrl + "application/quote", restData );
		};

		service.reviveSubmission = function( applicationId, effectiveDateString ) {
			var restData = { applicationId: applicationId, effectiveDate: effectiveDateString };
			return $http.put( archApiUrl + "application/submission/" + applicationId + "/revive", restData );
		};

		service.getAccountSummary = function( accountId ) {
			return $http.get( archApiUrl + "account/" + accountId + "/account-summary" ).then(function( result ) {
				return result.data;
			});
		};

		service.getPaymentSystem = function( accountId ){
			return $http.get( archApiUrl + "account/" + accountId + "/payment-system/" ).then(function( result ) {
				return result.data;
			});
		};

		service.getAccountActivity = function( accountId ) {
			return $http.get( archApiUrl + "account/" + accountId + "/activity" ).then(function( result ) {
				return result.data;
			});
		};

		service.getInsuredAccountSummary = function() {
			return $http.post( archApiUrl + "account/account-summary/search", {} ).then(function( result ) {
				var accounts = result.data;
				if( accounts.length > 0 ) {
					accounts.forEach( function( account )  {
						account.policies.forEach( function( policy ) {
							policy.account = account;
							policy.sortableEffectiveDate = moment( policy.effectiveDate, "MM/DD/YYYY" );
						});
					});
				}
				return accounts;
			});
		};

		service.getPolicyBillingHistory = function ( accountId, policyId, effectiveDate, insuredId, billingSystem ) {
			var params = {accountId:accountId, policyId: policyId, effectiveDate : effectiveDate,insuredId: insuredId, billingSystem:billingSystem}

			return $http.post( archApiUrl + "account/" + accountId + "/billing-history", params ).then( function ( result ) {
				var billingData = result.data;
				// Pull certain status values from history and schedule data to use in specific places in the UI.
				if ( billingSystem != 'BC9' ) {
					if ( billingData.invoices.length > 0 ) {
						if ( typeof billingData.invoices[0].unpaid != 'undefined' && billingData.invoices[0].unpaid != null ) {
							billingData.showUnpaid = true;
						}
					}
					// the current due date is the first future date in the payment schedule.
					var futurePaymentSchedule = billingData.invoices.filter( function ( a ) {
						return moment( a.dueDate ).isAfter( moment() );
					} );
					billingData.currentDueDate = ( futurePaymentSchedule[0] && futurePaymentSchedule[0].dueDate ) ? futurePaymentSchedule[0].dueDate : 'N/A';

					if ( billingData.paymentHistory.length > 0 ) {
						// Applying the formatMoney filter to a non-numeric value returns $0.00, so conditionally applying filter here rather than in the template
						billingData.lastPaymentAmount = billingData.paymentHistory[0].amount ? $filter( "formatMoney" )( billingData.paymentHistory[0].amount ) : 'N/A';
						billingData.lastPaymentDate = billingData.paymentHistory[0].paymentDate
					} else {
						billingData.lastPaymentAmount = 'N/A';
						billingData.lastPaymentDate = 'N/A';
					}
				}
				return billingData;
			} );
		};

		service.getPolicyClaims = function( policyNumber, effectiveDate, expirationDate ) {
			var params = { policyNumber: policyNumber };			
			
			var deferred = $q.defer();
			service.searchGraphQLClaims( params ).then(function  (result ) {
				
				if (result) {
					deferred.resolve( result );
				}
				else {
					return $http.post( archApiUrl + "claim/search", params ).then( function( result ) {
						return deferred.resolve(result.data);
					});
				}
			});
			return deferred.promise;			
		};

		service.getClaimsData = function(  ) {
			return $http.post( archApiUrl + "claim/claimInfo" ).then( function( result ) {
				//result.data ={policies : [{ effectiveDate: '01/01/3900', claimNumber : 'MNY900157629' }, { effectiveDate: '01/01/3999', claimNumber : 'MNY900157629' }]};
				return result.data;
			});
		};

		service.getClaimOccurrence = function( occurrenceNumber ) {
			let params = {occurrenceNumber: occurrenceNumber};
			var deferred = $q.defer();
			service.getGraphQLOccurrence( params ).then(function  (result ) {
				if (result) {					
					deferred.resolve( result );
				}
				else {
					// In this context, occurrenceNumber is passed as the "claimId" to the endpoint
					return $http.get( archApiUrl + "claim/" + occurrenceNumber ).then(function( result ) {
						return deferred.resolve( result.data );
					});
				}
			});
			return deferred.promise;
		
		};

		service.searchGraphQLClaims = function (params) {
			var searchResult = $http.post(window.location.origin + "/loss-run-api/loss-runs/mportal/", params).then(function (result) {
				return result.data;
			});
			return searchResult;

		}

		service.getGraphQLOccurrence = function(params) {
			var searchResult = $http.post(window.location.origin + "/loss-run-api/loss-runs/mportal/occurrence", params).then(function (result) {
				return result.data;
			});
			return searchResult;

		}

		service.getIconClaim = function( occurrenceNumber, agencyId ) {
			let params = {occurrenceNumber: occurrenceNumber};
			var deferred = $q.defer();
			service.searchGraphQLClaims( params ).then(function  (result ) {
				if (result && result.length > 0) {					
					deferred.resolve( result );
				}
				else {
					var params = { claimId: occurrenceNumber,agencyId:agencyId};
					// In this context, occurrenceNumber is passed as the "claimId" to the endpoint
					return $http.get( archApiUrl + "claim/" + occurrenceNumber + "/agencyId/"+agencyId , params ).then(function( result ) {
						return result.data;
					});
				}
			});
			return deferred.promise;
			
			// In this context, occurrenceNumber is passed as the "claimId" to the endpoint
			return $http.get( archApiUrl + "claim/" + occurrenceNumber + "/agencyId/"+agencyId , params ).then(function( result ) {
				return result.data;
			});
		};

		service.getOccurrenceDetails = function( occurrenceNumber ) {
			// In this context, occurrenceNumber is passed as the "claimId" to the endpoint
			return $http.get( archApiUrl + "claim/" + occurrenceNumber + "/occurrenceDetails" ).then(function( result ) {
				return result.data;
			});
		};

		service.getClaim = function (claimId){
			return $http.get( archApiUrl + "claim/" + claimId + "/claimDetails").then(function( result ) {
				return result.data;
			});
		};

		service.getClaimDetails = function (insuredId, insuredName){
			var params = { accountId: insuredId,insuredId:insuredId, insuredName:insuredName};
			return $http.post( archApiUrl + "claim/claimDetails", params).then(function( result ) {
				return result.data;
			});
		};

		service.getClaimSummary = function (insuredId,insuredName){
			var params = { accountId: insuredId,insuredId:insuredId,insuredName:insuredName };
			return $http.post( archApiUrl + "claim/claimSummary", params).then(function( result ) {
				return result.data;
			});
		}

		// Performs equivalency check between mPolicy and ICON policy numbers
		// Example: mPolicy policy number "CCP200236-01" and ICON policy number "CPP200236-1" reference the same policy
		service.policyNumbersMatch = function( policyNumberA, policyNumberB ) {
			var policyNumberPartsA = policyNumberA.split( "-" );
			var policyNumberPartsB = policyNumberB.split( "-" );
			var termSuffixA = policyNumberPartsA.pop();
			var termSuffixB = policyNumberPartsB.pop();
			return ( policyNumberPartsA.join('-') === policyNumberPartsB.join('-') ) && ( parseInt( termSuffixA ) === parseInt( termSuffixB ) );
		};

		// Performs equivalency check between mPolicy and ICON policy numbers and effective dates
		service.policyNumbersAndEffectiveDatesMatch = function ( policyNumberA, policyNumberB, effectiveDateA, effectiveDateB ) {
			return ( policyNumberA === policyNumberB.split( '-' ).first())
					&& ( moment( effectiveDateA ).format( "yyyy-MM-dd" ) === moment( effectiveDateB ).format( "yyyy-MM-dd" ) );
		};

		// Makes call to see if portal user can lock, or already has lock on, the specified submission.
		service.submissionLockableByUser = function( applicationId ) {
			return $http.get( archApiUrl + "application/submission/" + applicationId + "/lockable" ).then( function( result ) {
				return result.data;
			});
		};

		service.performPlacesLookup = function( params ) {
			return $http.post( archApiUrl + "organization/place/search", params).then( function( result ) {
				return result.data;
			});
		};

		service.getPlaceDetails = function( params ) {
			return $http.post( archApiUrl + "organization/place/details", params).then( function( result ) {
				return result.data;
			});
		};

		service.getInsuredDetails = function( referenceKey ) {
			return $http.get( archApiUrl + "organization/insured/" + referenceKey ).then( function( result ) {
				return result.data;
			});
		};

		service.retrieveEditableSubmissions = function( insuredId, agencyId, programs ) {
			var params = { insuredId: insuredId, agencyId: agencyId, products: programs };
			return $http.post( archApiUrl + "organization/agency/" + agencyId+ "/insured/" + insuredId + "/editable-submissions", params).then( function( result ) {
				var results = result.data.response;
				return results;
			});
		};

		service.parseAddressComponents = function( components, initialState ) {
			var addressObj = {};
			var streetNumber = $filter( "filter" )( components, function( item ) {
				return item.types.indexOf( "street_number" ) > -1
			})[0];
			var route = $filter( "filter" )( components, function( item ) {
				return item.types.indexOf( "route" ) > -1
			})[0];
			var city = $filter( "filter" )( components, function( item ) {
				return ( item.types.indexOf( "locality" ) > -1 && item.types.indexOf( "political" ) > -1 )
			})[0];
			var state = $filter( "filter" )( components, function( item ) {
				return ( item.types.indexOf( "administrative_area_level_1" ) > -1 && item.types.indexOf( "political" ) > -1 )
			})[0];
			var zipCode = $filter( "filter" )( components, function( item ) {
				return item.types.indexOf( "postal_code" ) > -1
			})[0];

			addressObj.address = ( streetNumber ? ( streetNumber.long_name  + " " ) : "" ).replace(/[^ -~]+/g, "") + ( route ? route.long_name : "" );
			addressObj.city = city ? city.long_name: "";
			addressObj.state = state ? state.short_name : initialState;
			addressObj.zip = zipCode ? zipCode.long_name : "";

			return addressObj;
		};

		service.parseInsuredProfiles = function( insuredProfiles ) {
			var main = insuredProfiles.mPolicy;
			var related = insuredProfiles.mAgency;

			// If no email on either profile, flag main profile as needing to be processed
			if( !main.email && !related.email ) {
				main.requiresUpdate = true;
			}

			// If the main profile is not persisted and inherits a 9-digit zip code from the related profile,
			// shorten the zip code (mPolicy will not take 9-digit zip codes)
			if( !main.maid && main.zip.length > 5 ) {
				main.zip = main.zip.substring(0,5);
			}

			return { main: main, related: related };
		};


		service.profileDataRequiresUpdate = function( mainProfile, insured, status ) {
			var result = false;
			if( !mainProfile.email && status.isEmailRequired ) {
				result = true;
			}
			if( !mainProfile.fein && insured.isFeinRequired ) {
				result = true;
			}

			// Empty profile values are null while empty form values are empty strings, so have to perform a conversion
			var profile = {
				dba: mainProfile.dba ? mainProfile.dba : "",
				phone: mainProfile.phone ? mainProfile.phone : "",
				email: mainProfile.email ? mainProfile.email : "",
				website: mainProfile.website ? mainProfile.website: ""
			};

			// Check for changes in the contact information, as the user is allowed to change those
			if( insured.dba != profile.dba || insured.phone != profile.phone || insured.email != profile.email || insured.website != profile.website ) {
				result = true;
			}

			return result;
		};

		return service;
	}

	return AccountService;

});
