define('components/stateImageMap/controller/StateImageMapController',['require'],function(require) {
	"use strict";

	StateImageMapController.$inject = ["$scope", "FormService"];

	function StateImageMapController( $scope, FormService ) {

		$scope.states = [];
		$scope.filter = {
			state: ""
		};

		FormService.getStates().then(function( result ) {
			$scope.states = result;
		});

		$scope.filterStates = function( state ) {
			
			if( !$scope.filter.state ) {
				return true;
			}

			var abbrev = state.PAYROLLSTATE.toLowerCase(),
				full = state.FULLSTATENAME.toLowerCase(),
				filterValue = $scope.filter.state.toLowerCase();

			return abbrev.indexOf( filterValue ) !== -1 || full.indexOf( filterValue ) !== -1;

		};

	}

	return StateImageMapController;

});
