define('components/specialtyApplication/questions/config/widgets/LocationInformationWidget',['require'],function(require) {
	"use strict";

	var widget = {};

	widget.getStructure = function() {

		return {

			"LocationInformationDataPoint" : { wrapper : ["twoPointRow"], fieldGroup:
					{
						"No Of Locations" : { wrapper: [ "horizontalBox", "verticalHalfRowError" ] },
						"Outside Exposure" : { wrapper: [ "horizontalBox", "verticalHalfRowError" ] }
					}
			},
			"KYExemptionDataPoint" : { wrapper : ["twoPointRow"], fieldGroup:
					{
						"KY Municipal Exemption" : { wrapper: [ "horizontalBox", "verticalHalfRowError" ] },
						"KY Surcharge Exemption" : { wrapper: [ "horizontalBox", "verticalHalfRowError" ] }
					}
			}
		}
	};

	return widget;


});
