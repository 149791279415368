define('components/quickSearch/directive/quickSearchLabel',['require'],function(require) {
	"use strict";

	function QuickSearchLabel() {
		return {
			restrict: "AE",
			scope: { item: "=" },
			templateUrl: "app/components/quickSearch/template/quickSearchLabel.html",
			controller: "QuickSearchLabelController"
		};
	}

	return QuickSearchLabel;

});
