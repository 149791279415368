define('components/startQuote/controller/ProductLineClassCodeRowController',['require'],function(require) {
    "use strict";

    ProductLineClassCodeRowController.$inject = [
        "$scope",
        "EligibilityService",
        "ClassCodeService",
        "UserProfile"
    ];

    function ProductLineClassCodeRowController( $scope, EligibilityService, ClassCodeService, UserProfile ) {

        $scope.uiState = {
            showGuidelines: $scope.line.productLine == "bo" ? true : false,
            showDescriptions : false,
            guidelines: null,
            loading: false
        };

        $scope.desc = [];
	    $scope.wcClassCodeId = "";
	    $scope.boClassCodeId = "";
        $scope.eoClassifications = [];

        $scope.selectDescription = function (event, selected) {

            $scope.$apply(function () {
                $scope.line.classCode = selected.item;
                $scope.line.value = selected.item.value;
                if ($scope.uiState.showGuidelines) {
                    $scope.line.awaitingData = true;
                    $scope.uiState.guidelines = null;
                    $scope.uiState.loading = true;
                    $scope.getDescriptionGuidelines($scope.line.classCode.descriptionId, $scope.effectiveDate);
                }

	            if ( $scope.productLines.wc.value != null ) {
		            $scope.wcClassCodeId = $scope.productLines.wc.value;
	            }
	            if ( $scope.productLines.bo.value != null ) {
		            $scope.boClassCodeId = $scope.productLines.bo.value;
	            }
                EligibilityService.getEOGroupsBasedOnClassCode($scope.wcClassCodeId, $scope.boClassCodeId, $scope.state, $scope.effectiveDate)
                    .then(function (result) {
                        $scope.groups = result.eoGroups;
                        $scope.classifications = result.eoDescriptions;
                        $('.showEoError').addClass('hidden');
                        if (result.eoGroups.length != 0) {
                            $('.eoEligibility').removeClass('hidden');
                            $('.showEoError').addClass('hidden');
                            if (UserProfile.allowedPrograms.indexOf('miscErrorsOmissions') >= 0) {
                                $('.showEoEligible').removeClass('hidden');
                            }
                            if ($scope.productLines.eo.enabled) {
                                $('.showEoEligible').addClass('hidden');
                            }
                        }
                        else {
                            $('.showEoEligible').addClass('hidden');
                            if ($scope.productLines.eo.enabled) {
                                $('.eoEligibility').addClass('hidden');
                                $('.showEoError').removeClass('hidden');
                            }
                        }
                    })

            });

        };
        $scope.updateBusinessType = function () {
            $scope.wcClassCodeId = $scope.productLines.wc.classCode.value ? $scope.productLines.wc.classCode.value : $scope.productLines.wc.classCode;
            $scope.boClassCodeId = $scope.productLines.bo.classCode.value ? $scope.productLines.bo.classCode.value : $scope.productLines.bo.classCode;
            var isValidWcClassCode = ClassCodeService.validateClassCode($scope.wcClassCodeId, 'wc'),
                isValidBoClassCode = ClassCodeService.validateClassCode($scope.boClassCodeId, 'bo');
            if (isValidWcClassCode || isValidBoClassCode) {
                EligibilityService.getEOGroupsBasedOnClassCode($scope.wcClassCodeId, $scope.boClassCodeId, $scope.state, $scope.effectiveDate)
                    .then(function (result) {
                        $scope.groups = result.eoGroups;
                        $scope.classifications = result.eoDescriptions;
                        $('.showEoError').addClass('hidden');
                        if (result.eoGroups.length != 0) {
                            $('.eoEligibility').removeClass('hidden');
                            $('.showEoError').addClass('hidden');
                            if (UserProfile.allowedPrograms.indexOf('miscErrorsOmissions') >= 0) {
                                $('.showEoEligible').removeClass('hidden');
                            }
                            if ($scope.productLines.eo.enabled) {
                                $('.showEoEligible').addClass('hidden');
                            }
                        }
                        else {
                            $('.showEoEligible').addClass('hidden');
                            if ($scope.productLines.eo.enabled) {
                                $('.eoEligibility').addClass('hidden');
                                $('.showEoError').removeClass('hidden');
                            }
                        }
                    })
            }
        };
	    $scope.productLineCheck = function () {
		    if ( $scope.line.productLine === 'eo' ) {
			    return true;
		    }
	    };
        $scope.getDescriptionGuidelines = function( descriptionId, effectiveDate ) {
            EligibilityService.getBOClassCodeDescriptionGuidelines( descriptionId, effectiveDate )
                .then( function( result ) {
                    if( result.legacy ) {
                        $scope.uiState.guidelines = [];
                    } else {
                        $scope.uiState.guidelines = result.guidelineData;
                    }
                })
                .finally( function() {
                    $scope.uiState.loading = false;
                    $scope.line.awaitingData = false;
                })
        };

	    $scope.filterByBusinessGroup = function () {
		    if ( $scope.line.productLine === 'eo' ) {
                $scope.classCodes = $scope.classifications
                $scope.desc.length = 0;
			    $scope.uiState.showDescriptions = true;
			    angular.forEach( $scope.classCodes, function ( classCode ) {
				    if ( classCode.businessGroup != null ) {
					    if ( classCode.businessGroup === $scope.line.group.label ) {
						    $scope.desc.push( classCode );
					    }
				    }
			    } );
		    }
	    };

    }

    return ProductLineClassCodeRowController;

});
