define('components/quickLinks/controller/MassSubmissionController',['require'],function(require) {
	"use strict";

	MassSubmissionController.$inject = ["$scope", "$sce", "$modalInstance", "maverickApiUrl"];

	function MassSubmissionController( $scope, $sce, $modalInstance, maverickApiUrl ) {

		$scope.close = $modalInstance.close;

		// $sce prevents us from interpolating the iframe src attribute directly in the template, so we have to build it here
		$scope.iframeSrc = $sce.trustAsResourceUrl( maverickApiUrl + "?action=massSubmission.default" );

	}

	return MassSubmissionController;

});
