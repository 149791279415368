define('components/specialtyApplication/retrieveQuote/controller/RetrieveQuoteController',[],function() {

	RetrieveQuoteController.$inject = ["$scope","$q","$location","moment","UserProfile","UserService","SubmissionStore","SpecialtyEligibilityService","MessageContainer"];

	function RetrieveQuoteController($scope,$q,$location,moment,UserProfile,UserService,SubmissionStore,SpecialtyEligibilityService,MessageContainer)
	{

		$scope.status = {
			loading : false,
			canEdit : true,
			showText: true,
			message : "Your quote is available for 30 days",
		};
		$scope.showQuoteLink = false;
		$scope.quotesAvailable = null;

		$scope.form = {
			zipCode : SubmissionStore.zipCode == undefined ? "" : SubmissionStore.zip,
			emailId : ""
		};

		$scope.isUnAuthenticatedUser        = SubmissionStore.unAuthenticatedCL? true : false
		//disabled the requirement of needing to come from acct summary
		$scope.messages = new MessageContainer({ error: [] });

        $scope.submitForm = function () {
	        $scope.messages.reset();
	        $scope.quotesAvailable = null;
	        $scope.status.showText = true;
	        $scope.status.message  = "Retrieving your quote ...";
	        $scope.status.loading  = true;
	        $scope.status.canEdit  = false;
		    SpecialtyEligibilityService.checkQuoteAvailability(
			        $scope.form.zipCode,
			        $scope.form.emailId
		    ).then( function ( result ) {
			    $scope.quotesAvailable = result.data.isQuoteAvailable;
			    if ( $scope.quotesAvailable ) {
				    $scope.status.message = "Welcome back! Your quote is still available."
				    $scope.showQuoteLink  = false;
			    } else {
				    $scope.status.message = "We're sorry, looks like your quote has expired."
				    $scope.showQuoteLink  = true;
			    }
			    $scope.status.canEdit = true;
			    $scope.status.loading = false;
		        } ).catch( function ( err ) {
			        $scope.status.canEdit = true;
			        $scope.status.message = "";
			        var data = err.data;
			        if ( err.status === 404 ) {
				        $scope.quotesAvailable = false;
				        $scope.status.message  = "We're sorry, looks like your quote has expired."
				        $scope.showQuoteLink   = true;
			        } else {
				        $scope.status.showText = false;
				        ["zipCode", "emailId"].forEach( function ( key ) {
					        if ( data[key] ) {
						        if ( key === "zipCode" ) {
							        $scope.messages.error = $scope.messages.error.concat( data[key].map( function ( validation ) {
								        return validation.message;
							        } ) );
						        } else {
							        // we'll probably only ever get a single message back per domain object, but just in case.. map over the message array.
							        $scope.messages.error = $scope.messages.error.concat( data[key].map( function ( validation ) {
								        return validation.message;
							        } ) );
						        }
					        }
				        } );
			        }
			        $scope.status.loading = false;
		        } );

	        };

        $scope.setNewQuoteLink = function( ) {
			$location.path( "/application" );
		}
	}

	return RetrieveQuoteController;

});
