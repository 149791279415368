define( 'components/claimDetail/claimInfo/directive/claimInfo',['require'],function( require ) {
    "use strict";

    /*
     As explained in the claimDetail directive, we want to use the display the policyNumber explicitly
     passed into the directive, not the policyNumber property value in the claim object provided.
     */
    function ClaimInfo() {
        return {
            restrict: "AE",
            scope: {
                policyNumber: "=",
                claim: "="
            },
            templateUrl: "app/components/claimDetail/claimInfo/template/template.html"
        };
    }

    return ClaimInfo;

});
