define('components/startQuoteDropdown/app',['require','angular','common/service/EligibilityService','components/startQuoteDropdown/directive/startQuoteDropdown','components/startQuoteDropdown/controller/StartQuoteDropdownController'],function(require) {
	"use strict";

	// i'm not quite sure why i need to require angular here, but there is an intermittent issue where this file
	// seems to be executed prior to angular being loaded. there's no downside to loading it here as requirejs will
	// only load a given file once anyway.
	require("angular");

	var EligibilityService = require("common/service/EligibilityService"),
		startQuoteDropdown = require("components/startQuoteDropdown/directive/startQuoteDropdown"),
		StartQuoteDropdownController = require("components/startQuoteDropdown/controller/StartQuoteDropdownController");

	var component = angular.module("mPortal.components.startQuoteDropdown", []);

	component.factory("EligibilityService", EligibilityService);
	component.directive("startQuoteDropdown", startQuoteDropdown);
	component.controller("StartQuoteDropdownController", StartQuoteDropdownController);

	return component;

});
