define('specialtyApplication/app',['require','common/config/config','common/directive/app','components/specialtyApplication/search/app','components/specialtyApplication/eligibility/app','components/specialtyApplication/retrieveQuote/app','components/specialtyApplication/account/app','components/specialtyApplication/questions/app','components/specialtyApplication/section/container/app','components/specialtyApplication/result/app','components/specialtyApplication/resultSummary/app','components/specialtyApplication/payment/app','components/specialtyApplication/resume/app','components/specialtyApplication/submit/review/app','components/quoteWizard/app','shim/overmind','common/service/specialtyApplication/SlugParserService','common/service/specialtyApplication/SubmissionStore','common/service/user/UserProfile','common/service/wizard/Wizard','common/service/specialtyApplication/SpecialtyDocumentService','common/service/PaymentService','common/service/specialtyApplication/QuestionService','common/service/specialtyApplication/ResultService','common/service/specialtyApplication/CoBrandingService','common/service/analytics/TeaLeafService','common/service/analytics/GoogleAdWordsService','common/service/user/VerifySession','common/service/user/EnforceSession','specialtyApplication/controller/SpecialtyApplicationController','common/service/specialtyApplication/ApplicationStateStore','common/service/specialtyApplication/DatapointModificationService','common/service/specialtyApplication/EligibilityGuidelineService','common/service/AgencyService','common/service/user/UserService'],function(require) {
	"use strict";

	require("common/config/config");
	require("common/directive/app");
	require("components/specialtyApplication/search/app");
	require("components/specialtyApplication/eligibility/app");
	require("components/specialtyApplication/retrieveQuote/app");
	require("components/specialtyApplication/account/app");
	require("components/specialtyApplication/questions/app");
	require("components/specialtyApplication/section/container/app");
	require("components/specialtyApplication/result/app");
	require("components/specialtyApplication/resultSummary/app");
	require("components/specialtyApplication/payment/app");
	require("components/specialtyApplication/resume/app");
	require("components/specialtyApplication/submit/review/app");
	require("components/quoteWizard/app");


	var overmind = require("shim/overmind");

	var SlugParserService 					= require("common/service/specialtyApplication/SlugParserService"),
		SubmissionStore 					= require("common/service/specialtyApplication/SubmissionStore"),
		UserProfile 						= require("common/service/user/UserProfile" ),
		Wizard 								= require("common/service/wizard/Wizard" ),
		SpecialtyDocumentService 			= require("common/service/specialtyApplication/SpecialtyDocumentService" ),
		PaymentService 						= require("common/service/PaymentService" ),
		QuestionService 					= require("common/service/specialtyApplication/QuestionService" ),
		ResultService 						= require("common/service/specialtyApplication/ResultService" ),
		CoBrandingService 					= require("common/service/specialtyApplication/CoBrandingService" ),
		TeaLeafService 						= require("common/service/analytics/TeaLeafService" ),
		GoogleAdWordsService 				= require("common/service/analytics/GoogleAdWordsService"),
		VerifySession 						= require("common/service/user/VerifySession"),
		EnforceSession 						= require("common/service/user/EnforceSession"),
		SpecialtyApplicationController 		= require("specialtyApplication/controller/SpecialtyApplicationController"),
		ApplicationStateStore 				= require("common/service/specialtyApplication/ApplicationStateStore" ),
		DatapointModificationService 		= require("common/service/specialtyApplication/DatapointModificationService" ),
		EligibilityGuidelineService 		= require("common/service/specialtyApplication/EligibilityGuidelineService"),
		AgencyService 			    		= require("common/service/AgencyService"),
		UserService 						= require("common/service/user/UserService");

	var app = angular.module("mPortal.specialtyApplication", [
		"mPortal.config",
		"mPortal.directive",
		"mPortal.components.specialtyApplication.search",
		"mPortal.components.specialtyApplication.eligibility",
		"mPortal.components.specialtyApplication.retrieveQuote",
		"mPortal.components.specialtyApplication.account",
		"mPortal.components.specialtyApplication.questions",
		"mPortal.components.specialtyApplication.section.container",
		"mPortal.components.specialtyApplication.result",
		"mPortal.components.specialtyApplication.resultSummary",
		"mPortal.components.specialtyApplication.payment",
		"mPortal.components.specialtyApplication.resume",
		"mPortal.components.specialtyApplication.submit.review",
		"mPortal.components.quoteWizard"
	]);

	app.config( overmind.control() );

	app.controller("SpecialtyApplicationController",SpecialtyApplicationController);
	app.factory("SlugParserService", SlugParserService);
	app.factory("SubmissionStore", SubmissionStore);
	app.factory("UserProfile", UserProfile);
	app.factory("Wizard", Wizard);
	app.factory("SpecialtyDocumentService", SpecialtyDocumentService );
	app.factory("PaymentService", PaymentService );
	app.factory("QuestionService", QuestionService );
	app.factory("ResultService", ResultService);
	app.factory("CoBrandingService", CoBrandingService);
	app.factory("TeaLeafService", TeaLeafService);
	app.factory("GoogleAdWordsService", GoogleAdWordsService);
	app.factory("DatapointModificationService", DatapointModificationService );
	app.factory("EligibilityGuidelineService", EligibilityGuidelineService );
	app.factory("AgencyService", AgencyService );
	app.factory( "ApplicationStateStore", ApplicationStateStore );

	app.config(["$routeProvider", function( $routeProvider ) {
		var resolve = {
			__verifySessionGuard: VerifySession
		};
		var secureApplicationResolve = {
			__verifySessionGuard: EnforceSession
		};

		$routeProvider.when("/application", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'eligibility',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/retrieve", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'retrieve',
			insecure: true,
			resolve: resolve
		});


		$routeProvider.when("/application/search", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: true,
			view : 'search',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/search/:businessTypeID", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: true,
			view : 'search',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/search/tag/:businessTypeTag", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: true,
			view : 'search',
			insecure: true,
			resolve: resolve
		});


		// A application route for agents to use that ensures they log in
		$routeProvider.when("/application/agent", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'eligibility',
			insecure: false,
			resolve: secureApplicationResolve
		});

		// The route that allows agents to edit an application
		$routeProvider.when("/application/agent/edit/:applicationId", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'account',
			insecure: false,
			resolve: secureApplicationResolve
		});

		$routeProvider.when("/application/edit/:applicationId", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'account',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/resume/:applicationId", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'account',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/account", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			view : 'account',
			insecure: true,
			resolve: resolve
		});



        $routeProvider.when("/application/account/clearance", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'clearance',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/questions", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'questions',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/questions/:applicationId", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'questions',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/additional-questions", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			view : 'section',
			layout: 'vertical',
			insecure: true,
			resolve: resolve
		});


		$routeProvider.when("/application/additional-questions/:applicationId", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			view : 'section',
			layout: 'vertical',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/section", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'section',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/section/:applicationId", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'section',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/result-summary/:insuredId", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: true,
			view : 'result-summary',
			insecure: false,
			resolve: resolve
		});

		$routeProvider.when("/application/result", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: true,
			view : 'result',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/result/:applicationId", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: true,
			view : 'result',
			insecure: false,
			resolve: resolve
		});

		$routeProvider.when("/application/result/:applicationId/account/:accountId", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: true,
			view : 'result',
			insecure: false,
			resolve: resolve
		});

		$routeProvider.when("/application/payment", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: true,
			view : 'payment',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/resume", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: true,
			view : 'resume',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/review", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'review',
			insecure: true,
			resolve: resolve
		});

		$routeProvider.when("/application/review/:applicationId", {
			templateUrl: "app/specialtyApplication/template/template.html",
			controller: "SpecialtyApplicationController",
			nav_hidden: false,
			view : 'review',
			insecure: true,
			resolve: resolve
		});

	}]);

	app.run(["overmindScope", "$location", "UserProfile", "SlugParserService", "SubmissionStore", "UserService",
		function( overmindScope, $location, UserProfile, SlugParserService, SubmissionStore, UserService ) {

		if( UserProfile.shortLinkParams ) {
			angular.extend( SubmissionStore, UserProfile.shortLinkParams );
		}

		overmindScope.$on("mPortal.specialtyApplication:routeNotFound", function( event, originalEvent, next, current ) {
			var path = $location.path(),
				parts = path.split("/"),
				slicePoint = parts[ 2 ] == "agent" ? 3 : 2,
				slugs = parts.slice( slicePoint, parts.length );

			SlugParserService.populateUserProfileFromUrlSlugs( UserProfile, slugs ).then(function() {
				if( slicePoint === 3 ) {
					$location.path("/application/agent").replace();
				} else {
					var btag = UserProfile.cobranding.id != ""? null : UserProfile.marketedProgram.businessTypeTag;
					if(btag){
						SubmissionStore.unAuthenticatedCL = true;
					}
					$location.path("/application").replace();

				}
			}).catch(function() {
				UserService.getUserProfile().then( function( profile ) {
					if( profile.isLoggedIn ) {
						$location.path( "/error/agent/404" ).replace();
					} else {
						$location.path( "/error/404" ).replace();
					}
				});
			});

		});

	}]);

	return app;

});
