define( 'common/service/VeriskService',[],function () {
	"use strict";

	VeriskService.$inject = ["$http", "archApiUrl"];

	function VeriskService($http, archApiUrl) {

		var service = {};

		service.getRisks = function(submission) {

            var body = submission;

            return $http({
                method: "POST",
                url:"/verisk-service-api/verisk-risk-search", 
                headers: {
                    "Content-Type": "application/json"
                    
                },
                data: body
            }).then(function(result){
                return result.data;
            });

		};

        service.getStructures = async function(vrsubmission) {

            return $http({
                method: "POST",
                url:"/verisk-service-api/verisk-risk", 
                headers: {
                    "Content-Type": "application/json"
                },
                data: vrsubmission
               
            }).then(function(result){
                return result.data;
            });

		};

        service.getLossCostReport_async = async function(vrsubmission) {
            //console.log("getLossCostReport vrsubmission " , vrsubmission)
            return $http({
                method: "POST",
                url:"/verisk-service-api/verisk-loss-cost-report", 
                headers: {
                    "Content-Type": "application/json"
                    
                },
                data: vrsubmission
            }).then(function(result){
                return result.data;
            });

		};

        service.getLossCostReport =  function(vrsubmission) {
            //console.log("getLossCostReport vrsubmission " , vrsubmission)
            return $http({
                method: "POST",
                url:"/verisk-service-api/verisk-loss-cost-report", 
                headers: {
                    "Content-Type": "application/json"
                    
                },
                data: vrsubmission
            }).then(function(result){ 
              return result.data;
            });

		};

		return service;
	}

	return VeriskService;

});
