define( 'common/service/BundlePaymentService',['require'],function ( require ) {
	"use strict";

	BundlePaymentService.$inject = ["$http", "$window", "archApiUrl"];

	function BundlePaymentService( $http, $window, archApiUrl ) {

		var service = {};

		service.getPaymentData = function( accountId ) {
			return $http.get( archApiUrl + "account/" + ( accountId ? accountId + "/" : "" ) + "payment-system" ).then(function( result ) {
				return result.data;
			});
		};

		return service;
	}

	return BundlePaymentService;

} );
