define('components/policyDetail/policyDetail/app',['require','common/directive/app','components/policyDetail/accountHistory/app','components/policyDetail/policyBilling/app','components/policyDetail/policyClaims/app','components/policyDetail/policyDocuments/app','components/policyDetail/policyInfo/app','components/paymentLink/app','components/lossRuns/app','components/policyDetail/policyDetail/directive/policyDetail','common/service/LossRunsService','components/policyDetail/policyDetail/controller/PolicyDetailController'],function(require) {
	"use strict";

	require("common/directive/app");
	require("components/policyDetail/accountHistory/app");
	require("components/policyDetail/policyBilling/app");
	require("components/policyDetail/policyClaims/app");
	require("components/policyDetail/policyDocuments/app");
	require("components/policyDetail/policyInfo/app");
	require("components/paymentLink/app");
	require("components/lossRuns/app");

	var policyDetail = require("components/policyDetail/policyDetail/directive/policyDetail" ),
		LossRunsService = require("common/service/LossRunsService"),
		PolicyDetailController = require("components/policyDetail/policyDetail/controller/PolicyDetailController");

	var component = angular.module("mPortal.policyDetail.policyDetail", [
		"mPortal.directive",
		"mPortal.policyDetail.accountHistory",
		"mPortal.policyDetail.policyBilling",
		"mPortal.policyDetail.policyClaims",
		"mPortal.policyDetail.policyDocuments",
		"mPortal.policyDetail.policyInfo",
		"mPortal.paymentLink",
		"mPortal.lossRuns"
	]);

	component.directive("policyDetail", policyDetail);
	component.controller("PolicyDetailController", PolicyDetailController);
	component.factory("LossRunsService", LossRunsService);

	return component;

});
