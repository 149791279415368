define('components/specialtyApplication/account/app',['require','common/directive/app','components/specialtyApplication/account/directive/account','components/specialtyApplication/account/directive/clearance','components/specialtyApplication/account/controller/InsuredAccountController','components/specialtyApplication/account/controller/ClearanceController','components/specialtyApplication/account/controller/EditableSubmissionsModalController','common/service/specialtyApplication/AccountService','common/service/MessageContainer','common/service/user/UserProfile'],function(require) {
	"use strict";

	require("common/directive/app");

	var account                     = require("components/specialtyApplication/account/directive/account"),
		clearance                   = require("components/specialtyApplication/account/directive/clearance"),
		InsuredAccountController    = require("components/specialtyApplication/account/controller/InsuredAccountController"),
		ClearanceController         = require("components/specialtyApplication/account/controller/ClearanceController"),
		EditableSubmissionsModalController = require("components/specialtyApplication/account/controller/EditableSubmissionsModalController"),

		AccountService      = require("common/service/specialtyApplication/AccountService"),
		MessageContainer    = require("common/service/MessageContainer"),
		UserProfile         = require( "common/service/user/UserProfile" );

	var component = angular.module("mPortal.components.specialtyApplication.account", [ "ui.bootstrap" ] );

	component.directive("specialtyApplicationAccount", account);
	component.directive("specialtyApplicationClearance", clearance);
	component.controller("SpecialtyApplicationAccountController", InsuredAccountController);
	component.controller("SpecialtyApplicationClearanceController", ClearanceController);
	component.controller("EditableSubmissionsModalController", EditableSubmissionsModalController);

	component.factory("AccountService", AccountService);
	component.factory("MessageContainer", MessageContainer);
	component.factory( "UserProfile", UserProfile );

	return component;

});
