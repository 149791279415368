define('components/widget/key-characteristics/directive/keyCharacteristics',['require'],function(require){

   "use strict";

    keyCharacteristics.$inject = [];
     function keyCharacteristics(){

         return{

             restrict : "AE",
             scope: {
                 model                : "=",
                 options              : "=",
                 quantityControlId    : "="
             },
             templateUrl: "app/components/widget/key-characteristics/template/template.html",
             controller: "KeyCharacteristicsController"

         };
     }
     return keyCharacteristics
});


