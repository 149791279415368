define('common/service/interceptor/CancellableRequestInterceptor',[],function() {
	"use strict";

	CancellableRequestInterceptor.$inject = ["$q"];

	function CancellableRequestInterceptor( $q ) {
		return {
			request: function( config ) {
				if( config.timeout === undefined ) {
					var canceller = $q.defer();

					config.timeout = canceller.promise;
					config.cancel = canceller.resolve;
				}

				return config;
			}
		};
	}

	return CancellableRequestInterceptor;

});
