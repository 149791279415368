define('components/widget/policy-information/coverage-table/directive/coverageTable',['require'],function(require) {
	"use strict";

	coverageTable.$inject = [];

	function coverageTable() {
		return {
			restrict: "AE",
			scope:{
				model	 : "=",
				options  : "=",
				fields	 : "=",
				form	 : "="
			},
			templateUrl: "app/components/widget/policy-information/coverage-table/template/template.html",
			controller: "coverageTableController"
		};
	}

	return coverageTable;

});
