define('components/startQuoteCommercialPackage/app',['require','components/startQuoteCommercialPackage/directive/startQuoteCommercialPackage','components/startQuoteCommercialPackage/controller/StartQuoteCommercialPackageController','common/service/MarketedProgramService'],function(require) {
	"use strict";

	var startQuoteCommercialPackage = require("components/startQuoteCommercialPackage/directive/startQuoteCommercialPackage"),
		StartQuoteCommercialPackageController = require("components/startQuoteCommercialPackage/controller/StartQuoteCommercialPackageController"),
		MarketedProgramService = require("common/service/MarketedProgramService");

	var component = angular.module("mPortal.components.startQuoteCommercialPackage", []);

	component.directive("startQuoteCommercialPackage", startQuoteCommercialPackage);
	component.controller("StartQuoteCommercialPackageController", StartQuoteCommercialPackageController);
	component.factory("MarketedProgramService", MarketedProgramService);

	return component;

});
