define('components/specialtyApplication/section/detail/app',['require','components/specialtyApplication/section/detail/directive/sectionDetail','components/specialtyApplication/section/detail/controller/SectionDetailController','common/service/user/UserProfile'],function(require) {
	"use strict";

	var section_directive	= require("components/specialtyApplication/section/detail/directive/sectionDetail"),
		section_controller	= require("components/specialtyApplication/section/detail/controller/SectionDetailController"),
		
		user_profile 	 	= require( "common/service/user/UserProfile" );

	var component = angular.module("mPortal.components.specialtyApplication.section.detail", []);

	component.directive("specialtyApplicationSectionDetail", section_directive);
	component.controller("SectionDetailController",section_controller);
	component.factory( "UserProfile", user_profile);

	return component;

});
