define('components/policyDetail/policyInfo/app',['require','components/policyDetail/policyInfo/directive/policyInfo','components/policyDetail/policyInfo/controller/PolicyInfoController','common/service/AgencyService'],function(require) {
	"use strict";

	var policyInfo = require("components/policyDetail/policyInfo/directive/policyInfo" ),
		PolicyInfoController = require("components/policyDetail/policyInfo/controller/PolicyInfoController"),
		AgencyService = require("common/service/AgencyService");

	var component = angular.module("mPortal.policyDetail.policyInfo", []);

	component.directive("policyInfo", policyInfo);
	component.controller("PolicyInfoController", PolicyInfoController);
	component.factory( "AgencyService", AgencyService );
	return component;

});
