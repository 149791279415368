define('nav/app',['require','common/config/config','components/quickSearch/app','components/startQuoteDropdown/app','components/mvpSwitch/app','shim/overmind','nav/controller/NavController','common/service/NavService','common/service/user/UserService','common/service/user/UserProfile','common/service/wizard/Wizard','common/service/EligibilityService','lib/moment-timezone/builds/moment-timezone-with-data-2010-2020'],function(require) {
	"use strict";

	require("common/config/config");
	require("components/quickSearch/app");
	require("components/startQuoteDropdown/app");
	require("components/mvpSwitch/app");

	var overmind = require("shim/overmind"),
		NavController = require("nav/controller/NavController"),
		NavService = require("common/service/NavService"),
		UserService = require("common/service/user/UserService"),
		UserProfile = require("common/service/user/UserProfile"),
		Wizard 	= require("common/service/wizard/Wizard"),
		EligibilityService = require("common/service/EligibilityService"),
		moment = require("lib/moment-timezone/builds/moment-timezone-with-data-2010-2020");

	var app = angular.module("mPortal.nav", [
		"mPortal.config",
		"mPortal.components.quickSearch",
		"mPortal.components.startQuoteDropdown",
		"mPortal.components.mvpSwitch"
	]);

	app.config( overmind.control() );
	app.controller("NavController", NavController);
	app.factory("NavService", NavService);
	app.factory("UserService", UserService);
	app.factory("UserProfile", UserProfile);
	app.factory("Wizard",Wizard);
	app.factory("EligibilityService", EligibilityService);
	app.value( "moment", moment );
	
	return app;

});
