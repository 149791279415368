define( 'components/viewApplication/app',['require','components/viewApplication/directive/viewApplication','components/viewApplication/controller/ViewApplicationController','common/service/specialtyApplication/SpecialtyDocumentService','common/service/specialtyApplication/SubmissionStore'],function ( require ) {
	"use strict";

	var viewApplication = require( "components/viewApplication/directive/viewApplication" ),
		ViewApplicationController = require( "components/viewApplication/controller/ViewApplicationController" ),
		SpecialtyDocumentService = require( "common/service/specialtyApplication/SpecialtyDocumentService" ),
		SubmissionStore = require( "common/service/specialtyApplication/SubmissionStore" );

	var component = angular.module( "mPortal.viewApplication", ["ui.bootstrap", "mPortal.config"] );

	component.directive( "viewApplication", viewApplication );
	component.controller( "ViewApplicationController", ViewApplicationController );
	component.factory( "SpecialtyDocumentService", SpecialtyDocumentService );
	component.factory( "SubmissionStore", SubmissionStore );

	return component;
} );
