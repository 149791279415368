define('components/widget/optional-endorsements/item-description-limit/directive/itemDescriptionLimit',['require'],function(require) {
    "use strict";

    itemDescriptionLimit.$inject = [];

    function itemDescriptionLimit() {
        return {
            restrict: "AE",
            scope:{
                model	            : "=",
                options             : "=",
                quantityControlId   : "="
            },
            templateUrl: "app/components/widget/optional-endorsements/item-description-limit/template/template.html",
            controller: "ItemDescriptionLimitController"
        };
    }

    return itemDescriptionLimit;

});
