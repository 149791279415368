define( 'components/documentDeliveryPreferencesLink/controller/DocumentDeliveryPreferencesModalController',['require'],function( require ) {
    "use strict";

    DocumentDeliveryPreferencesModalController.$inject = [ "$scope", "$modalInstance", "ResultService", "submissionId", "docDeliveryDataAvailable", "docDeliveryConfig", "insuredType" ];

    function DocumentDeliveryPreferencesModalController( $scope, $modalInstance, ResultService, submissionId, docDeliveryDataAvailable, docDeliveryConfig, insuredType ) {
        $scope.submissionId = submissionId;
        $scope.docDeliveryDataAvailable = docDeliveryDataAvailable;
        $scope.docDeliveryConfig = docDeliveryConfig;
        $scope.insuredType = insuredType;
        $scope.deliveryErrors = [];
        $scope.inProgress = false;
        $scope.button_text = "Save Changes";

        $scope.close = $modalInstance.close;

        $scope.getSaveButtonText = function() {
            return $scope.inProgress ? "Saving..." : "Save Changes";
        };

        $scope.saveSubmissionDeliveryPreferences = function() {
            $scope.deliveryErrors = [];
            $scope.inProgress = true;

            ResultService.saveSubmissionDeliveryPreferences( $scope.submissionId, $scope.docDeliveryConfig ).then( function ( result ) {
                if( result.error ) {
                    $scope.deliveryErrors = result.errorMessages;
                    $scope.inProgress = false;

                } else {
                    $scope.close();
                }
            } );
        };

    }

    return DocumentDeliveryPreferencesModalController;
});
