define('components/accountSummary/insuredAccountActivity/directive/insuredAccountActivity',['require'],function(require) {
	"use strict";

	function InsuredAccountActivity() {
		return {
			restrict: "AE",
			scope: {
				activityItems: "="
			},
			templateUrl: "app/components/accountSummary/insuredAccountActivity/template/template.html"
		};
	}

	return InsuredAccountActivity;

});
