define('components/specialtyApplication/section/container/directive/sectionContainer',['require'],function(require) {
	"use strict";

	SectionContainer.$inject = [];

	function SectionContainer() {
		return {
			restrict: "AE",
			scope: {
				applicationId: "=",
				layout : "="
			},
			templateUrl: "app/components/specialtyApplication/section/container/template/template.html",
			controller: "SectionContainerController"
		};
	}

	return SectionContainer;

});
