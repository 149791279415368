define('components/specialtyApplication/section/container/app',['require','angular-formly','angular-formly-templates-bootstrap','components/specialtyApplication/section/detail/app','components/specialtyApplication/section/container/directive/sectionContainer','components/specialtyApplication/section/container/controller/SectionContainerController','components/specialtyApplication/questions/config/configureFormly','components/specialtyApplication/questions/config/widgetFormlyConfiguration','components/widget/policy-information/coverage-table/app','common/service/user/UserProfile'],function(require) {
	"use strict";

	require("angular-formly");
	require("angular-formly-templates-bootstrap");
	require("components/specialtyApplication/section/detail/app");

	var section_directive	= require("components/specialtyApplication/section/container/directive/sectionContainer"),
		section_controller	= require("components/specialtyApplication/section/container/controller/SectionContainerController"),
		set_general_config	= require("components/specialtyApplication/questions/config/configureFormly"),
		set_widget_config	= require("components/specialtyApplication/questions/config/widgetFormlyConfiguration"),
		coverage_table 		= require("components/widget/policy-information/coverage-table/app"),
		user_profile 	 	= require( "common/service/user/UserProfile" );

	var component = angular.module( "mPortal.components.specialtyApplication.section.container", [
									"mPortal.components.specialtyApplication.section.detail",
									"formly",
									"formlyBootstrap",
									"mPortal.components.widget.policy-information.coverage-table"]);

	component.directive("specialtyApplicationSectionContainer", section_directive);
	component.controller("SectionContainerController",section_controller);
	component.factory( "UserProfile", user_profile);


/*	component.run(["formlyConfig", "formlyApiCheck", function( formlyConfig, formlyApiCheck ) {
		// Sets generic formly configuration settings
		set_general_config( formlyConfig, formlyApiCheck );
		// Sets formly configuration settings specific to widgets
		set_widget_config( formlyConfig, formlyApiCheck );
	}]);
*/

	return component;

});
