define('error/controller/InvalidTokenController',['require'],function(require) {
	"use strict";

	InvalidTokenController.$inject = ["$scope", "$routeParams"];

	function InvalidTokenController( $scope,  $routeParams ) {

		console.log($routeParams);
		$scope.token = $routeParams.token; 

	}

	return InvalidTokenController;
});
