define('components/message/form/directive/messageForm',['require'],function(require) {
	"use strict";

	MessageForm.$inject = [];

	function MessageForm() {
		return {
			restrict: "AE",
			scope: {
				account			: "=",
				accounts		: "="
			},
			templateUrl: "app/components/message/form/template/template.html",
			controller: "messageFormController"
		};
	}

	return MessageForm;

});
