define('components/specialtyApplication/eligibility/app',['require','components/specialtyApplication/eligibility/directive/eligibility','components/specialtyApplication/eligibility/controller/EligibilityController','common/service/specialtyApplication/SpecialtyEligibilityService','moment','common/service/MessageContainer'],function(require) {
	"use strict";

	var eligibility = require("components/specialtyApplication/eligibility/directive/eligibility"),
		EligibilityController = require("components/specialtyApplication/eligibility/controller/EligibilityController"),
		SpecialtyEligibilityService = require("common/service/specialtyApplication/SpecialtyEligibilityService"),
		moment = require("moment"),
		MessageContainer = require("common/service/MessageContainer");

	var component = angular.module("mPortal.components.specialtyApplication.eligibility", []);

	component.directive("specialtyApplicationEligibility", eligibility);
	component.controller("SpecialtyApplicationEligibilityController", EligibilityController);
	component.factory("SpecialtyEligibilityService", SpecialtyEligibilityService);
	component.factory("MessageContainer", MessageContainer);
	component.value("moment", moment);

	return component;

});
