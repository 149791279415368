define('components/applicationData/applicationData/config/widgets/ViewBuildingOEsAndAIsWidget',['require'],function(require) {
    "use strict";

    var widget = {};

    widget.getStructure = function (){

        return {

            "ViewBuildingOEsAndAIs": {wrapper: null, fieldGroup:
                {
                    "Location OE Label": {wrapper: null,type: "omittedHeader", wrapperOverride: true},
                    "Spoilage" : {wrapper: null, wrapperOverride: true, orderNumber: 1},
                    "Spoilage Limit" : {wrapper: ["LeftHorPolicyOE"],type: "setLocation", wrapperOverride: true, orderNumber: 2},
                    "Cause of Loss" : {wrapper: ["RightHorPolicyOE"],type: "setLocation", wrapperOverride: true, orderNumber: 3},
                    "Spoilage Refrigeration" : {wrapper: ["FullHorPolicyOEWithoutSpan"],type: "setLocation", wrapperOverride: true, orderNumber: 4},
                    "Spoilage Refrigeration Explain" : {wrapper: ["FullHorPolicyOEWithoutSpan"],type: "setLocation", wrapperOverride: true, orderNumber: 5},
                    "Spoilage Perishable Other" : {wrapper: ["FullHorPolicyOEWithoutSpan"],type: "setLocation", wrapperOverride: true, orderNumber: 6},

                    "Condo Coverage Optional": {wrapper: null, wrapperOverride: true,orderNumber : 7},
                    "Condo Loss Assessment" : {wrapper: ["viewLOI"],type: "setLocation", wrapperOverride: true, orderNumber: 8},
                    "Condo Real Property" : {wrapper: ["viewCondoRealProperty"],type: "setLocation", wrapperOverride: true, orderNumber: 9},
                    "Condo Deductible" : {wrapper: ["RightHorPolicyOE"],type: "setLocation", wrapperOverride: true, orderNumber: 10},
                    "Restaurants": { wrapper: null, wrapperOverride: true, orderNumber: 11 },
                    "Contamination Limit":{wrapper: [ "viewContaminationLimit" ], type: 'setLocation', wrapperOverride: true,orderNumber: 12},
                    "Advertising Limit": { wrapper: ["viewAdvertisingLimit"], type: 'setLocation', wrapperOverride: true,orderNumber: 13 },
                    "Restaurant Occ Limit": {wrapper: ["viewSquareFootage"],type: "setLocation", wrapperOverride: true, orderNumber: 14},
                    "viewOutdoorSigns":{ wrapper: ["hiredAutoSection"], type: 'setLocation',wrapperOverride: true, orderNumber: 15 },
                    "viewOutdoorSignsLimit": {wrapper: [ "stopGapLimit" ], type: 'setLocation', wrapperOverride: true,orderNumber: 16},
                    "AI Building Owner" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Building Owner Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Building Owner Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Building Owner Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Building Owner Address 2": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Building Owner City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Building Owner State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Building Owner Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

                    "AI Co Owner" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Co Owner Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Co Owner Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Co Owner Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Co Owner Address 2": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Co Owner City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Co Owner State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Co Owner Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

                    "Mortgage Holder" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Mortgage Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Mortgage Holder Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Mortgage Holder Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Mortgage Holder Address 2": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Mortgage Holder City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Mortgage Holder State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Mortgage Holder Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Mortgage Holder Account" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "AI Lessor of Leased Equipment":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Leased Equipment Number":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Leased Equipment Desc":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Leased Equipment Name":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Leased Equipment Address 1":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Leased Equipment Address 2":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Leased Equipment Zip":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Leased Equipment City":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Leased Equipment State":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

                    "Loss Payable" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Loss Payable Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Loss Payee Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Loss Payee Address" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Loss Payee Address 2": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Loss Payee City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Loss Payee State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Loss Payee Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Loss Payable Description" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Loss Payable Clause" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "AI Mortgagee" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Mortgagee Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Mortgagee Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Mortgagee Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Mortgagee Address 2" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Mortgagee Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Mortgagee City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Mortgagee State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "AI Managers or Lessors" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Managers or Lessors Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Managers or Lessors Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Managers or Lessors Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Managers or Lessors Address 2" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Managers or Lessors Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Managers or Lessors City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Managers or Lessors State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "AI Vendors" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Vendors Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Vendors Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Vendors Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Vendors Address 2" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Vendors Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Vendors City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Vendors State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Vendors Product Desc" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "AI Owner of Leased Land" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owner of Leased Land Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owner of Leased Land Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owner of Leased Land Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owner of Leased Land Address 2" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owner of Leased Land City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owner of Leased Land State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owner of Leased Land Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true}
                }
            }
        };
    };

    widget.configureFormly = function (formlyConfig, formlyApiCheck) {


        formlyConfig.setWrapper({
            name: "FullHorPolicyOEWithoutSpan",
            template:
            '<div class="viewItem" >' +
            '<formly-transclude></formly-transclude>' +
            '<div  style="width:96%; line-height: 0px; margin-left: 13px; overflow: hidden; word-wrap: break-word">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span style="margin-left: 6px; line-height: 18px; overflow: hidden; word-wrap: break-word">{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "viewLOI",
            template:
            '<div class="viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span2" style="width: 30.1%; max-height: 25px; margin-left: 13px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span style="margin-left: 6px">{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "viewCondoRealProperty",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:19.9%; margin-left: 0%; max-height: 25px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "viewAdvertisingLimit",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width: 37.4%; margin-left: 0%; max-height: 28px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "viewContaminationLimit",
            template:
            '<div class="viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span2" style="width: 30%; max-height: 28px; margin-left: 13px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span style="margin-left: 6px">{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });

    };

    return widget;
});
