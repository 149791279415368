define('newsArchives/app',['require','angular-sanitize','common/config/config','shim/overmind','common/service/NewsService','newsArchives/controller/NewsArchivesController'],function(require) {
	"use strict";

	require("angular-sanitize");
	require("common/config/config");

	var overmind = require("shim/overmind");

	var NewsService = require("common/service/NewsService"),
		NewsArchivesController = require("newsArchives/controller/NewsArchivesController");

	var app = angular.module("mPortal.newsArchives", [
		"ngSanitize",
		"mPortal.config"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider.when("/news/archives", {
			templateUrl: "app/newsArchives/template/archives.html",
			controller: "NewsArchivesController"
		});
	}]);

	app.factory("NewsService", NewsService);
	app.controller("NewsArchivesController", NewsArchivesController);

	return app;

});
