define('common/directive/loadingButton',['require'],function(require) {
	"use strict";

	function LoadingButton() {
		return {
			restrict: "A",
			scope: {
				loading: "=loadingButton",
				statusText: "=?"
			},
			link: function( scope, element, attrs ) {
				var $element = $(element),
					originalText = $element.text(),
					statusText = scope.statusText || "Processing...";

				scope.$watch("loading", function( loading ) {
					$element.attr("disabled", loading);
					$element.text( loading ? statusText : originalText );
				});
			}
		};
	}

	return LoadingButton;

});
