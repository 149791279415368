define( 'components/specialtyApplication/result/controller/ResultController',[],function () {

	ResultController.$inject = [
		"$scope",
		"$location",
		"SubmissionStore",
		"ResultService",
		"TeaLeafService",
		"UserProfile",
		"$routeParams"
	];

	function ResultController(
		$scope,
	    $location,
		SubmissionStore,
	    ResultService,
	    TeaLeafService,
	    UserProfile,
	    $routeParams
	) {

		$scope.uiState = {
			loading: true,
			requestError: false,
			visibleApplicationQuote: 0,
			showSummary: $routeParams.accountId || $scope.applicationId ? false : true
		};
		$scope.accountId = $routeParams.accountId;
		$scope.userType = UserProfile.type;
		$scope.outcomes = [];
		$scope.luxonData = {
			luxon_id : null,
			luxon_partition_key : null,
		};
		/*
		 There are three ways this page/route can be accessed:
		 Scenario A):  user is moved to this page after "Submit Application" button is triggered in questions component.
		 Scenario B):  user arrives on page via a special hyperlink contained in an email.
		 Scenario C):  an authenticated user arrives on page via a hyperlink to /application/result/:applicationId.

		 Scenario A is the continuation of an existing session, so certain data may already have set values in the SubmissionStore service object:
		 --applicationId:  definitely set.
		 --applicant: likely set as a result of passing the Clearance stage.
		 --status: current quote status, possibly set via the final response from the "Submit Application" action.

		 So in Scenario A, both the "status" endpoint call and the "document list" endpoint call can be made because the applicationId value
		 required for each of those calls is present.  The result of the "status" endpoint call will definitely retrieve the current quote status
		 and SHOULD be able to retrieve the "applicant" value (the insured name) from the data returned.

		 With Scenario B, the email hyperlink will trigger a chain of events that will end up populating the SubmissionStore object with some
		 values before redirecting to this controller/view.  Likely outcome:
		 --applicationId: definitely set.
		 --applicant: null
		 --status: null

		 With Scenario C, even if the SubmissionStore was populated, we cannot trust its contents and need to go off the route parameters.

		 So the logic on this page needs to accommodate all of these scenarios
		 */

		$scope.getOutcomes = function() {
			if( $routeParams.applicationId || $scope.applicationId ){
				SubmissionStore.applicationId = $routeParams.applicationId ? $routeParams.applicationId : $scope.applicationId;
			}

			if( SubmissionStore.statusResults.length ) {
				$scope.collectOutcomes( SubmissionStore.statusResults );
				// Remove result data so updated status data ( resulting from a bind attempt which returns here ) can be retrieved.
				SubmissionStore.statusResults = [];
			} else {
				ResultService.getStatus( SubmissionStore.applicationId )
					.then( function ( response ) {
						if( response.error ) {
							$scope.uiState.requestError = true;
							$scope.uiState.loading = false;
						} else {
							$scope.collectOutcomes( response.data );
						}

					})
					.catch( function( err ) {
						$scope.uiState.requestError = true;
						$scope.uiState.loading = false;
					});
			}

		};

		$scope.collectOutcomes = function( resultData ) {
			for( var index = 0; index < resultData.length; index++ ) {
				$scope.outcomes.push( $scope.parseOutcome( resultData[ index ] ) )
			}
			$scope.accountName = $scope.outcomes[ 0 ].accountName;
			$scope.uiState.loading = false;
		};

		$scope.parseOutcome = function( outcome ) {
			var quoteStatus;
			switch( outcome.masterquote.phase ) {
				case 25:
					quoteStatus = outcome.masterquoteinstance.paymentProcessed ? "Policy" : "Quote";
					break;
				default:
					quoteStatus = ResultService.getResultType( outcome.masterquote.portalStatus );
					break;
			}
			return {
				accountId: outcome.masterquote.masterquoteid.split("-")[0],
				accountName: outcome.insured.name,
				agentCanEdit: outcome.agentCanEdit,
				applicationId: outcome.masterquoteinstance.applicationid,
				effectiveDate: outcome.masterquote.effdate,
				marketedProgram: {
					id: outcome.marketedProgram.value,
					name: outcome.marketedProgram.name,
					abbreviation: outcome.marketedProgram.abbreviation,
					isUploadBasedSubmission : outcome.marketedProgram.isUploadBasedSubmission
				},
				portalStatus: outcome.masterquote.portalStatus,
				premium: quoteStatus == "Quote" ? outcome.masterquoteinstance.writtentotal : null,
				quoteStatus: quoteStatus,
				result: outcome,
				responseStatus: outcome.status,
				submissionId: outcome.masterquote.masterquoteid
			}
		};

		$scope.showApplicationQuote = function( applicationId ) {
			$scope.uiState.visibleApplicationQuote = applicationId;
		};

		$scope.hideApplicationQuote = function( applicationId ) {
			$scope.uiState.visibleApplicationQuote = -1;
		};

		$scope.messageUW = function( accountId, submissionId , isApplicationUpload ) {
			var url = "/account-summary/" + accountId + "/new-message/" + submissionId;

			if(isApplicationUpload)
			{
				url += "/application"
			}

			$location.path( url );
		};

		$scope.getOutcomes();

	}

	return ResultController;

} );
