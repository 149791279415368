define('components/widget/inland-marine/fine-item-limit/directive/fineItemLimit',['require'],function(require) {
    "use strict";

    fineItemLimit.$inject = [];

    function fineItemLimit() {
        console.log("Inside Inland Directive");
        return {
            restrict: "AE",
            scope:{
                model	            : "=",
                options             : "=",
                quantityControlId   : "="
            },
            templateUrl: "app/components/widget/inland-marine/fine-item-limit/template/template.html",
            controller: "FineItemLimitController"
        };
    }

    return fineItemLimit;

});
