define('components/accountSummary/quoteSummary/controller/QuoteSummaryController',['require'],function(require) {
    "use strict";

    QuoteSummaryController.$inject = ["$scope", "LossRunsService", "moment", "ResultService", "$q"];

    function QuoteSummaryController( $scope, LossRunsService, moment, ResultService, $q ) {

        $scope.luxonData = {
            luxon_id : null,
            luxon_partition_key : null,
            modified_date : null
        };

        $scope.showLossRuns = false;
        if(localStorage.getItem('mportal-show-loss-runs'))
        {
            $scope.showLossRuns = localStorage.getItem('mportal-show-loss-runs') === 'true';
        }

        $scope.isAvailableForEdit = false;
        $scope.outcomeType = ResultService.getResultType( $scope.quote.status );
        if ( $scope.quote.luxonId !== null && $scope.quote.luxonPartitionKey !== null ) {
            var promiseArray = [
                ResultService.getSubmissionStatus( $scope.quote.luxonId, $scope.quote.luxonPartitionKey, $scope.quote.modifiedDate )
            ];
            $q.all( promiseArray ).then( function ( results ) {
                $scope.luxonChildSubmissionStatus = results[0];
                if ( $scope.luxonChildSubmissionStatus.quotes_available_to_edit ) {
                    $scope.luxonData.luxon_id = $scope.luxonChildSubmissionStatus.id;
                    $scope.luxonData.luxon_partition_key = $scope.luxonChildSubmissionStatus.partition_key;
                    $scope.luxonData.modified_date = $scope.quote.modifiedDate;
                }
                $scope.isAvailableForEdit = true;
            } );
        } else {
            $scope.isAvailableForEdit = true;
        }

        $scope.openLossRunsWindow = function() {
            LossRunsService.openLossRunsWindow( $scope.account.piNumber );
        };

        $scope.isLossRunVisible = function() {
            if($scope.showLossRuns){
                return $scope.quote.lossRunEnabled && ( $scope.user.type.toLowerCase() == 'agent' || ( $scope.quote.insuredType === 'Direct' && LossRunsService.showInsuredLossRuns( $scope.quote.agencyId ) ) );
            }
            return false;
        };

        $scope.isQuoteEffectiveDateValid = function() {
            if ( moment( $scope.quote.marketedProgramEffectiveDate, "YYYY-MM-DD" ).isSameOrBefore( $scope.quote.effectiveDate, "YYYY-MM-DD" ) )
                return true;
            else
                return false;
        };

        $scope.showViewApplicationLink = function() {
            return $scope.quote.viewApplicationAllowed == 1 && $scope.quote.applicationId && ($scope.user.type == 'Agent' || $scope.user.agencyEmulation) && !($scope.quote.isUploadBasedSubmission) &&
                $scope.isQuoteEffectiveDateValid();
        };

        $scope.doesUserTrackBilling = function() {
            return $scope.quote.billType && $scope.quote.billType === ( $scope.user.type === 'Agent' ? $scope.quote.billType : 'Direct bill');
        };

        $scope.isPaymentLinkVisible = function() {
            return $scope.isBillingInfoVisible() && $scope.quote.paymentEnabled && $scope.account.piNumber;
        };

        $scope.isBillingInfoVisible = function() {
            return $scope.quote.isPolicy && $scope.quote.isBillingDataAvailable && $scope.isViewBillingAvailable();
        };

	    $scope.isViewBillingAvailable = function() {
		    if ( $scope.quote.billType && $scope.user.type !== 'Insured' ) {
			    if( $scope.user.type === 'Internal' && $scope.quote.billType === 'Agency bill' && $scope.user.insuredEmulation ) {
				    return false
			    }
			    return true
		    }
		    else if( $scope.user.type === 'Insured' && $scope.quote.billType === 'Direct bill' ) {
			    return true
		    }
	    };

        $scope.isPayPlanVisible = function() {
            return $scope.quote.payPlan && $scope.doesUserTrackBilling();
        };

        $scope.isBillTypeVisible = function() {
            return $scope.quote.billType && $scope.quote.payPlan;
        };

        $scope.isBalanceVisible = function() {
            return $scope.isViewBillingAvailable() && ($scope.quote.balance || $scope.quote.balance === 0);
        };

        $scope.isCurrentDueVisible = function() {
            return $scope.isViewBillingAvailable() && ($scope.quote.currentDue || $scope.quote.currentDue === 0);
        };

        $scope.isRenewalDepositDueVisible = function() {
            return $scope.isViewBillingAvailable() && ($scope.quote.renewalDepositDue || $scope.quote.renewalDepositDue === 0);
        };

        $scope.getLossRunLinkText = function() {
            return $scope.user.type.toLowerCase() === "insured"?"Pull Loss Report":"Pull Loss Runs";
        };

        $scope.isPolicyChangeRequestVisible = function() {
            var isExpired = moment( $scope.quote.expirationDate, "MM/DD/YYYY" ).isBefore( Date.now() );
            return $scope.quote.isPolicy && ($scope.user.type === 'Agent' || $scope.quote.insuredType === 'Direct') && !isExpired && ($scope.quote.status !== 'Cancelled');
        };

        $scope.isSubmissionChangeRequestVisible = function() {
            return !$scope.quote.isPolicy && !$scope.quote.canEdit;
        };

        $scope.canViewSubmissionDocuments = function() {
            return !$scope.quote.isPolicy && $scope.quote.submissionId && $scope.quote.accountId && [ "Bound", "Closed", "Declined", "Quoted", "Submitted", "Lost" ].indexOf( $scope.quote.status ) > -1
        };

        $scope.canViewResult = function() {
            return $scope.quote.isQuoteAvailable || ( $scope.quote.applicationId && ( $scope.quote.status === "Declined" || $scope.quote.status === "Submitted" )  )
        };

        $scope.canViewRenewal = function() {
            return $scope.quote.isRenewal || ( $scope.quote.applicationId && ($scope.quote.status === "Quoted" || $scope.quote.status === "Bound") )
        };

        $scope.isBindAndInvoice = function(){
            if($scope.quote.program === "Horse Mortality" || $scope.quote.program === "Horse mortality" || $scope.quote.program === "Farm property and liability"){
                return false;
            }
            else{
                return true;
            }
        }

        $scope.toggleQuoteSelection = function( quote ) {
            quote.selected = quote.selected ? !quote.selected : true;
            $scope.$parent.updateQuoteSelection();
        };
    }

    return QuoteSummaryController;

});
