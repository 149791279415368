define('common/service/AgencyService',['require'],function(require) {
	"use strict";

	AgencyService.$inject = ["$http", "maverickApiUrl", "maverickRoot", "archApiUrl"];

	function AgencyService( $http, maverickApiUrl, maverickRoot, archApiUrl ) {

		var service = {};

		service.getAgencyPrograms = function( agencies, effectiveDate ) {
			return $http.get( maverickApiUrl + "quote/getAgencyProgramsForSelectOptions", {
				params: {
					agencies: agencies.join(","),
					effectiveDate: effectiveDate
				}
			}).then(function( result ) {
				return result.data.programs;
			});
		};

		service.getAgencyIds = function() {
			return $http.get( maverickApiUrl + "quote/getAgencyLocations" ).then(function( result ) {
				return result.data.agencyLocations.DATA.map(function( location ) {
					// the 1 index is the agency ID
					return location[1];
				});
			});
		};

		service.getAgency = function( systemCode, systemId ) {
			return $http.get( maverickRoot + "v1/agency/system/" + systemCode + "/" + systemId ).then( function( result ) {
				return result.data;
			});
		};

		service.showAgencyInfo = function( agencyId ) {
			var hiddenDirectAgencies = [ "61341", "94519", "93970", "83820" ];
			return (agencyId == null || agencyId == undefined) ? false : hiddenDirectAgencies.indexOf( agencyId ) === -1;
		};

        service.getDefaultAgencyByProgram = function( state, effectiveDate, marketedProgram, cobrandedId ) {
            return $http.post( archApiUrl + "application/default-agency", {
                state: state,
                effectiveDate: effectiveDate,
                marketedProgramId: marketedProgram,
                cobrandedId: cobrandedId
            });
        };
		return service;

	}

	return AgencyService;

});
