define('components/widget/optional-endorsements/waiver-of-transfer/directive/waiverOfTransfer',['require'],function(require) {
	"use strict";

    waiverOfTransfer.$inject = [];

	function waiverOfTransfer() {
		return {
			restrict: "AE",
			scope:{
                model	            : "=",
                options             : "=",
                quantityControlId   : "="
			},
			templateUrl: "app/components/widget/optional-endorsements/waiver-of-transfer/template/template.html",
			controller: "WaiverOfTransferController"
		};
	}

	return waiverOfTransfer;

});
