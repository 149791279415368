define('components/agencyExperienceReport/directive/agencyExperienceReport',['require'],function(require) {
	"use strict";

	AgencyExperienceReport.$inject = [];

	function AgencyExperienceReport() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/agencyExperienceReport/template/template.html",
			controller: "AgencyExperienceReportController"
		};
	}

	return AgencyExperienceReport;

});
