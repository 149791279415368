define( 'components/specialtyApplication/submit/programDetails/bop/app',['require','components/specialtyApplication/submit/programDetails/bop/directive/bop','components/specialtyApplication/submit/programDetails/bop/controller/BopController','moment'],function( require ) {
	"use strict";

	var bop = require( "components/specialtyApplication/submit/programDetails/bop/directive/bop" ),
		BopController = require( "components/specialtyApplication/submit/programDetails/bop/controller/BopController"),
		moment = require( "moment" );

	var component = angular.module( "mPortal.components.specialtyApplication.submit.programDetails.bop", [] );

	component.directive( "specialtyApplicationReviewBop", bop );
	component.controller( "BopController", BopController );
	component.value( "moment", moment );

	return component;

});
