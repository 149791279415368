define('components/widget/building-optional-endorsements/mortgage-holder-details/controller/MortgageHolderDetailsController',['require'],function(require) {
	"use strict";

	MortgageHolderDetailsController.$inject = [ "$scope" ];

	function MortgageHolderDetailsController( $scope ) {

		$scope.widgetFields = $scope.options.fieldGroup;

		$scope.widgetFields.sort(function( a, b ) {
			return a.data.orderNumber - b.data.orderNumber;
		});
		// Break up the enumerations into separate arrays within the enumerations array;
		$scope.enumerations = [];
		for( var d = 0; d < $scope.widgetFields.length; d++ ) {
			var currentPoint = $scope.widgetFields[ d ];

			if(  currentPoint.validation && currentPoint.validation.errorExistsAndShouldBeVisible  ) {
				currentPoint.data.wasTouched = true;
			}


			var enumIndex = currentPoint.data.enumeration - 1;
			if( !$scope.enumerations[ enumIndex ] ) {
				$scope.enumerations[ enumIndex ] = [];
			}
			$scope.enumerations[ enumIndex ].push( currentPoint );
		}


	}

	return MortgageHolderDetailsController;

});
