define( 'components/specialtyApplication/submit/programDetails/wc/controller/WcController',['require'],function( require ) {
	"use strict";

	WcController.$inject = [ "$scope", "SubmissionStore", "ApplicationStateStore" ];

	function WcController( $scope, SubmissionStore, ApplicationStateStore ) {

		$scope.locations = [];

		$scope.programDetails = SubmissionStore.programs.filter( function ( program ) { return program.referenceString  === 'wcBop' } )[ 0 ];

		$scope.effectiveDate = $scope.programDetails.effectiveDate;

		$scope.parseLocationsDetails = function() {
			ApplicationStateStore.sortEnumeratedDatapoints( [ "Location Address1", "Location City", "Location State", "Location Zip", "Class Code Number" ] );

			var locationAddressList     = ApplicationStateStore.getEnumeratedDatapoints( "Location Address1" ),
				locationCityList        = ApplicationStateStore.getEnumeratedDatapoints( "Location City" ),
				locationStateList       = ApplicationStateStore.getEnumeratedDatapoints( "Location State" ),
				locationZipList         = ApplicationStateStore.getEnumeratedDatapoints( "Location Zip" ),
				payrollList             = ApplicationStateStore.getEnumeratedDatapoints( "Payroll" ),
				locationClassCodeDescs  = ApplicationStateStore.getEnumeratedDatapoints( "Class Code Description ID" ),
				numberOfClassCodes      = ApplicationStateStore.getEnumeratedDatapoints( "Class Code Number" );


			for( var b = 0; b < locationAddressList.length; b++ ) {
				$scope.locations.push(
						{
							address: locationAddressList[ b ].value,
							city: locationCityList[ b ].value,
							state: locationStateList[ b ].value,
							zip: locationZipList[ b ].value,
							classCodeDetails: []
						}
				);

				for( var c = 0 ; c < locationClassCodeDescs.length; c++ ){
					if( locationClassCodeDescs[ c ].sectionId.indexOf( locationAddressList[ b ].sectionId ) !== -1 ) {
						$scope.locations[ b ].classCodeDetails.push(
								{
									classCode: locationClassCodeDescs[ c ].value.name,
									payroll: payrollList[ c ].value
								}
						)
					}
				}

			}
		};

		$scope.parseData = function() {
			$scope.parseLocationsDetails();
		};

		$scope.parseData();
	}

	return WcController;

});
