define('components/specialtyApplication/section/container/controller/SectionContainerController',[],function() {

	SectionController.$inject = [
		"$scope",
		"$location",
		"$http",
		"$timeout",
		"$window",
		"$anchorScroll",
		"$route",
		"$routeParams",
		"$q",
		"SubmissionStore",
		"QuestionService",
		"ApplicationSectionService",
		"UserProfile",
		"ResultService",
		"ApplicationStateStore",
		"$filter"
	];

	function SectionController(
		$scope,
		$location,
		$http,
		$timeout,
		$window,
		$anchorScroll,
		$route,
		$routeParams,
		$q,
		SubmissionStore,
		QuestionService,
		ApplicationSectionService,
		UserProfile,
		ResultService,
		ApplicationStateStore,
		$filter
	) {


		$scope.status = {
			loadingSections: true,
			loading: false,
			menuVisible: false,
			onEnumerationLandingPage: false,
			hasVisibleSections: false
		};

		ApplicationStateStore.populatePrograms();
		$scope.width = '99%';
		$scope.current_section_index = 0;
		$scope.showValidationError = false;
		$scope.additionalInfoErrors = [];
		$scope.bundleQuoteId = $location.search().quoteid ? $location.search().quoteid : null
		$scope.showAdditionalQuestionsButton = !$location.search().save;
		/*
		 The SubmissionStore.preBindEdit property indicates whether this controller is being invoked to answer/edit
		 submission questions or to answer questions on a quote in order to bind.
		*/
		$scope.preBindEdit = SubmissionStore.preBindEdit[ SubmissionStore.applicationId ];
		var editing = false;
		// legacy mode indicates running from quoteDirective with a bundled BOP and/or WC submission
		var legacyMode = $scope.applicationId || false;

		if( UserProfile && UserProfile.isLoggedIn ) {
			var applicationPath = "/application/agent";
			$scope.isAgent = true;
		} else {
			var applicationPath = "/application";
			$scope.isAgent = false;
		}

		if( $routeParams.applicationId || $scope.applicationId) {
			SubmissionStore.applicationId = $routeParams.applicationId ? $routeParams.applicationId : $scope.applicationId;
			if( !$scope.phase ) {
				SubmissionStore.visitedSections.push("/application/questions");
			}
			editing = true;
		// Added to disable breadcrumb navigation back to the insured information step, as resubmitting
		// that step after visiting this one will throw errors and block the user from proceeding
		} else if ( !$scope.preBindEdit  ) {
			SubmissionStore.visitedSections = [ "/application/questions" ];
		}

		$scope.editingApplication = SubmissionStore.applicationId == UserProfile.editedApplication;

		if( !$scope.preBindEdit ) {
			// if the user refreshes the browser on this step we will only have 1 visited section. In this case we
			// are just going to forward them back to the eligibility step. however, if they are coming in to edit
			// an application (determined via $routeParams.applicationId existing), we don't want to send them away.
			if (!editing && !SubmissionStore.applicationId && SubmissionStore.visitedSections.length === 1) {
				SubmissionStore.visitedSections = [];
				SubmissionStore.visitedSections = [];
				$location.path(applicationPath);
				return;
			}

			// if the user already submitted their app, it's finalized and we aren't going to allow them to do anything with it. in the view,
			// we display a nice message directing them back to the outcome page, but here we just exit early because there's no reason to run
			// any other logic from this controller.
			if (SubmissionStore.finalizedApplication && !$scope.editingApplication) {
				$scope.status = {finalizedApplication: true};
			}
		}

		$scope.tree = {
			sections: [],
			expandedNodes: [],
			selectedNode: null,
			options: {
				nodeChildren: "sections",
				dirSelectable: true,
				allowDeselect: false,
				injectClasses: {
					li: "depth-{{ node.depth }}"
				},
				equality: ApplicationSectionService.sectionsAreEqual,
				isSelectable: function() {
					return !$scope.areRequestsPending();
				},
				isLeaf: function( node ) {
					// using this to get around some weird tree-control assumptions. it disregards our isSelectable function
					// above for "branch" nodes, so we're just going to tell it that everything is a leaf if we're currently
					// loading or saving.
					if( $scope.areRequestsPending() ) {
						return true;
					}

					// if we're not loading, use the default behavior. we have a leaf if the node does not have children
					return !node.sections || node.sections.length === 0;
				}
			}
		};

		$scope.form = {
			model: {},
			fields: []
		};

		$scope.sectionGraph = {};
		$scope.show_all 	= ( $scope.layout == 'vertical');
		$scope.preBindEdit 	= $scope.show_all;

		// we're going to be optimistic here and assume the user is not trying to edit an account that
		// has already been submitted. we could run these requests synchronously and only load sections
		// once we've verified that the application has not yet been submitted, but that will slow down
		// initial load for the majority of our users. we may end up not needing the data from the
		// listSections call, but 99% of the time running these requests in parallel will be a slight
		// speed improvement for the user.
		$q.all([
			QuestionService.getSubmission( SubmissionStore.applicationId ),
			QuestionService.listSections( SubmissionStore.applicationId )
		]).then(function( results ) {

			var submission = results[ 0 ],
				sections = results[ 1 ];

			// we probably already have this data on SubmissionStore, but if the user is editing an
			// application we need to re-populate the object. no need to check if the data already
			// exists on SubmissionStore because any existing data is superseded by server data anyway.
			angular.extend( SubmissionStore, {
				submissionId: submission.submissionId,
				applicant: submission.applicant,
				programCode: submission.programCode,
				programName: submission.programName,
				state: submission.state,
				effectiveDate: submission.effectiveDate,
				resumeLink: submission.resumeLink
			});

			var programCode = $filter('filter')(SubmissionStore.programs, {'referenceString': 'bop'}, true);
			if (programCode.length > 0 || SubmissionStore.programCode.toLowerCase() == "bop") {
				QuestionService.getAppEligibilityGuidelines(SubmissionStore.applicationId, SubmissionStore.effectiveDate, 'Class Code Description ID').then(function (result) {
					var boClassCodeGuidelines = result;
					ApplicationStateStore.populateEligibilityGuidelines( result );
				});
			}

			// Do not override the existing payment plan on the submission store
			if( submission.paymentPlan && !SubmissionStore.paymentPlan ) {
				SubmissionStore.paymentPlan = submission.paymentPlan
			}

			if( submission.marketedProgram && !SubmissionStore.marketedProgramId ) {
				SubmissionStore.marketedProgramId = submission.marketedProgram.value;
			}

			if( !$scope.preBindEdit) {
				// Submitted applications can only be edited after user acknowledgement (editingApplication flag)
				if ( submission.editRestrictedByStatus && !$scope.editingApplication ) {
					$scope.status.loadingSections = false;
					$scope.status.finalizedApplication = true;
					SubmissionStore.finalizedApplication = true;
					return;
				}

			}
			
			$scope.tree.sections = ApplicationSectionService.addSections( $scope.tree.sections, sections );

			var firstSection;
			angular.forEach( $scope.tree.sections, function( section ) {
				if( firstSection == undefined && section.visible ) {
					firstSection = section;
					$scope.status.hasVisibleSections = true;
				}
			});

			if( $scope.status.hasVisibleSections ) 
			{
				$scope.sectionGraph = ApplicationSectionService.graphSections( $scope.tree.sections , 'section' );

				if(!$scope.show_all)
				{
					$scope.load_section({type:'first-load'},{section:firstSection} );
					var next_section =	$scope.sectionGraph[ firstSection.sectionId ].next;
					if(!next_section.loaded)$scope.load_section({ type:'pre-load' },{ section:next_section , preload: true } );
				}
				else
				{
					for(var i = 0; i < $scope.tree.sections.length; i++)
					{
						if($scope.tree.sections[i].visible)
						{
							$scope.load_section({ type:'pre-load' },{ section:$scope.tree.sections[i], preload: true } );
						}
						

						if( i == ($scope.tree.sections.length - 1))
						{
							$scope.tree.selectedNode = $scope.tree.sections[i];
						}
					}
				}
			}

			$timeout($scope.resize);

			$scope.status.loadingSections = false;
		});

		$scope.resize = function(){
			$scope.width = $('.specialty-application .content-wrapper').width();
			if(arguments.length)$scope.$apply();
		}

		$scope.navigate = function(e,args){

			// Perform auto-outcome status check
			ResultService.getAutoOutcomeResult( SubmissionStore.applicationId, !!UserProfile.editedApplication ).then( function( result ) {
				if( result.outcome === "Decline" ) {
					SubmissionStore.visitedSections = [];
					$location.path( "/application/result" );
				} else {
					var section = args.section;

					switch(args.navigation)
					{
						case 'previous' :
							$scope.current_section_index--;
							break;

						case 'next' :
							$scope.current_section_index++;
							break;
					}

					if(!section.loaded)$scope.load_section({ type:'navigation-load' },{ section:section } );


					if(section.depth == 1)
					{
						if($scope.tree.selectedNode != null && $scope.tree.selectedNode.sectionId != section.sectionId)
						{
							var prev_section_id 		 = $scope.tree.selectedNode.sectionId;
							var prev_section 			 = ApplicationSectionService.findSection($scope.tree.sections,prev_section_id);

							$scope.validate_section(prev_section);
						}

						$scope.tree.selectedNode = section;
					}

					//scroll to top
					$anchorScroll( 'specialty-application-navigation' );

					if(!$scope.isOnLastSection())
					{
						var next_section =	$scope.sectionGraph[ $scope.tree.selectedNode.sectionId ].next;

						if(!next_section.loaded)$scope.load_section({ type:'pre-load' },{ section:next_section , preload: true } );
					}
				}

			});

		}

		$scope.load_section = function( e,args ) {
			var node = args.section;
			
			if(!args.preload)args.preload = false;
			if(!args.preload)$scope.status.loading = true;

			node.loading = true;
			if(node.depth == 1 && !args.preload )$scope.tree.selectedNode = node;
	
			QuestionService.getSection( SubmissionStore.applicationId, node.sectionId )
				.then( function ( response ) {

					ApplicationSectionService.addSections( $scope.tree.sections, response.sections );

					node.datapoints 	= response.datapoints;
					node.fields 		= ApplicationSectionService.getAllDatapoints( node );

					ApplicationSectionService.revisitSections( response.sections, $scope.tree.sections );
					ApplicationSectionService.recurseInjectEventHandlers( $scope.tree.sections, node.fields, $scope.submitFieldHandler, $scope );

					//reset our model datapoints
					for(var j=0; j < node.datapoints.length; j++)
					{
						var dp = node.datapoints[j];

							$scope.form.model[dp.key] = dp.defaultValue;
					}

					if(response.sections.length)
					{
						ApplicationSectionService.updateSections( response.sections, $scope.tree.sections, $scope.tree.expandedNodes, node , 'section');

						for(var i=0; i < response.sections.length; i++)
						{
							var sub_section = ApplicationSectionService.findSection($scope.tree.sections,response.sections[i].sectionId);
								sub_section.active = true; 

								if(sub_section.type == 'inline')
								{
									//reset our model datapoints
									for(var j=0; j < sub_section.datapoints.length; j++)
									{
										var dp = sub_section.datapoints[j];

											$scope.form.model[dp.key] = dp.defaultValue;
									}
								}
						}
					}

					node.loaded  = true;
					node.loading = false;
					node.open 	 = true;
					
					if(!args.preload)
					{
						node.active = true;
						$scope.status.loading = false;
					}

					if($scope.show_all)
					{
						node.active = true;
					}

					$scope.sectionGraph = ApplicationSectionService.graphSections( $scope.tree.sections , 'section' );

					$timeout($scope.resize);
				} );
			
		};

		$scope.reload_section_data = function(e,args){

			var node = args.section;

			QuestionService.getSection( SubmissionStore.applicationId, node.sectionId )
					.then( function ( response ) {

						var sections = response.sections;

						for(var i=0; i < response.datapoints.length; i++)
						{
							response.datapoints[i].data.change = 'changed';
						}


						
						for(var i=0; i < sections.length; i++)
						{
							var section = ApplicationSectionService.findSection($scope.tree.sections,sections[i].sectionId);
							
							if(!section)
							{
								section = ApplicationSectionService.addSection($scope.tree.sections,sections[i]).section;
							}

							if(!section.active || section.change == 'changed')section.active = true;
						}

						ApplicationSectionService.updateSections( sections, $scope.tree.sections, $scope.tree.expandedNodes, node , 'section' );
						
						
						ApplicationSectionService.updateDatapoints( response.datapoints, $scope.tree.sections );

						$scope.sectionGraph = ApplicationSectionService.graphSections( $scope.tree.sections , 'section' );
						$scope.status.loading = false;
					} );
		};


		$scope.add_section = function( e,args ) {
			var section 			= args.section;
			var	enumerationId 		= section.sectionId.replace(/HEAD$/, 1);

			//ok so find the last section w/ the same enumeration type and use that ID
			//if not found set it to the parent + 1
			for(var i = section.sections.length - 1; i >= 0; i--)
			{
				var sub_section = section.sections[i];

				if(sub_section.enumeration == section.sections[0].enumeration)
				{
					enumerationId = sub_section.sectionId;
					break;
				}
			}

			QuestionService.createEnumeration( SubmissionStore.applicationId, enumerationId ).then(function( sections ) {

				$scope.reload_section_data({ type:'enumeration-reload' },{ section:section , action: 'add' } );
					
			});
		};

		$scope.receiveSectionData = function ( sectionData, inlinesection ) {

			var currentSection = $filter( "filter" )( $scope.tree.sections, function ( section ) {
				return section.name === inlinesection.name
			} );

			$scope.delete_enumerated_section( inlinesection, currentSection[0].sections[0].parentNode );
		};

		$scope.delete_enumerated_section = function ( inlinesection, parentNode ) {

			var section = inlinesection;

			$scope.status.loading = true;

			QuestionService.deleteEnumeration( SubmissionStore.applicationId, section.sectionId ).then( function ( response ) {
				ApplicationSectionService.removeLastEnumeration( parentNode );

				parentNode.sections = [];
                $scope.status.loading = false;

                $scope.load_section({ type:'pre-load' },{ section:parentNode , preload: true });
			} );
		};

		$scope.delete_section = function( e,args ) {

			var section = args.section;

			$scope.status.loading = true;

			QuestionService.deleteEnumeration( SubmissionStore.applicationId, section.sectionId ).then(function( response ) {
				ApplicationSectionService.removeLastEnumeration( section.parentNode );

				section.parentNode.sections = [];
				$scope.reload_section_data({ type:'enumeration-reload' },{ section:section.parentNode , action:'remove' } );
				
			});
		};


		$scope.submitFieldHandler = function( sections ) {
			return function submitField( value, field, scope ) {
				// don't bother saving it if the value hasn't changed, but record that it has been touched
				if( value === field.defaultValue ) {
					ApplicationStateStore.processDatapoint( field, "touch" );
					return;
				}

				$scope.status.saving = true;

				var actualValue = value;

				// rateforest checkboxes do not abide by the rules of normal checkboxes, where there is only a value when checked and null when not. so, depending
				// on whether the checkbox is checked, we either send back the checked or unchecked value.
				if( field.type === "CheckBox" ) {
					actualValue = value === true ? field.data.checkedValue : field.data.uncheckedValue;
				}

				QuestionService.saveDatapoint( SubmissionStore.applicationId, field.data.sectionId, field.data.datapointId, field.key, actualValue ).then(function( response ) {
					
					//find our section and set fields for it
					var current_section  = ApplicationSectionService.findSection($scope.tree.sections,field.data.sectionId);
					var starting_section = current_section;

					ApplicationSectionService.updateSections( response.sections, $scope.tree.sections, $scope.tree.expandedNodes, current_section , 'section');
					ApplicationSectionService.updateDatapoints( response.datapoints, $scope.tree.sections, scope );
					ApplicationStateStore.processDatapoint( field, "save" );
					//set fields as needed
					//may need to loop over sections and update all fields.. we shall see
					if(current_section.type == 'inline')
					{	
						current_section.parentNode.fields = ApplicationSectionService.getAllDatapoints( current_section.parentNode );
						ApplicationSectionService.recurseInjectEventHandlers( sections, current_section.parentNode.fields, $scope.submitFieldHandler, $scope );
					}
					else
					{
						current_section.fields = ApplicationSectionService.getAllDatapoints( current_section );
						
						current_section.active = true;

						current_section.sections.forEach( function( sub_section ) {
							if(sub_section.enumeration != null &&  !sub_section.loaded)
							{
								sub_section.active = true;
							}
						});
					}
				
					ApplicationSectionService.recurseInjectEventHandlers( sections, current_section.fields, $scope.submitFieldHandler, $scope );
					$scope.sectionGraph = ApplicationSectionService.graphSections( $scope.tree.sections , 'section' );


					//find the top level 
					while(starting_section.depth != 1)
					{
						starting_section = starting_section.parentNode;
					}

					//make the top level active and set to current node
					starting_section.active  = true;
					if(!$scope.show_all)$scope.tree.selectedNode = starting_section;
					
					//console.log($scope.tree.selectedNode);
					//console.log(current_section.depth,current_section);
					//console.log(starting_section.depth,starting_section);
					$scope.status.saving 	 = false;
				});
			}
		};

		$scope.validate_section = function(parent_section){

			parent_section.active = false;
			parent_section.visited = true;

			if(parent_section.fields)
			{
				$timeout(function() {
					parent_section.fields.forEach( function( field ) {
						$scope.processInvalidField( field );
					});

					parent_section.valid = parent_section.isComplete();

					if(!parent_section.active && parent_section.visited)
					{ 
						parent_section.open = true;
					}
				});
			}

			if(parent_section.sections.length > 0)
			{
				for(var i=0; i < parent_section.sections.length; i++)
				{
					var sub_section = parent_section.sections[i];
						sub_section.visited = true;
						$scope.validate_section(sub_section);
				}
			}
		};

		$scope.processInvalidField = function( field ) {
			if( field.fieldGroup ) {
				field.fieldGroup.forEach( function( field) {
					$scope.processInvalidField( field );
				});
			} else {
				if( !field.data.isValid && field.formControl ) {
					field.formControl.$setValidity("error", false);
					field.validation.show = true;
				}
			}
		};

		$scope.validate_additional_info_section = function(parent_section){
			parent_section.active = false;
			parent_section.visited = true;

			if(parent_section.fields)
			{
				$timeout(function() {
					parent_section.fields.forEach( function( field ) {
						$scope.process_additional_info_invalid_field( field );
					});

					parent_section.valid = parent_section.isComplete();

					if(!parent_section.active && parent_section.visited)
					{
						parent_section.open = true;
					}
				});
			}

			if(parent_section.sections.length > 0)
			{
				for(var i=0; i < parent_section.sections.length; i++)
				{
					var sub_section = parent_section.sections[i];
					sub_section.visited = true;
					$scope.validate_additional_info_section(sub_section);
				}

			}
		};

		$scope.process_additional_info_invalid_field = function( field ) {
			if( field.fieldGroup ) {
				field.fieldGroup.forEach( function( field) {
					$scope.process_additional_info_invalid_field( field );
				});
			} else {
				if( !field.data.isValid && field.formControl ) {
					field.formControl.$setValidity("error", false);
					field.validation.show = true;
					$scope.additionalInfoErrors.push(field);
				}
				$scope.showValidationError = true;
			}

		};

		$scope.isOnLastSection = function() {
			return $scope.tree.selectedNode && !$scope.sectionGraph[ $scope.tree.selectedNode.sectionId ].next;
		};


		$scope.allSectionsAreVisitedAndComplete = function() {
			return ApplicationSectionService.sectionsAreVisited( $scope.tree.sections ) && ApplicationSectionService.sectionsAreComplete( $scope.tree.sections  );
		};

		$scope.allAdditionalInfoSectionsAreVisitedAndComplete = function() {
			return ApplicationSectionService.sectionsAreVisited( $scope.tree.sections ) && ApplicationSectionService.additionalSectionsAreComplete( $scope.tree.sections );
		};

		$scope.finalizeApplication = function() {

			// if in legacyMode (i.e., WC +- BOP + EO) we call the click method different submit button with Continue rather than Submit Application
			if ( legacyMode ) {
				$( "#miscEOPricingFormSubmit" ).click();
				return false;
			}
			$location.path( "/application/review" );
		};

		$scope.finalizeAdditionalQuestions = function() 
		{
            $scope.accept_quote = false;
            $scope.non_bindable_quote = false;
			$scope.additionalInfoErrors = [];

			if ( SubmissionStore.delayedPaymentFlag == true ) {
				ResultService.getStatus( SubmissionStore.applicationId )
						.then( function ( response ) {

							if ( response.data[0].isAppCompleteForBind == true ) {
								SubmissionStore.delayedPaymentFlag = false;
							}
							else {
                                if( response.data[0].paymentOptions.isDelayedPaymentAvailable )
                                {
                                    $scope.non_bindable_quote = true;

                                    angular.forEach( response.data[0].availableActions, function ( item ) {

                                        if ( item.action === "Bind Request" ) {
                                            $scope.accept_quote = true;
                                        }
                                    } );

                                }
                            }

							if ( !$scope.allAdditionalInfoSectionsAreVisitedAndComplete() ) {
								for ( var i = 0; i < $scope.tree.sections.length; i++ ) {
									$scope.validate_additional_info_section( $scope.tree.sections[i] );
								}
								$anchorScroll( 'additional-info-error' );
							} else {
								SubmissionStore.preBindEdit[SubmissionStore.applicationId] = false;
								SubmissionStore.preBindQuestionsAnswered[SubmissionStore.applicationId] = true;
								if($scope.bundleQuoteId){
									$location.path("account/bind/"+$scope.bundleQuoteId);
								}
								else if ( SubmissionStore.preBindPayment[SubmissionStore.applicationId] ) {
									$location.path( "/application/payment" );
								} else {
                                    if ( $scope.accept_quote || ( response.data[0].paymentOptions.allowOnlinePayments === false && $scope.non_bindable_quote ) ) {
                                            ResultService.acceptSubmission( SubmissionStore.applicationId ).then( function ( result ) {
                                                $location.path("/application/result");
                                            } );
                                        }
                                        else {
                                            ResultService.bindSubmission(SubmissionStore.applicationId, SubmissionStore.paymentPlan).then(function (result) {
                                                $location.path("/application/result");
                                            });
                                        }
								}
							}

						} );
			} else {
				if ( !$scope.allAdditionalInfoSectionsAreVisitedAndComplete() ) {
					for ( var i = 0; i < $scope.tree.sections.length; i++ ) {
						$scope.validate_additional_info_section( $scope.tree.sections[i] );
					}
					$anchorScroll( 'additional-info-error' );
				} else {
					SubmissionStore.preBindEdit[SubmissionStore.applicationId] = false;
					SubmissionStore.preBindQuestionsAnswered[SubmissionStore.applicationId] = true;
					if($scope.bundleQuoteId){
						$location.path("account/bind/"+$scope.bundleQuoteId).search({});
					}
					else if ( SubmissionStore.preBindPayment[SubmissionStore.applicationId] ) {
						$location.path( "/application/payment" );
					} else {
						ResultService.bindSubmission( SubmissionStore.applicationId, SubmissionStore.paymentPlan ).then( function ( result ) {
							$location.path( "/application/result" );
						} );
					}
				}
			}
		};

		$scope.areRequestsPending = function() {
			return $http.pendingRequests.length > 0;
		};

		$scope.showSectionInTree = function( section ) {
			return section.visible && section.type !== "inline";
		};

		$scope.startNewApplication = function() {
			SubmissionStore.reset();
			$location.path( applicationPath );
		};

		$scope.saveAdditionalQuestions = function(  ) {
			var url = "/application/result/"+ SubmissionStore.applicationId + "/account/" + SubmissionStore.submissionId;
			$location.path( url ).search( {} );
		};

		$scope.$on('add-section',$scope.add_section);
		$scope.$on('delete-section',$scope.delete_section);
		$scope.$on('load-section',$scope.load_section);
		$scope.$on('delete-enumerated-section', $scope.receiveSectionData);
		angular.element($window).bind('resize',$scope.resize);
	}

	return SectionController;

});
