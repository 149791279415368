define('components/specialtyApplication/questions/config/widgets/OptionalEndorsementsWidget',['require'],function(require) {
    "use strict";

    var widget = {};

    widget.getStructure = function() {

        return {

            "PolicyOELabel" : { wrapper: null, wrapperOverride: true, orderNumber: 1 },

            "Aggregate Limit of Ins" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 2 },

            "Barber Shops" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 3 },
            "Barber Number of Professionals": { wrapper: [ "horizontalBox", "oeVerticalFullRowError" ], wrapperOverride: true,  orderNumber: 4 },

            "Beauty Salon" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 5 },
            "Salon Number of Professionals": { wrapper: [ "horizontalBox", "oeVerticalFullRowError" ], wrapperOverride: true,  orderNumber: 6 },

            "Contractors" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 7 },
            "Contractors Required Warning" : { wrapper: null, wrapperOverride: true, orderNumber: 8 },
            "Contractors Limits" : { wrapper: [ "verticalWideFullRowPoint", "oeContractorFullRowError" ],wrapperOverride: true, orderNumber: 9 },
            "Contractors Tools Non Owned" : { wrapper: ["contractorsCheckBox"], wrapperOverride: true, orderNumber: 10 },
            "Tools Non Owned Limit" : { wrapper: [ "verticalWideFullRowPoint", "oeContractorFullRowError" ], wrapperOverride: true, orderNumber: 11 },
            "Contractors Employees Tools" : { wrapper: ["contractorsCheckBox"], wrapperOverride: true, orderNumber: 12 },
            "Employees Tools Limit" : { wrapper: ["verticalWideFullRowPoint","oeContractorFullRowError"], wrapperOverride: true, orderNumber: 13 },
            "Contractors Tools Blanket" : { wrapper: ["contractorsCheckBox"], wrapperOverride: true, orderNumber: 14 },
            "ToolsBlanketTable": {
                wrapper: [ "oeFieldGroupTable" ], orderNumber: 15,
                data: {
                    tableDiv: { class: "oeSubFieldGroupContainer" },
                    table: {
                        class: "table table-bordered",
                        headerRows: [
                            {
                                headers: [ "Limit","Sub-limit"]
                            }
                        ]
                    }
                },
                fieldGroup: {
                    "row1": {
                        fieldGroup: {
                            "Tools Blanket Limit": { wrapper: [ "labelDatapoint1","bOeSpan8HalfRowError" ], wrapperOverride: true },
                            "Tools Blanket Sub Limit": { wrapper: [ "labelDatapoint1","bOeSpan8HalfRowError" ], wrapperOverride: true }
                        }
                    }
                }
            },
            "Contractors Tools Scheduled" : { wrapper: ["contractorsCheckBox"], wrapperOverride: true, orderNumber: 16 },
            "ToolsScheduled": {
                wrapper: [ "tools-scheduled-wrapper" ], orderNumber: 17,
                fieldGroup: {
                    "Tools Scheduled Number" : {  wrapper: [ "defaultBootstrapLabel", "defaultBootstrapHasError" ], orderNumber: 1},
                    "Tools Scheduled Description": { wrapper: [ "defaultBootstrapSavedError" ], orderNumber: 2 },
                    "Tools Scheduled Limit": { wrapper: [ "labelDatapoint1", "defaultBootstrapSavedError" ], orderNumber: 3 }
                }
            },
            "Tools Scheduled Limit Total Warning" : { wrapper: null, wrapperOverride: true, orderNumber: 18 },
            "Question2006" : { wrapper: null, wrapperOverride: true, orderNumber: 19 },
            "Contractors Validation Warning" : { wrapper: null, wrapperOverride: true, orderNumber: 20 },

            "Policy Damage to Premises" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 21 },
            "Policy Damage to Premises Limit" : { wrapper: [ "horizontalBox", "oeVerticalFullRowError" ], wrapperOverride: true,  orderNumber: 22 },

            "Designated Const Proj" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 23 },

            "Ecommerce" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 24 },
            "EcommerceDataPoint" : { wrapper : ["twoPointRow"], orderNumber: 25, fieldGroup:
                {
                    "Ecommerce Agg Limit" : { wrapper: [ "verticalHalfRowPoint", "oeVerticalHalfRowError" ] },
                    "Virus Waiver" : { wrapper: [ "horizontalBox", "oeVerticalHalfRowError" ] }
                }

            },
            "Virus Waiver Warning" : { wrapper: null, wrapperOverride: true, orderNumber: 26 },

            "Employee Benefits" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 27 },
            "EBLDataPoint1": { wrapper : ["twoPointRow"], orderNumber: 28, fieldGroup:
                {

                    "EBL Limit" : { wrapper: [ "labelWidthPercHalfRowPoint", "span4HalfRowError" ] },
                    "EBL No of Employees" : { wrapper: [ "horizontalBox", "span4HalfRowError" ] },
                    "EBL Retro Date" : { wrapper: [ "horizontalBox", "span3HalfRowError" ] }
                }
            },
            "EBL Program" : { wrapper: [ "defaultBootstrapLabel", "eblProgramWrapper" ], orderNumber: 29 },

            "Employee Dishonesty" :{ wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 30 },
            "EmployeeDishonestyDatapoint":  { wrapper: ["twoPointRow"], orderNumber: 31, fieldGroup:
                {
                    "Dishonesty Limit": { wrapper: [ "verticalHalfRowPoint", "oeVerticalHalfRowError" ], wrapperOverride: true },
                    "Employee Dishonesty Total": { wrapper: [ "horizontalBox", "oeVerticalHalfRowError" ], type: 'oeReadonly',wrapperOverride: true }
                }
            },

            "EPLI": { wrapper: ["bootstrapTooltipIcon"], wrapperOverride: true, orderNumber: 32 },
            "EPLISection": { wrapper: ["twoPointRow"], orderNumber: 33, fieldGroup:
                {
                    "EPLI Claim Deductible": { wrapper: ["verticalHalfRowPoint", "oeVerticalHalfRowError"], wrapperOverride: true },
                    "EPLI Annual Aggregate": { wrapper: ["verticalHalfRowPoint", "oeVerticalHalfRowError"], wrapperOverride: true }
                }
            },

            "EPLISection2": { wrapper: ["twoPointRow"], orderNumber: 34, fieldGroup:
                {
                    "EPLI FT Employees": {wrapper: ["horizontalBox", "oeVerticalHalfRowError"], data: { savePointOnly: true },  wrapperOverride: true},
                    "EPLI PT Employees": {wrapper: ["horizontalBox", "oeVerticalHalfRowError"], data: { savePointOnly: true },  wrapperOverride: true}
                }

            },
            "Question1779": { wrapper: ["verticalWideFullRowPointEPLI", "oeVerticalFullRowErrorEPLI79"], wrapperOverride: true, orderNumber: 35, type: "yesNoToggle" },
            "EPLI Deselect Label": { wrapper: null, orderNumber: 36, wrapperOverride: true },
            "Question1779 Deselect Label": {wrapper: null, orderNumber: 37, wrapperOverride: true},
            "Question1776": { wrapper: ["verticalWideFullRowPointEPLI", "oeVerticalFullRowErrorEPLI"], orderNumber: 38, wrapperOverride: true, type: "yesNoToggle" },

            "Equipment Breakdown" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 39 },
            "Question1978" : { wrapper: [ "verticalWideFullRowPoint", "oeVerticalFullRowError" ], wrapperOverride: true, type: "yesNoToggle", orderNumber: 40 },
            "Question1980" : { wrapper: [ "textAreaBox", "oeVerticalFullRowError" ], wrapperOverride: true, type: "TextArea", orderNumber: 41 },
            "Question2001" : { wrapper: [ "verticalWideFullRowPoint", "oeVerticalFullRowError" ], wrapperOverride: true, type: "yesNoToggle", orderNumber: 42 },

            "Hired Auto": { wrapper: ["bootstrapTooltipIcon"], wrapperOverride: true, orderNumber: 43 },
            "HiredAutoSection": {
                wrapper: ["twoPointRow"], orderNumber: 44, fieldGroup:
                    {
                        "Hired Auto Coverage": { wrapper: ["horizontalBox", "oeVerticalHalfRowError"], wrapperOverride: true },
                        "Non Owned Delivery": { wrapper: ["horizontalBox", "oeVerticalHalfRowError"], wrapperOverride: true }
                    }
            },
            "HNO Deselect Label": { wrapper: null, orderNumber: 45, wrapperOverride: true},
            "Question1977" : { wrapper: [ "verticalWideFullRowPointEPLI", "oeVerticalFullRowError" ],orderNumber: 46, wrapperOverride: true, type: "yesNoToggle" },
            "Question1977 Deselect Label" : { wrapper: null, wrapperOverride: true, orderNumber: 47},
            "Question1976" : { wrapper: [ "verticalWideFullRowPointEPLI", "oeVerticalFullRowError" ], orderNumber: 48, wrapperOverride: true, type: "yesNoToggle" },
            "Question1976 Deselect Label" : { wrapper: null, orderNumber: 49, wrapperOverride: true },
            "Question1981" : { wrapper: [ "verticalWideFullRowPointEPLI", "oeVerticalFullRowErrorEPLI" ], orderNumber: 50, wrapperOverride: true, type: "yesNoToggle" },
            "Question1981 Deselect Label" : { wrapper: null, orderNumber: 51, wrapperOverride: true },
            "Question2009" : { wrapper: [ "verticalWideFullRowPointEPLI", "oeVerticalFullRowError" ], orderNumber: 52, wrapperOverride: true, type: "yesNoToggle" },
            "Question2009 Deselect Label" : { wrapper: null, orderNumber: 53, wrapperOverride: true },
            "Question2010" : { wrapper: [ "verticalWideFullRowPointEPLI", "oeVerticalFullRowError" ], orderNumber: 54, wrapperOverride: true, type: "yesNoToggle" },
            "Question2010 Deselect Label" : { wrapper: null, orderNumber: 55, wrapperOverride: true },

            "Liquor Liability" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 56 },

            "Optical and Hearing Aid" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 57 },
            "OHLDataPoint" : {  wrapper : ["twoPointRow"], orderNumber: 58, fieldGroup:
                {
                    "Optical Annual Sales" : { wrapper: [ "verticalHalfRowPoint", "oeVerticalHalfRowError" ] },
                    "Optical Exposure" : { wrapper: [ "horizontalBox", "oeVerticalHalfRowError" ] }
                }
            },

            "Pesticide" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 59 },

            "Photography" :{ wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 60 },
            "PhotographyTools": {
                wrapper: [ "photography-equipment-wrapper" ], orderNumber: 61,
                fieldGroup: {
                    "Photography Number" : { wrapper: [ "defaultBootstrapLabel", "defaultBootstrapHasError" ], orderNumber: 1},
                    "Photography Description" : { wrapper: [ "defaultBootstrapSavedError" ], orderNumber: 2 },
                    "Photography Limit" : { wrapper: [ "labelDatapoint1", "defaultBootstrapSavedError" ], orderNumber: 3 }

                }
            },
            "PhotographyDescriptions": {
                wrapper: [ "photography-description-wrapper" ], orderNumber: 62,
                fieldGroup: {
                    "Photography Description Bind": { wrapper: ["labelDatapoint"], type: 'oeReadonly', wrapperOverride: true },
                    "Photography Make": { wrapper: ["labelDatapoint","contactLocationRowError"], wrapperOverride: true },
                    "Photography Model": { wrapper: ["labelDatapoint","contactLocationRowError"], wrapperOverride: true },
                    "Photography Serial": { wrapper: ["labelDatapoint","contactLocationRowError"], wrapperOverride: true }
                }
            },

            "Primary and Noncontributory" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 63 },

            "Stop Gap" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 64 },

            "Waiver of Transfer" : { wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 65 },
            "WaiverDataPoint" : { wrapper : ["waiver-of-transfer-wrapper"], orderNumber: 66, fieldGroup:
                {
                    "Waiver Number" : { wrapper: ["defaultBootstrapLabel", "defaultBootstrapHasError"],orderNumber: 1 },
                    "Waiver Name" : {  wrapper: ["defaultBootstrapSavedError"],orderNumber: 2}
                }
            },


            "AICIDetails": { wrapper: [ "ai-controlling-interest" ], orderNumber: 67, fieldGroup:
                {
                    "AI CI Name": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI CI Address 1": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI CI Address 2": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI CI City": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI CI State": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI CI Zip": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true }
                }
            },

            "AIDesignatedPersonDetails": { wrapper: ["ai-designated-person-details"], orderNumber: 68, fieldGroup:
                {
                    "AI Designated Name": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Designated Address 1": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Designated Address 2": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Designated Zip": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Designated City": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Designated State": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true }
                }
            },

            "AIEngineersDetails": { wrapper: [ "ai-engineers-wrapper" ], orderNumber: 69, fieldGroup:
                {
                    "AI Engineers Name": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Engineers Address 1": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Engineers Address 2": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Engineers Zip": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Engineers City": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Engineers State": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true }
                }
            },

            "AIEngineersNotEngagedDetails": { wrapper: [ "ai-engineers-not-engaged" ], orderNumber: 70, fieldGroup:
                {
                    "AI Engineers Not Engaged Name": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Engineers Not Engaged Address 1": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Engineers Not Engaged Address 2": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Engineers Not Engaged Zip": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Engineers Not Engaged City": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Engineers Not Engaged State": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true }
                }
            },

            "AIGrantorSection": { wrapper: [ "ai-Grantor-Section" ], orderNumber: 71, fieldGroup:
                {
                    "AI Grantor Name": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Grantor Address 1": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Grantor Address 2": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Grantor Zip": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Grantor City": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Grantor State": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true }
                }
            },

            "AIOwnersDetails": { wrapper: [ "ai-Owners-details" ], orderNumber: 72, fieldGroup:
                {
                    "AI Owners Name": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Owners Address 1": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Owners Address 2": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Owners Zip": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Owners City": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Owners State": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true }

                }
            },

            "AISubdivisionsSection": { wrapper: [ "ai-Subdivisions-Section" ], orderNumber: 73, fieldGroup:
                {
                    "AI Subdivisions Name": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Subdivisions Address 1": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Subdivisions Address 2": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Subdivisions Zip": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Subdivisions City": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Subdivisions State": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true }
                }
            },

            "AIPremisesPermitsDetails": { wrapper: [ "ai-Premises-Permits" ], orderNumber: 74, fieldGroup:
                {
                    "AI Premises Permits Name": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Premises Permits Address 1": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Premises Permits Address 2": {wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Premises Permits Zip": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Premises Permits City": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true },
                    "AI Premises Permits State": { wrapper: ["horizontalBox","bOeSpan8HalfRowError"], wrapperOverride: true }
                }
            }

        };
    };

    widget.configureFormly = function( formlyConfig, formlyApiCheck ) {

        formlyConfig.setWrapper({
            name: "tools-scheduled-wrapper",
            template: '<div style="padding-left:50px" item-description-limit model="model" options="options" quantity-control-id="\'toolsScheduledQuantity\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "photography-equipment-wrapper",
            template: '<div style="padding-left:30px" item-description-limit model="model" options="options" quantity-control-id="\'photographyEquipmentQuantity\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "photography-description-wrapper",
            template: '<div style="padding-left:30px" photography-make-model model="model" options="options"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-controlling-interest",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Controlling Interest\'" location-control-id="\'aiControllingInterest\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "waiver-of-transfer-wrapper",
            template: '<div style="padding-left:30px" waiver-of-transfer model="model" options="options" quantity-control-id="\'waiverOfTransferQuantity\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "textAreaBox",
            template:
            '<style>.textAreaBox textarea { height: 50px;width: 520px !important;  } </style><div class="textAreaBox">' + '<label for="{{ id }}" class="control-label">{{ to.label }}</label>' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "verticalWideFullRowPoint",
            template:
            '<label style="display:inline !important; width:45%;float:left;font-weight: bold;" for="{{ id }}" class="control-label">{{ to.label }}' + '</label>' +
            '<formly-transclude></formly-transclude>'
        });

        formlyConfig.setWrapper({
            name: "verticalWideFullRowPointEPLI",
            template:
            '<label style="display:inline !important; width:55%;float:left;margin-bottom:1%;font-weight: bold;" for="{{ id }}" class="control-label">{{ to.label }}' + '</label>' +
            '<formly-transclude></formly-transclude>'
        });

        formlyConfig.setWrapper({
            name: "contractorsCheckBox",
            template:
            '<div class="inlineCheckbox" style="margin-left:20px">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setType({
            "extends": "radio",
            name: "inlineRadio",
            template:
            '<div class="radio-group">' +
            '<div ng-repeat="(key, option) in to.options" class="radio">' +
            '<label style="display: block;">' +
            '<input type="radio" ' +
            'id="{{id + \'_\'+ $index}}" ' +
            'tabindex="0" ' +
            'ng-value="option[to.valueProp || \'value\']" ' +
            'ng-model="model[options.key]">' +
            '{{option[to.labelProp || \'name\']}}' +
            '</label>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "oeVerticalHalfRowError",
            overwriteOk: true,
            template:
            '<div class="span5 form-group" style="padding-left:30px;" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "oeVerticalFullRowError",
            overwriteOk: true,
            template:
            '<div class="form-group" style="padding-left:30px;" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "oeVerticalFullRowErrorEPLI",
            overwriteOk: true,
            template:
            '<div class="form-group" style="padding-left:30px;margin-bottom:3%;" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "oeVerticalFullRowErrorEPLI79",
            overwriteOk: true,
            template:
            '<div class="form-group" style="padding-left:30px;margin-bottom:5%;" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'
        });


        formlyConfig.setWrapper({
            name: "oeContractorFullRowError",
            overwriteOk: true,
            template:
            '<div class="form-group" style="padding-left:50px;" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "span3HalfRowError",
            overwriteOk: true,
            template:
            '<div class="span3 form-group" style="padding-left:10px;" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "labelWidthPercHalfRowPoint",
            template:
            '<label for="{{ id }}" class="control-label" style="width:85%;font-weight: bold;">{{ to.label }}</label>' +
            '$ <formly-transclude></formly-transclude>'
        });

        formlyConfig.setWrapper({
            name: "labelDatapoint1",
            template:
                '$ <formly-transclude></formly-transclude>'
        });

        formlyConfig.setWrapper({
            name: "eblProgramWrapper",
            template:
            '<div>' + '<b>' +'Employee Benifits' + '</b>' + '</div>' + '</br>' +
            '<formly-transclude></formly-transclude>'
        });

        formlyConfig.setWrapper({
            name: "ai-designated-person-details",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Designated Person or Organization\'" location-control-id="\'aiDesignatedPersonDetails\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-engineers-wrapper",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Engineers, Architects or Surveyors\'" location-control-id="\'aiEngineers\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-engineers-not-engaged",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Engineers, Architects or Surveyors not engaged by the Named Insured\'" location-control-id="\'aiEngineersNotEngaged\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-Owners-details",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Owners, Lessees or Contractors - Completed Operations\'" location-control-id="\'AIOwnersDetails\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-Premises-Permits",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'State or Political Subdivisions - Permits relating to Premises\'" location-control-id="\'AIPremisesPermitsDetails\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-Grantor-Section",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'Grantor of Franchise Equipment\'" location-control-id="\'AIPremisesPermitsDetails\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "ai-Subdivisions-Section",
            template: '<div style="padding-left:30px" additional-information-location  model="model" options="options" header="\'State or Political Subdivisions\'" location-control-id="\'AIPremisesPermitsDetails\'"><formly-transclude></formly-transclude></div>'
        });


    };

    return widget;


});
