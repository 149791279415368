define( 'components/specialtyApplication/quoteResult/controller/ConfirmController',[],function () {

	ConfirmController.$inject = ["$scope", "$location", "$modalInstance", "$rootScope", "submissionInfo"];

	function ConfirmController( $scope, $location, $modalInstance, $rootScope, submissionInfo ) {

		$scope.effectiveDate = submissionInfo.effectiveDate;
		$scope.accountId = submissionInfo.accountId;
		$scope.submissionId = submissionInfo.submissionId;
		$scope.email = submissionInfo.email;
		$scope.close = $modalInstance.close;
		$scope.contact = submissionInfo.contact;
		$scope.processError = submissionInfo.processError;

		$scope.buyNow = function () {
			$rootScope.$broadcast( 'buyNow' );
			$scope.close();
		};

		$scope.messageUW = function ( accountId, submissionId ) {
			var reason ="effectiveDate";
			var url = "/account-summary/" + accountId + "/new-message/" + submissionId +"/reason/" + reason;
			$location.path( url );
			$scope.close();
		}
	}

	return ConfirmController;

} );
