define('components/applicationData/applicationData/config/widgets/ViewPolicyOEsAndAIsWidget',['require'],function(require) {
    "use strict";

    var widget = {};

    widget.getStructure = function (){

        return {

            "ViewPolicyOEsAndAIs": {wrapper: null, fieldGroup:
                {
                    "Policy OE Label": {wrapper: null, type:"omittedHeader",wrapperOverride: true},
                    "Employee Benefits": {wrapper: null, wrapperOverride: true,orderNumber : 1},
                    "viewProgramName": {wrapper: ["PolicyOEInfoProgramName"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 2},
                    "viewEmployeeLimit": {wrapper: ["PolicyOEInfoEmployeeLimit"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 3},
                    "viewNumberOfEmployees": {wrapper: ["PolicyOEInfoNumberOfEmployees"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 4},
                    "viewRetroactiveDate": {wrapper: ["PolicyOEInfoRetroactiveDate"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 5},

                    "Ecommerce": {wrapper: null, wrapperOverride: true,orderNumber : 6},
                    "Ecommerce Agg Limit": {wrapper: ["LeftHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 7},
                    "Virus Waiver": {wrapper: ["RightHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 8},
                    "Virus Waiver Warning": {wrapper: ["OELabelWithMargin"], wrapperOverride: true,orderNumber : 10},
                    "Policy Damage to Premises": {wrapper: null, wrapperOverride: true,orderNumber : 11},
                    "Policy Damage to Premises Limit": {wrapper: ["FullHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 12},

                    "Employee Dishonesty": {wrapper: null, wrapperOverride: true,orderNumber : 13},
                    "Dishonesty Limit": {wrapper: ["LeftHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 14},
                    "Employee Dishonesty Total": {wrapper: ["RightHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 15},

                    "EPLI": {wrapper: null, wrapperOverride: true,orderNumber : 16},
                    "EPLI Annual Aggregate" : {wrapper: ["LeftHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 17},
                    "EPLI Claim Deductible" : {wrapper: ["RightHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 18},
                    "EPLI FT Employees" : {wrapper: ["LeftHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 19},
                    "EPLI PT Employees" : {wrapper: ["RightHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 20},

                    "Question1776": {wrapper: ["PolicyQuestion1776"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 21},
                    "Question1779": {wrapper: ["PolicyQuestion1779"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 22},

                    "Equipment Breakdown" : {wrapper: null, wrapperOverride: true,orderNumber : 23},
                    "Question1978" : {wrapper: ["LeftHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 24},
                    "Question2001" : {wrapper: ["RightHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 25},
                    "Question1980" : {wrapper: ["PolicyDescription"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 26},

                    "Optical and Hearing Aid" : {wrapper: null, wrapperOverride: true,orderNumber : 30},
                    "Optical Annual Sales" : {wrapper: ["LeftHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 31},
                    "Optical Exposure" : {wrapper: ["RightHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 32},

                    "Barber Shops" : {wrapper: null, wrapperOverride: true,orderNumber : 36},
                    "Barber Number of Professionals" : {wrapper: ["FullHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 37},

                    "Beauty Salon" : {wrapper: null, wrapperOverride: true,orderNumber : 38},
                    "Salon Number of Professionals" : {wrapper: ["FullHorPolicyOE"], type: "setPolicyEndorsement", wrapperOverride: true,orderNumber : 39},

                    "AI Controlling Interest" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Controlling Interest Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI CI Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI CI Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

                    "AI CI Address 2": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI CI Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI CI City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI CI State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "AI Subdivisions" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Subdivisions Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Subdivisions Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Subdivisions Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Subdivisions Address 2" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Subdivisions City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Subdivisions State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Subdivisions Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "AI Premises Permits" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Premises Permits Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Premises Permits Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Premises Permits Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Premises Permits Address 2" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Premises Permits City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Premises Permits State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Premises Permits Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "AI Designated Person" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Designated Person Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Designated Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Designated Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Designated Address 2" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Designated City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Designated State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Designated Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "AI Owners" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owners Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owners Desc of Ops" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owners Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owners Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owners Address 2" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owners City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owners State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Owners Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "AI Grantor" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Grantor Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Grantor Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Grantor Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Grantor Address 2" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Grantor City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Grantor State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Grantor Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "AI Engineers" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Engineers Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Engineers Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Engineers Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Engineers Address 2" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Engineers City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Engineers State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "AI Engineers Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

                    "AI Engineers Not Engaged" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Engineers Not Engaged Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Engineers Not Engaged Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Engineers Not Engaged Address 1" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Engineers Not Engaged Address 2" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Engineers Not Engaged City" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Engineers Not Engaged State" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "AI Engineers Not Engaged Zip" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

                    "Hired Auto" : {wrapper: ["hiredAutoSection"], type: "setPolicyInfo", wrapperOverride: true, orderNumber:1},
                    "Hired Auto Coverage" : {wrapper: ["hiredAutoCoverage"], type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:2},
                    "Non Owned Delivery": {wrapper: ["hiredAutoDeliveryService"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:3},
                    "Question1977": {wrapper: ["hiredAutoQuestions"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:4},
                    "Question1976": {wrapper: ["hiredAutoQuestions"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:5},
                    "Question2009": {wrapper: ["hiredAutoQuestions"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:6},
                    "Question2010": {wrapper: ["hiredAutoQuestions"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:7},
                    "Question1981": {wrapper: ["hiredAutoPriorToAllow"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:8},

	                "Waiver of Transfer" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "Waiver Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "Waiver Name" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "viewConstructionProject" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "viewConstructionNumber" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "viewConstructionName" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

	                "Photography" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "Photography Number" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "Photography Description" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "Photography Description Bind" : {wrapper: null, type: "omittedHeader",  wrapperOverride: true},
	                "Photography Limit" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "Photography Make" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "Photography Model" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
	                "Photography Serial" : {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},

                    "viewStopGap" : {wrapper: ["hiredAutoSection"], type: "setPolicyInfo", wrapperOverride: true, orderNumber:9},
                    "viewStopGapLimit" : {wrapper: ["stopGapLimit"], type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:10},

                    "viewLiquorLiability" : {wrapper: ["liquorLiability"], type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:11},
                    "viewLiquorGrossSales": {wrapper: ["liquorGrossSales"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:12},
                    "viewQuestion1527": {wrapper: ["liquorLiabilityDoesApplicantQuestion"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:13},
                    "viewQuestion1974": {wrapper: ["liquorLiabilityHasTheApplicantQuestion"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:14},
                    "viewQuestion1532": {wrapper: ["liquorLiabilityIsTrainingProvidedQuestion"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:15},
                    "viewQuestion1530": {wrapper: ["liquorLiabilityWithinThePastQuestion"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:16},
                    "viewQuestion1975": {wrapper: ["liquorLiabilityDoAllServersReceiveQuestion"],type: "setPolicyEndorsement", wrapperOverride: true, orderNumber:17},

                    "Contractors": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Contractors Limits": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Contractors Tools Non Owned": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Tools Non Owned Limit":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Contractors Employees Tools":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Employees Tools Limit": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Contractors Tools Blanket":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Tools Blanket Limit":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Tools Blanket Sub Limit":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Contractors Tools Scheduled":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Tools Scheduled Number":{wrapper: null, type: "omittedHeader", wrapperOverride: true},
                    "Question2006":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Tools Scheduled Description": {wrapper: ["Location"], type: "setLocation", wrapperOverride: true},
                    "Tools Scheduled Limit":{wrapper: ["Location"], type: "setLocation", wrapperOverride: true}

               }
            }
        };
    };

    widget.configureFormly = function (formlyConfig, formlyApiCheck) {

        formlyConfig.setType({
            name: "setPolicyEndorsement",
            template: '<formly-transclude></formly-transclude>'
        });

        formlyConfig.setWrapper({
            name: "OELabelWithMargin",
            template: '<div class="span12" style="margin-left:10px">' + '<formly-transclude></formly-transclude>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "PolicyOEInfoProgramName",
            template:
            '<div class="viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="span4" style=" width: 19%; margin-left: 13px; margin-right: 10px; max-height: 43px" >' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label).replace(":", "")}}</span><br>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "PolicyOEInfoEmployeeLimit",
            template:
            '<div class="viewItem" >' +
            '<formly-transclude></formly-transclude>' +
            '<div class="span4" style=" width:29.8%; margin-left: 0%; max-height: 43px" >' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label).replace(":", "")}}</span><br>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "PolicyOEInfoNumberOfEmployees",
            template:
            '<div class="viewItem" >' +
            '<formly-transclude></formly-transclude>' +
            '<div class="span4" style=" width:21%; margin-left: 0%; max-height: 43px" >' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label).replace(":", "")}}</span><br>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "PolicyOEInfoRetroactiveDate",
            template:
            '<div class="viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="span4" style=" width:27%; margin-left: 0%; max-height: 43px" >' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label).replace(":", "")}}</span><br>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "LeftHorPolicyOE",
            template:
            '<div class="viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span2" style="width: 50%; max-height: 25px; margin-left: 13px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span style="margin-left: 6px">{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "RightHorPolicyOE",
            template:
            '<div class="viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span2" style="width:47%; max-height: 25px; margin-left: 0px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span style="margin-left: 6px">{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "FullHorPolicyOE",
            template:
            '<div class="viewItem" >' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span5" style="width:99%; line-height: 8px; margin-left: 13px; overflow: hidden; word-wrap: break-word">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '<span style="margin-left: 6px; line-height: 18px; overflow: hidden; word-wrap: break-word">{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "PolicyDescription",
            template:
            '<div class="viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span5" style="width:28%; max-height: 25px; margin-left: 13px">' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label).replace(".",":")}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '</div>'+
            '<div  class= "span5" style="width: 70%; margin-top: 2px; line-height: 17px; overflow: hidden; word-wrap: break-word;margin-left: 0px; min-height: 13px">' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "PolicyConstruction",
            template:
            '<div class="viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span5" style="width: 10.5%; max-height: 25px; margin-left: 13px">' +
            '<span ng-if="options.templateOptions.label">{{::(options.templateOptions.label).replace(".",":")}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '</div>'+
            '<div  class= "span5" style="width: 85%; margin-top: 2px; line-height: 16px; overflow: hidden; word-wrap: break-word;margin-left: 6px; min-height: 13px">' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "PolicyQuestion1776",
            template:
            '<div class="viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span5" style="width:87%; max-height: 25px;margin-left: 13px; margin-bottom: 20px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '</div>'+
            '<div  class= "span5" style="width:5%; max-height: 25px;">' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "PolicyQuestion1779",
            template:
            '<div class="viewItem">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span5" style="width:87%; max-height: 25px;margin-left: 13px; margin-bottom: 20px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span ng-if="!options.templateOptions.label">&nbsp;</span>' +
            '</div>'+
            '<div  class= "span5" style="width:5%; max-height: 25px;margin-bottom: 20px">' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>'+
            '</div>'
        });

        formlyConfig.setWrapper( {
            name: "hiredAutoSection",
            template:
            '<div class= "viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div class= "span12" style="margin-left:0; max-height: 26px; margin-top:10px"" >' +
            '<span ng-if="options.templateOptions.label && options.defaultValue == true">{{::options.templateOptions.label}}<br><br></span>' +
            '</div>' +
            '</div>'
        } );

        formlyConfig.setWrapper({
            name: "hiredAutoCoverage",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span6" style="width:66%; margin-left: 6px; max-height: 26px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "hiredAutoDeliveryService",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:30%; max-height: 26px;">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "hiredAutoQuestions",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:88.5%; margin-left:6px;  max-height: 26px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '</div>' +
            '<div class = "span1" style="{{(options.defaultValue===\'No\' || !options.defaultValue )?\'width:10%; margin-left:2px; max-height: 26px\':\'width:10%; margin-left:0%; max-height: 26px\'}}">' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "hiredAutoPriorToAllow",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:87%; margin-left:6px; margin-right:10px;  max-height: 26px; margin-bottom: 18px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '</div>' +
            '<div class = "span1" style="{{(options.defaultValue===\'No\' || !options.defaultValue )?\'width:10%; margin-left:7px; max-height: 26px; margin-bottom: 18px\':\'width:10%; margin-left:6px; max-height: 26px; margin-bottom: 18px\'}}">' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "stopGapLimit",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span12" style="margin-left: 9px; max-height: 26px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "liquorLiability",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span8" style="margin-left: 0%; max-height: 26px">' +
            '<span ng-if="options.templateOptions.label && options.defaultValue===true">{{::options.templateOptions.label}}</span>' +
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "liquorGrossSales",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:30%; margin-left: 26px; max-height: 26px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '<span>&nbsp;&nbsp;</span>' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "liquorLiabilityDoesApplicantQuestion",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:79.7%; margin-left:8px;  max-height: 26px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '</div>' +
            '<div class = "span1" style="{{(options.defaultValue===\'No\' || !options.defaultValue )?\'width:18%; margin-left:2px; max-height: 26px\':\'width:18%; margin-left:0%; max-height: 26px\'}}">' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "liquorLiabilityHasTheApplicantQuestion",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:79.7%; margin-left:8px;  max-height: 26px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '</div>' +
            '<div class = "span1" style="{{(options.defaultValue===\'No\' || !options.defaultValue )?\'width:18%; margin-left:2px; max-height: 26px\':\'width:18%; margin-left:0%; max-height: 26px\'}}">' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "liquorLiabilityIsTrainingProvidedQuestion",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:79.7%; margin-left:8px;  max-height: 46px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '</div>' +
            '<div class = "span1" style="{{(options.defaultValue===\'No\' || !options.defaultValue )?\'width:18%; margin-left:2px; max-height: 46px\':\'width:18%; margin-left:0%; max-height: 46px\'}}">' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "liquorLiabilityWithinThePastQuestion",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:79.7%; margin-left:8px;  max-height: 26px; margin-top:9px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '</div>' +
            '<div class = "span1" style="{{(options.defaultValue===\'No\' || !options.defaultValue )?\'width:18%; margin-left:2px; max-height: 26px; margin-top:9px\':\'width:18%; margin-left:0%; max-height: 26px; margin-top:9px\'}}">' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "liquorLiabilityDoAllServersReceiveQuestion",
            template:
            '<div class="viewItem" ng-if="!options.data.hide">' +
            '<formly-transclude></formly-transclude>' +
            '<div  class= "span4" style="width:79.2%; margin-left:8px; max-height: 26px; margin-bottom: 20px">' +
            '<span ng-if="options.templateOptions.label">{{::options.templateOptions.label}}</span>' +
            '</div>' +
            '<div class = "span1" style="{{(options.defaultValue===\'No\' || !options.defaultValue )?\'width:18%; margin-left:7px; max-height: 26px; margin-bottom: 20px\':\'width:18%; margin-left:6px; max-height: 26px; margin-bottom: 20px\'}}">' +
            '<span>{{::options.defaultValue}}</span><br><br>'+
            '</div>' +
            '</div>'
        });


    };

    return widget;
});
