define( 'components/applicationData/applicationData/controller/ApplicationDataController',['require'],function( require ) {
    "use strict";

    ApplicationDataController.$inject = [ "$scope", "$routeParams", "$timeout", "$filter", "$q", "QuestionService", "ResultService", "SpecialtyDocumentService", "AccountService", "SubmissionStore","UserProfile","$window" ];

    function ApplicationDataController( $scope, $routeParams, $timeout, $filter, $q, QuestionService, ResultService, SpecialtyDocumentService, AccountService, SubmissionStore,UserProfile,$window ) {
	    $scope.uiState 		= { loading: true, error: false };
	    $scope.topSections 	= [];
	    $scope.contextData 	= {};
	    $scope.status 	   	= {};
	    $scope.isMobile   	= UserProfile.isMobile;
	    


        $scope.resize = function()
        {
           	$scope.isMobile  = UserProfile.isMobile;
            if(arguments.length)$scope.$apply();
        }

        angular.element($window).bind('resize',$scope.resize);


        // Contextual data contained outside of the appmaster data is also retrieved, so make two parallel calls here
	    $scope.viewApplication = function() {

		     $q.all( [
			    QuestionService.viewApplication( $scope.application.applicationId, $scope.application.programAbbreviation ),
			    ResultService.getSubmissionContextData( $scope.application.applicationId ),
			    ResultService.getStatus( $scope.application.applicationId ),
			    QuestionService.getInsuredInfo( $scope.application.applicationId )
		    ] ).then( function ( results ) {
			    var submission = results[0];
			    $scope.contextData = results[1].data;
			    $scope.status = results[2].data[0].masterquote;
			    $scope.insuredInfo = results[3].insured;
			    // The top sections must be filtered by visibility and sorted by orderNumber
			    $scope.topSections = $filter( "filter" )( submission, function ( section ) {
				    return section.visible && section.name != "Signature" && section.name != "Notices and Warranty";
			    } ).sortBy( function ( section ) {
				    return section.orderNumber
			    } );
			    /*
				 Wrapping in a timeout because it takes time for the recursive sections to render, and in the
				 meantime the empty top section headers are displayed.  This the simplest way to give the UI
				 time to render
				 */
			    $timeout( function () {
				    $scope.uiState.loading = false;
			    }, 3000 );

		    } ).catch( function ( err ) {
			    $scope.uiState.error = true;
			    $scope.uiState.loading = false;
		    } );
	    };

	    if ( $routeParams.applicationId !== "null"  && typeof $routeParams.applicationId !== 'undefined' ) {
		    $scope.application = {};
		    $scope.application.applicationId = $routeParams.applicationId;
		    $scope.application.programAbbreviation = "";
		    $scope.viewApplication();
	    } else if ( $scope.application ) {
		    $scope.viewApplication();
	    } else {
		    $scope.uiState.error = true;
		    $scope.uiState.loading = false;
	    }



	    $scope.getApplicationDocument = function () {
		    SubmissionStore.applicationId = $scope.application.applicationId;
		    SubmissionStore.programName = $scope.status.programName;
		    SpecialtyDocumentService.getApplicationDocument();
	    };
    }

    return ApplicationDataController;
});
