define('common/service/RecentlyViewedService',['require'],function(require) {
	"use strict";

	RecentlyViewedService.$inject = ["$http", "$q", "maverickApiUrl", "RenewalService"];

	function RecentlyViewedService( $http, $q, maverickApiUrl, RenewalService ) {

		var service = {};

		service.getRecentlyViewed = function() {

			var dataToLoad = {};

			dataToLoad.recentlyViewed = $http.get( maverickApiUrl + "user/getRecentlyViewed" );
			dataToLoad.renewals = RenewalService.getUpcomingRenewals();
			dataToLoad.pendingCancel = RenewalService.getPendingCancellation();

			return $q.all(dataToLoad).then(function( result ) {

				var data = {};

				data.recentlyViewed = result.recentlyViewed.data.arr;
				data.renewals = result.renewals;
				data.pendingCancel = result.pendingCancel;
	
				// maps the accounts from result.recentlyViewed.data.arr to the account IDs from result.recentlyViewed.data.id
				angular.forEach( data.recentlyViewed, function( account, i ) {
					account.id = result.recentlyViewed.data.id[ i ];
					if( typeof( account.accountViewVersion ) === "undefined" ){
						account.accountViewVersion = 1
					}
				});
				angular.forEach( data.pendingCancel, function( cancelPolicy, i ) {
					cancelPolicy.id = result.pendingCancel[ i ].id;
					if( typeof( cancelPolicy.accountViewVersion ) === "undefined" ){
						cancelPolicy.accountViewVersion = 1
					}
				});

				return data;

			});

		};

		service.addRecentlyViewed = function( accountId, accountName, marketedProgramAbbreviation, accountViewVersion ) {

			var params = {
				key: accountId,
				userDataCategory: 'AP-Recent',
				value: $.stringify( {
					id: accountId,
					name: accountName,
					productLine: marketedProgramAbbreviation,
					accountViewVersion: accountViewVersion
				} )
			};

			$http.post( maverickApiUrl + "user/setDataByKey", $.param( params ), {
				headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" }
			});
		};

		return service;

	}

	return RecentlyViewedService;

});
