define( 'common/service/DataPointLookUpService',[],function () {
	"use strict";

	DataPointLookUpService.$inject = ["$http", "archApiUrl"];

	function DataPointLookUpService($http, archApiUrl) {

        var service = {};

		service.getdatapoint =async function(submission) {

            var body = submission;

            return $http({
                method: "POST",
                url:"/datapoint-lookup-api/intake", 
                headers: {
                    "Content-Type": "application/json"
                    
                },
                data: body
            }).then(function(result){
                return result.data;
            });

		};     

		return service;
	}

	return DataPointLookUpService;

});
