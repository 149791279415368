define( 'components/specialtyApplication/questions/config/widgets/UploadDocumentsWidget',['require'],function( require ) {
    "use strict";
    var widget = {};

    widget.getStructure = function() {
        return {
            "_datapointPlacement": "strictOrder",
            "applicationFileUpload": {
                type: "documentUploadLabel",
                wrapper: [ "documentUpload" ],
                wrapperOverride: true,
                data: {
                    fileUploadSettings: {
                        documentType: "Applications MSC",
                        documentTypeDescriptor: "applications",
                        buttonLabel: "Upload application forms",
                        modalTitle: "Upload Application Forms",
                        showUploadInstruction: false
                    }
                }
            },

	        "PortalFileUpload": {
		        type: "documentUploadLabel",
		        wrapper: [ "documentUpload" ],
		        wrapperOverride: true,
		        data: {
			        fileUploadSettings: {
				        documentType: "Portal Uploaded Document MSC",
				        documentTypeDescriptor: "correspondence",
				        buttonLabel: "Upload documents",
				        modalTitle: "Upload Documents",
				        showUploadInstruction: false
			        }
		        }
	        },
            // This will be refactored once we design a way to get the form download link from the marketed program record
            "applicationFormDownload": {
                type: "applicationFormDownloadLabel",
                wrapper: null,
                wrapperOverride: true,
                data: {
                    actions: [
                        {
                            methodName: "setMarketedProgramOfflineApplicationUrl",
                            contexts: [ "update" ],
                            programs: []
                        }
                    ]
                }
            }
        };
    };

    widget.configureFormly = function( formlyConfig, formlyApiCheck ) {

    };

    return widget;
});
