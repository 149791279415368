define('common/service/search/SearchResultContainer',['require'],function(require) {
	"use strict";

	/**
	* The purpose of SearchResultContainer is to consolidate several different services under a singular
	* object that we can use for displaying search results. In addition to the search results themselves,
	* it contains a pagination service, an error container, and flags indicating the status of retrieving
	* the search results.
	*
	* The "working" status should be set by an outside process prior to the results being returned from
	* the server. Once the results have been returned, they should be set in the container using the "populate"
	* method. After populating the container (or errors, if appropriate), this method will set the "working"
	* status back to false.
	*
	* The "ready" status can be used to determine if results are ready to be displayed. For example, if you
	* are hiding a UI table until results have been populated and paginated, you can use this status as an
	* indicator that the results are safe to display.
	*/

	SearchResultContainerFactory.$inject = ["MessageContainer", "PaginationService"];

	function SearchResultContainerFactory( MessageContainer, PaginationService ) {

		function SearchResultContainer( config ) {

			this.config = {
				pagination: new PaginationService({ currentPage: config.currentPage, itemsPerPage: config.itemsPerPage })
			};

			this.error = new MessageContainer({
				header: "",
				message: ""
			});

			this.status = {
				working: false,
				ready: false
			};

			this.results = [];
			this.filteredResults = [];

		}

		SearchResultContainer.prototype.paginate = function() {
			this.filteredResults = this.config.pagination.paginate( this.results );
		};

		SearchResultContainer.prototype.populate = function( results ) {

			if( results.searchResults.tooMany ) {
				this.error.header = "Too many records returned.";
				this.error.message = "Please add more criteria and try again.";
			} else {
				this.results = results.searchResults;
				this.paginate();
				this.status.ready = true;
			}
			
			this.status.working = false;

			return this;

		};

		SearchResultContainer.prototype.clearResults = function() {
			this.results = [];
			this.filteredResults = [];
		};

		SearchResultContainer.prototype.resetErrors = function() {
			this.error.reset();
		};

		return SearchResultContainer;

	}

	return SearchResultContainerFactory;

});
