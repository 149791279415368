define('components/specialtyApplication/eligibility/controller/EligibilityController',[],function() {

	EligibilityController.$inject = ["$scope","$q","$location","moment","UserProfile","UserService","SubmissionStore","SpecialtyEligibilityService","MessageContainer","ProductSearchService","$routeParams"];

	function EligibilityController($scope,$q,$location,moment,UserProfile,UserService,SubmissionStore,SpecialtyEligibilityService,MessageContainer,ProductSearchService,$routeParams)
	{

		$scope.status = {
			retrievingApplication: true,
			retrievalError: false,
			showDateChangedMessage: false,
			previousEffectiveDate: "",
			loading: false,
			notEligible: false,
			canEditZip: true
		};

		$scope.notEligibleMsg = "";
		$scope.businessTypeDescriptions= [];

		var business_type_tag =  UserProfile.marketedProgram.businessTypeTag;

		$scope.form = {
			zip 			: SubmissionStore.zip == undefined ? "" : SubmissionStore.zip,
			effectiveDate 	: SubmissionStore.effectiveDate == undefined ? moment().format("MM/DD/YYYY") : SubmissionStore.effectiveDate,
			marketedProgram :  "",
			businessTypeDescription : ""
		};

		$scope.isUnAuthenticatedUser        = SubmissionStore.unAuthenticatedCL? true : false
		//disabled the requirement of needing to come from acct summary
        if( $routeParams.applicationId && $routeParams.applicationId == UserProfile.editedApplication ){
			// Replace visitedSections with the appropriate starting point
			if( UserProfile.type == 'Agent' ) {
				SubmissionStore.visitedSections = [ '/application/agent' ];
			} else {
				SubmissionStore.visitedSections = [ '/application' ];
			}

			SpecialtyEligibilityService.reopenSubmission( $routeParams.applicationId ).then( function( result ) {
				$scope.status.retrievalError = result.error;
				if( result.response ) {
					var currentEffectiveDate = moment( result.response.effectiveDate, "YYYY-MM-DD" );
					SubmissionStore.previousValues = {
						effectiveDate: currentEffectiveDate,
						insured: result.response.insured,
						canEditInsured: result.response.canEditInsured
					};
					SubmissionStore.applicationId = $routeParams.applicationId ;
					SubmissionStore.programName = result.response.programName;
					SubmissionStore.masterQuoteID = result.response.masterQuoteID;
					SubmissionStore.servicingAgentId = result.response.servicingAgentId;
					SubmissionStore.licensedAgentId = result.response.licensedAgentId;
					SubmissionStore.instance = result.response.instance;
					SubmissionStore.resumeLink = result.response.resumeLink;
					SubmissionStore.insured = result.response.insured;

					$scope.status.canEditZip = result.response.canEditInsured;
					$scope.marketedPrograms = [ result.response.marketedProgram ];
					// Set the marketedProgram on the UserProfile to prevent the user from changing it
					UserProfile.marketedProgram = result.response.marketedProgram;

					$scope.form.marketedProgram = result.response.marketedProgram.value;
					$scope.form.zip = result.response.insured.zip;

					// If the user is not logged in as an agent and the effective date is in the past,
					// set the form's effective date to the current date and display a note about the
					// change.
					if( currentEffectiveDate.isBefore( moment(), "day" ) && UserProfile.type != 'Agent' ) {
						$scope.status.previousEffectiveDate = currentEffectiveDate.format( "MM/DD/YYYY" );
						$scope.status.showDateChangedMessage = true;
						$scope.form.effectiveDate = moment().format( "MM/DD/YYYY");
					} else {
						$scope.form.effectiveDate = currentEffectiveDate.format( "MM/DD/YYYY" );
					}
				}
				$scope.status.retrievingApplication = false;
			});

		} else {
			$scope.status.retrievingApplication = false;
			// Turn off the flag indicating this is an edit session.
			UserProfile.editedApplication = 0;

			$q.all({
				businessTypeDescriptions:$scope.isUnAuthenticatedUser ? ProductSearchService.getBusinessTypeDescriptions() : [],
				marketedPrograms: SpecialtyEligibilityService.getMarketedPrograms( UserProfile.cobranding.id )
			}).then(function( result ) {
				if($scope.isUnAuthenticatedUser){
					$scope.setBusinessTypeDescriptions(result.businessTypeDescriptions,business_type_tag);
				}else {
					$scope.setMarketedPrograms( result.marketedPrograms, UserProfile.marketedProgram, SubmissionStore.marketedProgramId );
				}
			});
		}



		$scope.messages = new MessageContainer({ error: [] });

        $scope.submitForm = function () {
	        $scope.messages.reset();
	        $scope.status.loading = true;
	        $scope.status.notEligible = false;
	        $scope.marketedProgramid =  $scope.form.marketedProgram;


	        if($scope.isUnAuthenticatedUser && UserProfile.cobranding.id == '' ){
		        $scope.marketedProgramid = UserProfile.marketedProgram.id
                if(!$scope.form.businessTypeDescription){
                    $scope.messages.error.push("You must select a business type");
                    $scope.status.loading = false;
                    return;
                }
	        }
		        SpecialtyEligibilityService.checkAvailability(
				        $scope.form.zip,
				        $scope.form.effectiveDate,
                    	$scope.marketedProgramid,
				        UserProfile.cobranding.id
		        ).then( function ( result ) {
			        var data = result.data;

			        angular.extend( SubmissionStore, {
				        agencyId: data.agencyId,
				        agentId: data.agentId,
				        programCode: data.programCode,
				        programName: data.programName,
				        marketedProgramId: $scope.form.marketedProgram,
				        form: {
					        cities: data.cities,
					        state: data.state,
					        zip: $scope.form.zip,
					        documentDeliveryOptions: data.documentDeliveryOptions
				        },
				        effectiveDate: $scope.form.effectiveDate,
				        cobrandingId: UserProfile.cobranding.id,
				        insuredMatchDispThreshold: data.insuredMatchDispThreshold
			        } );
					if(data.mAgencyId && $scope.isUnAuthenticatedUser){
                        UserProfile.agencyId = data.mAgencyId;
					}
			        //most likely got here from next step or from start quote
			        //check to see if the programID has changed
            		if(SubmissionStore.programs.length == 1)
            		{
				        //only reset if our curret program doesn't match the one in submission store
				        //if it does match we can keep our settings including datapoints
            			if($scope.form.marketedProgram != SubmissionStore.programs[0].marketedProgramId)
            			{
					        //just reset our programs in case someone hit back
					        SubmissionStore.programs = [];
		            		SubmissionStore.programs.push({ marketedProgramId      	:   $scope.form.marketedProgram,
						        marketedProgramName: data.programName,
						        programCode: data.programCode,
						        effectiveDate: $scope.form.effectiveDate,
						        dataPoints: [],
						        referenceString: data.referenceString,
						        isUploadBasedSubmission: data.isUploadBasedSubmission,
						        offlineApplicationUrl: data.offlineApplicationUrl,
						        fraudLink            : data.fraudLink
					        } );
				        }
				        else {
					        SubmissionStore.programs[0].effectiveDate = $scope.form.effectiveDate
				        }
			        }

            		if(SubmissionStore.programs.length == 0)
            		{
				        //just reset our programs in case someone hit back
				        SubmissionStore.programs = [];
						SubmissionStore.programs.push({ marketedProgramId       :   $scope.form.marketedProgram,
					        marketedProgramName: data.programName,
					        programCode: data.programCode,
					        effectiveDate: $scope.form.effectiveDate,
					        dataPoints: [],
					        referenceString: data.referenceString,
					        isUploadBasedSubmission: data.isUploadBasedSubmission,
					        offlineApplicationUrl: data.offlineApplicationUrl,
					        fraudLink               : data.fraudLink
				        } );
			        }


			        if ( $scope.isUnAuthenticatedUser ) {
				        UserProfile.ps = {};
				        UserProfile.ps.state = SubmissionStore.form.state.abbreviation;
				        UserProfile.ps.date = $scope.form.effectiveDate;
				        UserProfile.ps.label = $scope.form.businessTypeDescription.split("-")[1];
				        UserProfile.ps.business_type_id = $scope.form.businessTypeDescription.split("-")[0];
				        UserProfile.ps.business_type_descriptions = $scope.businessTypeDescriptions;
				        SubmissionStore.isUnAuthenticatedDirect = true;
				        $location.path("/application/search/" +UserProfile.ps.business_type_id).replace();
			        }else {
				        $location.path( "/application/account" );
			        }

		        } ).catch( function ( err ) {
			        // Hide the message about the changed date, as it is no longer needed
			        $scope.status.showDateChangedMessage = false;

			        var data = err.data;

			        if ( err.status === 409 ) {
				        $scope.setEligibilityErrorMsg( err.data.message );
				        $scope.status.notEligible = true;
			        } else {

				        ["zip", "marketedProgramId", "effectiveDate", "amEffectiveDate"].forEach( function ( key ) {
					        if ( data[key] ) {
						        if ( key == "zip" ) {
							        var selectedMarketedProgram;
							        angular.forEach( $scope.marketedPrograms, function ( program ) {
								        if ( program.id == $scope.form.marketedProgram ) {
									        selectedMarketedProgram = program;
								        }
							        } );
							        if ( selectedMarketedProgram && selectedMarketedProgram.referenceString.toLowerCase() == "horsemortality" ) {
								        $scope.messages.error = $scope.messages.error.concat( data[key].map( function ( validation ) {
									        return "A valid 5-digit United States zip code is required to continue.  For applicants residing outside of the United States: Our online application does not support mailing addresses outside of the United States at this time. For assistance with insurance, please contact our office.";
								        } ) );
							        } else {
								        $scope.messages.error = $scope.messages.error.concat( data[key].map( function ( validation ) {
									        return validation.message;
								        } ) );
							        }
						        } else {
							        // we'll probably only ever get a single message back per domain object, but just in case.. map over the message array.
							        $scope.messages.error = $scope.messages.error.concat( data[key].map( function ( validation ) {
								        return validation.message;
							        } ) );
						        }

					        }
				        } );

			        }

			        $scope.status.loading = false;
		        } );
	        };

		$scope.setMarketedPrograms = function( marketedPrograms, profileProgram, submissionProgram ) {
			$scope.marketedPrograms = profileProgram.value ? [ profileProgram ] : marketedPrograms;
			if( submissionProgram ) {
				$scope.form.marketedProgram = submissionProgram;
			}

			if( $scope.marketedPrograms.length === 1 ) {
				$scope.form.marketedProgram = $scope.marketedPrograms[ 0 ].value;
			} else {
				$scope.marketedPrograms.unshift({ label: "Select One", value: "" });
			}
		};
		$scope.setBusinessTypeDescriptions = function ( businessTypeDescriptions, business_type_tag ) {
			if(business_type_tag){
				$scope.businessTypeDescriptions = ProductSearchService.getMatchingDescriptions( businessTypeDescriptions, business_type_tag );
			}
			if($scope.businessTypeDescriptions.length === 1){
                $scope.form.businessTypeDescription = $scope.businessTypeDescriptions[0].businessTypeID + "-" +$scope.businessTypeDescriptions[0].label;
			}

		}

		$scope.setEligibilityErrorMsg = function( responseErrorMsg ) {
			if( responseErrorMsg == "Product not available." ) {
				// Currently the error message above is only returned if the effective date is outside of the effective date range
				// determined by mPolicy for the selected program, so the error message can cite the effective date as the main cause.
				$scope.notEligibleMsg = "Sorry, but the selected product is not available for the selected effective date.";
			} else {
				$scope.notEligibleMsg = "Sorry, but we do not currently offer a product for the effective date, business type, and state you selected. Please contact customer service for additional information.";
			}
		}
	}

	return EligibilityController;

});
