define('components/widget/building-optional-endorsements/mortgage-holder-details/directive/mortgageHolderDetails',['require'],function(require) {
	"use strict";

	mortgageHolderDetails.$inject = [];

	function mortgageHolderDetails() {
		return {
			restrict: "AE",
			scope:{
				model	            : "=",
				options             : "="
			},
			templateUrl: "app/components/widget/building-optional-endorsements/mortgage-holder-details/template/template.html",
			controller: "MortgageHolderDetailsController"
		};
	}

	return mortgageHolderDetails;

});
