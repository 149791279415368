define('search/app',['require','common/config/config','components/accountsSearch/app','components/submissionsSearch/app','components/policiesSearch/app','components/claimsSearch/app','components/classCodesSearch/app','components/twoYearView/twoYearView/app','shim/overmind','common/service/search/SearchService','search/controller/AdvancedSearchController','moment'],function(require) {
	"use strict";

	require("common/config/config");
	require("components/accountsSearch/app");
	require("components/submissionsSearch/app");
	require("components/policiesSearch/app");
	require("components/claimsSearch/app");
	require("components/classCodesSearch/app");
	require("components/twoYearView/twoYearView/app");

	var overmind = require("shim/overmind");

	var SearchService = require("common/service/search/SearchService"),
		AdvancedSearchController = require("search/controller/AdvancedSearchController"),
		moment = require("moment");

	var app = angular.module("mPortal.search", [
		"mPortal.config",
		"mPortal.components.accountsSearch",
		"mPortal.components.submissionsSearch",
		"mPortal.components.policiesSearch",
		"mPortal.components.claimsSearch",
		"mPortal.components.classCodesSearch",
		"mPortal.components.twoYearView.twoYearView"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider
			.when("/search/", {
				templateUrl: "app/search/template/advanced.html",
				controller: "AdvancedSearchController"
			});
	}]);

	app.controller("AdvancedSearchController", AdvancedSearchController);
	app.factory("SearchService", SearchService);
	app.value("moment", moment);

	return app;

});
