define('components/specialtyApplication/questionShellSections/agentDateSelections/controller/AgentDateSelectionsController',['require'],function(require) {
    "use strict";

    AgentDateSelectionsController.$inject = [ "$scope", "$q", "$modal", "moment", "SpecialtyEligibilityService", "ApplicationSectionService" ];

    function AgentDateSelectionsController( $scope, $q, $modal, moment, SpecialtyEligibilityService, ApplicationSectionService ) {

        /*
         Development notes:
         The component takes the shell section containing this component, the SubmissionStore, and
         UserProfile as directive arguments, so they are available on the $scope as:

         $scope.section
         $scope.store
         $scope.user

         This component is going to look like it's a regular, appmaster-based section
         in the Questions view, so it should behave like one.  That means:

         1) Whenever the user changes the value of a form input (and, in the case of a text input,
         navigates away from / triggers a blur event on the input), a REST call should be made to
         save the data, using whatever endpoint you create to do that.

         2) If any of the form inputs are invalid, the section should be marked as invalid.  The shell
         section has two methods for toggling the validity of the section:

         $scope.section.markShellValid();
         $scope.section.markShellInvalid();

         By default, the shell section starts off as invalid.  If you make a REST call to load the
         current effective date and agent data for this component and determine that all of the
         required data is already set, go ahead and invoke the markShellValid() method.

         As the user changes data, you'll need to update the validity of the section.  If a form
         input changes from valid to invalid, you can simply invoke markShellInvalid().  But if a
         form input changes from invalid to valid, you'll need to check if there are any other invalid
         form inputs before you can decide if you should invoke markShellValid().  So you may want to
         track the validity of all of the form inputs in the $scope.
        */

        $scope.form = {
            licenseAgents       : [],
            serviceAgents       : []
        };

        $scope.uiState = {
            firstRun : true,
            showLicensedAgentDetails : false,
            invalidForm : false,
            hasLicenseData : true
        };

        $scope.agentDetails = {
            agentId : '',
            licenseId : '',
            effectiveDate : ''
        };

        $scope.getAgentsAndLicenses = function () {

            var p =[];

            p.push( SpecialtyEligibilityService.getAgentsAndLicenses( $scope.user.agencyId, $scope.store.programs, $scope.store.state ) );

            $q.all(p).then(function(results){

                $scope.licenses    =  results[ 0 ].data.licenses;
                $scope.agents      =  results[ 0 ].data.agents;

                var data = results [ 0 ].data.programs;

                if( !$scope.licenses.length ){
                    $scope.uiState.hasLicenseData = false;
                }

                //assign boolean value for respective program
                for ( var i = 0; i < data.length; i++ ) {

                    $scope.store.programs[ i ].licensedAgentRequired    =   data[ i ].licensedAgentRequired;
                    $scope.store.programs[ i ].servicingAgentRequired   =   data[ i ].servicingAgentRequired;
                    $scope.store.programs[ i ].programCode              =   data[ i ].programCode;

	                if ( $scope.store.programs[ i ].licensedAgentRequired ) {
		                $scope.uiState.showLicensedAgentDetails = true;
	                }

                }

                //populating list of license agents
                for ( var j = 0; j < $scope.licenses.length; j++ ) {

                    if ( $scope.licenses[ j ].agent && $scope.licenses[ j ].agent.active === true) {

                        $scope.form.licenseAgents.push( {
                            name        :   $scope.licenses[ j ].agent.firstName + " " + $scope.licenses[ j ].agent.lastName,
                            licenseId   :   $scope.licenses[ j ].agent.id,
                            state       :   $scope.licenses[ j ].state,
                            effDate     :   $scope.licenses[ j ].effectiveDate,
                            expDate     :   $scope.licenses[ j ].expirationDate,
                            status      :   $scope.licenses[ j ].agent.active
                        } );
                    }
                }

                //populating list of service agents
                for ( var k = 0; k < $scope.agents.length; k++ ) {
					if($scope.agents[k].active === true)
                    $scope.form.serviceAgents.push( {
                        name        :   $scope.agents[ k ].firstName + " " + $scope.agents[ k ].lastName,
                        agentId     :   $scope.agents[ k ].id,
                        status      :   $scope.agents[ k ].active
                    } )
                }

                /* I'm using store.programs to show the user selected values in bundle submission.
                *  User can edit only one submission at a time even in bundled. So, I'm using the else
                *  logic to handle to show user selected values while editing the submission */

                if( $scope.store.programs.length > 1 ){

                    for ( var m = 0; m < $scope.store.programs.length; m++ ) {

                        if ( !$scope.store.programs[ m ].serviceAgent )
                            $scope.store.programs[ m ].serviceAgent = $scope.form.serviceAgents.filter( function ( agent ) { return agent.agentId === $scope.user.userId } )[ 0 ];

                        $scope.store.programs[ m ].serviceAgent = $scope.form.serviceAgents.filter( function ( agent ) { return agent.agentId === $scope.store.programs[ m ].serviceAgent.agentId } )[ 0 ];

	                    if ( !$scope.store.programs[m].serviceAgent ) {
		                    $scope.store.programs[m].serviceAgent = $scope.form.serviceAgents[0];
	                    }

                        //assign default value only if licensed agent info required is true
                        if ( !$scope.store.programs[ m ].licensedAgentRequired )
                            continue;
                        else if ( !$scope.store.programs[ m ].licensedAgent )
                            $scope.store.programs[ m ].licensedAgent = $scope.form.licenseAgents.filter( function ( license ) { return license.licenseId === $scope.user.userId } )[ 0 ];

                        $scope.store.programs[ m ].licensedAgent = $scope.form.licenseAgents.filter( function ( license ) { return license.licenseId === $scope.store.programs[ m ].licensedAgent.licenseId } )[ 0 ];
                        if(!$scope.store.program[m].licensedAgent)
	                        $scope.store.programs[ m ].licensedAgent = $scope.form.licenseAgents[ 0 ];
                        if ( !moment($scope.store.programs[m].effectiveDate, 'MM/DD/YYYY',true).isValid() && !moment($scope.store.programs[m].effectiveDate, 'M/D/YYYY',true).isValid() ) {
                            $scope.uiState.invalidForm = true;
                            $scope.section.markShellInvalid();
                        }

                    }
                } else {

                    if ($scope.store.programs[0].serviceAgent)
                        $scope.servicingAgent = $scope.form.serviceAgents.filter(function (agent) {
                            return agent.agentId === $scope.store.programs[0].serviceAgent.agentId
                        })[0];
                    else if ($scope.store.servicingAgentId)
                        $scope.servicingAgent = $scope.form.serviceAgents.filter(function (agent) {
                            return agent.agentId === $scope.store.servicingAgentId
                        })[0];
                    else
                        $scope.servicingAgent = $scope.form.serviceAgents.filter(function (agent) {
                            return agent.agentId === $scope.user.userId
                        })[0];
	                if ( !$scope.servicingAgent )
		                $scope.servicingAgent = $scope.form.serviceAgents[0];

                    $scope.store.programs[0].serviceAgent = $scope.servicingAgent;

                    if ($scope.store.programs[0].licensedAgent)
                        $scope.licenseAgent = $scope.form.licenseAgents.filter(function (license) {
                            return license.licenseId === $scope.store.programs[0].licensedAgent.licenseId
                        })[0];
                    else if ($scope.store.licensedAgentId)
                        $scope.licenseAgent = $scope.form.licenseAgents.filter(function (license) {
                            return license.licenseId === $scope.store.licensedAgentId
                        })[0];
                    else if ($scope.store.programs[0].licensedAgentRequired)
                        $scope.licenseAgent = $scope.form.licenseAgents.filter(function (license) {
                            return license.licenseId === $scope.user.userId
                        })[0];
                        if(!$scope.licenseAgent)
	                        $scope.licenseAgent = $scope.form.licenseAgents[0];

                    $scope.store.programs[0].licensedAgent = $scope.licenseAgent;
                    if ( !moment($scope.store.programs[0].effectiveDate, 'MM/DD/YYYY', true).isValid() && !moment($scope.store.programs[0].effectiveDate, 'M/D/YYYY',true).isValid() ) {
                        $scope.uiState.invalidForm = true;
                        $scope.section.markShellInvalid();

                    }
                }

                if( !$scope.uiState.invalidForm ) {
                    $scope.section.markShellValid();
                }

            } ).catch( function ( error ){
                $scope.section.markShellInvalid();
                $scope.uiState.invalidForm = true;
            });
        };
        $scope.viewLicensedInfo = function( license ){
            $modal.open({
                templateUrl: "app/components/specialtyApplication/questionShellSections/agentDateSelections/template/licensedInfoModal.html",
                controller: "LicenseInfoModalController",
                resolve: {
                    selectedLicense: function() { return license; },
                    licenseAgents: function(){ return $scope.form.licenseAgents;}
                }
            });
        };

        $scope.updateAgentDetails = function( ) {
           if( $scope.uiState.firstRun ){
                $scope.uiState.firstRun = false;
                return;
            }
            $scope.section.error = false;
            for ( var g = 0; g < $scope.store.programs.length; g++ ) {
                if ( !moment($scope.store.programs[g].effectiveDate, 'MM/DD/YYYY',true).isValid() && !moment($scope.store.programs[g].effectiveDate, 'M/D/YYYY',true).isValid() ) {
                    $scope.uiState.invalidForm = true;
                    $scope.section.markShellInvalid();
                    break;
                }
                else {
                    $scope.uiState.invalidForm = false;
                    $scope.section.markShellValid();
                    if ($scope.store.programs[g].licensedAgentRequired)
                        $scope.agentDetails.licenseId = $scope.store.programs[g].licensedAgent ? $scope.store.programs[g].licensedAgent.licenseId : null;

                    $scope.agentDetails.agentId = $scope.store.programs[g].serviceAgent ? $scope.store.programs[g].serviceAgent.agentId : null;
                    $scope.agentDetails.effectiveDate = $scope.store.programs[g].effectiveDate;
                    ApplicationSectionService.updateAgentDetails($scope.agentDetails.agentId, $scope.agentDetails.licenseId, $scope.agentDetails.effectiveDate, $scope.store.applicationId + g, $scope.store.instance, $scope.store.programs[g].programCode).then( function( result ) {
                        if ( result.data.message ) {
                            $scope.section.markShellInvalid();
                            $scope.section.error = true;
                        }
                    });
                }

            }

        };

        $scope.$watch('store.programs',$scope.updateAgentDetails,true);
        $scope.getAgentsAndLicenses();

    }

    return AgentDateSelectionsController;

});
