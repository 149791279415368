define('components/widget/document-upload/controller/DocumentUploadModalController',['require'],function(require) {
	"use strict";

	DocumentUploadModalController.$inject = ["$rootScope", "$scope", "$modalInstance", "$timeout", "archApiUrl", "SubmissionStore", "SpecialtyDocumentService", "documentSettings", "submissionId", "UserProfile" ];

	function DocumentUploadModalController( $rootScope, $scope, $modalInstance, $timeout, archApiUrl, SubmissionStore, SpecialtyDocumentService, documentSettings, submissionId, UserProfile ) {

		$scope.submissionId = submissionId;
		$scope.effectiveDate = SubmissionStore.effectiveDate;
		$scope.close = $modalInstance.close;
		$scope.documentType = documentSettings.documentType;
		$scope.modalTitle = documentSettings.modalTitle;
		$scope.profileToken = UserProfile ? ( UserProfile.profileToken ? UserProfile.profileToken : UserProfile.portalToken ) : "";
		$scope.formAction = archApiUrl + "application/upload-document";


		$scope.uiState = {
			submitting: false,
			uploadFailed: false,
			sizeError: false,
			validFileType: true,
			completed: false,
			errors: []
		};

		$scope.dropText = "Drag and Drop Files Here To Upload.";

		var dropzone;

		$timeout( function () {
			dropzone = document.getElementById( "dropzone" );
		}, 1000 );


		$timeout( function () {
			dropzone.addEventListener( "dragenter", dragEnterLeave, false );
			dropzone.addEventListener( "dragleave", dragEnterLeave, false );
			dropzone.addEventListener( "dragover", dragOver, false );
			dropzone.addEventListener( "drop", drop, false );
			try {
				jQuery.props.push('dataTransfer');
			} catch( error ) {
				//Do nothing; swallow the error.
			}
		}, 2000 );

		function dragEnterLeave(evt) {
			evt.stopPropagation();
			evt.preventDefault();
		}

		function dragOver( evt ) {
			evt.stopPropagation();
			evt.preventDefault();
		}

		function drop( evt ) {
			evt.stopPropagation();
			evt.preventDefault();
			var dt = evt.dataTransfer;
			var files = dt.files;
			$scope.file = files[0];
			var reader = new FileReader();
			reader.readAsDataURL(files[0]);
			reader.onload = function (e) {
				var temp = e.target.result;
				$scope.file.base64 = temp.substr(temp.indexOf(',') + 1);
			};

			$timeout( function () {
				if ( !$scope.file.size ) {
					$scope.uiState.sizeError = true;
					$scope.uiState.validFileType = true;
				} else {
					$scope.dropText = $scope.file.name;
					$scope.uiState.sizeError = false;
					$scope.uiState.validFileType = true;
					SpecialtyDocumentService.uploadDocumentByDragAndDrop(
							$scope.file.name,
							$scope.file.type,
							$scope.file.base64,
							$scope.submissionId,
							$scope.documentType,
							$scope.effectiveDate ).then( function ( result ) {
						$rootScope.$broadcast( 'update-document-list', { submissionId : $scope.submissionId } );
						$scope.uiState.completed = true;
					} ).catch(function( err ) {
						$scope.dropText = "Drag and Drop Files Here To Upload.";
						if( err.status === 422 ){
							$scope.uiState.validFileType = false
						} else {
							$scope.uiState.uploadFailed = true;
						}
					} );
				}
			}, 1000 );
		}

		$scope.documents = [{id: 'document1'}];

		$scope.onSubmit = function() {
			$scope.uiState.submitting = true;
			$scope.uiState.completed = false;
			$scope.uiState.errors = [];
		};

		$scope.onUploadSuccess = function() {
			$scope.uiState.validFileType = true;
			$scope.uiState.uploadFailed = false;
			$scope.uiState.submitting = false;
			$scope.uiState.completed = true;
			$rootScope.$broadcast( 'update-document-list', { submissionId : $scope.submissionId } );
		};

		$scope.onUploadFailure = function( result ) {
			$scope.uiState.uploadFailed = false;
			$scope.uiState.submitting = false;
			$scope.uiState.completed = false;
			$scope.uiState.errors = result.message || [ "An unexpected error has occurred. Please try again." ];
		};

	}

	return DocumentUploadModalController;

});
