define('components/twoYearView/accountTable/app',['require','components/twoYearView/accountTable/directive/accountTable','components/twoYearView/accountTable/controller/AccountTableController','common/filter/formatMoney'],function(require) {
	"use strict";

	var accountTable = require("components/twoYearView/accountTable/directive/accountTable"),
		AccountTableController = require("components/twoYearView/accountTable/controller/AccountTableController"),
		formatMoney = require("common/filter/formatMoney");

	var component = angular.module("mPortal.components.twoYearView.accountTable", []);

	component.directive("twoYearAccountTable", accountTable);
	component.controller("AccountTableController", AccountTableController);
	component.filter("formatMoney", formatMoney);

	return component;

});
