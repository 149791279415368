define( 'components/specialtyApplication/quoteResult/app',['require','components/specialtyApplication/pricingDetails/app','components/documentDeliveryPreferences/app','components/specialtyApplication/quoteResult/directive/quoteResult','components/specialtyApplication/quoteResult/controller/ConfirmController','components/specialtyApplication/quoteResult/controller/QuoteResultController','common/service/user/UserProfile','common/service/QuoteResultService','common/filter/formatMoney','common/filter/trustHtml'],function ( require ) {
    "use strict";

    require("components/specialtyApplication/pricingDetails/app");
    require("components/documentDeliveryPreferences/app");

    var quoteResult = require( "components/specialtyApplication/quoteResult/directive/quoteResult" ),
		ConfirmController = require( "components/specialtyApplication/quoteResult/controller/ConfirmController" ),
        QuoteResultController = require( "components/specialtyApplication/quoteResult/controller/QuoteResultController" ),
        UserProfile = require( "common/service/user/UserProfile" ),
        QuoteResultService = require("common/service/QuoteResultService"),
        formatMoney = require( "common/filter/formatMoney" ),
        trustHtml = require( "common/filter/trustHtml" );

    var component = angular.module( "mPortal.components.specialtyApplication.quoteResult", [
        "mPortal.components.specialtyApplication.pricingDetails",
        "mPortal.components.documentDeliveryPreferences"
    ] );

    component.filter( "formatMoney", formatMoney );
    component.filter( "trustHtml", trustHtml );
    component.directive( "quoteResult", quoteResult );
    component.controller( "ConfirmController", ConfirmController);
    component.controller( "SpecialtyApplicationQuoteResultController", QuoteResultController );
    component.factory( "UserProfile", UserProfile );
    component.factory("QuoteResultService", QuoteResultService);

    return component;

} );
