define( 'components/specialtyApplication/resume/controller/ResumeController',[],function() {

    ResumeController.$inject = [ "$scope", "$location", "ResumeService", "SubmissionStore", "UserProfile" ];

    function ResumeController( $scope, $location, ResumeService, SubmissionStore, UserProfile ) {

        // This controller can only be used if the applicationId has been stored in the SubmissionStore
        if( !SubmissionStore.applicationId ) {
            $location.path( UserProfile.type == 'Agent' ? "/" : "/application" );
        }

        $scope.zipCode = "";
        $scope.messages = { error: [] };
        $scope.status = { loading: false };

        $scope.submitForm = function() {
            $scope.messages.error = [];
            $scope.status.loading = true;
            ResumeService.verifyResumeAttempt( SubmissionStore.applicationId, $scope.zipCode ).then( function( result ) {
                if( result.error ) {
                    $scope.messages.error.push( result.message ? result.message : 'An error occurred. Please contact customer service for assistance.' );
                } else {
                    if( result.incomplete ) {
                        UserProfile.set( "editedApplication", SubmissionStore.applicationId );
                        if( UserProfile.type == 'Agent' ) {
                            $location.path( "/application/agent/edit/" + SubmissionStore.applicationId );
                        } else {
                            $location.path( "/application/resume/" + SubmissionStore.applicationId );
                        }
                    } else {
                        // Send the user to the result page
                        $location.path( "/application/result" );
                    }
                }
                $scope.status.loading = false;
            });
        }
    }

    return ResumeController;
});


