define('components/applicationActions/app',['require','components/applicationActions/directive/applicationActions','components/applicationActions/controller/ApplicationActionsController','components/specialtyApplication/resumeLater/controller/ResumeLaterController'],function(require) {
    "use strict";

    var applicationActions = require("components/applicationActions/directive/applicationActions"),
        ApplicationActionsController = require("components/applicationActions/controller/ApplicationActionsController"),
        ResumeLaterController = require("components/specialtyApplication/resumeLater/controller/ResumeLaterController");

    var component = angular.module("mPortal.components.applicationActions", []);

    component.directive("applicationActions", applicationActions);
    component.controller("ApplicationActionsController", ApplicationActionsController);
    component.controller("ResumeLaterController", ResumeLaterController );

    return component;

});
