define('common/service/PublicContentService',['require'],function(require) {
	"use strict";

	PublicContentService.$inject = ["$http", "archApiUrl"];

	function PublicContentService( $http, archApiUrl ) {

		var service = {};

		service.getAgentTermsAndConditions = function() {
			return getTermsAndConditions( "agent" );
		};

		service.getInsuredTermsAndConditions = function() {
			return getTermsAndConditions( "insured" );
		};

		function getTermsAndConditions( contentType ) {
			return $http.get( archApiUrl + "public-content/terms-and-conditions", {
				params: {
					contentType: contentType
				}
			}).then(function( result ) {
				return result.data;
			});
		}

		return service;

	}

	return PublicContentService;

});
