define('components/widget/field-group-table/directive/fieldGroupTable',['require'],function(require) {
    "use strict";

    fieldGroupTable.$inject = [];

    function fieldGroupTable() {
        return {
            restrict: "AE",
            scope:{
                model	 : "=",
                options  : "="
            },
            templateUrl: "app/components/widget/field-group-table/template/template.html"
        };
    }

    return fieldGroupTable;

});
