define('common/service/ReportService',['require'],function(require) {
	"use strict";

	ReportService.$inject = ["$filter", "DateService", "reportsUrl"];

	function ReportService( $filter, DateService, reportsUrl ) {

		var service = {},
			today = new Date();

		service.firstAvailableRun = new Date();
		service.firstAvailableRun.setMonth( today.getMonth() - 1 );

		service.months = DateService.getAllMonths();
		service.years = DateService.getYearRange( 1997, service.firstAvailableRun );

		service.validateDateRange = function( model ) {

			if( model.month >= today.getMonth() && model.year == today.getFullYear() ) {
				return false;
			}

			return true;

		};

		service.getAgencyExperienceReportUrl = function( model ) {

			var url = reportsUrl;

			url += "UW010705/dsp_UW010705.cfm";
			url += "?source=agency";
			url += "&month=" + ( model.month + 1 ); // 0-indexed months means we have to add 1 for the backend
			url += "&year=" + model.year;
			url += "&detailByLocation=" + ( model.detail ? "1" : "0" );

			return url;

		};

		service.getLossRunReportUrl = function( model ) {

			var evalDate = DateService.getDateForLastDayOfMonth({ year: model.year, month: model.month }),
				url = reportsUrl;

			evalDate = $filter("date")( evalDate, "MM/dd/yyyy" );

			if( model.detail ) {
				url += "UW010708_Agency/dsp_UW010708_Agency.cfm";
			} else {
				url += "UW020701_Agency/dsp_UW020701_Agency.cfm";
			}

			url += "?source=agency";
			url += "&report_id=" + model.reportId;
			url += "&evalDate=" + evalDate;
			url += "&lossRunEndDateMonth=" + model.month;
			url += "&lossRunEndDateYear=" + model.year;
			url += "&policyNum=" + model.policyNumber;

			return url;

		};

		return service;

	}

	return ReportService;

});
