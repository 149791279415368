define('components/rewardsSummary/app',['require','common/filter/formatMoney','components/rewardsSummary/directive/rewardsSummary','components/rewardsSummary/controller/RewardsSummaryController','common/service/RewardsService'],function(require) {
	"use strict";

	var formatMoney = require("common/filter/formatMoney"),
		rewardsSummary = require("components/rewardsSummary/directive/rewardsSummary"),
		RewardsSummaryController = require("components/rewardsSummary/controller/RewardsSummaryController"),
		RewardsService = require("common/service/RewardsService");

	var component = angular.module("mPortal.components.rewardsSummary", []);

	component.filter("formatMoney", formatMoney);
	component.directive("rewardsSummary", rewardsSummary);
	component.controller("RewardsSummaryController", RewardsSummaryController);
	component.factory("RewardsService", RewardsService);

	return component;

});
