define('components/widget/document-upload/controller/DocumentUploadController',['require'],function(require) {
	"use strict";

	DocumentUploadController.$inject = ["$scope", "$modal", "SubmissionStore", "SpecialtyDocumentService" ];

	function DocumentUploadController( $scope, $modal, SubmissionStore, SpecialtyDocumentService ) {

		$scope.init_data = function(){

			$scope.submissionId = $scope.getDatapointSubmissionId();

			$scope.uiState = {
				loading: true,
				error: false
			};

			$scope.documentTypeDescriptor = $scope.settings.documentTypeDescriptor ? $scope.settings.documentTypeDescriptor : "documents";
			$scope.showUploadInstruction = $scope.settings.showUploadInstruction != undefined ? $scope.settings.showUploadInstruction : true;

			$scope.getDocuments( 'update-document-list', { submissionId : $scope.submissionId } );
		};

		$scope.getDatapointSubmissionId = function() {
			/*
			 Currently the expectation is that each document upload datapoint will only ever be associated with one
			 submission (master quote), so simply grab the submissionId from the first element in the applications array.
			 But fallback to the SubmissionStore.submissionId if something went wrong and the applications array doesn't
			 come as expected.
			*/
			return ( $scope.datapointApplications && $scope.datapointApplications.length && $scope.datapointApplications[ 0 ].submissionId ) ?  $scope.datapointApplications[ 0 ].submissionId : SubmissionStore.submissionId;
		};

		$scope.getDocuments = function ( event, args ) {
			SpecialtyDocumentService.listDocuments( args.submissionId, $scope.settings.documentType ).then( function ( result ) {
				$scope.retrievalError = result.error;
				$scope.visibleDocuments = result.docs;
				$scope.uiState.loading = false;
			});
		};

		$scope.getDocument = function ( document ) {
			SpecialtyDocumentService.viewDocument( document )
		};

		$scope.openFileUpload = function() {
			$modal.open({
				templateUrl: "app/components/widget/document-upload/template/uploadDocumentModal.html",
				controller: "DocumentUploadModalController",
				resolve: {
					documentSettings: function(){ return $scope.settings; },
					submissionId: function(){ return $scope.submissionId; }
				}

			});
		};

		$scope.$on('update-document-list', $scope.getDocuments);
		$scope.init_data();
	}

	return DocumentUploadController;
});
