define('components/message/form/app',['require','common/directive/app','components/message/form/directive/messageForm','components/message/form/controller/messageFormController','common/service/MessageService','moment','components/message/form/directive/uploadAttachment','common/filter/deDuplicate','components/message/form/filter/PolicyVisibleFilter'],function (require) {
    "use strict";

    require("common/directive/app");

    var messageForm = require("components/message/form/directive/messageForm"),
        messageFormController = require("components/message/form/controller/messageFormController"),
        MessageService = require('common/service/MessageService'),
        moment = require("moment" ),
        uploadAttachment = require("components/message/form/directive/uploadAttachment"),
		deDuplicate = require("common/filter/deDuplicate"),
	    PolicyVisibleFilter = require("components/message/form/filter/PolicyVisibleFilter");

    var component = angular.module("mPortal.components.message.form", ["mPortal.directive", "ui.bootstrap"]);

    component.directive("messageForm", messageForm);
    component.controller("messageFormController", messageFormController);
    component.factory('MessageService', MessageService);
    component.directive("uploadAttachment", uploadAttachment);
    component.value("moment", moment);
    component.filter('deDuplicate',deDuplicate);
    component.filter("PolicyVisibleFilter",PolicyVisibleFilter);

    return component;

});
