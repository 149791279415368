define('components/renewalsList/directive/renewalsList',['require'],function(require) {
	"use strict";

	function RenewalsList() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/renewalsList/template/template.html",
			controller: "RenewalsListController"
		};
	}

	return RenewalsList;

});
