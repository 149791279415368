define('components/paymentLink/app',['require','components/paymentLink/directive/paymentLink','components/paymentLink/controller/PaymentLinkController','common/service/PaymentService'],function(require) {
	"use strict";

	var paymentLink = require("components/paymentLink/directive/paymentLink"),
		PaymentLinkController = require("components/paymentLink/controller/PaymentLinkController"),
		PaymentService = require("common/service/PaymentService");

	var component = angular.module("mPortal.paymentLink", []);

	component.directive("paymentLink", paymentLink);
	component.controller("PaymentLinkController", PaymentLinkController);
	component.factory("PaymentService", PaymentService);

	return component;

});
