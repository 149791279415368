define('components/activityFeed/app',['require','common/directive/app','components/message/modal/app','angular-sanitize','moment','common/service/util/DateService','components/activityFeed/directive/activityFeed','components/activityFeed/controller/ActivityFeedController','components/activityFeed/directive/activityFeedItem','common/service/activityFeed/ActivityFeedService','common/service/activityFeed/ActivityFeedItem','common/service/activityFeed/ActivityFeedConfig','components/activityFeed/filter/ActivityFeedFilter','common/service/MessageService','common/filter/trustHtml'],function(require) {
	"use strict";

	require("common/directive/app");
	require("components/message/modal/app");
	require("angular-sanitize");

	var moment = require("moment"),
		DateService = require("common/service/util/DateService"),
		activityFeed = require("components/activityFeed/directive/activityFeed"),
		ActivityFeedController = require("components/activityFeed/controller/ActivityFeedController"),
		activityFeedItem = require("components/activityFeed/directive/activityFeedItem"),
		ActivityFeedService = require("common/service/activityFeed/ActivityFeedService"),
		ActivityFeedItem = require("common/service/activityFeed/ActivityFeedItem"),
		ActivityFeedConfig = require("common/service/activityFeed/ActivityFeedConfig"),
		ActivityFeedFilter = require("components/activityFeed/filter/ActivityFeedFilter"),
		MessageService = require("common/service/MessageService"),
		trustHtml = require( "common/filter/trustHtml" );

	var component = angular.module("mPortal.components.activityFeed", [
		"mPortal.directive",
		"mPortal.components.message.modal",
		"ngSanitize"
	]);

	component.directive("activityFeed", activityFeed);
	component.directive("activityFeedItem", activityFeedItem);
	component.controller("ActivityFeedController", ActivityFeedController);
	component.factory("ActivityFeedService", ActivityFeedService);
	component.factory("ActivityFeedItem", ActivityFeedItem);
	component.factory("ActivityFeedConfig", ActivityFeedConfig);
	component.factory("DateService", DateService);
	component.factory("MessageService", MessageService);
	component.filter("activityFeedFilter", ActivityFeedFilter);
	component.filter( "trustHtml", trustHtml );
	component.value("moment", moment);

	return component;

});
