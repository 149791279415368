define('components/widget/optional-endorsements/owner-officer-information/controller/OwnerOfficerInformationController',['require'],function(require) {
    "use strict";

    OwnerOfficerInformationController.$inject = [ "$scope" ];

    function OwnerOfficerInformationController( $scope ) {

        $scope.widgetFields = $scope.options.fieldGroup;

        $scope.quantityControl = $scope.widgetFields.shift();

        $scope.quantityKey = $scope.quantityControl.key;

        $scope.widgetFields.sort(function( a, b ) {
            return a.data.orderNumber - b.data.orderNumber;
        });

        $scope.enumerations = [];
        for( var d = 0; d < $scope.widgetFields.length; d++ ) {
            var currentPoint = $scope.widgetFields[ d ];

            if(  currentPoint.validation && currentPoint.validation.errorExistsAndShouldBeVisible  ) {
                currentPoint.data.wasTouched = true;
            }

            var enumIndex = currentPoint.data.enumeration - 1;
            if( !$scope.enumerations[ enumIndex ] ) {
                $scope.enumerations[ enumIndex ] = [];
            }
            $scope.enumerations[ enumIndex ].push( currentPoint );
        }

        $scope.newLength = $scope.enumerations.length;

        $scope.changeQuantity = function( movement ) {
            $scope.newLength = parseInt($scope.model[ $scope.quantityKey ]) + movement;
            $scope.model[ $scope.quantityKey ] = "" + $scope.newLength + "";
            $("#" + $scope.quantityControlId + " input").blur();
        };

        $scope.changeQuantityOfSections = function ( section, movement ) {

            $scope.newLength = parseInt( $scope.model[$scope.quantityKey] ) + movement;
            $scope.model[$scope.quantityKey] = "" + $scope.newLength + "";
            $scope.$emit( 'delete-enumerated-section', {
                section: section,
                sectionId: section[0].data.sectionId,
                name: "Additional Information"
            } );

        };
    }

    return OwnerOfficerInformationController;

});
