define('components/specialtyApplication/search/app',['require','components/productSearch/container/app','components/specialtyApplication/search/directive/search','components/specialtyApplication/search/controller/searchController','common/service/user/UserProfile'],function(require) {
	"use strict";

	require("components/productSearch/container/app");

	var search_directive	= require("components/specialtyApplication/search/directive/search"),
		search_controller	= require("components/specialtyApplication/search/controller/searchController"),
		
		user_profile 	 	= require( "common/service/user/UserProfile" );

	var component = angular.module("mPortal.components.specialtyApplication.search", ["mPortal.components.productSearch.container"]);

	component.directive("specialtyApplicationSearch", search_directive);
	component.controller("searchController",search_controller);
	component.factory( "UserProfile", user_profile);

	return component;

});
