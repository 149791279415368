define('redirect/app',['require','common/config/config','common/directive/app','shim/overmind','redirect/controller/RedirectController'],function(require) {
	"use strict";

	require("common/config/config");
	require("common/directive/app");

	var overmind = require("shim/overmind");

	var redirect_controller = require("redirect/controller/RedirectController");

	var app = angular.module("mPortal.redirect", [
		"mPortal.config",
		"mPortal.directive",
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {

		$routeProvider.when("/login", {
			controller: "RedirectController",
			target : 'login',
			guard: function( userProfile ) {
				return !userProfile.isLoggedIn;
			}
		});

		$routeProvider.when("/legacy-login", {
			controller: "RedirectController",
			target : 'login',
			guard: function( userProfile ) {
				return !userProfile.isLoggedIn;
			}
		});

		$routeProvider.when("/legacy-register", {
			controller: "RedirectController",
			target : 'login',
			guard: function( userProfile ) {
				return !userProfile.isLoggedIn;
			}
		});

		$routeProvider.when("/register", {
			controller: "RedirectController",
			target: 'register',
			guard: function( userProfile ) {
				return !userProfile.isLoggedIn;
			}
		});
		
	}]);

	app.controller("RedirectController", redirect_controller);
	return app;

});
