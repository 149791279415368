define('components/userAccountSettings/securityResponse/controller/SecurityResponseController',['require'],function(require) {
	"use strict";

	SecurityResponseController.$inject = [ "$scope", "MessageContainer", "UserService" ];

	function SecurityResponseController( $scope, MessageContainer, UserService ) {

		$scope.uiState = {
			visible: $scope.hasSecurityResponse ? false : true,
			submitting: false,
			sectionTitle: $scope.hasSecurityResponse ? "Change Security Question" : "Set Security Question"
		};

		$scope.messages = new MessageContainer({
			success: false,
			errors: [],
			errorHeader: ""
		});

		$scope.save = function() {
			$scope.messages.reset();
			$scope.uiState.submitting = true;
			UserService.saveSecurityResponse( $scope.user ).then( function( result ) {
                $scope.user.securityPassword = "";
				if( result.isSaved ) {
					$scope.uiState.sectionTitle = "Change Security Question";
					$scope.messages.success = true;
				} else {
					$scope.messages.errorHeader = "Please correct the following problems:";
					angular.forEach( result.errors, function( error ) {
						$scope.messages.errors.push( error );
					});
				}
				$scope.uiState.submitting = false;
			});
		};

	}

	return SecurityResponseController;

});
