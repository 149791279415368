define('components/submissionsSearch/app',['require','common/directive/app','components/submissionsSearch/directive/submissionsSearch','components/submissionsSearch/controller/SubmissionsSearchController','common/service/MessageContainer','common/service/util/PaginationService','common/service/util/RenameKeys','common/service/search/SearchResultContainer','common/service/search/SearchService','common/filter/formatMoney'],function(require) {
	"use strict";

	require("common/directive/app");

	var submissionsSearch = require("components/submissionsSearch/directive/submissionsSearch"),
		SubmissionsSearchController = require("components/submissionsSearch/controller/SubmissionsSearchController"),
		MessageContainer = require("common/service/MessageContainer"),
		PaginationService = require("common/service/util/PaginationService"),
		RenameKeys = require("common/service/util/RenameKeys"),
		SearchResultContainer = require("common/service/search/SearchResultContainer"),
		SearchService = require("common/service/search/SearchService"),
		formatMoney = require("common/filter/formatMoney");

	var component = angular.module("mPortal.components.submissionsSearch", [
		"mPortal.directive"
	]);

	component.directive("submissionsSearch", submissionsSearch);
	component.controller("SubmissionsSearchController", SubmissionsSearchController);
	component.factory("MessageContainer", MessageContainer);
	component.factory("PaginationService", PaginationService);
	component.factory("RenameKeys", RenameKeys);
	component.factory("SearchResultContainer", SearchResultContainer);
	component.factory("SearchService", SearchService);
	component.filter("formatMoney", formatMoney);

	return component;

});
