define( 'components/policyDetail/policyClaims/controller/PolicyClaimsController',['require'],function( require ) {
    "use strict";

    PolicyClaimsController.$inject = [ "$scope", "AccountService" ];

    function PolicyClaimsController( $scope, AccountService ) {

        $scope.uiState = {
            loading: true,
            error: false,
            visible: true
        };

        $scope.visibleClaims = [];

        /*
         Because getPolicyClaims() makes a call to a multi-purpose API endpoint, an error response
         can still return a 200 success code with a object payload (as oppposed to an array of
         records).
        */
        AccountService.getPolicyClaims( $scope.policy.policyNumber, $scope.policy.effectiveDate, $scope.policy.expirationDate ).then( function( result ) {
            if( result instanceof Array ) {
                $scope.visibleClaims = result;
            } else {
                $scope.uiState.error = true;
            }
        }).catch(function( err ) {
	        $scope.uiState.error = true;
        }).finally(function() {
	        $scope.uiState.loading = false;
        });

        $scope.toggleVisibility = function() {
            $scope.uiState.visible = !$scope.uiState.visible;
        };

    }
    return PolicyClaimsController;

});

