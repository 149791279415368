define( 'components/specialtyApplication/resumeLater/controller/ResumeLaterController',['require'],function( require ) {
    "use strict";

    ResumeLaterController.$inject = [ "$scope", "$modalInstance", "UserProfile", "SubmissionStore" ];

    function ResumeLaterController( $scope, $modalInstance, UserProfile, SubmissionStore ) {

        $scope.encodeMailText = function( mailText ) {
            var encodedText = mailText.replace(/\s/g,'%20');
            encodedText = encodedText.replace(/\#/g,'%23');
            return encodedText;
        };

        $scope.createLinkOption = function( toLabel, toEmail, ccLabel, ccEmail ) {
            var linkOption = {};
            if( ccEmail ) {
                linkOption.label = toLabel + " (" + toEmail + "), CC " + ccLabel + " (" + ccEmail  + ")";
                linkOption.address = toEmail + "?cc=" + ccEmail + "&";
            } else if( toEmail ) {
                linkOption.label = toLabel + " (" + toEmail + ")";
                linkOption.address = toEmail + "?";
            } else {
                linkOption.label = toLabel;
                linkOption.address = "?";
            }
            return linkOption;
        };

        $scope.close = $modalInstance.close;

        // Set content
        $scope.resumeLink = SubmissionStore.resumeLink;
        $scope.mailSubject = "Link to complete your Markel insurance application";
        // %0D is line break
        $scope.mailBody =
            "We are excited for you to complete your insurance application!"+
            "%0D%0DThank you for your interest in our insurance product. We value your time and want to make completing your insurance application convenient for you!" +
            "%0D%0DWhen you are ready, please use this link to enter the remainder of the application:" +
            "%0D%0D" + $scope.resumeLink +
            "%0D%0DIf you have any questions we're here to help. Please call at 888-500-3344. We appreciate the opportunity to assist you with your insurance needs.";

        // Combine the content
        $scope.mailContent = "subject=" + $scope.encodeMailText( $scope.mailSubject ) + "&body=" + $scope.encodeMailText( $scope.mailBody );

        // Populate links
        $scope.links = [];
        if( UserProfile.type == 'Agent' ) {
            // Some agents do not have an email address on file
            if( UserProfile.email ) {
                $scope.links.push( $scope.createLinkOption( "Yourself", UserProfile.email ) );
                if( SubmissionStore.insured ) {
                    $scope.links.push( $scope.createLinkOption( SubmissionStore.insured.name, SubmissionStore.insured.email ) );
                    $scope.links.push( $scope.createLinkOption( SubmissionStore.insured.name, SubmissionStore.insured.email, "yourself", UserProfile.email ) );
                }
            } else {
                if( SubmissionStore.insured ) {
                    $scope.links.push( $scope.createLinkOption( SubmissionStore.insured.name, SubmissionStore.insured.email ) );
                }
            }
        } else {
            if( SubmissionStore.insured ) {
                $scope.links.push( $scope.createLinkOption( SubmissionStore.insured.name, SubmissionStore.insured.email ) );
            }
        }
    }

    return ResumeLaterController;
});


