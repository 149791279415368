define( 'common/service/specialtyApplication/EligibilityGuidelineService',[],function( ) {
   "use strict";

    EligibilityGuidelineService.$inject = [ "$http", "archApiUrl", "SubmissionStore" ];

    function EligibilityGuidelineService( $http, archApiUrl, SubmissionStore ) {
        var service = {
            instances: {},
            guidelines: {}
        };

        service.populateInitialGuidelines = function( data ) {
            var result = data.guidelines;
            if( result ) {
                for( var g = 0; g < result.length; g++ ) {
                    if( !this.instances[ result[ g ].descriptionId ] ) {
                        this.instances[ result[ g ].descriptionId ] = 1;
                        this.guidelines[ result[ g ].descriptionId ] = result[ g ];
                    } else {
                        this.instances[ result[ g ].descriptionId ]++;
                    }
                }
            }
        };

        service.class_code_description_id = function( datapoint, storeDatapoint, context, programs ) {
            if( programs[ "bop" ] || !Object.keys( programs ).length ) {
                if( context === "delete" ) {
                    if (datapoint && datapoint.defaultvalue && datapoint.defaultValue.value ) {
                        this.deleteDescription( datapoint.defaultValue.value )
                    }
                } else if( context === "update" || context === "save" ) {
                    this.refreshGuidelines( datapoint, storeDatapoint );
                }
            }
        };

        service.updateGuidelines = function( datapoint, storeDatapoint, context, programs ) {
            if( context == "delete" ) {
                if (datapoint && datapoint.defaultvalue && datapoint.defaultValue.value ) {
                    this.deleteDescription( datapoint.defaultValue.value )
                }
            } else {
                this.refreshGuidelines( datapoint, storeDatapoint );
            }
        };

        service.refreshGuidelines = function( datapoint, storeDatapoint ) {
            var previousDescriptionId = ( storeDatapoint && storeDatapoint.value ) ? storeDatapoint.value.value : null;
            var currentDescriptionId = ( datapoint.defaultValue && datapoint.defaultValue.value ) ? datapoint.defaultValue.value : null;
            if( storeDatapoint && previousDescriptionId != currentDescriptionId ) {
                // Check if class code description ID value already exists
                if( !this.instances[ currentDescriptionId ] ) {
                    // Perform the REST call
                    var serviceObj = this;
                    $http.get(
                        archApiUrl + "application/submission/eligibility-guidelines",
                        { params: { descriptionIds: currentDescriptionId, effectiveDate: SubmissionStore.effectiveDate } }
                    ).then( function( outcome ) {
                        if( !outcome.data[ "ERRORS"] ) {
                            var result = outcome.data.guidelines;
                            for( var g = 0; g < result.length; g++ ) {
                                serviceObj.instances[ result[ g ].descriptionId ] = 1;
                                serviceObj.guidelines[ result[ g ].descriptionId ] = result[ g ];
                            }
                        }
                    });
                } else {
                    // The guidelines are already present; simply increment the instance count.
                    this.instances[ currentDescriptionId ]++;
                }

                if( previousDescriptionId ) {
                    this.deleteDescription( previousDescriptionId );
                }
            }
        };

        service.deleteDescription = function( descriptionId ) {
            if( this.instances[ descriptionId ] ) {
                if( this.instances[ descriptionId ] == 1 ) {
                    delete this.guidelines[ descriptionId ];
                    delete this.instances[ descriptionId ];
                } else {
                    this.instances[ descriptionId ]--;
                }
            }
        };

        return service;
    }

    return EligibilityGuidelineService;
});
