define( 'common/directive/iframeResizer',['require','iframe-resizer'],function ( require ) {
	"use strict";

	require( 'iframe-resizer' )

	function IframeResizer() {
		return {
			restrict: "A",
			scope: {
				heightCalculationMethod: "="
			},
			link: function ( scope, element ) {
				var options = {
					checkOrigin: false,
					minHeight: 300
				};

				if( scope.heightCalculationMethod ) {
					options.heightCalculationMethod = scope.heightCalculationMethod;
				}

				$( element ).iFrameResize( options )
			}
		};
	}

	return IframeResizer;

} );
