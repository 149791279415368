define('components/message/form/controller/messageFormController',[],function() {

	MessageFormController.$inject = ["$scope", "$modal" , "$window" ,"MessageService","UserProfile","archApiUrl", "$routeParams", "moment"];

	function MessageFormController( $scope, $modal , $window , MessageService, UserProfile,archApiUrl, $routeParams, moment ) {

		$scope.user =  UserProfile;
		$scope.btn 	= null;

		$scope.reasons = { policies : [] , submissions : [], renewals : []};

		$scope.initForm = function(){
			$scope.application_upload = false;
			$scope.form = {
				accountId		: $scope.account.accountId,
				message			: "",
				subject			: "",
				reasonId		: "",
				fromAddress		: $scope.user.email,
				policyNumber	: "",
				submissionId	: "",
				typeId 			: 1,
                upload: [],
				fileNames:[]
			};

			if($routeParams.reason === "effectiveDate"){
				$scope.form.subject = "Request to update effective date";
				$scope.form.message = "Please update my effective date to: ";
			}

			if($scope.accounts) {
				for(var i = 0; i < $scope.accounts.length; i++ ) {
					var account = $scope.accounts[i];

					if(account.accountViewVersion == 2) {
						if(account.policies) {
							for(var j = 0; j < account.policies.length; j++ ) {
								$scope.reasons.policies.push(account.policies[j]);
							}
						}
						if(account.submissions) {
							for(var k = 0; k < account.submissions.length; k++ ) {
								if(account.submissions[k].isRenewal && account.submissions[k].status === "Quoted"){
									$scope.reasons.renewals.push(account.submissions[k]);
								}
								else{
									$scope.reasons.submissions.push(account.submissions[k]);
								}
							}
						}

					}

				}
				if( $routeParams.messageReasonId && !$routeParams.isApplicationUpload){

					$scope.reset_message_form( null, {
						reasonId: $routeParams.messageReasonId,
						typeId: $routeParams.reason === "endorsement" || $routeParams.reason === "requestChange" ? 2 : 1,
						application : $routeParams.isApplicationUpload }  )
				}
                if( $routeParams.isApplicationUpload )
                {
                    $scope.reset_message_form( null, { reasonId: $routeParams.messageReasonId, typeId: 3, application : $routeParams.isApplicationUpload }  )
                }
			}
		};
        $scope.openFileUpload = function() {
            var formAction = archApiUrl + "account/" + $scope.account.accountId+ "/upload";
            var fileUploadModal = $modal.open({
                template: "<div upload-attachment upload-action='\"" + formAction + "\"'></div>",
                controller: "GenericModalController"
            });

            fileUploadModal.result.then(function(result) {
                
                $scope.form.upload.push( result.form.file );
                $scope.form.fileNames.push( result.data.fileName );
            });
        };
		$scope.reset_message_form = function(event, args){
			$scope.form.reasonId = args.reasonId;
			$scope.form.typeId = args.typeId;
			if(args.application)
			{
				$scope.application_upload = true;
			}

			$scope.form.subject = $scope.get_subject();
		}

		$scope.errors = {
			visible : false,
			reason	: false,
			message : false,
			subject : false
		};

		$scope.status = {
			sending: false
		};

		$scope.get_subject = function()
		{
			var current_subject = $scope.form.subject;
			var subject = '';

			if($scope.application_upload)
			{
				subject = 'Markel Online - Application Attached - ' + $scope.form.reasonId;
			}
			else
			{
				if($scope.form.reasonId && $scope.form.typeId)
				{
					if( $scope.form.typeId == 2 ) {
						subject = 'Markel Online Policy Change Request - ' + $scope.account.accountName + ' - '+ $scope.form.reasonId + ' | ' ;
					} else {
						subject = 'Markel Online - ' + $scope.account.accountName + ' - '+ $scope.form.reasonId + ' | ' ;
					}
				}

			}
			

			if(current_subject.length > subject.length)
			{
				if(current_subject.substr(0,subject.length) == subject)
				{
					subject+= current_subject.substr(subject.length,current_subject.length);
				}
			}

			return subject;
		}

		$scope.set_subject = function(){
			$scope.form.subject = $scope.get_subject();
		}

        $scope.clearSearch = function(){
            $scope.form.fileNames = [];
            $scope.form.upload = [];
        }

        $scope.removeItem = function (x) {
            $scope.form.fileNames.splice(x,1);
            $scope.form.upload.splice(x, 1);
        }
		$scope.validate = function(){
			$scope.errors = {
				visible : false,
				reason	: !$scope.form.reasonId ,
				message : !$scope.form.message ,
				subject : !$scope.form.subject
			};

			angular.forEach($scope.errors,function(error,key){
				if(key != 'visible' &&  error && !$scope.errors.visible)$scope.errors.visible = true;
			});
		}

		$scope.set_reason_id = function(e,args){
			if(args.reasonId != undefined) {
				$scope.form.reasonId = args.reasonId;
			}
			if(args.typeId != undefined) {
				$scope.form.typeId = args.typeId;
			}

			if(args.application)
			{
				$scope.application_upload = true;
			}	

			$scope.form.subject = $scope.get_subject();
		}

		$scope.setMessageViewInsured = function( e, args ) {
			$scope.initForm();
			
			if(args.obj)
			{
				if(args.obj['quote'] != undefined) 
				{
					$scope.form.reasonId = args.obj['quote']['policyNumber'];

					if(args.obj['quote']['account'] != undefined)  
					{
						$scope.form.account = args.obj['quote']['account'];
					}
				}

				if(args.obj['policy'] != undefined) 
				{
					$scope.form.reasonId = args.obj['policy']['policyNumber'];

					if(args.obj['policy']['account'] != undefined)  
					{
						$scope.form.account = args.obj['policy']['account'];
					}
				}

				if(args.obj['typeId'] != undefined)  {
					$scope.form.typeId = args.obj['typeId'];
				}
			}
		}

		$scope.send = function($event) {

			$scope.btn = angular.element($event.currentTarget);
			
			$scope.validate();

			if($scope.errors.visible)return;
			
			if(!$scope.status.sending)
			{
				$scope.account = $scope.form.account != undefined ? $scope.form.account : $scope.account;

				$scope.status.sending = true;
				$scope.btn.attr('disabled',true);
				$scope.form.submissionId = $scope.form.reasonId;
				$scope.form.insuredName = $scope.account.accountName;

				//set the PolicyNumber and masterQuote Id for policy Messages
				angular.forEach($scope.reasons.policies,function(v,k){
					var policy = v;

					if(policy.policyNumber == $scope.form.reasonId)
					{
						$scope.form.policyNumber = policy.policyNumber;
						$scope.form.submissionId = policy.submissionId;
						$scope.form.agencyId = policy.agencyId;
						$scope.form.programCode = policy.programAbbreviation
						return false;
					}
				});

				//set policyNumber, agencyId and programCode for submission Messages
				angular.forEach($scope.reasons.submissions,function(v,k){
					var submission = v;

					if(submission.submissionId == $scope.form.reasonId)
					{
						$scope.form.submissionId = submission.submissionId;
						$scope.form.agencyId = submission.agencyId;
						$scope.form.programCode = submission.programAbbreviation
						return false;
					}
				});

				angular.forEach($scope.reasons.renewals,function(v,k){
					var submission = v;

					if(submission.submissionId == $scope.form.reasonId)
					{
						$scope.form.submissionId = submission.submissionId;
						$scope.form.agencyId = submission.agencyId;
						$scope.form.programCode = submission.programAbbreviation
						return false;
					}
				});

				//If Policy doesn't have submissionId, then setting systemName as "icon"
				var systemName = ($scope.account.accountId == $scope.account.piNumber)? 'icon' : ($scope.form.submissionId == null)? 'icon' : 'mpolicy';

				//ok I don't know why typeId would be null
				if(!$scope.form.typeId)
				{
					$scope.form.typeId = 1;
				}

				//prepend the subject to the beginning of the message in case the user changed it
				
				var accountID = (systemName == 'icon') ? $scope.account.piNumber : $scope.account.accountId;
				var subject   = $scope.get_subject() + 'Account :' + accountID;
				var message = subject + '\n \n' + $scope.form.message;
				
				// typeId = 1 : For Regular Message, typeId = 2 : For Policy Change Request, typeId = 3 : Application upload
				MessageService.sendMessage(	{
												system			: systemName,
												accountId		: (systemName == 'icon') ? $scope.account.piNumber : $scope.account.accountId,
												message			: message,
												subject			: $scope.form.subject,
												submissionId	: $scope.form.submissionId,
												fromAddress		: $scope.user.email,
												threadId		: '',
												policyNumber	: $scope.form.policyNumber,
												typeId			: $scope.form.typeId,
												insuredName     : $scope.form.insuredName,
												agencyId        : $scope.form.agencyId,
												programCode     : $scope.form.programCode,
												accountInsuredId : ($scope.account.accountId != $scope.account.piNumber) ? $scope.account.accountId : null,
												uploadAttachments: $scope.form.upload
											}
				).then(function( response ) {
					$scope.status.sending = false;

					//show message sent
					$modal.open({
						templateUrl: "app/components/message/modal/template/sent.html",
						controller: "GenericModalController"
					});
					if( $scope.user.type == 'Agent' ) {
						//return to inbox;
						$scope.$emit('set-message-view',{view:'list',refresh:true});
                        $scope.initForm()
						$scope.btn.attr('disabled',false);
					} else { //$scope.user.type = 'Insured'
						$scope.$emit('close-message-tab');

						$scope.initForm();
						$scope.btn.attr('disabled',false);
					}
				});
			}
				
		};

		$scope.$on('set-reason-id',$scope.set_reason_id);
		$scope.$on('reset-message-form',$scope.reset_message_form);
		$scope.$on('set-message-view-insured',$scope.setMessageViewInsured);

		$scope.initForm();
	}

	return MessageFormController;

});
