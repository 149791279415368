define('common/config/HttpConfig',['require','angular','components/errorModal/app','common/service/interceptor/ErrorInterceptor','common/service/interceptor/CacheBusterInterceptor','common/service/interceptor/CancellableRequestInterceptor','common/service/interceptor/RedirectInterceptor','common/service/interceptor/ProfileTokenInterceptor','common/service/user/UserService','common/service/user/UserProfile'],function(require) {
	"use strict";

	require("angular");
	require("components/errorModal/app");

	var ErrorInterceptor = require("common/service/interceptor/ErrorInterceptor"),
		CacheBusterInterceptor = require("common/service/interceptor/CacheBusterInterceptor"),
		CancellableRequestInterceptor = require("common/service/interceptor/CancellableRequestInterceptor"),
		RedirectInterceptor = require("common/service/interceptor/RedirectInterceptor"),
		ProfileTokenInterceptor = require("common/service/interceptor/ProfileTokenInterceptor"),
		UserService = require("common/service/user/UserService"),
		UserProfile = require("common/service/user/UserProfile");

	var app = angular.module("mPortal.config.http", [
		"mPortal.components.errorModal"
	]);

	app.factory("UserService", UserService);
	app.factory("UserProfile", UserProfile);

	app.config(["$httpProvider", function( $httpProvider ) {

		$httpProvider.interceptors.push( ErrorInterceptor );
		$httpProvider.interceptors.push( CacheBusterInterceptor );
		$httpProvider.interceptors.push( CancellableRequestInterceptor );
		$httpProvider.interceptors.push( RedirectInterceptor );
		$httpProvider.interceptors.push( ProfileTokenInterceptor );
		
		// this header is required because it's what maverick uses to determine if we're making an AJAX request
		$httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
		$httpProvider.defaults.headers.common["X-Requested-By"] = "mPortal";
		$httpProvider.defaults.withCredentials = true;

	}]);

	return app;

});
