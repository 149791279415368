define('components/accountSummary/paymentSystem/controller/PaymentSystemController',['require'],function(require) {
	"use strict";

	PaymentSystemController.$inject = ["$scope", "$q", "$location", "AccountService", "UserProfile"];

	function PaymentSystemController( $scope, $q , $location, AccountService, UserProfile ) {

		$scope.user = UserProfile;
		$scope.bundle_payment_visible = false;

		$scope.get_payement_system = function(){
			 var p = [   AccountService.getPaymentSystem( $scope.account.accountId ) ];

            $q.all(p).then(function(results){ 
            	$scope.payment_system = results[0];

	            $scope.$emit('bundle_payment_visible',$scope.payment_system.isBundlePaymentAvailable);

            	if($scope.user.type == 'Insured')
            	{
	            	var acct_arr 		 = [];
	            	var payment_acct_arr = [];

	            	for(var i=0; i < $scope.accounts.length; i++)
	            	{
	            		var acct = $scope.accounts[i];

	            		acct_arr.push(acct.accountId);
	            	}

	            	for(var i=0; i < $scope.payment_system.accounts.length; i++)
	            	{
	            		var acct = $scope.payment_system.accounts[i];

	            		payment_acct_arr.push(acct.accountId);
	            	}

	               if(acct_arr.length > 1)
	               {
	               		var bundle_visible = true;

	               		for(var i=0; i < acct_arr.length; i++)
	            		{
	            			var acct_id = acct_arr[i];

	            			//we can still see the bundle but we don't have all the accts
	            			if(bundle_visible && $.inArray(acct_id,payment_acct_arr) == -1)
	            			{
	            				bundle_visible = false;
	            				break;
	            			}
	            		}

	            		$scope.bundle_payment_visible = bundle_visible && $scope.payment_system.isBundlePaymentAvailable;
	               }
	               else
	               {
	               		//account ID lengths don't match missing a required id
	               		$scope.bundle_payment_visible = $scope.payment_system.isBundlePaymentAvailable;
	               }
            	}
            	else
            	{
            		$scope.bundle_payment_visible = $scope.payment_system.isBundlePaymentAvailable;
            	}
	           // $scope.$emit('bundle_payment_visible',$scope.bundle_payment_visible);
            });
		}

		$scope.open_payment = function(e){
			 $location.path('/bundle-payment/' + $scope.account.accountId);
		}

		$scope.get_payement_system();

		//console.log($scope);
	}

	return PaymentSystemController;

});
