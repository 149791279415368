define('common/config/config',['require','angular','common/config/constants','common/config/globalRequires','common/config/HttpConfig','common/config/templates'],function(require) {
	"use strict";

	require("angular");
	require("common/config/constants");
	require("common/config/globalRequires");
	require("common/config/HttpConfig");
	require("common/config/templates");

	var app = angular.module("mPortal.config", [
		"mPortal.config.constants",
		"mPortal.config.globalRequires",
		"mPortal.config.http",
		"mPortal.config.templates"
	]);

	// angular's error handling doesn't play nice with sourcemaps, so we have to decorate the exceptionHandler in order
	// to re-throw an error that will work with sourcemaps. this results in two errors being thrown for each actual error,
	// but it only applies in environments where we're actually using sourcemaps - alpha, beta, and a built version of dev.
	app.config(["$provide", function($provide) {
		$provide.decorator("$exceptionHandler", ["$delegate", "environment", function( $delegate, environment ) {
			return function( exception, cause ) {

				// pass the exception on to exceptionHandler to do whatever it needs
				$delegate( exception, cause );

				// if we're in an environment that uses sourcemaps, rethrow the exception
				if( environment !== "localdev" && environment !== "prod" ) {
					setTimeout(function() {
						throw exception;
					});
				}

			};
		}]);
	}]);

	return app;

});
