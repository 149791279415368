define('components/startQuote/directive/productLineCheck',['require'],function(require) {
	"use strict";

	ProductLineCheck.$inject = [];

	function ProductLineCheck() {
		return {
			restrict: "AE",
			replace: true,
			scope: {
				label: "@",
				line: "="
			},
			templateUrl: "app/components/startQuote/template/productLineCheck.html"
		};
	}

	return ProductLineCheck;

});
