define('common/service/MarketedProgramService',['require'],function(require) {
	"use strict";

	MarketedProgramService.$inject = ["$http", "archApiUrl"];

	function MarketedProgramService( $http, archApiUrl ) {

		var service = {};

		service.getMarketedPrograms = function() {
			return $http.get( archApiUrl + "program/marketed-program" ).then(function( result ) {
				return result.data;
			});
		};

		service.getMarketedProgram = function( id ) {
			return $http.get( archApiUrl + "program/marketed-program/" + id ).then(function( result ) {
				return result.data;
			});
		};

		/*
		Initially, there was a single WC/BOP marketed program with a reference string of "wcBop",
		making it easy to jointly allow or deny access to WC/BOP product line functionality in the
		portal.

		The mPortal-1209 split of that single program into two, with the original record becoming
		the WC program and a new BOP program record created.  It was still desirable to have the WC
		program record retain the "wcBop" reference string so as not to break the permissions logic
		already in place in the portal and in Maverick (Policy Management).  But when the marketed
		program records are shared with the portal, the "wcBop" reference string is converted to
		"wc" (so it can be used properly in search parameters), and a disallowed program of "wcBop"
		needs to block user access to both WC and BOP.

		So this method currently needs logic to handle the "wcBop" / "wc" and "bop" mismatch, ideally
		logic that will not break if the "wcBop" reference string value is refactored/retired.
		*/
		service.userHasAccessToProgram = function( userProfile, program ) {
			if( userProfile.disallowedPrograms.indexOf( "wcBop" ) !== -1 && [ "wc", "bop" ].indexOf( program.referenceString ) !== -1 ) {
				return false;
			}

			if( userProfile.disallowedPrograms.indexOf( program.referenceString ) !== -1 ) {
				return false;
			}

			return true;
		};

		return service;

	}

	return MarketedProgramService;

});
