define('common/service/activityFeed/ActivityFeedService',['require'],function(require) {
	"use strict";

	ActivityFeedService.$inject = ["$http","$sce", "archApiUrl", "ActivityFeedItem" , "UserProfile", "$q"];

	function ActivityFeedService( $http, $sce , archApiUrl, ActivityFeedItem, UserProfile, $q) {

		var service = {};

		service.getAgentActivity = function () {
			return $http.get( archApiUrl + "user/activity" ).then( function ( results ) {
				return results.data;
			} );
		};

		service.getLuxonActivity = function( ) {
			return []
		};

		service.getRecentAccountActivity = function() {
			var accountActivity = [];
			var promiseArray = [
				service.getAgentActivity(),
				service.getLuxonActivity()
			];

			accountActivity = $q.all( promiseArray ).then( function ( results ) {
				var result = [];
				result = results[0];
				if ( results[1].length ) {
					angular.forEach( results[1], function ( account ) {
						result.push( account );
					} )
				}
				var user = UserProfile;
				var items = {newer: [], older: []};

				angular.forEach( result, function ( item ) {

					var activityFeedItem = new ActivityFeedItem( item );

					//reverse can reply flag for emulation
					if ( user.agencyEmulation && item.type === 'New Message' ) {
						activityFeedItem['canReply'] = !item['canReply'];
					}

					if ( activityFeedItem.isOlderThanTenDays() ) {
						items.older.push( activityFeedItem );
					} else {
						items.newer.push( activityFeedItem );
					}

				} );

				return items;
			} )
			return accountActivity;
		};

		return service;

	}

	return ActivityFeedService;

});
