define('components/message/container/controller/MessageContainerController',[],function() {

	MessageContainerController.$inject = ["$scope" , "$routeParams", "$q" , "$modal" , "$window" ,"MessageService","UserProfile"];

	function MessageContainerController( $scope, $routeParams , $q , $modal , $window , MessageService, UserProfile) {

		$scope.user 		=  UserProfile;
        $scope.view			= "list";
        $scope.threads		= null;
        $scope.open_thread	= null;
        $scope.query		= "";

		$scope.setMessageView = function( e, args ){

            $scope.view = args.view;

            if(args.obj != undefined)
            {
            	var reasonId = '';
	            var typeId = '';
	            var application = false;

            	if(args.obj['policy'] != undefined) 		reasonId = args.obj['policy']['policyNumber'];
            	if(args.obj['submission'] != undefined) 	reasonId = args.obj['submission']['submissionId'];
	            if(args.obj['quote'] != undefined) 	        reasonId = args.obj['quote']['policyNumber'];
	            if(args.obj['typeId'] != undefined) 	    typeId = args.obj['typeId'];
	            if(args.obj['application'])					application = args.obj['application'];

            	$scope.$broadcast('set-reason-id',{reasonId : reasonId, typeId : typeId , application : application });
            }

			if( args.view == 'send' ){
				$scope.$broadcast('reset-message-form',{reasonId : reasonId, typeId : typeId , application : application});
			}

			if(args.view == 'list' && args.refresh)
            {
            	$scope.open_thread	= null;
            	$scope.get_threads();
            }
		}

		$scope.get_threads = function(e,args){

			var system = ($scope.account.accountId == $scope.account.piNumber)? 'icon' : 'mpolicy';

			var p = [   MessageService.getThreads({ system : system ,accountId: $scope.account.accountId}) ];

			$q.all(p).then(function(results){
				var threads = results[0];

				if(system == 'icon')
				{
					var policies = $scope.account.policies;

					for(var i = 0; i < threads.length; i++)
					{
						var thread = threads[i];

						for(var	j = 0;j < policies.length; j++)
						{
							var policy = policies[j];

							if( policy.policyNumber== thread.policyNumber )
							{
								thread.lineOfBusiness = policy.lineOfBusiness;
								break;
							}
						}
						
						threads[i] = thread;	
					}	
				}

				$scope.threads = threads;
				if($scope.open_thread != null)$scope.open_message_thread('open-message-thread',$scope.open_thread);
			});
		}

		$scope.open_message_thread = function(e,args){
			
			//tell whoever cares to open the message tab
			$scope.$emit('open-message-tab');

			if(args.threadId != 'inbox')
			{
				 angular.forEach($scope.threads,function(v,k){
					var thread = v;
					
					if(thread.threadId == args.threadId )
					{
						var p = [  MessageService.getThread({accountId:$scope.account.accountId , threadId:thread.threadId , system : ($scope.account.accountId == $scope.account.piNumber)? 'icon' : 'mpolicy'}) ];
						
						$q.all(p).then(function(results){
							thread.messages = results[0];
							thread.open		= true;

							//set message to read and unreadCount to 0
							thread.totalUnread = 0;

							//if we have a reply action show it
							if($scope.open_thread.reply) thread.reply = true;
						});
					}
				});
			}
			
		}

		if( $routeParams.messageReasonId && !$routeParams.isApplicationUpload ){
			$scope.setMessageView( null, { view: 'send', obj: { submission: { submissionId: $routeParams.messageReasonId }, typeId: 1 } } )
		}

		if( $routeParams.isApplicationUpload )
		{
            $scope.setMessageView( null, { view: 'send', obj: { submission: { submissionId: $routeParams.messageReasonId }, typeId: 3, application: true} } )
		}

		$q.all([$scope.get_threads()] ).then( function() {
			if( $routeParams.threadId )
			{
				$scope.open_thread = { threadId:Number($routeParams.threadId) ,accountId : $routeParams.accountId , reply : ($routeParams.threadAction) };
			}
		});

		$scope.$on('open-message-thread',$scope.open_message_thread);
		$scope.$on('set-message-view',$scope.setMessageView);
		$scope.$on('get-message-threads',$scope.get_threads);

	}

	return MessageContainerController;

});
