define( 'components/specialtyApplication/questions/config/widgets/SignatureWidget',['require'],function( require ) {
    "use strict";
    var widget = {};

    widget.getStructure = function() {
        return {
	        "_datapointPlacement": "strictOrder",
            "Agreement": { wrapper: [ "bopAgreementGuidelinesWrapper" ], orderNumber: 1, data: {
                actions: [
                    {
                        methodName: "injectNameIntoAgreement",
                        contexts: [ "update" ],
                        programs: []
                    }
                ]
            }},
            "Electronic Signature": { wrapper: [ "defaultBootstrapLabel", "defaultBootstrapHasError" ], orderNumber: 2 },
            "ESignature": { wrapper:  [ "ESignatureLabel" ], wrapperOverride: true },
            "ESignature Name": { wrapper: [ "nameDatapoint", "nameError" ] },
            "ESignature MI": { wrapper: [ "initialDatapoint", "initialError" ] },
            "ESignature Last": { wrapper: [ "nameDatapoint", "nameError" ] },
            "ESignature Suffix": { wrapper: [ "suffixDatapoint", "suffixError" ] },
			"Signed App Label": { wrapper: [ "bopAgreementGuidelinesWrapper" ] },
	        "FraudPortalLink": {
		        type: "fraudLinkLabel",
		        wrapper: null,
		        wrapperOverride: true,
		        data: {
			        actions: [
				        {
					        methodName: "setMarketedProgramFraudLinkUrl",
					        contexts: [ "update" ],
					        programs: []
				        }
			        ]
		        }
	        }

        };
    };

    widget.configureFormly = function( formlyConfig, formlyApiCheck ) {

        formlyConfig.setWrapper({
            name: "ESignatureLabel",
            overwriteOk: true,
            template:
            '<div class="inlineCheckbox">' +
            '<div class="span5" style="width: 65% !important; min-height: 54px">'+
            '{{ options.templateOptions.label }}' +
            '<a target="_blank" style="float:none;margin-left:1%" ng-href="https://www.markel.com/e-disclosure">Markel Electronic Delivery and Signature Consent Disclosure<span style="color:red;">*</span></a>' +
            '</div>'+
            '<div class="span6 field-wrapper" style="width: 20% !important; margin-left: 80px; min-height: 54px">' +
            '<formly-transclude></formly-transclude>' +
            '</div>'+
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "nameDatapoint",
            template: '<style>.nameDatapoint input { width: 322px !important; } .field-help {' +
            'margin-top: 4px !important;' +
            '    } </style><div  class="nameDatapoint">' +
            '<label for="{{ id }}" class="control-label" style="margin-top: 18px">{{ to.label }}</label>' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "nameError",
            overwriteOk: true,
            template:
            '<div class="span2_5 form-group" style="width: 331px; padding-left:0px;padding-top:0px;padding-right:0px" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "initialDatapoint",
            template: '<style>.initialDatapoint input { width: 29px !important;} .field-help {' +
            'margin-top: 4px !important;' +
            '    } </style><div class="initialDatapoint">' +
            '<label for="{{ id }}" class="control-label">{{ to.label }}</label>' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "initialError",
            overwriteOk: true,
            template:
            '<div class="span2_5 form-group" style="width:39px; padding-left:10px;padding-top:0px;padding-right:10px" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "suffixDatapoint",
            template: '<style>.suffixDatapoint input { width: 60px !important;} .field-help {' +
            'margin-top: 4px !important;' +
            '    } </style><div class="suffixDatapoint">' +
            '<label for="{{ id }}" class="control-label" style="margin-top: 18px">{{ to.label }}</label>' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "suffixError",
            overwriteOk: true,
            template:
            '<div class="span2_5 form-group" style="width:70px ; padding-left:10px;padding-top:0px;padding-right:10px" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<p style="padding-top:1ex;">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>'

        });

    };

    return widget;
});
