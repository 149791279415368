define('components/productSearch/filter/controller/productSearchFilterController',['require'],function(require) {
	"use strict";

	productSearchFilterController.$inject = ["$scope", "$filter", "$location", "$sce", "UserProfile", "FilterService"];

	function productSearchFilterController( $scope, $filter, $location , $sce, UserProfile, FilterService ) {

        $scope.validate        = FilterService.validate;
	    $scope.calendar_click  = FilterService.calendar_click;
        $scope.open_dropdown   = FilterService.open_dropdown;
        $scope.set_value       = FilterService.set_value;
        $scope.option_click    = FilterService.option_click;
        $scope.slider_update   = FilterService.slider_update;
    }

	return productSearchFilterController;
});
