define('common/service/util/OvermindHelperService',['require'],function(require) {
	"use strict";

	function OvermindHelperService() {

		var service = {};

		service.getAppByRoute = function( path, apps ) {
			var parts = path.split("/"),
				applicationPath = parts[ 0 ] ? parts[ 0 ] : parts[ 1 ];

			return apps[ "/" + applicationPath ];
		};

		return service;

	}

	return OvermindHelperService;

});
