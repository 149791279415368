define('components/insuredClaims/directive/insuredClaims',['require'],function(require) {
	"use strict";

	function InsuredClaims() {
		return {
			restrict: "AE",
			scope: {
				claims: "=",
				openClaimDetail: "="
			},
			templateUrl: "app/components/insuredClaims/template/template.html",
			controller: "InsuredClaimsController"
		};
	}

	return InsuredClaims;

});
