define('components/lossRuns/controller/LossRunsController',['require'],function(require) {
	"use strict";

	LossRunsController.$inject = ["$scope", "LossRunsService"];

	function LossRunsController( $scope, LossRunsService ) {

		$scope.getLossRunLinkText = function() {
			return $scope.user.type.toLowerCase() === "insured"?"View Loss Report":"View Loss Runs";
		}

		$scope.openLossRunsWindow = function() {
			LossRunsService.openLossRunsWindow( $scope.accountId );
		};

	}

	return LossRunsController;

});
