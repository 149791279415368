define('components/widget/optional-endorsements/additional-information-location/controller/AdditionalInformationLocationController',['require'],function(require) {
	"use strict";

	AdditionalInformationLocationController.$inject = [ "$scope" ];

	function AdditionalInformationLocationController( $scope ) {

		$scope.widgetFields = $scope.options.fieldGroup;

		$scope.widgetFields.sort(function( a, b ) {
			return a.data.orderNumber - b.data.orderNumber;
		});
		// Break up the enumerations into separate arrays within the enumerations array;
		$scope.enumerations = [];
		for( var d = 0; d < $scope.widgetFields.length; d++ ) {
			var currentPoint = $scope.widgetFields[ d ];

			/* Below code resets the touched property on invalid datapoints when the component reloads so the
			   validation errors made visible. Refer to ItemDescriptionLimitController.js for detailed information */

			if(  currentPoint.validation && currentPoint.validation.errorExistsAndShouldBeVisible  ) {
				currentPoint.data.wasTouched = true;
			}

			var enumIndex = currentPoint.data.enumeration - 1;
			if( !$scope.enumerations[ enumIndex ] ) {
				$scope.enumerations[ enumIndex ] = [];
			}
			$scope.enumerations[ enumIndex ].push( currentPoint );
		}

	}

	return AdditionalInformationLocationController;

});
