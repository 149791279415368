define('components/twoYearView/twoYearView/app',['require','components/twoYearView/accountTable/app','angular-utils-pagination','components/twoYearView/twoYearView/directive/twoYearView','components/twoYearView/twoYearView/controller/TwoYearViewController','components/twoYearView/twoYearView/filter/TwoYearViewFilter','moment','common/service/search/SearchService','common/service/user/UserProfile'],function(require) {
	"use strict";

	require("components/twoYearView/accountTable/app");
	require("angular-utils-pagination");

	var twoYearView = require("components/twoYearView/twoYearView/directive/twoYearView"),
		TwoYearViewController = require("components/twoYearView/twoYearView/controller/TwoYearViewController"),
		TwoYearViewFilter = require("components/twoYearView/twoYearView/filter/TwoYearViewFilter"),
		moment = require("moment"),
		SearchService = require("common/service/search/SearchService"),
		UserProfile = require("common/service/user/UserProfile");

	var component = angular.module("mPortal.components.twoYearView.twoYearView", [
		"mPortal.components.twoYearView.accountTable",
		"angularUtils.directives.dirPagination"
	]);

	component.directive("twoYearView", twoYearView);
	component.controller("TwoYearViewController", TwoYearViewController);
	component.value("moment", moment);
	component.factory("SearchService", SearchService);
	component.factory("UserProfile", UserProfile);
	component.filter("twoYearViewFilter", TwoYearViewFilter);

	return component;

});
