define('common/service/specialtyApplication/ApplicationSectionBuilder',['require'],function(require) {
	"use strict";

	ApplicationSectionBuilder.$inject = ["$injector"];

	/**
	* This service exists purely as a means to get around a circular dependency issue (ApplicationSection -> ApplicationSectionService -> ApplicationSection).
	*/
	function ApplicationSectionBuilder( $injector ) {

		var service = {};

		service.buildSection = function( section ) {
			var ApplicationSection = $injector.get("ApplicationSection");
			return new ApplicationSection( section );
		};

		return service;

	}

	return ApplicationSectionBuilder;

});
