define('common/filter/stripHtml',['require'],function(require) {
	"use strict";

	// from rexUtil.js
	function StripHtml() {
		return function( str ) {
			return str && str.replace(/<(?:.|\n)*?>/gm, "");
		};
	}

	return StripHtml;

});
