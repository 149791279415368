define('components/accountsSearch/app',['require','common/directive/app','components/accountsSearch/directive/accountsSearch','components/accountsSearch/controller/AccountsSearchController','common/service/MessageContainer','common/service/util/PaginationService','common/service/util/RenameKeys','common/service/search/SearchService','common/service/search/SearchResultContainer','common/filter/truncate'],function(require) {
	"use strict";
	
	require("common/directive/app");

	var accountsSearch = require("components/accountsSearch/directive/accountsSearch"),
		AccountsSearchController = require("components/accountsSearch/controller/AccountsSearchController"),
		MessageContainer = require("common/service/MessageContainer"),
		PaginationService = require("common/service/util/PaginationService"),
		RenameKeys = require("common/service/util/RenameKeys"),
		SearchService = require("common/service/search/SearchService"),
		SearchResultContainer = require("common/service/search/SearchResultContainer"),
		truncate = require("common/filter/truncate");

	var component = angular.module("mPortal.components.accountsSearch", [
		"mPortal.directive"
	]);

	component.directive("accountsSearch", accountsSearch);
	component.controller("AccountsSearchController", AccountsSearchController);
	component.factory("MessageContainer", MessageContainer);
	component.factory("PaginationService", PaginationService);
	component.factory("RenameKeys", RenameKeys);
	component.factory("SearchService", SearchService);
	component.factory("SearchResultContainer", SearchResultContainer);
	component.filter("truncate", truncate);

	return component;

});
