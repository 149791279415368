define('components/errorModal/controller/ErrorModalController',['require'],function(require) {
	"use strict";

	ErrorModalController.$inject = ["$scope","UserService","$location", "$sce","$modalStack", "$modalInstance", "title", "message", "exception", "status","ciamApiUrl"];

	function ErrorModalController( $scope,UserService,$location, $sce,$modalStack, $modalInstance, title, message, exception, status,ciamApiUrl ) {

		$scope.title = title;
		$scope.message = $sce.trustAsHtml( message );
		$scope.exception = $sce.trustAsHtml( exception );
		$scope.status = (status)?status:null;
		
		$scope.close_check = function()
		{
			if($modalInstance)$modalInstance.dismiss('close');
			if(!$modalInstance)$modalStack.dismissAll('close');

			if( $scope.status == 401 ) {
				// we don't need to wait on this promise because it's just making a request to maverick to log out. we shouldn't
				// be hitting this unless we've already been logged out, so the important thing about this call is to reset
				// our local UserProfile instance.
				UserService.logout();

				window.location = ciamApiUrl + 'auth/login-request/mportal';
			}
		};

		$scope.close = $scope.close_check;
	}

	return ErrorModalController;

});
