define( 'components/specialtyApplication/payment/controller/PaymentController',[],function () {

	PaymentController.$inject = ["$scope", "$sce", "$window", "SubmissionStore", "PaymentService", "UserProfile"];

	function PaymentController( $scope, $sce, $window, SubmissionStore, PaymentService, UserProfile ) {

		$scope.paymentInfo = {loading: true};

		$scope.loadPaymentScreen = function () {
			$scope.paymentInfo.loading = true;
			//the redirectURL is passed back to the ePay by the billing adapter.  The user will be directed to this link if they click "close" or "cancel" on an epay screen.
			var redirectURL = $window.location.hash ?
					$window.location.toString().replace( $window.location.hash,'#/application/result') :
					$window.location.toString() + '#/application/result';
			var supportPhoneNumber = !UserProfile.cobranding.id ? "1-800-900-1155" : UserProfile.cobranding.contactPhone;

			//pass the delayedpayment argument from submissoinstroe
			PaymentService.getPaymentURL( SubmissionStore.applicationId, SubmissionStore.paymentPlan, SubmissionStore.marketedProgramId , SubmissionStore.delayedPaymentFlag, redirectURL, supportPhoneNumber ).then(
					function ( result ) {
						$scope.paymentInfo.loading = false;
						angular.extend( $scope.paymentInfo, result );
						$scope.paymentURL = $sce.trustAsResourceUrl( $scope.paymentInfo.paymentURL );
					} );
		};

		$scope.loadPaymentScreen();
	}

	return PaymentController;

} );
