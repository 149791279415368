define('common/service/productSearch/productService',['require'],function(require) {
	"use strict";

	FilterService.$inject = ["$http","$sce","archApiUrl","UserProfile"];

	function FilterService( $http, $sce, archApiUrl, UserProfile) {

		var service = { products : { source: [] } };
		
        service.split_products = function(){

            service.products.selected    = [];
            service.products.unselected  = [];
            service.products.ineligible  = [];
            service.products.unavailable = [];

            //collect selected product IDs
            for(var i=0; i < service.products.source.length; i++)
            {
                var product = service.products.source[i];

                if(!product.forbidden)
                {

                    if(product.allowed)
                    {
                        if(!product.disabled)
                        {
                            if(product.selected)
                            {
                                service.products.selected.push(product);
                            }
                            else
                            {
                                service.products.unselected.push(product);
                            }
                        }
                        else
                        {
                            service.products.ineligible.push(product);
                        }
                    }
                    else
                    {
                        service.products.unavailable.push(product);
                    }
                }
            }

            if(UserProfile.debug.display && UserProfile.debug.products) console.log('products :', service.products);
        };

        service.selected_products = function(){
            var selected_products = { arr : [] };

            //collect selected product IDs
            for(var i=0; i < service.products.source.length; i++)
            {
                var prod = service.products.source[i];

                if(prod.selected && prod.allowed)
                {
                    selected_products[prod.id] = prod;
                    selected_products.arr.push(prod.id);
                }
            }

            return selected_products;
        }

		return service;
	}

	return FilterService;

});
