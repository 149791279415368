/*
 Created based (heavily) on this CodePen: https://codepen.io/lumi/pen/pvuyA

 Designed to be instantiated with a unique container ID that becomes the DOM element ID of the block containing the
 dial. This contains the CSS styles so they don't affect any of the surrounding DOM and allows for the use of multiple
 dials on a given page.  It also allows you to declare "!important" styles that override the colors:

 Example:
 <div progress-dial container-id="'dialAlpha'" size="200" percentage="getCurrentlyDone()"></div>

 #dialAlpha > .pie-wrapper .arc-not-completed               { border-color:#ccc !important; }
 #dialAlpha > .pie-wrapper .arc-completed                   { border-color: green !important; }
 #dialAlpha > .pie-wrapper .percentage-label                { color: black !important; }
 #dialAlpha > .pie-wrapper .percentage-label .percent-sign  { color: #666 !important }
 #dialAlpha > .pie-wrapper .percentage-label                { background-color: beige !important; }

 */
define( 'common/directive/progressDial',['require'],function( require ) {
    "use strict";

    function ProgressDial() {
        return {
            restrict: "AE",
            scope: {
                containerId: "=",
                size: "=?",
                percentage: "=?"
            },
            link: function( scope, element, attrs ) {
                scope.id = scope.containerId;
                scope.size = scope.size || 200;
                scope.percentage = scope.percentage || 0;
            },
            template:
            '<style> ' +
            '    #{{id}} *, ' +
            '    #{{id}} *:before, ' +
            '    #{{id}} *:after { ' +
            '        -moz-box-sizing: border-box; ' +
            '        -webkit-box-sizing: border-box; ' +
            '        box-sizing: border-box; ' +
            '    } ' +
            '    #{{id}} > .pie-wrapper { ' +
            '        display: inline-block; ' +
            '        height: {{size}}px; ' +
            '        margin: 10px; ' +
            '        position: relative; ' +
            '        width: {{size}}px; ' +
            '    } ' +
            '    #{{id}} > .pie-wrapper .arc-completed { ' +
            '        border: {{size/10}}px solid #009AA6; ' +
            '        border-radius: 50%; ' +
            '        clip: rect(0, {{size/2}}px, {{size}}px, 0); ' +
            '        height: {{size}}px; ' +
            '        position: absolute; ' +
            '        width: {{size}}px; ' +
            '    } ' +
            '    #{{id}} > .pie-wrapper .percentage-label { ' +
            '        background: none; ' +
            '        border-radius: 50%; ' +
            '        color: #212223; ' +
            '        cursor: default; ' +
            '        display: block; ' +
            '		 font-family: Arial, Verdana, Helvetica, sans-serif !important; ' +
            '        font-size: 1em; ' +
            '        height: {{size - (size * .2 )}}px; ' +
            '        left: 10%; ' +
            '        /* line-height: {{(size/100)+1.80}}em; */' +
            '        position: absolute; ' +
            '        text-align: center; ' +
            '        top: 10%; ' +
            '        width: {{size - (size * .2 )}}px;  ' +
            '    } ' +
            '    #{{id}} > .pie-wrapper .percentage-label .percent-block { ' +
            '        margin-top: 26%; ' +
            '        margin-left: 8%; ' +
        '    } ' +
            '    #{{id}} > .pie-wrapper .percentage-label .percent-sign { ' +
            '        color: #212223; ' +
            '        font-size: .7em; ' +
            '        padding-bottom: 20px; ' +
            '        vertical-align: text-bottom;  ' +
            '    } ' +
            '    #{{id}} > .pie-wrapper .arc-not-completed { ' +
            '        border: {{size/10}}px solid #bdc3c7; ' +
            '        border-radius: 50%; ' +
            '        height: 100%; ' +
            '        width: 100%; ' +
            '    } ' +
            '    #{{id}} > .pie-wrapper .small-pie { ' +
            '        clip: rect(0, {{size}}px, {{size}}px, {{size/2}}px); ' +
            '        height: {{size}}px; ' +
            '        position: absolute; ' +
            '        width: {{size}}px; ' +
            '    } ' +
            '    #{{id}} > .pie-wrapper .small-pie .right-side { ' +
            '        display:none; ' +
            '    } ' +
            '    #{{id}} > .pie-wrapper .small-pie .left-side { ' +
            '		-webkit-transform: rotate({{percentage*3.6}}deg); ' +
            '		-moz-transform: rotate({{percentage*3.6}}deg); ' +
            '		-ms-transform: rotate({{percentage*3.6}}deg); ' +
            '		-o-transform: rotate({{percentage*3.6}}deg); ' +
            '		transform: rotate({{percentage*3.6}}deg); ' +
            '    } ' +
            '    #{{id}} > .pie-wrapper .large-pie { ' +
            '        clip: rect(auto, auto, auto, auto); ' +
            '    } ' +
            '    #{{id}} > .pie-wrapper .large-pie .right-side { ' +
            '        -webkit-transform: rotate(180deg); ' +
            '        -moz-transform: rotate(180deg); ' +
            '        -ms-transform: rotate(180deg); ' +
            '        -o-transform: rotate(180deg); ' +
            '        transform: rotate(180deg); ' +
            '    } ' +
            '    #{{id}} > .pie-wrapper .large-pie .left-side { ' +
            '        -webkit-transform: rotate({{percentage*3.6}}deg); ' +
            '        -moz-transform: rotate({{percentage*3.6}}deg); ' +
            '        -ms-transform: rotate({{percentage*3.6}}deg); ' +
            '        -o-transform: rotate({{percentage*3.6}}deg); ' +
            '        transform: rotate({{percentage*3.6}}deg); ' +
            '    } ' +
            '</style> ' +
            '<div id="{{id}}"> ' +
            '    <div class="pie-wrapper pull-right"> ' +
            '        <span class="percentage-label"><div class="percent-block">{{ percentage }}<span class="percent-sign">%</span></div></span> ' +
            '        <div ng-if="percentage <= 50" class="small-pie"> ' +
            '            <div class="left-side arc-completed"></div> ' +
            '            <div class="right-side arc-completed"></div> ' +
            '        </div> ' +
            '        <div ng-if="percentage > 50" class="large-pie"> ' +
            '            <div class="left-side arc-completed"></div> ' +
            '            <div class="right-side arc-completed"></div> ' +
            '        </div> ' +
            '        <div class="arc-not-completed"></div> ' +
            '    </div> ' +
            '</div> '


        };
    }

    return ProgressDial;

});
