define( 'components/policyDetail/policyClaims/app',['require','components/policyDetail/policyClaims/directive/policyClaims','components/policyDetail/policyClaims/controller/PolicyClaimsController','common/service/specialtyApplication/AccountService'],function( require ) {
   "use strict";

    var policyClaims = require( "components/policyDetail/policyClaims/directive/policyClaims" ),
        PolicyClaimsController = require( "components/policyDetail/policyClaims/controller/PolicyClaimsController" ),
        AccountService = require( "common/service/specialtyApplication/AccountService" );

    var component = angular.module( "mPortal.policyDetail.policyClaims", [] );

    component.directive( "policyClaims", policyClaims );
    component.controller( "PolicyClaimsController", PolicyClaimsController );
    component.factory( "AccountService", AccountService );

    return component;
});

