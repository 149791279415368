define('components/widget/policy-information/coverage-table/controller/coverageTableController',['require'],function(require) {
	"use strict";

	coverageTableController.$inject = ["$scope", "$filter", "$location","$q", "moment", "$sce", "UserProfile","SubmissionDataService"];

	function coverageTableController( $scope, $filter, $location , $q ,moment ,$sce , UserProfile, SubmissionDataService ) {

        $scope.init_data = function()
        {   
            $scope.key              = $scope.options.key;
            $scope.packages         = $scope.options.templateOptions.options;
            $scope.change           = $scope.options.templateOptions.onChange;
            $scope.account          = UserProfile;

            $scope.show_coverages   = false;
            $scope.coverages        = [];
            $scope.coverages_loaded = false;
            $scope.packageNames     = { arr: [] , list : ''};
            

            for(var i=0 ; i < $scope.packages.length; i++)
            {
                var pack = $scope.packages[i];

                if($scope.model[$scope.key]  == pack.value )
                {
                    pack.selected = true;
                }
                else
                {
                    pack.selected = false;
                }

                $scope.packageNames.arr.push(pack.name);
            }

            $scope.packageNames.list = $scope.packageNames.arr.join();

            //console.log($scope);
        }
		
        $scope.get_coverages = function(){
            var p = [ SubmissionDataService.getCoverages({packageNames:$scope.packageNames.list}) ];

            $q.all(p).then(function(results){
                $scope.coverages = results[0];

                for(var i=0 ; i < $scope.coverages.length; i++)
                {
                    var coverage = $scope.coverages[i];
                    var details  = coverage.details;

                   for(var j=0; j < details.length; j++)
                   {
                        var detail      = details[j];
                            detail.text = '';
                            detail.arr  = [];

                            if(detail.loi1)detail.arr.push(detail.loi1);
                            if(detail.loi2)detail.arr.push(detail.loi2);
                            if(detail.loi3)detail.arr.push(detail.loi3);

                            if(detail.arr.length)
                            {
                                detail.text = detail.arr.join('<br>');
                            }
                            else
                            {
                                detail.text = 'N/A';
                            }

                        details[j] = detail;
                   }
                }

                $scope.coverages_loaded = true;
            });
        };

        $scope.show_highlights = function(e){

            window.open( '/assets/documents/BOPCoverageHighlights.pdf', 'BOPCoverageHighlights', 'menubar=no;height=500;width=400;scrollbars=yes' );

            return false;
        }

        $scope.show_detail = function(e){

            $scope.show_coverages = !$scope.show_coverages;

            if(!$scope.coverages_loaded)
            {
                $scope.get_coverages();
            }

            if($scope.show_coverages) {
                $('#coverage-package .packageHeader .btn ').text('Hide');
            } else {
                $('#coverage-package .packageHeader .btn ').text('Show');
            }

            return false;
        };

        $scope.set_option = function(e,pack){           
            pack.selected = true;

            $scope.model[$scope.key] = pack.value;
            

            for(var i=0 ; i < $scope.packages.length; i++)
            {
                var pack = $scope.packages[i];

                if($scope.model[$scope.key]  == pack.value )
                {
                    pack.selected = true;
                }
                else
                {
                    pack.selected = false;
                }
            }

            //fire off data change event so we can save the change
            $scope.change($scope.model[$scope.key],$scope.options,$scope,e);
            
            return false;
        }
       
        $scope.init_data();
	}

	return coverageTableController;
});
