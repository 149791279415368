define('isc/app',['require','angular-sanitize','common/config/config','common/directive/app','shim/overmind','isc/controller/IscPolicyController','common/service/user/VerifySession','common/service/user/UserProfile','common/service/user/UserService'],function(require) {
	"use strict";

	require("angular-sanitize");
	require("common/config/config");
	require("common/directive/app");

	var overmind = require("shim/overmind"),
		IscPolicyController = require("isc/controller/IscPolicyController"),
		VerifySession = require("common/service/user/VerifySession"),
		UserProfile = require("common/service/user/UserProfile"),
		UserService = require("common/service/user/UserService");

	var app = angular.module("mPortal.isc", [
		"ngSanitize",
		"mPortal.config",
		"mPortal.directive"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {
		/*
		VerifySession will determine if the user is still logged in before resolving the route, preventing a scenario
		where the ISC iframe presents its own login dialog box then, once the user logs in again, returns the ISC
		navigation bar along with the policy content
		*/
		var resolve = {
			__verifySessionGuard: VerifySession
		};

		$routeProvider.when("/isc/:area/:id/:subId?", {
			templateUrl: "app/isc/template/template.html",
			controller: "IscPolicyController",
			resolve: resolve
		});
	}]);

	app.controller("IscPolicyController", IscPolicyController);
	app.factory("UserProfile", UserProfile);
	app.factory("UserService", UserService);

	return app;

});
