define('components/surveyList/controller/SurveyListController',['require'],function(require) {
	"use strict";

	SurveyListController.$inject = ["$scope", "SurveyService"];

	function SurveyListController( $scope, SurveyService ) {

		$scope.surveys = [];

		SurveyService.getSurveys().then(function( result ) {
			$scope.surveys = result;
		});

	}

	return SurveyListController;

});
