define('common/service/PromotionsService',['require'],function(require) {
	"use strict";

	PromotionsService.$inject = ["$http", "maverickApiUrl"];

	function PromotionsService( $http, maverickApiUrl ) {

		var service = {};

		service.getPromotionsSummary = function() {
			return $http.get( maverickApiUrl + "promotion/getSummary" ).then(function( results ) {
				return results.data;
			});
		};

		service.getPromotionHistory = function( type ) {
			return $http.get( maverickApiUrl + "office/getPromotionHistory", { params: { type: type } }).then(function( results ) {
				return results.data;
			});
		};

		return service;

	}

	return PromotionsService;

});
