define('common/service/productSearch/RuleService',['require'],function(require) {
	"use strict";

	RuleService.$inject = ["$http","$sce",  "$q","archApiUrl","UserProfile","ProductService","ProductSearchService","FilterService", "LogDataService"];

	function RuleService( $http, $sce, $q, archApiUrl, UserProfile, ProductService, ProductSearchService, FilterService, LogDataService ) {

		var service = { rules : { source: [] } , filters : { source: [] } , products: { source: [] } };
		

		service.getStates = function(){
		
			return states;
		}


		service.run_rules = function(e,obj){
            var filters     = {};
            var options     = {};
            var rules       = obj.rules;
            var outcomes    = { arr : [] };

            if(rules)
            {
	            //put our options in an easy to use object
	            for(var i = 0; i < service.filters.source.length ; i++)
	            {
	                var filter = service.filters.source[i];
						if( filter.hidden == false ){
							filters[filter.id] = filter.value;
						}

	                    for(var j = 0; j < filter.options.length; j++)
	                    {
	                        var option = filter.options[j];

	                        if(option.selected)
	                        {
	                            options[option.id] = option.value;
	                        }
	                    }
	            }

	            //loop our rules and compare them against the options
	            for(var i = 0; i < rules.length ; i++)
	            {
	                var rule        = rules[i];
	                var conditions  = rule.conditions;

	                for(var j = 0; j < conditions.length; j++)
	                {
	                    var condition        = conditions[j];
	                        condition.filter = filters[condition.filterID];
	                        condition.option = options[condition.optionID];

	                    if(!condition.optionID)condition.option = condition.filter;

	                    switch(condition.dataType)
	                    {
	                        case 'string' :
	                            condition.filter = String(condition.filter).toLowerCase(); 
	                            condition.value  = String(condition.value).toLowerCase();
	                            condition.option = String(condition.option).toLowerCase();
	                        break;

	                        case 'number':
	                            condition.filter = Number(String(condition.filter).replace(/\D/g,''));
	                            condition.value  = Number(String(condition.value).replace(/\D/g,''));
	                            condition.option = Number(String(condition.option).replace(/\D/g,''));
	                        break;

	                        case 'date':
	                            condition.filter = new Date(condition.filter).getTime(); 
	                            condition.value  = new Date(condition.value).getTime(); 
	                        break;

	                        case 'boolean':
	                            condition.filter = Boolean(condition.filter.toString().toLowerCase() == 'true'); 
	                            condition.value  = Boolean(condition.value.toString().toLowerCase() == 'true');
	                        break;
	                    }

	                    switch(condition.conditionString)
	                    {
	                        case '==' : condition.outcome = (condition.filter == condition.value);                         break;
	                        case '!=' : condition.outcome = (condition.filter != condition.value);                         break;
	                        case '>=' : condition.outcome = (condition.filter >= condition.value);                         break;
	                        case '<=' : condition.outcome = (condition.filter <= condition.value);                         break;
	                        case '>'  : condition.outcome = (condition.filter >  condition.value);                         break;
	                        case '<'  : condition.outcome = (condition.filter <  condition.value);                         break;
	                        case 'in' : condition.outcome = (condition.value.split(",").indexOf(condition.option) != -1);  break;
	                        case '!in': condition.outcome = (condition.value.split(",").indexOf(condition.option) == -1);  break;
	                    }
	                }

	                rule.outcome = true;

	                for(var j = 0; j < conditions.length; j++)
	                {
	                    var condition = conditions[j];

	                    if(!condition.outcome)
	                    {
	                        rule.outcome = false;
	                        break;
	                    }
	                }

	               //rule was hit
	               if(rule.outcome)
	               {
	                    outcomes.arr.push(rule.id);
	                    outcomes[rule.id] = rule;
	               }
	            }	
            }

             if(UserProfile.debug.display && UserProfile.debug.rules)console.log('run-rules [' + obj.type + '] :' , rules);

            return outcomes;
        }

        service.run_eligibility_rules = function()
        {
            var outcomes    = service.run_rules('run-rules',{rules:service.rules.eligibility, type: 'eligibility override'});
            var product_arr = [];

            for(var x=0; x < outcomes.arr.length;x++)
            {
                var rule = outcomes[outcomes.arr[x]];

                for(var i = 0; i < service.products.source.length ; i++)
                {
                    var product = service.products.source[i];

                    if(rule.product.id == product.id)
                    {
                        product.eligibility = rule.eligibility;
                        product_arr.push(product.id);
                    }
                } 
            }

            //loop over products and if not overridden by rule reset eligibility
            for(var i = 0; i < service.products.source.length ; i++)
            {
                var product = service.products.source[i];
				if($.inArray(product.id,product_arr) == -1)
				{
                	product.eligibility = product.defaultEligibility;
        		}
        	}
        };

        service.run_underwriting_rules = function()
        {
            var outcomes    = service.run_rules('run-rules',{rules:service.rules.blurb, type: 'data blurb override'});
            var product_arr = [];

            for(var x=0; x < outcomes.arr.length;x++)
            {
                var rule = outcomes[outcomes.arr[x]];

                for(var i = 0; i < service.products.source.length ; i++)
                {
                    var product = service.products.source[i];

                    if(rule.product.id == product.id)
                    {
                        product.underwriting = rule.underwriting;
                        product_arr.push(product.id);
                    }
                } 
            }

            //loop over products and if not overridden by rule reset eligibility
            for(var i = 0; i < service.products.source.length ; i++)
            {
                var product = service.products.source[i];
                if($.inArray(product.id,product_arr) == -1)
                {
                    product.underwriting = product.defaultUnderwriting;
                }
            }
        };

        service.run_all_rules = function()
        {
            if(service.rules.source.length)
            {	
				//clear all inforce rules
            	service.rules.log = { id: [], arr:[]};
	            service.run_hide_rules();

	            service.run_unavailable_rules();
	            service.run_ineligible_rules();
	            service.run_classcode_rules();
                service.run_classcode_mol_rules();
                service.run_naics_rules();

	            service.check_program_eligibility();
	            service.run_eligibility_rules();
                service.run_underwriting_rules();
	            service.set_product_messages();

	            //ok bunch of statuses changed lets run or product rules
	            var selected_products = service.selected_products();

	            if(selected_products.arr.length)
	            {
	                for(var i=0; i < selected_products.arr.length; i++)
	                {
	                    var product = selected_products[selected_products.arr[i]];

	                    service.product_exclusion(product);
	                    service.product_inclusion(product);
	                    service.product_package(product);
	                    service.product_program_override(product);
	                }    

	                service.continue = selected_products.arr.length != 0;
	            }

	            service.run_link_rules();
                service.run_product_availability_rules();

	           if(UserProfile.debug.display)console.log('logged rules : ',service.rules.log);
	           if(!service.isUnAuthenticatedUser) {
                   LogDataService.productSearchLogOnFilterChange(service.business_type_id, service.business_type_input, service.filters.source, service.products.source, service.rules.log.arr, service.transactionUUID, 3);
               }
       		}
        };

        service.find_product = function(id)
        {
            var product = null;

            //loop all our products find our parent
            for(var x = 0; x < service.products.source.length ; x++)
            {
                var prod = service.products.source[x];
                
                if(prod.id == id)
                {
                    product = prod;
                    break;
                }
            }

            return product;
        };

        service.log_rule = function(rule)
        {
            if($.inArray(rule.id,service.rules.log.id) == -1)
            {
                service.rules.log.id.push(rule.id);
                service.rules.log.arr.push(rule);
            }

            //may want to sort array by type?
            service.rules.log.arr.sort(function(a,b){
                return a.type - b.type;
            });
        },

        service.run_hide_rules = function()
        {
            var outcomes    = service.run_rules('run-rules',{rules:service.rules.hide, type:'hide filter'});
            var filter_arr  = [];

            for(var x=0; x < outcomes.arr.length;x++)
            {
                var rule = outcomes[outcomes.arr[x]];
                
                    filter_arr.push(rule.filter.id);

                    service.log_rule(rule);
            }

            for(var i = 0; i < service.filters.source.length ; i++)
            {
                var filter = service.filters.source[i];

                if($.inArray(filter.id,filter_arr) == -1)
                {
                    filter.visible = true;
                    filter.hidden  = false;
                }
                else
                {
                    filter.visible = false;
                    filter.hidden  = true; 
                }
            }
        };

        service.run_link_rules = function()
        {
            var selected_products   = service.selected_products();
            var ineligible_products = service.ineligible_products();
    
            //loop over our rules
            for(var x=0; x < service.rules.linked.length;x++)
            {
                var rule = service.rules.linked[x];
                var filter_arr = [];
                //check if our product is selected or not and show or hide the filter

                for(var j = 0; j < rule.filters.length;j++)
                {
                    filter_arr.push(rule.filters[j].id);
                }

                
                service.log_rule(rule);

                for(var i = 0; i < service.filters.source.length ; i++)
                {
                    var filter = service.filters.source[i];

                    if($.inArray(filter.id,filter_arr) != -1)
                    {
                        var selected = $.inArray(rule.product.id,selected_products.arr) != -1;
                        var ineligible = $.inArray(rule.product.id,ineligible_products.arr) != -1;
                        filter.visible = (selected || ineligible) && !filter.hidden;
                    }
                } 
            }
        };

        service.run_unavailable_rules = function(){
            var outcomes    = service.run_rules('run-rules',{rules:service.rules.unavailable, type:'unavailable'});
            var unavailable = [];

            for(var x=0; x < outcomes.arr.length;x++)
            {
                var rule     = outcomes[outcomes.arr[x]];
                var id_arr   = [];

                for(var j = 0; j < rule.products.length;j++)
                {
                    id_arr.push(rule.products[j].id);
                    unavailable.push(rule.products[j].id)
                }

                 //loop over products and hide products that hit rule
                for(var i = 0; i < service.products.source.length ; i++)
                {
                    var product = service.products.source[i];

                    if($.inArray(product.id,id_arr) != -1)
                    {
                        product.allowed = false;
                        product.selected = false;
                        //record rule that hid the product because of unavailable rule
                        service.log_rule(rule);
                    }
                }
            }

            //re-enable products not in our rules
            for(var i = 0; i < service.products.source.length ; i++)
            {
                var product = service.products.source[i];

                if($.inArray(product.id,unavailable) == -1)
                {
                    product.allowed = true;
                    if(product.preselected && !product.deselected && !product.deselected) product.selected = true;
                }
            }
        };

        service.run_ineligible_rules = function(){
            var outcomes    = service.run_rules('run-rules',{rules:service.rules.ineligible, type: 'ineligible'});
            var ineligible  = [];

            for(var x=0; x < outcomes.arr.length;x++)
            {
                var rule = outcomes[outcomes.arr[x]];
                var id_arr   = [];

                for(var j = 0; j < rule.products.length;j++)
                {
                    id_arr.push(rule.products[j].id);
                    ineligible.push(rule.products[j].id);
                }

                //loop over products and disable prods in rule
                for(var i = 0; i < service.products.source.length ; i++)
                {
                    var product = service.products.source[i];

                    if($.inArray(product.id,id_arr) != -1)
                    {
                        if(product.allowed)
                        {
                            product.disabled = true;
                            product.selected = false;
                            //log rule 
                            service.log_rule(rule);
                        }
                    }
                }
            }

            //re-enable products not in our rules
            for(var i = 0; i < service.products.source.length ; i++)
            {
                var product = service.products.source[i];

                if($.inArray(product.id,ineligible) == -1)
                {
                    product.disabled = false;
                    if(product.preselected & !product.deselected) product.selected = true;
                }
            }
        };

        service.run_naics_rules = function()
        {
            var outcomes    = service.run_rules('run-rules',{rules:service.rules.naics, type: 'naics'});
            var naics_codes  = { arr:[] };

            for(var x=0; x < outcomes.arr.length;x++)
            {
                var rule = outcomes[outcomes.arr[x]];
                
                //do stuff
                naics_codes.arr.push(rule.id);
                naics_codes[rule.id] = { nc :rule.naicsCode };

                //log rule 
                service.log_rule(rule);
            }


            var naics_code = null;

            //set our initial classcodes
            if(service.business_type)
            {
                if(service.business_type.naicsCode && service.business_type.naicsCode.naicsCode)
                {
                    service.naics_code = service.business_type.naicsCode;
                }
            }
           

            if(UserProfile.debug.display && UserProfile.debug.classcodes)console.log('pre-rule  naics : ' , service.naics_code);
           
            if(naics_codes.arr.length)
            {
                //loop our rules and override defaults
                for(var i= 0; i < naics_codes.arr.length; i++ )
                {
                    var naics_code = naics_codes[naics_codes.arr[i]];

                    if(naics_code.nc && naics_code.nc.naicsCode)
                    {
                          service.naics_code = naics_code.nc;
                    }
                }
            }

            if(UserProfile.debug.display && UserProfile.debug.classcodes)console.log('post-rule naics : ' , service.naics_code);
            
            return naics_codes;
        };

        service.run_classcode_mol_rules = function()
        {
            var outcomes    = service.run_rules('run-rules',{rules:service.rules.classcode, type: 'classcode'});
            var classcodes  = { arr:[] };

            //reset product mol class codes
            for(var x = 0; x < service.products.source.length ; x++)
            {
                var product = service.products.source[x];
                product.molClassCode = product.default_mol;
            }

            for(var x=0; x < outcomes.arr.length;x++)
            {
                var rule = outcomes[outcomes.arr[x]];

                //check mol overrides
                if(rule.product && rule.system)
                {
                    //if so set mol class code
                    var product = service.find_product(rule.product.id);

                    if(product.system.id == rule.system.id)
                    {
                        product.molClassCode = rule.molClassCode;
                    } 
                }
                
                //log rule 
                service.log_rule(rule);
            }

            return classcodes;
        };

        service.run_classcode_rules = function()
        {
            var outcomes    = service.run_rules('run-rules',{rules:service.rules.classcode, type: 'classcode'});
            var classcodes  = { arr:[] };

            for(var x=0; x < outcomes.arr.length;x++)
            {
                var rule = outcomes[outcomes.arr[x]];
                
                //do stuff
                classcodes.arr.push(rule.id);
                classcodes[rule.id] = { bo :rule.boClassCode, wc: rule.wcClassCode , legacy : rule.legacyClassCode};
                //log rule 
                service.log_rule(rule);
            }

            var bo_classcode = null;
            var wc_classcode = null;

            //set our initial classcodes
            if(service.business_type)
            {
                if(service.business_type.boClassCode && service.business_type.boClassCode.classCode)
                {
                    service.bo_classcode = service.business_type.boClassCode;
                }

                if(service.business_type.wcClassCode && service.business_type.wcClassCode.classCode  )
                {
                    service.wc_classcode = service.business_type.wcClassCode;
                }

                if(service.business_type.legacyClassCode && service.business_type.legacyClassCode.classCode  )
                {
                   service.wc_classcode = service.business_type.legacyClassCode;
                }
            }
           

            if(UserProfile.debug.display && UserProfile.debug.classcodes)console.log('pre-rule bo classcodes : ' , service.bo_classcode);
            if(UserProfile.debug.display && UserProfile.debug.classcodes)console.log('pre-rule wc classcodes : ' , service.wc_classcode);
           
            if(classcodes.arr.length)
            {
                //loop our rules and override defaults
                for(var i= 0; i < classcodes.arr.length; i++ )
                {
                    var classcode = classcodes[classcodes.arr[i]];

                    if(classcode.bo && classcode.bo.classCode)
                    {
                        service.bo_classcode = classcode.bo;
                    }

                    if(classcode.wc && classcode.wc.classCode)
                    {
                        service.wc_classcode = classcode.wc;
                    }

                    if(classcode.legacy && classcode.legacy.classCode)
                    {
                        service.wc_classcode = classcode.legacy;
                    }
                }
            }

            if(UserProfile.debug.display && UserProfile.debug.classcodes)console.log('post-rule bo classcodes : ' , service.bo_classcode);
            if(UserProfile.debug.display && UserProfile.debug.classcodes)console.log('post-rule wc classcodes : ' , service.wc_classcode);

            //check wc / bop availability
            service.check_classcode_eligibility();
            
            return classcodes;
        };

        service.run_program_rules = function()
        {
            var outcomes    = service.run_rules('run-rules',{rules:service.rules.program, type: 'program'});
            var programs    = { arr:[] };

            for(var x=0; x < outcomes.arr.length;x++)
            {
                var rule = outcomes[outcomes.arr[x]];
                
                programs.arr.push(rule.id);
                programs[rule.id] = rule.program;

                //log rule 
                service.log_rule(rule);
            }
           
           return programs;
        };

        service.run_product_system_rules = function()
        {
            var outcomes    = service.run_rules('run-rules',{rules:service.rules.productSystem, type: 'productSystem'});
            var products    = [];

            for(var x=0; x < outcomes.arr.length;x++)
            {
                var rule = outcomes[outcomes.arr[x]];
                products.push({id: rule.product.id, system: rule.system});
                service.log_rule(rule);
            }

            return products;
        };

        service.run_product_availability_rules = function()
        {
            var allowed     = [];

            for(var j=0; j < service.products.source.length; j++)
            {
                var product = service.products.source[j];
                if(product.allowed && !product.disabled)allowed.push(product.id);
            }

            for(var i = 0; i < service.rules.product_availability.length; i++)
            {
                var rule          = service.rules.product_availability[i]; 
                var products      = [];
                var product       = service.find_product(rule.product.id);

                var available = true;

                for(var j=0; j < rule.products.length; j++)
                {
                    if($.inArray(rule.products[j].id,allowed) != -1)
                    {
                        available = false;
                        break;
                    }
                }

                product.allowed = available;
            }
        }

        service.run_datapoint_rules = function()
        {
            var outcomes    = service.run_rules('run-rules',{rules:service.rules.datapoint, type: 'datapoint'});
            var datapoints  = { arr:[] };

            for(var x=0; x < outcomes.arr.length;x++)
            {
                var rule = outcomes[outcomes.arr[x]];
                    rule.datapoint.priority = rule.priority;
                
                switch(rule.type)
                {
                    case 6:
                        datapoints.arr.push(rule.id);
                        datapoints[rule.id] = rule.datapoint;
                    break;

                    case 7:
                        var selected_products = service.selected_products();

                        if($.inArray(rule.product.id,selected_products.arr) != -1)
                        {
                            datapoints.arr.push(rule.id);
                            datapoints[rule.id] = rule.datapoint;
                        }
                    break;
                }
                
                //log rule 
                service.log_rule(rule);
            }

            return datapoints;
        };

        service.set_product_messages = function()
        {

            var rule_arr = [];
                rule_arr = rule_arr.concat(service.rules.exclusion,service.rules.inclusion,service.rules.package);

            //loop over rules and show message only if all products in rule are there
            for(var i = 0; i < rule_arr.length ; i++)
            {
                var rule            = rule_arr[i];
                var rule_products = [ rule.product.id ];

                for(var j=0; j < rule.products.length; j++)
                {
                    var r_prod = rule.products[j];

                        rule_products.push(r_prod.id);
                }

       
                //loop over products and hide messages as needed
                for(var x = 0; x < service.products.source.length ; x++)
                {
                    var product = service.products.source[x];
                    
                    if($.inArray(product.id,rule_products) != -1)
                    {
                        var message_closed = false;

                        if(product.messages[rule.id])
                        {
                            message_closed =  product.messages[rule.id].closed; 
                        }   

                        product.messages[rule.id] = { productID: rule.product.id, closed: message_closed ,visible:false ,text:rule.message };  
                    }  
                } 
            }
        }

        service.product_exclusion = function(product)
        {
            for(var i = 0; i < service.rules.exclusion.length; i++)
            {
                var rule          = service.rules.exclusion[i];
                var rule_products = [ rule.product.id ];

                for(var j=0; j < rule.products.length; j++)
                {
                    var r_prod = rule.products[j];

                        rule_products.push(r_prod.id);
                }

                //loop all our products
                for(var x = 0; x < service.products.source.length ; x++)
                {
                    var prod = service.products.source[x];
                    

                    if( $.inArray(prod.id,rule_products) != -1 && $.inArray(product.id,rule_products) != -1)
                    {   
                        //prod.disabled = ( prod.id != product.id && product.selected);
                        if(prod.id != product.id)
                        {
                            prod.selected = false;
                            prod.messages[rule.id].visible = false;
                            //record ineligible that hid the product because of exclusion selection rule
                            service.log_rule(rule);
                        }
                        else
                        {
                            prod.messages[rule.id].visible = true;
                        }
                        
                        if(!prod.selected && !product.selected)
                        {
                            prod.messages[rule.id].visible = false;
                            product.messages[rule.id].visible = false;
                        }
                    }
                }
            }
        }

        service.product_inclusion = function(product)
        {
            var parent_product    = null;

            for(var i = 0; i < service.rules.inclusion.length; i++)
            {
                var rule          = service.rules.inclusion[i];
                var rule_products = [ rule.product.id ];

                for(var j=0; j < rule.products.length; j++)
                {
                    var r_prod = rule.products[j];

                        rule_products.push(r_prod.id);
                }

                //loop all our products find our parent
                for(var x = 0; x < service.products.source.length ; x++)
                {
                    var prod = service.products.source[x];
                    
                    if(prod.id == rule.product.id)
                    {
                        parent_product = prod;
                        break;
                    }
                }

                if(parent_product && !parent_product.selected)
                {
	                if ( parent_product.messages[rule.id] ) {
		                parent_product.messages[rule.id].visible = false;
	                }
                }

                //loop over again to work w/ our children
                for(var x = 0; x < service.products.source.length ; x++)
                {
                    var prod = service.products.source[x];
                    
                    //product in the rule?
                    if( $.inArray(prod.id,rule_products) != -1 && $.inArray(product.id,rule_products) != -1)
                    {
                        //child product
                        if(prod.id != parent_product.id)
                        {
                            //deselect children if parent is not selected
                            if(!product.selected && !prod.disabled && prod.allowed)
                            {
                                prod.selected = false;
                                prod.deselected = true;
                                service.product_exclusion(prod);
                                parent_product.messages[rule.id].visible = true;
                                //record rule product interaction because of rule
                                service.log_rule(rule);
                            }
                            else
                            {
                                //child selected and parent not selected
                                if(!parent_product.selected && prod.selected && !prod.disabled && prod.allowed)
                                {
                                    parent_product.selected = true;
                                    parent_product.messages[rule.id].visible = true;
                                    service.product_exclusion(parent_product);
                                    //record rule product interaction because of rule
                                    service.log_rule(rule);
                                }
                            }

                        }
                    }
                }
            }
        }

        service.product_package = function(product)
        {
            var parent_product    = null;

            for(var i = 0; i < service.rules.package.length; i++)
            {
                var rule          = service.rules.package[i];
                var rule_products = [ rule.product.id ];

                for(var j=0; j < rule.products.length; j++)
                {
                    var r_prod = rule.products[j];

                        rule_products.push(r_prod.id);
                }

                if( $.inArray(product.id,rule_products) != -1)
                {
                    //loop all our products and set the selected = whatever the product.
                    for(var x = 0; x < service.products.source.length ; x++)
                    {
                        var prod = service.products.source[x];
                        
                        if(prod.id == rule.product.id)
                        {
                            parent_product = prod;
                        }

                         //product in the rule?
                        if( $.inArray(prod.id,rule_products) != -1)
                        {
                            prod.selected = product.selected;
                            service.log_rule(rule);
                        }
                    }

                    //show the message on the parent
                    parent_product.messages[rule.id].visible = true;
                }
            }
        }

        service.product_program_override = function(product)
        {
            var parent_product    = null;

            for(var i = 0; i < service.rules.override.length; i++)
            {
                var rule          = service.rules.override[i];
                var rule_products = [ rule.product.id ];

                for(var j=0; j < rule.products.length; j++)
                {
                    var r_prod = rule.products[j];

                        rule_products.push(r_prod.id);
                }

                if( $.inArray(product.id,rule_products) != -1)
                {
                    var products_selected = false;
                    //loop all our products and set the selected = whatever the product.
                    for(var x = 0; x < service.products.source.length ; x++)
                    {
                        var prod = service.products.source[x];
                        
                        if(prod.id == rule.product.id)
                        {
                            parent_product = prod;
                        }
                        else
                        {
                            //product in the rule?
                            if( $.inArray(prod.id,rule_products) != -1)
                            {
                                if(prod.selected)
                                {
                                    products_selected = true;
                                }
                            }
                        }
                    }

                    //parent selected and no others over ride program
                    if(parent_product.selected)
                    {
                        if(!products_selected)
                        {
                            parent_product.override = rule.program; 
                        }
                        else
                        {
                            delete parent_product.override;
                        } 

                        service.log_rule(rule);
                    }
                    else
                    {
                        delete parent_product.override;
                    }
                }
            }

            service.check_program_eligibility();
        }

        service.set_noProductsAvailableMessage_rule = function () {
            var rule_str = '';
	        var outcomes = service.run_rules('run-rules',{rules:service.rules.noProductsAvailableMessage, type:'noProductsAvailableMessage'});

	        var rule = outcomes[outcomes.arr[0]];
	        if(rule){
		        rule_str = rule.noProductsAvailableMessage;
	        }
            return rule_str;
        }

        service.selected_products = function(){
            var selected_products = { arr : [] };

            //collect selected product IDs
            for(var i=0; i < service.products.source.length; i++)
            {
                var prod = service.products.source[i];

                if(prod.selected && prod.allowed)
                {
                    selected_products[prod.id] = prod;
                    selected_products.arr.push(prod.id);
                }
            }

            return selected_products;
        }

        service.ineligible_products = function(){
            var ineligible_products = { arr : [] };

            //collect selected product IDs
            for(var i=0; i < service.products.source.length; i++)
            {
                var prod = service.products.source[i];

                if(prod.disabled)
                {
                    ineligible_products[prod.id] = prod;
                    ineligible_products.arr.push(prod.id);
                }
            }

            return ineligible_products;
        }


        service.check_program_eligibility = function()
        {
            var filter_data     = FilterService.get_filter_data();
            var effectiveDate   = filter_data.effectivedate;
            var state           = filter_data.state;
            var programs        = { id : [] , arr: []}

            //get product programs
            for(var x = 0; x < service.products.source.length ; x++)
            {
                var product = service.products.source[x];

                if(product.program && product.allowed && !product.disabled )
                {

                    if(!product.override)
                    {   
                        if($.inArray(product.program.id,programs.id) == -1)
                        {
                            programs.id.push(product.program.id)
                            programs.arr.push(product.program);
                        }
                    }
                    else
                    {
                        if($.inArray(product.override.id,programs.id) == -1)
                        {
                            programs.id.push(product.override.id)
                            programs.arr.push(product.override); 
                        }
                    }
                   
                }
            }


            //runs program rules which will add programs based on filter options
            var rule_programs = service.run_program_rules();

            //console.log(rule_programs);

            if(rule_programs.arr.length)
            {
                for(var i= 0; i < rule_programs.arr.length; i++ )
                {
                    var program = rule_programs[rule_programs.arr[i]];

                    if($.inArray(program.id,programs.id) == -1)
                    {
                        programs.id.push(program.id)
                        programs.arr.push(product.program);
                    }                    
                }
            }

            if(service.eligibility)
            {
	            if( ( service.eligibility.program.effectiveDate != effectiveDate || service.eligibility.program.state != state) && state && effectiveDate)
	            {
	                if(effectiveDate)service.eligibility.program.effectiveDate = effectiveDate; 
	                if(state)service.eligibility.program.state = state;
	                
	                service.eligibility.program.result   = null;
                    service.eligibility.program.complete = false;

	                var p = [ ProductSearchService.checkProgramEligibility( effectiveDate, state , programs.arr ) ];


	                $q.all(p).then(function(results){
	                   
	                    var result = results[0];
	                    //loop our products
	                    service.eligibility.program.result = result;
	                    service.parse_program_eligibility();
	                }); 
	            }
	            else
	            {
                    service.eligibility.program.complete = false;
	                //run check against last result
	                if(service.eligibility.program.result)service.parse_program_eligibility();
	            }	
            }
        };

        service.parse_program_eligibility = function()
        {
            var result = service.eligibility.program.result;

            if(result)
            {
                for(var i = 0; i < service.products.source.length ; i++)
                {
                    var product = service.products.source[i];
                        
                       for(var j = 0; j < result.length;j++)
                       {
                            var eligibility = result[j];
                            var program_reference_string = (product.override)?product.override.referenceString:product.program.referenceString;

                            if(eligibility.referenceString == program_reference_string)
                            {
                                if(($.inArray(eligibility.referenceString,['wcBop']) == -1 && product.system.codekey == 'mpolicy') && $.inArray(product,service.products.unavailable) == -1)
                                {
                                    if(!eligibility.quote)
                                    {
                                        product.allowed = false;
                                        product.selected = false;
                                        product.quote = false;
                                        
                                        //record rule that hid the product because of eligibility decline
                                        var rule = { id:333, display: 'MP Declined during eligibility call string:' + eligibility.referenceString , type:998};
                                        service.log_rule(rule);
                                    }
                                    
                                    if(eligibility.quote)
                                    {
                                        product.allowed = true;

                                        if(!product.default_quote)
                                        {
                                            product.refer = true;
                                        }
                                        else
                                        {
                                        	product.quote = true;
                                            product.refer = false;
                                        }
                                    }  
                                }
                                break;
                            }
                       }

                    service.run_hide_rules();
                    service.run_link_rules();
                    service.run_product_availability_rules();
                }



                ProductService.split_products();  
                service.eligibility.program.complete = true;
            }
        };

        service.check_classcode_eligibility = function()
        {
            var filter_data     = FilterService.get_filter_data();
            var effectiveDate   = filter_data.effectivedate;
            var state           = filter_data.state;
            var bo_classcode    = null;
            var wc_classcode    = null;
            var eligibility     = {};

            if(service.eligibility)
            {
	            if(service.bo_classcode)
	            {
	                eligibility["bo"] = [{
	                                        "state": state,
	                                        "classCode":service.bo_classcode.classCode.toString(),
	                                        "descriptionId":service.bo_classcode.classCodeDescriptionID.toString(),
	                                        "classCodeId":service.bo_classcode.classCodeID.toString()
	                                    }];
	                bo_classcode = service.bo_classcode.classCodeDescriptionID.toString();
	            }

	            if(service.wc_classcode)
	            {
	                eligibility["wc"] = [{
	                                        "state": state,
	                                        "classCode":service.wc_classcode.classCode.toString(),
	                                        "descriptionId":service.wc_classcode.classCodeDescriptionID.toString(),
	                                        "classCodeId":service.wc_classcode.classCodeID.toString()
	                                    }];
	                wc_classcode = service.wc_classcode.classCodeDescriptionID.toString();
	            }


	            if( (service.eligibility.classcode.bo != bo_classcode || 
	                service.eligibility.classcode.wc != wc_classcode || 
	                service.eligibility.classcode.effectiveDate != effectiveDate ||
	                service.eligibility.classcode.state != state) && state && effectiveDate)
	            {

	                    if(bo_classcode)service.eligibility.classcode.bo = bo_classcode;
	                    if(wc_classcode)service.eligibility.classcode.wc = wc_classcode;
	                    if(effectiveDate)service.eligibility.classcode.effectiveDate = effectiveDate; 
	                    if(state)service.eligibility.classcode.state = state;
	                    service.eligibility.classcode.result = null;


	                    var p = [ ProductSearchService.checkClassCodeEligibility( effectiveDate, state , eligibility ) ];

                        service.eligibility.classcode.complete = false;

	                    $q.all(p).then(function(results){
	                       
	                        var result = results[0];
	                        service.eligibility.classcode.result = result;
	                        service.parse_classcode_eligibility();
	                    }); 
	            }
	            else
	            {
                    service.eligibility.classcode.complete = false;
	                //run check against last result
	                if(service.eligibility.classcode.result)service.parse_classcode_eligibility();
	            }
            }

         
        };

        service.parse_classcode_eligibility = function()
        {
            var result = service.eligibility.classcode.result;

            if(result)
            {
                for(var i = 0; i < service.products.source.length ; i++)
                {
                    var product = service.products.source[i];
                        
                        if(product.program.referenceString == 'wcBop' && service.wc_classcode && product.system.codekey == 'maverick')
                        {
                            if(result.wc == 'decline' || result.wc == 'invalid')
                            {
                                product.allowed     = false;
                                product.selected    = false;
                                product.quote       = false;
                                product.refer       = false;
                                 //record rule that hid the product because of eligibility decline
                                var rule = { id:111, display: 'WC Declined during eligibility call cc:' + service.wc_classcode.classCode.toString() , type:999 , classcode: service.wc_classcode};
                                service.log_rule(rule);
                            }
                           
                            if(product.allowed && (result.wc != 'decline' || result.wc != 'invalid'))
                            {
                                product.allowed = true;
                                //record rule that hid the product because of eligibility decline
                                //product.rule = { id:111, display: 'WC Refered during eligibility call cc:' + wc_classcode.classCode , classcode: wc_classcode};
                            
                                if(result.wc == 'autoquote' || result.wc == 'quote')
                                {
                                    product.quote = true;
                                    product.default_quote = true;
                                    product.refer  = false;
                                }
                                else
                                {
                                    product.quote = false;

                                    if(result.wc == 'refer')
                                    {
                                        product.refer  = true;
                                    }
                                }
                            }

                            product.classCode = service.wc_classcode;
                        }

                        if(product.program.referenceString == 'bop' && service.bo_classcode && product.system.codekey == 'maverick')
                        {
                            if(result.bo == 'decline' || result.bo == 'invalid')
                            {
                                product.allowed     = false;
                                product.selected    = false;
                                product.quote       = false;
                                product.refer       = false;
                                //record rule that hid the product because of eligibility decline
                                var rule = { id:222, display: 'BO Declined during eligibility call cc:' + service.bo_classcode.classCode.toString(), type:999 , classcode: service.bo_classcode };
                                service.log_rule(rule);
                            }
                           
                            if(product.allowed && (result.bo != 'decline' || result.bo != 'invalid'))
                            {
                                product.allowed = true;
                                //record rule that hid the product because of eligibility decline
                                // product.rule = { id:222, display: 'BO Declined during eligibility call cc:' + bo_classcode , classcode: bo_classcode };
                                
                                if(result.bo == 'autoquote' || result.bo == 'quote')
                                {
                                    product.quote           = true;
                                    product.default_quote   = true;
                                    product.refer           = false;
                                }
                                else
                                {
                                    product.quote = false;

                                    if(result.bo == 'refer')
                                    {
                                        product.refer = true;
                                    }
                                }
                            }

                            product.classCode = service.bo_classcode;
                        }


                    service.run_hide_rules();
                    service.run_link_rules();
                    service.run_product_availability_rules();
                }

                ProductService.split_products();
                service.eligibility.classcode.complete = true;

            }
        };



		return service;

	}

	return RuleService;

});
