define('common/directive/productLabel',['require'],function(require) {
	"use strict";

	function ProductLabel() {
		return {
			restrict: "AE",
			scope: {
				productLabel: "=",
				autoWidth: "="
			},
			transclude: true,
			template: '<span class="{{ getLabelClass() }}"><span ng-transclude></span></span>',
			link: function( scope ) {
				scope.getLabelClass = function() {
					var labelClass = "label label-product";

					if( scope.productLabel ) {
						labelClass += " label-product-" + scope.productLabel.toLowerCase().replace(/\s+/g, "-");
					}

					if( scope.autoWidth ) {
						labelClass += " label-product-auto-width";
					}

					return labelClass;
				};
			}
		};
	}

	return ProductLabel;

});
