define('components/activityFeed/controller/ActivityFeedController',['require'],function(require) {
	"use strict";

	ActivityFeedController.$inject = ["$scope", "overmindScope", "ActivityFeedService", "ActivityFeedConfig", "MessageService", "$modal","$sce"];

	function ActivityFeedController( $scope, overmindScope, ActivityFeedService, ActivityFeedConfig, MessageService, $modal, $sce) {

		$scope.activityConfig = ActivityFeedConfig;
		$scope.items = {
			newer: [],
			older: [],
			size: 0,
			loaded: false
		};

		ActivityFeedService.getRecentAccountActivity().then(function(result) {
			$scope.items = result;
			$scope.items.size = $scope.items.newer.length + $scope.items.older.length;
			$scope.items.loaded = true;
		});

		$scope.isShowMoreVisible = function() {
			return $scope.activityConfig.showAll() && $scope.activityConfig.maxRows < $scope.items.size;
		};

		$scope.showMore = function() {
			$scope.activityConfig.maxRows += 30;
		};

		$scope.openMessageModal = function( messageReplyId ) {
			// the message reply id is a hyphen-delimited string formatted as $messageId-$insuredId
			var idParts = messageReplyId.split("-"),
				messageId = idParts[ 0 ],
				insuredId = idParts[ 1 ];

			MessageService.getMessage({system : 'maverick', messageId : messageId , insuredId : insuredId }).then(function( message ) {
				$modal.open({
					templateUrl: "app/components/message/modal/template/template.html",
					windowTemplateUrl: "app/components/message/modal/template/windowTemplate.html",
					resolve: {
						message: function() { return message; }
					},
					controller: "MessageModalController"
				});
			});
		};

		$scope.openGlobalSearch = function(){
			overmindScope.$broadcast('open-global-search', $scope.activityConfig.filters.keyword);
		};
	}

	return ActivityFeedController;

});
