define('components/widget/inland-marine/fine-item-limit/app',['require','components/widget/inland-marine/fine-item-limit/directive/fineItemLimit','components/widget/inland-marine/fine-item-limit/Controller/FineItemLimitController'],function(require) {
    "use strict";

    var fineItemLimit 		    = require("components/widget/inland-marine/fine-item-limit/directive/fineItemLimit"),
        FineItemLimitController 	= require("components/widget/inland-marine/fine-item-limit/Controller/FineItemLimitController");

    var component 					= angular.module("mPortal.components.widget.inland-marine.fine-item-limit", []);

    component.directive("fineItemLimit", fineItemLimit);
    component.controller("FineItemLimitController", FineItemLimitController);

    return component;

});
