define('components/accountSummary/quoteTable/directive/quoteTable',['require'],function(require) {
	"use strict";

	function QuoteTable() {
		return {
			restrict: "AE",
			scope: {
				quote: "=",
				account: "=",
				openTab: "=",
				setMessageView: "=",
				user: "=",
				openApplicationTab: "="
			},
			templateUrl: "app/components/accountSummary/quoteTable/template/template.html",
			controller: "QuoteTableController"
		};
	}

	return QuoteTable;

});
