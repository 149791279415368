define( 'training/Controller/RequestTrainingController',['require'],function( require ) {
    "use strict";

    RequestTrainingController.$inject = [ "$scope", "UserProfile"];

    function RequestTrainingController( $scope, UserProfile) {

        $scope.encodeMailText = function( mailText ) {
            var encodedText = mailText.replace(/\s/g,'%20');
            encodedText = encodedText.replace(/\#/g,'%23');
            return encodedText;
        };

       $scope.address = "customerservice2@markel.com" + "?";
        $scope.mailSubject = "mPortal Training";
        // %0D is line break
        $scope.mailBody =
            "Please Contact me for an mPortal Training!"+
            "%0D%0D\t\t\t\t1.\t\tName:\t" + UserProfile.name +
            "%0D%0D\t\t\t\t2.\t\tAgency:\t" + UserProfile.agencies[0].name + "\t-\t" + UserProfile.agencyId  +
            "%0D%0D\t\t\t\t3.\t\tPhone Number:\t" + UserProfile.phone;

        // Combine the content
        $scope.mailContent = "subject=" + $scope.encodeMailText( $scope.mailSubject ) + "&body=" +  $scope.encodeMailText( $scope.mailBody ) ;

        $scope.openMarkelContacts = function() {            
            window.open('https://www.markel.com/us/contact-search?state=' + UserProfile.defaultState);
        };
    }

    return RequestTrainingController;
});


