define('search/controller/AdvancedSearchController',['require'],function(require) {
	"use strict";

	AdvancedSearchController.$inject = ["$scope", "SearchService", "UserProfile"];

	function AdvancedSearchController( $scope, SearchService, UserProfile ) {
		$scope.uiState = {
            twoYearViewAvailable: true,
            isAccountsTabDefault: false
        };

        $scope.searchData = SearchService.getSearchData();
        $scope.user = UserProfile;
        
        if( !$scope.user.hasComponentPermissions( "twoYearView" ) ) {
            $scope.uiState.twoYearViewAvailable = false;
            $scope.uiState.isAccountsTabDefault = true;
        } else if ( $scope.user.isProlificMaverickAgent ) {
            $scope.uiState.isAccountsTabDefault = true;
            

        }
	}



	return AdvancedSearchController;

});
