define('components/marketedPrograms/detail/directive/marketedProgramDetail',['require'],function(require) {
	"use strict";

	MarketedProgramDetail.$inject = [];

	function MarketedProgramDetail() {
		return {
			restrict: "AE",
			scope: {
				program: "="
			},
			templateUrl: "app/components/marketedPrograms/detail/template/template.html",
			controller: "MarketedProgramDetailController"
		};
	}

	return MarketedProgramDetail;

});
