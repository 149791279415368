define('components/recentlyViewed/directive/recentlyViewed',['require'],function(require) {
	"use strict";

	RecentlyViewed.$inject = [];

	function RecentlyViewed() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/recentlyViewed/template/template.html",
			controller: "RecentlyViewedController"
		};
	}

	return RecentlyViewed;

});
