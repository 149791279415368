define('bundlePayment/controller/BundlePaymentController',['require'],function(require) {
    "use strict";

    BundlePaymentController.$inject = [ "$scope", "UserProfile", "BundlePaymentService", "$anchorScroll", "$routeParams", "$window", "$interval", "$timeout", "SharedData", "$sce", "maverickRoot", "iscRoot", "epayUrl" ];

    function BundlePaymentController( $scope, UserProfile, BundlePaymentService, $anchorScroll, $routeParams, $window, $interval, $timeout, SharedData, $sce, maverickRoot, iscRoot , epayUrl ) {
        $scope.uiState = {
            someVar: true
        };
	    $scope.processingWidth = 5;
	    $scope.paymentMessages = [];
	    $scope.iscTicket = {
		    id: 0,
		    accountId: "",
		    enterpriseId: 0,
		    ticketString: ""
	    };
	    $scope.Math=Math;
        // setup functions
        function show(segment, policyBreakdown) {
            $scope.uiPayment = false;
            $scope.uiInit = false;
            $scope.uiConfirm = false;
            $scope.uiProcessing = false;
            $scope.uiReceipt = false;
            $scope.uiCancelRecurring = false;
            $scope[ segment ] = true;
	        // load messageListener if uiConfirm was submitted and uiProcessing is displayed
	        if (segment === "uiProcessing") {
		        //$window.addEventListener("message", $scope.messageListener );
		        // todo-bc: fake loop for now
		        $scope.paymentProgress = 0;
		        var promise = $interval(function() {
			        //100%, cancel interval
			        if($scope.paymentProgress > 99)
				        $interval.cancel(promise);
			        else
				        $scope.paymentProgress = $scope.paymentProgress+1;  //increment progress
		        }, 100);
	        }
	        if (segment === 'uiReceipt') {
		        $scope.$apply();
	        }
        } // end - show function
        // strings for labels and messages
        $scope.uiPaymentLabels = {
            accountHeader : 'Account/Policy Information',
            accountNumber: 'Account Number',
            insuredName: 'Insured Name',
            policyNumber: 'Policy Number',
            renewalDeposit: 'Deposit on renewal of',
            status: 'Status',
            effectiveDate: 'Effective Date',
            currentDue: 'Current Due',
            policy: 'Current Due',
            active: 'Active',
            paymentOptions: 'Payment Options',
            poCurrent: 'Pay Current Due',
            poDueDate: 'Payment due date',
            poBalance: 'Pay Total Balance Due',
            poSpecifyAmount: 'Specify Payment Amount',
            poScheduleCalculator: 'Schedule Calculator',
            scInstructions: 'Select the latest Due Date to pay and then click Done to save results.',
            scAmount: 'Selected amount is',
            poSetupRecurring: 'Enroll in Automatic Payments',
            poEditRecurring: 'Manage Automatic Payments',
            poPayments: 'Projected Payment Schedule',
            billDate: 'Bill Date',
            cancelRecurring: 'Automatic Payment Cancellation',
            contactInformation: 'Contact Information',
            phoneNumber: 'Phone Number',
            paymentInformation: 'Payment Information',
            paymentMethod: 'Payment Method',
            previousMethod: 'Use previous payment method?',
            routingNumber: 'Routing Number',
            routingHelp: 'Find your bank routing number on a check or statement.',
            cardType: 'Card Type',
            cardNumber: 'Credit Card Number',
            cardExpiration: 'Credit Card Expiration date',
            ccv: 'Credit Card Verification Number',
            ccvHelp: $sce.trustAsHtml('The CVV Number ("Card Verification Value") on your credit card or debit card is a 3 digit number on VISA&reg;, MasterCard&reg; and Discover&reg; branded credit and debit cards typically on the back of the card after the card number.'),
            cvnHelp: $sce.trustAsHtml('The CVN Number ("Card Verification Number") on your American Express&reg credit card is a 4 digit number on the front of the card after the card number.'),
            useExistingAddress: 'Use existing address?',
            address1: 'Billing Address 1',
            address2: 'Billing Address 2',
            city: 'Billing City',
            state: 'Billing State',
            country: 'Billing Country',
            zip: 'Billing ZIP Code',
            paymentBtnText: 'Make Payment',
            cancelBtnText: 'Cancel',
            continueBtnText: 'Continue'
        };
        $scope.uiConfirmLabels = {
            header: 'Payment Details',
            instructions: 'Click the Submit button to complete your payment or use the link to Edit Payment Information.',
            customInstructions: 'Click the {{submit}} button to complete your payment or use the link to Edit Payment Information.',
            setupInstructions: 'Click the Submit button to complete your enrollment in Markel\'s Automatic payments, or use the link to Edit Payment Information.',
            customSetupInstructions: 'Click the {{submit}} button to complete your enrollment in Markel\'s Automatic payments, or use the link to Edit Payment Information.',
            updateInstructions: 'Click the Submit button to update your payment information or use the link to Edit Payment Information',
            customUpdateInstructions: 'Click the {{submit}} button to update your payment information or use the link to Edit Payment Information',
            contactInstructions: 'Click the Submit button to complete the update to your contact information or use the link to Edit Contact Information.',
            customContactInstructions: 'Click the {{submit}} button to complete the update to your contact information or use the link to Edit Contact Information.',
            paymentType: 'Payment Type',
            payerName: 'Payer Name',
            billingAddress: 'Billing Address',
            submit: 'Submit',
            authorize: 'Authorize',
            edit: 'Edit Payment Information',
            editContact: 'Edit Contact Information'

        };
        $scope.uiProcessingLabels = {
            procPayment: 'Processing Payment',
            proc: 'Processing',
            message1: 'Processing payment for',
            message2: 'in the amount of',
            please: 'Your payment/authorization is being processed. Please wait.  Using the back button or refreshing your browser could result in multiple payments/authorizations being processed'
        };
        $scope.uiReceiptLabels = {
            receiptHeader: 'Payment Receipt',
            confirmationHeader: 'Confirmation',
            receiptThank: 'Thank you for your payment.  Your payment has been applied.',
            confirmationThank: 'Your enrollment in the Markel Automatic Payment system was successful. Your profile was created and payment information stored for future use',
            confirmationThankNew: 'Your profile has been updated and payment information stored for future use.',
            cancelThank: 'Automatic Payments Enrollment has been cancelled.',
            transactionDateTime: 'Transaction Date/Time',
            confirmationNumber: 'Confirmation Number'

        };
        $scope.uiError = {
            errorLabel: 'Error',
            errorCodeLabel: 'Error Code'
        };
        $scope.error = {
                noAmountZero : 'Payment amount must be greater than zero (0)',
                noAmountMin : 'Payment amount must be at least ${{minimum}}',
                noAmountMax : 'Payment amount cannot exceed ${{maximum}}',
                acceptTerms: 'You must accept the terms and conditions',
                poRequired: 'Payment option is required.',
                emailRequired: 'Valid email address is required.',
                emailInvalid: 'Invalid email address. Please try again.',
                phoneRequired: 'Phone Number is required.',
                phoneInvalid: 'Invalid Phone Number. Please try again.',
                routingRequired: 'Valid Routing Number is required.',
                routingInvalid: 'Invalid Routing Number. Please try again.',
                accountRequired: 'Valid Account Number is required.',
                cardRequired: 'Valid credit card number is required.',
                cardTypeRequired: 'Valid credit card type is required.',
                cardExpiration: 'Card Expiration must be current or future month and year.',
                accountInvalid: 'Invalid Account Number.  Please try again.',
                ccvRequired: 'CCV is required.',
                ccvInvalid: 'Invalid CCV format. Please try again.',
                previousMethod: 'Pick a previous payment method',
                firstNameRequired: 'First name is required.',
                lastNameRequired: 'Last name is required.',
                corpNameRequired: 'Corporation Name is Required.',
                address1: 'Billing Address is required.',
                cityRequired: 'Billing City is required.',
                stateRequired: 'Billing State is required.',
                zipRequired: 'Billing ZIP Code is required.',
                zipInvalid: 'Invalid ZIP Code. Please try again.',
                mainValidationMessage: 'We are unable to proceed with your payment.  Please review the errors below and try again. ',
                defaultDontAcceptPaymentsReasonMessage: 'Account information cannot be retrieved at this time.  Please try again later.'
            };


        $scope.ccFormat = new RegExp("^[0-9]{13,16}$");
        $scope.ccvFormat = new RegExp("^[0-9]{3}$");   // credit card verification other than AMEX
        $scope.cvnFormat = new RegExp("^[0-9]{4}$");   // AMEX verification number
        $scope.routingNumberFormat = new RegExp("^[0-9]{9}$");
        $scope.numberFormat = new RegExp("^[0-9]*$");
        $scope.decimalTest = new RegExp("^\\d+.?\\d{3,}$");
        $scope.decimalExec = new RegExp("^(\\d+.?\\d{0,2})");
        $scope.phoneNumberFormat = new RegExp("^[- ()]*[1]?[- ()]*[0-9]{3}[- ()]*[0-9]{3}[- ()]*[0-9]{4}[- ()]*$");
        $scope.zipFormat = new RegExp("(^\\d{5}(\\W?\\d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\\d{1}[a-z,A-Z]{1} *\\d{1}[a-z,A-Z]{1}\\d{1}$)");
        $scope.dt = '2013-12-18T15:29:53Z';

        var merchantData = [];
        var policyBreakdown = [];
        var paymentSystemBreakdown = [];

	    $scope.validateSpecifyAmountPayment = function() {
		    // reset each time
		    this.pmtForm.$setValidity( "invalidValue", true );
		     if (!this.policy.isRenewalDownPayment && (this.pmtForm.amount.$modelValue * 1) > this.policy.balance) {
			     this.pmtForm.$setValidity( "invalidValue", false );
		     }
		    // renewal is a special case - this.policy.isRenewalDownPayment
		    if (this.policy.isRenewalDownPayment && (this.pmtForm.amount.$modelValue * 1) < this.policy.balance) {
			    this.pmtForm.$setValidity( "invalidValue", false );
		    }

	    };

	    $scope.precisionRound = function(number, precision) {
		    var factor = Math.pow(10, precision);
		    return Math.round(number * factor) / factor;
	    };

        // validation of at least one entry - change handler for specified amount inputs (variable)
        $scope.changeSpecifiedAmount = function() {
            var sum = 0.00;
            var i = 0;
            var policyCount = $scope.data.itemsDue.length;

            $scope.paymentPolicyText = '';
            var paymentPolicyTextIndex = 0;
            var policyBreakdown = [];
            $scope.confirmNotificationMessages = [];

            $scope.totalSpecifiedAmount = sum;
            for (; i<policyCount; i++) {
                if ($scope.data.itemsDue[i].payment && isNaN($scope.data.itemsDue[i].payment)) {
                    $scope.data.itemsDue[i].payment = String($scope.data.itemsDue[i].payment).replace(/([$?&,@#%^*()])*([a-z]*[A-Z]*)/mg,'');
                }

                if ($scope.data.itemsDue[i].payment && !isNaN($scope.data.itemsDue[i].payment) && $scope.decimalTest.test($scope.data.itemsDue[i].payment) ) {
                    $scope.data.itemsDue[i].payment = $scope.decimalExec.exec($scope.data.itemsDue[i].payment)[0];
                }
	            if( $scope.data.itemsDue[i].paymentIsRequired ){
		            sum += Number($scope.data.itemsDue[i].balance);
		            policyBreakdown.push( {
			            paymentSystem: $scope.data.itemsDue[i].paymentSystem,
			            policyNumber: $scope.data.itemsDue[i].policyNumber,
			            amount: Math.round( $scope.data.itemsDue[i].balance * 1e2 ) / 1e2
		            } );
		            merchantData.push( '"' + $scope.data.itemsDue[i].policyNumber + '" : ' + Number($scope.data.itemsDue[i].balance).toFixed(2) + '' );
	            }
                if ($scope.data.itemsDue[i].payment && !isNaN($scope.data.itemsDue[i].payment) && $scope.data.itemsDue[i].payment > 0 ) {
                    sum += Number($scope.data.itemsDue[i].payment);

                    // build out merchantData and policyBreakdown structures for each policy payment that has a value
                    if($scope.data.itemsDue[i].payment) {
                        policyBreakdown.push( {
	                        paymentSystem: $scope.data.itemsDue[i].paymentSystem,
	                        policyNumber: $scope.data.itemsDue[i].policyNumber,
	                        isRenewal: $scope.data.itemsDue[i].isRenewalDownPayment,
	                        amount: Math.round( $scope.data.itemsDue[i].payment * 1e2 ) / 1e2
                        } );
                        merchantData.push( '"' + $scope.data.itemsDue[i].policyNumber + '" : ' + Number($scope.data.itemsDue[i].payment).toFixed(2) + '' );
                        $scope.paymentPolicyText += ( paymentPolicyTextIndex > 0 ) ? ', ': '';
                        paymentPolicyTextIndex++;
                        $scope.paymentPolicyText += $scope.data.itemsDue[i].policyNumber;
                    }
                }
                //build the confirmationMessagesForPolicies
                if($scope.data.itemsDue[i].paymentWarning) {
                    $scope.confirmNotificationMessages.push($scope.data.itemsDue[i].paymentWarning);
                }
                else {
                    //add confirmation messages for current payment != current due for ICON policies only
                    if($scope.data.itemsDue[i].paymentSystem  == 'ePay-ICON' && $scope.data.itemsDue[i].payment < $scope.data.itemsDue[i].amountDue) {
                        $scope.confirmNotificationMessages.push($scope.data.itemsDue[i].policyNumber + ': The payment amount is less than current due and could affect your policy status. If current due is not paid by due date, then your policy(s) are subject to cancellation for nonpayment.');
                    }
                }

            }
            // set policyBreakDown in scope so we can use it in the submit method
            $scope.policyBreakdown = policyBreakdown;

            if(!isNaN(Number(sum)))
                $scope.totalSpecifiedAmount = Number(sum).toFixed(2);

        };

        $scope.eventPaymentFormSubmitted = function() {
            //this.pmtForm.totalSpecifiedAmount.$setValidity('required', true);

            // if this is specified, validate the min/max on all policies
            if(this.pmtForm.paymentOption.$viewValue=='specifyAmount') {
                var i = 0;
                var policyCount = $scope.data.itemsDue.length;
	            this.pmtForm.totalSpecifiedAmount.$setValidity('required', true);

                if(this.pmtForm.totalSpecifiedAmount.$viewValue == null)
                    this.pmtForm.totalSpecifiedAmount.$viewValue = 0;

	            // at least validate that total specified amount is > 0
	            if(this.pmtForm.totalSpecifiedAmount.$viewValue <= 0) {
		            this.pmtForm.totalSpecifiedAmount.$setValidity('required', false);
	            }

            }

            // make sure the expirations aren't being validated if they aren't relevant
            $scope.paymentMethodChanged();

            // set Insured Name based on first account name in the array
            $scope.paymentForm.accountName = $scope.data.accounts[0].accountName;

            $scope.paymentForm.amount = 0;

            switch ( $scope.paymentForm.paymentOption ) {
                case 'currentDue':
                    merchantData = [];
                    policyBreakdown = [];
                    $scope.paymentPolicyText = '';
                    $scope.confirmNotificationMessages = [];
                    $scope.paymentForm.amount = $scope.data.totalCurrentDue;
                    angular.forEach( $scope.data.itemsDue, function( item, index ) {
                        merchantData.push( '"' + item.policyNumber + '" : ' + item.amountDue + '' );
                        policyBreakdown.push( {
                            paymentSystem: item.paymentSystem,
                            policyNumber: item.policyNumber,
                            isRenewal: item.isRenewalDownPayment,
                            amount: item.amountDue
                        } );
                        $scope.paymentPolicyText += ( index > 0 ) ? ', ': '';
                        $scope.paymentPolicyText += item.policyNumber;
                        if(item.paymentWarning) {
                            $scope.confirmNotificationMessages.push(item.paymentWarning);
                        }
                    } );
                    // flatten policyBreakDown to paymentSystemBreakdown
                    paymentSystemBreakdown = $scope.sumByPaymentSystem( policyBreakdown, $scope.paymentForm );

	                $scope.policyBreakdown = policyBreakdown;

                    break;
                case 'payBalance':
                    merchantData = [];
                    policyBreakdown = [];
                    $scope.paymentPolicyText = '';
                    $scope.confirmNotificationMessages = [];
                    $scope.paymentForm.amount = $scope.data.totalBalance;
                    angular.forEach( $scope.data.itemsDue, function( item, index ) {
                        policyBreakdown.push( {
                            paymentSystem: item.paymentSystem,
                            policyNumber: item.policyNumber,
                            amount: item.balance,
                            isRenewal: item.isRenewalDownPayment
                        } );
                        merchantData.push( '"' + item.policyNumber + '" : ' + item.balance + '' );
                        $scope.paymentPolicyText += ( index > 0 ) ? ', ': '';
                        $scope.paymentPolicyText += item.policyNumber;
                        if(item.paymentWarning) {
                            $scope.confirmNotificationMessages.push(item.paymentWarning);
                        }
                    } );
                    // flatten policyBreakDown to paymentSystemBreakdown
                    paymentSystemBreakdown = $scope.sumByPaymentSystem( policyBreakdown, $scope.paymentForm );

	                $scope.policyBreakdown = policyBreakdown;

                    break;
                case 'specifyAmount':
                    $scope.paymentForm.amount = $scope.totalSpecifiedAmount;
                    // flatten policyBreakDown to paymentSystemBreakdown
                    paymentSystemBreakdown = $scope.sumByPaymentSystem( $scope.policyBreakdown, $scope.paymentForm );
                    break;
            }
            $scope.paymentForm.numberOfPolicies = merchantData.length;
            if ( merchantData.length > 1) {
                $scope.paymentPolicyDisplayText = 'Policy #\'s: ' + $scope.paymentPolicyText;
            } else if ( merchantData.length == 1 ) {
                $scope.paymentPolicyDisplayText = 'Policy #: ' + $scope.paymentPolicyText;
            }
            // verify that we have an amount > 0
            if(!this.pmtForm.amount) {
                this.pmtForm.amount.$setValidity( 'required', true );
            }

            // verify that the amount is not outside the min and max
            if(this.pmtForm.amount < $scope.data.minimumPayment || this.pmtForm.amount > $scope.data.maximumPayment) {
                this.pmtForm.amount.$setValidity( 'required', true );
            }
	        // pmtForm$valid and $invalid should be set via bubble up when fields are flagged as invalid but
	        // these props are always showing null for some reason - found another way by evaluating the $error array
            if (Object.keys($scope.pmtForm.$error).length) {
                $scope.paymentError = 'We are unable to proceed with your payment.  Please review the errors below and try again.';
                $anchorScroll();
                return false;
            }

	        // store the breakdown for use when user submits the confirmation page
	        SharedData.setProperty( 'paymentSystemBreakdown', paymentSystemBreakdown );

            // overload show function with policyBreakdown to allow passing to comparePaymentToRecentPayments
            show('uiConfirm', policyBreakdown);
        };

        $scope.eventConfirmFormSubmitted = function( ) {
	        // make payments to one or more payment systems - ISC, ePay-BC9, ePay-mPolicy, and ePay-ICON
	        var paymentSystemBreakdown = SharedData.getProperty( 'paymentSystemBreakdown');
	        // set up shared storage for number of payment systems to process/expect responses from
	        SharedData.setProperty( 'paymentSystemPendingResponses', 0 );
	        // start the listener before making the postMessage calls
	        $window.addEventListener("message", $scope.messageListener );

	        // loop and post message to each payment system iframe in the array
	        for ( var i = 0; i < paymentSystemBreakdown.length; i++ ) {
		        // set the correct iframe id based on the payment system
		        var expr = paymentSystemBreakdown[i].paymentSystem;
		        var iFrameID = '';
		        var theMessage = '';
		        switch (expr) {
			        case 'ePay-mPolicy':
				        iFrameID = 'ePayMpolicyIframe';
				        break;
			        case 'ePay-BC9':
			        	iFrameID = 'ePayBC9Iframe';
			        	break;
			        case 'ISC':
				        iFrameID = 'iscIframe';
				        break;
			        case 'ePay-ICON':
				        iFrameID = 'ePayIconIframe';
				        break;
			        default:
				        console.log('Data Error - Unknown Payment System - ' + expr + '!');
		        }
		        theMessage = JSON.stringify(paymentSystemBreakdown[i]);
		        // increment response counter
		        SharedData.setProperty( 'paymentSystemPendingResponses', SharedData.getProperty( 'paymentSystemPendingResponses' ) + 1 );
		        document.getElementById(iFrameID).contentWindow.postMessage(theMessage, '*');
	        }
	        //document.getElementById('ePayiframe').contentWindow.postMessage(JSON.stringify(paymentData), '*');

	        show('uiProcessing');

        };

	    $scope.messageListener = function( event ) {
		    console.log( event );
		    var data;
		    var messages = [];

		    try {
			    data = JSON.parse( event.data );
		    } catch ( err ) {
			    console.log( err );
			    return;
		    }
		    // polyfill Object.Entries for IE of course
		    if (!Object.entries)
			    Object.entries = function( obj ){
				    var ownProps = Object.keys( obj ),
						    i = ownProps.length,
						    resArray = new Array(i); // preallocate the Array
				    while (i--)
					    resArray[i] = [ownProps[i], obj[ownProps[i]]];

				    return resArray;
			    };
			// make environment specific for multiple payment systems
		    var acceptedDomains = [];
		    var paymentSystems = $scope.data.paymentSystems;
		    var paymentSystemsArray = Object.entries(paymentSystems)
		    for (var index = 0; index < paymentSystemsArray.length; index++) {
			    var url = paymentSystemsArray[index][1].paymentUrl;
			    if( typeof url === "string" ){
				    var parts = url.split('/');
				    var domain = parts[0] + '//' + parts[2];

				    acceptedDomains.push(domain);
			    }
		    }
		    var iscDomain = iscRoot.replace( /\/$/, "");
		    // add iscRoot manually (not in api paymentSystems list)
		    acceptedDomains.push(iscDomain);
		    console.log(acceptedDomains);
		    if (acceptedDomains.indexOf( event.origin ) === -1) {
			    console.log('Invalid origin: ' + event.origin);
			    return;
		    }

		    // store the returned message data
		    $scope.paymentMessages.push( data );
		    // decrement response counter
		    SharedData.setProperty( 'paymentSystemPendingResponses', SharedData.getProperty( 'paymentSystemPendingResponses' ) - 1 );
		    // once all pending responses are received update the scope with returned messages and call show with uiReceipt segment
		    if ( SharedData.getProperty( 'paymentSystemPendingResponses' ) === 0 ) {
			    console.log( '$scope.paymentMessages' );
			    console.log( $scope.paymentMessages );
			    show('uiReceipt');
		    }
	    };

        $scope.sumByPaymentSystem = function( policyBreakdown, paymentForm ) {
            var result = [];
	        // polyfill Object.assign for IE
	        if (typeof Object.assign != 'function') {
		        // Must be writable: true, enumerable: false, configurable: true
		        Object.defineProperty(Object, "assign", {
			        value: function assign(target, varArgs) { // .length of function is 2
				        'use strict';
				        if (target == null) { // TypeError if undefined or null
					        throw new TypeError('Cannot convert undefined or null to object');
				        }

				        var to = Object(target);

				        for (var index = 1; index < arguments.length; index++) {
					        var nextSource = arguments[index];

					        if (nextSource != null) { // Skip over if undefined or null
						        for (var nextKey in nextSource) {
							        // Avoid bugs when hasOwnProperty is shadowed
							        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
								        to[nextKey] = nextSource[nextKey];
							        }
						        }
					        }
				        }
				        return to;
			        },
			        writable: true,
			        configurable: true
		        });
	        }

            policyBreakdown.reduce(function (res, value) {
                if (!res[value.paymentSystem]) {
                    res[value.paymentSystem] = {
                        paymentSystem: value.paymentSystem,
                        amount: 0,
                        policyCount: 0,
                        policies: []
                    };
	                // Object.assign pushes the form field keys into the structure
	                // so we can send them in the message to the iframe
                    Object.assign(res[value.paymentSystem], paymentForm);
	                // clear the form amount variable so the incrementation is based on zero to start below
	                res[value.paymentSystem].amount = 0;
                    result.push(res[value.paymentSystem]);
                }
                if(value.policyNumber !== 'BILLING FEES') {
	                res[value.paymentSystem].amount += $scope.precisionRound( value.amount, 2 );
	                res[value.paymentSystem].policyCount += 1;
	                res[value.paymentSystem].policies.push( {
		                policyNumber: value.policyNumber,
		                amount: value.amount,
		                isRenewal: value.isRenewal
	                } );
                }
                return res;
            }, {});
	        // delete any records that have total payment <= 0 and fix up the policyBreakdown to reflect that as well?
            return result;
        };

        $scope.paymentMethodChanged = function() {

            //then override them for checks.
            if (this.pmtForm.paymentMethod.$viewValue!='card') {
                // set validity of card date controls to valid
                this.pmtForm.card_expirationMonth.$setValidity('expiryValid' ,true);
                this.pmtForm.card_expirationYear.$setValidity('expiryValid', true);
                this.pmtForm.bill_to_phone.$setViewValue(this.pmtForm.bill_to_phone.$viewValue);
            }
            else
            {
                this.pmtForm.card_expirationMonth.$setViewValue(this.pmtForm.card_expirationMonth.$viewValue);
                this.pmtForm.card_expirationYear.$setViewValue(this.pmtForm.card_expirationYear.$viewValue);
                this.pmtForm.bill_to_phone.$setValidity('pattern', true);
                $scope.paymentForm.chk_corporateChecking = false;
            }

        };

        $scope.eventEditPaymentClicked = function() {
            $scope.submitted = false;
            show('uiPayment');
            $anchorScroll();
        };

        // data endpoint - http://portal-dev.markelinsurance.com:8081/api/v1/account/ID3243/payment-system

        $scope.user = UserProfile;

        $scope.account = {
            config: { maximumPayment: 100 }
        };
        var accountId = $routeParams.accountId;
        show( 'uiInit' );

        BundlePaymentService.getPaymentData( accountId ).then(function( result ) {
            $scope.data = result;

	        if ( $scope.data.paymentSystems['ePay-mPolicy'] ) 
            {
                var mpolicy_url = $scope.data.paymentSystems['ePay-mPolicy'].paymentUrl;

		        $scope.ePayMpolicyIframeSrc = $sce.trustAsResourceUrl(mpolicy_url);
	        }

	        if ( $scope.data.paymentSystems['ePay-ICON'] ) 
            {
                 var icon_url = $scope.data.paymentSystems['ePay-ICON'].paymentUrl;

                $scope.ePayIconIframeSrc = $sce.trustAsResourceUrl(icon_url);
	        }

	        if ( $scope.data.paymentSystems['ePay-BC9'] ) 
            {
                var bc_url = $scope.data.paymentSystems['ePay-BC9'].paymentUrl;

                $scope.ePayBC9IframeSrc = $sce.trustAsResourceUrl(bc_url);
	        }

	        if ( $scope.data.paymentSystems['ISC'] ) 
            {
                var isc_url = $scope.data.paymentSystems['ISC'].paymentUrl;

                $scope.iscIframeSrc = $sce.trustAsResourceUrl(isc_url);
	        }
            else if ( $scope.data.paymentSystems['ISC-ticket'] ) 
            {
		        $scope.iscFormAction = $sce.trustAsResourceUrl( iscRoot + '?event=action.handleLogin' );
		        $scope.iscTicket = $scope.data.paymentSystems['ISC-ticket'];

		        $timeout( function(){
			        angular.element("#iscForm").submit();
		        } );
	        }

            show('uiPayment');
        });

	    $scope.printReceipt = function () {
		    window.print();
	    };

	    $scope.closeReceipt = function () {
		    // delete the iframes on the page and go back to get to where the user came from
		    $("#ePayMpolicyIframe" ).remove();
		    $("#ePayIconIframe" ).remove();
		    $("#ePayBC9Iframe" ).remove();
		    $("#iscIframe" ).remove();
		    window.history.back();
	    };

        // strings for labels, error messages, etc... global and section specific
        $scope.global = {
            policy: 'Policy',
	        payment: 'Payment',
            account: 'Account',
            amount: 'Amount',
            balance: 'Balance',
            dueDate: 'Due Date',
            done: 'Done',
            cancel: 'Cancel',
            email: 'Email Address',
            emailAddendum: '(Payment Purpose Only)',
            creditCard: 'Credit/Debit Card',
            bankAccount: 'Bank Account',
            eCheck: 'Electronic Check',
            name: 'Name',
            jan: 'January',
            feb: 'February',
            mar: 'March',
            apr: 'April',
            may: 'May',
            jun: 'June',
            jul: 'July',
            aug: 'August',
            sep: 'September',
            oct: 'October',
            nov: 'November',
            dec: 'December'
        };

    }
    return BundlePaymentController
});

