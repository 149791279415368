define('components/specialtyApplication/questions/config/widgets/InlandMarineWidget',['require'],function(require) {
    "use strict";

    var widget = {};

    widget.getStructure = function() {

        return {

            /*Misc - Blanket*/
            "IM MA Blanket" :{ wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 1 },
            "IM MA Blanket Item Limit" : { wrapper: ["verticalWideFullRowPointIM" ], orderNumber: 2},
            "IM MA Blanket Occ Limit" : { wrapper: ["verticalWideFullRowPointIM" ], orderNumber: 3 },
            "IM MA Blanket Ded" : { wrapper: ["verticalWideFullRowPointIM" ], orderNumber: 4 },
            "IM MA Blanket Desc" : { wrapper: ["verticalWideFullRowPointIM" ], orderNumber: 5 },
            "IM Blanket Item Limit" :{ wrapper: [ "verticalWideFullRowPointIM" ], wrapperOverride: true, orderNumber: 6 },

            /*Misc - Scheduled*/
            "IM MA Basic" :{ wrapper: [ "bootstrapTooltipIcon" ], wrapperOverride: true, orderNumber: 7 },
            "IM MA Basic Ded" :{ wrapper: [ "verticalWideFullRowPointIM" ], wrapperOverride: true, orderNumber: 8 },
            "ScheduleInfo": {
                wrapper: [ "schedule-item-wrapper" ], orderNumber: 9,
                fieldGroup: {
                    "IM MA Basic Item Num" : {  wrapper: [ "defaultBootstrapLabel", "defaultBootstrapHasError" ], orderNumber: 1, data: { saveBroadcast: true }},
                    "IM Basic Item Number" : {  wrapper: [ "scheduleNumber", "scheduleNumberError" ], wrapperOverride: true, orderNumber: 2, data: { savePointOnly: true }},
                    "IM Basic Item Desc": { wrapper: [ "scheduleDescription", "scheduleDescriptionError" ], orderNumber: 3, data: { savePointOnly: true } },
                    "IM Basic Item Limit": { wrapper: [ "scheduleLimit", "scheduleLimitError" ], wrapperOverride: true, orderNumber: 4, data: { savePointOnly: true } }
                }
            },
            "IM MA Basic Sum" :{ wrapper: [ "verticalWideFullRowPointIM" ], wrapperOverride: true, orderNumber: 10 }

        };
    };

    widget.configureFormly = function( formlyConfig, formlyApiCheck ) {

        formlyConfig.setWrapper({
            name: "schedule-item-wrapper",
            template: '<div style="padding-left:30px; font-weight: bold" blanket-item-limit model="model" options="options" quantity-control-id="\'itemNumberQuantity\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "verticalWideFullRowPointIM",
            template:
                '<label style="display:inline !important; width:55%;float:left;margin-bottom:1%;margin-left: 35px;font-weight: bold;" for="{{ id }}" class="control-label">{{ to.label }}' + '</label>' +
                '<formly-transclude></formly-transclude>'
        });

        formlyConfig.setWrapper({
            name: "labelDatapoint1",
            template:
                '<formly-transclude></formly-transclude>'
        });

        formlyConfig.setWrapper({
            name: "scheduleLimit",
            template: '<div class="scheduleLimit" style="width: 112px;">' +
                '<formly-transclude></formly-transclude>' +
                '<i ng-if="to.help" class="glyphicon glyphicon-question-sign field-help" ' +
                'popover="{{ to.help }}" ' +
                'popover-trigger="mouseenter" ' +
                'popover-placement="bottom" ' +
                'tooltip-class="custom-popover"' +
                '></i>' +
                '</div>'
        });

        formlyConfig.setWrapper({
            name: "scheduleLimitError",
            overwriteOk: true,
            template:
                '<div class="span1 form-group scheduleLimitError" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
                '<formly-transclude></formly-transclude>' +
                '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
                '<div>' +
                '<p class="scheduleLimitErr">{{ options.data.validationMessage }}</p>' +
                '</div>' +
                '</div>' +
                '</div>'
        });

        formlyConfig.setWrapper({
            name: "scheduleNumber",
            template:
                '<div class="scheduleNumber" style="width: 80px;">' +
                '<formly-transclude></formly-transclude>' +
                '<i ng-if="!(to.help)" class="glyphicon glyphicon-question-sign field-help" ' +
                'popover="{{ to.help }}" ' +
                'popover-trigger="mouseenter" ' +
                'popover-placement="bottom" ' +
                'tooltip-class="custom-popover"' +
                '></i>' +
                '</div>'
        });

        formlyConfig.setWrapper({
            name: "scheduleNumberError",
            overwriteOk: true,
            template:
                '<div class="span1 form-group scheduleNumberError" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
                '<formly-transclude></formly-transclude>' +
                '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
                '<div>' +
                '<p class="scheduleNumberError">{{ options.data.validationMessage }}</p>' +
                '</div>' +
                '</div>' +
                '</div>'
        });

        formlyConfig.setWrapper({
            name: "scheduleDescription",
            template: '<div class="scheduleDescription">' +
                '<formly-transclude></formly-transclude>' +
                '</div>'
        });

        formlyConfig.setWrapper({
            name: "scheduleDescriptionError",
            overwriteOk: true,
            template:
                '<div class="span1 form-group scheduleDescriptionError" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
                '<formly-transclude></formly-transclude>' +
                '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
                '<div>' +
                '<p class="scheduleDescError">{{ options.data.validationMessage }}</p>' +
                '</div>' +
                '</div>' +
                '</div>'
        });

    };

    return widget;


});
