define('components/specialtyApplication/search/directive/search',['require'],function(require) {
	"use strict";

	search.$inject = [];

	function search() {
		return {
			restrict: "AE",
			templateUrl: "app/components/specialtyApplication/search/template/template.html",
			controller: "searchController"
		};
	}

	return search;

});
