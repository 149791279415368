define( 'components/specialtyApplication/submit/programDetails/bop/controller/BopController',['require'],function( require ) {
	"use strict";

	BopController.$inject = [ "$scope", "SubmissionStore", "ApplicationStateStore" ];

	function BopController( $scope, SubmissionStore, ApplicationStateStore ) {

		$scope.policyDatapointReferences = [
			"Aggregate Limit",
			"Medical Expenses",
			"Property Deductible",
			"PD Liability Deductible"
		];

		$scope.optionalEndorsementReferences = [
			"Aggregate Limit of Ins",
			"Barber Shops",
			"Beauty Salon",
			"Condo Coverage Optional",
			"Contractors",
			"Damage to Premises",
			"Designated Const Proj",
			"Ecommerce",
			"Employee Benefits",
			"Employee Dishonesty",
			"Employee Dishonesty Loc",
			"EPLI",
			"Equipment Breakdown",
			"Food Contamination",
			"Hired Auto",
			"Liquor Liability",
			"Loss Payable",
			"Mortgage Holder",
			"Optical and Hearing Aid",
			"Ordinance and Law",
			"Pesticide",
			"Photography",
			"Policy Damage to Premises",
			"Primary and Noncontributory",
			"Restaurants",
			"Spoilage",
			"StopGap",
			"Waiver of Transfer"
		].sort( function( a, b ) {
				return a.toLowerCase().localeCompare( b.toLowerCase() );
		}); // Preserves the order in case some are added or removed in the future

		$scope.programDetails = SubmissionStore.programs.filter( function ( program ) { return program.referenceString  === 'bop' } )[ 0 ];

		var effectiveDatePoint = {
			reportLabel: "Effective Date",
			value: $scope.programDetails.effectiveDate
		};
		$scope.policyDatapoints = [];
		$scope.buildings = [];
		$scope.optionalEndorsements = [];

		$scope.parsePolicyDatapoints = function() {
			$scope.policyDatapoints = ApplicationStateStore.findDatapointsByReferences( $scope.policyDatapointReferences );
			$scope.policyDatapoints.unshift( effectiveDatePoint );
		};

		$scope.parseBuildings = function() {
			ApplicationStateStore.sortEnumeratedDatapoints( [ "Building Description", "Class Code Description ID" ] );
			var buildingDescs = ApplicationStateStore.getEnumeratedDatapoints( "Building Description" );
			var buildingClassCodeDescs = ApplicationStateStore.getEnumeratedDatapoints( "Class Code Description ID" );
			for( var b = 0; b < buildingDescs.length; b++ ) {
				$scope.buildings.push(
					{
						description: buildingDescs[ b ].value,
						classCode: buildingClassCodeDescs[ b ].value.name
					}
				)
			}
		};

		$scope.parseOptionalEndorsements = function() {
			var storedPoints = ApplicationStateStore.findDatapointsByReferences( $scope.optionalEndorsementReferences );
			for( var e = 0; e < storedPoints.length; e++ ) {
				if( storedPoints[e].value && $scope.optionalEndorsements.indexOf( storedPoints[e].reportLabel ) == -1 ) {
					$scope.optionalEndorsements.push( storedPoints[e].reportLabel );
				}
			}
		};

		$scope.parseData = function() {
			$scope.parsePolicyDatapoints();
			$scope.parseBuildings();
			$scope.parseOptionalEndorsements();
		};

		$scope.parseData();

	}

	return BopController;

});
