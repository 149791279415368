define('components/accountSummary/accountList/directive/accountList',['require'],function(require) {
    "use strict";

    function AccountList() {
        return {
            restrict: "AE",
            scope: {
                accounts: "=",
                openTab: "=",
                setMessageView: "=",
                user: '='
            },
            templateUrl: "app/components/accountSummary/accountList/template/template.html",
            controller: "AccountListController"
        };
    }

    return AccountList;

});
