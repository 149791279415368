define('components/applicationActions/directive/applicationActions',['require'],function(require) {
    "use strict";

    ApplicationActions.$inject = [];

    function ApplicationActions() {
        return {
            restrict: "AE",
            scope: {
                store: "="
            },
            templateUrl: "app/components/applicationActions/template/template.html",
            controller: "ApplicationActionsController"
        };
    }

    return ApplicationActions;

});
