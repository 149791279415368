define('footer/controller/FooterController',['require'],function(require) {
	"use strict";

	FooterController.$inject = ["$scope", "overmindScope", "NavService", "UserProfile"];

	function FooterController( $scope, overmindScope, NavService, UserProfile ) {
		$scope.initialized = false;
		// because route changes are triggered outside of this controller, we need to watch
		// overmindScope for route changes and then trigger a digest.
		overmindScope.$on("$routeChangeSuccess", function() {
			$scope.$apply();
		});

		$scope.user = UserProfile;
		$scope.isActive = NavService.isActive;
		$scope.layout = NavService.getLayout();
		$scope.timestamp = new Date();

		$scope.initialized = true;
	}

	return FooterController;

});
