define( 'components/applicationData/applicationInfo/directive/applicationInfo',['require'],function( require ) {
    "use strict";

    function ApplicationInfo() {
        return {
            restrict: "AE",
            scope: {
                application: "=",
                context: "="
            },
            templateUrl: "app/components/applicationData/applicationInfo/template/template.html",
            controller: "ApplicationInfoController"
        };
    }

    return ApplicationInfo;

});
