define('components/specialtyApplication/questions/config/widgets/WindStorm',['require'],function (require) {
    "use strict";

    var widget = {};

    widget.getStructure = function () {

        return {
            "WSDataPoint": {
                wrapper: ["twoPointRow"], fieldGroup:
                    {

                        "Windpool Eligible": {wrapper: ["horizontalBox", "span4HalfRowError"], type: 'oeReadonly'},
                        "Windstorm Deductible Excluded": {wrapper: ["horizontalBox", "span4HalfRowError"]},
                        "Windstorm Ded Percent Lookup Value": {wrapper: ["horizontalBox", "span4HalfRowError"]}
                    }
            }
        }
    };

    return widget;

});
