define('bundlePayment/filter/MaskAccountFilter',['require'],function(require) {
	"use strict";

	MaskAccountFilter.$inject = [];

	function MaskAccountFilter() {
		return function(input) {
			var out = "";
			if(input)
			{
				var out = "";
				var size = input.length;
				var i = 0;
				for(;i<size-4;i++) {
					out += "x";
				}
				i = 3;
				for(;i>=0;i--) {
					out += input.charAt(size-i-1);
				}
			}
			return out;
		};
	}

	return MaskAccountFilter;

});
