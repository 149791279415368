define( 'components/claimDetail/insuredClaimInfo/controller/InsuredClaimInfoController',['require'],function( require ) {
    "use strict";

    InsuredClaimInfoController.$inject = [ "$scope", "AccountService","$q","moment" ];

    function InsuredClaimInfoController( $scope, AccountService, $q, moment ) {

        $scope.claimsByYear = {};
        $scope.claimYears = [];
        $scope.getClaims = function (){
            var promiseArray = [
                AccountService.getClaimDetails($scope.claim.insuredID,$scope.claim.insuredName)
            ];

            $q.all(promiseArray).then(function (results) {
                $scope.claims = results[0];
                if($scope.claims ) {
                    $scope.claimsByYear = $scope.claims.reduce(function( grouped, claim ) {
                        var effectiveYear = moment( claim.claimDate, "MM/DD/YYYY" ).year();

                        if( !grouped[ effectiveYear ] ) {
                            grouped[ effectiveYear ] = [];
                            $scope.claimYears.push( effectiveYear );
                        }

                        grouped[ effectiveYear ].push( claim );

                        return grouped;
                    }, {});
                    $scope.claimYears.sort().reverse()
                }

            });

        }

        $scope.getClaims();

    }

    return InsuredClaimInfoController;

});
