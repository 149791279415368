define('components/specialtyApplication/questions/config/widgets/OperationsWidget',['require'],function(require){

    "use strict";

    var widget={};

    widget.getStructure = function(){
        return {

            "HostShowsEventsSection"   : { wrapper: ["event-info-wrapper"], fieldGroup: {
                "Host Shows Events"         :{ wrapper: ["hideGroup"],orderNumber: 1, data: { saveBroadcast: true }},
                "Event Start Date"          :{ wrapper: ["EventInfoStartDate", "EventInfoRowErrorStartDate"],orderNumber: 2, data: { savePointOnly: true }, wrapperOverride: true},
                "Event End Date"            :{ wrapper: ["EventInfoEndDate", "EventInfoRowErrorEndDate"],orderNumber: 3, data: { savePointOnly: true }, wrapperOverride: true},
                "Event Estimated Date"      :{ wrapper: ["EventInfoEstimatedDate", "EventInfoRowErrorEstimatedDate"],orderNumber: 4, data: { savePointOnly: true }, wrapperOverride: true},
                "Event Name"                :{ wrapper: ["EventInfoName", "EventInfoRowErrorName"],orderNumber: 5, data: { savePointOnly: true }, wrapperOverride: true},
                "Show Association"          :{ wrapper: ["EventInfoShowAssociation", "EventInfoRowErrorShowAssociation"],orderNumber: 6, data: { savePointOnly: true }, wrapperOverride: true},
                "Competition Number"        :{ wrapper: ["EventInfoCompetitionNumber", "EventInfoRowErrorCompetitionNumber"],orderNumber: 7, data: { savePointOnly: true }, wrapperOverride: true},
                "Show Safety"               :{ wrapper: ["EventInfoShowSafety", "EventInfoRowErrorShowSafety"],orderNumber: 8, data: { savePointOnly: true }, wrapperOverride: true}
            }
            },

            "OwnPremisesLabel" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 1},
            "OwnPremises" : { wrapper:  [ "ownPremisesData" ] , wrapperOverride: true, type: "ownPremisesType", orderNumber: 2},
            "OperationsPrimaryLabel" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 3},
            "Boarding" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 4},
            "Breeding" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 5},
            "Farrier" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 6},
            "LeaseHorseToOthers" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 7},
            "Pleasure" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 8},
            "Riding" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 9},
            "TeachClinics" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 10},
            "HorseTraining" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 11},
            "OperationValidation" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 12},
            "OperationsSecondaryLabel" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 13},
            "DayCamp" : { wrapper:  [ "operationLabel" ] , wrapperOverride: true, orderNumber: 14},
            "DayCampLink" : { wrapper:  [ "dayCampLinkLabel" ] , wrapperOverride: true,type : 'dayCampLinkType', orderNumber: 15}
        }
    };
    widget.configureFormly = function(formlyConfig, formlyApiCheck){

        formlyConfig.setWrapper({
            name: "dayCampLinkLabel",
            template:
            '<div>' +
            '<formly-transclude></formly-transclude>' +
            '</div>'
        });

        formlyConfig.setType({
            name: "dayCampLinkType",
            template:
            '<div ng-class="options.templateOptions.label.length ? options.templateOptions.style.className ? options.templateOptions.style.className : \'alert alert-info\': \'\' " style="" class="ng-scope no-style">'+
            '<label class="control-label" style="width:102%;" for="{{ id }}" class="">{{ to.label }}' +
            '<span class="span_link" style="float:none; padding-left: 1%;" onclick="window.open( \'/assets/documents/Supp-Horse Camp 10-2016.pdf\', \'BOPCoverageHighlights\' );">Day Camp (pdf)</span>' +
            '</label>' +
            '</div>'
        });


        formlyConfig.setWrapper({
            name: "operationLabel",
            template: '<div class="operationLabel" >' +
            '<formly-transclude></formly-transclude>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "ownPremisesData",
            template: '<div class="ownPremisesData" >' +
            '<formly-transclude></formly-transclude>' +
            '</div>'
        });

        formlyConfig.setType({
            name: "ownPremisesType",
            template:
            '<div class="form-group" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<div class="control-group row-fluid">' +
            '<div class="span6"><label  class="control-label ng-binding">{{to.label}}</label></div>' +
            '<div class="span6 field-wrapper">' +
            '<div class="radio-group switch-field">' +
            '<div style="padding-left: 0px;" ng-repeat="(key, option) in to.options" class="radio switch-title">' +
            '<input type="radio" id="{{id + \'_\'+ $index}}" tabindex="0" ng-value="option[to.valueProp || \'value\']" ng-model="model[options.key]">' +
            '<label for="{{id + \'_\'+ $index}}"><span>{{option[to.labelProp || \'name\']}}</span></label>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            ' <div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid &amp;&amp; options.validation.errorExistsAndShouldBeVisible">' +
            '<div class="span12">' +
            '<p class="ng-binding">{{options.data.validationMessage}}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });


        formlyConfig.setWrapper({
            name: "event-info-wrapper",
            template: '<div host-shows-events model="model" options="options" quantity-control-id="\'hostShowsEvents\'"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "EventInfoStartDate",
            template: '<div class="EventInfoStartDate" style="width: 110px;">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "EventInfoEndDate",
            template: '<div class="EventInfoEndDate" style="width: 110px;">' +
            '<formly-transclude></formly-transclude>' +
            '</div>'
        });

        formlyConfig.setWrapper({
            name: "EventInfoEstimatedDate",
            template: '<div class="EventInfoEstimatedDate">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "EventInfoName",
            template: '<div class="EventInfoName">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "EventInfoShowAssociation",
            template: '<div class="EventInfoShowAssociation">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "EventInfoCompetitionNumber",
            template: '<div class="EventInfoCompetitionNumber">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "EventInfoShowSafety",
            template: '<div class="EventInfoShowSafety">' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });


        formlyConfig.setWrapper({
            name: "EventInfoRowErrorStartDate",
            template:
            '<div class="span1 form-group EventInfoRowErrorStartDate" ng-class="{ \'has-error\': !options.data.isValid && (options.data.wasTouched || options.validation.errorExistsAndShouldBeVisible), required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && (options.data.wasTouched || options.validation.errorExistsAndShouldBeVisible)">' +
            '<div>' +
            '<p class="EIErrorStartDate">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "EventInfoRowErrorEndDate",
            template:
            '<div class="span1 form-group EventInfoRowErrorEndDate" ng-class="{ \'has-error\': !options.data.isValid && (options.data.wasTouched || options.validation.errorExistsAndShouldBeVisible), required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && (options.data.wasTouched || options.validation.errorExistsAndShouldBeVisible)">' +
            '<div>' +
            '<p class="EIErrorEndDate">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "EventInfoRowErrorEstimatedDate",
            template:
            '<div class="span1 form-group EventInfoRowErrorEstimatedDate" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="EIErrorEstimatedDate">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "EventInfoRowErrorName",
            template:
            '<div class="span1 form-group EventInfoRowErrorName" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="EIErrorName">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "EventInfoRowErrorShowAssociation",
            template:
            '<div class="span1 form-group EventInfoRowErrorShowAssociation" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="EIErrorShowAssociation">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "EventInfoRowErrorCompetitionNumber",
            template:
            '<div class="span1 form-group EventInfoRowErrorCompetitionNumber" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="EIErrorCompetitionNumber">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });
        formlyConfig.setWrapper({
            name: "EventInfoRowErrorShowSafety",
            template:
            '<div class="span1 form-group EventInfoRowErrorShowSafety" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
            '<div>' +
            '<p class="EIErrorShowSafety">{{ options.data.validationMessage }}</p>' +
            '</div>' +
            '</div>' +
            '</div>'
        });

    };
    return widget;
});
