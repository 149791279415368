define('common/directive/scrollTo',['require'],function(require) {
	"use strict";

	function ScrollTo() {
		return {
			restrict: "AE",
			scope: {
				scrollEvent: "@"
			},
			link: function( scope, ele, attrs ) {
				scope.$on( scope.scrollEvent, function() {
					$("html,body").animate({ scrollTop: $(ele).offset().top }, 500 );
				});
			}
		};
	}

	return ScrollTo;

});
