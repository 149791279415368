define('common/directive/loadingBar',['require'],function(require) {
	"use strict";

	function LoadingBar() {
		return {
			restrict: "AE",
			scope: true,
			template:
				'<div class="loading-bar">' +
					'<h2>Loading...</h2>' +
					'<img class="loader" src="/assets/images/ajax-loader-grey.gif" />' +
				'</div>'
		};
	}

	return LoadingBar;

});
