define('components/widget/optional-endorsements/waiver-of-transfer/app',['require','components/widget/optional-endorsements/waiver-of-transfer/directive/waiverOfTransfer','components/widget/optional-endorsements/waiver-of-transfer/controller/WaiverOfTransferController'],function(require) {
	"use strict";

	var waiverOfTransfer = require("components/widget/optional-endorsements/waiver-of-transfer/directive/waiverOfTransfer"),
        WaiverOfTransferController = require("components/widget/optional-endorsements/waiver-of-transfer/controller/WaiverOfTransferController");

	var component = angular.module("mPortal.components.widget.optional-endorsements.waiver-of-transfer", []);
	component.directive( "waiverOfTransfer", waiverOfTransfer );
	component.controller( "WaiverOfTransferController", WaiverOfTransferController );

	return component;

});
