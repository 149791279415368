define('components/specialtyApplication/questions/config/widgets/BuildingWidget',['require'],function(require) {
    "use strict";

    var widget = {};


    widget.getStructure = function() {

        return  {
                     "buildingTable": {
                        wrapper: [ "building-table-wrapper" ], 
                        orderNumber: 1,
                        fieldGroup: {
                                      "Applicant Type" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber : 0},
                                      "Building Location" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 1 },
                                      "Building Description" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 2 },
                                      "Business Name" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 3 },
                                      "Construction Type" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 4 },
                                      "Year Built" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 5 },
                                      "Stories" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 6 },
                                      "Building Square Footage" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 7 },
                                      "Occupied Percent" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 8 },
                                      "Annual Gross Sales" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 9 },
                                      "Single Occupancy" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , type: "buildingRadio", wrapperOverride: true, orderNumber: 10 },
                                      "Fire Station Distance" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 11 },
                                      "Hydrant Distance" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 12 },
                                      "Fire Alarm" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 13 },
                                      "Fire Protection Area" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 14 },
                                      "Fire Protection Area Label" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 15 },
                                      "Sprinklered" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , type: "buildingRadio" , wrapperOverride: true, orderNumber: 16 },
                                      "Condo Unit Owner" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ], type: "buildingRadio" , wrapperOverride: true, orderNumber: 17 },
                                      "Class Code Description ID" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 18 },
                                      "BPP Limit" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 19 },
                                      "Replacement Cost" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 20 },
                                      "getReplacementCost" : { wrapper: null, wrapperOverride: true, orderNumber: 21 },
                                      "Building Value" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 22 },
                                      "Annual Payroll" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 21 },
                                      "Renovations" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 22 },
                                      "Renovation Type Label" : { wrapper: null , wrapperOverride: false, orderNumber: 22 },
                                      "Roof Renovation": { wrapper:  null , wrapperOverride: false, orderNumber: 22 },
                                      "Electrical Renovation": { wrapper:  null , wrapperOverride: false, orderNumber: 22 },
                                      "Heating Renovation": { wrapper:  null, wrapperOverride: false, orderNumber: 22 },
                                      "Plumbing Renovation" : { wrapper: null , wrapperOverride: false, orderNumber: 22 },
                                      "Roof Years": { wrapper:  null , wrapperOverride: false, orderNumber: 22 },
                                      "Electrical Years": { wrapper:  null , wrapperOverride: false, orderNumber: 22 },
                                      "Heating Years": { wrapper:  null, wrapperOverride: false, orderNumber: 22 },
                                      "Plumbing Years" : { wrapper: null , wrapperOverride: false, orderNumber: 22 },
                                      "Class Code" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 24 },
                                      "Mine Subsidence Waiver" : { wrapper:  null, wrapperOverride: false, orderNumber: 25 },
                                      "Mine Subsidence Limit" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 26 },
                                      "Additional Expense Coverage" : { wrapper:  [ "horizontalBox", "verticalFullRowError" ] , wrapperOverride: true, orderNumber: 27 },
                                      "Additional Living Expense" : { wrapper: null , wrapperOverride: false, orderNumber: 28 }

                                    }
                    }
                };
    };

    widget.configureFormly = function (formlyConfig, formlyApiCheck) {

        formlyConfig.setWrapper({
            name: "building-table-wrapper",
            template: '<div class="row-fluid" building-table model="model" options="options" fields="fields" form="form" section="section"><formly-transclude></formly-transclude></div>'
        });

	    formlyConfig.setType({
		    "extends": "radio",
		    name: "buildingRadio",
		    template:
		    '<div class="radio-group switch-field">' +
		    '<div style="padding-left: 0px;" ng-repeat="(key, option) in to.options" class="radio switch-title">' +
		    '<input type="radio" id="{{id + \'_\'+ $index}}" tabindex="0" ng-value="option[to.valueProp || \'value\']" ng-model="model[options.key]">' +
		    '<label for="{{id + \'_\'+ $index}}"><span>{{option[to.labelProp || \'name\']}}</span></label>' +
		    '</div>' +
		    '</div>'
	    });


    };

    return widget;


});
