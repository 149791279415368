define('contact/controller/ContactController',['require'],function(require) {
    "use strict";

    ContactController.$inject = [ "$scope", "UserProfile" ];

    function ContactController( $scope, UserProfile ) {
        $scope.insuredType = UserProfile.insuredType;
    }

    return ContactController;

});
