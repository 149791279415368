define('components/newsHeadlines/controller/NewsHeadlinesController',['require'],function(require) {
	"use strict";

	NewsHeadlinesController.$inject = ["$scope", "$modal"];

	function NewsHeadlinesController( $scope, $modal ) {

		$scope.news = [];

		// newsItems are injected via the directive from the calling page
		$scope.newsItems.then(function( news ) {
			$scope.news = news.lastFive;
		});

		$scope.openItem = function( item ) {
			$modal.open({
				templateUrl: "app/components/newsHeadlines/template/newsItem.html",
				controller: "NewsItemController",
				resolve: {
					newsItem: function() {
						return item;
					}
				}
			});
		};

	}

	return NewsHeadlinesController;

});
