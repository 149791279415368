define('components/specialtyApplication/resultSummary/directive/resultSummary',['require'],function(require) {
	"use strict";

	Result.$inject = [];

	function Result() {
		return {
			restrict: "AE",
			scope: {
				applicationId: "=?"
			},
			templateUrl: "app/components/specialtyApplication/resultSummary/template/template.html",
			controller: "SpecialtyApplicationResultSummaryController"
		};
	}

	return Result;

});
