define('common/service/util/DateService',['require'],function(require) {
	"use strict";

	DateService.$inject = ["moment"];

	function DateService( moment ) {

		var service = {},
			months;

		months = [
			{ value: 0, label: "January" },
			{ value: 1, label: "February" },
			{ value: 2, label: "March" },
			{ value: 3, label: "April" },
			{ value: 4, label: "May" },
			{ value: 5, label: "June" },
			{ value: 6, label: "July" },
			{ value: 7, label: "August" },
			{ value: 8, label: "September" },
			{ value: 9, label: "October" },
			{ value: 10, label: "November" },
			{ value: 11, label: "December" }
		];

		service.dateDiff = function( datepart, date1, date2 ) {
			return moment( date2 ).diff( moment( date1 ), datepart );
		};

		service.getAllMonths = function() {
			return months;
		};

		// returns array of all years between the start and end dates, including start and end year
		service.getYearRange = function( start, end ) {

			var startYear = start instanceof Date ? start.getFullYear() : start,
				endYear = end instanceof Date ? end.getFullYear() : end,
				years = [];

			for( var i = startYear; i <= endYear; i++ ) {
				years.push( i );
			}

			return years;

		};

		// returns a date object for the last day of a month. requires either a date object as params.date
		// or a numeric month and year as params.month and params.year, respectively.
		service.getDateForLastDayOfMonth = function( params ) {

			if( params.date ) {
				params.year = params.date.getFullYear();
				params.month = params.date.getMonth();
			}

			return moment({ year: params.year, month: params.month }).endOf("month").toDate();

		};

		service.isValidDate = function( date, format ) {
			return moment( date, format, true ).isValid();
		};

		service.formatDate = function( date, format ) {
			return moment( date ).format( format );
		};

		return service;

	}

	return DateService;

});
