define( 'common/filter/deDuplicate',['require'],function ( require ) {
	"use strict";

	deDuplicate.$inject = [];

	function deDuplicate() {
		return function ( input, name ) {
			var keys = [];
			var output = [];
			angular.forEach( input, function ( value ) {
				var key = value[ name ];
				if ( keys.indexOf( key ) === -1 ) {
					keys.push( key );
					output.push( value );
				}
			} )
			return output;
		};
	}

	return deDuplicate;

} );
