define('components/startQuoteCommercialPackage/controller/StartQuoteCommercialPackageController',['require'],function(require) {
	"use strict";

	StartQuoteCommercialPackageController.$inject = ["$scope", "$filter", "$location", "MarketedProgramService", "UserProfile"];

	function StartQuoteCommercialPackageController( $scope, $filter, $location, MarketedProgramService, UserProfile ) {

		var defaultProgram = { name: "Select One" };

		$scope.programs = [];
		$scope.selectedProgram = defaultProgram;

		MarketedProgramService.getMarketedPrograms().then(function( programs ) {
			$scope.programs = [];
			var userPrograms = $filter("filter")( programs, MarketedProgramService.userHasAccessToProgram.bind( null, UserProfile ) );
			userPrograms.unshift( defaultProgram );

			// Exclude FirstComp/Maverick programs
			angular.forEach( userPrograms, function( program ) {
				if( program.mbu != "FC" ) {
					$scope.programs.push( program );
				}
			});
		});

		$scope.isContinueEnabled = function() {
			return $scope.selectedProgram && $scope.selectedProgram !== defaultProgram;
		};

		$scope.continue = function() {
			if ($scope.selectedProgram.defaultBusinessTypeId) {
				$location.path( "application/search/" + $scope.selectedProgram.defaultBusinessTypeId );
			} else {
				if( $scope.selectedProgram.onlineSubmissionAvailable ) {
					$location.path( "application/" + $scope.selectedProgram.urlSlug );
				} else {
					$location.path( "programs/" + $scope.selectedProgram.id );
				}
			}

		};

	}

	return StartQuoteCommercialPackageController;
});
