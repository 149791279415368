define('common/service/CommissionsService',['require'],function(require) {
	"use strict";

	CommissionsService.$inject = ["$http", "maverickApiUrl", "archApiUrl"];

	function CommissionsService( $http, maverickApiUrl, archApiUrl ) {

		var service = {};

		service.getCommissions = function() {
			return $http.get( maverickApiUrl + "office/getCommissionStatements" ).then(function( result ) {
				return result.data;
			});
		};

		service.getCommissionDocuments = function ( agencyId ) {
			return $http.get( archApiUrl + "application/agency/" + agencyId + "/documents/commission" ).then( function ( result ) {
				return result.data;
			});
		}

		service.listAgencyBillingDocuments = function ( agencyId ) {
			return $http.get( archApiUrl + "organization/agency/"+ agencyId + "/documents/billing" ).then( function ( result ) {
				return result.data;
			});
		}

		return service;

	}

	return CommissionsService;

});

