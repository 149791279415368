define('components/widget/description-eligibility-guidelines/controller/descriptionEligibilityGuidelinesController',['require'],function(require) {
    "use strict";

    descriptionEligibilityGuidelinesController.$inject = ["$scope", "EligibilityGuidelineService"];

    function descriptionEligibilityGuidelinesController( $scope, EligibilityGuidelineService ) {
        $scope.guidelines = EligibilityGuidelineService.guidelines;
        $scope.hasGuidelines = $scope.guidelines ? Object.keys( $scope.guidelines ).length > 0 : false;
    }

    return descriptionEligibilityGuidelinesController;
    
});

