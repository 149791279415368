define('components/userAccountSettings/personalDetails/directive/personalDetails',['require'],function(require) {
	"use strict";

	function PersonalDetails() {
		return {
			restrict: "AE",
			scope: {
				user: "=",
				states: "="
			},
			templateUrl: "app/components/userAccountSettings/personalDetails/template/template.html",
			controller: "PersonalDetailsController"
		};
	}

	return PersonalDetails;

});
