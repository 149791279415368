define('components/policyDetail/accountHistory/directive/accountHistory',['require'],function(require) {
	"use strict";

	function AccountHistory() {
		return {
			restrict: "AE",
			scope: {
				policies: "=",
				openPolicyTab: "=",
				account: "=",
				user: "="
			},
			templateUrl: "app/components/policyDetail/accountHistory/template/template.html",
			controller: "AccountHistoryController"
		};
	}

	return AccountHistory;

});
