define('components/lossRunReport/directive/lossRunReport',['require'],function(require) {
	"use strict";

	LossRunReport.$inject = [];

	function LossRunReport() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/lossRunReport/template/template.html",
			controller: "LossRunReportController"
		};
	}

	return LossRunReport;

});
