define('contact/app',['require','common/config/config','shim/overmind','contact/controller/ContactController'],function(require) {
	"use strict";

	require("common/config/config");

	var overmind = require("shim/overmind");

	var ContactController = require( "contact/controller/ContactController");

	var app = angular.module("mPortal.contact", [
		"mPortal.config"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {

		$routeProvider.when("/contact", {
			templateUrl: "app/contact/template/template.html",
			controller: "ContactController"
		});
		
	}]);

	app.controller( "ContactController", ContactController );

	return app;

});
