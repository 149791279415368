define( 'components/documentDeliveryPreferences/directive/documentDeliveryPreferences',['require'],function( require ) {
    "use strict";

    DocumentDeliveryPreferences.$inject = [];

    function DocumentDeliveryPreferences() {
        return {
            restrict: "AE",
            scope: {
                masterQuoteId: "=",
                hasData: "=",
                docDeliveryConfig: "=",
                deliveryErrors: "=",
                isIssued: "=",
                buttonText: "=",
                insuredType: "=",
                isAvailableForBind: "="
            },
            templateUrl: "app/components/documentDeliveryPreferences/template/template.html",
            controller: "DocumentDeliveryPreferencesController"
        };
    }

    return DocumentDeliveryPreferences;

});
