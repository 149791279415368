define('components/userAccountSettings/securityResponse/directive/securityResponse',['require'],function(require) {
	"use strict";

	function SecurityResponse() {
		return {
			restrict: "AE",
			scope: {
				user: '=',
				hasSecurityResponse: '=',
				securityQuestions: '='
			},
			templateUrl: "app/components/userAccountSettings/securityResponse/template/template.html",
			controller: "SecurityResponseController"
		};
	}

	return SecurityResponse;

});
