define('components/startQuote/controller/StartQuoteController',['require'],function(require) {
	"use strict";

	StartQuoteController.$inject = [
		"$scope",
		"$q",
		"$window",
		"$location",
		"EligibilityService",
		"MarketedProgramService",
		"MessageContainer",
		"StartQuoteService",
		"AgencyService",
		"UserProfile",
		"DateService",
		"ClassCodeService",
		"SubmissionStore"
	];

	function StartQuoteController( $scope, $q, $window, $location, EligibilityService, MarketedProgramService , MessageContainer, StartQuoteService, AgencyService, UserProfile, DateService, ClassCodeService,SubmissionStore ) {

		$scope.availableProductLines = [];
		$scope.wcClassCodes = [];
		$scope.boClassCodes = [];
		$scope.eoDescriptions = [];
		$scope.eoGroups = [];
		$scope.eoMarketedProgram = '';
		$scope.states = [];
		$scope.store = SubmissionStore;


		$scope.selected = {
			state: "",
			effectiveDate: "",
			includeMiscEO: true,
			eoGroup : ""
		};
		$scope.lines = {
			wc: {
                value: "",
				enabled: false,
				classCode: "",
				productLine: "wc",
				program: 0,
				programsAvailable: false,
				awaitingData: false,
				messages: new MessageContainer({
					checking: "",
					validation: "",
					failed: "",
					passed: ""
				})
			},
			bo: {
                value: "",
				enabled: false,
				classCode: "",
				productLine: "bo",
				program: 0,
				programsAvailable: false,
				awaitingData: false,
				messages: new MessageContainer({
					checking: "",
					validation: "",
					failed: "",
					passed: ""
				})
			},
			eo: {
                value: "",
				enabled: false,
				classCode: "",
				productLine: "eo",
				group: "",
				program: 0,
				programsAvailable: false,
				awaitingData: false,
				messages: new MessageContainer( {
					checking: "",
					validation: "",
					failed: "",
					passed: ""
				} )
			}
		};

		$scope.programs = {
			wc: [],
			bo: [],
			eo: []
		};


		$scope.get_marketed_programs = function(e,args)
        {
            var p = [   MarketedProgramService.getMarketedPrograms() ];

            $q.all(p).then(function(results){
                var programs = results[0];

                for( var i = 0; i < programs.length; i++)
                {
                    var program = programs[i];

                    if( program.referenceString === "miscErrorsOmissions" )
					{
						$scope.eoMarketedProgram = program;
					}
			
                }
            });
        };

        $scope.get_marketed_programs();

		function getEligibilityParams() {

			var queryParams = $location.search(),
				eligibilityParams = {};

			if( queryParams.state ) {
				eligibilityParams.state = queryParams.state;
			}

			return eligibilityParams;

		}

		$scope.agencyIdsPromise = AgencyService.getAgencyIds().then(function( ids ) {
			$window.appStorage.agencyLocations = ids;
			return ids;
		});

		EligibilityService.getEligibility( getEligibilityParams() ).then(function( result ) {

			$scope.wcClassCodes = result.wcClassCodes;
			$scope.boClassCodes = result.bopClassCodes;
			$scope.eoDescriptions = result.eoDescriptions;
			$scope.eoGroups = result.eoGroups;
			$scope.states = result.states;
			$scope.selected.state = result.defaultState || "";
			$scope.setAvailableLines( result.availableLines );

		});

		$scope.hasProductLineAvailable = function( productLine ) {
			if( productLine.toLowerCase() == 'eo' && !$scope.isEoAllowed())
			{
				return false;
			}
			return $scope.availableProductLines.indexOf( productLine ) !== -1;
		};

		$scope.setAvailableLines = function( availableLines ) {
			availableLines = availableLines || [];

			$scope.availableProductLines = availableLines.map(function( productLine ) {
				return productLine.toLowerCase();
			});

			if( $scope.availableProductLines.length === 1 ) {
				$scope.lines[ $scope.availableProductLines[ 0 ] ].enabled = true;
			}
		};

		$scope.areButtonsDisabled = function() {
			if( $scope.lines.wc.awaitingData || $scope.lines.bo.awaitingData ) {
				return true;
			}
			return !StartQuoteService.allowEligibilityCheck( $scope.lines );
		};

        $scope.disableLine = function (productLine) {
            $scope.lines[productLine].messages.reset();
            $scope.lines[productLine].classCode = "";
            $scope.lines[productLine].value = "";
            var wcClassCodeId = $scope.lines.wc.classCode.value ? $scope.lines.wc.classCode.value : $scope.lines.wc.classCode,
                boClassCodeId = $scope.lines.bo.classCode.value ? $scope.lines.bo.classCode.value : $scope.lines.bo.classCode,
                isValidWcClassCode = ClassCodeService.validateClassCode(wcClassCodeId, 'wc'),
                isValidBoClassCode = ClassCodeService.validateClassCode(boClassCodeId, 'bo');
            if (isValidWcClassCode || isValidBoClassCode) {
                EligibilityService.getEOGroupsBasedOnClassCode(wcClassCodeId, boClassCodeId, $scope.selected.state, $scope.selected.effectiveDate)
                    .then(function (result) {
                        $scope.eoGroups = result.eoGroups;
                        $scope.eoDescriptions = result.eoDescriptions;
                        $('.showEoError').addClass('hidden');
                        if (result.eoGroups.length != 0) {
                            $('.eoEligibility').removeClass('hidden');
                            $('.showEoError').addClass('hidden');
                            if (UserProfile.allowedPrograms.indexOf('miscErrorsOmissions') >= 0) {
                                $('.showEoEligible').removeClass('hidden');
                            }
                            if ($scope.lines.eo.enabled) {
                                $('.showEoEligible').addClass('hidden');
                            }
                        }
                        else {
                        $('.showEoEligible').addClass('hidden');
                            if ($scope.lines.eo.enabled) {
                                $('.eoEligibility').addClass('hidden');
                                if (wcClassCodeId != "" || boClassCodeId != "") {
                                    $('.showEoError').removeClass('hidden');
                                }
                            }
                        }
                    })
            }
        };

		$scope.resetLines = function() {
			if( !DateService.isValidDate( $scope.selected.effectiveDate, "MM/DD/YYYY") ) {
				return;
			}

			Object.keys( $scope.lines ).forEach(function( key ) {
				$scope.disableLine( key );
				$scope.lines[ key ].enabled = false;
			});

			$q.all([
				EligibilityService.getAvailableProductLines( $scope.selected.state, $scope.selected.effectiveDate ),
				EligibilityService.getStateClassCodes( $scope.selected.state, $scope.selected.effectiveDate )
			]).then(function( result ) {
				$scope.setAvailableLines( result[ 0 ].availableLines );
				$scope.wcClassCodes = result[ 1 ].wcClassCodes;
				$scope.boClassCodes = result[ 1 ].bopClassCodes;
				$scope.eoDescriptions = result[ 1 ].eoDescriptions;
				$scope.eoGroups = result[ 1 ].eoGroups;
			});
		};

		$scope.checkEligibility = function() {

			$scope.validate();

			return StartQuoteService.checkEligibility(
				$scope.getValidLines(),
				UserProfile.enterpriseID,
				$scope.selected.state,
				$scope.selected.effectiveDate
			).then(function( statuses ) {

				angular.forEach( statuses, function( status, productLine ) {

					var line = $scope.lines[ productLine ],
						messageType = ["Decline", "Invalid"].indexOf( status ) !== -1 ? "failed" : "passed";

					if( status === "Invalid" ) {
						status = "Invalid class code for selected state, please enter a different class code.";
					}

					line.messages.reset();
					line.messages[ messageType ] = status;

				});

			});

		};

		/** 
		* for a user to "continue" (progress to the appropriate quote application) from the start quote widget, the
		* following conditions must be met:
		* 	1. all enabled lines must be valid
		* 	2. all enabled lines must not have a response of "Decline"
		*/
		$scope.continueQuote = function() {

			$scope.checkEligibility().then(function() {

				var validLines = $scope.getValidLines(),
					statuses = {},
					quotable = true && validLines.length;

				// we have to check all of the lines, not just the valid lines. we still want to prevent the user
				// from proceeding if they have an invalid line enabled, which is not possible unless we check all lines.
				angular.forEach( $scope.lines, function( line ) {

					// if this line isn't selected, we can skip it
					if( !line.enabled ) {
						return;
					}

					// similarly, if this line is selected but did not validate, we can skip it. make sure to
					// set quotable = false so that we don't let the user continue with an invalid line.
					if( validLines.indexOf( line ) === -1 ) {
						quotable = false;
						return;
					}

					if( line.messages.failed === "Decline" ) {

						// because the user is trying to continue to the actual quote page, let's give them an explanation
						// as to why we're not letting them continue
						line.messages.failed = "Decline - Please correct or un-check the product line to Continue";
						quotable = false;

					} else {

						// this status will be used on the start quote page to populate some otherwise default data
						statuses[ line.productLine ] = line.messages.passed;

					}

				});

                if (quotable) {

                    // start quote page is a legacy app - no angular - so just stick this stuff on the window and let it
                    // deal with it however it wants to
                    $window.appStorage.newQuote = {
                        effectiveDate: $scope.selected.effectiveDate,
                        state: $scope.selected.state,
                        wcClassCode: $scope.lines.wc.classCode.value,
                        wcClassCodeID: $scope.lines.wc.classCode.id,
                        wcResult: statuses.wc || false,
                        bopClassCode: $scope.lines.bo.classCode.value,
                        bopClassCodeID: $scope.lines.bo.classCode.id,
                        bopClassCodeDescriptionID: $scope.lines.bo.classCode.descriptionId,
                        bopResult: statuses.bo || false,
                        agencyLocations: $window.appStorage.agencyLocations,
                        eoResult: statuses.eo || false,
                        eoClassificationId: $scope.lines.eo.classCode.id,
                        eoClassification: $scope.lines.eo.classCode.value,
                        eoGroup: $scope.lines.eo.classCode.businessGroup,
                        descriptionKeyword: $scope.lines.eo.classCode.descriptionKeyword,
                        groupKeyword: $scope.lines.eo.classCode.groupKeyword,
                    };

                    //EO data for legacy app
                    $window.appStorage.quoteEO = $scope.isEoAllowed() && $scope.lines.eo.classCode.value;
                    $window.appStorage.effectiveDate = $scope.selected.effectiveDate;
                    $window.appStorage.descriptionKeyword = $scope.lines.eo.classCode.descriptionKeyword;
                    $window.appStorage.groupKeyword = $scope.lines.eo.classCode.groupKeyword;
                    
                  
                    if($window.appStorage.quoteEO)
                    {
                    	$scope.store.effectiveDate = $scope.selected.effectiveDate;



						$scope.store.programs = [{
                    								marketedProgramId	:   $scope.eoMarketedProgram.id, 
			                                        marketedProgramName :   $scope.eoMarketedProgram.name,
			                                        dataPoints			: 	[{
						                    								    keyname: "EO Admitted Classes",
																	            value: $scope.lines.eo.classCode.descriptionKeyword
																	         },
																	         {
															                  	keyname: "EO Business Type",
															                    value: $scope.lines.eo.classCode.groupKeyword
															                 }]
    											}];

						UserProfile.submission_store = $scope.store;
                    }

                    if ($scope.lines.wc.programsAvailable && $scope.lines.wc.program !== 0) {
                        $window.appStorage.newQuote.wcProgram = $scope.lines.wc.program;

                        angular.forEach($scope.programs.wc, function (program) {
                            if (program.ID === $scope.lines.wc.program) {
                                $window.appStorage.newQuote.wcProgramName = program.NAME;
                                return;
                            }
                        });
                    }
                    if ($scope.lines.wc.classCode || $scope.lines.bo.classCode) {
                        $location.path("/quote");
                    } else {
                        $location.path("application/" + "misceo");
                    }
                }

			});

		};

		$scope.validate = function() {
			var ClassificationId = 1;
			angular.forEach( $scope.lines, function( line ) {

				if( !line.enabled ) {
					return;
				}
                if($scope.lines.eo.enabled && $scope.lines.eo.classCode == '' )
                {
                    ClassificationId = 0;
                }
				line.messages.reset();
				line.messages.validation = StartQuoteService.validate( line, $scope.selected.state, $scope.selected.effectiveDate, ClassificationId );

			});

		};

		$scope.getValidLines = function() {

			var validLines = [];

			angular.forEach( $scope.lines, function( line, productLine ) {

				if( line.enabled && !line.messages.validation.length && !line.messages.failed.length ) {
					validLines.push( line );
				}

			});

			return validLines;

		};

		$scope.loadPrograms = function( effectiveDate ) {
			$scope.agencyIdsPromise.then(function( agencies ) {
				AgencyService.getAgencyPrograms( agencies, effectiveDate ).then(function( programs ) {
					// for now, all we support is WC programs
					$scope.programs.wc = programs;
					$scope.lines.wc.programsAvailable = programs.length > 1;
				});
			});
		};

		$scope.isMarketedProgramAvailable = function( marketedProgramReferenceString ){
			var validLines = $scope.getValidLines();
			var result = validLines.length > 0;

			if( UserProfile.allowedPrograms.indexOf( marketedProgramReferenceString ) < 0 ){
				return false;
			}

			angular.forEach( $scope.getValidLines(), function( line ) {
				if( !angular.isObject( line.classCode ) ||
					!angular.isString( line.classCode.marketedProgramReferenceString ) ||
					line.classCode.marketedProgramReferenceString.split(',' ).indexOf( marketedProgramReferenceString ) < 0
				){
					result = false;
				}
			});

			return result;
		};

        $scope.isEoAllowed = function () {
            if (UserProfile.allowedPrograms.indexOf('miscErrorsOmissions') < 0) {
                return false;
            }
            return true;
        };
    }

	return StartQuoteController;

});
