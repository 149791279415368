define('user/controller/UserEditController',['require'],function(require) {
	"use strict";

	UserEditController.$inject = ["$scope", "$filter", "UserService", "UserProfile"];

	function UserEditController( $scope, $filter, UserService, UserProfile ) {
		$scope.userProfile = UserProfile;
		$scope.pageLoading = true;

		$scope.user = {
			type: $scope.userProfile.type,
			priorPassword: "",
			password: "",
			passwordConfirm: "",
			newUserName: "",
			question: 0,
			answer: "",
            securityPassword: "",
            personalDetailPassword: "",
			states: []
		};

		if( !$scope.userProfile.agencyEmulation ) {
			UserService.getUserData().then(function( result ) {
				$scope.user = angular.extend( $scope.user, result.user );

				// Set the user's address state from the general state data
				var selectedStateOrProvince = $filter( "filter" )( result.states, { selected: true } );
				$scope.user.stateOrProvince = selectedStateOrProvince.length ? selectedStateOrProvince[0].value : '';
				$scope.showAPIKey = result.showAPIKey;
				$scope.securityQuestions = result.securityQuestions;
				$scope.user.question = $scope.securityQuestions[0].id; // Ensures a question is always selected
				$scope.hasSecurityResponse = result.hasSecurityResponse ? result.hasSecurityResponse : false;
				$scope.states = result.states;
				$scope.subscriptions = angular.copy( result.states );
				$scope.pageLoading = false;
			});
		}
	}

	return UserEditController;

});
