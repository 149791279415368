define('components/startQuote/directive/startQuote',['require'],function(require) {
	"use strict";

	StartQuote.$inject = [];

	function StartQuote() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/startQuote/template/template.html",
			controller: "StartQuoteController"
		};
	}

	return StartQuote;

});
