define( 'components/applicationData/applicationSection/controller/ApplicationSectionController',['require'],function( require ) {
    "use strict";

    ApplicationSectionController.$inject = [ "$scope", "$filter", "ApplicationSectionService" ];

    function ApplicationSectionController( $scope, $filter, ApplicationSectionService ) {

        var collectedFields = [];
        $scope.nextDepth = $scope.depth + 1;
        $scope.fields = [];
        $scope.sectionBranches = [];
        $scope.buildingBranchs = [];
        $scope.mortgageAccount = [];
        $scope.aiLeasedDesc = [];
        $scope.aiLossPayDesc = [];
        $scope.aiLossPayClause = [];
        $scope.aiVendorsDesc = [];
        $scope.aiOwnersDesc = [];
        $scope.aiPhotographyDesc = [];
        $scope.aiPhotographyModel = [];
        $scope.aiPhotographySerial = [];
        $scope.aiPhotographyMake = [];
        $scope.aiToolsDesc = [];
        $scope.aiToolsLimit = [];

        if($scope.section && $scope.section.name != "Signature" && $scope.section.name != "Notices and Warranty") {
            collectedFields = ApplicationSectionService.getAllViewDatapoints( $scope.section );
            $scope.fields = ApplicationSectionService.processViewDatapoints( $scope.program, collectedFields );

            $scope.sectionBranches = $filter( "filter" )( $scope.section.sections, function( section ) {
                return section.type !== "inline" && section.visible;
            }).sortBy( function( branch ) { return branch.orderNumber } );
        }

        //Hiding Datapoints which are already displaying in Insured section.
        if ( $scope.section.name === "Insured" ) {
            var hideDatapoints = ['Insured Name','Phone Number', 'Primary Email', 'Email', 'Website','DBA', 'Insured FEIN', 'Mailing Address Label','Mailing Address1', 'Mailing Zip','Mailing City', 'Mailing County', 'Mailing State']
            angular.forEach( $scope.section.datapoints, function ( datapoint ) {
                if ( hideDatapoints.indexOf( datapoint.data.reference ) !== -1 )
                    datapoint.data.hide = true;
            } );
        }

        if($scope.section.title === "Excess liability" || $scope.section.title === "Property"){
            $scope.section.isSectionShow = true;
        }

        if($scope.section.title === "Building OEs and AIs" || $scope.section.name === "Building OEs and AIs" ){
            $scope.section.isSectionShow = false;
        }

        $scope.locationFields = function () {
            if ( $scope.section.name === "Location" ) {
                return true
            }
        };

        $scope.buildingOeAiFields = function () {
            if($scope.section.name === "Building OEs and AIs") {
                return true
            }
        }
        $scope.policyOeAiFields = function () {
            if($scope.section.name === "Policy OEs and AIs") {
                return true
            }
        }

        $scope.AdditionalInsuredFields =  function () {
            if ( $scope.section.name === "Additional Insured" ) {
                return true
            }
        };

        $scope.fetchLocationDefaultValues = function () {
            var setList = [];
            var zipCode;
            var yearBuilt;
            if ( $scope.section.name === "Location" || "Additional Insured" ) {
                for ( var i = 1; i < $scope.section.datapoints.length; i++ ) {
                    var type = $scope.section.datapoints[i].type;
                    var widgetReference = $scope.section.datapoints[i].data.widgetReference;
                    var defaultValue = $scope.section.datapoints[i].defaultValue;
                    //This is just a work-around for pest control and essential BOP
                    if( $scope.program.toLowerCase() == "bop" ) {
                        if (((type === "setLocation" || "TextBox" || "setAdditionalInsuredInfo") && defaultValue !== "") && (widgetReference !== "" && widgetReference !== 'viewAddInsuredName' && widgetReference !== 'viewAddInsuredTypeDesc')) {
                            if ((widgetReference !== 'viewAddLocationZip' && widgetReference !== 'viewLocationZip' && widgetReference !== 'Class Code Description ID' ) && (widgetReference === 'viewLocationAddress1' || widgetReference === 'viewLocationAddress2' || widgetReference === 'viewLocationCity' || widgetReference === 'viewLocationState' || widgetReference === 'viewLocationCounty' || widgetReference === 'No of Buildings' || widgetReference === 'Applicant Type') && $scope.section.datapoints[i].defaultValue != '') {
                                setList.push($scope.section.datapoints[i].defaultValue + ', ');
                            } else if (widgetReference === 'viewLocationZip' || widgetReference === 'viewAddLocationZip' || widgetReference === 'Class Code Description ID') {
                                zipCode = $scope.section.datapoints[i].defaultValue;
                            }
                        }
                    } else {
                        if (((type === "setLocation" || "TextBox" || "setAdditionalInsuredInfo") && (defaultValue !== "" || defaultValue !== null || defaultValue) ) && (widgetReference !== "" && widgetReference !== 'viewNoOfAddInsuredName' && widgetReference !== 'viewAddInsuredName' && widgetReference !== 'viewAddInsuredAccount' && widgetReference !== 'viewAddInsuredType' && widgetReference !== 'viewAddInsuredTypeDesc' && widgetReference !== 'viewAddInsuredAccount'
                                && widgetReference !== 'viewLocationPestControlOperators' && widgetReference !== 'viewLocationPestControlGross ' && widgetReference !== 'viewLocationBedBugs' && widgetReference !== 'viewLocationBedBugsGross' && widgetReference !== 'viewLocationFumigating' && widgetReference !== 'viewLocationFumStructural'
                                && widgetReference !== 'viewLocationFumCommodity' && widgetReference !== 'viewLocationWildlifeControl' && widgetReference !== 'viewLocationWildlifeControlGross ' && widgetReference !== 'viewLocationTermiteTreatment' && widgetReference !== 'viewLocationTermiteTreatmentGross' && widgetReference !== 'viewLocationWDI '
                                && widgetReference !== 'viewLocationWDIGross' && widgetReference !== 'viewLocationLawnCare ' && widgetReference !== 'viewLocationLawnCarePayroll ' && widgetReference !== 'viewLocationLawnCareReceipts ' && widgetReference !== 'viewLocationTreeTrimming ' && widgetReference !== 'viewLocationTreeTrimmingPayroll ' && widgetReference !== 'viewLocationTreeTrimmingReceipts ')) {
                            if ((widgetReference === 'viewLocationAddress1' || widgetReference === 'viewLocationAddress2' || widgetReference === 'viewLocationCity' || widgetReference === 'viewLocationCounty' || widgetReference === 'viewLocationState' || widgetReference === 'No of Buildings' || widgetReference === 'Applicant Type' || widgetReference === 'viewConstructionType' || widgetReference === 'viewAddLocationAddress1' || widgetReference === 'viewAddLocationAddress2' || widgetReference === 'viewAddLocationCity' || widgetReference === 'viewAddLocationCounty' || widgetReference === 'viewAddLocationState' || widgetReference === 'viewTypeOfMasonry' || widgetReference === 'viewTypeOfSteel' ) && $scope.section.datapoints[i].defaultValue != '') {
                                setList.push($scope.section.datapoints[i].defaultValue + ', ');
                            } else if (widgetReference === 'viewLocationZip' || widgetReference === 'viewAddLocationZip') {
                                zipCode =  $scope.section.datapoints[i].defaultValue;
                            } else if (widgetReference === 'viewYearBuilt') {
                                setList.push($scope.section.datapoints[i].defaultValue);
                            }
                        }
                    }
                }
                setList.push( zipCode );
                $scope.section.locationDefaultValues = setList.join( " " );
                return $scope.section.locationDefaultValues;
            }
        }


        $scope.fetchAdditionalInsuredInfoValues = function () {
            var setList = [];
            setList.push($scope.section.instance +')' )
            if ( $scope.section.name === "Additional Insured" ) {

                for ( var i = 0; i < $scope.section.datapoints.length; i++ ) {
                    var type = $scope.section.datapoints[i].type;
                    var widgetReference = $scope.section.datapoints[i].data.widgetReference;
                    var defaultValue = $scope.section.datapoints[i].defaultValue;
                    if ( (type === "setAdditional_InsuredInfo") && defaultValue !== "" && widgetReference != ''  && widgetReference !== 'viewAddInsuredTypeDesc' && widgetReference !== 'viewAddInsuredAccount'  ) {
                        if(widgetReference == 'viewAddInsuredName' || 'viewAddInsuredType') {
                            setList.push( $scope.section.datapoints[i].defaultValue );
                        }
                    }
                }
                $scope.section.additionalInsuredInfoValues = setList.join( ", ");
                $scope.section.additionalInsuredInfoValues = $scope.section.additionalInsuredInfoValues.replace(',','');
                return $scope.section.additionalInsuredInfoValues
            }
        }

        //function to display the Heading and quantity (Header Field)
        $scope.fetchBusinessOeAiHeaderFields = function (oelabel, oeQuantity){
            var headerList = [];
            for (var i = 0; i < $scope.section.datapoints.length; i++) {
                var type = $scope.section.datapoints[i].type;
                var widgetReference = $scope.section.datapoints[i].data.widgetReference;
                if((type === "setLocation" || "viewData" || "CheckBox" || "TextBox") && $scope.section.datapoints[i].defaultValue !== "" && $scope.section.datapoints[i].defaultValue !== false && widgetReference === oelabel) {
                    headerList.push($scope.section.datapoints[i].templateOptions.label);
                }
                else if (widgetReference === oeQuantity ){
                    headerList.push($scope.section.datapoints[i].defaultValue);
                }
            }
            return headerList;
        }

        //function to display the Name and Address (Datapoint Field)
        $scope.fetchOeAiDatapointFields = function (subSectionName, oelabel, oeQuantity, name, zip) {
            var datapointList = [];
            var nameList = "";
            var setBuildingList = [];
            var zipCode = "";
            var k = 1;
            var OeAiDetailDesc = "";
            var extraOeAiDetail = "";
            var photographyModel = "";
            var photographySerial = "";
            var photographyMake = "";
            var contractorInstallation = [];
            var contractorNonOwned = [];
            var contractorEmployeesTools = [];
            var contractorBlanket = "";
            var contractorBlanketSubLimit = "";
            var contractorBlanketLimit = "";
            var contractorScheduled = [];
            var contractorQuestion = [];
            var contractorList= [];
            for (var j = 0; j < $scope.section.sections.length; j++) {
                setBuildingList = [];
                zipCode = "";
                nameList = "";
                if ($scope.section.sections[j].name === subSectionName) {
                    for (var i = 0; i < $scope.section.sections[j].datapoints.length; i++) {
                        var type = $scope.section.sections[j].datapoints[i].type;
                        var widgetReference = $scope.section.sections[j].datapoints[i].data.widgetReference;
                        var defaultValue = $scope.section.sections[j].datapoints[i].defaultValue;
                        var label = $scope.section.sections[j].datapoints[i].templateOptions.label;
                        if ((type === "setLocation" || "viewData" || "CheckBox" || "TextBox") && defaultValue !== "" && widgetReference !== '' && widgetReference !== oelabel && widgetReference !== oeQuantity && widgetReference !== name && widgetReference !== zip &&
                            widgetReference !== "Mortgage Holder Account" && widgetReference !== "AI Leased Equipment Desc" && widgetReference !== "Loss Payable Description" && widgetReference !== "Loss Payable Clause" && widgetReference !== "AI Vendors Product Desc" && widgetReference !== "AI Owners Desc of Ops" && widgetReference !== "Photography Description" && widgetReference !== "Photography Model" && widgetReference !== "Photography Serial" && widgetReference !== "Photography Make" &&
                            widgetReference !== "Contractors Limits" && widgetReference !== "Contractors Tools Non Owned" && widgetReference !== "Tools Non Owned Limit" && widgetReference !== "Contractors Employees Tools" && widgetReference !== "Employees Tools Limit" && widgetReference !== "Contractors Tools Blanket" && widgetReference !== "Tools Blanket Limit" && widgetReference !== "Tools Blanket Sub Limit" && widgetReference !== "Contractors Tools Scheduled" && widgetReference !== "Question2006" ) {
                            setBuildingList.push(" " + $scope.section.sections[j].datapoints[i].defaultValue);
                        } else if (widgetReference === name) {
                            nameList = " " + $scope.section.sections[j].datapoints[i].defaultValue;
                        } else if (widgetReference === zip) {
                            zipCode = " " + $scope.section.sections[j].datapoints[i].defaultValue;
                        } else if (widgetReference === "Mortgage Holder Account") {
                            extraOeAiDetail = " " + $scope.section.sections[j].datapoints[i].defaultValue;
                        } else if (widgetReference === "AI Leased Equipment Desc" || widgetReference === "AI Vendors Product Desc" || widgetReference === "AI Owners Desc of Ops" || widgetReference === "Photography Description") {
                            OeAiDetailDesc = " " + $scope.section.sections[j].datapoints[i].defaultValue;
                        } else if (widgetReference === "Loss Payable Description") {
                            OeAiDetailDesc = " " + $scope.section.sections[j].datapoints[i].defaultValue;
                        } else if (widgetReference === "Loss Payable Clause") {
                            extraOeAiDetail = " " + $scope.section.sections[j].datapoints[i].defaultValue;
                        } else if (widgetReference === "Photography Model") {
                            photographyModel = " " + $scope.section.sections[j].datapoints[i].defaultValue;
                        } else if (widgetReference === "Photography Serial") {
                            photographySerial = " " + $scope.section.sections[j].datapoints[i].defaultValue;
                        } else if (widgetReference === "Photography Make") {
                            photographyMake = " " + $scope.section.sections[j].datapoints[i].defaultValue;
                        }
                        else if (widgetReference === "Contractors Limits" ) {
                            if(defaultValue !== false && (defaultValue == true || defaultValue == "")){
                                contractorInstallation.push(label);
                            }
                            else if(defaultValue == false){
                                continue;
                            }
                            else{
                                contractorInstallation.push(label + "  " + defaultValue);
                            }
                        }
                        else if (widgetReference === "Contractors Tools Non Owned" || widgetReference === "Tools Non Owned Limit" ) {
                            if(defaultValue !== false && (defaultValue == true || defaultValue == "")){
                                contractorNonOwned.push(label);
                            }
                            else if(defaultValue == false ){
                                continue;
                            }
                            else{
                                contractorNonOwned.push(label + "  " + defaultValue);
                            }
                        }
                        else if (widgetReference === "Contractors Employees Tools" || widgetReference === "Employees Tools Limit" ) {
                            if(defaultValue !== false && (defaultValue == true || defaultValue == "") ){
                                contractorEmployeesTools.push(label);
                            }
                            else if(defaultValue == false){
                                continue;
                            }
                            else{
                                contractorEmployeesTools.push(label + "  " + defaultValue);
                            }
                        }
                        else if (widgetReference === "Contractors Tools Blanket" || widgetReference === "Tools Blanket Limit" || widgetReference === "Tools Blanket Sub Limit" ) {
                            if(defaultValue !== false && defaultValue != "Please select" && (defaultValue == true || defaultValue == "")){
                                contractorBlanket = label;
                            }
                            else if(defaultValue == false){
                                continue;
                            }
                            else if(widgetReference === "Tools Blanket Sub Limit"){
                                contractorBlanketSubLimit = label + "  " + defaultValue;
                            }
                            else{
                                contractorBlanketLimit = (label + "  " + defaultValue);
                            }
                            var limit = contractorBlanket.concat("  " + contractorBlanketLimit);
                        }
                        else if (widgetReference === "Contractors Tools Scheduled") {
                            if(defaultValue !== false && (defaultValue == true || defaultValue == "")){
                                contractorScheduled.push(label );
                            }
                            else if(defaultValue == false){
                                continue;
                            }
                            else{
                                contractorScheduled.push(label + "  " + defaultValue);
                            }
                        }
                        else if( widgetReference === "Question2006"){
                                contractorQuestion.push(label);
                        }
                    }
                    setBuildingList.push(zipCode);
                    setBuildingList.join(", ");
                    datapointList.push(k + ": " + nameList);
                    datapointList.push(k + ": " + setBuildingList.toString());
                    if (subSectionName == "Mortgage Holder Section") {
                        $scope.mortgageAccount.push(k + ": " + extraOeAiDetail);
                    }
                    if (subSectionName == "AI Leased Equipment Section") {
                        $scope.aiLeasedDesc.push(k + ": " + OeAiDetailDesc);
                    }
                    if (subSectionName == "Loss Payable Section") {
                        $scope.aiLossPayDesc.push(k + ": " + OeAiDetailDesc);
                        $scope.aiLossPayClause.push(k + ": " + extraOeAiDetail);

                    }
                    if (subSectionName == "AI Vendors Section") {
                        $scope.aiVendorsDesc.push(k + ": " + OeAiDetailDesc);
                    }
                    if (subSectionName == "AI Owners Section") {
                        $scope.aiOwnersDesc.push(k + ": " + OeAiDetailDesc);
                    }
                    if (subSectionName == "Photography") {
                        $scope.aiPhotographyDesc.push(k + ": " + OeAiDetailDesc);
                        $scope.aiPhotographyModel.push(k + ": " + photographyModel);
                        $scope.aiPhotographySerial.push(k + ": " + photographySerial);
                        $scope.aiPhotographyMake.push(k + ": " + photographyMake);
                    }
                    if (subSectionName == "Scheduled Tools Section") {
                        $scope.aiToolsDesc.push(k + ": " + scheduledToolsDesc);
                        $scope.aiToolsLimit.push(k + ": " + scheduledToolsLimit);
                    }
                    k++;
                }
            }
            if(subSectionName === "Contractors Section"){
                contractorList.push(contractorInstallation.join(" "));
                contractorList.push(contractorNonOwned.join(" "));
                contractorList.push(contractorEmployeesTools.join(" "));
                contractorList.push(limit);
                contractorList.push(contractorBlanketSubLimit);
                contractorList.push(contractorScheduled.join(" "));
                contractorList.push(contractorQuestion.join(" "));
                return contractorList;
            }
            return datapointList;
        }

        $scope.fetchDescriptionFields = function (subSectionName) {
            var scheduledToolsDesc="";
            var scheduledToolsLimit="";
            var descriptionLimit=[];
            var k=1;
            for (var j = 0; j < $scope.section.sections.length; j++) {
                if (subSectionName === "Contractors Section") {
                    for (var x = 0; x < $scope.section.sections[j].sections.length; x++) {
                        if ($scope.section.sections[j].sections[x].name === "Scheduled Tools Section") {
                            for (var y = 0; y < $scope.section.sections[j].sections[x].datapoints.length; y++) {
                                var contractorType = $scope.section.sections[j].sections[x].datapoints[y].type;
                                var contractorWidgetReference = $scope.section.sections[j].sections[x].datapoints[y].data.widgetReference;
                                var contractorDefaultValue = $scope.section.sections[j].sections[x].datapoints[y].defaultValue;
                                if (contractorWidgetReference === "Tools Scheduled Description") {
                                    scheduledToolsDesc = " " + contractorDefaultValue;
                                }
                                else if (contractorWidgetReference === "Tools Scheduled Limit") {
                                    scheduledToolsLimit = " " + contractorDefaultValue;
                                }
                            }
                            descriptionLimit.push(k + ": " + scheduledToolsDesc);
                            descriptionLimit.push(k + ": " + scheduledToolsLimit);
                        }
                        k++;
                    }
                }
            }
            return descriptionLimit;
        }
    }
    return ApplicationSectionController;
});
