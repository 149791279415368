define('components/specialtyApplication/questions/directive/questions',['require'],function(require) {
	"use strict";

	Questions.$inject = [];

	function Questions() {
		return {
			restrict: "AE",
			scope: {
				applicationId: "="
			},
			templateUrl: "app/components/specialtyApplication/questions/template/template.html",
			controller: "SpecialtyApplicationQuestionsController"
		};
	}

	return Questions;

});
