define('components/insuredClaims/app',['require','components/insuredClaims/directive/insuredClaims','components/insuredClaims/controller/InsuredClaimsController'],function(require) {
	"use strict";

	var insuredClaims = require("components/insuredClaims/directive/insuredClaims"),
		InsuredClaimsController = require("components/insuredClaims/controller/InsuredClaimsController");

	var component = angular.module("mPortal.insuredClaims", []);

	component.directive("insuredClaims", insuredClaims);
	component.controller("InsuredClaimsController", InsuredClaimsController);

	return component;

});
