define('components/accountSummary/insuredInfo/app',['require','components/accountSummary/insuredInfo/directive/insuredInfo'],function(require) {
	"use strict";

	var insuredInfo = require("components/accountSummary/insuredInfo/directive/insuredInfo");

	var component = angular.module("mPortal.accountSummary.insuredInfo", []);

	component.directive("insuredInfo", insuredInfo);

	return component;

});
