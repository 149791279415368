define('components/submissionDetail/submissionInfo/directive/submissionInfo',['require'],function(require) {
	"use strict";

	function SubmissionInfo() {
		return {
			restrict: "AE",
			scope: {
				submission: "="
			},
			templateUrl: "app/components/submissionDetail/submissionInfo/template/template.html"
		};
	}

	return SubmissionInfo;

});
