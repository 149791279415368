define('common/config/routes',[],function() {
	return {
		"/report": {
			ngModule: "mPortal.reports",
			file: "reports/app"
		},
		"/search": {
			ngModule: "mPortal.search",
			file: "search/app"
		},
		"/accounts": {
			ngModule: "mPortal.accounts",
			file: "accounts/app"
		},
		"/user": {
			ngModule: "mPortal.user",
			file: "user/app"
		},
		"/news": {
			ngModule: "mPortal.newsArchives",
			file: "newsArchives/app"
		},
		"/legacy-login": {
			ngModule: "mPortal.login",
			file: "login/app"
		},
		"/form": {
			ngModule: "mPortal.forms",
			file: "forms/app"
		},
		"/support": {
			ngModule: "mPortal.support",
			file: "support/app"
		},
		"/training": {
			ngModule: "mPortal.training",
			file: "training/app"
		},
		"/application": {
			ngModule: "mPortal.specialtyApplication",
			file: "specialtyApplication/app"
		},
		"/m": {
			ngModule: "mPortal.shortLink",
			file: "shortLink/app"
		},
		"/error": {
			ngModule: "mPortal.error",
			file: "error/app"
		},
		"/lx": {
			ngModule: "mPortal.legacyRoutes",
			file: "legacyRoutes/app"
		},
		"/quote": {
			ngModule: "mPortal.legacyRoutes",
			file: "legacyRoutes/app"
		},
		"/renewal": {
			ngModule: "mPortal.legacyRoutes",
			file: "legacyRoutes/app"
		},
		"/account": {
			ngModule: "mPortal.legacyRoutes",
			file: "legacyRoutes/app"
		},
		"/survey": {
			ngModule: "mPortal.legacyRoutes",
			file: "legacyRoutes/app"
		},
		"/about": {
			ngModule: "mPortal.legacyRoutes",
			file: "legacyRoutes/app"
		},
		"/termsAndConditions": {
			ngModule: "mPortal.termsAndConditions",
			file: "termsAndConditions/app"
		},
		"/privacyPolicy": {
			ngModule: "mPortal.legacyRoutes",
			file: "legacyRoutes/app"
		},
		"/page": {
			ngModule: "mPortal.legacyRoutes",
			file: "legacyRoutes/app"
		},
		"/programs": {
			ngModule: "mPortal.marketedPrograms",
			file: "marketedPrograms/app"
		},
		"/account-summary": {
			ngModule: "mPortal.accountSummary",
			file: "accountSummary/app"
		},
		"/register": {
			ngModule: "mPortal.redirect",
			file: "redirect/app"
		},
		"/login": {
			ngModule: "mPortal.redirect",
			file: "redirect/app"
		},
		"/legacy-register": {
			ngModule: "mPortal.redirect",
			file: "redirect/app"
		},
		"/pay-now": {
			ngModule: "mPortal.insuredPayment",
			file: "insuredPayment/app"
		},
		"/payment": {
			ngModule: "mPortal.insuredPayment",
			file: "insuredPayment/app"
		},
		"/isc": {
			ngModule: "mPortal.isc",
			file: "isc/app"
		},
		"/faq": {
			ngModule: "mPortal.faq",
			file: "faq/app"
		},
		"/contact": {
			ngModule: "mPortal.contact",
			file: "contact/app"
		},
		"/bundle-payment": {
			ngModule: "mPortal.bundlePayment",
			file: "bundlePayment/app"
		}

	};
});
