define('accounts/app',['require','common/config/config','components/accountsSearch/app','components/submissionsSearch/app','components/policiesSearch/app','components/submissionsList/app','components/renewalsList/app','components/twoYearView/twoYearView/app','shim/overmind','common/service/search/SearchService','accounts/controller/AccountsController'],function(require) {
	"use strict";

	require("common/config/config");
	require("components/accountsSearch/app");
	require("components/submissionsSearch/app");
	require("components/policiesSearch/app");
	require("components/submissionsList/app");
	require("components/renewalsList/app");
	require("components/twoYearView/twoYearView/app");

	var overmind = require("shim/overmind");

	var SearchService = require("common/service/search/SearchService"),
		AccountsController = require("accounts/controller/AccountsController");

	var app = angular.module("mPortal.accounts", [
		"mPortal.config",
		"mPortal.components.accountsSearch",
		"mPortal.components.submissionsSearch",
		"mPortal.components.policiesSearch",
		"mPortal.components.submissionsList",
		"mPortal.components.renewalsList",
		"mPortal.components.twoYearView.twoYearView"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {

		$routeProvider.when("/accounts/", {
			templateUrl: "app/accounts/template/template.html",
			controller: "AccountsController"
		});
		
	}]);

	app.controller("AccountsController", AccountsController);
	app.factory("SearchService", SearchService);

	return app;

});
