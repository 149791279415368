define('components/productSearch/filter/directive/productSearchFilter',['require'],function(require) {
	"use strict";

	productSearchFilter.$inject = [];

	function productSearchFilter() {
		return {
			restrict: "AE",
            scope: {
                filters : "=",
                rules	: "="
            },
			templateUrl: "app/components/productSearch/filter/template/template.html",
			controller: "productSearchFilterController"
		};
	}

	return productSearchFilter;

});
