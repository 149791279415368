define('components/claimsSearch/app',['require','common/directive/app','components/claimsSearch/directive/claimsSearch','components/claimsSearch/controller/ClaimsSearchController','common/service/MessageContainer','common/service/util/PaginationService','common/service/util/RenameKeys','common/service/search/SearchResultContainer','common/service/search/SearchService','common/filter/formatMoney','common/service/MarketedProgramService'],function(require) {
	"use strict";
	
	require("common/directive/app");

	var claimsSearch = require("components/claimsSearch/directive/claimsSearch"),
		ClaimsSearchController = require("components/claimsSearch/controller/ClaimsSearchController"),
		MessageContainer = require("common/service/MessageContainer"),
		PaginationService = require("common/service/util/PaginationService"),
		RenameKeys = require("common/service/util/RenameKeys"),
		SearchResultContainer = require("common/service/search/SearchResultContainer"),
		SearchService = require("common/service/search/SearchService"),
		formatMoney = require("common/filter/formatMoney"),
		MarketedProgramService = require("common/service/MarketedProgramService");

	var component = angular.module("mPortal.components.claimsSearch", [
		"mPortal.directive"
	]);

	component.directive("claimsSearch", claimsSearch);
	component.controller("ClaimsSearchController", ClaimsSearchController);
	component.factory("MessageContainer", MessageContainer);
	component.factory("PaginationService", PaginationService);
	component.factory("RenameKeys", RenameKeys);
	component.factory("SearchResultContainer", SearchResultContainer);
	component.factory("SearchService", SearchService);
	component.factory("MarketedProgramService", MarketedProgramService);
	component.filter("formatMoney", formatMoney);


	return component;

});
