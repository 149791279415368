define( 'components/documentDeliveryPreferencesLink/directive/documentDeliveryPreferencesLink',['require'],function( require ) {
    "use strict";

    function DocumentDeliveryPreferencesLink() {
        return {
            restrict: "AE",
            scope: {
                applicationId: "@",
                submissionId: "@",
                insuredType: "@" // "Direct" if with a direct agency, "Agent" if with a non-Markel / 3rd party agency
            },
            templateUrl: "app/components/documentDeliveryPreferencesLink/template/template.html",
            controller: "DocumentDeliveryPreferencesLinkController"
        }
    }

    return DocumentDeliveryPreferencesLink;
});
