define('components/bigNews/directive/bigNews',['require'],function(require) {
	"use strict";

	BigNews.$inject = [];

	function BigNews() {
		return {
			restrict: "AE",
			scope: {
				newsItems: "="
			},
			templateUrl: "app/components/bigNews/template/template.html",
			controller: "BigNewsController"
		};
	}

	return BigNews;

});
