define('components/quickSearch/controller/QuickSearchLabelController',['require'],function(require) {
	"use strict";

	QuickSearchLabelController.$inject = ["$scope"];

	function QuickSearchLabelController( $scope ) {
		if( $scope.item.is_luxon && $scope.item.is_luxon === true)
		{
			let flags = $scope.item.flags;
			$scope.type = "luxon";
			$scope.item.label = "";

			if(flags.wc)
			{
				$scope.item.label += "WC";
			}

			if(flags.wc && (flags.profLiab || flags.package))
			{
				$scope.item.label += "/";
			}

			if(flags.profLiab)
			{
				$scope.item.label += "INVEST";
			}

			if(flags.profLiab && flags.package)
			{
				$scope.item.label += "/";
			}

			if(flags.package)
			{
				$scope.item.label += "PKG";
			}

			return;
		}

		if( $scope.item.category.toLowerCase() == "account" ){
			$scope.type = "account";
		} else if( $scope.item.category.toLowerCase( ) == "submission" || $scope.item.category.toLowerCase( ) == "policy" ) {
			$scope.type = "policy";
		} else if ( $scope.item.category.toLowerCase() == "claim" ){
			$scope.type = "claim";
		}

		$scope.renewalLabel = "";
		if( typeof $scope.item.isRenewal !== "undefined" ){
			$scope.renewalLabel = $scope.item.isRenewal ? "- Renewal" : "- New";
		}

	}

	return QuickSearchLabelController;

});
