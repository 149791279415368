define('common/service/RenewalService',['require'],function(require) {
	"use strict";

	RenewalService.$inject = ["$http", "maverickApiUrl","archApiUrl", "$q"];

	function RenewalService( $http, maverickApiUrl, archApiUrl, $q ) {

		var service = {};

		service.getUpcomingRenewals = function() {

			var renewals = [];
            var promiseArray = [
                service.getMaverickRenewalData(),
                service.getMpolicyRenewalData()
            ];

            renewals = $q.all( promiseArray ).then( function ( results ) {
                var result = [];
                var maverickRenewals = results[0];
                var mpolicyRenewals = results[1];

                angular.forEach( maverickRenewals, function( account ) {
                    account.accountViewVersion = 1;
                    result.push(account);
                });

                angular.forEach( mpolicyRenewals, function( account ) {
                    result.push(account);
                });

                result.sort(function(a, b) {
                    return new Date(b.effectiveDate) - new Date(a.effectiveDate);
                });
                
                return result.slice(0, 10);


            } );

             return renewals;

		};

		service.getMaverickRenewalData  = function() {
            return $http.get( maverickApiUrl + "quote/getUpcomingRenewals" ).then( function( result ) {
                return result.data.renewals;
            });
        };

		service.getMpolicyRenewalData = function() {
            return $http.get(  archApiUrl + "user/upcomingRenewals").then( function( result ) {
                return result.data;
            });
        };

        service.getPendingCancellation = function() {
            return $http.get(  archApiUrl + "user/pending-cancellation").then( function( result ) {
                return result.data.slice(0, 10);
            });
        };

        return service;

	}

	return RenewalService;

});
