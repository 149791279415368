define('components/message/list/controller/messageListController',[],function() {

	MessageListController.$inject = ["$scope","$modal","$q", "$window","archApiUrl","MessageService", "UserProfile"];

	function MessageListController( $scope , $modal , $q , $window , archApiUrl , MessageService , UserProfile  ) {
		$scope.current_thread = {};
		$scope.btn = null;
		$scope.user =  UserProfile;

		$scope.status = {
			sending: false
		};

		$scope.validate = function(thread){
			thread.errors = { 
								visible		: false,
								message		: !thread.form.message
							}

			angular.forEach(thread.errors,function(error,key){
				if(key != 'visible' &&  error && !thread.errors.visible)thread.errors.visible = true;
			});
		}

        $scope.openFileUpload = function(thread) {
            var formAction = archApiUrl + "account/" + $scope.account.accountId+ "/upload";
            var fileUploadModal = $modal.open({
                template: "<div upload-attachment upload-action='\"" + formAction + "\"'></div>",
                controller: "GenericModalController"
            });

            fileUploadModal.result.then(function(fileContent) {
                thread.form.fileNames.push( fileContent.data.fileName );
                thread.form.upload.push( fileContent.form.file );
            });
        };

        $scope.clearSearch = function(thread){
            thread.form.fileNames = [];
            thread.form.upload = [];
        }

        $scope.removeItem = function (x,thread) {
            thread.form.fileNames.splice(x,1);
            thread.form.upload.splice(x, 1);
        }

		$scope.send = function($event,thread){

			$scope.btn = angular.element($event.currentTarget);
		
			$scope.validate(thread);

			if(thread.errors.visible)return;

			if(!$scope.status.sending)
			{
				$scope.status.sending = true;
				$scope.btn.attr('disabled',true);
				//If Thread doesn't have Masterquote, then setting systemName as "icon"
				var systemName = ($scope.account.accountId == $scope.account.piNumber)? 'icon' : (thread.masterQuoteId == null)? 'icon' : 'mpolicy';

				MessageService.sendMessage(	{
												system			: systemName,
												accountId		: (systemName == 'icon') ? $scope.account.piNumber : $scope.account.accountId,
												message			: thread.form.message,
												submissionId	: thread.masterQuoteId,
												threadId		: thread.threadId,
												fromAddress		: $scope.user.email,
												policyNumber	: thread.policyNumber,
												typeId			: thread.typeId,
												insuredName     : thread.insuredName,
												agencyId        : thread.agencyId,
												programCode     : thread.programCode,
                    							uploadAttachments: thread.form.upload
											}
				).then(function( response ) {
					$scope.status.sending = false;

					//show message sent
					$modal.open({
						templateUrl: "app/components/message/modal/template/sent.html",
						controller: "GenericModalController"
					});

					thread.form.message = "";
					$scope.cancel(thread);
					$scope.btn.attr('disabled',false);

					$scope.$emit('set-message-view',{view:'list',refresh:true});
				});
			}
			
		};

		$scope.reply = function(thread){
			thread.reply = true;
		}

		$scope.cancel = function(thread){
			thread.reply = false;
		}

		$scope.thread_click = function(thread){
			$scope.current_thread = thread;
			if(thread.messages.length)thread.open = (thread.open)?false:true;

			if(thread.messages.length == 0)
			{
				var p = [  MessageService.getThread({accountId:$scope.account.accountId , threadId:thread.threadId , system : ($scope.account.accountId == $scope.account.piNumber)? 'icon' : 'mpolicy'}) ];
				
				$q.all(p).then(function(results){
					$scope.current_thread.messages  = results[0];
					$scope.current_thread.open 		= true;

					//set message as read
					if($scope.current_thread.totalUnread > 0)MessageService.markRead({system:'mpolicy',accountId:$scope.account.accountId,threadId:$scope.current_thread.threadId});
					//unreadCount to 0
					$scope.current_thread.totalUnread = 0;					
				});
			}

			return false;
		}

		$scope.thread_forward = function(thread){
			//show forward modal
			$modal.open({
				templateUrl: "app/components/message/list/template/forward.html",
				controller: "forwardModalController",
				resolve: {
						thread	: function() { return thread; },
						account	: function() { return $scope.account},
						user 	: function() { return $scope.user}
					}
			});
		}
		$scope.getDocument = function ( thread, messageId, documentId ) {
			var system = ($scope.account.accountId == $scope.account.piNumber) ? 'icon' : 'mpolicy';
			$window.open( archApiUrl + "account/" + $scope.account.accountId + "/thread/" + thread.threadId + "/message/" + messageId + "/attachment/" + documentId + "/" + system );
		};
		$scope.thread_pdf = function(thread){
			var system = ($scope.account.accountId == $scope.account.piNumber)? 'icon' : 'mpolicy';
			$window.open(archApiUrl + "account/" + $scope.account.accountId + "/thread/" + thread.threadId + "/display/attachment/pdf/" + system ,"_blank");
		}

		$scope.thread_print = function(thread){
			var system = ($scope.account.accountId == $scope.account.piNumber)? 'icon' : 'mpolicy';
			$window.open(archApiUrl + "account/" + $scope.account.accountId + "/thread/" + thread.threadId + "/display/inline/pdf/" + system ,"_blank");
		}
	}

	return MessageListController;

});
