define('components/mvpSwitch/controller/mvpSwitchController',[],function() {

	mvpSwitchController.$inject = [ "$scope","$window", "$location", "$q" , "UserProfile", "UserService","ciamApiUrl"];

	function mvpSwitchController( $scope, $window, $location , $q , UserProfile, UserService,ciamApiUrl) {
		$scope.show = false;

		$scope.accounts = UserProfile.mvp.accounts;
		$scope.agency_accounts  = [];
		$scope.insured_accounts = [];
		$scope.show_filter = false;

		if($scope.accounts.length > 10){
			$scope.show_filter = true;
		}

		
		for(var i=0;i < $scope.accounts.length;i++)
		{
			var view_account = $scope.accounts[i];

			if(view_account['pe_id'] == UserProfile.userId)
			{
				$scope.account = view_account;
			}

			if(view_account.pe_type == 10)
			{
				$scope.insured_accounts.push(view_account);
			}
			else
			{
				$scope.agency_accounts.push(view_account);
			}
		}	

		$scope.logout = function() {
			UserService.logout().then(function() {
				$window.location = ciamApiUrl + 'auth/login-request/mportal';
			});
		};

		$scope.switch = function(view_account)
		{
			var index = $scope.accounts.indexOf(view_account);

			var p = [ UserService.clear()];

                $q.all(p).then(function(results){
           
                    var result = results[0];

                   	if(result.success)
					{
						var n = [ UserService.switchMvpAccount(index)];

		                $q.all(n).then(function(results){
		           
		                    var result = results[0];

		                   	if(result.success)
							{
								$window.location.href = '/';
							}
		                }); 
					}
                }); 
		};
	}
	
	return mvpSwitchController;
});
