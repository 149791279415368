define('components/specialtyApplication/questions/config/widgets/EligibilityInformationWidget',['require'],function(require) {

	"use strict";

	var widget = {};

	widget.getStructure = function() {

		return {
			"EligibiltyDataPoint": {
				wrapper: ["eligibilityRow"], fieldGroup:
						{
							"Waiver Provisions" :{ wrapper: [ "verticalFullRowRadio", "verticalFullRowError" ], type: "yesNoToggle", wrapperOverride: true, orderNumber: 1 },
							"Waiver Signed Parent" : {wrapper: null, type:"Label" , wrapperOverride: true, orderNumber: 2},
							"Waiver Risks" : {wrapper: null, type: "Label" , wrapperOverride: true, orderNumber: 3},
							"Waiver Release" : {wrapper: null,type: "Label" , wrapperOverride: true, orderNumber: 4},
							"Ineligibility Label" :{ wrapper: null, type: "label", wrapperOverride: true, orderNumber: 5 },
							"Operating Residence": {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 6, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "Yes",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility 1" : {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 7, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "No",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility 2" : {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 8, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "No",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility 3" : {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 9, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "No",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility 4" : {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 10, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "No",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility 5" : {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 11, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "No",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Stand Alone BB": {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 12, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "Yes",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility 6" : {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 13, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "No",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility 7" : {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 14, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "No",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility 8" : {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 15, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "No",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Manufacture Products": {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 16, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "Yes",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility 9" : {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 17, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "No",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility 10": {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 18, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "No",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Safety Signage": {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 19, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "Yes",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility 11": {wrapper: null, type: "CheckBoxWithMargin", wrapperOverride: true, orderNumber: 20, data: {
								blockingGroup: "healthClubEligibility",
								NoneElement: "Yes",
								actions: [
									{
										methodName: "registerBlockingDataPoint",
										contexts: [ "update"],
										programs: []
									},
									{
										methodName: "updateBlockingDataPoint",
										contexts: [ "save"],
										programs: []
									}
								]
							}},
							"Ineligibility Warning": {wrapper: null, type: "label", wrapperOverride: true, orderNumber: 21}
					}
			}
		}
	};
	
	widget.configureFormly = function ( formlyConfig, formlyApiCheck ) {

		formlyConfig.setWrapper({
			name: "eligibilityRow",
			template: '<div class="row-fluid"><formly-transclude></formly-transclude></div>'
		});

		formlyConfig.setType({
			"extends": "checkbox",
			name: "CheckBoxWithMargin",
			template:
			'<label class="easy_checkbox" style="margin-left: 41.4px">' +
			'<input type="checkbox"' +
			'class="formly-field-checkbox"' +
			'ng-model="model[options.key]">' +
			'{{to.label}}' +
			'{{to.required ? \'*\' : \'\'}}' +
			'<span class="checkmark"></span>' +
			'<formly-transclude></formly-transclude>'+
			'</label>'
		});

	};

	return widget;

});
