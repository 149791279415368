define('components/paymentLink/controller/PaymentLinkController',['require'],function(require) {
	"use strict";

	PaymentLinkController.$inject = ["$scope", "PaymentService"];

	function PaymentLinkController( $scope, PaymentService ) {

		$scope.openPaymentWindow = function() {
			PaymentService.openPaymentWindow( $scope.accountId, $scope.billingSystem );
		};

	}

	return PaymentLinkController;

});
