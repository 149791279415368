define('components/accountsSearch/controller/AccountsSearchController',['require'],function(require) {
	"use strict";

	AccountsSearchController.$inject = ["$scope", "SearchService", "SearchResultContainer"];

	function AccountsSearchController( $scope, SearchService, SearchResultContainer ) {

		$scope.searchData = {
			states: []
		};

		$scope.searchParams = {
			insuredName: "",
			effectiveDateFrom: "",
			effectiveDateTo: "",
			insuredAccount: "",
			fein: "",
			dbaName: "",
			state: "All"
		};

		$scope.searchResults = new SearchResultContainer({ currentPage: 1, itemsPerPage: 25 });

		$scope.searchDataResults.then(function( result ) {
			$scope.searchData.states = result.state;
		});

		$scope.getResults = function() {
			SearchService.getAccountsSearchResults( $scope.searchParams, $scope.searchResults );
		};

	}

	return AccountsSearchController;

});
