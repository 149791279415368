define( 'components/specialtyApplication/pricingDetails/app',['require','components/specialtyApplication/pricingDetails/animalMortality/app','components/specialtyApplication/pricingDetails/directive/pricingDetails','common/filter/formatMoney'],function ( require ) {
    "use strict";

    require("components/specialtyApplication/pricingDetails/animalMortality/app");

    var pricingDetails = require( "components/specialtyApplication/pricingDetails/directive/pricingDetails" ),
        formatMoney = require( "common/filter/formatMoney" );

    var component = angular.module( "mPortal.components.specialtyApplication.pricingDetails", [
        "mPortal.components.specialtyApplication.pricingDetails.animalMortality"
    ] );

    component.filter( "formatMoney", formatMoney );
    component.directive( "pricingDetails", pricingDetails );

    return component;

} );
