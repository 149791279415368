define('components/userAccountSettings/userCredentials/directive/userCredentials',['require'],function(require) {
	"use strict";

	function UserCredentials() {
		return {
			restrict: "AE",
			scope: {
				user: '=',
				showKey: '='
			},
			templateUrl: "app/components/userAccountSettings/userCredentials/template/template.html",
			controller: "UserCredentialsController"
		};
	}

	return UserCredentials;

});
