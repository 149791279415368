define('components/lossRuns/app',['require','components/lossRuns/directive/lossRuns','components/lossRuns/controller/LossRunsController','common/service/LossRunsService'],function(require) {
	"use strict";

	var lossRuns = require("components/lossRuns/directive/lossRuns"),
		LossRunsController = require("components/lossRuns/controller/LossRunsController"),
		LossRunsService = require("common/service/LossRunsService");

	var component = angular.module("mPortal.lossRuns", []);

	component.directive("lossRuns", lossRuns);
	component.controller("LossRunsController", LossRunsController);
	component.factory("LossRunsService", LossRunsService);

	return component;

});
