define('components/productSearch/container/app',['require','components/productSearch/filter/app','components/productSearch/list/app','components/productSearch/container/directive/productSearchContainer','components/productSearch/container/controller/productSearchContainerController','common/service/productSearch/ProductSearchService','common/service/productSearch/RuleService','common/service/productSearch/FilterService','common/service/productSearch/productService','common/service/MarketedProgramService','common/service/specialtyApplication/SubmissionStore','common/service/wizard/Wizard','common/service/LogDataService','moment'],function(require) {
	"use strict";

	require("components/productSearch/filter/app");
	require("components/productSearch/list/app");

	var productSearchContainer 				= require("components/productSearch/container/directive/productSearchContainer"),
		productSearchContainerController 	= require("components/productSearch/container/controller/productSearchContainerController"),
		productSearchService 				= require("common/service/productSearch/ProductSearchService"),
		ruleService 						= require("common/service/productSearch/RuleService"),
		filterService 						= require("common/service/productSearch/FilterService"),
		productService 						= require("common/service/productSearch/productService"),
		MarketedProgramService				= require("common/service/MarketedProgramService"),
		SubmissionStore 					= require("common/service/specialtyApplication/SubmissionStore"),
		Wizard 								= require("common/service/wizard/Wizard"),
        logDataService 						= require("common/service/LogDataService"),
		moment 								= require("moment");

	var component 							= angular.module("mPortal.components.productSearch.container", ['mPortal.components.productSearch.filter',
																											'mPortal.components.productSearch.list']);

	component.directive("productSearchContainer", productSearchContainer);
	component.controller("productSearchContainerController", productSearchContainerController);

	component.factory('MarketedProgramService', MarketedProgramService);
	component.factory("ProductSearchService", productSearchService);
	component.factory("LogDataService", logDataService);
	component.factory("RuleService", ruleService);
	component.factory("FilterService", filterService);
	component.factory("ProductService", productService);

	component.factory("Wizard",Wizard);
	component.factory("SubmissionStore", SubmissionStore);

	component.value("moment", moment);


	return component;

});
