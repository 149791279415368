define('components/insuredRegistration/accountLookup/directive/accountLookup',['require'],function(require) {
	"use strict";

	function AccountLookup() {
		return {
			restrict: "AE",
			scope: {
				headerText: "@",
				onNextStep: "=",
				condensed: "=",
				includeEmail: "=",
				submitButtonText: "@",
				refresh: "=",
				insuredAccountNumber:"="
			},
			templateUrl: "app/components/insuredRegistration/accountLookup/template/template.html",
			controller: "AccountLookupController"
		};
	}

	return AccountLookup;

});
