define( 'common/filter/trustHtml',['require'],function ( require ) {
	"use strict";

	TrustHtml.$inject = ["$sce"];

	function TrustHtml( $sce ) {
		return function( html ) {
			return $sce.trustAsHtml( html );
		};
	}

	return TrustHtml;

} );
