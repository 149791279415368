define('components/twoYearView/twoYearView/controller/TwoYearViewController',['require'],function (require) {
	"use strict";

	TwoYearViewController.$inject = ["$scope", "$filter", "$timeout", "moment", "SearchService", "UserProfile"];

	function TwoYearViewController($scope, $filter, $timeout, moment, SearchService, UserProfile) {
		var defaultSelectValue = "All",
			defaultBalanceFilterValue = "All balances";

		$scope.preFilterValues = {
			effectiveDateFrom: "",
			effectiveDateTo: "",
			status: "",
			program: ""
		};
		$scope.preFilterOptions = {
			status: [],
			program: []
		};

		$scope.filterValues = {
			name: "",
			effectiveDateFrom: "",
			effectiveDateTo: "",
			status: defaultSelectValue,
			program: defaultSelectValue,
			balance: defaultBalanceFilterValue,
			billType: defaultSelectValue,
			pageSize: 10
		};

		$scope.filterOptions = {
			status: [],
			program: [],
			billType: [defaultSelectValue, "Agency bill", "Direct bill"],
			balance: [defaultBalanceFilterValue, "Balance <> $0"],
			pageSize: [10, 25, 50, 100]
		};

		$scope.uiState = {
			loading: true,
			noResults: false,
			maverickExcluded: UserProfile.isProlificMaverickAgent,
			dataOverflow: false
		};

		SearchService.getTwoYearView().then(function (data) {
			$scope.setTwoYearData(data);
		});


		$scope.refreshView = function () {
			$scope.uiState.loading = true;
			SearchService.getTwoYearView($scope.preFilterValues).then(function (data) {
				$scope.setTwoYearData(data);
			});
		};


		$scope.setTwoYearData = function (data) {
			$scope.filterOptions.status = [];
			SearchService.getSearchData().then(function (data) {
				if (data) {
					let policiesStatus = data.policiesStatus || [];
					let submissionsStatus = data.submissionsStatus || [];
					$scope.policiesAndSubmissionStatus = [...policiesStatus, ...submissionsStatus]
						.filter((item, index, arr) => index === arr.findIndex(t => t.NAME === item.NAME))
						.map(item => item.NAME);
					$scope.filterOptions.status = $scope.policiesAndSubmissionStatus;
					$scope.preFilterOptions.status = $scope.policiesAndSubmissionStatus;
					$scope.preFilterOptions.status = $scope.preFilterOptions.status.sort();
				}
				else {
					$scope.policiesAndSubmissionStatus = [];
				}
			});

			SearchService.getTwoYearView().then(function (data) {
				if (data) {
					let statusList = data.statusList || [];
					$scope.policiesAndSubmissionStatus = [
						...$scope.policiesAndSubmissionStatus,
						...statusList
					]
				} else {
					$scope.policiesAndSubmissionStatus = [];
				}
			});

			$scope.filterOptions.program = [];
			angular.forEach(data.accounts, function (account) {
				angular.forEach(account.quotes, function (quote) {

					if ($scope.filterOptions.status.indexOf(quote.status) === -1) {
						$scope.filterOptions.status.push(quote.status);
					}

					if ($scope.filterOptions.program.indexOf(quote.program) === -1) {
						$scope.filterOptions.program.push(quote.program);
					}
				});
			});
			$scope.preFilterOptions.program = data.programList;
			$scope.preFilterOptions.program = $scope.preFilterOptions.program.sort();

			$scope.filterOptions.program = $scope.filterOptions.program.sort();
			$scope.filterOptions.program.unshift(defaultSelectValue);

			$scope.accounts = data.accounts;
			if (data.dataOverflow) {
				$scope.uiState.dataOverflow = true;  // once we detect data overflow, we leave this set to true and never reset it back to false.
			}
			$scope.uiState.noResults = $scope.accounts.length == 0;
			$scope.uiState.loading = false;
		};

		$scope.resetFilters = function () {
			// rollback the view value of name in case we are currently debouncing a change
			$scope.twoYearFilterForm.name.$rollbackViewValue();

			$scope.filterValues.name = "";
			$scope.filterValues.effectiveDateFrom = "";
			$scope.filterValues.effectiveDateTo = "";
			$scope.filterValues.status = defaultSelectValue;
			$scope.filterValues.program = defaultSelectValue;
			$scope.filterValues.balance = defaultBalanceFilterValue;
			$scope.filterValues.billType = defaultSelectValue;
		};

	}

	return TwoYearViewController;

});
