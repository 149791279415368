define( 'common/service/specialtyApplication/ApplicationStateStore',[],function() {
    "use strict";

    ApplicationStateStore.$inject = [ "DatapointModificationService", "EligibilityGuidelineService", "SubmissionStore" ];

    function ApplicationStateStore( DatapointModificationService, EligibilityGuidelineService, SubmissionStore ) {
        var store = {
            last: {
                saved: null,
                touched: null
            },
            points: {
                byKey: {},
                byReference: {}
            },
            programs: {},
            pointMethods: {}
        };

        /*
        This populates the pointMethods key:value map with the names of all of the methods in the supporting service
        objects and provides pointers to them.  Datapoints can then be configured to use those methods yet not worry
        about which supporting service is involved.
        */
        store.populateActionMethods = function() {
            var methodObjects = [ DatapointModificationService, EligibilityGuidelineService ];
            for( var mo = 0; mo < methodObjects.length; mo++ ) {
                var objProperties = Object.getOwnPropertyNames( methodObjects[ mo ] );
                for( var prop = 0; prop < objProperties.length; prop++ ) {
                    if( typeof methodObjects[ mo ][ objProperties[ prop ] ] === "function" ) {
                        this.pointMethods[ objProperties[ prop ] ] = {
                            serviceName: methodObjects[ mo ],
                            methodName: objProperties[ prop ]
                        }
                    }
                }
            }
        };

        store.populateActionMethods();

        // The programs are used to ensure a widget-configured method is only invoked when the submission relates to certain programs
        store.populatePrograms = function() {
            this.programs = {};
            for( var p = 0; p < SubmissionStore.programs.length; p++ ) {
                if( SubmissionStore.programs[ p ].referenceString ) {
                    this.programs[ SubmissionStore.programs[ p ].referenceString.toLowerCase() ] = true;
                }
            }
        };

        store.populateEligibilityGuidelines = function( data ) {
            EligibilityGuidelineService.populateInitialGuidelines( data );
        };

        store.evaluateBlockingDataPoints = function () {
            return DatapointModificationService.evaluateBlockingDataPoints();
        };

        store.createStatePoint = function( datapoint ) {
            var storePoint = {
                key: datapoint.key,
                value: typeof datapoint.defaultValue === "object" ? {} : datapoint.defaultValue,
                reference: datapoint.data.reference,
                enumeration: datapoint.data.enumeration,
                sectionId: datapoint.data.sectionId,
                formLabel: datapoint.templateOptions.label,
                reportLabel: datapoint.data.reportLabel ? datapoint.data.reportLabel : datapoint.templateOptions.label,
                userActions: { touched: false, saved: false }
            };

            if( typeof datapoint.defaultValue === "object" ) {
                this.updateValue( datapoint, storePoint );
            }

            return storePoint;

        };

        store.processDatapoint = function( datapoint, context ) {
            var storeDatapoint = this.points.byKey[ datapoint.key ];
            // Invoke any actions associated with the datapoint before the storeDatapoint is updated to reflect datapoint changes
            this.invokeDatapointActions( datapoint, storeDatapoint, context );
            switch( context ) {
                case "update":
                    this.updateDatapoint( datapoint, storeDatapoint );
                    break;
                case "save":
                    this.updateState( datapoint, storeDatapoint, "saved" );
                    break;
                case "touch":
                    this.updateState( datapoint, storeDatapoint, "touched" );
                    break;
                case "delete":
                    this.deleteDatapoint( datapoint, storeDatapoint );
                    break;
            }
        };

        store.invokeDatapointActions = function( datapoint, storeDatapoint, context ){
            /*
            If the datapoint is configured via a widget AND the action context is performed as part of the widget (when
            the section containing the widget is displayed or the datapoint is saved or touched by the user), methods
            to be invoked based on the context and on the programs associated in the submission can be configured in the
            widget configuration for the datapoint
            */
            if( datapoint.data.actions ) {

                for( var a = 0; a < datapoint.data.actions.length; a++ ) {

                    var action = datapoint.data.actions[ a ];
                    if( action.contexts && action.contexts.indexOf( context ) > -1 ) {
                        var validProgramAction = ( action.programs && action.programs.length > 0 ) ? false : true;
                        if( !validProgramAction ) {
                            for( var program = 0; program < action.programs.length; program++ ) {
                                if( this.programs[ action.programs[ program].toLowerCase() ] ) {
                                    validProgramAction = true;
                                }
                            }
                        }

                        if( validProgramAction ) {
                            if( this.pointMethods[ action.methodName ] ) {
                                this.pointMethods[ action.methodName ].serviceName[ this.pointMethods[ action.methodName ].methodName ]( datapoint, storeDatapoint, context, this.programs )
                            } else {
                                console.log( "Datapoint action method " + action.methodName + " not found/executed." );
                            }
                        }
                    }

                }
            } else {
                /*
                Certain actions like the deletion of a section will only pass the raw datapoint (un-decorated by widget
                configuration data) to this method.  If you need methods to be invoked for one of the those actions,
                currently the only option is to invoke a method based on the datapoint reference value formatted as
                shown below.
                */
                if (datapoint && datapoint.data && datapoint.data.reference) {
                    var referenceMethod = datapoint.data.reference.replace(/\s/g,"_").toLowerCase();
                    if( this.pointMethods[ referenceMethod ] ) {
                        this.pointMethods[ referenceMethod ].serviceName[ this.pointMethods[ referenceMethod ].methodName ]( datapoint, storeDatapoint, context, this.programs )
                    }
                }
            }

        };

        store.updateValue = function( datapoint, storeDatapoint ) {
            if( datapoint.defaultValue != null && typeof datapoint.defaultValue === "object" ) {
                storeDatapoint.value = {};
                var defaultValueProperties = Object.keys( datapoint.defaultValue );
                for( var p = 0; p < defaultValueProperties.length; p++ ) {
                    storeDatapoint.value[ defaultValueProperties[ p ] ] = datapoint.defaultValue[ defaultValueProperties[ p ] ];
                }
            } else {
                storeDatapoint.value = datapoint.defaultValue;
            }
        };

        store.deleteDatapoint = function( datapoint, storeDatapoint ) {
            // Possible that the datapoint being deleted hasn't been added to the store during this user session, so check for existence
            if( storeDatapoint ) {
                var referenceIndex = this.points.byReference[ storeDatapoint.reference ].indexOf( storeDatapoint );
                if( referenceIndex !== -1 ) {
                    this.points.byReference[ storeDatapoint.reference ].splice( referenceIndex, 1 );
                    if( this.points.byReference[ storeDatapoint.reference].length === 0 ) {
                        delete this.points.byReference[ storeDatapoint.reference ];
                    }
                }
                delete this.points.byKey[ datapoint.key ];
            }
        };

        store.updateDatapoint = function( datapoint, storeDatapoint ) {
            if( !storeDatapoint ) {
                var storeDatapoint = this.createStatePoint( datapoint );
                this.points.byKey[ storeDatapoint.key ] = storeDatapoint;
                if( this.points.byReference[ storeDatapoint.reference ] ) {
                    this.points.byReference[ storeDatapoint.reference ].push( storeDatapoint );
                } else {
                    this.points.byReference[ storeDatapoint.reference ] = [ storeDatapoint ];
                }
            } else {
                this.updateValue( datapoint, storeDatapoint );
                storeDatapoint.enumeration = datapoint.data.enumeration;
            }
        };

        store.updateState = function( datapoint, storeDatapoint, stateChange  ) {
            if( !storeDatapoint ) {
                var storeDatapoint = this.createStatePoint( datapoint );
                this.points.byKey[ storeDatapoint.key ] = storeDatapoint;
                if( this.points.byReference[ storeDatapoint.reference ] ) {
                    this.points.byReference[ storeDatapoint.reference ].push( storeDatapoint );
                } else {
                    this.points.byReference[ storeDatapoint.reference ] = [ storeDatapoint ];
                }
            }
            storeDatapoint.userActions[ stateChange ] = true;
            this.last[ stateChange ] = storeDatapoint;
            switch( stateChange ) {
                case "saved":
                    this.updateValue( datapoint, storeDatapoint );
                    datapoint.data.wasTouched = true;
                    break;
                case "touched":
                    datapoint.data.wasTouched = true;
                    break;
            }
        };

        store.getDatapoint = function( datapoint ) {
            return this.points.byKey[ datapoint.key ];
        };

        store.sortEnumeratedDatapoints = function( referenceArray ) {
            for( var d = 0; d < referenceArray.length; d++ ) {
                var datapoints = this.points.byReference[ referenceArray[ d ] ];
                if( datapoints ) {
                    datapoints.sort(function( a, b ) {
                        return a.enumeration - b.enumeration;
                    });
                }
            }
        };

        // Will only return the first datapoint of any of the given references
        store.findDatapointsByReferences = function( referenceArray ) {
            var datapoints = [];
            for( var ref = 0; ref < referenceArray.length; ref++ ) {
                if( this.points.byReference[ referenceArray[ ref ] ] ) {
                    datapoints.push( this.points.byReference[ referenceArray[ ref ] ][ 0 ] )
                }
            }
            return datapoints;
        };

        store.getDatapointByReference = function( reference ) {
            var datapoints = this.points.byReference[ reference ];
            return ( datapoints && datapoints.length == 1 ) ? datapoints[ 0 ] : datapoints;
        };

        store.getEnumeratedDatapoints = function( reference ) {
            return this.points.byReference[ reference ];
        };

        return store;
    }

    return ApplicationStateStore;

});
