define('components/widget/description-eligibility-guidelines/directive/descriptionEligibilityGuidelines',['require'],function(require) {
    "use strict";

    descriptionEligibilityGuidelines.$inject = [];

    function descriptionEligibilityGuidelines() {
        return {
            restrict: "AE",
            scope:{},
            templateUrl: "app/components/widget/description-eligibility-guidelines/template/template.html",
            controller: "DescriptionEligibilityGuidelinesController"
        };
    }

    return descriptionEligibilityGuidelines;

});
