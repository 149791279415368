define('components/productSearch/container/controller/productSearchContainerController',['require'],function(require) {
	"use strict";

	productSearchContainerController.$inject = ["$scope", 
                                                "$filter", 
                                                "$location",
                                                "$q", 
                                                "moment", 
                                                "$sce", 
                                                "UserProfile",
                                                "Wizard",
                                                "ProductSearchService", 
                                                "RuleService",
                                                "FilterService",
                                                "ProductService",
                                                "MarketedProgramService" ,
                                                "AgencyService", 
                                                "SubmissionStore",
                                                "$route",
                                                "$routeParams",
                                                "$timeout",
                                                "$window",
                                                "LogDataService"];

	function productSearchContainerController(  $scope, 
                                                $filter, 
                                                $location , 
                                                $q ,
                                                moment ,
                                                $sce , 
                                                UserProfile, 
                                                Wizard, 
                                                ProductSearchService, 
                                                RuleService,
                                                FilterService,
                                                ProductService,
                                                MarketedProgramService ,
                                                AgencyService , 
                                                SubmissionStore , 
                                                $route, 
                                                $routeParams, 
                                                $timeout, 
                                                $window, 
                                                LogDataService ){

        $scope.rule_service                 = RuleService;
        $scope.disallowed_programs          = [];
        $scope.business_type_descriptions   = [{id:0,label:'Please wait loading..',tags:[]}];
        $scope.account                      = UserProfile;
        $scope.store                        = SubmissionStore;
        $scope.loading                      = { business_type: false, products: false , descriptions : false};
        $scope.transactionUUID              = '';
        $scope.state                        = UserProfile.defaultState;
        $scope.date                         = moment(Date.now()).format('MM/DD/YYYY');
		$scope.isUnAuthenticatedUser        = SubmissionStore.unAuthenticatedCL ? true : false;
        $scope.isUnAuthenticatedDirect      = SubmissionStore.isUnAuthenticatedDirect ? true : false;
        $scope.account.debug                = { 
                                                display:false,
                                                classcodes:false,
                                                products:false,
                                                programs:false,
                                                rules:true,
                                                continue:true,
                                                datapoints:false,
                                                wizard:true
                                             };

        $scope.business_type_text           = 'Enter a keyword or industry';
        $scope.quoteFein = 0;


        //reset wizard on home page
        Wizard.reset();

        $scope.user = UserProfile;
        if (!$scope.user.isLoggedIn) {
            $scope.date = moment(Date.now()).add(1, "d").format("MM/DD/YYYY");
          }    
    
        if( $scope.user.type == "Agent" ||$scope.user.agencyEmulation )
        {
            $scope.business_type_text       = 'Enter a keyword, industry, or class code';
        }

        $scope.init_data = function()
        {
            $scope.business_type_input          = '';
            $scope.business_type_tag            = '';
            $scope.business_type                = null;
            $scope.business_type_id             = '';
            
            $scope.filters                      =  {     
                                                        source      : [], 
                                                        required    : [], 
                                                        optional    : [], 
                                                        hidden      : [],
                                                        state       : { value: $scope.state, source : [] , type: { display: 'autocomplete' },default:UserProfile.defaultState},
                                                        date        : { value : $scope.date, type: { display: 'calendar'} }
                                                    };

            
            $scope.products                     =  { 
                                                        id          : [],
                                                        source      : [], 
                                                        allowed     : [], 
                                                        selected    : [], 
                                                        unselected  : [], 
                                                        ineligible  : [] 
                                                    };

            $scope.rules                        =  { 
                                                        source      : [],
                                                        log         : { id:[], arr:[] },
                                                        classcode   : [], 
                                                        inclusion   : [], 
                                                        exclusion   : [], 
                                                        override    : [], 
                                                        package     : [], 
                                                        program     : [], 
                                                        datapoint   : [], 
                                                        eligibility : [], 
                                                        ineligible  : [], 
                                                        unavailable : [], 
                                                        hide        : [], 
                                                        linked      : [],
                                                        blurb       : [],
                                                        naics       : [],
                                                        productSystem : [],
	                                                    noProductsAvailableMessage : [],
                                                        product_availability : []
                                                    };
            
            $scope.states                       = ProductSearchService.getStates();
            $scope.programs                     = [];
            $scope.continue                     = true;

            $scope.bo_classcode                 = null;
            $scope.wc_classcode                 = null;
            $scope.naics_code                   = null;
            $scope.eligibility                  =   { 
                                                        classcode : { state : null, effectiveDate: null, bo: null , wc:null , result : null , complete: false},
                                                        program :   { state : null, effectiveDate: null, programs:[] , result : null , complete: false} 
                                                    };
            $scope.businessTypeTextIsRequired   = true;


            
            //state source hack for top drop down
            for(var j = 0; j < $scope.states.length; j++)
            {
                var option = $scope.states[j];
                option.selected = ( $scope.filters.state.value == option.id); 
                if(option.selected)
                {
                    $scope.filters.state.display_value = option.label;
                }

                $scope.filters.state.source.push({id:option.id,value:option.id,label:option.label,selected:option.selected});
            }

        }

        var p = [ LogDataService.getUUID() ];

        $q.all(p).then(function(results) {
            $scope.transactionUUID = results[0].transactionUUID
        })

        $scope.select_description = function( event, selected) {

            var state = $scope.filters.state.value;
            var date  = $scope.filters.date.value;

            $scope.init_data();

            $scope.business_type_input  = selected.item.value;
            $scope.business_type_id     = selected.item.businessTypeID;

            if(!selected.item.programID)
            {
                if($scope.includeLocation != 'home')
                {
                    $scope.get_business_type();
                }
                else
                {
                    $scope.account.ps                            = {};
                    $scope.account.ps.label                      = $scope.business_type_input;
                    $scope.account.ps.business_type_id           = $scope.business_type_id;
                    $scope.account.ps.business_type_descriptions = $scope.business_type_descriptions;
                    $scope.account.ps.state                      = state;
                    $scope.account.ps.date                       = date;

                    $location.path('/application/search/' + $scope.business_type_id);
                }
            }
            else
            {
                //program allowed forward to programs page
                if($.inArray(selected.item.marketedProgramReferenceString,$scope.disallowed_programs) == -1)
                {
                    $location.path( "programs/" + selected.item.programID );
                }
                else
                {
                    $location.path('/application/search/' + $scope.business_type_id)
                }
            }
                
            if(UserProfile.debug.display)console.log('btype : ',$scope.business_type);
        };

        $scope.get_business_type = function()
        {
            $scope.loading.business_type = true;

             var p = [  ProductSearchService.getBusinessType({businessTypeID:$scope.business_type_id})];

            $q.all(p).then(function(results){
                $scope.business_type = results[0];
                $scope.get_business_type_details();
            });

            return false;
        }

        $scope.get_business_type_details = function()
        {
            $scope.loading.business_type = true;

             var p = [  ProductSearchService.getBusinessTypeFilters({businessTypeID:$scope.business_type_id}), 
                        ProductSearchService.getBusinessTypeProducts({businessTypeID:$scope.business_type_id}),
                        ProductSearchService.getBusinessTypeRules({businessTypeID:$scope.business_type_id})];

            $q.all(p).then(function(results){
                $scope.filters.source             = results[0];
                $scope.products.source            = results[1];
                $scope.rules.source               = results[2];

                //seperate the filters and link the option sources
                for(var i = 0; i < $scope.filters.source.length; i++)
                {
                    var filter = $scope.filters.source[i];
                        filter.visible = true;
                        filter.invalid = false;

                    switch(filter.codekey)
                    {
                        case 'state' :
                            filter.value = UserProfile.defaultState;
                        break;


                        case 'agencyid' : 
                            filter.value = UserProfile.agencyId;
                        break;

                        case 'btype_description':
                            filter.value = $scope.business_type_input;
                        break;
                    }

                    for(var j = 0; j < filter.options.length; j++)
                    {
                        var option = filter.options[j];

                        if(filter.type.display == 'single-select' || filter.type.display == 'multi-select')
                        {
                            option.selected = option.selected == 1;
                            if(option.selected)filter.value = option.value;
                        }                       
                    }

                    //set sources in first option
                    if(filter.type.display == 'autocomplete' || filter.type.display == 'calendar')
                    {
                        switch(filter.source.display)
                        {
                            case 'state':
                                var source = [];

                                for(var j = 0; j < $scope.states.length; j++)
                                {
                                    var option = $scope.states[j];
                                    option.selected = (filter.value == option.id); 

                                    if(option.selected)
                                    {
                                        filter.display_value = option.label;
                                    }

                                    source.push({id:option.id,value:option.id,label:option.label,selected:option.selected});
                                }

                                filter.source = source;
                            break;

                            case 'zipcode':
                                filter.source = $scope.states;
                            break;

                            case 'date':
                                filter.source = moment(Date.now()).format('MM/DD/YYYY');
                                filter.value  = moment(Date.now()).format('MM/DD/YYYY');
                            break;

                            case 'options':
                                var source = [];

                                for(var j = 0; j < filter.options.length; j++)
                                {
                                    var option = filter.options[j];
                                    
                                    if(option.selected)
                                    {
                                        filter.display_value = option.display;
                                        filter.value = option.value;
                                    }
                                    
                                    source.push({id:option.id,value:option.value,label:option.display,selected:option.selected});
                                }

                               filter.source = source;
                            break;
                        }
                    }

                    //type cast slider value
                    if(filter.type.display == 'slider')
                    {
                        filter.value = Number(String(filter.value).replace(/\D/g,''));
                        filter.percent = (filter.value / filter.max) * 100;
                    }
                    
                    if(filter.required && !filter.hidden)
                    {
                        if(filter.codekey !='state')
                        {
                           $scope.filters.required.push(filter);
                        }
                    }
                    else if(filter.hidden)
                    {
                        $scope.filters.hidden.push(filter);
                    }
                    else
                    {
                        $scope.filters.optional.push(filter);
                    }

                    if(filter.codekey == 'state')
                    {
                        filter.value = $scope.filters.state.value;
                    }


                    if(filter.codekey == 'effectivedate')
                    {
                        filter.value = $scope.filters.date.value;
                    }
                }

                for(var i = 0; i < $scope.rules.source.length ; i++)
                {
                    var rule = $scope.rules.source[i];

                    switch(rule.type){
                        case 1:  $scope.rules.unavailable.push(rule);                   break;
                        case 2:  $scope.rules.classcode.push(rule);                     break;
                        case 3:  $scope.rules.program.push(rule);                       break;
                        case 4:  $scope.rules.inclusion.push(rule);                     break;
                        case 5:  $scope.rules.exclusion.push(rule);                     break;
                        case 6:  $scope.rules.datapoint.push(rule);                     break;
                        case 7:  $scope.rules.datapoint.push(rule);                     break;
                        case 8:  $scope.rules.eligibility.push(rule);                   break;
                        case 9:  $scope.rules.hide.push(rule);                          break;
                        case 10: $scope.rules.ineligible.push(rule);                    break;
                        case 11: $scope.rules.package.push(rule);                       break;
                        case 12: $scope.rules.override.push(rule);                      break;
                        case 13: $scope.rules.linked.push(rule);                        break;
                        case 14: $scope.rules.blurb.push(rule);                         break;
                        case 15: $scope.rules.noProductsAvailableMessage.push(rule);    break;
                        case 16: $scope.rules.productSystem.push(rule);                 break;
                        case 17: $scope.rules.naics.push(rule);                         break;
                        case 18: $scope.rules.product_availability.push(rule);          break;
                    }

                }


                for(var i = 0; i < $scope.products.source.length; i++)
                {
                    var product = $scope.products.source[i];
                        //default product availability / eligibility
                        product.allowed          = true;
                        product.forbidden        = false;
                        product.disabled         = false;
                        product.default_quote    = product.quote;
                        product.defaultUnderwriting = product.underwriting;
                        
                        product.selected         = false;
                        //flag to tell us if user unclicked a selected product
                        product.deselected       = false;
                        
                        product.messages         = {};
                        product.show_eligibility = false;
                        
                        if(product.authenticated == 1 && $scope.isUnAuthenticatedUser){
                            product.forbidden = true;
                            product.preselected = false;
                            product.allowed = false
                        }
                        //if we have a program lets see if we are allowed to use it
                        if(product.program)
                        {
                            if($.inArray(product.program.referenceString,$scope.disallowed_programs) != -1)
                            {
                                product.forbidden   = true;
                                product.allowed     = false;
                            }
                        }

                        if(product.allowed)
                        {
                            $scope.products.allowed.push(product);
                        }

                        $scope.products.id.push(product.id);
                }
                if(!$scope.isUnAuthenticatedUser) {
                    LogDataService.productSearchLogOnLoad($scope.business_type_id, $scope.business_type_input, $scope.filters.source, $scope.products.source, $scope.transactionUUID, 1);
                }
                
                FilterService.filters       = $scope.filters;
                RuleService.transactionUUID = $scope.transactionUUID;
                RuleService.isUnAuthenticatedUser = $scope.isUnAuthenticatedUser
                RuleService.business_type   = $scope.business_type;
                RuleService.rules           = $scope.rules;
                RuleService.products        = $scope.products;
                RuleService.filters         = $scope.filters;
                RuleService.eligibility     = $scope.eligibility;

                RuleService.bo_classcode    = $scope.bo_classcode;
                RuleService.wc_classcode    = $scope.wc_classcode;
                RuleService.naics_code      = $scope.naics_code;

                ProductService.products     = $scope.products;

                //run intial rule set
                RuleService.run_all_rules();
                $scope.noProductsAvailableMessage = RuleService.set_noProductsAvailableMessage_rule();

                $timeout(function(){$scope.loading.business_type= false; $scope.$apply(); },500);
            });

            return false;
        };
        

        $scope.check_state = function(){
            if($scope.filters.state.value.length == 0)
            {
                $scope.filters.state.value = $scope.filters.state.default;
            }
            else
            {
                 //state source hack for top drop down
                for(var j = 0; j < $scope.filters.source.length; j++)
                {
                    var option = $scope.filters.source[j];
                    option.selected = ( $scope.filters.state.value == option.id); 
                    if(option.selected)
                    {
                       $scope.state = $scope.filters.state.value;
                    } 
                }
            }

            //effective date filterID .. super lame
            var filterID = 1;

            //ok lame, but I can't make the autocomplete play nice
            for(var i = 0; i < $scope.filters.source.length ; i++)
            {
                var filter = $scope.filters.source[i];

                if(filter.id == filterID)
                {
                   filter.value =  $scope.filters.state.value;
                   break;
                }
            }
        };

        

        $scope.calendar_select = function(date)
        {
            //effective date filterID .. super lame
            var filterID = 2;

            //ok lame, but I can't make the autocomplete play nice
            for(var i = 0; i < $scope.filters.source.length ; i++)
            {
                var filter = $scope.filters.source[i];

                if(filter.id == filterID)
                {
                   filter.value = date;
                   break;
                }
            }

            $scope.filters.date.value = date;
            $scope.date = date;
            $scope.noProductsAvailableMessage = RuleService.set_noProductsAvailableMessage_rule();

            return false;
        }

        $scope.calendar_click   = FilterService.calendar_click;
        $scope.filters_visible  = FilterService.filters_visible;
        $scope.open_dropdown    = FilterService.open_dropdown;
        $scope.filters_required_completed  = FilterService.filters_required_completed;

        $scope.validate_filters = function(){
 
            if(FilterService.validate_filters())
            {
                $scope.get_app_master();   
            }
        };

        $scope.set_value = function(e,selected)
        {
            FilterService.set_value(e,selected);
            $scope.filters.state.value = selected.item.value;
            $scope.filters.state.display_value = selected.item.label;
            $scope.state = selected.item.value;
	        $scope.noProductsAvailableMessage = RuleService.set_noProductsAvailableMessage_rule();
            if($scope.includeLocation != 'home')RuleService.run_all_rules();
            return false;
        };

        $scope.render_item = function(autocomplete,ul,item){

            if($scope.business_type_tag && $scope.businessTypeTextIsRequired){
                autocomplete.term = $scope.business_type_tag;
                $scope.business_type_auto_focus();
            }

            var search_phrase         = autocomplete.term.replace(/\sand\s|\sor\s/gi," ").trim().toLowerCase();
            var search_words          = search_phrase.split(" ");
            var str                   = item.label;
            

            if($scope.business_type_descriptions.length > 1)
            {
                for(var i = 0; i < search_words.length; i++)
                {
                    if(search_words[i].length > 3)
                    {
                        var search_word_regex = new RegExp('(?<!<[^>]*)(' + search_words[i] + ')(?![^<]*>)', 'gi');
                        str = str.replace(search_word_regex,function(s){  
                            return '<span class="term-highlight">' + s + '</span>';
                        });
                    }
                }
            }


            if($routeParams.businessTypeTag && $scope.businessTypeTextIsRequired){
                autocomplete.term = '';
            }

           return $( "<li></li>" )
            .data( "item.autocomplete", item )
            .append( $( "<a></a>" ).html( str ) )
            .appendTo( ul );
        };

        $scope.get_descriptions = function(e,args){
            var p = [   ProductSearchService.getBusinessTypeDescriptions() ];

            $scope.loading.business_type = true;
            $scope.loading.descriptions  = true;

            $q.all(p).then(function(results){
                $scope.business_type_descriptions = results[0];

                if($routeParams.businessTypeID && !$scope.account['product-search'])
                {
                    $scope.business_type_id = $routeParams.businessTypeID;

                    var description_arr = $.grep($scope.business_type_descriptions,function(description){
                        return description.businessTypeID == $scope.business_type_id;
                    });

                    var selected_buisness_type = null;

                    if(description_arr.length > 0)
                    {
                        var filtered_arr = $.grep(description_arr,function(description){
                            return description.defaultDescription;
                        });

                        if(filtered_arr.length == 1)
                        {
                            selected_buisness_type = filtered_arr[0];
                        }
                        else
                        {
                            selected_buisness_type = description_arr[0];
                        }
                    }

                    $scope.business_type       = selected_buisness_type;
                    $scope.business_type_input = selected_buisness_type.label;

                    $scope.get_business_type();
                }

                if($routeParams.businessTypeTag && !$scope.account['product-search']) {
                    $scope.business_type_tag = $routeParams.businessTypeTag;
                    $("#business-type-autocomplete").autocomplete("search", $scope.business_type_tag);
                    $scope.business_type_input = '';
                    $scope.business_type_text = 'Please select business type below';
                }

                if($scope.business_type_input.length > 0)
                {
                    $("#business-type-autocomplete").autocomplete("search",$scope.business_type_input);
                }

                $scope.loading.descriptions = false;
            });
        };

        $("#business-type-autocomplete").keyup(function(e) {
            $scope.businessTypeTextIsRequired = false;
        });

        $scope.filter_matches = function( request, callback ) {
            if( $scope.user.type == "Agent" ||$scope.user.agencyEmulation )
            {
                $scope.business_type_text  = 'Enter a keyword, industry, or class code';
            } else {
                $scope.business_type_text  = 'Enter a keyword or industry';
            }

            var matches = [];

            if($scope.business_type_descriptions.length == 1)
            {
                matches = $scope.business_type_descriptions;
            }
            else
            {
                matches = ProductSearchService.getMatchingDescriptions( $scope.business_type_descriptions, request.term, true );
            }

            if(matches.length == 0) {
                matches = [{id:0,label:'Sorry, we could not find a match. Please try again.',tags:[]}];      
            }

            callback( matches );
        };

        $scope.business_type_auto_focus = function() {
            $("#business-type-autocomplete").autocomplete("option", "autoFocus", true);
        };

        $scope.get_disallowed_program = function(e,args)
        {
            $scope.disallowed_programs = $scope.account.disallowedPrograms;            
        };
        
        $scope.get_app_master = function() {

            //reset our wizard
            Wizard.reset();
            if(UserProfile.debug.display)$scope.disallowed_programs = [];

            $scope.store.programs   = [];

            var filter_data         = FilterService.get_filter_data();
            var product_no_program  = false;

            //reset our programs
            $scope.programs = [];
            // Get product sytem rules
            var rule_productSystems = RuleService.run_product_system_rules();

            //get product programs
            for(var x = 0; x < $scope.products.source.length ; x++)
            {
                for(var p = 0; p < rule_productSystems.length ; p++){
                    if(rule_productSystems[p].id == $scope.products.source[x].id){
                        $scope.products.source[x].system =  rule_productSystems[p].system;
                    }
                }
                var product = $scope.products.source[x];
                var program = null;

                if(product.selected && product.program && product.allowed && !product.disabled)
                {
                    program = (product.override)?product.override:product.program;
                }

                if(program)
                {
                    program.system = product.system.codekey;
                    $scope.programs.push(program);
                }

                if(product.selected && !product.program && product.allowed && !product.disabled)product_no_program = true;
            }

            //runs program rules which will add programs based on filter options
            var rule_programs = RuleService.run_program_rules();

            //console.log(rule_programs);

            if(rule_programs.arr.length)
            {
                for(var i= 0; i < rule_programs.arr.length; i++ )
                {
                    var program = rule_programs[rule_programs.arr[i]];
                    //temp
                    program.system = 'mpolicy';
                    $scope.programs.push(program);
                }
            }

            
            if(UserProfile.debug.display)console.log('logged rules : ',$scope.rules.log);

            if(UserProfile.debug.display)console.log('btype : ' , $scope.business_type);

            if(UserProfile.debug.display)console.log('filter data : ' , filter_data);


            //set our data points
            var data_points         = RuleService.run_datapoint_rules();
            var program_data_points = { arr:[] };

            if(UserProfile.debug.display && UserProfile.debug.datapoints)console.log('datapoints : ' , data_points);

            for(var i= 0; i < data_points.arr.length; i++ )
            {
                var datapoint = data_points[data_points.arr[i]];

                if(datapoint.filter)
                {
                    datapoint.value = filter_data[datapoint.filter.id];
                }

                if(!program_data_points[datapoint.program.id])
                {
                    program_data_points[datapoint.program.id] = [];
                    program_data_points.arr.push(datapoint.program.id);
                }

                program_data_points[datapoint.program.id].push(datapoint);

                program_data_points[datapoint.program.id].sort(function(a,b){
                    return a.priority - b.priority;
                });
            }


            if(UserProfile.debug.display && UserProfile.debug.datapoints)console.log('program datapoints : ' , program_data_points);

            $scope.store.effectiveDate      = filter_data.effectivedate;
            $scope.store.form.state         = filter_data.state;
            $scope.store.form.zip           = filter_data.zipcode;
            $scope.store.btype_description  = filter_data.btype_description;
            
            var programs = { arr:[] };

            for(var i = 0; i < $scope.programs.length ; i++)
            {
                var program = $scope.programs[i];

                //program must be allowed
                if($.inArray(program.referenceString,$scope.disallowed_programs) == -1 ) 
                {
                    var store_program = {
                        marketedProgramId: program.id,
                        marketedProgramName: program.name,
                        effectiveDate: filter_data.effectivedate,
                        programCode: program.programCode,
                        dataPoints: (program_data_points[program.id]) ? program_data_points[program.id] : [],
                        referenceString: program.referenceString,
                        isUploadBasedSubmission: program.isUploadBasedSubmission,
                        offlineApplicationUrl: program.offlineApplicationUrl,
                        system : program.system,
                        fraudLink            : program.fraudLink ? program.fraudLink : ''
                    };
                    
                    if(!programs[store_program.referenceString + '-' +  program.system])
                    {

                        if($.inArray(program.referenceString,['bop','umbrella']) != -1)
                        {
                            //add classcode data points
                            store_program.dataPoints.push({ keyname:'CL Class Code',value: RuleService.bo_classcode.classCode});
                            store_program.dataPoints.push({ keyname:'CL Class Code Description ID',value: RuleService.bo_classcode.classCodeDescriptionID.toString()});
                        }

                        programs.arr.push(store_program.referenceString + '-' +  program.system);
                        programs[store_program.referenceString + '-' +  program.system] = store_program;
                    }
                }
            }    
            

            // Reset the editedApplication flag in the UserProfile to kill any previous editing session
            UserProfile.editedApplication = 0;

            if(UserProfile.debug.display && UserProfile.debug.programs)console.log('found programs :',programs);

            //for now we are only going to store 1 quote wizard object
            var wizard_data = Wizard.data.entries['id-0'];

            //copy our filter data.. because we may need it?
            wizard_data.data = filter_data;

            //figure out what systems / submissions we have and then forward
            for(var i = 0; i < programs.arr.length; i++)
            {
                var program = programs[programs.arr[i]];

                switch(program.referenceString)
                {
                    case 'bop':
                        program.classcode = RuleService.bo_classcode;
                        wizard_data[program.system].arr.push(program.referenceString);
                        wizard_data[program.system][program.referenceString] = program;
                    break;

                    case 'wcBop':
                        program.classcode = RuleService.wc_classcode;
                        wizard_data[program.system].arr.push(program.referenceString);
                        wizard_data[program.system][program.referenceString] = program;
                    break;

                    default :
                        //super lame E&O crap
                        if(program.referenceString != 'miscErrorsOmissions')
                        {
                            wizard_data[program.system].arr.push(program.referenceString);
                            wizard_data[program.system][program.referenceString] = program;
                        }
                    break;
                }

                 //dont add meo here , depends on whether its bundled w/ wc/bop or not.
                if((program.system == 'mpolicy' || program.system == 'luxon') && program.referenceString != 'miscErrorsOmissions')
                {
                    $scope.store.programs.push(program);
                }
            }

            //special case for misc eo
            if(programs['miscErrorsOmissions-maverick'] || programs['miscErrorsOmissions-mpolicy'] )
            {
                var program = (programs['miscErrorsOmissions-maverick'])?programs['miscErrorsOmissions-maverick']:programs['miscErrorsOmissions-mpolicy'];

                if(wizard_data.maverick.arr.length)
                {
                    //we need to add as mpolicy if there is no other mav bundeling
                    wizard_data.maverick.arr.push('miscErrorsOmissions');
                    wizard_data.maverick['miscErrorsOmissions'] = program;
                }
                else{
                    wizard_data.mpolicy.arr.push('miscErrorsOmissions');
                    wizard_data.mpolicy['miscErrorsOmissions'] = program;
                    //add meo to programs for create master quote.
                    //from what I can tell the store is what createMasterQuote uses 
                    $scope.store.programs.push(program);
                }
            }
           

            if(UserProfile.debug.display && UserProfile.debug.wizard)console.log('mpolicy programs :',$scope.store.programs);

            //store the selected products for later use
            wizard_data.products = ProductService.selected_products();
            wizard_data.data.naics = RuleService.naics_code;

            if(UserProfile.debug.display && UserProfile.debug.wizard)console.log('wizard data', wizard_data);
            if(!$scope.isUnAuthenticatedUser) {
                AgencyService.getAgencyIds().then(function( ids ) {
                    wizard_data.agencies = ids;
                    wizard_data.step = 1;
                    Wizard.set(wizard_data);

                    if(UserProfile.debug.continue)
                    {
                        //we have an mpolicy product goto that insured step
                        if(wizard_data.mpolicy.arr.length || wizard_data.luxon.arr.length)
                        {
                            $location.path('/application/account');
                        }
                        else if(wizard_data.maverick.arr.length)
                        {
                            $location.path('/quote');
                        }

                    }
                });

                if($scope.quoteFein!=0) {
                    wizard_data.fein = $scope.quoteFein;
                }
                LogDataService.productSearchLogOnContinue($scope.business_type_id, $scope.business_type_input, $scope.filters.source, $scope.products.source, $scope.rules.log.arr, programs, $scope.transactionUUID, 2);
            } else {
                $location.path('/application/account');
            }


        };


         $scope.select_product = function(e,obj){
            var product             = obj.product;
            var was_selected        = product.selected;
            var selected_products   = [];

            //only work if product is allowed
            if(product.allowed && !product.disabled && !product.forbidden)
            {
                //swap selected or not 
                product.selected = !was_selected;
                if(!product.selected) product.deselected = true;
                
                //run exclusion rules
                RuleService.product_exclusion(product);
                //run inclusion rules
                RuleService.product_inclusion(product);
                //run package rules
                RuleService.product_package(product);
                //product proram override rules
                RuleService.product_program_override(product);

                //enable continue if we have a product selected
                selected_products = ProductService.selected_products().arr;
                RuleService.run_link_rules();
            }

        }

        $scope.resize = function()
        {
            //console.log($scope.account.isMobile)
            if(arguments.length)$scope.$apply();
        }

        $scope.eligibility_update = function(eligibility)
        {
            if(eligibility)
            {
                $scope.loading.products = !(eligibility.classcode.complete && eligibility.program.complete);
            }
        }

        $scope.$on('select-product',$scope.select_product);
        $scope.$watch('products',ProductService.split_products,true);
        $scope.$watch('filters.source',RuleService.run_all_rules,true);
        $scope.$watch('rule_service.eligibility',$scope.eligibility_update,true);

        angular.element($window).bind('resize',$scope.resize);

        $scope.get_disallowed_program();
        $scope.init_data();
        
        if($scope.includeLocation == 'home' || !$scope.account.ps)
        {
           $scope.get_descriptions(); 
        }


        if($routeParams.businessTypeID && $scope.account.ps)
        {
            $scope.business_type_id = $routeParams.businessTypeID;

            if($routeParams.businessTypeID == $scope.account.ps.business_type_id)
            {
                $scope.business_type_input        = $scope.account.ps.label;
                $scope.business_type_descriptions = $scope.account.ps.business_type_descriptions; 
                $scope.state                      = $scope.account.ps.state;
                $scope.date                       = $scope.account.ps.date; 
                $scope.filters.state.value        = $scope.state;
                $scope.filters.date.value         = $scope.date;
                $scope.quoteFein = $scope.account.ps.fein;

                delete($scope.account.ps);

                //state source hack for top drop down
                for(var j = 0; j < $scope.states.length; j++)
                {
                    var option = $scope.states[j];
                    option.selected = ( $scope.filters.state.value == option.id); 
                    if(option.selected)
                    {
                        $scope.filters.state.display_value = option.label;
                    }


                    //$scope.filters.state.source.push({id:option.id,value:option.id,label:option.label,selected:option.selected});
                }

                $scope.get_business_type();
            }
            else
            {
               $scope.get_descriptions(); 
            }
        }
	}

    var originalRenderMenu = jQuery.ui.autocomplete.prototype._renderMenu,
        originalResizeMenu = jQuery.ui.autocomplete.prototype._resizeMenu,
        originalRenderItem = jQuery.ui.autocomplete.prototype._renderItem;

    jQuery.ui.autocomplete.prototype._renderItem = function( ul, item ) {
        return angular.isFunction( this.options.renderItem ) ? this.options.renderItem( this, ul, item ) : originalRenderItem.apply( this, arguments );
    };

	return productSearchContainerController;
});
