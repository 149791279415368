define('components/widget/document-upload/directive/documentUpload',['require'],function(require) {
	"use strict";

	documentUpload.$inject = [];

	function documentUpload() {
		return {
			restrict: "AE",
			scope:{
				settings    			: "=",
				datapointApplications 	: "="
			},
			templateUrl: "app/components/widget/document-upload/template/template.html",
			controller: "DocumentUploadController"
		};
	}

	return documentUpload;

});

