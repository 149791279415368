define( 'components/policyDetail/policyClaims/directive/policyClaims',['require'],function( require ) {
    "use strict";

    function PolicyClaims() {
        return {
            restrict: "AE",
            scope: {
                policy: "=",
	            openClaimTab: "="
            },
            templateUrl: "app/components/policyDetail/policyClaims/template/template.html",
            controller: "PolicyClaimsController"
        };
    }

    return PolicyClaims;

});

