define('components/quickLinks/directive/quickLinks',['require'],function(require) {
	"use strict";

	QuickLinks.$inject = [];

	function QuickLinks() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/quickLinks/template/template.html",
			controller: "QuickLinksController"
		};
	}

	return QuickLinks;

});
