define('marketedPrograms/app',['require','angular-sanitize','common/config/config','components/marketedPrograms/list/app','components/marketedPrograms/detail/app','shim/overmind','marketedPrograms/controller/MarketedProgramsController','common/service/MarketedProgramService','common/service/user/UserProfile'],function(require) {
	"use strict";

	require("angular-sanitize");
	require("common/config/config");
	require("components/marketedPrograms/list/app");
	require("components/marketedPrograms/detail/app");

	var overmind = require("shim/overmind");

	var MarketedProgramsController = require("marketedPrograms/controller/MarketedProgramsController"),
		MarketedProgramService = require("common/service/MarketedProgramService"),
		UserProfile = require("common/service/user/UserProfile" );

	var app = angular.module("mPortal.marketedPrograms", [
		"ngSanitize",
		"mPortal.config",
		"mPortal.components.marketedPrograms.marketedProgramList",
		"mPortal.components.marketedPrograms.marketedProgramDetail"
	]);

	app.config( overmind.control() );
	app.controller("MarketedProgramsController", MarketedProgramsController);
	app.factory("MarketedProgramService", MarketedProgramService);
	app.factory("UserProfile", UserProfile);

	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider.when("/programs", {
			templateUrl: "app/marketedPrograms/template/template.html",
			controller: "MarketedProgramsController"
		});

		$routeProvider.when("/programs/:id", {
			templateUrl: "app/marketedPrograms/template/template.html",
			controller: "MarketedProgramsController"
		});

	}]);

	return app;

});
