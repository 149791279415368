define('components/userAccountSettings/stateNewsSubscriptions/controller/StateNewsSubscriptionsController',['require'],function(require) {
	"use strict";

	StateNewsSubscriptionsController.$inject = [ "$scope", "$filter", "MessageContainer", "UserService" ];

	function StateNewsSubscriptionsController( $scope, $filter, MessageContainer, UserService ) {

		$scope.uiState = {
			visible: false,
			submitting: false
		};

		$scope.messages = new MessageContainer({
			success: false,
			errors: [],
			errorHeader: ""
		});

		$scope.save = function() {
			$scope.messages.reset();
			$scope.uiState.submitting = true;

			// Generate array of selected states
			var subscriptionArray = [];
			var subscribedStates = $filter( "filter" )( $scope.subscriptions, { subscribed: true } );
			angular.forEach( subscribedStates, function( state ) {
				subscriptionArray.push( state.value );
			});

			UserService.saveStateSubscriptions( { states: subscriptionArray.join(",") } ).then( function( result ) {
				if( result.isValid && result.isSaved ) {
					$scope.messages.success = true;
				} else {
					$scope.messages.errorHeader = "Please correct the following problems:";
					angular.forEach( result.errors, function( error ) {
						$scope.messages.errors.push( error.message );
					});
				}
				$scope.uiState.submitting = false;
			});
		};
	}

	return StateNewsSubscriptionsController;

});
