define('common/directive/sectionToggle',[],function() {
	"use strict";

	function SectionToggle() {
		return {
			restrict: "AE",
			scope: {
				span: '=',
				title: '=',
				hideLabel: '=',
				showLabel: '=',
				open: '=?'
			},
			template: 
				'<div class="row-fluid">' +
					'<div class="{{:: span || \'span12\' }}">' +
						'<div class="banner sectionToggle">' +
							'{{ title }}' +
							'<button class="btn btn-small pull-right" ng-click="toggle()">' +
								'<span ng-if="open"><i class="icon-minus toggler"></i> {{:: hideLabel || \'Hide\' }}</span>' +
								'<span ng-if="!open"><i class="icon-plus toggler"></i> {{:: showLabel || \'View\' }}</span>' +
							'</button>' +
						'</div>' +
					'</div>' +
				'</div>',
			link: function( scope, element, attrs ) {
				scope.open = 'open' in attrs ? scope.open : true;

				scope.toggle = function() {
					scope.open = !scope.open;
				};
			}
		};
	}

	return SectionToggle;

});
