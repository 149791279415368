define('common/service/interceptor/RedirectInterceptor',['require'],function(require) {
	"use strict";

	RedirectInterceptor.$inject = ["$q", "$window"];

	function RedirectInterceptor( $q, $window ) {

		return {
			responseError: function( responseError ) {
				// if maverick has the need to send a 302 redirect, it cannot send the response with an actual 302 status code because
				// the browser grabs the redirect before it ever makes it to our JS. the redirect then fails because it's attempting
				// to redirect us to a different domain, which the browser doesn't like. so, in order to work around that, we send a
				// nonsensical status code along with the real status code and the url to redirect to.
				if( responseError.status === 418 && responseError.data.message && responseError.data.message.realStatusCode === 302 ) {

					// the redirect url generated in maverick is based on the original request URL (e.g. agency/index.cfm/whatever), but
					// the "agency" virtual directory only exists in mPortal, so we need to strip it off prior to redirecting.
					$window.location = responseError.data.message.redirectUrl.replace(/\/agency/, "");
					return;

				}

				return $q.reject( responseError );
			}
		};
	}

	return RedirectInterceptor;

});
