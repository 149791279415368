define('components/widget/document-upload/app',['require','components/widget/document-upload/directive/documentUpload','components/widget/document-upload/controller/DocumentUploadController','components/widget/document-upload/controller/DocumentUploadModalController','common/service/SubmissionDataService'],function(require) {
	"use strict";

	var documentUpload 					= require("components/widget/document-upload/directive/documentUpload"),
			DocumentUploadController		= require("components/widget/document-upload/controller/DocumentUploadController"),
			DocumentUploadModalController	= require("components/widget/document-upload/controller/DocumentUploadModalController"),
			submissionDataService			= require("common/service/SubmissionDataService");


	var component 					= angular.module("mPortal.components.widget.document-upload", []);

	component.directive("documentUpload", documentUpload);
	component.controller("DocumentUploadController", DocumentUploadController);
	component.controller("DocumentUploadModalController", DocumentUploadModalController);
	component.factory("SubmissionDataService", submissionDataService);

	return component;

});
