define('shortLink/app',['require','common/config/config','shim/overmind','shortLink/controller/ShortLinkController','common/service/ShortLinkService','common/service/user/UserProfile','common/service/user/UserService'],function(require) {
	"use strict";

	require("common/config/config");

	var overmind = require("shim/overmind"),
		ShortLinkController = require("shortLink/controller/ShortLinkController"),
		ShortLinkService = require("common/service/ShortLinkService"),
		UserProfile = require("common/service/user/UserProfile"),
		UserService = require("common/service/user/UserService");

	var app = angular.module("mPortal.shortLink", [
		"mPortal.config"
	]);

	app.config( overmind.control() );
	app.controller("ShortLinkController", ShortLinkController);
	app.factory("ShortLinkService", ShortLinkService);
	app.factory("UserProfile", UserProfile);
	app.factory("UserService", UserService);

	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider.when("/m/:id/:token", {
			template: " ",
			controller: "ShortLinkController",
			insecure: true
		});
	}]);

	app.value("routes", {
		outcome: "/application/result",
		resume: "/application/resume"
	});

	return app;

});
