define('components/specialtyApplication/eligibility/directive/eligibility',['require'],function(require) {
	"use strict";

	Eligibility.$inject = [];

	function Eligibility() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/specialtyApplication/eligibility/template/template.html",
			controller: "SpecialtyApplicationEligibilityController"
		};
	}

	return Eligibility;

});
