define('components/widget/optional-endorsements/photography-make-model/controller/photographyMakeModelController',['require'],function (require) {
    "use strict";

    photographyMakeModelController.$inject = ["$scope"];

    function photographyMakeModelController($scope) {

        $scope.Description = [];

        $scope.widgetFields = $scope.options.fieldGroup;

        $scope.widgetFields.sort(function (a, b) {
            return a.data.enumeration - b.data.enumeration;
        });

        // Break up the enumerations into separate arrays within the enumerations array;
        $scope.enumerations = [];
        for (var d = 0; d < $scope.widgetFields.length; d++) {
            var currentPoint = $scope.widgetFields[d];

            if (currentPoint.validation && currentPoint.validation.errorExistsAndShouldBeVisible) {
                currentPoint.data.wasTouched = true;
            }

            var enumIndex = currentPoint.data.enumeration - 1;
            if (!$scope.enumerations[enumIndex]) {
                $scope.enumerations[enumIndex] = [];
            }
            $scope.enumerations[enumIndex].push(currentPoint);
        }
    }

    return photographyMakeModelController;

});
