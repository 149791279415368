define('components/marketedPrograms/list/app',['require','components/marketedPrograms/list/directive/marketedProgramList'],function(require) {
	"use strict";

	var marketedProgramList = require("components/marketedPrograms/list/directive/marketedProgramList");

	var component = angular.module("mPortal.components.marketedPrograms.marketedProgramList", []);

	component.directive("marketedProgramList", marketedProgramList);

	return component;

});
