define('components/agencyExperienceReport/controller/AgencyExperienceReportController',['require'],function(require) {
	"use strict";

	AgencyExperienceReportController.$inject = ["$scope", "$window", "ReportService", "UserProfile"];

	function AgencyExperienceReportController( $scope, $window, ReportService, UserProfile ) {

		$scope.months = ReportService.months;
		$scope.years = ReportService.years;
		$scope.firstAvailableRun = ReportService.firstAvailableRun;

		$scope.reportParams = {
			month: $scope.firstAvailableRun.getMonth(),
			year: $scope.firstAvailableRun.getFullYear(),
			detail: false,
			enterpriseID: UserProfile.enterpriseID
		};

		$scope.getReport = function() {

			var reportUrl = ReportService.getAgencyExperienceReportUrl( $scope.reportParams );
			
			$window.open( reportUrl, "_blank", "toolbar=0,location=0,directories=0,status=0,menubar=0,scrollbars=1,resizable=1,width=950,height=650" );

		};

	}

	return AgencyExperienceReportController;

});
