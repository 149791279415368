define( 'components/specialtyApplication/account/controller/EditableSubmissionsModalController',['require'],function( require ) {
    "use strict";

    EditableSubmissionsModalController.$inject = [ "$scope", "$modalInstance", "$location", "AccountService", "possibleSubmissions", "existingSubmissions", "accountId", "effectiveDate", "Wizard", "wizard_data", "SubmissionStore", "UserProfile" ];

    function EditableSubmissionsModalController( $scope, $modalInstance, $location, AccountService, possibleSubmissions, existingSubmissions, accountId, effectiveDate, Wizard, wizard_data, SubmissionStore, UserProfile ) {
        $scope.accountId = accountId;
        $scope.isMultiSystemBundle = wizard_data.maverick.arr.length > 0;
        $scope.existingSubmissions = existingSubmissions;
        $scope.multipleExisting = existingSubmissions.length > 1;
        $scope.multiplePrograms = false;
        $scope.allIssued = true;
        $scope.issuedCount = 0;
        $scope.newEffectiveDate = effectiveDate;
        $scope.isBlocked = false;
        $scope.isProcessing = false;
        $scope.canEdit = false;
        $scope.canEndorse = false;
        $scope.maverickSubmission = "";
        $scope.hasPolicies = false;
        $scope.primaryProgram = '';

        angular.forEach( $scope.existingSubmissions, function( submission ) {
            /*
             If the submission is configured to create multiple masterquotes (for different programs), and there are
             existing submissions for this insured for more than one of those programs, that will impact the options
             presented to the user: if any one of those existing submissions is editable / will be blocked from creating
             a new submission, there's no way for the user to proceed.
            */
            if( $scope.primaryProgram == '' ) {
                $scope.primaryProgram = submission.programAbbreviation;
            } else {
                if( $scope.primaryProgram != submission.programAbbreviation ) {
                    $scope.multiplePrograms = true;
                }
            }
            if( submission.status === 'Issued' && submission.policyNumbers && submission.policyNumbers.length > 0 ){
                $scope.hasPolicies = true;
            }
            if( submission.status != 'Issued' ) {
                $scope.allIssued = false;
            } else {
                $scope.issuedCount++;
            }
        });

        if( $scope.isMultiSystemBundle ) {
            angular.forEach( wizard_data.maverick.arr, function( mp ) {
                var mpName;
                switch( mp ) {
                    case "wcBop":
                        mpName = "Workers Compensation";
                        break;
                    case "bop":
                        mpName = "Business Owners Policy";
                        break;
                    case "miscErrorsOmissions":
                        mpName = "Miscellaneous E&O";
                        break;
                }
                if( !$scope.maverickSubmission ) {
                    $scope.maverickSubmission = mpName;
                } else {
                    $scope.maverickSubmission = $scope.maverickSubmission + "/" + mpName;
                }
            });
        }

        if( !$scope.multiplePrograms ) {
            /*
             Even if there are multiple submissions returned, the first submission (lowest phase) will determine
             what actions/options are presented to the user.
            */
            $scope.canEdit = existingSubmissions[0].canEdit;
            $scope.canEndorse = existingSubmissions[0].canEndorse;
        } else {
            if( $scope.allIssued ) {
                /*
                 If all of the submissions from the multiple programs are issued, the user is allowed to
                 contineu with the mPolicy bundle submission (or opt to endorse instead).
                */
                $scope.canEndorse = true;
            }
        }

        $scope.close = $modalInstance.close;

        $scope.skipSubmission = function() {
            $scope.isBlocked = false;
            $scope.isProcessing = true;
            var existingSubmission = $scope.existingSubmissions[0];

            SubmissionStore.applicationId = existingSubmission.applicationId;
            SubmissionStore.instance = existingSubmission.instance;
            SubmissionStore.masterQuoteId = existingSubmission.masterQuoteId;
            SubmissionStore.effectiveDate = existingSubmission.effectiveDate;

            wizard_data.step = 2;
            wizard_data.store = SubmissionStore;
            wizard_data.mPolicySkip = true;

            Wizard.set(wizard_data);
            $scope.close();
            $location.path("/quote");
        };

        $scope.editExistingSubmission = function(  ) {
            $scope.isBlocked = false;
            $scope.isProcessing = true;
            var existingSubmission = $scope.existingSubmissions[0];

            AccountService.reviveSubmission( existingSubmission.applicationId, $scope.newEffectiveDate).then( function( result ) {

                var data = result.data;
                angular.extend( SubmissionStore, result.data );
                SubmissionStore.applicationId = existingSubmission.applicationId;
                SubmissionStore.instance = existingSubmission.instance;
                SubmissionStore.masterQuoteId = existingSubmission.masterQuoteId;

                //Update previous effectiveDate to new value to allow for a re-edit
                SubmissionStore.previousValues.effectiveDate = SubmissionStore.effectiveDate;

                UserProfile.set( "editedApplication", $scope.applicationId );
                $scope.isProcessing = false;
                $scope.close();
                if( $scope.isMultiSystemBundle )
                {
                    wizard_data.step = 2;
                    wizard_data.store = SubmissionStore;
                    Wizard.set(wizard_data);

                    $location.path("/quote");
                } else {
                    $location.path("/application/questions");
                }
            }).catch( function( err ) {
                $scope.isBlocked = true;
                $scope.isProcessing = false;
            });
        };

        $scope.createNewSubmission= function() {
            $scope.close();
            // Emit event to trigger InsuredAccountController's createMasterQuote() function.
            $scope.$parent.$emit( "proceed-with-new-submission" );
        };

        $scope.navigateToAccountSummary = function() {
            $scope.close();
            $location.path( "account-summary/" + accountId );
        };

        $scope.startEndorsementMessage = function() {
            $scope.close();
            $location.path( "account-summary/" + accountId + "/new-message/" + $scope.existingSubmissions[0].masterQuoteId + "/reason/endorsement" );
        };



    }

    return EditableSubmissionsModalController;
});
