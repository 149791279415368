define('components/widget/policy-information/building-table/app',['require','components/widget/policy-information/building-table/directive/buildingTable','components/widget/policy-information/building-table/controller/buildingTableController','common/service/SubmissionDataService'],function(require) {
	"use strict";

	var buildingTable 				= require("components/widget/policy-information/building-table/directive/buildingTable"),
		buildingTableController 	= require("components/widget/policy-information/building-table/controller/buildingTableController"),
		submissionDataService		= require("common/service/SubmissionDataService");

	var component 					= angular.module("mPortal.components.widget.policy-information.building-table", []);

	component.directive("buildingTable", buildingTable);
	component.controller("buildingTableController", buildingTableController);
	component.factory("SubmissionDataService", submissionDataService);

	return component;

});
