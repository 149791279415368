define('common/service/ShortLinkService',['require'],function(require) {
	"use strict";

	ShortLinkService.$inject = ["$http", "archApiUrl"];

	function ShortLinkService( $http, archApiUrl ) {

		var service = {};

		service.verify = function( id, token ) {
			return $http.post( archApiUrl + "short-link/verify", { id: id, link: token }).then(function( result ) {
				return result.data;
			});
		};

		return service;

	}

	return ShortLinkService;

});
