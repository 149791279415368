define('components/policyDetail/policyDocuments/directive/policyDocuments',['require'],function(require) {
	"use strict";

	function PolicyDocuments() {
		return {
			restrict: "AE",
			scope: {
				accountId: "=",
				policyId: "=",
				submissionId: "="
			},
			templateUrl: "app/components/policyDetail/policyDocuments/template/template.html",
			controller: "PolicyDocumentsController"
		};
	}

	return PolicyDocuments;

});
