define('common/directive/maphilight',['require','jquery-maphilight'],function(require) {
	"use strict";

	require("jquery-maphilight");

	function MapHilight() {
		return {
			restrict: "A",
			link: function( scope, element, attrs ) {
				$(element).maphilight();
			}
		};
	}

	return MapHilight;

});
