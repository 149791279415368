define('components/widget/inland-marine/fine-item-limit/Controller/FineItemLimitController',['require'],function(require) {
    "use strict";

    FineItemLimitController.$inject = [ "$scope", "QuestionService" ];

    function FineItemLimitController( $scope, QuestionService ) {

        $scope.changingQuantity = false;

        $scope.widgetFields = $scope.options.fieldGroup;

        // Isolate the quantity field that controls the number of enumerations and remove it from the rest of the fields
        $scope.quantityControl = $scope.widgetFields.shift();

        // Capture the Formly identifier for the quantity control
        $scope.quantityKey = $scope.quantityControl.key;

        // Sort the remaining fields by enumeration. Widget configuration should take care of the ordering within an enumeration
        $scope.widgetFields.sort(function( a, b ) {
            return a.data.enumeration - b.data.enumeration;
        });

        // Break up the enumerations into separate arrays within the enumerations array;
        $scope.enumerations = [];
        for( var d = 0; d < $scope.widgetFields.length; d++ ) {
            var currentPoint = $scope.widgetFields[ d ];

            /*
             When you navigate away from a section, validation checks will be performed against all of the datapoints
             in the section, and the Formly library will update invalid datapoints with validation.errorExistsAndShouldBeVisible
             set to true, so that when the user returns to the section the datapoint validation errors will be displayed.

             However, when inline enumerated section datapoints belonging to a field group are updated, they end up
             resetting the Formly-managed data, and the errorExistsAndShouldBeVisible flag is lost.  This conditional
             block will effective preserve that flag by marking any such datapoints as having been touched, which is
             another condition for showing errors in the Formly wrappers used for the datapoints.

             In user-initiated changes to the datapoint data, the wasTouched property is set on the datapoint whenever
             the user interacts with the datapoint.
            */

            if(  currentPoint.validation && currentPoint.validation.errorExistsAndShouldBeVisible  ) {
                currentPoint.data.wasTouched = true;
            }


            var enumIndex = currentPoint.data.enumeration - 1;
            if( !$scope.enumerations[ enumIndex ] ) {
                $scope.enumerations[ enumIndex ] = [];
            }
            $scope.enumerations[ enumIndex ].push( currentPoint );
        }

        // Track the number of enumerations to help disable controls while enumeration array is grown/shrunk
        $scope.newLength = $scope.enumerations.length;

        $scope.changeQuantity = function( movement ) {
            $scope.changingQuantity = true;
            $("#fineArtsTable input").prop("disabled", true);
            $scope.newLength = parseInt($scope.model[ $scope.quantityKey ]) + movement;
            $scope.model[ $scope.quantityKey ] = "" + $scope.newLength + "";
            $("#" + $scope.quantityControlId + " input").blur();
        };

        $scope.changeQuantityOfSections = function ( section, movement ) {

            $scope.newLength = parseInt( $scope.model[$scope.quantityKey] ) + movement;
            $scope.model[$scope.quantityKey] = "" + $scope.newLength + "";
            $scope.$emit( 'delete-im-enumerated-section', {
                section: section,
                sectionId: section[0].data.sectionId,
                name: "Loc FAC Section",
                mainSectionName: "Location Information"
            } );

        };
        $scope.buttonDisabled = function( row ) {
            if( $scope.changingQuantity ) {
                return true;
            }
            var datapointsInvalid = false;
            // Buttons should be disabled if any datapoint in the row is visibly invalid (touched and invalid)
            angular.forEach( $scope.enumerations[ row ], function( dPoint ) {
                if( dPoint.data.wasTouched && !dPoint.data.isValid ) {
                    datapointsInvalid = true;
                }
            });
            return datapointsInvalid;
        };

        // Disable the buttons while the REST call catches up to what the number of enumerations should be
        $scope.movementButtonDisabled = function( context, isLast ) {
            if( context == 'subtract' ) {
                return !isLast || $scope.enumerations.length == 1 || $scope.enumerations.length != $scope.newLength;
            } else {
                return !isLast || $scope.enumerations.length != $scope.newLength;
            }
        };
        $scope.saveReceived = function (event, data) {
            $("#fineArtsTable input").prop("disabled", false);
            $scope.changingQuantity = false;
        };

        // The quantity datapoint is configured to send out a broadcast once the save data is returned
        // from mPolicy, so we can listen for that to know when that action is completed and we can
        // re-enable UI elements.
        $scope.$on('broadcastedDatapointSave', $scope.saveReceived)
    }

    return FineItemLimitController;

});
