require.config({
	baseUrl: "app",
	paths: {
		"angular": "lib/angular/angular",
		"angular-bootstrap": "lib/angular-bootstrap/ui-bootstrap-tpls",
		"angular-formly": "lib/angular-formly/dist/formly",
		"angular-formly-templates-bootstrap": "lib/angular-formly-templates-bootstrap/dist/angular-formly-templates-bootstrap",
		"angular-merge-polyfill": "lib/angular-merge-polyfill/index",
		"angular-route": "lib/angular-route/angular-route",
		"angular-sanitize": "lib/angular-sanitize/angular-sanitize",
        "angular-animate": "lib/angular-animate/angular-animate",
		"angular-tree-control": "lib/angular-tree-control/angular-tree-control",
		"angular-utils-pagination": "lib/angularUtils-pagination/dirPagination",
		"api-check": "lib/api-check/dist/api-check",
		"bowser": "lib/bowser/src/bowser",
		"es5-shim": "lib/es5-shim/es5-shim",
		"jquery": "shim/jquery",
		"jquery-ui": "shim/jquery-ui",
		"jquery-ui-autocomplete-html": "lib/jquery-ui-extensions/src/autocomplete/jquery.ui.autocomplete.html",
		"jquery-maphilight": "lib/maphilight/jquery.maphilight",
		"moment": "lib/moment/moment",
		"overmind": "lib/overmind/src/overmind",
		"iframe-resizer": "lib/iframe-resizer/src/iframeResizer"
	},
	shim: {
		"angular": ["jquery"],
		"angular-bootstrap": ["angular"],
        "angular-animate": ["angular"],
		"angular-formly": ["angular"],
		"angular-formly-templates-bootstrap": ["angular", "angular-formly"],
		"angular-merge-polyfill": ["angular"],
		"angular-route": ["angular"],
		"angular-sanitize": ["angular"],
		"angular-tree-control": ["angular"],
		"angular-utils-pagination": ["angular"],
		"jquery-ui": ["jquery"],
		"jquery-ui-autocomplete-html": ["jquery", "jquery-ui"],
		"jquery-maphilight": ["jquery"],
		"overmind": ["angular", "angular-route"]
	},
	deps: ["es5-shim", "angular-merge-polyfill", "bootstrap/app"]
});
define("require/config", function(){});

