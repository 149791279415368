define('components/accountSummary/paymentSystem/directive/paymentSystem',['require'],function(require) {
    "use strict";

    function PaymentSystem() {
        return {
            restrict: "AE",
            scope: {
                account:"=",
                accounts:"="
            },
            templateUrl: "app/components/accountSummary/paymentSystem/template/template.html",
            controller: "PaymentSystemController"
        };
    }

    return PaymentSystem;

});
