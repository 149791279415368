define('components/filterableDocumentList/directive/filterableDocumentList',['require'],function(require) {
	"use strict";

	function FilterableDocumentList() {
		return {
			restrict: "AE",
			scope: {
				placeholder: "=",
				documents: "="
			},
			templateUrl: "app/components/filterableDocumentList/template/template.html",
			controller: "FilterableDocumentListController"
		};
	}

	return FilterableDocumentList;

});
