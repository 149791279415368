define('components/policiesSearch/app',['require','common/directive/app','components/policiesSearch/directive/policiesSearch','components/policiesSearch/controller/PoliciesSearchController','common/service/MessageContainer','common/service/util/PaginationService','common/service/util/RenameKeys','common/service/search/SearchResultContainer','common/service/search/SearchService'],function(require) {
	"use strict";

	require("common/directive/app");

	var policiesSearch = require("components/policiesSearch/directive/policiesSearch"),
		PoliciesSearchController = require("components/policiesSearch/controller/PoliciesSearchController"),
		MessageContainer = require("common/service/MessageContainer"),
		PaginationService = require("common/service/util/PaginationService"),
		RenameKeys = require("common/service/util/RenameKeys"),
		SearchResultContainer = require("common/service/search/SearchResultContainer"),
		SearchService = require("common/service/search/SearchService");

	var component = angular.module("mPortal.components.policiesSearch", [
		"mPortal.directive"
	]);

	component.directive("policiesSearch", policiesSearch);
	component.controller("PoliciesSearchController", PoliciesSearchController);
	component.factory("MessageContainer", MessageContainer);
	component.factory("PaginationService", PaginationService);
	component.factory("RenameKeys", RenameKeys);
	component.factory("SearchResultContainer", SearchResultContainer);
	component.factory("SearchService", SearchService);

	return component;

});
