define('components/quoteWizard/controller/quoteWizardController',[],function() {

	quoteWizardController.$inject = [ "$scope","$window", "$location" , "Wizard" , "UserProfile"];

	function quoteWizardController( $scope, $window, $location , Wizard , UserProfile) {

		$scope.wizard_data = Wizard.data.entries['id-0'];
		
		$scope.products = [];

		$scope.account = UserProfile;

		for(var i=0 ; i < $scope.wizard_data.products.arr.length; i++)
		{
			var product = $scope.wizard_data.products[$scope.wizard_data.products.arr[i]];
			if($.inArray(product.program.referenceString,['wcBop','miscErrorsOmissions']) == -1 && product.id != 3 ) 
	        {
	        	$scope.products.push(product);
	        }
		}

		if($scope.wizard_data.step == 5)
		{
			Wizard.reset();
		}

		$scope.resize = function()
        {
            //console.log($scope.account.isMobile)
            if(arguments.length)$scope.$apply();
        }

        angular.element($window).bind('resize',$scope.resize);
	}

	return quoteWizardController;

});
