define('components/specialtyApplication/questions/config/configureFormly',['require'],function(require) {
	"use strict";

	return function( formlyConfig, formlyApiCheck ) {

		formlyConfig.templateManipulators.preWrapper.push(function(template, options, scope) {
		
		  var template_original = template;
		  var appmaster_arr = [];
		  var appmaster = options.data.applications[0].referenceString;

		  angular.forEach(options.data.applications,function(am){
		  	appmaster_arr.push(am.referenceString);
		  });

		  appmaster = appmaster_arr.join();

		  switch(options.type.toLowerCase())
		  {

		  	 case 'checkbox':
		  	 	var match_str  = '<input';
				var qa_str 	   = match_str;
					qa_str    += ' qa-appmaster="' + appmaster + '"';
					qa_str	  += ' qa-value="' + "{{model[options.key]}}" + '"';
					qa_str	  += ' qa-reference="' + options.data.reference.toLowerCase() + '" ';
					qa_str	  += ' qa-type="checkbox" ';


		  	 	template = template.replace(match_str,qa_str);
		  	 break;

		  	 case 'select' :

				var match_str  = '<select';
				var qa_str 	   = match_str;
					qa_str    += ' qa-appmaster="' + appmaster + '"';
					qa_str	  += ' qa-value="' + "{{model[options.key]}}" + '"';
					qa_str	  += ' qa-reference="' + options.data.reference.toLowerCase() + '" ';
					qa_str	  += ' qa-type="select" ';

		  	 	template = template.replace(match_str,qa_str);
		  	 break;


		  	 case 'textbox' : case 'dateinput': case 'phoneinput':

		  	 	var match_str  = '<input';
				var qa_str 	   = match_str;
					qa_str    += ' qa-appmaster="' + appmaster + '"';
					qa_str	  += ' qa-value="' + "{{model[options.key]}}" + '"';
					qa_str	  += ' qa-reference="' + options.data.reference.toLowerCase() + '" ';
					qa_str	  += ' qa-type="textbox" ';


		  	 	template = template.replace(match_str,qa_str);
		  	 break;

		  	 case 'textarea' :

		  	 	var match_str  = '<textarea';
				var qa_str 	   = match_str;
					qa_str    += ' qa-appmaster="' + appmaster + '"';
					qa_str	  += ' qa-value="' + "{{model[options.key]}}" + '"';
					qa_str	  += ' qa-reference="' + options.data.reference.toLowerCase() + '" ';
					qa_str	  += ' qa-type="textarea" ';


		  	 	template = template.replace(match_str,qa_str);
		  	 break;

		  	 case 'radio': case 'yesnotoggle':
		  	 	var match_str  = '<input';
				var qa_str 	   = match_str;
					qa_str    += ' qa-appmaster="' + appmaster + '"';
					qa_str	  += ' qa-value="' + "{{option[to.valueProp || 'value']}}" + '"';
					qa_str	  += ' qa-reference="' + options.data.reference.toLowerCase() + '" ';
					qa_str	  += ' qa-type="radio" ';


		  	 	template = template.replace(match_str,qa_str);
		  	 break;
		  }

		  return template;
		});

		formlyConfig.setType({
			name: "label",
            template: '<div ng-class="options.templateOptions.label.length ? options.templateOptions.style.className ? options.templateOptions.style.className : \'alert alert-info\': \'\'" style={{options.templateOptions.style.style}} ng-bind-html="options.templateOptions.label | trustHtml"></div>',
            defaultOptions: {
                noFormControl: true
            }
		});

		formlyConfig.setType({
			"extends": "input",
			name: "static",
			template: '<p class="form-control-static">{{ to.value }}</p>',
			defaultOptions: {
				noFormControl: true
			}
		});

		formlyConfig.setType({
			"extends": "input",
			name: "TextBox"
		});

		formlyConfig.setType({
			"extends": "input",
			name: "PhoneInput",
			template: '<input class="form-control" ng-model="model[options.key]" placeholder="(XXX) XXX-XXXX" maxlength="14">'
		});

		formlyConfig.setType({
			"extends": "input",
			name: "DateInput",
			template: '<div class="input-append widget-calendar" style="margin-bottom:-30px"><input ng-model="model[options.key]" placeholder="MM/DD/YYYY" date-picker date-picker-defaultDate="+0" year-range="-30:+30"> <span class="add-on" style="margin-left:10px;border-radius:3px;"><i id="effectiveDate_icon" onclick="$(this).closest(\'.widget-calendar\').find(\'input\').focus()" class="icon-calendar cal-btn"></i></span> </div>'
		});

		formlyConfig.setType({
			"extends": "input",
			name: "ReadOnly",
			defaultOptions: {
				templateOptions: {
					disabled: true
				}
			}
		});

		formlyConfig.setType({
			"extends": "select",
			name: "SelectReadOnly",
			defaultOptions: {
				templateOptions: {
					disabled: true
				}
			}
		});

		formlyConfig.setType({
			"extends": "select",
			name: "Select"
		});

		formlyConfig.setType({
			"extends": "select",
			name: "SelectPopIn",
			template :
			'<label ng-repeat="option in options.templateOptions.options" ng-if="option.value === options.defaultValue"><b>{{ option.name }}</b></label>'+
			'<select ng-model="model[options.key]"' +
			' ng-options="option[to.valueProp || \'value\'] as option[to.labelProp || \'name\'] group by option[to.groupProp || \'group\'] for option in to.options">' +
			'</select>'
		});

		formlyConfig.setType( {
			"extends": "input",
			name: 'Typeahead',
			template: '<input type="text" typeahead-editable="false" ng-model="model[options.key]" typeahead="item as item.name for item in options.templateOptions.options | filter:$viewValue | limitTo:15" />',
			defaultOptions: {
				ngModelAttrs: {
					onSelect: {
						statement: 'typeahead-on-select'
					}
				},
				templateOptions: {
					onSelect: function ( value, options, scope, $event ) {
						this.typeaheadSelect( value.value, options, scope, $event );
					},
					onBlur: function ( value, options, scope, $event ) {
						if ( !value ) {
							this.typeaheadSelect( value, options, scope, $event );
						}
					}
				}
			}
		} );

		formlyConfig.setType({
			"extends": "label",
			name: "Label",
			template:
			'<div ng-class="options.templateOptions.label.length ? options.templateOptions.style.className ? options.templateOptions.style.className : \'alert alert-info\': \'\' "style={{options.templateOptions.style.style}}>' +
			'<ul><li><label for="{{id}}" class="control-label {{to.labelSrOnly ? \'sr-only\' : \'\'}}" ng-if="to.label">' +
			'{{to.label}}'+
			'{{to.required ? \'*\' : \'\'}}'+
			'<i ng-if="to.help" class="glyphicon glyphicon-question-sign field-help " ' +
			'tooltip="{{ to.help }}" ' +
			'tooltip-trigger="hover" ' +
			'tooltip-placement="bottom" ' +
			'tooltip-class="custom-popover"' +
			'></i>' +
			'</label>' +
			'</li></ul>'+
			'</div>'

		});

		formlyConfig.setType({
			"extends": "radio",
			name: "Radio",
			template:
			'<div class="radio-group switch-field">' +
			'<div style="padding-left: 0px;" ng-repeat="(key, option) in to.options" class="radio switch-title">' +
			'<input type="radio" id="{{id + \'_\'+ $index}}" tabindex="0" ng-value="option[to.valueProp || \'value\']" ng-model="model[options.key]">' +
			'<label for="{{id + \'_\'+ $index}}"><span>{{option[to.labelProp || \'name\']}}</span></label>' +
			'</div>' +
			'</div>'
		});

		formlyConfig.setType({
		    "extends": "checkbox",
		    name: "CheckBox",
		    template:
		    '<label class="easy_checkbox">' +
		    '<input type="checkbox"' +
		    'class="formly-field-checkbox"' +
		    'ng-model="model[options.key]">' +
			'{{to.label}}' +
	        '{{to.required ? \'*\' : \'\'}}' +
		    '<span class="checkmark"></span>' +
            '</label>'
	    });

		formlyConfig.setType({
			"extends": "textarea",
			name: "TextArea",
			defaultOptions: {
				templateOptions: {
					rows: 5
				}
			}
		});

		formlyConfig.setType({
			name: "Button",
			template: '<button style={{options.templateOptions.style.style}} ng-model="model[options.key]">{{ to.label }}</button>',
			defaultOptions: {
				templateOptions :{
					onClick: function ( value, options, scope, $event ) {
						this.clickButton(  value, options, scope, $event )
					}
				}
			}

		});

		/*
		The angular-formly-templates-bootstrap library "forces" the bootstrapLabel and bootstrapHasError
		wrappers on the inputs, so in order to control those wrappers we simply limit them to transcludes
		*/
		formlyConfig.setWrapper({
			name: "bootstrapHasError",
			overwriteOk: true,
			template: '<formly-transclude></formly-transclude>'
		});

		formlyConfig.setWrapper({
			name: "bootstrapLabel",
			overwriteOk: true,
			template: '<formly-transclude></formly-transclude>'
		});

		/*
		The defaultBootstrapLabel and defaultBootstrapHasError wrappers are used on datapoint form inputs that
		are not custom-configured by widget configuration
		*/
		formlyConfig.setWrapper({
			name: "defaultBootstrapLabel",
			overwriteOk: true,
			template:
			'<div class="control-group row-fluid">' +
				'<div class="span6">' +
					'<label for="{{ id }}" class="control-label">{{ to.label }}</label>' +
				'</div>' +
				'<div class="span6 field-wrapper">' +
					'<formly-transclude></formly-transclude>' +
				'</div>' +
			'</div>'
		});

		formlyConfig.setWrapper({
			name: "defaultBootstrapHasError",
			overwriteOk: true,
			template:
				'<div class="form-group" ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
					'<formly-transclude></formly-transclude>' +
				'<div class="control-group row-fluid validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">' +
					'<div class="span12">' +
					'<p>{{ options.data.validationMessage }}</p>' +
					'</div>' +
				'</div>' +
			'</div>'
		});



		formlyConfig.setWrapper({
			name: "bootstrapTooltipInput",
			types: ["TextBox", "Select", "TextArea", "Typeahead"],
			template:
				'<formly-transclude></formly-transclude>' +
				'<i ng-if="to.help" class="glyphicon glyphicon-question-sign field-help" ' +
					'input-popover="{{ to.help }}" ' +
					'input-popover-trigger="hover" ' +
					'input-popover-placement="bottom" ' +
					'tooltip-class="custom-popover"' +
				'></i>'
		});

		formlyConfig.setWrapper({
			name: "bootstrapTooltipIcon",
			types: [ "Radio", "CheckBox" ],
			template:
				'<div class="help-container help-{{ options.type | lowercase }}"><formly-transclude></formly-transclude>' +
              	  '<i ng-if="to.help" class="glyphicon glyphicon-question-sign field-help " ' +
				  'tooltip="{{ to.help }}" ' +
              	  'tooltip-trigger="hover" ' +
              	  'tooltip-placement="bottom" ' +
              	  'tooltip-class="custom-popover"' +
              	  '></i>' + '</div>'
		});

		formlyConfig.setWrapper({
			name: "checkboxWrapper",
			types: [ "CheckBox" ],
			template:
				'<div class="control-group row-fluid">' +
					'<div class="span12 field-wrapper">' +
						'<formly-transclude></formly-transclude>' +
					'</div>' +
				'</div>'
		});

	};

});
