define( 'components/quickLinks/controller/CommissionsController',['require'],function ( require ) {
	"use strict";

	CommissionsController.$inject = ["$scope", "$modalInstance", "UserProfile", "CommissionsService", "archApiUrl"];

	function CommissionsController( $scope, $modalInstance, UserProfile, CommissionsService, archApiUrl ) {
		$scope.close = $modalInstance.close;
		$scope.agencies = UserProfile.agencies;
		$scope.maverickCommissions = [];
		$scope.billingCommissions = [];
		$scope.commissionDocuments = [];
		$scope.documentCount = -1;
		$scope.pageSize = 10;
		if(UserProfile.agencyBillingLocationId === 0){
			$scope.billingAgencyId = UserProfile.agencyId;
			angular.forEach( $scope.agencies, function ( agency ) {
				if ( agency.id === UserProfile.agencyId ) {
					$scope.agency = agency.name;
				}
			} );
		}
		else {
			$scope.billingAgencyId = UserProfile.agencyBillingLocationId;
			$scope.agency = UserProfile.agencyBillingLocationName;
		}


		CommissionsService.getCommissions().then( function ( result ) {
			$scope.maverickCommissions = result.statements;
			angular.forEach( $scope.maverickCommissions, function ( maverickCommission ) {
				var commission = {
					'Agencyname': maverickCommission.Agencyname,
					'Statement_Date': new Date( maverickCommission.Statement_Date ),
					'document_link': maverickCommission.document_link
				};
				$scope.commissionDocuments.push( commission );
			} );
		} );

		CommissionsService.getCommissionDocuments( $scope.billingAgencyId ).then( function ( result ) {
			$scope.billingCommissions = result;
			angular.forEach( $scope.billingCommissions, function ( billingCommission ) {
				var commission = {
					'Agencyname': $scope.agency,
					'Statement_Date': new Date( billingCommission.date ),
					'document_link': archApiUrl + "application/dms/document/" + billingCommission.id
				};
				$scope.commissionDocuments.push( commission );
			} );
			$scope.documentCount = $scope.commissionDocuments.length;
		} );
	}

	return CommissionsController;

});
