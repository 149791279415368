define('components/errorModal/app',['require','angular','angular-bootstrap','components/errorModal/controller/ErrorModalController','common/service/user/UserService'],function(require) {
	"use strict";

	require("angular");
	require("angular-bootstrap");

	var app = angular.module("mPortal.components.errorModal", [
		"ui.bootstrap"
	]);

	var ErrorModalController = require("components/errorModal/controller/ErrorModalController"),
		UserService = require("common/service/user/UserService" );

	app.factory("UserService", UserService);
	app.controller("ErrorModalController", ErrorModalController);

	return app;

});
