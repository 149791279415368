define('components/specialtyApplication/questions/config/widgets/PolicyInformationWidget',['require'],function(require) {
    "use strict";

    //Use require statements below this comment to add any tag-based directives used in the Formly
    // wrappers and types defined in this file
    //require("components/{widgetFolder}/app");

    var widget = {};

    // getStructure() defines the grouping/nesting of the datapoints in this section and sets certain configuration data.
    // It is designed to be called by the ApplicationSectionConfiguration service

    // Some expectations regarding the structure data:
    // 1) A fieldGroup should always have wrapper, orderNumber, and fieldGroup properties
    // 2) The wrapper property is always an array
    // 3) A datapoint should always have a wrapper property, though it can be set to null or and empty array
    // 4) Optional datapoint properties:  type (string), orderNumber (integer), and wrapperOverride (boolean)
    //
    // The "type" property overrides the normal field type (label, select, etc.).  "wrapperOverride" when present
    // and set to true ensures that only the wrappers defined here are used (see the ApplicationSectionService logic)

    widget.getStructure = function() {

        return {
            "_datapointPlacement": "strictOrder",
            "Limits Label": { wrapper: null, wrapperOverride: true, type: "plainTextHeader"},

            "LimitDatapoints": { wrapper: ["twoPointRow"], fieldGroup:
                {
                    "Aggregate Limit": { wrapper: [ "horizontalBox", "verticalHalfRowError" ] },
                    "Medical Expenses": { wrapper: [ "horizontalBox", "verticalHalfRowError" ] }
                }
            },

            "Deductibles Label": { wrapper: null, wrapperOverride: true, type: "plainTextHeader" },

            "DeductibleDatapoints": { wrapper: ["twoPointRow"], fieldGroup:
                {
                    "Property Deductible": { wrapper: [ "horizontalBox", "verticalHalfRowError" ] },
                    "PD Liability Deductible": { wrapper: [ "horizontalBox", "verticalHalfRowError" ] }
                }
            },

            "Essential Label": { wrapper: null, wrapperOverride: true, type: "plainTextHeader" },

            "bop_CoverageSelection": { wrapper: [ "coverage-table-wrapper"], wrapperOverride: true , type:"coverage-table-type"},

            //Apparently this label isn't wanted
            "Terrorism Label": { wrapper: null, wrapperOverride: true, type: "omittedHeader"},

            "Terrorism Coverage": { wrapper: [ "verticalFullRowRadio", "verticalFullRowError" ], wrapperOverride: true, type: "yesNoToggle" },

            "Loss History Label" : { wrapper: null, wrapperOverride: true },

            "LossDetailsDataPoint":  { wrapper: ["twoPointRow"], fieldGroup:
                {
                    "Years Insured": { wrapper: [ "horizontalBox", "verticalHalfRowError" ] },
                    "wc_Years Insured": { wrapper: [ "horizontalBox", "verticalHalfRowError" ] },
                    "Previous Prem": { wrapper: [ "horizontalBox", "verticalHalfRowError" ] }
                }
            },
            "Jan_Losses" : { type: "yesNoToggle", wrapper: [ "verticalWideFullRowRadio", "verticalFullRowError" ], wrapperOverride: true },

            "Jan_LossesAvailable" : {  type: "yesNoToggle", wrapper: [ "verticalWideFullRowRadio", "verticalFullRowError", "lossRunsUpload" ], wrapperOverride: true,
                data: {
                    fileUploadSettings: {
                        documentType: "Loss Runs MSC",
                        documentTypeDescriptor: "loss runs",
                        buttonLabel: "Upload Loss Runs",
                        modalTitle: "Upload Loss Runs"
                    } }
            },

            "Waiver Include" : { type: "yesNoToggle", wrapper: [ "verticalFullRowRadio", "verticalFullRowError" ], wrapperOverride: true  },

            "Losses" : { type: "yesNoToggle", wrapper: [ "verticalFullRowRadio", "verticalFullRowError" ], wrapperOverride: true    },

            "Losses Available" : {  type: "yesNoToggle", wrapper: [ "verticalFullRowRadio", "verticalFullRowError", "lossRunsUpload" ], wrapperOverride: true,
                data: {
                    fileUploadSettings: {
                        documentType: "Loss Runs MSC",
                        documentTypeDescriptor: "loss runs",
                        buttonLabel: "Upload Loss Runs",
                        modalTitle: "Upload Loss Runs"
                    } }
            },

            "Loss Eligibility Label": { wrapper: null, wrapperOverride: true },

            "wcBop_SetLossTo0" : { wrapper: null, wrapperOverride: true },

            "HiddenLabelBlock": { wrapper: ["hideGroup"], fieldGroup:
                {
                    "Non Crime Claims Current" : { wrapper: null , type: "omittedHeader", wrapperOverride: true },
                    "Crime Claims Current": { wrapper: null , type: "omittedHeader", wrapperOverride: true },
                    "Liability Claims Current" : { wrapper: null , type: "omittedHeader", wrapperOverride: true },
                    "Prof Liab Claims Current" : { wrapper: null , type: "omittedHeader", wrapperOverride: true },

                    "Non Crime Claims First" : { wrapper: null , type: "omittedHeader", wrapperOverride: true },
                    "Crime Claims First": { wrapper: null , type: "omittedHeader", wrapperOverride: true },
                    "Liability Claims First" : { wrapper: null , type: "omittedHeader", wrapperOverride: true },
                    "Prof Liab Claims First" : { wrapper: null , type: "omittedHeader", wrapperOverride: true },

                    "Non Crime Claims Second" : { wrapper: null , type: "omittedHeader", wrapperOverride: true },
                    "Crime Claims Second" : { wrapper: null, type: "omittedHeader", wrapperOverride: true  },
                    "Liability Claims Second" : { wrapper: null, type: "omittedHeader", wrapperOverride: true  },
                    "Prof Liab Claims Second" : { wrapper: null, type: "omittedHeader", wrapperOverride: true  },

                    "Non Crime Claims Third" : { wrapper: null , type: "omittedHeader", wrapperOverride: true },
                    "Crime Claims Third" : { wrapper: null, type: "omittedHeader", wrapperOverride: true  },
                    "Liability Claims Third" : { wrapper: null, type: "omittedHeader", wrapperOverride: true  },
                    "Prof Liab Claims Third" : { wrapper: null, type: "omittedHeader", wrapperOverride: true  }
                }
            },

            "LossDetailsTable": {
                wrapper: ["fieldGroupTable"],
                data: {
                    table: {
                        class: "table table-bordered loss-details-table-margin",
                        headerRows: [

                            {
                                headers: [
                                    {
                                        text: " ", colspan: 2
                                    },
                                    {
                                        text: "# of Property Claims Non-Crime Related", colspan: 2

                                    },
                                    {
                                        text: "# of Property Claims Crime Related", colspan: 2

                                    },
                                    {
                                        text: "# of Liability Claims Excluding Professional", colspan: 2

                                    },
                                    {
                                        text: "# of Professional Liability Claims", colspan: 2

                                    }
                                ]
                            }
                        ],
                        headerRows1: [

                            {
                                headers: ["", "Carrier", "<=$10K", ">$10K", "<=$10K ", ">$10K ", "<=$10K  ", ">$10K  ", "<=$10K   ", ">$10K   "]
                            }
                        ]
                    }
                },
                fieldGroup: {

                    "row1": {
                        fieldGroup: {
                            "Loss Year Label Current": {wrapper: [ "verticalHalfRowTable2" ],wrapperOverride: true, type: "labelWithNoBg"},
                            "Loss Carrier Current": {wrapper: [ "topLabelLossDatapoint1","lossRowError" ], wrapperOverride: true},
                            "Non Crime Under 10K Current": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Non Crime Over 10K Current": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Crime Claims Under 10K Current": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Crime Claims Over 10K Current": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Liability Claims Under 10K Current": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Liability Claims Over 10K Current": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Prof Liab Claims Under 10K Current": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Prof Liab Claims Over 10K Current": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true }
                        }
                    },

                    "row2": {
                        fieldGroup: {
                            "Loss Year Label First": {wrapper: [ "verticalHalfRowTable2" ],wrapperOverride: true, type: "labelWithNoBg"},
                            "Loss Carrier First": {wrapper: [ "topLabelLossDatapoint1","lossRowError" ], wrapperOverride: true},
                            "Non Crime Under 10K First": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Non Crime Over 10K First": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Crime Claims Under 10K First": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Crime Claims Over 10K First": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Liability Claims Under 10K First": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Liability Claims Over 10K First": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Prof Liab Claims Under 10K First": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Prof Liab Claims Over 10K First": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true }
                        }
                    },

                    "row3": {
                        fieldGroup: {
                            "Loss Year Label Second": {wrapper: [ "verticalHalfRowTable2" ],wrapperOverride: true, type: "labelWithNoBg"},
                            "Loss Carrier Second": {wrapper: [ "topLabelLossDatapoint1","lossRowError" ], wrapperOverride: true},
                            "Non Crime Under 10K Second": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Non Crime Over 10K Second": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Crime Claims Under 10K Second": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Crime Claims Over 10K Second": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Liability Claims Under 10K Second": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Liability Claims Over 10K Second": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Prof Liab Claims Under 10K Second": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Prof Liab Claims Over 10K Second": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true }
                        }
                    },

                    "row4": {
                        fieldGroup: {
                            "Loss Year Label Third": {wrapper: [ "verticalHalfRowTable2" ],wrapperOverride: true, type: "labelWithNoBg"},
                            "Loss Carrier Third": {wrapper: [ "topLabelLossDatapoint1","lossRowError" ], wrapperOverride: true},
                            "Non Crime Under 10K Third": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Non Crime Over 10K Third": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Crime Claims Under 10K Third": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Crime Claims Over 10K Third": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Liability Claims Under 10K Third": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Liability Claims Over 10K Third": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Prof Liab Claims Under 10K Third": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true },
                            "Prof Liab Claims Over 10K Third": { wrapper: ["topLabelLossDatapoint","lossRowError"], wrapperOverride: true }
                        }
                    }
                }
            },

            "wcLossDetailsTable" : {
                wrapper: ["fieldGroupTable"],
                data: {
                    table: {
                        class: "table table-bordered loss-details-table-margin",
                        headerRows1: [

                            {
                                headers: ["Year ", "Carrier ", "# of Lost Time Cases* ", "# of Medical Only Cases* ", "Total Incurred Loss Amount ", "Estimated Annual Premium " ]
                            }
                        ]
                    }
                },
                fieldGroup: {

                    "row1": {
                        fieldGroup: {
                            "wc_Loss Year Label Current": { wrapper: [ "verticalHalfRowTable2" ],wrapperOverride: true, type: "labelWithNoBg" },
                            "wc_Loss Carrier Current": { wrapper: [ "topLabelLossDatapoint1","lossRowError" ], wrapperOverride: true },
                            "Lost Time Claims Current": { wrapper: [ "topLabelLossDatapoint","lossRowError" ], wrapperOverride: true },
                            "Medical Only Claims Current": { wrapper: [ "topLabelLossDatapoint","lossRowError" ], wrapperOverride: true },
                            "Total Loss Amount Current": { wrapper: [ "topLabelLossDatapoint2"], wrapperOverride: true },
                            "Loss Annual Premium Current": { wrapper: [ "topLabelLossDatapoint2"], wrapperOverride: true }
                        }
                    },

                    "row2": {
                        fieldGroup: {
                            "wc_Loss Year Label First": { wrapper: [ "verticalHalfRowTable2" ],wrapperOverride: true, type: "labelWithNoBg" },
                            "wc_Loss Carrier First": { wrapper: [ "topLabelLossDatapoint1","lossRowError" ], wrapperOverride: true },
                            "First Lost Time Claims": { wrapper: [ "topLabelLossDatapoint","lossRowError" ], wrapperOverride: true },
                            "First Medical Only Claims": { wrapper: [ "topLabelLossDatapoint","lossRowError" ], wrapperOverride: true },
                            "Total Loss Amount First": { wrapper: [ "topLabelLossDatapoint2" ], wrapperOverride: true },
                            "Loss Annual Premium First": { wrapper: [ "topLabelLossDatapoint2" ], wrapperOverride: true }
                        }
                    },

                    "row3": {
                        fieldGroup: {
                            "wc_Loss Year Label Second": { wrapper: [ "verticalHalfRowTable2" ],wrapperOverride: true, type: "labelWithNoBg" },
                            "wc_Loss Carrier Second": { wrapper: [ "topLabelLossDatapoint1","lossRowError" ], wrapperOverride: true },
                            "Lost Time Claims Second": { wrapper: [ "topLabelLossDatapoint","lossRowError" ], wrapperOverride: true },
                            "Medical Only Claims Second": { wrapper: [ "topLabelLossDatapoint","lossRowError" ], wrapperOverride: true },
                            "Total Loss Amount Second": { wrapper: [ "topLabelLossDatapoint2" ], wrapperOverride: true },
                            "Loss Annual Premium Second": { wrapper: [ "topLabelLossDatapoint2" ], wrapperOverride: true }
                        }
                    },

                    "row4": {
                        fieldGroup: {
                            "Loss Year Label": { wrapper: [ "verticalHalfRowTable2" ],wrapperOverride: true, type: "labelWithNoBg" },
                            "wc_Loss Carrier Third": { wrapper: [ "topLabelLossDatapoint1","lossRowError" ], wrapperOverride: true },
                            "Lost Time Claims Third": { wrapper: ["topLabelLossDatapoint","lossRowError" ], wrapperOverride: true },
                            "Medical Only Claims Third": { wrapper: ["topLabelLossDatapoint","lossRowError" ], wrapperOverride: true },
                            "Total Loss Amount Third": { wrapper: [ "topLabelLossDatapoint2" ], wrapperOverride: true },
                            "Loss Annual Premium Third": { wrapper: [ "topLabelLossDatapoint2" ], wrapperOverride: true }
                        }
                    },

                    "row5": {
                        fieldGroup:
                            {
                                "Loss Description": { wrapper: ["twoPointRow", "lossDescriptionTextAreaBox"] , wrapperOverride: true, type: "TextArea", data: { cell: { colspan: 6 } } }
                            }
                    }
                }
            }
        };

    };

    // configureFormly() adds Formly wrappers and types specific to this widget to the global Formly configuration
    // It is designed to be called by widgetFormlyConfiguration
    // NOTE: if you need to define a validation error wrapper to replace the default, make sure the wrapper name
    // ends in "Error"

    widget.configureFormly = function (formlyConfig, formlyApiCheck) {

        formlyConfig.setWrapper({
            name: "lossRowError",
            overwriteOk: true,
            template:
            '<div class="span1 form-group" style="width: 60% !important;"  ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid }">' +
            '<formly-transclude></formly-transclude>' +
            '</div>'

        });

        formlyConfig.setWrapper({
            name: "verticalWideFullRowRadio",
            template:
            '<label style="display:inline !important; width:49.5%;float:left;font-weight: bold;" for="{{ id }}" class="control-label">{{ to.label }}' + '</label>' +
            '<formly-transclude></formly-transclude>'

        });

        formlyConfig.setWrapper({
            name: "lossDescriptionTextAreaBox",
            template:
            '<div class="lossDescriptionTextAreaBox">' + '<label style="margin-top: 30px;font-weight: bold;" for="{{ id }}" class="control-label">{{ to.label }}</label>' +
            '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "topLabelLossDatapoint",
            template: '<style>.lossLabelRow input { width: 50px !important; } </style><div class="lossLabelRow">'
            + '<formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "topLabelLossDatapoint1",
            template: '<style>.lossLabelRow1 input { width: 120px !important; } </style><div class="lossLabelRow1">' +
            '<formly-transclude></formly-transclude>' + '</div>'

        });

        formlyConfig.setWrapper({
            name: "topLabelLossDatapoint2",
            template: '<style>.lossLabelRow input { width: 50px !important; } </style><div class="lossLabelRow">'
            + '$ <formly-transclude></formly-transclude>' + '</div>'
        });

        formlyConfig.setWrapper({
            name: "verticalHalfRowTable2",
            template:
            '<div >' +
            '<formly-transclude></formly-transclude>' +
            '</div>'
        });

        formlyConfig.setType({
            name: "plainTextHeader",
            template: '<div ng-bind-html="options.templateOptions.label | trustHtml"></div>',
            defaultOptions: {
                noFormControl: true
            }
        });

        formlyConfig.setType({
            name: "omittedHeader",
            template: '',
            defaultOptions: {
                noFormControl: true
            }
        });

        formlyConfig.setType({
            name: "coverage-table-type",
            template: '<coverage-table model="model" options="options" fields="fields" form="form"></coverage-table>',
            defaultOptions: {
                noFormControl: true
            }
        });

        formlyConfig.setWrapper({
            name: "coverage-table-wrapper",
            template: '<div class="row-fluid coverage-table"><formly-transclude></formly-transclude></div>'
        });

        formlyConfig.setWrapper({
            name: "lossRunsUpload",
            overwriteOk: true,
            template:
            '<div>' +
            '<formly-transclude></formly-transclude>' +
            '<div style="margin-left: 10px" class="row-fluid document-upload" ng-if="options.defaultValue === \'Yes\'">' +
            '<document-upload settings="options.data.fileUploadSettings" datapoint-applications="options.data.applications"></document-upload>' +
            '</div>' +
            '</div>'
        });

        formlyConfig.setType({
            name: "labelWithNoBg",
            template: '<div ng-bind-html="options.templateOptions.label | trustHtml"></div>',
            defaultOptions: {
                noFormControl: true
            }
        });

    };

    return widget;


});
