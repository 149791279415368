define('specialtyApplication/controller/SpecialtyApplicationController',['require'],function(require) {
    "use strict";

    SpecialtyApplicationController.$inject = ["$scope","$route", "$q", "$location", "UserProfile","SubmissionStore" , "Wizard"];

    function SpecialtyApplicationController( $scope,$route, $q , $location, UserProfile, SubmissionStore, Wizard ) {

        $scope.account     = UserProfile;
        $scope.view        = $route.current.view;
        $scope.nav_hidden  = $route.current.nav_hidden;
        $scope.layout      = ($route.current.layout)?$route.current.layout:'default';
        $scope.show_wizard = Wizard.visible() && $.inArray($scope.view,['account','questions','review']) != -1;

        SubmissionStore.insuredMatchDispThreshold   = 115;
        SubmissionStore.cobrandingId                = $scope.account.cobranding.id;
        SubmissionStore.agentId                     = ($scope.account.type == 'Agent') ? $scope.account.userId : SubmissionStore.agentId;
        

        if(SubmissionStore.programs.length)
        {
            SubmissionStore.programCode             = SubmissionStore.programs[0].programCode;
            SubmissionStore.marketedProgramId       = SubmissionStore.programs[0].marketedProgramId;
        }

        if($.inArray($scope.view,['eligibility','search']) != -1)
        {
            Wizard.reset();
        }

        if(Wizard.visible())
        {
            var wizard_data = Wizard.data.entries['id-0'];

            if(wizard_data.store)
            {
                angular.extend( SubmissionStore,wizard_data.store);
                UserProfile.set( "editedApplication", wizard_data.store.applicationId );
            }

            if(wizard_data.insured)
            {
                angular.extend( SubmissionStore.insured,wizard_data.insured);
            }
        }


        $scope.getAencyID = function()
        {
            if(SubmissionStore.agencyId === null)
            {
                if($scope.account.type == 'Agent' || $scope.account.agencyEmulation)
                {
                    if($scope.account.iconAgencies && $scope.account.iconAgencies.length)
                    {
                        return $scope.account.iconAgencies[ 0 ].mbuAgencyId;
                    }
                    else
                    {
                        return SubmissionStore.agencyId;
                    }
                }
            }
            else
            {
                return SubmissionStore.agencyId;
            }
        }

        SubmissionStore.agencyId                    = $scope.getAencyID();  

    }

    return SpecialtyApplicationController;

});
