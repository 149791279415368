define('components/bigNews/controller/BigNewsController',['require'],function(require) {
	"use strict";

	BigNewsController.$inject = ["$scope", "$modal"];

	function BigNewsController( $scope, $modal ) {

		$scope.news = {
			visible: false
		};

		// newsItems are injected via the directive from the calling page
		$scope.newsItems.then(function( result ) {
			if( result.bigNews.bigNewsDescriptor ) {
				$scope.news = result.bigNews;
				$scope.news.visible = true;
			}
		});

		$scope.openNewsModal = function() {
			$modal.open({
				templateUrl: "app/components/newsHeadlines/template/newsItem.html",
				controller: "NewsItemController",
				resolve: {
					newsItem: function() {
						return $scope.news;
					}
				}
			});
		};

	}

	return BigNewsController;

});
