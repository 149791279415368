define( 'components/specialtyApplication/questions/config/widgets/UnderwriterQuestionsWidget',['require'],function( require ) {
    "use strict";
    var widget = {};

    widget.getStructure = function() {
        return {
            "_datapointPlacement": "bottom",
            "Description of Operations": { wrapper: [ "offsetWideVerticalDatapointWithError" ], wrapperOverride: true, orderNumber: 1 },
        };
    };

    widget.configureFormly = function( formlyConfig, formlyApiCheck ) {

        formlyConfig.setWrapper({
            name: "offsetWideVerticalDatapointWithError",
            template: '<div class="row-fluid"><div class="span1">&nbsp;</div><div class="span10">'
                + '<br /><label for="{{ id }}" class="control-label" style="width:100% !important;">{{ to.label }}</label><br />'
                + '<div ng-class="{ \'has-error\': !options.data.isValid && options.validation.errorExistsAndShouldBeVisible, required: to.required, invalid: !to.data.valid, fullWidthTextareaDatapoint: true }">'
                    + '<formly-transclude></formly-transclude>'
                    + '<div class="validation-messages" ng-if="!options.data.isValid && options.validation.errorExistsAndShouldBeVisible">'
                        + '<p>{{ options.data.validationMessage }}</p>'
                    + '</div>'
                + '</div>'
            + '</div></div>'
        });

    };

    return widget;
});
