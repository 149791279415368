define('isc/controller/IscPolicyController',['require'],function(require) {
	"use strict";

	IscPolicyController.$inject = ["$scope", "$sce", "$routeParams", "$timeout", "iscRoot", "$window", "$location", "UserService","ciamApiUrl"];

	function IscPolicyController( $scope, $sce, $routeParams, $timeout, iscRoot, $window, $location, UserService, ciamApiUrl) {

		/*
		 Set a callback function and an event listener to handle a message from the ISC indicating
		 that the user is no longer logged into ISC (and hence no longer logged into the portal)
		 */
		function onMessageCallback( event ) {
			$scope.messageListener( event.originalEvent );
		}

		angular.element( $window ).on( "message", onMessageCallback );

		$scope.messageListener = function( event ) {
			var eventData;
			try {
				eventData = JSON.parse( event.data );
			} catch( err ) {
				// The event will fire on the first load of the controller; ignore it
				return;
			}

			if( eventData.messageType == "notloggedin" ) {
				// Remove/clean up the event listener
				angular.element( $window ).off( "message", onMessageCallback );

				/*
				 Verify that the user is no longer logged into the portal, and it that's the case
				 redirect them to the login page along with the query param that will return them
				 to this route after login.
				 */
				UserService.getUserProfile().then(function( profile ) {
					if( !profile.isLoggedIn ) {
						$window.location = ciamApiUrl + 'auth/login-request/mportal?target_url=/isc/policy/' + $routeParams.policyId;
					}
				});
			}
		};

		$scope.getIframeSrc = function() {
			var url = iscRoot + "index.cfm?nextevent=";
			if( $routeParams.area === "payment" ){
				url += "page.payment&ipi_id=" + $routeParams.subId;
			} else {
				url += "selectInsured&policyNumber=" + $routeParams.id;
			}
			return $sce.trustAsResourceUrl( url );
		};

		$scope.getReturnUrl = function (){
			return $window.location.origin + $window.location.pathname;
		};

		$scope.submitForm = function() {
			angular.element("#isc-form").submit();
		};

		// in order to auto-submit the form, we need to wait for angular to process a digest cycle and
		// populate the iframe source. easiest way to accomplish this is by just wrapping it in a timeout
		$timeout( $scope.submitForm );

	}

	return IscPolicyController;

});
