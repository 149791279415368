define('components/widget/optional-endorsements/photography-make-model/directive/photographyMakeModel',['require'],function(require) {
    "use strict";

    photographyMakeModel.$inject = [];

    function photographyMakeModel() {
        return {
            restrict: "AE",
            scope:{
                model	            : "=",
                options             : "="
            },
            templateUrl: "app/components/widget/optional-endorsements/photography-make-model/template/template.html",
            controller: "photographyMakeModelController"
        };
    }

    return photographyMakeModel;

});
