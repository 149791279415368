define('common/service/specialtyApplication/SubmissionStore',[],function() {
	"use strict";

	function SubmissionStore() {
		var store = {};

		store.applicationId 			= null;
		store.submissionId 				= null;
		store.applicant 				= null;
		store.agencyId 					= null;
		store.programCode 				= null;
		store.programName 				= null;
		store.marketedProgramId 		= null;
		store.state 					= null;
		store.effectiveDate 			= null;
		store.cobrandingId 				= null;
		store.phase 					= null;
		store.paymentPlan 				= null;
		store.delayedPaymentFlag		= false;
		store.finalizedApplication 		= false;
		store.visitedSections 			= [];
		store.documentWindows 			= {};
		store.preBindEdit 				= {};
		store.preBindQuestionsAnswered 	= {};
		store.preBindPayment 			= {};
		store.previousValues 			= {};
		store.programs					= [];
		store.form						= {};
		store.activeApplication			= 0;
		store.statusResults				= [];
		store.isUnAuthenticatedDirect   = false;

		store.reset = function() {
			angular.forEach( store, function( value, key ) {
				if( typeof value !== "function" ) {
					store[ key ] = null;
				}
			});

			store.delayedPaymentFlag		= false;
			store.finalizedApplication 		= false;
			store.preBindEdit 				= {};
			store.preBindQuestionsAnswered 	= {};
			store.preBindPayment 			= {};
			store.visitedSections 			= [];
			store.documentWindows 			= {};
			store.previousValues 		    = {};
			store.programs				   	= [];
			store.form						= {};
			store.activeApplication			= 0;
			store.statusResults				= [];
			store.isUnAuthenticatedDirect   = false;
		};

		return store;
	}

	return SubmissionStore;

});
