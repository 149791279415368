define('components/accountSummary/quoteList/app',['require','components/accountSummary/paymentSystem/app','components/accountSummary/quoteList/directive/quoteList','components/accountSummary/quoteList/controller/QuoteListController','moment'],function(require) {
	"use strict";

	require("components/accountSummary/paymentSystem/app");

	var quoteList = require("components/accountSummary/quoteList/directive/quoteList"),
		QuoteListController = require("components/accountSummary/quoteList/controller/QuoteListController"),
		moment = require("moment");

	var component = angular.module("mPortal.accountSummary.quoteList", [ "mPortal.accountSummary.paymentSystem"]);

	component.directive("quoteList", quoteList);
	component.controller("QuoteListController", QuoteListController);
	component.value("moment", moment);

	return component;

});
