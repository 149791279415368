define( 'components/specialtyApplication/submit/review/controller/ReviewController',['require'],function( require ) {
	"use strict";

	ReviewController.$inject = [
		"$scope",
		"$location",
		"$timeout",
		"SubmissionStore",
		"UserProfile",
		"Wizard",
		"QuestionService",
		"GoogleAdWordsService",
		"ResultService" ];

	function ReviewController(
		$scope,
		$location,
		$timeout,
		SubmissionStore,
		UserProfile,
		Wizard,
		QuestionService,
		GoogleAdWordsService,
		ResultService ) {

		$scope.programs = {};
		angular.forEach( SubmissionStore.programs, function( program ) {
			$scope.programs[ program.referenceString.toLowerCase() ] = true;
		});

		SubmissionStore.visitedSections = [ "/application/review" ];

		$scope.uiState = {
			stage: "start",
			userAction: "",
			autoOutcomeCompleted: false,
			submitCompleted: false,
			resultCompleted: false,
			messageIndex: 0,
			currentMessage: {},
			errorType: ""
		};

		$scope.marketingMessages = [
			{ text: "Thank you for choosing Markel", class: "markel-red" },
			{ text: "Flexible payment options", class: "" },
			{ text: "Ease of doing business", class: "" },
			{ text: "Customized Coverage", class: "" },
			{ text: "Designed for small business", class: "" }
		];

		$scope.insured = SubmissionStore.insured;

		//add access to wizard of the quotes object
        $scope.quotes =  Wizard.data.entries['id-0'];
        $scope.maxAttempt = 7;
        $scope.attempt = 0;

		/*
		 There is a lot of state management in this controller designed to support the goal of showing content to the user
		 while processing the submission (in two stages) in the background.

		 --If the user opts to edit the application, what happens depends on the processing state.  The application cannot
		 be transitioned back to the incomplete phase until the relevant status change (a decline or a submit)
		 process has completed (successfully or not), so if the editSubmission() method is invoked before then, the user will
		 see a waiting message until the edit can proceed.  Otherwise, the edit transition / navigation will start
		 immediately, even if the result data retrieval process has started.

		 --If the user opts to "continue" to submit the application, the time-delayed marketing message display (ported
		 over from Maverick) will be started.  No matter what the processing state is, the first message (the thank you)
		 will always be displayed followed by a 1.5 second delay before the second execution of the message display
		 occurs.  If all REST-based processing is done (successfully or unsuccessfully) before or by that second
		 execution, the message display will stop and either be replaced by an error display or the user will be
		 navigated to the result page with the result data ready to be displayed immediately.

		 All of the error displays include a clickable link for re-attempting whatever part of the REST-base processing
		 failed.
		*/

		$scope.executeAutoOutcome = function() {
			ResultService.getAutoOutcomeResult( SubmissionStore.applicationId, false, false )
				.then( function( result ) {
					$scope.uiState.autoOutcomeCompleted = true;
					if( result.outcome == 'Decline' ) {
						if( $scope.uiState.userAction == "edit" ) {
							$scope.reopenSubmission();
						} else {
							// If we decline, that is essentially our "submit", so we mark the process as such and go get the status
							$scope.uiState.submitCompleted = true;
							SubmissionStore.finalizedApplication = true;
							$scope.getStatusData();
						}
					} else {
						if( $scope.uiState.userAction == "edit" ) {
							$scope.reopenSubmission();
						} else {
							$scope.processSubmission();
						}
					}
				})
				.catch( function( error ) {
					// Ignore the autoOutcome error if user has already opted to edit
					if( $scope.uiState.userAction == "edit" ) {
						$scope.reopenSubmission();
					} else {
						$scope.uiState.errorType = "autoOutcomeError";
						if( error.status == 500 || $scope.uiState.stage == "retryingSubmit" || ( $scope.uiState.stage == "continue" && $scope.uiState.messageIndex ) ) {
							$scope.uiState.stage = "showError";
						}
					}


				})
		}

		$scope.processSubmission = function() {
			var insuredName = SubmissionStore.insured && SubmissionStore.insured.name ? SubmissionStore.insured.name : ""
			QuestionService.submitApplication( SubmissionStore.applicationId, SubmissionStore.marketedProgramId, SubmissionStore.cobrandingId, insuredName )
				.then( function( result ) {
					$scope.uiState.submitCompleted = true;
					if( result.success ){
						if( $scope.uiState.userAction == "edit" ) {
							$scope.reopenSubmission();
						} else {
							// Record the successful submission for any conversion tracking
							GoogleAdWordsService.trackSubmittedApplicationConversion( SubmissionStore.applicationId );

							// Mark application as finalized
							SubmissionStore.finalizedApplication = true;
							$scope.executePoll();
						}

					} else {
						// Ignore the submit error if user has already opted to edit
						if( $scope.uiState.userAction == "edit" ) {
							$scope.reopenSubmission();
						} else {
							$scope.uiState.errorType = "submitError";
							if( $scope.uiState.stage == "retryingSubmit" || ( $scope.uiState.stage == "continue" && $scope.uiState.messageIndex ) ) {
								$scope.uiState.stage = "showError";
							}
						}

					}
				});

		};

		$scope.executePoll = function(){
			if(SubmissionStore.programCode === "GenPackage") {
				$scope.attempt++;
				ResultService.getStatusSummary(SubmissionStore.applicationId).then(function (response) {
					$scope.error = response.error;
					if($scope.error) {
						$scope.uiState.errorType = "resultError";
						$scope.uiState.stage = "showError";
					}else{
						if (response.data[0].isAvailableForBind || response.data[0].portalStatus === "Quoted" || response.data[0].portalStatus === "Declined") {
							$scope.getStatusData();
						} else if ($scope.attempt === $scope.maxAttempt) {
							$scope.getStatusData();
						} else {
							$timeout($scope.executePoll, 3000);
						}
					}
				});
			}
			else{
				$scope.getStatusData();
			}
		};

		$scope.getStatusData = function() {
			ResultService.getStatus( SubmissionStore.applicationId )
				.then( function ( response ) {
					if( response.error ) {
						$scope.uiState.errorType = "resultError";
						// A 500 error will display the error modal window, so no point in waiting to show the error
						if( response.status == 500 || $scope.uiState.stage == "retryingSubmit" || ( $scope.uiState.stage == "continue" && $scope.uiState.messageIndex ) ) {
							$scope.uiState.stage = "showError";
						}
					} else {
						SubmissionStore.statusResults = response.data;
						$scope.uiState.resultCompleted = true;
						// Navigate to the result page if this is a reattempted submission or if the user has already clicked the continue
						// button and has seen the first marketing message.
						if( $scope.uiState.stage == "retryingSubmit" || ( $scope.uiState.stage == "continue" && $scope.uiState.messageIndex ) ) {
							$scope.navigate( "result" );
						}
					}
				});
		};

		$scope.continueSubmission = function() {
			$scope.uiState.userAction = "continue";
			$scope.uiState.stage = "continue";

			function messageCarousel() {
				if( $scope.uiState.resultCompleted && $scope.uiState.messageIndex > 0 ) {
					$scope.navigate( "result" );
				} else if ( $scope.uiState.errorType && $scope.uiState.messageIndex > 0 ) {
					$scope.uiState.stage = "showError";
				} else if( $scope.uiState.messageIndex < $scope.marketingMessages.length ) {
					$timeout( messageCarousel, 1500 );
					$scope.uiState.currentMessage = $scope.marketingMessages[ $scope.uiState.messageIndex ];
					$scope.uiState.messageIndex += 1;
				}
			}

			messageCarousel();
		};

		$scope.editSubmission = function() {
			$scope.uiState.userAction = "edit";
			if( !$scope.uiState.submitCompleted ) {
				$scope.uiState.stage = "waitToEdit";
			} else {
				$scope.reopenSubmission();
			}
		};

		$scope.reopenSubmission = function() {
			UserProfile.set( "editedApplication", SubmissionStore.applicationId );
			if( UserProfile.type == 'Agent' ) {
				$scope.navigate( "agent/edit/" + SubmissionStore.applicationId );
			} else {
				$scope.navigate( "resume/" + SubmissionStore.applicationId );
			}
		};

		$scope.retrySubmit = function() {
			$scope.uiState.stage = "retryingSubmit";
			$scope.uiState.errorType = "";
			$scope.executeAutoOutcome();
		};

		$scope.retryResults = function() {
			$scope.navigate( "result" );
		};

		$scope.navigate = function( applicationPath ) {
			// Clear visitedSections to prevent navigation back to this review
			SubmissionStore.visitedSections = [];

			if(applicationPath == 'result')
			{
				if(Wizard.visible())
				{
					//update our wizard step
					$scope.quotes.step = 5;
					Wizard.set($scope.quotes);
					console.log($scope.quotes.result_url);
					//redirect to our combined result in old code
					$location.path($scope.quotes.result_url);
				}
				else
				{
					$location.path( "/application/" + applicationPath );
				}
			}
			else
			{
				$location.path( "/application/" + applicationPath );
			}
		};

		$scope.executeAutoOutcome();
	}

	return ReviewController;

});
