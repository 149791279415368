define('home/app',['require','common/config/config','components/activityFeed/app','components/bigNews/app','components/newsHeadlines/app','components/promotionsSummary/app','components/quickLinks/app','components/recentlyViewed/app','components/rewardsSummary/app','components/startQuote/app','components/startQuoteCommercialPackage/app','components/productSearch/container/app','components/surveyList/app','angular-sanitize','shim/overmind','home/controller/HomeController','common/service/user/UserProfile'],function(require) {
	"use strict";

	require("common/config/config");
	require("components/activityFeed/app");
	require("components/bigNews/app");
	require("components/newsHeadlines/app");
	require("components/promotionsSummary/app");
	require("components/quickLinks/app");
	require("components/recentlyViewed/app");
	require("components/rewardsSummary/app");
	require("components/startQuote/app");
	require("components/startQuoteCommercialPackage/app");
	require("components/productSearch/container/app");
	require("components/surveyList/app");
    require("angular-sanitize");

	var overmind = require("shim/overmind");

	var HomeController = require("home/controller/HomeController"),
		UserProfile = require("common/service/user/UserProfile");

	var app = angular.module("mPortal.home", [
		"mPortal.config",
		"mPortal.components.activityFeed",
		"mPortal.components.bigNews",
		"mPortal.components.newsHeadlines",
		"mPortal.components.promotionsSummary",
		"mPortal.components.quickLinks",
		"mPortal.components.recentlyViewed",
		"mPortal.components.rewardsSummary",
		"mPortal.components.startQuote",
		"mPortal.components.startQuoteCommercialPackage",
		"mPortal.components.productSearch.container",
		"mPortal.components.surveyList"
	]);

	app.controller("HomeController", HomeController);
	app.factory("UserProfile", UserProfile);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider.when("/", {
			templateUrl: "app/home/template/template.html",
			controller: "HomeController"
		});
	}]);

	return app;

});
