define('common/filter/truncate',['require'],function(require) {
	"use strict";

	// from rexUtil.js
	function Truncate() {
		return function( value, maxLength ) {

			if( value.length <= maxLength ) {
				return value;
			}

			return value.substr( 0, maxLength ) + "...";

		};
	}

	return Truncate;

});
