define('components/recentlyViewed/app',['require','common/service/RecentlyViewedService','common/service/RenewalService','components/recentlyViewed/directive/recentlyViewed','components/recentlyViewed/controller/RecentlyViewedController'],function(require) {
	"use strict";

	var RecentlyViewedService = require("common/service/RecentlyViewedService"),
		RenewalService = require("common/service/RenewalService"),
		recentlyViewed = require("components/recentlyViewed/directive/recentlyViewed"),
		RecentlyViewedController = require("components/recentlyViewed/controller/RecentlyViewedController");

	var component = angular.module("mPortal.components.recentlyViewed", []);

	component.factory("RecentlyViewedService", RecentlyViewedService);
	component.factory("RenewalService", RenewalService);
	component.directive("recentlyViewed", recentlyViewed);
	component.controller("RecentlyViewedController", RecentlyViewedController);

	return component;

});
