define('components/documentDeliveryPreferences/controller/ElectronicDeliveryTermsModalController',['require'],function(require) {
	"use strict";

	ElectronicDeliveryTermsModalController.$inject = ["$scope", "$modalInstance", "$modal" , "buttonText"];

	function ElectronicDeliveryTermsModalController( $scope, $modalInstance, $modal, buttonText ) {
		$scope.close = $modalInstance.close;
		$scope.buttonText = buttonText;
	}

	return ElectronicDeliveryTermsModalController;

});


