define( 'components/specialtyApplication/resume/directive/resume',['require'],function( require ) {
    "use strict";

    Resume.$inject = [];

    function Resume() {
        return {
            restrict: "AE",
            scope: true,
            templateUrl: "app/components/specialtyApplication/resume/template/template.html",
            controller: "ResumeController"
        };
    }

    return Resume;
});

