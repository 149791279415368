define('components/classCodesSearch/filter/formatClassCodeDescription',['require'],function(require) {
	"use strict";

	FormatClassCodeDescription.$inject = ["$sce"];

	function FormatClassCodeDescription( $sce ) {
		return function( descriptions ) {
			return $sce.trustAsHtml( descriptions.join("<br>") );
		};
	}

	return FormatClassCodeDescription;

});
