define('components/accountSummary/quoteList/directive/quoteList',['require'],function(require) {
	"use strict";

	function QuoteList() {
		return {
			restrict: "AE",
			scope: {
				quotes: "=",
				account: "=",
				quoteType: "@",
				openTab: "=",
				setMessageView: "=",
				user: '=',
				openApplicationTab: '='
			},
			templateUrl: "app/components/accountSummary/quoteList/template/template.html",
			controller: "QuoteListController"
		};
	}

	return QuoteList;

});
