define('components/userAccountSettings/stateNewsSubscriptions/directive/stateNewsSubscriptions',['require'],function(require) {
	"use strict";

	function StateNewsSubscriptions() {
		return {
			restrict: "AE",
			scope: {
				subscriptions: '='
			},
			templateUrl: "app/components/userAccountSettings/stateNewsSubscriptions/template/template.html",
			controller: "StateNewsSubscriptionsController"
		};
	}

	return StateNewsSubscriptions;

});
