define('components/accountSummary/paymentSystem/app',['require','components/accountSummary/paymentSystem/directive/paymentSystem','common/service/specialtyApplication/accountService','components/accountSummary/paymentSystem/controller/PaymentSystemController'],function( require ) {
    "use strict";

    var paymentSystem 			= require( "components/accountSummary/paymentSystem/directive/paymentSystem" ),
    	AccountService 			= require( "common/service/specialtyApplication/accountService" ),
        PaymentSystemController = require( "components/accountSummary/paymentSystem/controller/PaymentSystemController" );

    var component = angular.module( "mPortal.accountSummary.paymentSystem", [] );

    component.directive( "paymentSystem", paymentSystem );
    component.factory("AccountService", AccountService);
    component.controller( "PaymentSystemController", PaymentSystemController );

    return component;

});
