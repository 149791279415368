define('components/recentlyViewed/controller/RecentlyViewedController',['require'],function(require) {
	"use strict";

	RecentlyViewedController.$inject = [ "$scope", "$window", "maverickRoot", "RecentlyViewedService" ];

	function RecentlyViewedController( $scope, $window, maverickRoot, RecentlyViewedService ) {

		RecentlyViewedService.getRecentlyViewed().then(function( results ) {
			$scope.recentlyViewed = results.recentlyViewed;
			$scope.renewals = results.renewals;
			$scope.pendingCancel = results.pendingCancel;
		});

		$scope.showRenewalDocument = function( id ) {
			$window.open(
				maverickRoot + "policymgmt/Policies/Documents/openDocument.cfm?DocumentID=" + id,
				"Statement",
				"toolbar=0,location=0,directories=0,status=1,menubar=0,scrollbars=1,resizable=1,width=1092,height=785"
			);
		};

	}

	return RecentlyViewedController;

});
