define( 'components/specialtyApplication/pricingDetails/animalMortality/app',['require','components/specialtyApplication/pricingDetails/animalMortality/directive/animalMortality','common/filter/formatMoney'],function ( require ) {
    "use strict";

    var animalMortality = require( "components/specialtyApplication/pricingDetails/animalMortality/directive/animalMortality" ),
        formatMoney = require( "common/filter/formatMoney" );

    var component = angular.module( "mPortal.components.specialtyApplication.pricingDetails.animalMortality", [] );

    component.filter( "formatMoney", formatMoney );
    component.directive( "animalMortality", animalMortality );

    return component;

} );
