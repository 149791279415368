define( 'components/applicationData/applicationSection/app',['require','components/applicationData/applicationSection/directive/applicationSection','components/applicationData/applicationSection/controller/ApplicationSectionController','common/service/specialtyApplication/ApplicationSectionService','common/service/specialtyApplication/ApplicationSectionBuilder','common/service/specialtyApplication/ApplicationStateStore','common/service/specialtyApplication/DatapointModificationService','common/service/specialtyApplication/EligibilityGuidelineService','common/service/specialtyApplication/ApplicationSectionViewConfiguration','common/filter/trustHtml'],function( require ) {
    "use strict";

    var applicationSection = require( "components/applicationData/applicationSection/directive/applicationSection"),
        ApplicationSectionController = require( "components/applicationData/applicationSection/controller/ApplicationSectionController"),
        ApplicationSectionService = require("common/service/specialtyApplication/ApplicationSectionService"),
        ApplicationSectionBuilder = require("common/service/specialtyApplication/ApplicationSectionBuilder"),
        ApplicationStateStore = require("common/service/specialtyApplication/ApplicationStateStore"),
        DatapointModificationService = require("common/service/specialtyApplication/DatapointModificationService"),
        EligibilityGuidelineService = require("common/service/specialtyApplication/EligibilityGuidelineService"),
        ApplicationSectionViewConfiguration = require("common/service/specialtyApplication/ApplicationSectionViewConfiguration"),
        trustHtml = require( "common/filter/trustHtml" );

    var component = angular.module( "mPortal.applicationData.applicationSection", [] );

    component.directive( "applicationSection", applicationSection );
    component.filter( "trustHtml", trustHtml );
    component.controller( "ApplicationSectionController", ApplicationSectionController );
    component.factory( "ApplicationSectionService", ApplicationSectionService );
    component.factory("ApplicationSectionBuilder", ApplicationSectionBuilder);
    component.factory("ApplicationStateStore", ApplicationStateStore);
    component.factory("DatapointModificationService", DatapointModificationService);
    component.factory("EligibilityGuidelineService", EligibilityGuidelineService);
    // The view configuration file will take place of the one normally used during the submission process
    component.factory( "ApplicationSectionConfiguration", ApplicationSectionViewConfiguration);
    return component;

});
