define('components/applicationActions/controller/ApplicationActionsController',[],function() {

    ApplicationActionsController.$inject = [ "$scope", "$modal", "SpecialtyDocumentService" ];

    function ApplicationActionsController( $scope, $modal, SpecialtyDocumentService ) {

        $scope.action = '';

        $scope.canPrint = function() {
            return !!$scope.store.applicationId && !!$scope.store.programName;
        };

        $scope.canResumeLater = function() {
            // To start with, a resume short link is only provided for certain programs
            return $scope.canPrint() && $scope.store.resumeLink;
        };

        $scope.determineAction = function() {
            var determinedAction = "";
            if( $scope.canPrint() ) {
                determinedAction = $scope.canResumeLater() ? "resume" : "print";
                if( $scope.store.programs ) {
                    angular.forEach( $scope.store.programs, function( program ) {
                       if( program.isUploadBasedSubmission ) {
                           determinedAction = "";
                       }
                    });
                }
            }
            if($scope.store.programCode === "GenPackage"){
                determinedAction = "";
            }
            $scope.action = determinedAction;
        };

        $scope.determineAction();

        $scope.printApplication = function() {
            SpecialtyDocumentService.getApplicationDocument();
        };

        $scope.resumeLater = function() {
            $modal.open({
                templateUrl: "app/components/specialtyApplication/resumeLater/template/template.html",
                controller: "ResumeLaterController",
                resolve: {
                    applicationId: function(){ return $scope.store.applicationId; }
                }
            });
        };

    }

    return ApplicationActionsController;

});
