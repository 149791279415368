define('termsAndConditions/app',['require','termsAndConditions/controller/TermsAndConditionsController','common/service/PublicContentService'],function(require) {
	"use strict";

	var TermsAndConditionsController = require("termsAndConditions/controller/TermsAndConditionsController"),
		PublicContentService = require("common/service/PublicContentService");

	var app = angular.module("mPortal.termsAndConditions", [
		"mPortal.config",
		"mPortal.directive"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {

		$routeProvider.when("/termsAndConditions", {
			templateUrl: "app/termsAndConditions/template/template.html",
			controller: "TermsAndConditionsController",
			guard: function( userProfile ) {
				return true;
			}
		});

	}]);

	app.controller("TermsAndConditionsController", TermsAndConditionsController);
	app.factory("PublicContentService", PublicContentService);

	return app;

});

