define( 'components/editSubmission/directive/editSubmission',['require'],function( require ) {
   "use strict";

    function EditSubmission() {
        return {
            restrict: "AE",
            scope: {
                applicationId: "@",
                status: "@",
                linkText: "@",
                luxonId : "@",
                luxonPartitionKey : "@"
            },
            templateUrl: "app/components/editSubmission/template/template.html",
            controller: "EditSubmissionController"
        }
    }

    return EditSubmission;
});
