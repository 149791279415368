define('components/promotionsSummary/controller/PromotionModalController',['require'],function(require) {
	"use strict";

	PromotionModalController.$inject = ["$scope", "$modalInstance", "body"];

	function PromotionModalController( $scope, $modalInstance, body ) {

		$scope.body = body;
		$scope.close = $modalInstance.close;

	}

	return PromotionModalController;

});
