define('components/accountSummary/insuredInfo/directive/insuredInfo',['require'],function(require) {
	"use strict";

	function InsuredInfo() {
		return {
			restrict: "AE",
			scope: {
				account: "=",
				accountIdList: "="
			},
			templateUrl: "app/components/accountSummary/insuredInfo/template/template.html"
		};
	}

	return InsuredInfo;

});
