define('components/specialtyApplication/questions/config/widgets/verisk/veriskLossCostModalController',['require'],function(require) {
	"use strict";

	VeriskLossCostModalController.$inject = ["$scope", "$modalInstance", "lossCostData" ];

	function VeriskLossCostModalController( $scope, $modalInstance, lossCostData) {
     
        $scope.uiState = {
			visible: false,
			submitting: false
		};
                
        $scope.lossCostData              = lossCostData;
        $scope.close                     = $modalInstance.close;        
        $scope.sectionId                 = lossCostData.sectionId;
        $scope.is_emit                   = lossCostData.is_emit;
        $scope.BuildingRatingDetails     = lossCostData.BuildingRatingDetails;
        $scope.ExperienceLevelAdjustment = lossCostData.ExperienceLevelAdjustment;
        $scope.RiskSummary               = lossCostData.RiskSummary;

       
        var blg_data = lossCostData.LossCosts.filter(function(el) { return el.LineNumber == "10"; });                 
       
        if(blg_data)
        {
            if(blg_data[0].SpecificLossCost)
            {
                if(blg_data[0].SpecificLossCost.BGILossCostSpecific)
                {
                    $scope.bldgLossCost = blg_data[0].SpecificLossCost.BGILossCostSpecific.toString() ;
                }
            }
        }

        $scope.AddLostCostData = function () { 
            var selectedobj = $scope.SelectedObj;
             var add = true ;
            if(selectedobj.Name == "NONE OF THE ABOVE")
            {
                add = false;
            }
            selectedobj.BuildingRatingDetails       = $scope.BuildingRatingDetails;
            selectedobj.ExperienceLevelAdjustment   = $scope.ExperienceLevelAdjustment;
            selectedobj.bldgLossCost                = $scope.bldgLossCost;
            selectedobj.RiskSummary                 = $scope.RiskSummary;
            map_loss_cost_data(selectedobj,add);  
              
            $modalInstance.close();

            $.skynet.modal.set({
                content: "Thank you for your selection",
                show_header	: false,
                show_footer	: false
            });
            $.skynet.modal.show();  
            
            clearInterval($scope.thanks_message);   
            $scope.thanks_message = setInterval(() =>
            {

                $.skynet.modal.hide(); 

                clearInterval($scope.thanks_message);

            }, 4000);
            
        };      

        function map_loss_cost_data(selected_loss_cost_data,add)
        {         
            //result,sectionId,add  
            if($scope.is_emit)
            {
               
                $scope.$emit( 'add_loss_cost_data', {
                    losscostdata: selected_loss_cost_data,
                    sectionId :  $scope.sectionId  , 
                    add:add,
                    mainSectionName: "Building"  
                } );
                $('#mportal-loader').hide();

            }
            else 
            {
                $scope.$root.$broadcast( 'add_loss_cost_data', {
                    losscostdata: selected_loss_cost_data,
                    sectionId :  $scope.sectionId  , 
                    add:add,
                    mainSectionName: "Building"  
                } );
                $('#mportal-loader').hide();

            }
        }  
    }
	return VeriskLossCostModalController;

});
