define('components/startQuoteDropdown/controller/StartQuoteDropdownController',['require'],function(require) {
	"use strict";

	StartQuoteDropdownController.$inject = ["$scope", "UserService", "UserProfile", "EligibilityService"];

	function StartQuoteDropdownController( $scope, UserService, UserProfile, EligibilityService ) {

		$scope.navElements = [];
		$scope.wcBopOnly = false;

		// we can't rely on UserProfile being loaded before this controller initiates because both are happening simultaneously. instead of 
		// referencing UserProfile directly, we'll piggyback on to the promise for the AJAX request that's already in flight and then do
		// our nav permissions once we know we have the profile.
		UserService.getCachedUserProfile().then(function( user ) {
			if( user.hasNavPermissions( "startQuoteWcBop" ) ) {

				EligibilityService.getEligibility({}).then(function( result ) {

					if( result.availableLines.indexOf("WC") !== -1 ) {
						$scope.navElements.push({ label: "Work Comp", href: "#/quote" });
					}

					if( result.availableLines.indexOf("BO") !== -1 ) {
						$scope.navElements.push({ label: "BOP", href: "#/quote" });
					}

                    if ( UserProfile.allowedPrograms.indexOf('miscErrorsOmissions') >= 0 ) {
                        $scope.navElements.push({ label: "Miscellaneous E&O", href: "#/programs/41" });
                    }

					if( user.hasNavPermissions( "startQuoteCommercial" ) ) {
						$scope.navElements.push({ label: "Commercial Package", href: "#/programs" });
					} else {
						$scope.wcBopOnly = true;
					}

				});

			} else if( user.hasNavPermissions( "startQuoteCommercial" ) ) {
				$scope.navElements.push({ label: "Commercial Package", href: "#/programs" });
			}

            else if ( UserProfile.allowedPrograms.indexOf('miscErrorsOmissions') >= 0 ) {
                $scope.navElements.push({ label: "Miscellaneous E&O", href: "#/programs/41" });
            }
		});

	}

	return StartQuoteDropdownController;

});
