define( 'components/specialtyApplication/result/app',['require','common/directive/app','components/editSubmission/app','components/viewApplication/app','components/specialtyApplication/quoteResult/app','components/specialtyApplication/result/directive/result','components/specialtyApplication/result/controller/ResultController','common/service/user/UserProfile','common/filter/formatMoney','common/filter/trustHtml'],function ( require ) {
	"use strict";

	require("common/directive/app");
	require("components/editSubmission/app");
	require("components/viewApplication/app");
	require("components/specialtyApplication/quoteResult/app");

	var result = require( "components/specialtyApplication/result/directive/result" ),
			ResultController = require( "components/specialtyApplication/result/controller/ResultController" ),
			UserProfile = require( "common/service/user/UserProfile" ),
			formatMoney = require( "common/filter/formatMoney" ),
			trustHtml = require( "common/filter/trustHtml" );

	var component = angular.module( "mPortal.components.specialtyApplication.result", [
		"mPortal.directive",
		"mPortal.editSubmission",
		"mPortal.viewApplication",
		"mPortal.components.specialtyApplication.quoteResult"
	] );

	component.filter( "formatMoney", formatMoney );
	component.filter( "trustHtml", trustHtml );
	component.directive( "specialtyApplicationResult", result );
	component.controller( "SpecialtyApplicationResultController", ResultController );
	component.factory( "UserProfile", UserProfile );

	return component;

} );
