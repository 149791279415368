define('insuredPayment/controller/InsuredPaymentController',['require'],function(require) {
	"use strict";

	InsuredPaymentController.$inject = ["$scope","$routeParams", "$window","$route", "BundlePaymentService", "PaymentService", "$sce", "$timeout", "iscRoot", "UserProfile", "UserService","ciamDomain" ];

	function InsuredPaymentController( $scope,$routeParams,$window,$route,BundlePaymentService, PaymentService, $sce, $timeout, iscRoot, UserProfile, UserService,ciamDomain) {
	
		if($route.current.source != 'external')
		{
			$window.location.href = ciamDomain + "#pay-my-bill";
		}

		$scope.uiState = {
			step: $route.current.view,
			error: false,
			message: ""
		};

		$scope.iscTicket = {
			id: 0,
			accountId: "",
			enterpriseId: 0,
			ticketString: ""
		};

		$scope.getPaymentData = function()
		{
			var forwardToBundlePayment = false;
			var paymentUrl = '';
			BundlePaymentService.getPaymentData( null ).then(function( bundleResult ) {
				forwardToBundlePayment = bundleResult.isBundlePaymentAvailable;
				if( bundleResult.hasOwnProperty( 'paymentSystems' ) &&
						Object.keys( bundleResult.paymentSystems ).length === 1 &&
						bundleResult.paymentSystems[ Object.keys(bundleResult.paymentSystems)[0] ].hasOwnProperty( 'paymentUrl' )
				){
					paymentUrl = bundleResult.paymentSystems[ Object.keys(bundleResult.paymentSystems)[0] ].paymentUrl;
				}
			}).finally(function ( ) {
				if( forwardToBundlePayment ){
					$location.path( "/bundle-payment" );
				} else if( paymentUrl ) {
					$window.location.href = paymentUrl;
				} else {
					PaymentService.getAccountPaymentUrl( null ).then( function( result ){
						$scope.uiState.error = false;
						$scope.uiState.message = "";
						if ( result.paymentWebSiteType === "isc" ) {
							$scope.iscTicket.id = result.ticket.id;
							$scope.iscTicket.accountId = result.ticket.accountId;
							$scope.iscTicket.enterpriseId = result.ticket.enterpriseId;
							$scope.iscTicket.ticketString = result.ticket.ticketString;

							// in order to auto-submit the form, we need to wait for angular to process a digest cycle and
							// populate the form. easiest way to accomplish this is by just wrapping it in a timeout
							$timeout( $scope.submitForm, 500 );
						} else if ( result.paymentWebSiteType === "direct-link" ) {
							$window.location.href = result.url;
						} else {
							$scope.uiState.error = true;
							$scope.uiState.message = "The payment system for this account is not currently supported.";
						}
					}).catch(function ( err ) {
						$scope.uiState.error = true;
						$scope.uiState.message = "There was a problem loading the payment system.  Please try again.  If the problem continues, please contact support.";
					});
				}
			});
		};

		$scope.getFormAction = function () {
			return $sce.trustAsResourceUrl( iscRoot + '?event=action.handleLogin' );
		};

		$scope.getReturnUrl = function (){
			return $window.location.origin + $window.location.pathname;
		};

		$scope.submitForm = function() {
			angular.element("#isc-form").submit();
		};

		$scope.verify = function()
		{
			UserService.getUserProfile().then( function( profile ) {
				if( profile.profileToken && $scope.refresh ) {
					$location.path( "/" );
				}

				PaymentService.verifyAccount($routeParams.accountId,$routeParams.zip,'', $routeParams.email ).then(function(result) {
					$scope.getPaymentData();
				}).catch(function( err ) {
					if( err.status === 404 ) {
						$location.path( "/" );
					}
				});
			});
		};

		if($scope.uiState.step == 'payment' && $route.current.view == 'payment')
		{
			if($routeParams.accountId && $routeParams.zip && $routeParams.email)
			{
				$scope.verify();
			}
			else
			{
				$location.path( "/" );
			}
		}
	}

	return InsuredPaymentController;

});
