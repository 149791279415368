define('components/submissionsList/app',['require','common/directive/app','components/submissionsList/directive/submissionsList','components/submissionsList/directive/submissionListItem','components/submissionsList/controller/SubmissionsListController','common/service/search/SearchService'],function(require) {
	"use strict";

	require("common/directive/app");

	var submissionsList = require("components/submissionsList/directive/submissionsList"),
		submissionListItem = require("components/submissionsList/directive/submissionListItem"),
		SubmissionsListController = require("components/submissionsList/controller/SubmissionsListController"),
		SearchService = require("common/service/search/SearchService");

	var component = angular.module("mPortal.components.submissionsList", [
		"mPortal.directive"
	]);

	component.directive("submissionsList", submissionsList);
	component.directive("submissionListItem", submissionListItem);
	component.controller("SubmissionsListController", SubmissionsListController);
	component.factory("SearchService", SearchService);

	return component;

});
