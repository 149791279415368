define('common/service/specialtyApplication/ApplicationSectionService',['require'],function(require) {
	"use strict";

	ApplicationSectionService.$inject = [ "$http", "archApiUrl", "ApplicationSectionBuilder", "QuestionService", "SubmissionStore", "ApplicationSectionConfiguration", "ApplicationStateStore" ];

	function ApplicationSectionService( $http, archApiUrl, ApplicationSectionBuilder, QuestionService, SubmissionStore, ApplicationSectionConfiguration, ApplicationStateStore ) {

		var service = {};

		service.addSections = function( container, sections ) {
			for( var i = 0; i < sections.length; i++ ) {
				container = service.addSection( container, sections[ i ] ).container;
			}

			return container;
		};


		service.prepEnumSection = function( container, rawSection ) {
			if ( rawSection.enumeration && rawSection.type !== "inline" && rawSection.visible ){
				var enumSectionId = service.getEnumerationHeadSectionId( rawSection.sectionId );
				var enumSectionParent = rawSection.parent;
				var enumSection = service.findSection( container, enumSectionId );
				rawSection.parent = enumSectionId;

				// Try to locate enumeration section.
				if( !enumSection ){
					// Enumeration section not found; create it.
					var rawEnumSection = service.buildEnumerationLandingSection( enumSectionId, rawSection, enumSectionParent );

					var parent = service.findSection( container, rawEnumSection.parent );
					parent.addSection( rawEnumSection );
					service.sortSections( container );
				}
			}

			return container;
		};

		service.addSection = function( container, rawSection ) {
			var section;
			var existingSection = service.findSection( container, rawSection.sectionId );

			if( existingSection ) {
				existingSection.replaceSection( rawSection );
				existingSection.datapoints = rawSection.datapoints;
				existingSection.sections = [];
				existingSection.addSections( rawSection.sections );

				return { container: container, section: existingSection };
			}

			if( rawSection.parent ) {
				var parent = service.findSection( container, rawSection.parent );
				section = parent.addSection( rawSection );
			} else {
				section = ApplicationSectionBuilder.buildSection( rawSection );
				container.push( section );
			}

			container = service.sortSections( container );

			return { container: container, section: section }
		};

		service.findSection = function( container, sectionId ) {
			for( var i = 0; i < container.length; i++ ) {
				var found = container[ i ].findSection( sectionId );

				if( found ) {
					return found;
				}
			}
		};

		service.deleteSection = function( container, section ) {
			service.recordSectionDeletion( section );
			if( section.parent ){
				return service.findSection( container, section.parent ).deleteSection( section );
			} else {
				// root section
				var idx = container.indexOf( section );
				if( idx !== -1 ) {
					container.splice( idx, 1 );
				}
			}

		};

		service.recordSectionDeletion = function( section ) {
			// Process nested sections first.
			angular.forEach( section.sections, function( subSection ) {
				service.recordSectionDeletion( subSection );
			});
			angular.forEach( section.datapoints, function( datapoint ) {
				ApplicationStateStore.processDatapoint( datapoint, "delete" );
			})
		};

		service.padSectionId = function ( sectionId ) {
			// E.g., turns '57250.1:57251.10' into '00057250.0001:00057251.0010'
			var paddedSectionIds = [];
			var sectionChunks = sectionId.split( ':' )

			for ( var i = 0; i < sectionChunks.length; i++ ) {
				var sectionPieces = sectionChunks[i].split( '.' );
				var paddedSectionPart = ('00000000' + sectionPieces[0]).slice( -8 ) + '.' + ('0000' + sectionPieces[1]).slice( -4 )
				paddedSectionIds.push( paddedSectionPart );
			}

			return paddedSectionIds.join( ':' );
		};

		service.sortSections = function( container ) {
			return container.sort(function( a, b ) {
				// if orderNumbers are the same, sort by sectionId. it's not as good as orderNumber,
				// but it's the next best thing.
				if( a.orderNumber === b.orderNumber ) {
					return service.padSectionId( a.sectionId ) < service.padSectionId( b.sectionId ) ? -1 : 1;
				}

				return a.orderNumber < b.orderNumber ? -1 : 1;
			});
		};

		service.sortDatapoints = function( datapoints ) {
			return datapoints.sort(function( a, b ) {
				return a.data.orderNumber < b.data.orderNumber ? -1 : 1;
			});
		};

		service.sectionsAreComplete = function (container) {
			for (var i = 0; i < container.length; i++) {
				if (container[i].parentNode && !container[i].isComplete()) {
					return false;
				} 
			}
			return true;
		};

		service.additionalSectionsAreComplete = function( container ) {
			for( var i = 0; i < container.length; i++ ) {
				if( !container[ i ].isComplete() ) {
					return false;
				}
			}

			return true;
		};

		service.sectionsAreVisited = function( container ) {
			for ( var i = 0; i < container.length; i++ ) {
				if ( container[i].type !== 'enumerationLandingSection' && !container[i].isVisited() ) {
					return false;
				}
				else if ( container[i].parentNode ) {
					if ( container[i].parentNode.type === 'enumerationLandingSection' ) {
						return true;
					}
				}
			}
			return true;
		};


		service.datapointsAreValid = function( section ) {
			var dataPointsValid = true;
			for( var i = 0; i < section.datapoints.length; i++ ) {
				if( !section.datapoints[ i ].data.isValid ){
					return false;
				}
			}

			return true;
		};

		service.graphSections = function ( sections , controllerID ) {
			if(!controllerID)controllerID = 'questions';

			var flattenedSections = service.flattenSections( sections );

			if(controllerID == 'section')
			{

				for(var i = flattenedSections.length -1; i >= 0 ; i--)
				{
					var sec = flattenedSections[i];


					if(sec.depth > 1)
					{
						flattenedSections.splice(i,1);
					}
				}
			}

			

			return flattenedSections.reduce(function( graph, section, idx ) {
				
				if( section.type === "inline" ) {
					return graph;
				}
			

				var graphedSection = graph[ section.sectionId ] = {};
				var prevIdx = idx - 1;
				var nextIdx = idx + 1;

				graphedSection.previous = flattenedSections[ prevIdx ];
				graphedSection.next = flattenedSections[ nextIdx ];

				while( graphedSection.previous && ( graphedSection.previous.type === "inline" || !graphedSection.previous.visible ) ) {
					graphedSection.previous = flattenedSections[ --prevIdx ];
				}

				while( graphedSection.next && ( graphedSection.next.type === "inline" || !graphedSection.next.visible ) ) {
					graphedSection.next = flattenedSections[ ++nextIdx ];
				}

				return graph;
			}, {});
		};

		service.flattenSections = function ( sections ) {
			return sections.reduce(function( flattened, section ) {
				flattened = flattened.concat( section );

				if( section.sections && section.sections.length ) {
					flattened = flattened.concat( service.flattenSections( section.sections ) );
				}

				return flattened;
			}, []);
		};

		service.setUpEnumerationLandingPages = function( sections ) {
			var sectionMap = service.convertSectionsToFlatMap( sections );

			angular.forEach( sectionMap, function( section ) {
				if( !section.enumeration
						|| ( section.enumeration && section.type === "inline" )
				) {
					return;
				}

				var sectionId = section.sectionId,
					head = service.getEnumerationHeadSectionId( sectionId );

				if( !sectionMap[ head ] ) {
					sectionMap[ head ] = service.buildEnumerationLandingSection( head, section );
				}

				section.parent = head;
			});

			return service.reTreeSections( sectionMap );
		};

		service.convertSectionsToFlatMap = function( sections ) {
			return service.flattenSections( sections ).reduce(function( map, section ) {
				section.sections = [];
				map[ section.sectionId ] = section;

				return map;
			}, {});
		};

		service.reTreeSections = function( sections ) {
			var tree = [];

			angular.forEach( sections, function( section ) {
				if( section.parent && sections[ section.parent ] ) {
					sections[ section.parent ].sections.push( section );
				} else {
					tree.push( section );
				}
			});

			return tree;
		};

		service.sectionsAreEqual = function( section1, section2 ) {
			return ( section1 && section1.sectionId ) === ( section2 && section2.sectionId );
		};

		service.updateDatapoints = function( datapoints, sections, scope, currentSectionId ) {
			angular.forEach( datapoints, function( datapoint ) {
				var section = service.findSection( sections, datapoint.data.isEarlyDisplay ? currentSectionId : datapoint.data.sectionId );

				if( !section ) {
					return;
				}


				switch( datapoint.data.change ) {
					case "added":
						section.addDatapoint( datapoint );
						break;
					case "changed":
						section.replaceDatapoint( datapoint );
						break;
					case "deleted":
						ApplicationStateStore.processDatapoint( datapoint, "delete" );
						section.deleteDatapoint( datapoint );
						break;
				}

				if(scope)service.updateFieldValidation( scope, datapoint );
			});
		};

		service.updateSections = function( changedSections, sections, expandedNodes, selectedNode , controllerID ) {
			angular.forEach( changedSections, function( rawSection ) {
				var section;
				if(controllerID == null)
				{
					controllerID = 'questions';
				}

				switch( rawSection.change ) {
					case "added":
						//Only add visible sections
						if( rawSection.visible ) {
							if(controllerID == 'questions')service.prepEnumSection( sections, rawSection );
							section = service.addSection( sections, rawSection ).section;
							service.expandSectionsUpTree( section, expandedNodes );
							service.flagSectionUnvisited( sections, selectedNode.sectionId, rawSection.sectionId );
						}
					break;
					case "changed":
						section = service.findSection( sections, rawSection.sectionId );
						
						if( section ){
							section.replaceSection( rawSection );
						} else if( rawSection.enumeration && rawSection.visible ) {
							if(controllerID == 'questions')service.prepEnumSection( sections, rawSection );
							section = service.addSection( sections, rawSection ).section;
							service.expandSectionsUpTree( section, expandedNodes );
						} else if( rawSection.type == 'inline' && controllerID == 'section'){
							//weird bug for an enumerated section w/an inline section
							section = service.addSection( sections, rawSection ).section;
							service.expandSectionsUpTree( section, expandedNodes );
						}
					break;
					case "deleted":
						section = service.findSection( sections, rawSection.sectionId );
						if( section ) {
							service.deleteSection( sections, section );
						}

					break;
				}
			});
		};

		service.updateFieldValidation = function( scope, datapoint ){
			// Fetch field corresponding to datapoint
			var thisField;

			for( var i = 0; i < scope.fields.length; i++ ) {
				if( scope.fields[ i ].key === datapoint.key ) {
					thisField = scope.fields[ i ];
					break;
				}
			}

			// Update field's validation
			if( thisField ){
				var input = scope.form[ thisField.name ];

				if( input ) {
					input.$setValidity( "error", datapoint.data.isValid );
				}

				if( thisField.validation ) {
					thisField.validation.messages.error = thisField.data.validation ? thisField.data.validation : '';
					thisField.validation.show = thisField.data.isValid ? null : true;
				}
			}
		};

		service.injectEventHandlers = function ( sections, fields, changeHandler, context ) {
			var composedChangeHandler = changeHandler( sections );

			if ( angular.isObject( fields ) && fields.templateOptions ) {

				var event = ["TextBox", "TextArea", "PhoneInput" ].indexOf( fields.type ) !== -1 ? "onBlur" : "onChange";

				if ( fields.type === "Typeahead" ) {
					event = "typeaheadSelect"
				}

				if ( fields.type === "Button" ) {
					event = "clickButton"
				}

				fields.templateOptions[event] = composedChangeHandler.bind( context );


				if ( fields.templateOptions.fields ) {
					service.recurseInjectEventHandlers( sections, fields.templateOptions.fields, changeHandler, context );
				}
			} else if ( angular.isArray( fields ) || ( angular.isObject( fields ) && !fields.templateOptions ) ) {
				service.recurseInjectEventHandlers( sections, fields, changeHandler, context );
			}
		};

		service.recurseInjectEventHandlers = function ( sections, fields, changeHandler, context ) {
			angular.forEach( fields, function ( field ) {
				service.injectEventHandlers( sections, field, changeHandler, context );
			});
		};

		service.buildEnumerationLandingSection = function( sectionId, enumeration, parentSectionId ) {
			return {
				sectionId: sectionId,
				title: enumeration.enumeration,
				type: "enumerationLandingSection",
				parent: parentSectionId || enumeration.parent,
				orderNumber: enumeration.orderNumber,
				sections: [],
				visible: true
			};
		};

		service.getEnumerationHeadSectionId = function( sectionId ) {
			return sectionId.replace(/\.\d+$/, ".HEAD");
		};

		service.expandSectionsUpTree = function( fromNode, expandedNodes ) {
			// if someone is navigating to a node that is nested (e.g. by hitting the previous button) and the
			// parent has not been hit yet, iterate up the tree to expand all nodes above that node.
			var parent = fromNode.parentNode;

			while( parent ) {
				if( expandedNodes.indexOf( parent ) === -1 ) {
					expandedNodes.push( parent );
				}

				parent = parent.parentNode;
			}
		};

		service.flagSectionsInvalid = function( container, expandedNodes, invalidSectionIds ) {
			var section;
			for( var i = 0; i < invalidSectionIds.length; i++ ) {
				section = service.findSection( container, invalidSectionIds[ i ] );
				if( section ){
					section.visited = true;
					service.expandSectionsUpTree( section, expandedNodes );
				}
			}
		};

		service.flagSectionUnvisited = function( container, currentlySelectedSectionId, sectionToFlagUnvisitedSectionId ) {
			// Flag a section or its first non-inline ancestor section unvisited.
			// We make sure to not 'unvisit' the section the user is currently on.
			var section = service.findSection( container, sectionToFlagUnvisitedSectionId );

			if( section ){
				if( section.type === "inline" ){
					var parentSection = section.parentNode;

					while( parentSection ){
						if( parentSection.type !== "inline" ){
							if( currentlySelectedSectionId !== parentSection.sectionId ){
								parentSection.visited = false;
							}

							// Stop since we've found the first non-inline ancestor.
							// Do not continue to go up the section tree.
							break;
						}
						parentSection = parentSection.parentNode;
					}
				} else {
					if( currentlySelectedSectionId !== section.sectionId ){
						section.visited = false;
					}
				}
			}
		};

		service.getAllDatapoints = function( section, mainSectionIdentifier ) {
			// Outermost call to getAllDatapoints will not include mainSection value, so it gets set to the outermost section
			var sectionIdentifier = section.widgetReference;
			var mainSectionId = mainSectionIdentifier ? mainSectionIdentifier : sectionIdentifier;
			var inlineSectionId = sectionIdentifier == mainSectionId ? null : sectionIdentifier;
			var datapointsAndInlineSections = Array.prototype.concat.apply( section.datapoints, service.getInlineSections( section.sections ) ).sortBy(function( obj ) {
				// if it's a section, it will have a top level orderNumber property. otherwise it's a datapoint and it will be on the data obj.
				return obj.orderNumber || obj.data.orderNumber;
			});

			var datapointCollection = service.flattenInlineDatapoints( datapointsAndInlineSections, mainSectionId, inlineSectionId, "edit" );

			if( inlineSectionId ) {
				return datapointCollection
			} else {
				return service.processDatapoints( section, datapointCollection );
			}
		};

		service.getAllViewDatapoints = function( section, mainSectionIdentifier ) {
			// Outermost call to getAllDatapoints will not include mainSection value, so it gets set to the outermost section
			// Enumerated sections should be identified by their enumeration value rather than their title
			var sectionIdentifier = section.widgetReference;
			var mainSectionId = mainSectionIdentifier ? mainSectionIdentifier : sectionIdentifier;
			var inlineSectionId = sectionIdentifier == mainSectionId ? null : sectionIdentifier;
			var datapointsAndInlineSections = Array.prototype.concat.apply( section.datapoints, service.getInlineSections( section.sections ) ).sortBy(function( obj ) {
				// if it's a section, it will have a top level orderNumber property. otherwise it's a datapoint and it will be on the data obj.
				return obj.orderNumber || obj.data.orderNumber;
			});

			var datapointCollection = service.flattenInlineDatapoints( datapointsAndInlineSections, mainSectionId, inlineSectionId, "view" );

			return datapointCollection
		};

		service.processViewDatapoints = function( program, datapoints ) {
			var sectionMapPoints = {};
			var unconfiguredPoints = [];

			/*
			 There are certain scenarios where we want the configured datapoints to be segregated from the unconfigured
			 datapoints and appear at either the top or bottom of the section rather than by strict order
			 */
			var configuredDatapointPlacement = "top";
			/*
			 In scenarios where the configured and unconfigured datapoints should appear in the order in which they appear
			 in the response (which is NOT necessarily by orderNumber value, as the orderNumbers for datapoints in different
			 inline sections may conflict/intermix with each other), we need to track the order as we iterate over them
			 and apply sorting based on that order once the datapoints are combined.
			 */
			var responseOrderMap = {};
			var orderNumber = 0;

			service.modifyDatapointsForView( datapoints );

			angular.forEach( datapoints, function( datapoint ) {
				responseOrderMap[ datapoint.key ] = orderNumber++;
				var pointWrappers = [];
				var pointConfig;

				/*
				 For view processing of datapoints, the "ApplicationSectionConfiguration" dependency is injected with an instance
				 of the ApplicationSectionViewConfiguration.js file instead of the submission-based ApplicationSectionConfiguration.js file.
				 Which means the widget configuration collections are separate
				*/
				if( ApplicationSectionConfiguration && ApplicationSectionConfiguration[ datapoint.data.mainSectionId ] ) {
					// Check if the widget configuration calls for unconfigured datapoints to appear above configured datapoints
					configuredDatapointPlacement = ApplicationSectionConfiguration[ datapoint.data.mainSectionId ][ "_datapointPlacement" ] ? ApplicationSectionConfiguration[ datapoint.data.mainSectionId][ "_datapointPlacement" ] : "top";
					pointConfig = service.getCustomDatapointConfiguration( datapoint, ApplicationSectionConfiguration[ datapoint.data.mainSectionId ] );
				}

				if( pointConfig ) {
					// Copy any configuration metadata to the datapoint
					angular.extend( datapoint.data, pointConfig.data );
					if( pointConfig.fieldGroups ) {
						service.placeNestedDatapoint( datapoint, pointConfig, sectionMapPoints, "view" )
					} else {
						sectionMapPoints[ service.getWidgetMappingKeyValue( sectionMapPoints, datapoint, false ) ] = service.wrapViewConfiguredDatapoint( datapoint, pointConfig );
					}
					// Will trigger any "view" actions defined in the widget configuration of the datapoint
					ApplicationStateStore.processDatapoint( datapoint, "view" );
				} else {
					unconfiguredPoints.push( service.wrapViewUnconfiguredDatapoint( datapoint ) );
				}

			});

			// Create initial array from section
			var finalArray = service.translateSectionMapPoints( sectionMapPoints );

			switch( configuredDatapointPlacement ) {
				case "bottom":
					unconfiguredPoints.reverse();
					angular.forEach( unconfiguredPoints, function( dPoint ) {
						finalArray.unshift( dPoint );
					});
					break;
				case "strictOrder":
					angular.forEach( unconfiguredPoints, function( dPoint ) {
						finalArray.push( dPoint );
					});
					finalArray.sort(function( a, b ) {
						return responseOrderMap[ a.key ] - responseOrderMap[ b.key ];
					});
					break;
				case "top":
				default:
					angular.forEach( unconfiguredPoints, function( dPoint ) {
						finalArray.push( dPoint );
					});
					break;
			}

			return finalArray;
		};

		service.wrapViewConfiguredDatapoint = function( datapoint, pointConfig ) {
			// The type of form element can be overridden
			datapoint.type = pointConfig.type ? pointConfig.type : datapoint.type;

			// ...as can the orderNumber
			datapoint.data.orderNumber = pointConfig.orderNumber ? pointConfig.orderNumber : datapoint.data.orderNumber;

			// ...and of course wrappers can be applied
			datapoint.wrapper = pointConfig.wrapper;

			return datapoint;
		};

		service.wrapViewUnconfiguredDatapoint = function( datapoint  ) {
			// Unconfigured datapoints marked as empty should be excluded from the DOM
			datapoint.type = datapoint.data.empty ? "exclude" : datapoint.type;

			return datapoint;
		};

		service.modifyDatapointsForView = function( datapoints ) {
			angular.forEach( datapoints, function( datapoint ) {
				// Preserve the original datapoint type (label, radio, checkbox, etc) in case that is needed as context later
				datapoint.data.nonViewType = datapoint.type;
				/*
				 Currently, logic exists in Arch that changes a "bullet label" type to "Label), which
				 gets a different style from "label".  So need to accommodate that
				*/
				switch( datapoint.type ) {
					case "label":
						datapoint.type = "viewLabel";
						break;
					case "Label":
						datapoint.type = "viewLabelWithBullet";
						break;
					case "CheckBox":
						// Checkboxes only show if true and only display the label
						datapoint.type = "viewCheckbox";
						break;
					default:
						datapoint.type = "viewData";

						// Autocomplete datapoints have a string representing a JSON object as the default value
						if( datapoint.defaultValue ) {
							datapoint.defaultValue = typeof(datapoint.defaultValue) == "object" ? ( datapoint.defaultValue.name ? datapoint.defaultValue.name : "") : datapoint.defaultValue;
						}

						// Mark datapoints that have no label or value
						if( !datapoint.defaultValue && !datapoint.templateOptions.label ) {
							datapoint.data.empty = true;
						} else {
							// See if the datapoint label does not end with any punctuation.
							if( !/\?$/.test( datapoint.templateOptions.label ) && !/\:$/.test( datapoint.templateOptions.label ) && !/\.$/.test( datapoint.templateOptions.label ) ) {
								// Some datapoints have no label.
								datapoint.templateOptions.label = datapoint.templateOptions.label != "" ? datapoint.templateOptions.label + ":" : "";
							}
						}
						break;
				}
			});
		};

		service.processDatapoints = function( section, datapoints ) {
			var sectionMapPoints = {};
			var unconfiguredPoints = [];
			/*
			 There are certain scenarios where we want the configured datapoints to be segregated from the unconfigured
			 datapoints and appear at either the top or bottom of the section rather than by strict order
			*/
			var configuredDatapointPlacement = "top";
			/*
			 In scenarios where the configured and unconfigured datapoints should appear in the order in which they appear
			 in the response (which is NOT necessarily by orderNumber value, as the orderNumbers for datapoints in different
			 inline sections may conflict/intermix with each other), we need to track the order as we iterate over them
			 and apply sorting based on that order once the datapoints are combined.
			*/
			var responseOrderMap = {};
			var orderNumber = 0;

			angular.forEach( datapoints, function( datapoint ) {
				responseOrderMap[ datapoint.key ] = orderNumber++;
				var pointWrappers = [];
				var pointConfig;

				// Look to see if datapoint main section is configured in ApplicationSectionConfiguration, and if so
				// look for a configuration for the datapoint
				if( ApplicationSectionConfiguration[ datapoint.data.mainSectionId ] ) {
					// Check if the widget configuration calls for unconfigured datapoints to appear above configured datapoints
					configuredDatapointPlacement = ApplicationSectionConfiguration[ datapoint.data.mainSectionId ][ "_datapointPlacement" ] ? ApplicationSectionConfiguration[ datapoint.data.mainSectionId][ "_datapointPlacement" ] : "top";
					pointConfig = service.getCustomDatapointConfiguration( datapoint, ApplicationSectionConfiguration[ datapoint.data.mainSectionId ] );
				}

				if( pointConfig ) {
					// Copy any configuration metadata to the datapoint
					angular.extend( datapoint.data, pointConfig.data );
					if( pointConfig.fieldGroups ) {
						service.placeNestedDatapoint( datapoint, pointConfig, sectionMapPoints, "submission" )
					} else {
						sectionMapPoints[ service.getWidgetMappingKeyValue( sectionMapPoints, datapoint, false ) ] = service.wrapConfiguredDatapoint( datapoint, pointConfig );
					}
				} else {
					unconfiguredPoints.push( service.wrapUnconfiguredDatapoint( datapoint, pointWrappers ) );
				}

				ApplicationStateStore.processDatapoint( datapoint, "update" );
			});

			// Create initial array from section
			var finalArray = service.translateSectionMapPoints( sectionMapPoints );

			switch( configuredDatapointPlacement ) {
				case "bottom":
					unconfiguredPoints.reverse();
					angular.forEach( unconfiguredPoints, function( dPoint ) {
						finalArray.unshift( dPoint );
					});
					break;
				case "strictOrder":
					angular.forEach( unconfiguredPoints, function( dPoint ) {
						finalArray.push( dPoint );
					});
					finalArray.sort(function( a, b ) {
						return responseOrderMap[ a.key ] - responseOrderMap[ b.key ];
					});
					break;
				case "top":
				default:
					angular.forEach( unconfiguredPoints, function( dPoint ) {
						finalArray.push( dPoint );
					});
					break;
			}

			return finalArray;
		};

		service.translateSectionMapPoints = function( branch ) {
			var fieldArray = [];
			angular.forEach( branch, function( value, key ) {
				if( value.fieldGroup ) {
					fieldArray.push( {
						wrapper: value.wrapper,
						data: value.data,
						fieldGroup: service.translateSectionMapPoints( value.fieldGroup )
					})
				} else {
					fieldArray.push( value )
				}
			});
			return fieldArray.sortBy( function( arrayItem ) { return arrayItem.data.orderNumber } );
		};

		service.placeNestedDatapoint = function( datapoint, pointConfig, sectionMap, context ) {

			var datapointTarget = sectionMap;
			if( pointConfig.fieldGroups.length > 0 ) {
				angular.forEach( pointConfig.fieldGroups, function( group ) {

					// Account for the fact that section itself has no fieldGroup
					if( datapointTarget.fieldGroup ) {
						if( !datapointTarget.fieldGroup[ group.data.name ] ) {
							datapointTarget.fieldGroup[ group.data.name ] = group;
						}
						datapointTarget = datapointTarget.fieldGroup[ group.data.name ];
					} else {
						if( !datapointTarget[ group.data.name ] ) {
							datapointTarget[ group.data.name ] = group;
						}
						datapointTarget = datapointTarget[ group.data.name ];
					}
				});
				if( context === "submission" ) {
					datapointTarget.fieldGroup[ service.getWidgetMappingKeyValue( datapointTarget, datapoint, true ) ] = service.wrapConfiguredDatapoint( datapoint, pointConfig );
				} else {
					datapointTarget.fieldGroup[ service.getWidgetMappingKeyValue( datapointTarget, datapoint, true ) ] = service.wrapViewConfiguredDatapoint( datapoint, pointConfig );
				}

			} else {
				if( context === "submission" ) {
					datapointTarget[ service.getWidgetMappingKeyValue( datapointTarget, datapoint, false ) ] = service.wrapConfiguredDatapoint(datapoint, pointConfig);
				} else {
					datapointTarget[ service.getWidgetMappingKeyValue( datapointTarget, datapoint, false ) ] = service.wrapViewConfiguredDatapoint(datapoint, pointConfig);
				}
			}

		};

		service.getWidgetMappingKeyValue = function( sectionMap, datapoint, isFieldGroup ) {
			var mapKey = datapoint.data.widgetReference;
			var targetedProperty = isFieldGroup ? sectionMap.fieldGroup[ mapKey ] : sectionMap[ mapKey ];
			if( targetedProperty ) {
				var pointSectionArray = datapoint.data.sectionId.split(".");
				var pointEnum = pointSectionArray[ ( pointSectionArray.length - 1 )];
				mapKey = datapoint.data.widgetReference + "." + pointEnum;

			}
			return mapKey;
		};

		service.wrapConfiguredDatapoint = function( datapoint, pointConfig ) {
			// The type of form element can be overridden
			datapoint.type = pointConfig.type ? pointConfig.type : datapoint.type;

			// ...as can the orderNumber
			datapoint.data.orderNumber = pointConfig.orderNumber ? pointConfig.orderNumber : datapoint.data.orderNumber;

			// If wrapperOverride is true, only use the wrappers defined in the datapoint
			// configuration (do not apply standard conditionally-triggered wrappers like tooltips)
			if( pointConfig.wrapperOverride ) {
				datapoint.wrapper = pointConfig.wrapper

			} else {
				var pointWrappers = [];

				if( datapoint.templateOptions.help ) {
					if (datapoint.type == "Radio" || datapoint.type == "CheckBox") {
						pointWrappers.push("bootstrapTooltipIcon");
					} else {
						pointWrappers.push("bootstrapTooltipInput");
					}
				}

				// Every datapoint representing a form input should have a validation error wrapper
				var hasErrorWrap = false;
				angular.forEach( pointConfig.wrapper, function( wrap ) {
					if( /Error$/.test( wrap ) ) {
						hasErrorWrap = true;
					}
					pointWrappers.push( wrap );
				});

				if( !hasErrorWrap ) {
					// Default to standard
					pointWrappers.push( "defaultBootstrapHasError" );
				}

				datapoint.wrapper = pointWrappers;
			}

			return datapoint;
		};

		service.wrapUnconfiguredDatapoint = function( datapoint, pointWrappers ) {
			// Standard label datapoints do not have a wrapper
			if( datapoint.type == "Label" || datapoint.type == "label" ) {
				datapoint.wrapper = null;
			} else {
				if( datapoint.templateOptions.help ) {
					if( datapoint.type == "Radio" || datapoint.type == "CheckBox" ) {
						pointWrappers.push( "bootstrapTooltipIcon" );
					} else {
						pointWrappers.push( "bootstrapTooltipInput" );
					}
				}
				if( datapoint.type == "CheckBox" ) {
					pointWrappers.push( "checkboxWrapper" );
				} else {
					pointWrappers.push( "defaultBootstrapLabel" );
					pointWrappers.push( "defaultBootstrapHasError" );
				}
				datapoint.wrapper = pointWrappers;
			}
			return datapoint;
		};

		/*
		While a widget configuration maps out how certain datapoints are organized and styled, the existence of certain
		datapoints in a given section can change (the way a datapoint is answered can cause other datapoints to be added
		or removed from the section).  So the actual structure of the widget shown in the UI is defined by the datapoints
		that are present.  This function attaches location information (where in the widget field grouping structure the
		datapoint should be located) on the datapoint itself, and this is used later to build the groupings used in the UI
		*/
		service.getCustomDatapointConfiguration = function( datapoint, configurationMap, incomingGroups ) {

			var currentGroups = incomingGroups ? incomingGroups : [];
			var pointConfig = configurationMap[ datapoint.data.widgetReference ];
			if( pointConfig ) {
				pointConfig.fieldGroups = currentGroups;
				return pointConfig;
			}

			var groups;

			for( var key in configurationMap ) {
				groups = [];
				angular.copy( currentGroups, groups );
				if( configurationMap[ key ].fieldGroup ) {
					groups.push( {
						wrapper: configurationMap[ key ].wrapper,
						fieldGroup: {},
						data: configurationMap[ key ].data
							? angular.extend( configurationMap[ key ].data, { name: key, orderNumber: configurationMap[ key ].orderNumber  } )
							: { name: key, orderNumber: configurationMap[ key ].orderNumber  } }
					);
					pointConfig = service.getCustomDatapointConfiguration( datapoint, configurationMap[ key ].fieldGroup, groups );
					if( pointConfig ) {
						break;
					}
				}
			}

			return pointConfig;

		};

		service.getInlineSections = function( sections ) {
			return sections.filter(function( section ) {
				return section.type === "inline";
			});
		};

		service.flattenInlineDatapoints = function( datapointsAndSections, mainSectionId, inlineSectionId, context ) {
			return datapointsAndSections.reduce(function( flattened, obj ) {
				// if we have a datapoint, just push it on to the array
				if( obj.templateOptions ) {
					var sectionIdArray = obj.data.sectionId.split(".");
					obj.data.mainSectionId = mainSectionId;
					obj.data.inlineSectionId = inlineSectionId;
					obj.data.enumeration = parseInt(sectionIdArray[ ( sectionIdArray.length - 1 ) ]);
					flattened.push( obj );
					return flattened;
				}

				// if we have sections on our section, start from the beginning and grab all the datapoints + inline sections
				if( obj.sections && obj.sections.length ) {
					if( context == 'view') {
						flattened = flattened.concat( service.getAllViewDatapoints( obj, mainSectionId ) );
					} else {
						flattened = flattened.concat( service.getAllDatapoints( obj, mainSectionId ) );
					}
					return flattened;
				}

				// finally, if it's just a plain section with only datapoints, shove them onto the array
				if( obj.datapoints && obj.datapoints.length ) {
					var assignedDatapoints = [];
					angular.forEach( obj.datapoints, function( dPoint ) {
						var dPointSectionIdArray = dPoint.data.sectionId.split(".");
						dPoint.data.mainSectionId = mainSectionId;
						dPoint.data.inlineSectionId = obj.widgetReference;
						dPoint.data.enumeration = parseInt(dPointSectionIdArray[ ( dPointSectionIdArray.length - 1 ) ]);
						assignedDatapoints.push( dPoint );
					});

					flattened = Array.prototype.concat( flattened, assignedDatapoints.sortBy(function( datapoint ) {
						return datapoint.data.orderNumber;
					}));
				}

				return flattened;
			}, []);
		};

		service.removeLastEnumeration = function( section ) {
			service.recordSectionDeletion( section.sections[ section.sections.length - 1 ] );
			section.sections.splice( section.sections.length - 1, 1 );

			return section;
		};

		service.revisitSections = function( newSections, oldSections ) {
			newSections.forEach(function( section ) {
				var oldSection = service.findSection( oldSections, section.sectionId );

				if( oldSection ) {
					section.visited = oldSection.visited;
				}

				if( section.sections ) {
					service.revisitSections( section.sections, oldSections );
				}
			});
		};

		/*
		 Creates a "shell" section to serve as a container for a custom component that handles data outside of an
		 appmaster, while still having the shape of a regular section necessary to be part of the appmaster section
		 collection.  Shell sections are added to the end of the section collection.
		 */
		service.createShellSection = function( sectionId, title, lastSectionOrderNumber ) {
			// Set the orderNumber high enough to make it the last section
			var orderNumber = lastSectionOrderNumber ? lastSectionOrderNumber + 1 : 10000;
			var shellSectionData = {
				sectionId: sectionId,
				name: title,
				title: title,
				visible: true,
				orderNumber: orderNumber,
				widgetReference: "",
				type: "tree",
				// A single "datapoint" is needed to set the validity the "section"
				datapoints: [
					{ data: { isValid: false }}
				],
				sections: [],
				shellSection: true,
				/*
				 Helper methods that only exist on shell sections to control section validity.  Meant to be invoked
				 from within the custom component inside the shell, so this shell section will need to be passed into
				 the custom component.
				 */
				markShellValid: function() { this.datapoints[0].data.isValid = true },
				markShellInvalid: function() { this.datapoints[0].data.isValid = false }
			};
			return service.addSection( [], shellSectionData ).section;
		};

		service.updateAgentDetails = function ( agencyId, licenseId, effectiveDate, applicationId, instance, programCode ) {
			return $http.put( archApiUrl + "application/quote", {
				serviceAgentId : agencyId,
				licensedAgentId : licenseId,
				effectiveDate : effectiveDate,
				applicationId : applicationId,
				instance : instance,
                programCode : programCode
			});
		};

		return service;
	}

	return ApplicationSectionService;

});
