define('common/directive/datePicker',['require'],function(require) {
	"use strict";

	function DatePicker() {
		return {
			restrict: "A",
			require: "ngModel",
			scope: {
				minDate: "@datePickerMinDate",
				maxDate: "@datePickerMaxDate",
				changeMonthYear: "@datePickerChangeMonthYear",
				yearRange: "@yearRange",
				defaultDate: "@datePickerDefaultDate",
				onSelect: "=datePickerOnSelect"
			},
			link: function( scope, element, attrs, ngModelCtrl ) {

				var options = {
					dateFormat: "mm/dd/yy",
					changeMonth: scope.changeMonthYear ? scope.changeMonthYear : true,
					changeYear: scope.changeMonthYear ? scope.changeMonthYear : true,
					yearRange: scope.yearRange,
					minDate: scope.minDate,
					maxDate: scope.maxDate,
					defaultDate: scope.defaultDate,
					onSelect: function( date ) {
						scope.$apply(function() {
							ngModelCtrl.$setViewValue( date );

							if( typeof scope.onSelect === "function" ) {
								scope.onSelect( date );
							}
						});
					}
				};

				element.datepicker( options );
				// If a calendar icon also exists in the UI, add a click handler to 
				// show the pop-up calendar. The icon element should have an id '<datePicker_id>_icon'
	            var iconElement = element.parent().find('#' + element.prop('id') + '_icon');
	            if (iconElement.length) {
	                iconElement.on('click', function () {
	                    element.trigger('focus');
	                });
	            }
				

			}
		};
	}

	return DatePicker;

});
