define('components/marketedPrograms/detail/controller/MarketedProgramDetailController',[],function() {

	MarketedProgramDetailController.$inject = ["$scope", "$modal"];

	function MarketedProgramDetailController( $scope, $modal ) {

		$scope.getApplicationUrl = function() {
			var url = $scope.program.onlineSubmissionAvailable ? "#/application/agent/" + $scope.program.urlSlug : $scope.program.offlineApplicationUrl;
			if ($scope.program.defaultBusinessTypeId) {
				url = "#/application/search/" + $scope.program.defaultBusinessTypeId;
			}

            if ($scope.program.businessTypeTag) {
				url = "#/application/search/tag/" + $scope.program.businessTypeTag;
			}

			return url;
		};

		$scope.getTarget = function() {
			if( $scope.program.onlineSubmissionAvailable ) {
				return "";
			}

			if( $scope.program.offlineApplicationUrl && /^https?/.test( $scope.program.offlineApplicationUrl ) ) {
				return "_blank";
			}

			return "";
		};

		$scope.openUploadModal = function() {
			$modal.open({
				templateUrl: "app/components/marketedPrograms/detail/template/fileUploadModal.html",
				controller: "FileUploadModalController",
				resolve: {
					program: function() { return $scope.program; }
				}
			});
		};

	}

	return MarketedProgramDetailController;

});
