define( 'components/documentDeliveryPreferences/app',['require','components/documentDeliveryPreferences/directive/documentDeliveryPreferences','components/documentDeliveryPreferences/controller/DocumentDeliveryPreferencesController','components/documentDeliveryPreferences/controller/ElectronicDeliveryTermsModalController','common/service/user/UserProfile','common/service/specialtyApplication/ResultService'],function( require ) {
   "use strict";

    var documentDeliveryPreferences = require( "components/documentDeliveryPreferences/directive/documentDeliveryPreferences" ),
        DocumentDeliveryPreferencesController = require( "components/documentDeliveryPreferences/controller/DocumentDeliveryPreferencesController" ),
        ElectronicDeliveryTermsModalController = require( "components/documentDeliveryPreferences/controller/ElectronicDeliveryTermsModalController" ),
        UserProfile = require( "common/service/user/UserProfile" ),
        ResultService = require( "common/service/specialtyApplication/ResultService" );

    var component = angular.module( "mPortal.components.documentDeliveryPreferences", [ ] );

    component.directive( "documentDeliveryPreferences", documentDeliveryPreferences );
    component.controller( "DocumentDeliveryPreferencesController", DocumentDeliveryPreferencesController );
    component.controller( "ElectronicDeliveryTermsModalController", ElectronicDeliveryTermsModalController );
    component.factory( "UserProfile", UserProfile );
    component.factory( "ResultService", ResultService );

    return component;

});


