define('components/specialtyApplication/section/detail/directive/sectionDetail',['require'],function(require) {
	"use strict";

	SectionDetail.$inject = [];

	function SectionDetail() {
		return {
			restrict: "AE",
			scope: {
				section : "=",
				model	: "=",
				layout  : "="
			},
			templateUrl: "app/components/specialtyApplication/section/detail/template/template.html",
			controller: "SectionDetailController"
		};
	}

	return SectionDetail;

});
