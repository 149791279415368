define('components/quickSearch/directive/quickSearch',['require'],function(require) {
	"use strict";

	function QuickSearch() {
		return {
			restrict: "AE",
			scope: {
				searchText : "@"
			},
			templateUrl: "app/components/quickSearch/template/template.html",
			controller: "QuickSearchController"
		};
	}

	return QuickSearch;

});
