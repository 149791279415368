define( 'components/specialtyApplication/account/controller/InsuredAccountController',[],function() {
   InsuredAccountController.$inject = [ "$scope", "$q", "$timeout", "$filter", "$location", "$modal", "SubmissionStore", "AccountService", "SpecialtyEligibilityService", "ProductSearchService", "MessageContainer", "UserProfile", "Wizard", "$routeParams", "moment" ];

   
    function InsuredAccountController( $scope, $q, $timeout, $filter, $location, $modal, SubmissionStore, AccountService, SpecialtyEligibilityService, ProductSearchService, MessageContainer, UserProfile , Wizard, $routeParams, moment ) {

        // Set initial data values:
        $scope.initialValues = {
            deliveryPreference: "EM"
        };

        $scope.init_data = function(){
            $scope.form_data = {
                default  : {
                    cities      : [],
                    states      : []
                }
            };

            $scope.form.maxscore = 0;
        };
        // isFeinRequired is a form value because it becomes part of the POST data
        $scope.form = {  isFeinRequired: false,isWebsiteRequired : false, isChecked :false };

        $scope.previousInsuredValues = {};
        $scope.settableFormFields = [ "address1","address2", "city", "state", "zip", "name", "dba", "phone", "email", "fein", "website" ];
        $scope.notEligibleMsg = ''
        //add access to wizard of the wizard_data object
        $scope.wizard_data =  Wizard.data.entries['id-0'];
        for(var i=0; i< $scope.wizard_data.products.arr.length; i++) {
           var prod = $scope.wizard_data.products[$scope.wizard_data.products.arr[i]];
            if(prod.title === "Cyber" || prod.title === "Network and Information Security Liability" ){
                $scope.isWebsiteRequired = true;
            }
        }


        // Set initial values for the rest of the form
        $scope.resetFormValues = function( fields, keepState ) {
            angular.forEach( fields, function( field ) {
                if( !keepState || ( keepState && field != "state" ) ) {
                    $scope.form[ field ] = '';
                    $scope.previousInsuredValues[ field ] = '';
                }
            });
        };
        $scope.userProfile=UserProfile;
        $scope.resetFormValues( $scope.settableFormFields );
        $scope.showAgencyLocations = false;
        $scope.wizard_data.data.agencyId = (UserProfile.type == "Agent" || UserProfile.agencyEmulation) ? $scope.userProfile.iconAgencies[ 0 ].id : '';
        $scope.masterQuoteData = {
            deliveryPreference: $scope.initialValues.deliveryPreference
        };

        $scope.triggerExistingSubmissionsModal = false;
        $scope.possibleSubmissions = [];
        $scope.matchingExistingSubmissions = [];

        $scope.status = {
            loading         : false,
            canEditInsured  : true,
            isEmailRequired : true,
            hideAddressForm : true,
            continueWithoutSearchResult: false,
            existingInsuredSelected: false,
            searchInProgress: false,
            insuredRetrievalError: false,
            selectedInsuredAddressError: false,
            loadingSections: true,
            showInsuredName: false
        };
        $scope.status.editingSubmission = ( UserProfile.editedApplication && UserProfile.editedApplication > 0 );
        // Make sure the insuredProfiles are reset when starting a new submission
        if( !$scope.status.editingSubmission ) {
            SubmissionStore.insuredProfiles = null
        };
        isEligibleForBusinessName(SubmissionStore)

        $scope.status.hideSearch = $scope.status.editingSubmission;
        $scope.status.showInsuredForm = $scope.status.editingSubmission;
        $scope.addressMessages = new MessageContainer({ error: [] });
        $scope.contactMessages = new MessageContainer({ error: [] });
        $scope.applicationPath =  ( UserProfile && UserProfile.isLoggedIn ) ? "/application/agent" : "/application";
        $scope.isExistingInsuredSearchAllowed = AccountService.isExistingInsuredSearchAllowed( UserProfile );
        $scope.searchTitle = "Let us help you start your submission";
        $scope.lastInsuredSearchResults = [];
        $scope.lastGoogleSearchResults = [];

        $scope.searchPhrase = "";
        $scope.insuredProfiles = null;

        $scope.states = ProductSearchService.getStates();
        $scope.checkAgencyLocation = function () {
            if ( $scope.status.editingSubmission === true || $scope.userProfile.type !== 'Agent' ) {
                $scope.showAgencyLocations = false;
            }
            else if ( $scope.userProfile.iconAgencies.length > 1 ) {
                $scope.showAgencyLocations = true;
            }
        };
        $scope.checkAgencyLocation();
        $scope.focusOnElement = function( elementId ) {
            $timeout( function () {
                jQuery( "#" + elementId ).focus();
            });
        };

        if( !$scope.status.hideSearch ) {
            $scope.focusOnElement( "searchPhrase" );
        }
        if( $routeParams.applicationId && $routeParams.applicationId == UserProfile.editedApplication ){
            // Replace visitedSections with the appropriate starting point
            if( UserProfile.type == 'Agent' ) {
                SubmissionStore.visitedSections = [ '/application/agent' ];
            } else {
                SubmissionStore.visitedSections = [ '/application' ];
            }
            $scope.status.loadingSections = true;
            
            SpecialtyEligibilityService.reopenSubmission( $routeParams.applicationId ).then( function( result ) {
                $scope.status.retrievalError = result.error;
                if( result.response ) {
                    var currentEffectiveDate = moment( result.response.effectiveDate, "YYYY-MM-DD" );
                    SubmissionStore.previousValues = {
                        effectiveDate: currentEffectiveDate,
                        insured: result.response.insured,
                        canEditInsured: result.response.canEditInsured
                    };
                    SubmissionStore.applicationId = $routeParams.applicationId ;
                    SubmissionStore.programName = result.response.programName;
                    SubmissionStore.masterQuoteID = result.response.masterQuoteID;
                    SubmissionStore.servicingAgentId = result.response.servicingAgentId;
                    SubmissionStore.licensedAgentId = result.response.licensedAgentId;
                    SubmissionStore.instance = result.response.instance;
                    SubmissionStore.resumeLink = result.response.resumeLink;
                    SubmissionStore.insured = result.response.insured;
                    $scope.marketedPrograms = [ result.response.marketedProgram ];
                    SubmissionStore.programs = [ result.response.marketedProgram ];
                    // Set the marketedProgram on the UserProfile to prevent the user from changing it
                    UserProfile.marketedProgram = result.response.marketedProgram;

                    $scope.marketedProgram = result.response.marketedProgram.value;
                    // If the user is not logged in as an agent and the effective date is in the past,
                    // set the form's effective date to the current date and display a note about the
                    // change.
                    if( currentEffectiveDate.isBefore( moment(), "day" ) && UserProfile.type != 'Agent' ) {
                        $scope.status.previousEffectiveDate = currentEffectiveDate.format( "MM/DD/YYYY" );
                        $scope.status.showDateChangedMessage = true;
                        $scope.form.effectiveDate = moment().format( "MM/DD/YYYY");
                    } else {
                        $scope.form.effectiveDate = currentEffectiveDate.format( "MM/DD/YYYY" );
                    }
                }
                $scope.store = SubmissionStore;
                // Update scope values based on data in SubmissionStore
                AccountService.updateScopeFromStore( $scope, SubmissionStore, $scope.settableFormFields );
                $scope.format_zip();
                $scope.showFein = function() {
                    var profiles = $scope.insuredProfiles ? $scope.insuredProfiles : SubmissionStore.insuredProfiles;
                    return $scope.form.isFeinRequired || ( !!profiles && !profiles.main.maid && !!profiles.related.fein );
                };
                $scope.status.loadingSections = false;
            });
        } else {
            $scope.status.loadingSections = false;
            $scope.store = SubmissionStore;
            // Update scope values based on data in SubmissionStore
            AccountService.updateScopeFromStore( $scope, SubmissionStore, $scope.settableFormFields );
        }

        /*
         If this is a new submission and a zip code was provided, call the zip code REST endpoint and populate the
         state and list of city form field with the first city result as a courtesy.  The expectation is that the search result will
         update it anyway.
         */
        if( !$scope.status.editingSubmission && $scope.form.zip.length ) {
            SpecialtyEligibilityService.checkZipCode( $scope.form.zip ).then( function( result ) {
                if( $scope.form.zip == result.config.data.zip && result.data.cities && result.data.cities.length > 0 ) {
                    for (var i = 0; i < result.data.cities.length; i++) {
                        $scope.form_data.default.cities.push({label: result.data.cities[i], value: result.data.cities[i]});
                    }
                    $scope.form_data.default.states.push({label: result.data.state.name, value: result.data.state.abbreviation});

                    $scope.form.city = $scope.form_data.default.cities[0].value;
                    $scope.form.state = $scope.form_data.default.states[0].value;
                }
            });
        }

        $scope.format_phone = function () {
            var numbers = $scope.form.phone.replace(/\D/g, ''),
                char    = {0: '(', 3: ') ', 6: ' - '};
            if (numbers.length > 10 && numbers.substring(0, 1) == 1) {
                numbers = numbers.substring(1, 11)
            }
            else {
                numbers = numbers.substring(0, 10)
            }
            $scope.form.phone = '';
            for (var i = 0; i < numbers.length; i++) {
                $scope.form.phone += (char[i] || '') + numbers[i];
            }
        };

        function isEligibleForBusinessName(submission) 
		{
			let arr = [];

			if(submission.btype_description)
			{
				arr.push(submission.btype_description.toLowerCase());
			}
			
			for (var i = 0; i < submission.programs.length; i++) 
			{
				let reference_string = submission.programs[i].marketedProgramName;

				if(reference_string)
				{
					arr.push(reference_string.toLowerCase());
				}
			}
            
			let search_str = arr.join();

			$scope.status.showInsuredName = (search_str.indexOf('horse') != -1 || search_str.indexOf('equine') != -1);
        }
		
        $scope.format_zip = function () {
            if (angular.isDefined($scope.form.zip)) {
                $scope.form.zip = $scope.form.zip.replace(/\D/gm, '' );
                var p = [];
                p.push(SpecialtyEligibilityService.checkZipCode($scope.form.zip));
                $q.all(p).then(function (results) {
                    var r = results[0];
                    $scope.form.zip = r.config.data.zip;
                    if ($scope.form.zip.length && $scope.form.zip == r.config.data.zip) {
                        $scope.form_data.default.cities = [];
                        $scope.form_data.default.states = [];

                        for (var i = 0; i < r.data.cities.length; i++) {
                            $scope.form_data.default.cities.push({label: r.data.cities[i], value: r.data.cities[i]});
                        }
                        $scope.form_data.default.states.push({label: r.data.state.name, value: r.data.state.abbreviation});

                        $scope.form.city = $scope.form_data.default.cities[0].value;
                        $scope.form.state = $scope.form_data.default.states[0].value;
                    }
                });
            }
        };

        $scope.format_fein = function () {
            if( $scope.form.fein ) {
                $scope.form.fein = $scope.form.fein.replace(/\D/gm, '' ).substr(0, 9);
            }
        };

        $scope.navigate_back = function()
        {
            $location.path(  $scope.applicationPath );
        };

        $scope.showSearch = function() {
            // Reset form value except for state
            $scope.resetFormValues( $scope.settableFormFields, true );
            // Reset insuredProfiles
            $scope.insuredProfiles = null;
            $scope.focusOnElement( "searchPhrase" );
            $scope.status.hideSearch = false;
            $scope.status.hideAddressForm = true;
            $scope.status.showInsuredForm = false;
            $scope.status.continueWithoutSearchResult = false;
            $scope.status.existingInsuredSelected = false;
            $scope.status.insuredRetrievalError = false;
            $scope.status.selectedInsuredAddressError = false;
            // Reset any existing submissions data
            $scope.triggerExistingSubmissionsModal = false;
            $scope.possibleSubmissions = [];
            $scope.matchingExistingSubmissions = [];

            // Reset any validation errors as well
            $scope.addressMessages.reset();
            $scope.contactMessages.reset();
        };

        $scope.lookupPlace = function( request, callback ) {
            $scope.status.insuredRetrievalError = false;
            $scope.status.showInsuredForm = false;

            var params = {
                searchTerm: request.term,
                searchTermFilter: $filter('filter')( $scope.states, { id: $scope.form.state }, true )[0].label,
                searchState: $scope.form.state,
                includeExistingInsureds: $scope.isExistingInsuredSearchAllowed,
                isMultipleSystemSearch: $scope.wizard_data.maverick.arr.length > 0,
                sessionToken: $scope.sessionToken //starts off empty
            };

            var responseArray = [];
            // Reset search results
            $scope.lastInsuredSearchResults = [];
            $scope.lastGoogleSearchResults = [];
            AccountService.performPlacesLookup( params ).then( function( result ) {

                if( result.success ) {
                    $scope.sessionToken = $scope.sessionToken ? $scope.sessionToken : result.sessionToken;

                    for( var m = 0; m < result.matches.length; m++ ) {
                        var labelHTML;
                        var googleResultsTitle;
                        var insuredResultsTitle;
                        if( result.matches[m].resultGroupOrder == 1 ) {
                            if( !googleResultsTitle && $scope.isExistingInsuredSearchAllowed ) {
                                googleResultsTitle = "<span class='choiceHeading'><strong>These are records we found we thought might be a match</strong></span>";
                                $scope.lastGoogleSearchResults.push( { label: googleResultsTitle, action: "none", value: request.term } );
                            }
                            if( result.matches[m].name ) {
                                labelHTML = "<strong>" + result.matches[m].name + ":</strong> " + result.matches[m].address
                            } else {
                                labelHTML = result.matches[m].address
                            }
                            $scope.lastGoogleSearchResults.push(
                                {
                                    label: labelHTML,
                                    value: request.term,
                                    placeId: result.matches[m].placeId,
                                    name: result.matches[m].name,
                                    action: "getPlaceDetail"
                                }
                            )
                        }else{
                            if( !insuredResultsTitle ) {
                                insuredResultsTitle = "<span class='choiceHeading'><strong>We found these insureds your agency has previously submitted</strong></span>";
                                $scope.lastInsuredSearchResults.push( { label: insuredResultsTitle, action: "none", value: request.term } );
                            }
                            var name;
                            var dba;
                            if(result.matches[m].name.length > 50){
                                name = result.matches[m].name.slice(0,50).concat("...");
                            }else{
                                name = result.matches[m].name
                            }

                            if(result.matches[m].dba.length > 30){
                                dba = result.matches[m].dba.slice(0,30).concat("...");
                            }else{
                                dba = result.matches[m].dba
                            }

                            var title;

                            if(result.matches[m].dba.length > 0){
                                title = result.matches[m].name + "  / DBA  " + result.matches[m].dba;
                            }else{
                                title = result.matches[m].name;
                            }
                            labelHTML = "<div class='hiddenResult' title = '"+ title + "'><strong>" +  result.matches[m].insuredId + "</strong>  " +  name  + " " +  dba  +" (" + result.matches[m].state + ")</div>";
                            $scope.lastInsuredSearchResults.push(
                                    {
                                        label: labelHTML,
                                        value: result.matches[m].name,
                                        insuredId: result.matches[m].insuredId,
                                        referenceId: result.matches[m].referenceId,
                                        systemSource: result.matches[m].systemSource,
                                        action: "getInsuredDetail"
                                    }
                            )
                        }
                    }
                }
                // Limit the initial list of found insureds to 5 (the array on the scope will retain the full list for
                // use when the user wants to see all the results).
                var insuredResponseFinalArray = [];
                if($scope.lastInsuredSearchResults.length <= 6 ){
                    var matches = $scope.lastInsuredSearchResults.length;
                    for ( var i = 0; i < matches ; i++ ) {
                        insuredResponseFinalArray.push( $scope.lastInsuredSearchResults[i] );
                    }
                }
                else {
                    matches = 6;
                    for ( i = 0; i < matches; i++ ) {
                        insuredResponseFinalArray.push( $scope.lastInsuredSearchResults[i] );
                    }
                    insuredResponseFinalArray.push( {
                        label: "<div class='showMoreInsureds'>&#43; Show more suggestions </div> ",
                        value: callback,
                        term: request.term,
                        action: "showMoreInsureds"
                    } );
                }
                responseArray = insuredResponseFinalArray.concat( $scope.lastGoogleSearchResults );
                if ( responseArray.length ) {
                    responseArray.push( {
                        label: "<span class='nonChoice'>None of these items match. Let me enter the insured information manually</span>",
                        value: request.term,
                        action: "manualAddress"
                    } );
                }
                else {
                    responseArray.push( {
                        label: "<span class='nonChoice'>No matches found. Click here to enter the insured information manually</span>",
                        value: request.term,
                        action: "manualAddress"
                    } );
                }
                callback( responseArray );

                // Make sure the results scroll to the top (without this, when the user scrolls down the list in
                // a previous search, it becomes the starting scroll point on the next search).
                jQuery( "#searchPhrase" ).data("autocomplete").menu.element.scrollTop(0);

                $scope.status.searchInProgress = false;

            }).catch( function( err ) {
                responseArray.push( {
                    label: "<span class='nonChoice'>The search feature is currently not available. Click here to enter the insured information manually</span>",
                    value: request.term,
                    action: "manualAddress"
                } );

                callback( responseArray );

                $scope.status.searchInProgress = false;
            });
        };

        $scope.showMoreInsureds = function ( callback, term ) {
            // The timeout is necessary to refresh the results
           $timeout( function() {
               $scope.status.searchInProgress = true;
               var responseArray = [];
               // This time all of the entries in the insured search are returned.
               responseArray = responseArray.concat( $scope.lastInsuredSearchResults );
               responseArray = responseArray.concat( $scope.lastGoogleSearchResults );

               responseArray.push( {
                    label: "<span class='nonChoice'>None of these items match. Let me enter the insured information manually</span>",
                    value: term,
                    action: "manualAddress"
               });

                callback( responseArray );
                $scope.status.searchInProgress = false;
            }, 50 );
        };

        $scope.parsePlace = function( event, selected ) {
            $scope.status.searchInProgress = true;

            switch( selected.item.action ) {

                case "getPlaceDetail":
                    $scope.processPlaceDetail( selected );
                    break;

                case "getInsuredDetail":
                    $scope.processInsuredDetail( selected );
                    break;

                case "showMoreInsureds":
                    $scope.showMoreInsureds( selected.item.value, selected.item.term );
                    break;

                case "none":
                    $scope.status.searchInProgress = false;
                    break;

                default:
                    $scope.status.searchInProgress = false;
                    $scope.status.hideAddressForm = false;
                    $scope.status.showInsuredForm = true;
                    $scope.status.hideSearch = true;
                    $scope.status.continueWithoutSearchResult = true;
                    // Send the cursor to the first address field
                    $scope.focusOnElement( "address1" );
                    $scope.$digest(); // A digest is needed here to update the UI
                    break;
            }

        };

        $scope.processPlaceDetail = function( selected ) {
            var params = {
                placeId: selected.item.placeId,
                sessionToken: $scope.sessionToken
            };

            AccountService.getPlaceDetails( params ).then(function (result) {
                var responseArray = [];

                if (result.success) {
                    $scope.sessionToken = result.sessionToken;
                    var addressObj = AccountService.parseAddressComponents(result.detail.addressComponents, $scope.form.state);
                    $scope.form.address1 = addressObj.address;
                    $scope.form.city = addressObj.city;
                    $scope.form.state = addressObj.state;
                    $scope.form.zip = addressObj.zip;
                    $scope.format_zip();
                    
                    // A business search result may provide name, phone, and website; partial address searches will not
                    $scope.form.name = selected.item.name ? selected.item.name : "";
                    $scope.form.phone = result.detail.phone;
                    $scope.form.website = result.detail.website;
                    // Any entered DBA or email value should remain untouched
                }

                $scope.status.hideAddressForm = true;
                $scope.status.showInsuredForm = true;
                $scope.status.hideSearch = true;
                $scope.status.searchInProgress = false;

                // Send the cursor to the first empty input
                $scope.focusOnElement($scope.form.name ? "dba" : "insuredName");
            });
        };

        $scope.processInsuredDetail = function( selected ) {
            $scope.status.existingInsuredSelected = true;
            AccountService.getInsuredDetails( selected.item.referenceId ).then( function ( insuredProfiles ) {
                $scope.insuredProfiles = AccountService.parseInsuredProfiles( insuredProfiles );

                $scope.form.address1 = $scope.insuredProfiles.main.address1;
                $scope.form.address2 = $scope.insuredProfiles.main.address2;
                $scope.form.city = $scope.insuredProfiles.main.city;
                $scope.form.state = $scope.insuredProfiles.main.state;
                $scope.form.zip = $scope.insuredProfiles.main.zip;
                $scope.format_zip();

                $scope.form.name = $scope.insuredProfiles.main.name;
                $scope.form.dba = $scope.insuredProfiles.main.dba ? $scope.insuredProfiles.main.dba : "";
                $scope.form.phone = $scope.insuredProfiles.main.phone ? $scope.insuredProfiles.main.phone : "";
                $scope.form.email = $scope.insuredProfiles.main.email ? $scope.insuredProfiles.main.email : "";
                $scope.form.website = $scope.insuredProfiles.main.website ? $scope.insuredProfiles.main.website: "";
                $scope.form.fein = $scope.insuredProfiles.main.fein ? $scope.insuredProfiles.main.fein : "";

                if( $scope.insuredProfiles.main.maid && $scope.form.email == "" ) {
                    $scope.focusOnElement( "email" );
                } else if( !$scope.insuredProfiles.main.maid ) {
                    $scope.focusOnElement( "dba" );
                }

                // Check for editable submissions
                if( $scope.insuredProfiles.main.insuredid ) {
                    AccountService.retrieveEditableSubmissions( $scope.insuredProfiles.main.insuredid, SubmissionStore.agencyId, SubmissionStore.programs).then( function ( data ) {
                        $scope.possibleSubmissions = data;
                        angular.forEach( $scope.possibleSubmissions, function( marketedProgramSubmission ) {
                            if( marketedProgramSubmission.masterQuoteId ) {
                                $scope.matchingExistingSubmissions.push( marketedProgramSubmission )
                            }
                        });
                        if( $scope.matchingExistingSubmissions.length > 0 ) {
                            $scope.triggerExistingSubmissionsModal = true;
                        }

                        $scope.status.hideAddressForm = true;
                        $scope.status.showInsuredForm = true;
                        $scope.status.hideSearch = true;
                        $scope.status.searchInProgress = false;
                    }).catch( function( error ) {
                        $scope.status.insuredRetrievalError = true;
                        $scope.status.searchInProgress = false;
                    });
                } else {
                    $scope.status.hideAddressForm = true;
                    $scope.status.showInsuredForm = true;
                    $scope.status.hideSearch = true;
                    $scope.status.searchInProgress = false;
                }

            }).catch( function( error ) {

                $scope.status.insuredRetrievalError = true;
                $scope.status.searchInProgress = false;

            });
        };

        $scope.showEditAddressButton = function() {
            return $scope.status.canEditInsured && !$scope.status.existingInsuredSelected && !SubmissionStore.insuredProfiles;
        };

        $scope.disableField = function( fieldName ) {
            if( !$scope.status.existingInsuredSelected && !SubmissionStore.insuredProfiles ) {
                return false;
            }
            var maid = $scope.insuredProfiles ? $scope.insuredProfiles.main.maid : ( SubmissionStore.insuredProfiles ? SubmissionStore.insuredProfiles.main.maid : null );
            // If we somehow have an existing mPolicy insured without an email address, the user will need to be able to provide one
            if( maid && fieldName == "email" && !$scope.form.email ) {
                return false;
            }
            // The name field should not be editable if an existing insured of any type is selected
            if( ( $scope.status.existingInsuredSelected || SubmissionStore.insuredProfiles ) && fieldName == "name" ) {
                return true;
            }
            return !!maid;
        };

        $scope.showFein = function() {
            var profiles = $scope.insuredProfiles ? $scope.insuredProfiles : SubmissionStore.insuredProfiles;
            return $scope.form.isFeinRequired || ( !!profiles && !profiles.main.maid && !!profiles.related.fein );
        };

        $scope.submitForm = function () {
            // If the search bar is still visible when they click the submit/"Continue" button, that means they
            // didn't select a result and will have to enter the address manually, so change the view and do not
            // submit the data.
            if( !$scope.status.hideSearch ) {
                $scope.status.hideAddressForm = false;
                $scope.status.showInsuredForm = true;
                $scope.status.hideSearch = true;
                $scope.status.continueWithoutSearchResult = true;
                $scope.focusOnElement( "address1" );
                return;
            }

            // If the retrieval of a search selection is still in progress, do nothing
            if( $scope.status.searchInProgress ) {
                return;
            }

            //no insured in Submission Store - Default it to our form values
            if( !SubmissionStore.insured ) {
                SubmissionStore.insured = $scope.form;
            }

            $scope.addressMessages.reset();
            $scope.contactMessages.reset();
            $scope.status.selectedInsuredAddressError = false;
            $scope.status.loading = true;

            // If editing an application, ensure that the user can only reach the next step (even if it was previously
            // visited) via the Continue button
            if( $scope.status.editingSubmission ) {
                var questionsSectionIndex = SubmissionStore.visitedSections.indexOf( '/application/questions' );
                if( questionsSectionIndex > -1 ) {
                    SubmissionStore.visitedSections.splice( questionsSectionIndex, 1 );
                }
                $scope.performInsuredChange = $scope.status.canEditInsured;
            } else {
                $scope.performInsuredChange = false;
            }

            // Re-evaluate value for performInsured based on form data.
            $scope.performInsuredChange = $scope.performInsuredChange ? $scope.hasInsuredDataChanged() : false;

            // Now update submission store w/ insured data
            angular.extend( SubmissionStore.insured, $scope.form );

            // If we've already created an applicationId (e.g., the user navigated backwards) but are not editing a submission
            // and the insured info hasn't changed, push forward to the application questions and skip insured search, etc.
            if ( (!UserProfile.editedApplication && UserProfile.editedApplication == 0) && SubmissionStore.applicationId != null && typeof SubmissionStore.insured !== 'undefined' && !$scope.hasInsuredDataChanged() ) {
                $location.path( "/application/questions" );
                return;
            }

            if( $scope.status.editingSubmission || SubmissionStore.insured ) {
                $scope.form.maid = SubmissionStore.insured.maid;
            }

            if ($scope.isWebsiteRequired && !$scope.form.isChecked) {
                if ($scope.form.website == '' || $scope.form.website == null || $scope.form.website == undefined) {
                if ($scope.form.name == '' || $scope.form.name == null) {
                    $scope.contactMessages.error.push("You must enter a name.");
                }
                if ($scope.form.email == '' || $scope.form.email == null) {
                    $scope.contactMessages.error.push("You must enter an email address.");
                }
                if ($scope.form.email !== '' && $scope.form.email !== undefined) {
                        let regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
                        let result = regex.test(String($scope.form.email).toLowerCase());
                        if (!result) {
                            $scope.contactMessages.error.push("You must enter an valid email address.");
                        }
                }

                if ($scope.form.phone !== '' && $scope.form.phone !== undefined) {
                    let phoneRegex = /^\(\d{3}\) \d{3} - \d{4}$/;
                    let phoneValid = phoneRegex.test(String($scope.form.phone));
                    if (!phoneValid) {
                        $scope.contactMessages.error.push("You must enter a valid phone number. (e.g. XXX-XXX-XXXX)");
                    }
                }
                    $scope.contactMessages.error.push("You must enter a Website.");
                    $scope.status.loading = false;
                    return
                }

            }
            // Do not perform a clearance search if the threshold > 100 or an existing mPolicy insured record was selected
            if( SubmissionStore.insuredMatchDispThreshold >= 100 || ( $scope.insuredProfiles && $scope.insuredProfiles.main.maid ) ) {
                if( $scope.status.editingSubmission ) {
                    self.checkAvailability()
                } else {
                    if( $scope.insuredProfiles && $scope.insuredProfiles.main.maid ) {
                        self.changeInsured( $scope.form, $scope.insuredProfiles.main, $scope.status.isEmailRequired )
                    } else {
                        self.saveInsured( $scope.form, $scope.insuredProfiles )
                    }
                }
            }
            else
            {
                //lets see if our insured exists!
                AccountService.search( $scope.form )
                    .then(function( result ) {
                        var data = result.data;
                        SubmissionStore.clearanceSearchResults = [];
                        angular.extend( SubmissionStore.clearanceSearchResults, result.data.matches );

                        /**
                         *  If the clearance search produces 0 results there is no reason for the user to see this screen. We still
                         *  needed to forward the user to this controller though so we can create the master quote and move on. We need
                         *  to do this here just because of the nature of promises. If we did this in the clearance controller you would
                         *  still see the results screen until the promise of the save insured was met.
                         *
                         *  For now, the process of editing a submission does not support the selection of an insured via clearance.
                         */
                        if( SubmissionStore.clearanceSearchResults.length == 0 || $scope.status.editingSubmission )
                        {
                            //same as above
                            $scope.status.editingSubmission  ? self.checkAvailability() : self.saveInsured( $scope.form );
                        }
                        else
                        {
                            $location.path("/application/account/clearance");
                        }

                    }).catch(function( err ) {
                        var data = err.data;
                        $scope.parseErrorMessages( data );
                        $scope.status.loading = false;
                    });
            }

        };

        $scope.changeAgencyId = function () {
            if($scope.form.agency !== null) {
                SubmissionStore.agencyId = $scope.form.agency.mbuAgencyId;
                $scope.wizard_data.data.agencyId = $scope.form.agency.id;
            }
        };

        // Parse the error messages into the correct collections
        $scope.parseErrorMessages = function( errorData ) {
            var contactFields = [ "name", "email", "fein" ];
            var messageCollection;
            $scope.settableFormFields.forEach( function ( key ) {
                messageCollection = contactFields.indexOf( key ) > -1 ? $scope.contactMessages : $scope.addressMessages;
                if ( errorData[ key ] ) {
                    errorData[ key ].forEach( function( validation ) {
                        if( messageCollection.error.indexOf( validation.message ) == -1 ) {
                            messageCollection.error.push( validation.message );
                        }
                    });
                }
            });
            if ( $scope.addressMessages.error.length > 0 ) {
               $scope.status.hideAddressForm = false;
            }
        };

        $scope.hasInsuredDataChanged = function() {
            return !(
                $scope.form.name === $scope.previousInsuredValues.name
                && $scope.form.dba === $scope.previousInsuredValues.dba
                && $scope.form.address1 === $scope.previousInsuredValues.address1
                && $scope.form.address2 === $scope.previousInsuredValues.address2
                && $scope.form.city === $scope.previousInsuredValues.city
                && $scope.form.state === $scope.previousInsuredValues.state
                && $scope.form.zip === $scope.previousInsuredValues.zip
                && $scope.form.phone === $scope.previousInsuredValues.phone
                && $scope.form.email === $scope.previousInsuredValues.email
                && $scope.form.fein === $scope.previousInsuredValues.fein
                && $scope.form.website === $scope.previousInsuredValues.website
            )
        };

        $scope.$on( "proceed-with-new-submission",  function ( event, args ) {
            self.createMasterQuote();
        });

        // Creating a namespace in order to expose these functions for testing
        var self = this;

        self.openExistingSubmissionsModal = function() {

            $modal.open({
                templateUrl: "app/components/specialtyApplication/account/template/editableSubmissionsModal.html",
                controller: "EditableSubmissionsModalController",
                windowClass: "editable-submissions-modal",
                scope: $scope,
                resolve: {
                    possibleSubmissions: function(){ return $scope.possibleSubmissions },
                    existingSubmissions: function(){ return $scope.matchingExistingSubmissions },
                    accountId: function() { return $scope.insuredProfiles.main.insuredid },
                    effectiveDate: function() { return SubmissionStore.effectiveDate },
                    Wizard: function() { return Wizard },
                    wizard_data: function() { return $scope.wizard_data }
                }
            });
            $scope.status.loading = false;
        };

        self.changeInsured = function( insuredFormData, mainProfile, isEmailRequired ) {

            self.clearSearchResults();
            if( $scope.performInsuredChange || ( mainProfile && AccountService.profileDataRequiresUpdate( mainProfile, insuredFormData, isEmailRequired ) ) ) {
                var insuredData = {};
                if( mainProfile ) {
                    // Copy the insured form data to insuredData object so maid doesn't persist if submit attempt errors and user
                    // decides to create a new user instead.
                    angular.copy( insuredFormData, insuredData );
                    insuredData.maid = mainProfile.maid;
                } else {
                    insuredData = insuredFormData;
                }
                AccountService.changeInsured(
                    insuredData,
                    UserProfile.editedApplication,
                    SubmissionStore.programCode,
                    SubmissionStore.agencyId,
                    $scope.form.maxscore
                ).then(function( result ) {
                        var data = result.data;
                        angular.extend( SubmissionStore.insured, data );

                        // mainProfile will only ever be present when attempting to create a new submission, so we made minor
                        // changes to the insured record but are creating a new master quote
                        if( mainProfile ) {
                            /*
                             If the related profile has an insuredid value, use that to populate the wizard.  Otherwise,
                             use the insuredData/form data
                            */
                            if( $scope.insuredProfiles.related && $scope.insuredProfiles.related.insuredid ) {
                                $scope.wizard_data.insured = $scope.insuredProfiles.related;
                                $scope.wizard_data.data.state = $scope.insuredProfiles.related.state;
                            } else {
                                $scope.wizard_data.insured = insuredData;
                                $scope.wizard_data.data.state = insuredData.state;
                            }

                            Wizard.set($scope.wizard_data);

                            if( $scope.triggerExistingSubmissionsModal ) 
                            {
                                self.openExistingSubmissionsModal();
                            } 
                            else 
                            {
                                self.createMasterQuote();
                            }

                        } else {
                            // Check if user came back to this step from the Maverick process, and if so head back there
                            if($scope.wizard_data.maverick.arr.length)
                            {
                                var existingMaverickId = $scope.insuredProfiles ? $scope.insuredProfiles.related.insuredid : ( SubmissionStore.insuredProfiles ? SubmissionStore.insuredProfiles.related.insuredid : null )
                                if( !existingMaverickId ) {
                                    // Update the insured data in the wizard based on the form data (otherwise the wizard already has the data from the previous visit)
                                    $scope.wizard_data.insured = insuredData;
                                    $scope.wizard_data.data.state = insuredData.state;
                                }
                                $scope.wizard_data.step = 2;
                                $scope.wizard_data.store = SubmissionStore;
                                Wizard.set($scope.wizard_data);

                                $location.path("/quote");
                            }
                            else
                            {
                                //monoline proceed!
                                self.changeMasterQuote();
                            }
                        }

                    }).catch(function ( err ) {
                        var data = err.data;
                        AccountService.populateAddressVerificationErrors( $scope.addressMessages.error, data, $scope.status );
                        $scope.parseErrorMessages( data );
                        $scope.status.loading = false;
                    });
            } else {
                // mainProfile will only ever be present when creating a new submission, so we skipped making
                // changes to the insured record but are creating a new master quote, which pulls data from the
                // SubmissionStore.insured object
                if( mainProfile ) {
                    angular.extend( SubmissionStore.insured, mainProfile );
                    // We need to add insured data to the wizard
                    if( $scope.insuredProfiles.related && $scope.insuredProfiles.related.insuredid ) {
                        $scope.wizard_data.insured = $scope.insuredProfiles.related;
                        $scope.wizard_data.data.state = $scope.insuredProfiles.related.state;
                    } else {
                        $scope.wizard_data.insured = insuredFormData;
                        $scope.wizard_data.data.state = insuredFormData.state;
                    }
                    if( $scope.triggerExistingSubmissionsModal ) {
                        self.openExistingSubmissionsModal();
                    } else {
                        self.createMasterQuote();
                    }
                } else {
                    self.changeMasterQuote();
                }
            }
        };

        self.changeMasterQuote = function() {
            $scope.status.loading = true;

            AccountService.changeMasterQuote(
                UserProfile.editedApplication,
                SubmissionStore.previousValues.effectiveDate,
                SubmissionStore.effectiveDate,
                SubmissionStore.insured.deliveryPreference,
                SubmissionStore.instance
            )
            .then(function( result ) {
                var data = result.data;

                angular.extend( SubmissionStore, {
                    applicationId: data.applicationId,
                    masterQuoteID: data.masterquoteid,
                    programName: data.programName
                });

                //Update previous effectiveDate to new value to allow for a re-edit
                SubmissionStore.previousValues.effectiveDate = $scope.form.effectiveDate;

                $location.path("/application/questions");
            });

        };

        self.saveInsured = function( insuredFormData, insuredProfiles ) {
            self.clearSearchResults();
            var programCode = SubmissionStore.programCode,
                agencyId    = SubmissionStore.agencyId;

            var insuredData = {};
            if( insuredProfiles ) {
                // Copy the insured form data to insuredData object so additional data doesn't persist if submit
                // attempt errors and user selects a different user.
                angular.copy( insuredFormData, insuredData );
                insuredData.maverickAccountId = insuredProfiles.related.insuredid;
                insuredData.isAddressVerificationRequired = true;
            } else {
                insuredData = insuredFormData;
            }

            insuredData.name = (!insuredData.name) ? "" : insuredData.name;
            insuredData.dba  = (!insuredData.dba) ? ""  : insuredData.dba;

            const specialchars = ["'", ",", ".", ";", ":"];
            specialchars.forEach((c)=>{
                insuredData.name = insuredData.name.replace(c, "");    
                insuredData.dba = insuredData.dba.replace(c, "");
            });        
            console.log('INSURED DATA: ', insuredData, programCode, agencyId, $scope.form.maxscore, SubmissionStore.programs)
            
            AccountService.saveInsured(
                insuredData,
                programCode,
                agencyId,
                $scope.form.maxscore,
                SubmissionStore.programs[0].referenceString
            )
            .then(function( result ) {
                var data    = result.data;
                var systems = Wizard.systems();
                
                angular.extend( SubmissionStore.insured, data );
                
                /*
                 If the related profile has an insuredid value, use that to populate the wizard.  Otherwise,
                 use the insuredData/form data
                */
                if( insuredProfiles && insuredProfiles.related && insuredProfiles.related.insuredid ) {
                    $scope.wizard_data.insured = insuredProfiles.related;
                    $scope.wizard_data.data.state = insuredProfiles.related.state;
                } else {
                    $scope.wizard_data.insured = insuredData;
                    $scope.wizard_data.data.state = insuredData.state;
                }

                self.update_wizard();

                if(systems.luxon)
                {
                    self.continue();
                    return;
                }
                
                self.createMasterQuote();
            
            }).catch(function ( err ) {

                var data = err.data;
                AccountService.populateAddressVerificationErrors( $scope.addressMessages.error, data, $scope.status );
                $scope.parseErrorMessages( data );
                $scope.status.loading = false;
            });

        };

        self.clearSearchResults = function(){
            SubmissionStore.clearanceSearchResults = null;
        };

        self.update_wizard = function()
        {
            //update our wizard data
            $scope.wizard_data.store = SubmissionStore;
            if(Wizard.systems.magency)
            {
                 $scope.wizard_data.step = 2;
            }

            Wizard.set($scope.wizard_data);

            if(UserProfile.debug)console.log('wizard of the wizard_data' , $scope.wizard_data);
        };

        self.continue = function(){
            var systems = Wizard.systems();

            if(systems.magency)
            {
                $location.path("/quote");
                return;
            }

            if(systems.mpolicy)
            {
                $location.path("/application/questions");
                return;
            }

            if(systems.luxon) 
            {
                //better way of doing this, probably a field on a btype that passes the parition_key;
                //may need to override based on rules


                //loop our products and grab the partition key
                let arr = $scope.wizard_data.luxon.arr;
                let partition_key = null;
                        
                for(let i=0; i < arr.length;i++)
                {
                    let product = $scope.wizard_data.luxon[arr[i]];

                    if(!partition_key)
                    {
                        for(let j = 0; j < product.dataPoints.length; j++)
                        {
                            let field = product.dataPoints[j];

                            if(field.keyname == 'partition_key')
                            {
                                partition_key = field.value;
                                break;
                            }
                        }
                    }
                    else
                    {
                        break;
                    }
                }

                //need to pass the partition_key in the wizard from product search
                $location.path(`/lx/submission/${partition_key}`);
                return;
            }

            //no systems defined assumed no wizard which means mpolicy!
            $location.path("/application/questions"); 
            return;
        };

        self.createMasterQuote = function() {
            $scope.status.loading = true;

            //set our website data point from the form!
            angular.forEach(SubmissionStore.programs, function (program) {
                program.dataPoints.push({keyname: 'Website', value: $scope.form.website});
                if (program.programCode === 'GenPackage' && $scope.wizard_data.data.naics) {
                    program.dataPoints.push({keyname: 'NAICS Code', value: $scope.wizard_data.data.naics.naicsCode});

                }
                if(!$scope.userProfile.isLoggedIn && program.marketedProgramName === 'Farriers'){
                    $scope.wizard_data.data.btype_description = 'Farrier'
                }
            });

           AccountService.createMasterQuote(
                SubmissionStore.insured.maid,
                SubmissionStore.insured.insuredid,
                SubmissionStore.insured.name,
                SubmissionStore.insured.dba,
                SubmissionStore.agencyId,
                SubmissionStore.agentId,
                "",
                SubmissionStore.insured.state,
                SubmissionStore.insured.zip,
                SubmissionStore.programs,
                SubmissionStore.effectiveDate,
                SubmissionStore.marketedProgramId,
                $scope.masterQuoteData.deliveryPreference,
                SubmissionStore.cobrandingId,
                $scope.wizard_data.data.btype_description
            )
            .then(function( result ) {
                var data    = result.data;
                var systems = Wizard.systems();

                angular.extend( SubmissionStore, {
                    applicationId: data.applicationId,
                    masterQuoteID: data.masterQuoteID,
                    instance: data.instance,
                    insuredProfiles: $scope.insuredProfiles
                });

                self.update_wizard();
                self.continue();
                
            }).catch( function( err ) {
                if( $scope.status.existingInsuredSelected && err.data.message == 'Program unavailable' ) {
                    $scope.contactMessages.error.push( "The insured you selected is in a state which is unavailable for this product.  Please contact customer service with any questions." );
                } else {
                    $scope.contactMessages.error.push( "An error occurred while trying to create your submission. Please try again, but if this error persists please contact customer service." );
                }
                $scope.status.loading = false;
           });

        };

        self.checkAvailability = function () {
            $scope.notEligibleMsg = ''
            SpecialtyEligibilityService.checkAvailability(
                $scope.form.zip,
                $scope.form.effectiveDate,
                $scope.marketedProgram,
                UserProfile.cobranding.id
            ).then(function (result) {
                var data = result.data;
                angular.extend( SubmissionStore, {
                    agencyId: data.agencyId,
                    agentId: data.agentId,
                    programCode: data.programCode,
                    programName: data.programName,
                    marketedProgramId: $scope.form.marketedProgram,
                    form: {
                        cities: data.cities,
                        state: data.state,
                        zip: $scope.form.zip,
                        documentDeliveryOptions: data.documentDeliveryOptions
                    },
                    effectiveDate: $scope.form.effectiveDate,
                    cobrandingId: UserProfile.cobranding.id,
                    insuredMatchDispThreshold: data.insuredMatchDispThreshold
                });

                //most likely got here from next step or from start quote
                //check to see if the programID has changed
                if(SubmissionStore.programs.length == 1)
                {
                    //only reset if our curret program doesn't match the one in submission store
                    //if it does match we can keep our settings including datapoints
                    if($scope.form.marketedProgram != SubmissionStore.programs[0].marketedProgramId)
                    {
                        //just reset our programs in case someone hit back
                        SubmissionStore.programs = [];
                        SubmissionStore.programs.push({ marketedProgramId       :   $scope.form.marketedProgram,
                            marketedProgramName     :   data.programName,
                            programCode             :   data.programCode,
                            effectiveDate           :   $scope.form.effectiveDate,
                            dataPoints              :   [],
                            referenceString         :   data.referenceString,
                            isUploadBasedSubmission :   data.isUploadBasedSubmission,
                            offlineApplicationUrl   :   data.offlineApplicationUrl,
                            fraudLink            : data.fraudLink
                        });
                    }
                    else {
                        SubmissionStore.programs[0].effectiveDate = $scope.form.effectiveDate
                        SubmissionStore.programs[0].marketedProgramName  =   data.programName
                    }
                }

                if(SubmissionStore.programs.length == 0)
                {
                    //just reset our programs in case someone hit back
                    SubmissionStore.programs = [];
                    SubmissionStore.programs.push({ marketedProgramId       :   $scope.form.marketedProgram,
                        marketedProgramName     :   data.programName,
                        programCode             :   data.programCode,
                        effectiveDate           :   $scope.form.effectiveDate,
                        dataPoints              :   [],
                        referenceString         :   data.referenceString,
                        isUploadBasedSubmission :   data.isUploadBasedSubmission,
                        offlineApplicationUrl   :   data.offlineApplicationUrl,
                        fraudLink            : data.fraudLink
                    });
                }
                /*if(mainProfile){
                    self.changeInsured( $scope.form, mainProfile, isEmailRequired )
                }*/
                SubmissionStore.effectiveDate = $scope.form.effectiveDate,
                self.changeInsured( $scope.form )
            }).catch(function (err) {

                var data = err.data;

                if (err.status === 409) {
                    $scope.setEligibilityErrorMsg(err.data.message);
                }
                $scope.status.loading = false;
            });
        };

        $scope.setEligibilityErrorMsg = function( responseErrorMsg ) {
            if( responseErrorMsg == "Product not available." ) {
                // Currently the error message above is only returned if the effective date is outside of the effective date range
                // determined by mPolicy for the selected program, so the error message can cite the effective date as the main cause.
                $scope.notEligibleMsg = "Sorry, but the selected product is not available for the selected effective date.";
            } else {
                $scope.notEligibleMsg = "Sorry, but we do not currently offer a product for the effective date, business type, and state you selected. Please contact customer service for additional information.";
            }
        }

        $scope.init_data();


    }

    return InsuredAccountController;
});
