define('common/service/util/PaginationService',['require'],function(require) {
	"use strict";

	function PaginationServiceFactory() {

		function PaginationService( params ) {

			this.config = angular.extend({
				currentPage: 1,
				itemsPerPage: 25
			}, params );

			return this;

		}

		PaginationService.prototype.paginate = function( resultSet ) {

			var start = ( this.config.currentPage - 1 ) * this.config.itemsPerPage,
				end = start + this.config.itemsPerPage;

			return resultSet.slice( start, end );

		};

		return PaginationService;

	}

	return PaginationServiceFactory;

});
