define('error/app',['require','common/config/config','shim/overmind','error/controller/InvalidTokenController'],function(require) {
	"use strict";

	require("common/config/config");

	var overmind = require("shim/overmind");
	var InvalidTokenController = require("error/controller/InvalidTokenController");

	var app = angular.module("mPortal.error", [
		"mPortal.config"
	]);

	app.config( overmind.control() );

	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider.when("/error/404", {
			templateUrl: "app/error/template/404.html",
			insecure: true
		});
		$routeProvider.when("/error/agent/404", {
			templateUrl: "app/error/template/agent404.html",
			insecure: true
		});
		$routeProvider.when("/error/invalid-token/:token", {
			templateUrl: "app/error/template/invalid-token.html",
			controller: "InvalidTokenController",
			insecure: true
		});
	}]);

	app.controller("InvalidTokenController", InvalidTokenController);

	return app;

});
