define('redirect/controller/RedirectController',['require'],function(require) {
	"use strict";

	Redirect.$inject = ["$scope", "$route","$window", "ciamDomain"];

	function Redirect( $scope, $route, $window ,ciamDomain) {


		var target = $route.current.target;
		
		switch(target)
		{
			case 'register':
				$window.location.href = ciamDomain + "#register";
			break;

			case 'login':
				$window.location.href = ciamDomain;
			break;
		}


	}

	return Redirect;

});
