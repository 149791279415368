define('components/submissionsList/controller/SubmissionsListController',['require'],function(require) {
	"use strict";

	SubmissionsListController.$inject = ["$scope", "SearchService"];

	function SubmissionsListController( $scope, SearchService ) {

		$scope.submissions = {
			submitted: [],
			quoted: [],
			tooMany: false,
			maxRecords: null
		};

		$scope.selected = {
			submitted: true,
			quoted: false
		};

		SearchService.getSubmissionsSearchResults({
			status: "Quoted,Submitted",
			type: "New",
			sortType: "Desc"
		}).then(function( results ) {

			if( results.tooMany ) {
				$scope.submissions.tooMany = true;
				$scope.submissions.maxRecords = results.maxRecords;
			}
			
			angular.forEach( results.searchResults, function( result ) {

				if( result.STATUS === "Submitted" ) {
					$scope.submissions.submitted.push( result );
				}

				if( result.STATUS === "Quoted" ) {
					$scope.submissions.quoted.push( result );
				}

			});

		});

		$scope.setSelected = function( section ) {

			if( section === "submitted" ) {
				$scope.selected.submitted = true;
				$scope.selected.quoted = false;
			}

			if( section === "quoted" ) {
				$scope.selected.submitted = false;
				$scope.selected.quoted = true;
			}

		};

	}

	return SubmissionsListController;

});
