define('marketedPrograms/controller/MarketedProgramsController',[],function() {

	MarketedProgramsController.$inject = ["$scope", "$filter", "$routeParams", "MarketedProgramService", "UserProfile"];

	function MarketedProgramsController( $scope, $filter, $routeParams, MarketedProgramService, UserProfile ) {

		$scope.programs = [];
		$scope.selectedProgram = {};

		MarketedProgramService.getMarketedPrograms().then(function( programs ) {
			$scope.programs = $filter("filter")( programs, MarketedProgramService.userHasAccessToProgram.bind( null, UserProfile ) );

			if( $routeParams.id ) {
				var preSelectedProgram = $scope.programs.find(function( program ) {
					return program.id == $routeParams.id;
				});

				$scope.selectProgram( preSelectedProgram );
			}
		});

		$scope.selectProgram = function( program ) {
			$scope.selectedProgram = program;
		};

	}

	return MarketedProgramsController;

});
