define('components/specialtyApplication/account/directive/account',['require'],function(require) {
	"use strict";

	Account.$inject = [];

	function Account() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/specialtyApplication/account/template/insuredAccountTemplate.html",
			controller: "SpecialtyApplicationAccountController"
		};
	}

	return Account;

});
