define( 'common/service/specialtyApplication/ResultService',['require'],function (require) {
	"use strict";

	ResultService.$inject = ["$http", "archApiUrl", "$q" ];

	function ResultService( $http, archApiUrl, $q ) {
		var service = {};

		service.getStatus = function(applicationId) {
			return $http.get( archApiUrl + "application/submission/" + applicationId + "/status" ).then( function ( result ) {
				return { error: false, data: angular.isArray( result.data ) ? result.data : [ result.data ] };
			} ).catch( function ( err ) {
				return { error: true, status: err.status };
			} );
		};

		service.getStatusSummary = function(applicationId) {
			return $http.get( archApiUrl + "application/submission/" + applicationId + "/status-summary" ).then( function ( result ) {
				return { error: false, data: angular.isArray( result.data ) ? result.data : [ result.data ] };
			} ).catch( function ( err ) {
				return { error: true, status: err.status };
			} );
		};

		service.acceptSubmission = function( applicationId ) {
			var postUrl = archApiUrl + "application/submission/" + applicationId + "/accept";
			return $http.post( postUrl, { applicationId: applicationId } ).then( function ( result ) {
				return {
					error: false,
					response: result.data.response
				}
			} ).catch( function ( err ) {
				return {error: true}
			} );
		};

		service.bindSubmission = function( applicationId, billingPlan ) {
			var postUrl = archApiUrl + "application/submission/" + applicationId + "/bind";
			return $http.post( postUrl, { applicationId: applicationId, billingPlan: billingPlan } ).then( function ( result ) {
				return {
					error: false,
					response: result.data.response
				}
			} ).catch( function ( err ) {
				return {error: true}
			} );
		};

        service.saveDeliveryPreference = function( submissionId,deliveryPreference ) {
            $http.post(archApiUrl + "application/submission/" + submissionId +"/preference/"+ deliveryPreference);
		};

		service.getSubmissionDeliveryPreferences = function( applicationId ) {
			return $http.get( archApiUrl + "application/" + applicationId + "/document-delivery-preference" ).then( function ( result ) {
				return { error: false, data: result.data };
			} ).catch( function ( err ) {
				return { error: true, status: err.status };
			} );
		};

		service.saveSubmissionDeliveryPreferences = function( submissionId, docDeliveryConfig ) {
			var postUrl = archApiUrl + "application/submission/" + submissionId + "/document-delivery-preference";
			return $http.post( postUrl, docDeliveryConfig ).then( function ( result ) {
				return { error: !result.data.success }
			} ).catch( function ( err ) {
				var errorMessages = [];
				// Only email-related insured preferences undergo validation
				if( err.data && err.data.insured ) {
					angular.forEach( err.data.insured, function( errorObj ) {
						errorMessages.push( errorObj.message );
					});
				}
				return { error: true, errorMessages: errorMessages }
			} );
		};

		// Returns a noun that describes a submission result by its portal status
		service.getResultType = function( status ) {
			switch( status ) {
				case "Bound":
					return "Binder";
					break;
				case "Declined":
					return "Declination";
					break;
				case "Issued":
					return "Policy";
					break;
				case "Quoted":
					return "Quote";
					break;
				case "Submitted":
					return "Submission";
					break;
				default:
					return status;
					break;
			}
		};

		service.getAutoOutcomeResult = function( applicationId, editingApplication, isWizardVisible ) {
			// The wizard process emulates an edit, so only go by the edit status when the wizard is not active/visible
			if( editingApplication && !isWizardVisible ) {
				return $q.when( {} );
			} else {
				return $http.get( archApiUrl + "application/submission/" + applicationId + "/auto-outcome").then( function( result ) {
					return result.data;
				});
			}
		};

        service.changeBillType = function (submissionId, billingplan, billingType) {
            var data = {
                submissionId: submissionId,
                billingplan: billingplan,
                billingType: billingType
            };

            return $http.post(archApiUrl + "application/submission/" + submissionId + "/billing-type", data).then(function (result) {
                return result.status;
            });


        };

		// Used to retrieve contextual data about a submission, such as agency details and the underwriter involved
		service.getSubmissionContextData = function( applicationId ) {
			return $http.get( archApiUrl + "application/submission/" + applicationId + "/context" ).then( function ( result ) {
				return { error: false, data: result.data };
			} ).catch( function ( err ) {
				return { error: true, status: err.status };
			} );
		};

		service.getSubmissionStatus = function( luxonId, luxonPartitionKey, modifiedDate ) {
			var data = {
				luxon_id : luxonId,
				partition_key: luxonPartitionKey ,
				modified_date : modifiedDate
			}
			return $http.post( "lx/status", data).then(function (result) {
				return result.data;
			});
		};

		return service;
	}

	return ResultService;
});
