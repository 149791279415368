define('components/specialtyApplication/search/controller/searchController',['require'],function(require) {
	"use strict";

	searchController.$inject = ["$scope"];

	function searchController( $scope ) {
        $scope.product_search_location = "search"
	}

	return searchController;
});
