define('components/widget/optional-endorsements/owner-officer-information/app',['require','components/widget/optional-endorsements/owner-officer-information/directive/ownerOfficerInformation','components/widget/optional-endorsements/owner-officer-information/controller/OwnerOfficerInformationController'],function(require) {
    "use strict";

    var ownerOfficerInformation 		= require("components/widget/optional-endorsements/owner-officer-information/directive/ownerOfficerInformation"),
        OwnerOfficerInformationController = require("components/widget/optional-endorsements/owner-officer-information/controller/OwnerOfficerInformationController");

    var component 				                = angular.module("mPortal.components.widget.optional-endorsements.owner-officer-information", []);
    component.directive( "ownerOfficerInformation", ownerOfficerInformation );
    component.controller( "OwnerOfficerInformationController", OwnerOfficerInformationController );

    return component;

});
