define('common/service/specialtyApplication/ApplicationSectionViewConfiguration',['require','components/applicationData/applicationData/config/widgets/ViewLocationInformationWidget','components/applicationData/applicationData/config/widgets/ViewExclusionsWidget','components/applicationData/applicationData/config/widgets/ViewPolicyInformationWidget','components/applicationData/applicationData/config/widgets/ViewInsuredInfoWidget','components/applicationData/applicationData/config/widgets/ViewAdditionalInsuredWidget','components/applicationData/applicationData/config/widgets/ViewPolicyOEsAndAIsWidget','components/applicationData/applicationData/config/widgets/ViewBuildingOEsAndAIsWidget'],function( require ) {
    'use strict';

    // Use require statements below this comment to pull in any applicationData widget configurations
    // Example:
    // var PropertyWidget          = require("components/applicationData/applicationData/config/widgets/PropertyWidget");

	var ViewLocationInformationWidget = require("components/applicationData/applicationData/config/widgets/ViewLocationInformationWidget");
    var ViewExclusionsWidget = require("components/applicationData/applicationData/config/widgets/ViewExclusionsWidget");
    var ViewPolicyInformationWidget = require("components/applicationData/applicationData/config/widgets/ViewPolicyInformationWidget");
    var ViewInsuredInfoWidget = require("components/applicationData/applicationData/config/widgets/ViewInsuredInfoWidget");
	var ViewAdditionalInsuredWidget = require("components/applicationData/applicationData/config/widgets/ViewAdditionalInsuredWidget");
    var ViewPolicyOEsAndAIsWidget = require("components/applicationData/applicationData/config/widgets/ViewPolicyOEsAndAIsWidget");
    var ViewBuildingOEsAndAIsWidget = require("components/applicationData/applicationData/config/widgets/ViewBuildingOEsAndAIsWidget");

    function ApplicationSectionViewConfiguration() {

        /*
         Populate the sectionConfigs object with key/values pairs where the key reflects the section widget reference
         (not the name) and the value is the configuration object returned by the corresponding widget's
         getStructure() method.
         Example:
         var viewConfigs = {
            "Property"              : PropertyWidget.getStructure()
         };

        */

        var viewConfigs = {
	        "viewLocation": ViewLocationInformationWidget.getStructure(),
            "Location": ViewLocationInformationWidget.getStructure(),
            "Building": ViewLocationInformationWidget.getStructure(),
	        "viewPropertyCoverages": ViewLocationInformationWidget.getStructure(),
	        "viewPropAddInterestInfo": ViewLocationInformationWidget.getStructure(),
	        "viewSectionBuildingCoverage": ViewLocationInformationWidget.getStructure(),
	        "viewSectionContentsCoverage": ViewLocationInformationWidget.getStructure(),
	        "viewSectionTIBCoverage": ViewLocationInformationWidget.getStructure(),
	        "viewSectionBICoverage": ViewLocationInformationWidget.getStructure(),
	        "viewBuilding": ViewLocationInformationWidget.getStructure(),
	        "viewLocationInformation": ViewLocationInformationWidget.getStructure(),
	        "viewEligibility": ViewExclusionsWidget.getStructure(),
	        "viewPolicyInformation": ViewPolicyInformationWidget.getStructure(),
	        "Policy Information": ViewPolicyInformationWidget.getStructure(),
	        "viewInsuredInfo": ViewInsuredInfoWidget.getStructure(),
	        "viewAdditionalInsured" : ViewAdditionalInsuredWidget.getStructure(),
            "bop_PolicyOEsAndAIs": ViewPolicyOEsAndAIsWidget.getStructure(),
            "bop_BuildingOEsAndAIs": ViewBuildingOEsAndAIsWidget.getStructure(),
	        "viewExcessLiability": ViewPolicyInformationWidget.getStructure(),
	        "viewWCInfoForUmb": ViewPolicyInformationWidget.getStructure(),
	        "GeneralLiability":ViewPolicyInformationWidget.getStructure(),
	        "Property":ViewPolicyInformationWidget.getStructure(),
	        "viewLimitedMoldLiabilitySection" : ViewPolicyInformationWidget.getStructure(),
            "viewCondoCoverageOptionalCoverageSection": ViewBuildingOEsAndAIsWidget.getStructure()

        };

        return viewConfigs;
    }

    return ApplicationSectionViewConfiguration;
});

