define('components/widget/optional-endorsements/photography-make-model/app',['require','components/widget/optional-endorsements/photography-make-model/directive/photographyMakeModel','components/widget/optional-endorsements/photography-make-model/controller/photographyMakeModelController'],function(require) {
    "use strict";

    var photographyMakeModel 		    = require("components/widget/optional-endorsements/photography-make-model/directive/photographyMakeModel"),
        photographyMakeModelController 	= require("components/widget/optional-endorsements/photography-make-model/controller/photographyMakeModelController");

    var component 					= angular.module("mPortal.components.widget.optional-endorsements.photography-make-model", []);

    component.directive("photographyMakeModel", photographyMakeModel);
    component.controller("photographyMakeModelController", photographyMakeModelController);

    return component;

});
