define('common/service/productSearch/ProductSearchService',['require'],function(require) {
	"use strict";

	ProductSearchService.$inject = ["$http","$sce","archApiUrl"];

	function ProductSearchService( $http, $sce, archApiUrl ) {

		var service = {};

		service.getStates = function(){
			var states = [
							{ id: "AL", label : "Alabama"},
							{ id: "AK", label : "Alaska"},
							{ id: "AZ", label : "Arizona"},
							{ id: "AR", label : "Arkansas"},
							{ id: "CA", label : "California"},
							{ id: "CO", label : "Colorado"},
							{ id: "CT", label : "Connecticut"},
							{ id: "DE", label : "Delaware"},
							{ id: "DC", label : "District Of Columbia"},
							{ id: "FL", label : "Florida"},
							{ id: "GA", label : "Georgia"},
							{ id: "HI", label : "Hawaii"},
							{ id: "ID", label : "Idaho"},
							{ id: "IL", label : "Illinois"},
							{ id: "IN", label : "Indiana"},
							{ id: "IA", label : "Iowa"},
							{ id: "KS", label : "Kansas"},
							{ id: "KY", label : "Kentucky"},
							{ id: "LA", label : "Louisiana"},
							{ id: "ME", label : "Maine"},
							{ id: "MD", label : "Maryland"},
							{ id: "MA", label : "Massachusetts"},
							{ id: "MI", label : "Michigan"},
							{ id: "MN", label : "Minnesota"},
							{ id: "MS", label : "Mississippi"},
							{ id: "MO", label : "Missouri"},
							{ id: "MT", label : "Montana"},
							{ id: "NE", label : "Nebraska"},
							{ id: "NV", label : "Nevada"},
							{ id: "NH", label : "New Hampshire"},
							{ id: "NJ", label : "New Jersey"},
							{ id: "NM", label : "New Mexico"},
							{ id: "NY", label : "New York"},
							{ id: "NC", label : "North Carolina"},
							{ id: "ND", label : "North Dakota"},
							{ id: "OH", label : "Ohio"},
							{ id: "OK", label : "Oklahoma"},
							{ id: "OR", label : "Oregon"},
							{ id: "PA", label : "Pennsylvania"},
							{ id: "RI", label : "Rhode Island"},
							{ id: "SC", label : "South Carolina"},
							{ id: "SD", label : "South Dakota"},
							{ id: "TN", label : "Tennessee"},
							{ id: "TX", label : "Texas"},
							{ id: "UT", label : "Utah"},
							{ id: "VT", label : "Vermont"},
							{ id: "VA", label : "Virginia"},
							{ id: "WA", label : "Washington"},
							{ id: "WV", label : "West Virginia"},
							{ id: "WI", label : "Wisconsin"},
							{ id: "WY", label : "Wyoming"}
						];

			return states;
		};

		service.getBusinessType= function(args){
			return $http.get( archApiUrl + "product-search/business-type/" + args.businessTypeID).then(function( result ) {	
						return result.data;
					});
	
		};

		service.getBusinessTypeDescriptions = function(){
			return $http.get( archApiUrl + "product-search/business-type-descriptions/").then(function( result ) {	

							angular.forEach(result.data,function(obj,k){
								result.data[k]['label'] = obj.display;
							});

						return result.data;
					});
	
		};

		service.getBusinessTypeFilters = function(args){
			return $http.get( archApiUrl + "product-search/business-type-filters/" + args.businessTypeID).then(function( result ) {	
						return result.data;
					});
	
		};

		service.getBusinessTypeProducts = function(args){
			return $http.get( archApiUrl + "product-search/business-type-products/" + args.businessTypeID).then(function( result ) {	
						return result.data;
					});
	
		};

		service.getBusinessTypeRules = function(args){
			return $http.get( archApiUrl + "product-search/business-type-rules/" + args.businessTypeID).then(function( result ) {	
						return result.data;
					});
	
		};

		service.checkClassCodeEligibility = function(effectiveDate,state,eligibility){
			return $http({
									method: "POST",
									url:  archApiUrl + "product-search/check-classcode-eligibility/", 
									data:{
											effectiveDate: effectiveDate,
											state : state,
											eligibility : eligibility
										}
						}).then(function(result){
									return result.data;
						});
		};

		service.checkProgramEligibility = function(effectiveDate,state,programs){
			return $http({
									method: "POST",
									url:  archApiUrl + "product-search/check-program-eligibility/", 
									data:{
											effectiveDate: effectiveDate,
											state : state,
											programs : programs
										}
						}).then(function(result){
									return result.data;
						});
		};

		service.search_descriptions = function(search_words,descriptions,search_type,matched_desc){
			var search_regex_str	= '^';


			//search results
			var results 			= {
											matches  		: [],
											matched_desc  	: (!matched_desc)?{}:matched_desc
									  };
			
			for(var i = 0; i < search_words.length; i++)
			{
				search_regex_str+= '(?=.*?(' + search_words[i] + '))';
			}

			search_regex_str+='.*$';

			var search_word_regex = new RegExp( search_regex_str );

			for(var i = 0; i < descriptions.length; i++)
			{
				var description = descriptions[i];
				var search_value = (search_type != 'tags')?description.label.toLowerCase():description.tags.join("|").toLowerCase();

				if(!results.matched_desc[description.id])
				{
					if( search_word_regex.test( search_value ) ) 
					{
						results.matches.push( description );
						results.matched_desc[ description.id ] = true;
					}
				}
			}

			return results;
		};

		/*
		Used by the product search autocomplete to find business type descriptions based on the provided search phrase
		*/
		service.getMatchingDescriptions = function( descriptions, searchPhrase ) {
			var search_phrase 		= searchPhrase.replace(/\sand\s|\sor\s/gi," ").trim().toLowerCase();
			var search_words  		= search_phrase.split(" ");
			
			//search entire phrase
			var results = service.search_descriptions(search_words,descriptions,'description');

			if(results.matches.length == 0 && search_words.length > 1)
			{
				//no results, but we have more than 1 word, just search the first
				results = service.search_descriptions([search_words[0]],descriptions,'description');
			}

			//sort our listing by earliest occurence of the first word
			results.matches = results.matches.sort( function( a, b ) { 	
																		var label_a = a.label.replace(/\sand\s|\sor\s/gi," ").trim().toLowerCase().indexOf(search_words[0]);
																		var label_b = b.label.replace(/\sand\s|\sor\s/gi," ").trim().toLowerCase().indexOf(search_words[0]);

																		
																	 	if (label_a < label_b) 
														                    return -1; 
														                if (label_a > label_b) 
														                    return 1; 
														                return 0; 
																	 });

			//now we have results er lets search the descriptions that weren't found tags
			var tag_results = service.search_descriptions([search_words[0]],descriptions,'tags',results.matched_desc);
			
			var matches 	= results.matches.concat(tag_results.matches);

			if(!search_phrase.length)
			{
				matches.sort( function( a, b ) { return a.label.toLowerCase().localeCompare( b.label.toLowerCase() ) } );
			}
			
			return matches;
		};

		return service;

	}

	return ProductSearchService;

});
