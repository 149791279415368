define( 'components/specialtyApplication/submit/programDetails/miscErrorsOmissions/app',['require','components/specialtyApplication/submit/programDetails/miscErrorsOmissions/directive/miscErrorsOmissions','components/specialtyApplication/submit/programDetails/miscErrorsOmissions/controller/MiscErrorsOmissionsController','moment'],function( require ) {
	"use strict";

	var miscErrorsOmissions = require( "components/specialtyApplication/submit/programDetails/miscErrorsOmissions/directive/miscErrorsOmissions" ),
		MiscErrorsOmissionsController = require( "components/specialtyApplication/submit/programDetails/miscErrorsOmissions/controller/MiscErrorsOmissionsController"),
		moment = require( "moment" );

	var component = angular.module( "mPortal.components.specialtyApplication.submit.programDetails.miscErrorsOmissions", [] );

	component.directive( "specialtyApplicationReviewMiscEo", miscErrorsOmissions );
	component.controller( "MiscErrorsOmissionsController", MiscErrorsOmissionsController );
	component.value( "moment", moment );

	return component;

});
