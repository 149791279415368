define('components/accountSummary/quoteSummary/directive/quoteSummary',['require'],function(require) {
    "use strict";

    function QuoteSummary() {
        return {
            restrict: "AE",
            scope: {
                quote: "=",
                account: "=",
                openTab: "=",
                setMessageView: "=",
                user: "=",
                openApplicationTab: "=",
                showFutureControls: "=" // For development purposes
            },
            templateUrl: "app/components/accountSummary/quoteSummary/template/template.html",
            controller: "QuoteSummaryController"
        };
    }

    return QuoteSummary;

});
