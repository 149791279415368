define('components/marketedPrograms/list/directive/marketedProgramList',['require'],function(require) {
	"use strict";

	MarketedProgramList.$inject = [];

	function MarketedProgramList() {
		return {
			restrict: "AE",
			scope: {
				programs: "=",
				currentProgramId: "=",
				onSelect: "="
			},
			templateUrl: "app/components/marketedPrograms/list/template/template.html"
		};
	}

	return MarketedProgramList;

});
