define('common/service/insuredRegistration/InsuredRegistrationService',['require'],function(require) {
	"use strict";

	InsuredRegistrationService.$inject = ["$http", "archApiUrl"];

	function InsuredRegistrationService( $http, archApiUrl ) {

		var service = {};

		service.verifyAccount = function( accountId, zipCode, honeyPot, email ) {
			return $http.post( archApiUrl + "account/verify", {
				accountId: accountId,
				zipCode: zipCode,
				honeyPot: honeyPot,
				email: email
			});
		};

		service.getSecurityQuestions = function() {
			return $http.get( archApiUrl + "insured-user/security-questions" ).then(function( result ) {
				return result.data;
			});
		};

		service.createUser = function( userData ) {
			return $http.post( archApiUrl + "insured-user", userData );
		};

		service.addAccount = function( accountId, zipCode ) {
			return $http.post( archApiUrl + "insured-user/add-account", {
				accountId: accountId,
				zipCode: zipCode
			});
		};

		return service;

	}

	return InsuredRegistrationService;

});
