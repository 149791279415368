define('nav/controller/NavController',['require'],function (require) {
	"use strict";

	NavController.$inject = [
		"$scope",
		"$window",
		"$location",
		"$route",
		"$timeout",
		"overmindScope",
		"NavService",
		"UserService",
		"UserProfile",
		"Wizard",
		"moment",
		"ciamApiUrl"
	];

	function NavController(
		$scope,
		$window,
		$location,
		$route,
		$timeout,
		overmindScope,
		NavService,
		UserService,
		UserProfile,
		Wizard,
		moment,
		ciamApiUrl
	) {

		// because route changes are triggered outside of this controller, we need to watch
		// overmindScope for route changes and then trigger a digest.
		overmindScope.$on("$routeChangeSuccess", function () {
			$scope.$apply();
			$scope.showSearchContainer = false;
			if ($location.path() == '/') Wizard.reset();
		});

		$scope.searchText = "";
		overmindScope.$on('open-global-search', function (opt1, text) {
			$scope.searchText = text;
			$scope.showSearch();
			$scope.$apply();
		});
		$scope.initialized = false;
		$scope.user = UserProfile;
		$scope.isActive = NavService.isActive;
		$scope.layout = NavService.getLayout();
		$scope.showUserFlyout = false;
		$scope.showSearchContainer = false;

		$scope.open_survey = function () {
			window.open("https://markelcorp.az1.qualtrics.com/jfe/form/SV_elEo6Qu6iLJAsiq", "Agency Survey", "width=400, height=700");
			return false;
		};

		$scope.logout = function () {
			UserService.logout().then(function () {
				$window.location = ciamApiUrl + 'auth/login-request/mportal';
			});
		};

		$scope.redirectToResources = function () {
			$location.path('/training');

			// Use $timeout to wait until the view has fully loaded
			$timeout(function () {
				// Scroll to the element with the specified ID
				var targetElement = document.getElementById('producer-resources');
				if (targetElement) {
					targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
			}, 500);  // Adjust timeout as necessary
		};


		$scope.showFlyout = function () {
			$scope.showUserFlyout = !$scope.showUserFlyout;
		}
		$scope.showSearch = function () {

			$scope.showSearchContainer = true;
		}
		$scope.hideSearch = function () {
			$scope.searchText = "";
			$scope.showSearchContainer = false;
		}

		$scope.isChatOnline = function () {

			var centralTime = moment().tz('America/Chicago');

			// chat is available weekdays between 8 am and 5 pm central time
			return [0, 6].indexOf(centralTime.day()) === -1 &&
				centralTime.hour() >= 8 &&
				centralTime.hour() < 17;

		};

		// used for legacy sections that require the user be able to reload the current route by clicking the current nav item
		$scope.conditionalReload = function (path) {

			if ($scope.isActive(path)) {
				$route.reload();
			}

		};

		$scope.openLiveChat = function () {

			var liveChatWindow = $window.open(
				"https://c.velaro.com/visitor/requestchat.aspx?siteid=9553&showwhen=inqueue&secure=yes",
				"OnlineChatSoftware",
				"toolbar=no,location=no,directories=no,menubar=no,status=no,scrollbars=no,resizable=yes,replace=no"
			);

			liveChatWindow.focus();
			liveChatWindow.opener = $window;

		};

		$scope.getMainAgencyName = function() {
			if (!UserProfile.agencies || UserProfile.agencies.length === 0) {
				return; 
			}

			if(UserProfile.agencyEmulation === true){
				return UserProfile.agencies[0].name;
			}
			
			var agency = UserProfile.agencies.find(function( agency ) {
				return agency.isMain;
			});

			if( !agency ) {
				return;
			}

			return agency.name;
		};
		
		$scope.isOnPage = function (route) {
			return new RegExp("^" + route).test($location.path())
		};

		$scope.initialized = true;
	}

	return NavController;

});
