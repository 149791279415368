define('components/message/modal/controller/MessageModalController',['require'],function(require) {
	"use strict";

	MessageModalController.$inject = ["$scope", "$modalInstance", "$sce", "$window", "$modal", "$location", "MessageService", "maverickApiUrl", "message"];

	function MessageModalController( $scope, $modalInstance, $sce, $window, $modal, $location, MessageService, maverickApiUrl, message ) {
		$scope.message = message;
		$scope.trusted = {
			subject: $sce.trustAsHtml( $scope.message.subject ),
			message: $sce.trustAsHtml( $scope.message.message )
		};

		$scope.form = {
			message: "",
			upload: []
		};

		$scope.status = {
			sending: false
		};

		$scope.close = $modalInstance.close;

		$scope.goToThread = function() {
			$location.path( $scope.message["thread-link"].replace("#", "") );
			$scope.close();
		};

		$scope.openFileUpload = function() {
			var formAction = maverickApiUrl + "messages/uploadMessageAttachment";
			var fileUploadModal = $modal.open({
				template: "<div upload-attachment upload-action='\"" + formAction + "\"'></div>",
				controller: "GenericModalController"
			});

			fileUploadModal.result.then(function( fileName ) {
				$scope.form.upload.push( fileName );
			});
		};

		$scope.send = function() {
			if( !$scope.form.message ) {
				$window.alert("Please fill in the Message field.");
				return;
			}

			$scope.status.sending = true;

			MessageService.sendMessage({
				system			: 'maverick',
				entityId 		: $scope.message["quote-id"],
				claimId 		: $scope.message["claim-id"],
				parentMessageId	: $scope.message["reply-id"],
				uploadDocuments	: $scope.form.upload.join(","),
				message			: $scope.form.message,
				subject 		: $scope.message.subject
			}).then(function( response ) {
				$scope.status.sending = false;

				$modal.open({
					templateUrl: "app/components/message/modal/template/sent.html",
					controller: "GenericModalController"
				});

				$scope.close();
			});
		};
	}

	return MessageModalController;

});


