define('components/applicationData/applicationData/config/widgetFormlyConfiguration',['require','components/applicationData/applicationData/config/widgets/ViewLocationInformationWidget','components/applicationData/applicationData/config/widgets/ViewExclusionsWidget','components/applicationData/applicationData/config/widgets/ViewPolicyInformationWidget','components/applicationData/applicationData/config/widgets/ViewInsuredInfoWidget','components/applicationData/applicationData/config/widgets/ViewAdditionalInsuredWidget','components/applicationData/applicationData/config/widgets/ViewPolicyOEsAndAIsWidget','components/applicationData/applicationData/config/widgets/ViewBuildingOEsAndAIsWidget'],function(require) {
    "use strict";

    // Use require statements below this comment to add any tag-based directives used in a Formly wrapper
    // or type defined in Section 1. Widget files for views should be stored in the applicationData/config/widgets folder

	var ViewLocationInformation = require("components/applicationData/applicationData/config/widgets/ViewLocationInformationWidget" );
	var ViewExclusions =  require("components/applicationData/applicationData/config/widgets/ViewExclusionsWidget" );
	var ViewPolicyInformationWidget =  require("components/applicationData/applicationData/config/widgets/ViewPolicyInformationWidget" );
	var ViewInsuredInfoWidget =  require("components/applicationData/applicationData/config/widgets/ViewInsuredInfoWidget" );
    var ViewAdditionalInsuredWidget = require("components/applicationData/applicationData/config/widgets/ViewAdditionalInsuredWidget");
    var ViewPolicyOEsAndAIsWidget = require("components/applicationData/applicationData/config/widgets/ViewPolicyOEsAndAIsWidget");
    var ViewBuildingOEsAndAIsWidget = require("components/applicationData/applicationData/config/widgets/ViewBuildingOEsAndAIsWidget");

    return function( formlyConfig, formlyApiCheck ) {

        // Step 1: Configurations for any Formly types and wrappers that are used by more than one widget
        formlyConfig.setType({  // When we don't even want the datapoint to exist
            name: "exclude",
            template: '',
            defaultOptions: {
                noFormControl: true
            }
        });

        formlyConfig.setType({ // When we want to start with just the value then wrap it with formatting and its label
            name: "valueOnly",
            template: '{{::options.defaultValue}}',
            defaultOptions: {
                noFormControl: true
            }
        });

        formlyConfig.setType({
            name: "omittedHeader",
            template: '',
            defaultOptions: {
                noFormControl: true
            }
        });

        formlyConfig.setWrapper({ // For putting a dollar sign in front of the type/wrapper output
            name: "moneyValue",
            template: '<span ng-if="options.defaultValue">$</span><formly-transclude></formly-transclude>'
        });



        // Step 2: Invoke configureFormly() for each widget that contains widget-specific Formly types

        ViewLocationInformation.configureFormly(formlyConfig, formlyApiCheck);
        ViewExclusions.configureFormly(formlyConfig, formlyApiCheck);
	    ViewPolicyInformationWidget.configureFormly(formlyConfig, formlyApiCheck);
	    ViewInsuredInfoWidget.configureFormly(formlyConfig, formlyApiCheck);
        ViewAdditionalInsuredWidget.configureFormly(formlyConfig, formlyApiCheck);
        ViewPolicyOEsAndAIsWidget.configureFormly(formlyConfig, formlyApiCheck);
        ViewBuildingOEsAndAIsWidget.configureFormly(formlyConfig, formlyApiCheck);
    };

});
