define('components/policiesSearch/directive/policiesSearch',['require'],function(require) {
	"use strict";

	function PoliciesSearch() {
		return {
			restrict: "AE",
			scope: {
				searchDataResults: "="
			},
			templateUrl: "app/components/policiesSearch/template/template.html",
			controller: "PoliciesSearchController"
		};
	}

	return PoliciesSearch;

});
