define('components/widget/summary-of-equines/directive/summaryOfEquines',['require'],function(require){

   "use strict";

    summaryOfEquines.$inject = [];
     function summaryOfEquines(){

         return{

             restrict : "AE",
             scope: {
                 model                : "=",
                 options              : "=",
                 quantityControlId    : "="
             },
             templateUrl: "app/components/widget/summary-of-equines/template/template.html",
             controller: "SummaryOfEquinesController"

         };
     }
     return summaryOfEquines
});


