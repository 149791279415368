define( 'common/service/specialtyApplication/ResumeService',['require'],function (require) {
    "use strict";

    ResumeService.$inject = ["$http", "archApiUrl"];

    function ResumeService( $http, archApiUrl ) {
        var service = {};

        service.verifyResumeAttempt = function( applicationId, zipCode ) {
            return $http.post( archApiUrl + "application/submission/" + applicationId + "/resume", { applicationId: applicationId, zip: zipCode } ).then( function ( result ) {
                return {
                    error: false,
                    incomplete: result.data.incomplete
                }
            } ).catch( function ( err ) {
                return {
                    error: true,
                    message: err.data.message
                }
            } );
        };

        return service;
    }

    return ResumeService;
});
