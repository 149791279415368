define('components/submissionDetail/submissionDetail/directive/submissionDetail',['require'],function(require) {
	"use strict";

	function SubmissionDetail() {
		return {
			restrict: "AE",
			scope: {
				submission: "=",
				account: "=",
				allSubmissions: "=",
				openSubmissionTab: "=",
				setMessageView: "="
			},
			templateUrl: "app/components/submissionDetail/submissionDetail/template/template.html"
		};
	}

	return SubmissionDetail;

});
