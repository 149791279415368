define( 'components/specialtyApplication/submit/programDetails/miscErrorsOmissions/controller/MiscErrorsOmissionsController',['require'],function( require ) {
	"use strict";

	MiscErrorsOmissionsController.$inject = [ "$scope", "SubmissionStore" ];

	function MiscErrorsOmissionsController( $scope, SubmissionStore ) {

		$scope.programDetails = SubmissionStore.programs.filter( function ( program ) { return program.referenceString  === 'miscErrorsOmissions' } )[ 0 ];

		var effectiveDatePoint = {
			reportLabel: "Effective Date",
			value: $scope.programDetails.effectiveDate
		};

		var submissionIDPoint = {
			reportLabel: "Submission ID",
			value: SubmissionStore.submissionId
		};

		$scope.policyDatapoints = [];

		$scope.parsePolicyDatapoints = function() {
			$scope.policyDatapoints.push( submissionIDPoint );
			$scope.policyDatapoints.push( effectiveDatePoint );
		};

		$scope.parsePolicyDatapoints();
	}

	return MiscErrorsOmissionsController;

});
