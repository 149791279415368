define('components/policyDetail/policyBilling/app',['require','angular-utils-pagination','components/policyDetail/policyBilling/directive/policyBilling','components/policyDetail/policyBilling/controller/PolicyBillingController','components/addAccount/controller/AddAccountModalController','common/service/specialtyApplication/AccountService','components/policyDetail/policyBilling/filter/UnPaidDueFilter'],function(require) {
	"use strict";

    require("angular-utils-pagination");
	var policyBilling = require("components/policyDetail/policyBilling/directive/policyBilling"),
		PolicyBillingController = require("components/policyDetail/policyBilling/controller/PolicyBillingController"),
		AddAccountModalController = require("components/addAccount/controller/AddAccountModalController"),
		AccountService = require("common/service/specialtyApplication/AccountService"),
		UnPaidDueFilter = require("components/policyDetail/policyBilling/filter/UnPaidDueFilter");
    var component = angular.module("mPortal.policyDetail.policyBilling", [
        "angularUtils.directives.dirPagination"
    ]);

	component.directive("policyBilling", policyBilling);
	component.controller("PolicyBillingController", PolicyBillingController);
	component.controller("AddAccountModalController", AddAccountModalController);
	component.factory("AccountService", AccountService);
	component.filter( "UnPaidDueFilter", UnPaidDueFilter );

	return component;

});
