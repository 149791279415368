define('components/userAccountSettings/userCredentials/controller/UserCredentialsController',['require'],function(require) {
	"use strict";

	UserCredentialsController.$inject = [ "$scope", "MessageContainer", "UserService" ];

	function UserCredentialsController( $scope, MessageContainer, UserService ) {

		$scope.uiState = {
			visible: false,
			submitting: false,
			api: {
				available: $scope.showKey,
				keyVisible: false
			}
		};

		$scope.messages = new MessageContainer({
			success: false,
			errors: [],
			errorHeader: ""
		});

		$scope.showApiKey = function() {
			$scope.uiState.api.keyVisible = true;
		};

		$scope.save = function() {
			$scope.messages.reset();
			$scope.uiState.submitting = true;
			UserService.saveCredentials( $scope.user ).then( function( result ) {
				$scope.user.priorPassword = "";
				$scope.user.password = "";
				$scope.user.passwordConfirm = "";

				if( result.isSaved ) {
					if( $scope.user.newUserName ) {
						$scope.user.userName = $scope.user.newUserName;
						$scope.user.newUserName = "";
					}
					$scope.messages.success = true;
				} else {
					$scope.messages.errorHeader = "Please correct the following problems:";
					angular.forEach( result.errors, function( error ) {
						$scope.messages.errors.push( error );
					});
				}
				$scope.uiState.submitting = false;
			});
		};

	}

	return UserCredentialsController;

});
