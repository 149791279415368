define('components/widget/policy-information/coverage-table/app',['require','components/widget/policy-information/coverage-table/directive/coverageTable','components/widget/policy-information/coverage-table/controller/coverageTableController','common/service/SubmissionDataService'],function(require) {
	"use strict";

	var coverageTable 				= require("components/widget/policy-information/coverage-table/directive/coverageTable"),
		coverageTableController 	= require("components/widget/policy-information/coverage-table/controller/coverageTableController"),
		submissionDataService		= require("common/service/SubmissionDataService");

	var component 					= angular.module("mPortal.components.widget.policy-information.coverage-table", []);

	component.directive("coverageTable", coverageTable);
	component.controller("coverageTableController", coverageTableController);
	component.factory("SubmissionDataService", submissionDataService);

	return component;

});
