define( 'termsAndConditions/controller/TermsAndConditionsController',['require'],function ( require ) {
	"use strict";

	TermsAndConditionsController.$inject = ["$scope", "$sce", "PublicContentService", "UserProfile"];

	function TermsAndConditionsController( $scope, $sce, PublicContentService, UserProfile ) {

		$scope.user = UserProfile;

		if ( $scope.user.isLoggedIn === false ) {
			$scope.user.type = "agent";
		}

		if ( $scope.user.type.toLowerCase() === "insured" ) {
			PublicContentService.getInsuredTermsAndConditions().then( function ( result ) {
				$scope.terms = $sce.trustAsHtml( result.terms );
			} );
		} else { //if UserType = "agent"
			PublicContentService.getAgentTermsAndConditions().then( function ( result ) {
				$scope.terms = $sce.trustAsHtml( result.terms );
			} );
		}

	}

	return TermsAndConditionsController;

} );
