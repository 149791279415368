/**
 * This module exists to serve legacy routes that have not been (or will not be) converted to Angular. These legacy routes invoke
 * a jQuery plugin, superman, which can be found in the REX SVN repo along with all of the legacy applications themselves.
 */
define('legacyRoutes/app',['require','shim/overmind','common/service/wizard/Wizard'],function(require) {
	"use strict";

	var overmind = require("shim/overmind");
	var wizard = require("common/service/wizard/Wizard");

	var app = angular.module( "mPortal.legacyRoutes", [] );

	app.factory('Wizard', wizard);

	app.config( overmind.control() );


	app.config(["$routeProvider", function( $routeProvider ) {
		$routeProvider
			.when("/lx/:params*", {
				template: " ",
				controller: ["$routeParams", "Wizard", function( $routeParams, Wizard ) {
					let wizard_data = Wizard.data.entries['id-0'];
					let data        = null;
					let defaults    = {};


					function map_value(defaults,product)
					{
						for(let j = 0; j < product.dataPoints.length; j++)
						{
							let field = product.dataPoints[j];

							if(field.keyname.indexOf('.') > 0)
							{
								let keys   = field.keyname.split('.');
								let value  = (field.value === "true" || field.value === "false")?Boolean(field.value):field.value;
								let root   = defaults;

								for(let i = 0; i < keys.length; i++)
								{
									let key = keys[i];

									if(!root[key])
									{
										root[key] = {};
									}

									if(i == keys.length - 1)
									{
										root[key] = value;
									}

									root = root[key];
								}

								if(keys.length === 0)
								{
									root[field.keyname] = value;
								}
							}
						}

						return defaults;
					}

					if(wizard_data && wizard_data.insured != null)
					{
						let arr = wizard_data.luxon.arr;
						
						for(let i=0; i < arr.length;i++)
						{
							let product = wizard_data.luxon[arr[i]];
								defaults = map_value(defaults,product);
						}

						data = {
									insured: wizard_data.insured,
									state: wizard_data.data.state,
									effectivedate: wizard_data.data.effectivedate,
									filters: wizard_data.data,
									dataPoints: [],
									agentId: wizard_data.store.agentId,
									agencyId: wizard_data.data.agencyId,
									agencies: wizard_data.agencies,
									btype_description: wizard_data.data.btype_description,
									naics: wizard_data.data.naics,
									defaults : defaults
								};
					}

					
					
					let params 		  = $routeParams.params.split('/');
					
					let submission_id = params[2];
					let partition_key = params[1];

					let luxon = { 
									id:  submission_id,
									partition_key: partition_key,
									data: data,
									urlUpdated: false 
								};


					localStorage.setItem('luxon', JSON.stringify(luxon));

					fetch("/lx/include-manifest.json")
					.then(response => response.json())
					.then((json) => {
						let scriptSrc = json.includes[0].Content.replace('{{rootPath}}', '/lx').split('"')[1];
						let element = json.includes[1].Content;
						let elm = document.getElementById('tmplMainContent');
						elm.innerHTML = element;
						var script = document.createElement('script');
						script.src = scriptSrc;
						document.head.appendChild(script);});
				}]
			})



		$routeProvider
			.when("/quote/:param?", {
				template: " ",
				controller: ["$routeParams", function( $routeParams ) {
					// param will be either an empty string, startQuote, or a quote ID.
					// we don't want to pass along "startQuote" as the quote ID because it breaks quoteDirective.
					var quoteId = $routeParams.param !== "startClean" ? $routeParams.param : "";
					$.superman( "agencyQuoteDirective", "preSetup", quoteId );
				}]
			})
			.when("/renewal/:params*", {

				template: " ",
				controller: ["$routeParams", function( $routeParams ) {
					// agencyRenewalDirective.setup takes a path with an arbitrary number of params and parses it out.
					$.superman( "agencyRenewalDirective", "setup", { path: "#/renewal/" + $routeParams.params });
				}]
			})	
			.when("/account/:params*", {
				template: " ",
				controller: ["$routeParams", function( $routeParams ) {
					// agencyAccountDirective.setup takes a path with an arbitrary number of params and parses it out.
					$.superman( "agencyAccountDirective", "setup", { path: "#/account/" + $routeParams.params });
				}]
			})

			.when("/survey/:surveyId", {
				template: " ",
				controller: ["$routeParams", function( $routeParams ) {
					$.superman( "agencySurveyDirective", "getSurvey", $routeParams.surveyId );
				}]
			})

			.when("/about", {
				template: " ",
			 	controller: function() {
					$.superman( "agencyPubliccontentDirective", "setup" );
					$.superman( "agencyPubliccontentDirective", "about" );
				}
			})

			.when("/termsAndConditions", {
				template: " ",
			 	controller: function() {
					$.superman( "agencyPubliccontentDirective", "setup" );
					$.superman( "agencyPubliccontentDirective", "termsAndConditions" );
				}
			})

			.when("/privacyPolicy", {
				template: " ",
			 	controller: function() {
					$.superman( "agencyPubliccontentDirective", "setup" );
					$.superman( "agencyPubliccontentDirective", "privacyPolicy" );
				}
			})

			.when("/page/:tableId/:page", {
				template: " ",
				controller: ["$routeParams", function( $routeParams ) {
					$.rexUtil.tablePageChange( $routeParams.tableId, "page" + $routeParams.page );
					$.rexUtil.tableMakePagination( $routeParams.tableId, $routeParams.page );
				}]
			});

	}]);

	return app;

});
