define('components/twoYearView/twoYearView/directive/twoYearView',['require'],function(require) {
	"use strict";

	TwoYearView.$inject = [];

	function TwoYearView() {
		return {
			restrict: "AE",
			scope: true,
			templateUrl: "app/components/twoYearView/twoYearView/template/template.html",
			controller: "TwoYearViewController"
		};
	}

	return TwoYearView;

});
