define('training/app',['require','common/config/config','shim/overmind','training/Controller/RequestTrainingController'],function(require) {
    "use strict";

    require("common/config/config");
    var overmind = require("shim/overmind"),
        RequestTrainingController = require("training/Controller/RequestTrainingController");

    var app = angular.module("mPortal.training", [
        "mPortal.config"
    ]);

    app.config( overmind.control() );
    app.controller("RequestTrainingController", RequestTrainingController);

    app.config(["$routeProvider", function( $routeProvider ) {
        $routeProvider.when("/training/", {
            templateUrl: "app/training/template/template.html",
            controller: "RequestTrainingController"
        });
    }]);

    return app;

});
