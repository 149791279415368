define('components/accountSummary/quoteTable/app',['require','components/paymentLink/app','components/lossRuns/app','components/editSubmission/app','components/documentDeliveryPreferencesLink/app','components/accountSummary/quoteTable/directive/quoteTable','components/accountSummary/quoteTable/controller/QuoteTableController','common/service/LossRunsService','common/service/user/UserProfile','moment','common/service/specialtyApplication/SubmissionStore','common/service/specialtyApplication/ResultService','common/filter/formatMoney'],function(require) {
	"use strict";

	require("components/paymentLink/app");
	require("components/lossRuns/app");
	require("components/editSubmission/app");
	require("components/documentDeliveryPreferencesLink/app");

	var quoteTable = require("components/accountSummary/quoteTable/directive/quoteTable"),
		QuoteTableController = require("components/accountSummary/quoteTable/controller/QuoteTableController"),
		LossRunsService = require("common/service/LossRunsService" ),
		UserProfile = require( "common/service/user/UserProfile" ),
		moment = require("moment" ),
		SubmissionStore = require("common/service/specialtyApplication/SubmissionStore"),
		ResultService = require("common/service/specialtyApplication/ResultService" ),
		formatMoney = require("common/filter/formatMoney");

	var component = angular.module("mPortal.accountSummary.quoteTable", [
		"mPortal.paymentLink",
		"mPortal.editSubmission",
		"mPortal.documentDeliveryPreferencesLink"
	]);

	component.directive("quoteTable", quoteTable);
	component.controller("QuoteTableController", QuoteTableController);
	component.factory("LossRunsService", LossRunsService);
	component.factory("SubmissionStore", SubmissionStore);
	component.factory("ResultService", ResultService);
	component.factory( "UserProfile", UserProfile );
	component.filter("formatMoney", formatMoney);
	component.value("moment", moment);

	return component;

});
