define('common/directive/fileUpload',['require'],function(require) {
	"use strict";

	FileUpload.$inject = ["$window"];

	/**
	* This directive facilitates faux-AJAX file uploads by submitting the target element (a form) into a hidden iframe. The processing
	* code on the server handles the upload as necessary and then renders a view into the iframe that consists of some JS using the
	* window.postMessage API to send the response back to our directive. Once we've received that response, we fire off either the
	* onSuccess or onError callback depending on whether the server indicates the upload was successful. Both callbacks receive the
	* full JSON response from the server as an argument.
	*
	* In order to implement this on a form, the form must have an action that points to the appropriate server endpoint for handling the
	* file upload. The endpoint must also ultimately render a view that posts a valid message back to its parent window (see Arch's
	* views/fileUpload/iframe.gsp for an example).
	*/
	function FileUpload( $window ) {
		return {
			restrict: "A",
			scope: {
				onSuccess: "=fileUploadOnSuccess",
				onError: "=fileUploadOnError"
			},
			link: function( scope, element, attrs ) {
				if( element.prop("tagName").toLowerCase() !== "form" ) {
					throw new Error("FileUpload may only be applied to a form element.");
				}

				var target = "file-upload-" + Math.random(),
					iframe = jQuery("<iframe></iframe>", { name: target }).css({ display: "none" }),
					windowEl = angular.element( $window );

				element
					.attr("target", target)
					.attr("enctype", "multipart/form-data");

				element.append( iframe );

				element.on("submit", function() {
					windowEl.on("message", onComplete);
				});

				function onComplete( event ) {
					windowEl.off("message", onComplete);

					var data;

					try {
						data = JSON.parse( event.originalEvent.data );
					} catch( e ) {
						data = { success: false, exception: e, event: event };
					}
					
					if( data.success && scope.onSuccess ) {
						scope.$apply(function() {
							scope.onSuccess( data );
						});
					}

					if( !data.success && scope.onError ) {
						scope.$apply(function() {
							scope.onError( data );
						});
					}
				}
			}
		};
	}

	return FileUpload;

});
