define('common/service/EligibilityService',['require'],function(require) {
	"use strict";

	EligibilityService.$inject = ["$http", "maverickApiUrl"];

	function EligibilityService( $http, maverickApiUrl ) {

		var service = {};

		function transformClassCode( classCode ) {
			return {
				id: classCode.CLASSCODEID,
				value: "" + classCode.CLASSCODE,
				label: classCode.CLASSCODE + " " + classCode.CLASSCODEDESCRIPTION + "<span class=\"hide ccd-keywords\">" + classCode.KEYWORDS.join(", ") + "</span>",
				marketedProgramReferenceString: classCode.marketedProgramReferenceString,
				descriptionId: classCode.CLASSCODEDESCRIPTIONID
			};

		}

		function transformEOBusiness( classCode ) {
			return {
				id: classCode.ID,
				value: "" + classCode.DESCRIPTION,
				label: classCode.DESCRIPTION,
				descriptionId: classCode.ID,
				businessGroup: classCode.GROUP,
                descriptionKeyword: classCode.DescriptionKeyword,
                groupKeyword: classCode.GroupKeyword
			};
		}

		function transformEOGroups( classCode ) {
			return {
				value: classCode.GROUP,
				label: classCode.GROUP
			};
		}

		function transformEligibleGroups( result ) {
			var transformedGroups = angular.extend( {}, result.data, { eoGroups: [] ,eoDescriptions: []} );
			angular.forEach( result.data.eoGroups, function ( classCode ) {
				transformedGroups.eoGroups.push( transformEOGroups( classCode ) );
			} );
            angular.forEach( result.data.eoDescriptions, function ( classCode ) {
                transformedGroups.eoDescriptions.push( transformEOBusiness( classCode ) );
            } );
			return transformedGroups;
		}

		function transformEligibility( result ) {

			var transformedData = angular.extend({}, result.data, { states: [], wcClassCodes: [], bopClassCodes: [], eoGroups: [], eoDescriptions: [] });

			angular.forEach( result.data.states.DATA, function( state ) {
				transformedData.states.push({
					abbreviation: state[ 0 ],
					label: state[ 1 ]
				});
			});

			angular.forEach( result.data.eoGroups, function ( classCode ) {
				transformedData.eoGroups.push( transformEOGroups( classCode ) );
			});

			angular.forEach( result.data.workersCompClassCodes, function( classCode ) {
				transformedData.wcClassCodes.push( transformClassCode( classCode ) );
			});

			angular.forEach( result.data.bopClassCodes, function( classCode ) {
				transformedData.bopClassCodes.push( transformClassCode( classCode ) );
			});

			angular.forEach( result.data.eoDescriptions, function ( classCode ) {
				transformedData.eoDescriptions.push( transformEOBusiness( classCode ) );
			} );

			delete transformedData.workersCompClassCodes;

			return transformedData;

		}

		service.getEligibility = function( params ) {
			return $http.get( maverickApiUrl + "quote/getEligibility", { params: params }).then( transformEligibility );
		};

		service.checkEligibility = function( params ) {
			return $http.get( maverickApiUrl + "quote/checkEligibility", { params: params }).then(function( result ) {
				return result.data;
			});
		};

		service.getAvailableProductLines = function( state, effectiveDate ) {
			return $http.get( maverickApiUrl + "quote/getAvailableProductLinesForState", {
				params: {
					state: state,
					effectiveDate: effectiveDate
				}
			}).then(function( result ) {
				return result.data;
			});
		};

		service.getStateClassCodes = function( state, effectiveDate ) {
			return $http.get( maverickApiUrl + "quote/getClassCodeDescriptions", {
				params: {
					state: state,
					effectiveDate: effectiveDate,
				}
			}).then(function( result ) {
				var classCodes = {
					wcClassCodes: result.data.wcDescriptions.map( transformClassCode ),
					bopClassCodes: result.data.boDescriptions.map( transformClassCode ),
					eoDescriptions: result.data.eoDescriptions.map( transformEOBusiness ),
					eoGroups: result.data.eoGroups.map( transformEOGroups )
				};

				return classCodes;
			});
		};

		service.getEOGroupsBasedOnClassCode = function ( wcClassCodeId, boClassCodeId, state, effectiveDate ) {
			return $http.get( maverickApiUrl + "quote/getEOGroupsOnClassCode", {
				params: {
					wcClassCodeId: wcClassCodeId,
					boClassCodeId: boClassCodeId,
					state: state,
					effectiveDate: effectiveDate
				}
			} ).then( transformEligibleGroups )

		};

		service.getBOClassCodeDescriptionGuidelines = function( descriptionId, effectiveDate ) {
			return $http.get( maverickApiUrl + "quote/getBOClassCodeDescriptionGuidelines", {
				params: { descriptionId: descriptionId, effectiveDate: effectiveDate }
			}).then( function( result ) {
				return result.data;
			});
		};

		return service;

	}

	return EligibilityService;

});
